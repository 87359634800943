import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import * as actionType from "../../constants/actionTypes";

const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info.school_code;




function withParams(Component) {
    return props => <Component {...props} location={useLocation()} />;
}
// function SideBarLeave(props) {
class SideBarLeave extends React.Component {
    render() {
        const { location } = this.props;
        const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
        const permission_info = JSON.parse(localStorage.getItem("permissions"));
        const { school_code } = user_info
        console.log("DASfsdgdfgdfg", permission_info);
        return (
            <>
                <div
                    id="kt_aside"
                    className="aside aside-light aside-hoverable"
                    data-kt-drawer="true"
                    data-kt-drawer-name="aside"
                    data-kt-drawer-activate="{default: true, lg: false}"
                    data-kt-drawer-overlay="true"
                    data-kt-drawer-width="{default:'200px', '300px': '250px'}"
                    data-kt-drawer-direction="start"
                    data-kt-drawer-toggle="#kt_aside_mobile_toggle"
                    style={{ overflow: "scroll" }}
                >
                    <div className="aside-logo flex-column-auto" id="kt_aside_logo">
                        <Link to={`/${school_code}/dashboard`}>
                            {/* <img alt="Logo" src="/assets/media/logos/logo.png" className="h-50px logo" /> */}
                            <img alt="Logo" src="/assets/media/logos/TRIANGLE PNG (WOP) (2).png" className="h-50px logo" />
                        </Link>
                        <div className="cursor-pointer symbol symbol-30px symbol-md-40px" data-kt-menu-placement="bottom-end">
                            <img alt="Logo" src={user_info?.user_school?.school_logo ? actionType?.environment_url + '/' + user_info?.user_school?.school_logo : "/assets/media/school-logo.png"} />
                        </div>
                        <div className="menu-item menu-accordion">
                            <div className="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle" data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body" data-kt-toggle-name="aside-minimize">
                                <span className="svg-icon svg-icon-1 rotate-180">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path
                                            opacity="0.5"
                                            d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z"
                                            fill="black"
                                        />

                                        <path
                                            d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z"
                                            fill="black"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div id="sidebarscrole" className="aside-menu flex-column-fluid sidebarscrole">
                        <div
                            className="hover-scroll-overlay-y mb-5 mb-lg-5"
                            id="kt_aside_menu_wrapper"
                            data-kt-scroll="true"
                            data-kt-scroll-activate="{default: true, lg: false}"
                            data-kt-scroll-height="auto"
                            data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
                            data-kt-scroll-wrappers="#kt_aside_menu"
                            data-kt-scroll-offset="0"
                        >
                            <div
                                className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
                                id="#kt_aside_menu"
                                data-kt-menu="true"
                                data-kt-menu-expand="false"
                            >


                                <div id="leaves" data-kt-menu-trigger="click"
                                    className={
                                        "menu-item menu-accordion pagenav" +
                                        (location && location.pathname && (location.pathname === `/${school_code}/leave/manage-boarders-leave` || location.pathname === `/${school_code}/leave/manage-boarders-leave`
                                            || location.pathname === `/${school_code}/leave/boarders-leave-details` || location.pathname === `/${school_code}/leave/boarders-leave-details/`
                                            || location.pathname === `/${school_code}/leave/present-student-list` || location.pathname === `/${school_code}/leave/present-student-list/`
                                            // || location.pathname === `/${school_code}/leave/archive-leaves` || location.pathname === `/${school_code}/leave/archive-leaves/`
                                            || location.pathname === `/${school_code}/leave/manage-student-departure` || location.pathname === `/${school_code}/leave/manage-student-departure/`
                                            || location.pathname === `/${school_code}/leave/student-return` || location.pathname === `/${school_code}/leave/student-return/`
                                        ) ? " active-menu" : "")
                                    }
                                >
                                    <span className="menu-link py-3 main-menu">
                                        <span className="menu-icon">
                                            <i class="fas fa-user-plus" style={{filter: "brightness(0.5)"}}></i>
                                        </span>
                                        <span className="menu-title">Leave</span>
                                        <span className="menu-arrow"></span>
                                    </span>

                                    <div className="menu-sub menu-sub-accordion menu-active-bg">
                                        <div className="menu-item menu-accordion">
                                            <NavLink activeclassname="menu-active" to={`/${school_code}/leave/manage-boarders-leave`} className="menu-link">

                                                <span className="menu-icon">
                                                    <img alt="Logo" src="/media/Air-Traffic-Control.png" className="max-w-22"/>
                                                    {/* <i class="fa fa-folder-open"></i> */}
                                                </span>
                                                <span className="menu-title">Air Traffic Control (View Only)</span>
                                            </NavLink>
                                            {
                                                permission_info?.view_boarder_leaves !== false &&
                                                <NavLink activeclassname="menu-active" to={`/${school_code}/leave/boarders-leave-details`} className="menu-link">
                                                    <span className="menu-icon">
                                                        <img alt="Logo" src="/media/Leave-Approve Leave.png" className="max-w-22" style={{filter: "brightness(0.5)"}}/>
                                                        {/* <i class="fa fa-info-circle" aria-hidden="true"></i> */}
                                                    </span>
                                                    <span className="menu-title">Approve Leaves</span>
                                                </NavLink>
                                            }
                                            {
                                                permission_info?.add_boarder_leaves !== false &&
                                                <NavLink activeclassname="menu-active" to={`/${school_code}/leave/present-student-list`} className="menu-link">
                                                    <span className="menu-icon">
                                                        <img alt="Logo" src="/media/Leave_Apply For Boarders Leave.png" className="max-w-22" style={{filter: "brightness(0.5)"}}/>
                                                        {/* <i class="fa fa-check-circle" aria-hidden="true"></i> */}
                                                    </span>
                                                    <span className="menu-title">Apply For Boarders Leave</span>
                                                </NavLink>
                                            }

                                            {/* <NavLink activeclassname="menu-active" to={`/${school_code}/leave/archive-leaves`} className="menu-link">
                                                <span className="menu-icon">
                                                    <i className="fa fa-archive" aria-hidden="true"></i>
                                                </span>
                                                <span className="menu-title">Archive Leaves</span>
                                            </NavLink> */}

                                            <NavLink activeclassname="menu-active" to={`/${school_code}/leave/manage-student-departure`} className="menu-link">
                                                <span className="menu-icon">
                                                    <img alt="student_depart" src="/media/Leave _ Manage Student Departures.jpg" className="max-w-22"/>
                                                    {/* <i className="fa fa-file" aria-hidden="true"></i> */}
                                                </span>
                                                <span className="menu-title">Manage Student Departure's</span>
                                            </NavLink>

                                            <NavLink activeclassname="menu-active" to={`/${school_code}/leave/student-return`} className="menu-link">
                                                <span className="menu-icon">
                                                    <img alt="student_return" src="/media/Manage Student Returns.jpg" className="max-w-22"/>
                                                    {/* <i className="fa fa-address-book" aria-hidden="true"></i> */}
                                                </span>
                                                <span className="menu-title">Manage Student Return's</span>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>



                                <div id="adhoc_leaves" data-kt-menu-trigger="click"
                                    className={
                                        "menu-item menu-accordion pagenav" +
                                        (location && location.pathname && (location.pathname === `/${school_code}/leave` || location.pathname === `/${school_code}/leave`
                                            || location.pathname === `/${school_code}/leave/Adhoc-leave-details` || location.pathname === `/${school_code}/leave/Adhoc-leave-details/`
                                            || location.pathname === `/${school_code}/leave/present-student-list-for-adhoc` || location.pathname === `/${school_code}/leave/apply-For-adhoc-leave/`
                                            || location.pathname === `/${school_code}/leave/sign-boarders-out-in` || location.pathname === `/${school_code}/leave/sign-boarders-out-in/`
                                            || location.pathname === `/${school_code}/leave/apply-For-adhoc-leave` || location.pathname === `/${school_code}/leave/apply-For-adhoc-leave/`
                                            || location.pathname === `/${school_code}/rijultest/dashboard` || location.pathname === `/${school_code}/rijultest/dashboard/`
                                            // || location.pathname === `/${school_code}/leave/activity-list` || location.pathname === `/${school_code}/leave/activity-list/`
                                        ) ? " active-menu" : "")
                                    }
                                >

                                    <span className="menu-link py-3 main-menu">
                                        <span className="menu-icon">
                                            <i class="fas fa-ad" style={{filter: "brightness(0.5)"}}></i>
                                        </span>
                                        <span className="menu-title">Adhoc Leave</span>
                                        <span className="menu-arrow"></span>

                                    </span>

                                    <div className="menu-sub menu-sub-accordion menu-active-bg">
                                        <div className="menu-item menu-accordion">
                                            {
                                                permission_info?.view_adhoc_leave !== false &&
                                                <NavLink activeclassname="menu-active" to={`/${school_code}/leave/Adhoc-leave-details`} className="menu-link">
                                                    {/* <span className="menu-icon">
                                                        <i className="fa fa-folder" aria-hidden="true"></i>
                                                    </span> */}
                                                    <span className="menu-icon menu-bullet">
                                                    {/* <img alt="Logo" src="/media/Adhoc-leave.png" /> */}
                                                        <i class="fas fa-ad" style={{filter: "brightness(0.5)"}}></i>
                                                    </span>
                                                    <span className="menu-title">Adhoc Leave Details</span>
                                                </NavLink>
                                            }
                                            {
                                                permission_info?.add_travel_mode !== false &&
                                                <NavLink activeclassname="menu-active" to={`/${school_code}/leave/present-student-list-for-adhoc`} className="menu-link">
                                                    {/* <span className="menu-icon">
                                                        <i className="fa fa-arrow-circle-right" aria-hidden="true"></i>
                                                    </span> */}
                                                    <span className="menu-icon menu-bullet">
                                                    {/* <img alt="Logo" src="/media/Adhoc-leave.png" /> */}
                                                        <i class="fas fa-ad" style={{filter: "brightness(0.5)"}}></i>
                                                    </span>
                                                    <span className="menu-title">Apply For Adhoc Leave</span>
                                                </NavLink>
                                            }

                                            <NavLink activeclassname="menu-active" to={`/${school_code}/leave/sign-boarders-out-in`} className="menu-link">
                                                {/* <span className="menu-icon">
                                                    <i className="fa fa-outdent" aria-hidden="true"></i>
                                                </span> */}
                                                <span className="menu-icon menu-bullet">
                                                    {/* <img alt="Logo" src="/media/Adhoc-leave.png" /> */}
                                                    <i class="fas fa-ad" style={{filter: "brightness(0.5)"}}></i>
                                                </span>
                                                <span className="menu-title">Sign Boarders OUT/IN</span>
                                            </NavLink>

                                            <NavLink activeclassname="menu-active" to={`/${school_code}/rijultest/dashboard`} className="menu-link">
                                                {/* <span className="menu-icon">
                                                    <i className="fa fa-outdent" aria-hidden="true"></i>
                                                </span> */}
                                                <span className="menu-icon menu-bullet">
                                                    {/* <img alt="Logo" src="/media/Adhoc-leave.png" /> */}
                                                    <i class="fas fa-ad" style={{filter: "brightness(0.5)"}}></i>
                                                </span>
                                                <span className="menu-title">Adhoc Live</span>
                                            </NavLink>

                                            {/* <NavLink activeclassname="menu-active" to={`/${school_code}/leave/activity-list`} className="menu-link">
                                                <span className="menu-icon">
                                                    <i className="fa fa-plus-circle" aria-hidden="true"></i>
                                                </span>
                                                <span className="menu-title">Add Ad Hoc Activity - Off Campus</span>
                                            </NavLink> */}

                                        </div>
                                    </div>
                                </div>



                                <div id="leaves_settings" data-kt-menu-trigger="click"
                                    className={
                                        "menu-item menu-accordion pagenav" +
                                        (location && location.pathname && (location.pathname === `/${school_code}/leave` || location.pathname === `/${school_code}/leave`
                                            || location.pathname === `/${school_code}/leave/manage-host` || location.pathname === `/${school_code}/leave/manage-host/`
                                            || location.pathname === `/${school_code}/leave/manage-mode-of-travels` || location.pathname === `/${school_code}/leave/manage-mode-of-travels/`
                                            || location.pathname === `/${school_code}/leave/manage-leave-rejection-reason` || location.pathname === `/${school_code}/leave/manage-leave-rejection-reason/`
                                            || location.pathname === `/${school_code}/leave/host-responsibility` || location.pathname === `/${school_code}/leave/host-responsibility/`
                                            || location.pathname === `/${school_code}/leave/archive-leaves` || location.pathname === `/${school_code}/leave/archive-leaves/`
                                            || location.pathname === `/${school_code}/leave/activity-list` || location.pathname === `/${school_code}/leave/activity-list/` 
                                        ) ? " active-menu" : "")
                                    }
                                >

                                    <span className="menu-link py-3 main-menu">
                                        <span className="menu-icon">
                                            <img alt="Logo" src="/media/Leave-Settings.png" className="max-w-22" style={{filter: "brightness(0.5)"}}/>
                                            {/* <i className="fas fa-cog"></i> */}
                                        </span>
                                        <span className="menu-title">Leave Settings</span>
                                        <span className="menu-arrow"></span>
                                    </span>

                                    <div className="menu-sub menu-sub-accordion menu-active-bg">
                                        <div className="menu-item menu-accordion">
                                            <NavLink activeclassname="menu-active" to={`/${school_code}/leave/activity-list`} className="menu-link">
                                                <span className="menu-icon">
                                                    <img alt="activity" src="/media/Leave_Add Adhoc Activity Off Campus.png" className="max-w-22"/>
                                                    {/* <i className="fa fa-plus-circle" aria-hidden="true"></i> */}
                                                </span>
                                                <span className="menu-title">Add Ad Hoc Activity - Off Campus</span>
                                            </NavLink>
                                            <NavLink activeclassname="menu-active" to={`/${school_code}/leave/archive-leaves`} className="menu-link">
                                                <span className="menu-icon">
                                                    <img alt="Logo" src="/media/ARchive-Leaves.png" className="max-w-22"/>
                                                    {/* <i className="fa fa-archive" aria-hidden="true"></i> */}
                                                </span>
                                                <span className="menu-title">Archive Leaves</span>
                                            </NavLink>
                                            <NavLink activeclassname="menu-active" to={`/${school_code}/leave/manage-host`} className="menu-link">
                                                <span className="menu-icon">
                                                    <img alt="manage_host" src="/media/Leave Manage Host.png" className="max-w-22"/>
                                                    {/* <i className="fa fa-user-circle" aria-hidden="true"></i>*/}
                                                </span>
                                                <span className="menu-title">Manage Host</span>
                                            </NavLink>
                                            {permission_info?.view_rejection_reason !== false &&
                                                <NavLink activeclassname="menu-active" to={`/${school_code}/leave/manage-leave-rejection-reason`} className="menu-link">
                                                    <span className="menu-icon">
                                                        <img alt="Logo" src="/media/Leave-Rejection-Reasons.png" className="max-w-22" style={{filter: "brightness(0.5)"}}/>
                                                        {/* <i className="fa fa-times-circle" aria-hidden="true"></i> */}
                                                    </span>
                                                    <span className="menu-title">Manage Leave Rejection Reasons</span>
                                                </NavLink>
                                            }
                                            {permission_info?.view_travel_mode !== false &&
                                                <NavLink activeclassname="menu-active" to={`/${school_code}/leave/manage-mode-of-travels`} className="menu-link">
                                                    <span className="menu-icon">
                                                        <img alt="travel_mode" src="/media/Manage-Mode-of-Travel1.png" className="max-w-22" style={{filter: "brightness(0.5)"}}/>                                                        
                                                        {/* <i className="fa fa-globe" aria-hidden="true"></i> */}
                                                    </span>
                                                    <span className="menu-title">Manage Mode Of Travel</span>
                                                </NavLink>
                                            }
                                            <NavLink activeclassname="menu-active" to={`/${school_code}/leave/host-responsibility`} className="menu-link">
                                                <span className="menu-icon menu-bullet">
                                                    <img alt="Logo" src="/media/Manage-Leave-Rejection-Reasons.png" />
                                                    {/* <i class="fas fa-hand-holding-heart" aria-hidden="true"></i> */}
                                                </span>
                                                <span className="menu-title">Student and Host Responsibilities</span>
                                            </NavLink>
                                        </div>
                                    </div>

                                </div>


                                <div id="reports" data-kt-menu-trigger="click"
                                    className={
                                        "menu-item menu-accordion pagenav" +
                                        (location && location.pathname && (location.pathname === `/${school_code}/leave` || location.pathname === `/${school_code}/leave`
                                            || location.pathname === `/${school_code}/leave/reports/approved-leave-report` || location.pathname === `/${school_code}/leave/reports/approved-leave-report/`
                                            || location.pathname === `/${school_code}/leave/reports/non-return-report` || location.pathname === `/${school_code}/leave/reports/non-return-report/`
                                            || location.pathname === `/${school_code}/leave/reports/departure-report` || location.pathname === `/${school_code}/leave/reports/departure-report/`
                                            || location.pathname === `/${school_code}/leave/reports/due-back-report` || location.pathname === `/${school_code}/leave/reports/due-back-report/`
                                            || location.pathname === `/${school_code}/leave/reports/catering-report` || location.pathname === `/${school_code}/leave/reports/catering-report/`
                                            || location.pathname === `/${school_code}/leave/reports/border-leave-history-report` || location.pathname === `/${school_code}/leave/reports/border-leave-history-report/`
                                            || location.pathname === `/${school_code}/leave/reports/adhoc-leave-history-report` || location.pathname === `/${school_code}/leave/reports/adhoc-leave-history-report/`
                                            || location.pathname === `/${school_code}/leave/reports/student-connect-to-host-report` || location.pathname === `/${school_code}/leave/reports/student-connect-to-host-report/`
                                            || location.pathname === `/${school_code}/leave/reports/adhoc-report` || location.pathname === `/${school_code}/leave/reports/adhoc-report/`
                                            || location.pathname === `/${school_code}/leave/reports/allergic-report` || location.pathname === `/${school_code}/leave/reports/allergic-report/`
                                            || location.pathname === `/${school_code}/leave/reports/host-connect-to-student-report` || location.pathname === `/${school_code}/leave/reports/host-connect-to-student-report/`
                                            || location.pathname === `/${school_code}/leave/reports/host-report` || location.pathname === `/${school_code}/leave/reports/host-report/`

                                        ) ? " active-menu" : "")
                                    }
                                >

                                    {permission_info?.view_reports !== false &&
                                        <span className="menu-link py-3 main-menu">
                                            <span className="menu-icon">
                                                <img alt="report" src="/media/Settings_Reports.png" className="max-w-22" style={{filter: "brightness(0.5)"}}/>
                                                {/* <i className="far fa-user"></i> */}
                                            </span>
                                            <span className="menu-title">Reports</span>
                                            <span className="menu-arrow"></span>
                                        </span>
                                    }

                                    <div className="menu-sub menu-sub-accordion menu-active-bg">
                                        <div className="menu-item menu-accordion">

                                            <NavLink activeclassname="menu-active" className="menu-link" to={`/${school_code}/leave/reports/approved-leave-report`}>
                                                <span className="menu-bullet">
                                                    {/* <i className="fas fa-list"></i> */}
                                                </span>
                                                <span className="menu-title">Approved Leave Report</span>
                                            </NavLink>

                                            <NavLink activeclassname="menu-active" className="menu-link" to={`/${school_code}/leave/reports/non-return-report`}>
                                                <span className="menu-bullet">
                                                    {/* <i className="fas fa-list"></i> */}
                                                </span>
                                                <span className="menu-title">NRN Report</span>
                                            </NavLink>

                                            <NavLink activeclassname="menu-active" className="menu-link" to={`/${school_code}/leave/reports/departure-report`}>
                                                <span className="menu-bullet">
                                                    {/* <i className="fas fa-list"></i> */}
                                                </span>
                                                <span className="menu-title">Departure Report</span>
                                            </NavLink>

                                            <NavLink activeclassname="menu-active" className="menu-link" to={`/${school_code}/leave/reports/due-back-report`}>
                                                <span className="menu-bullet">
                                                    {/* <i className="fas fa-list"></i> */}
                                                </span>
                                                <span className="menu-title">Due Back Report</span>
                                            </NavLink>

                                            <NavLink activeclassname="menu-active" className="menu-link" to={`/${school_code}/leave/reports/catering-report`}>
                                                <span className="menu-bullet">
                                                    {/* <i className="fas fa-list"></i> */}
                                                </span>
                                                <span className="menu-title">Catering Report</span>
                                            </NavLink>

                                            <NavLink activeclassname="menu-active" className="menu-link" to={`/${school_code}/leave/reports/border-leave-history-report`}>
                                                <span className="menu-bullet">
                                                    {/* <i className="fas fa-list"></i> */}
                                                </span>
                                                <span className="menu-title">Boarders Leave History Report</span>
                                            </NavLink>

                                            <NavLink activeclassname="menu-active" className="menu-link" to={`/${school_code}/leave/reports/adhoc-leave-history-report`}>
                                                <span className="menu-bullet">
                                                    {/* <i className="fas fa-list"></i> */}
                                                </span>
                                                <span className="menu-title">Adhoc Leave History Report</span>
                                            </NavLink>


                                            <NavLink activeclassname="menu-active" className="menu-link" to={`/${school_code}/leave/reports/adhoc-report`}>
                                                <span className="menu-bullet">
                                                    {/* <i className="fas fa-list"></i> */}
                                                </span>
                                                <span className="menu-title">Adhoc Report</span>
                                            </NavLink>

                                            <NavLink activeclassname="menu-active" className="menu-link" to={`/${school_code}/leave/reports/allergic-report`}>
                                                <span className="menu-bullet">
                                                    {/* <i className="fas fa-list"></i> */}
                                                </span>
                                                <span className="menu-title">Allergic Report</span>
                                            </NavLink>

                                            <NavLink activeclassname="menu-active" className="menu-link" to={`/${school_code}/leave/reports/student-connect-to-host-report`}>
                                                <span className="menu-bullet">
                                                    {/* <i className="fas fa-list"></i> */}
                                                </span>
                                                <span className="menu-title">Hosts of a Student</span>
                                            </NavLink>

                                            <NavLink activeclassname="menu-active" className="menu-link" to={`/${school_code}/leave/reports/host-connect-to-student-report`}>
                                                <span className="menu-bullet">
                                                    {/* <i className="fas fa-list"></i> */}
                                                </span>
                                                <span className="menu-title">Students of a Host</span>
                                            </NavLink>

                                            <NavLink activeclassname="menu-active" className="menu-link" to={`/${school_code}/leave/reports/host-report`}>
                                                <span className="menu-bullet">
                                                    {/* <i className="fas fa-list"></i> */}
                                                </span>
                                                <span className="menu-title">All Hosts</span>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="menu-item">
                                    <NavLink activeclassname="menu-active" to={`/${school_code}/snapshot`} className="menu-link">
                                        <span className="menu-icon">
                                                <img alt="report" src="/media/Return-To-Student-Interface1.png" className="max-w-22" style={{filter: "brightness(0.5)"}}/>
                                            {/* <span className="svg-icon svg-icon-2">
                                                <i className="fas fa-images"></i>
                                            </span> */}
                                        </span>
                                        <span className="menu-title">Return To Student Interface</span>
                                    </NavLink>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withParams(SideBarLeave);
