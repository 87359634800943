import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import * as userActions from '../../../actions/index';
import Select from 'react-select';
import Validations from "../../Utility/Validations";
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import ReturnSchudleChange from '../UniversalComponent/ReturnSchudleChange'

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: state.isFocused ? "#e7f5fe" : "",
    },
    backgroundColor: state.isSelected ? "#4a8fb8" : "",
  }),
};
const ReturnScheduleChangeModal = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [edit_return_form, setEditReturnForm] = useState(
    {
      planned_return_date: {
        type: "date",
        label: "Date",
        value: "",
        valid: true,
        error_msg: "",
        onBlur_out: false,
        placeholder: "Host's Address",
        validations: { required: true },
      },
      planned_return_time: {
        type: "time",
        label: "Time",
        value: "",
        valid: true,
        error_msg: "",
        onBlur_out: false,
        placeholder: "Host Name",
        validations: { required: true },
      },
      planned_return_mode_id: {
        type: "text",
        label: "Mode Travel",
        value: "",
        valid: true,
        error_msg: "",
        onBlur_out: false,
        placeholder: "Host Relation",
        validations: { required: true },
        options: []
      },
      schedule_changed_reason: {
        type: "text",
        label: "Reason for Changed Leave Times",
        value: true,
        valid: true,
        error_msg: "",
        validations: { required: false },
      },
      return_status: {
        type: "text",
        label: "Student Host Comment",
        value: "schedule_changed",
        valid: true,
        error_msg: "Student Host Comment",
        validations: { required: false },
      },
      border_leave_id: {
        type: "text",
        label: "Host's Mobile Number *",
        value: "",
        valid: true,
        error_msg: "",
        onBlur_out: false,
        placeholder: "Host's Mobile Number",
        validations: { required: true },
      },
      student_id: {
        type: "hidden",
        label: "student id",
        value: "",
        valid: true,
        error_msg: "",
        onBlur_out: false,
        placeholder: "student id",
        validations: { required: false },
      },
      border_leave_return_stu_id: {
        type: "hidden",
        label: "student id",
        value: "",
        valid: true,
        error_msg: "",
        onBlur_out: false,
        placeholder: "Return student id",
        validations: { required: false },
      },
    }
  )

  useState(() => {
    setShowModal(props.showChangeShedule)
    // let hours = parseInt(props.returnLeaveData.return_students_data.planned_return_time.split(':')[0], 10)
    // let minute = parseInt(props.returnLeaveData.return_students_data.planned_return_time.split(':')[1], 10)
    const update_edit_return_form = { ...edit_return_form }
    update_edit_return_form['planned_return_time'].value = props.returnLeaveData.return_students_data.planned_return_time;
    update_edit_return_form['planned_return_date'].value = props.returnLeaveData.return_students_data.planned_return_date;
    update_edit_return_form['planned_return_mode_id'].value = props.returnLeaveData.return_students_data.planned_return_mode_id;
    update_edit_return_form['border_leave_id'].value = props.returnLeaveData.border_leave_id;
    update_edit_return_form['student_id'].value = props.returnLeaveData.student_id;
    update_edit_return_form['border_leave_return_stu_id'].value = props.returnLeaveData?.return_students_data?.border_leave_return_stu_id;
    setEditReturnForm(update_edit_return_form)
  }, [props])

  const closeModal = () => {
    props.hideReturnStuLeaveChangeModal(false);
  };

  const inputChangeHandler = (e, identifier) => {
    const update_return_form = { ...edit_return_form };
    if (identifier === "planned_return_time") {
      update_return_form[identifier].value =moment(e, 'hh:mm A').format('HH:mm');
    } else if (identifier === "planned_return_date") {
      update_return_form[identifier].value = e.target.value
    } else {
      update_return_form[identifier].value = e.target.value;
    }
    setEditReturnForm(update_return_form)
  }

  const handleSelectChange = (data, identifier) => {
    const update_return_form = { ...edit_return_form };
    update_return_form[identifier].value = data.value;
    setEditReturnForm(update_return_form)
  }

  const SubmitHandlerHost = (event) => {
    event.preventDefault();
    let is_form_valid = true;
    const update_return_form = { ...edit_return_form };
    for (let key in update_return_form) {
      let form_element = update_return_form[key];
      let validate_element = Validations(form_element);
      is_form_valid = validate_element.valid === false ? false : is_form_valid;
      form_element.onBlur_out = true;
      form_element.valid = validate_element.valid;
      form_element.error_msg = validate_element.error_msg;
      update_return_form[key] = form_element;
    }
    setEditReturnForm(update_return_form)
    if (is_form_valid) {
      const form_data = {};
      for (let key in edit_return_form) {
        form_data[key] = edit_return_form[key].value;
      }
      props.ReturnStuLeaveChange(form_data)
    }
  }


  useEffect(() => {
    GetAllTravelModes()
  }, []);
  const GetAllTravelModes = async () => {
    props.onGetAllTravelModes().then((response) => {
      if (response.success === true) {
        const arrayData = response.data.rows;
        const state_data = []
        state_data.push({
          label: "Modes Of Travel",
          value: "",
        })
        for (let key in arrayData) {
          state_data.push({
            label: arrayData[key].travel_mode_name,
            value: arrayData[key].travel_mode_id,
          })
        }
        const update_return_form = { ...edit_return_form }
        update_return_form['planned_return_mode_id'].options = state_data
        setEditReturnForm(update_return_form)
      }
    })
  };


  return (
    <div>
      <Modal size="md" show={showModal} onHide={closeModal}>
        <form onSubmit={(e) => SubmitHandlerHost(e)}>
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Return On Different Date/Time
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid">
            <div className="mb-3">
              <label className="form-label">{edit_return_form.planned_return_date.label}</label>
              <div className='position-relative'>
                <span className='position-absolute apply_adhoc_leave_date_and_time' onClick={() => document.getElementById('session-date').click()}>

                </span>
                <input
                  id="session-date"
                  type={edit_return_form.planned_return_date.type}
                  className="form-control bg-transparent"
                  placeholder={edit_return_form.planned_return_date.placeholder}
                  value={edit_return_form.planned_return_date.value}
                  onChange={(event) => inputChangeHandler(event, "planned_return_date")}
                  autoComplete="off"
                  required
                />
              </div>
              {!edit_return_form.planned_return_date.valid && edit_return_form.planned_return_date.onBlur_out ? <div className="error field-error">{edit_return_form.planned_return_date.error_msg}</div> : ""}
            </div>
            <div className="mb-3">
              <label className="form-label">{edit_return_form.planned_return_time.label}</label>
              <ReturnSchudleChange
                selectTime={edit_return_form.planned_return_time.value}
                onChange={(event) => inputChangeHandler(event, "planned_return_time")}
              />
              {/* <div className='position-relative adhoc_leave_form_datePicker'>
                <span className='position-absolute apply_adhoc_leave_date_and_time'>
                  <i class="far fa-clock"></i>
                </span>
                <TimePicker
                  showSecond={false}
                  minuteStep={5}
                  use12Hours={true}
                  defaultValue={edit_return_form.planned_return_time.value}
                  className="leave_time_picker w-100 bg-transparent"
                  popupClassName="popupClassName"
                  inputReadOnly={true}
                  onChange={(event) => inputChangeHandler(event, "planned_return_time")}
                />
              </div> */}
              {!edit_return_form.planned_return_time.valid && edit_return_form.planned_return_time.onBlur_out ? <div className="error field-error">{edit_return_form.planned_return_time.error_msg}</div> : ""}
            </div>
            <div className="mb-3">
              <label className="form-label">{edit_return_form.planned_return_mode_id.label}</label>
              <Select
                styles={customStyles}
                className="react-bootstrap-typeahead tag-outer"
                options={edit_return_form.planned_return_mode_id.options}
                value={edit_return_form.planned_return_mode_id.options.filter(function (option) {
                  return option.value === edit_return_form.planned_return_mode_id.value;
                })}
                onChange={(newValue) => handleSelectChange(newValue, "planned_return_mode_id")}
                autoComplete="off"
              />
              {!edit_return_form.planned_return_mode_id.valid && edit_return_form.planned_return_mode_id.onBlur_out ? <div className="error field-error">{edit_return_form.planned_return_mode_id.error_msg}</div> : ""}
            </div>
            <div className="mb-3">
              <label className="form-label">{edit_return_form.schedule_changed_reason.label}</label>
              <textarea
                className="form-control h-150"
                placeholder={edit_return_form.schedule_changed_reason.placeholder}
                id="floatingTextarea2"
                onChange={(event) => inputChangeHandler(event, "schedule_changed_reason")}
              ></textarea>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary btn-sm" onClick={closeModal}>
              Close
            </button>
            <button class="btn btn-primary btn-sm" type="submit">
              Save Changes
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  )
}


function mapStateToProps(state) {
  return {

  };
}

const mapDispatchToProps = {
  onGetAllTravelModes: userActions.GetAllTravelModes
};
export default connect(mapStateToProps, mapDispatchToProps)(ReturnScheduleChangeModal);
