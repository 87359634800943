import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from "react-redux";
import Select from 'react-select';
import moment from "moment";
import { toast } from "react-toastify";
import SideBarLeave from "../../Constant/SideBarLeave";
import Footer from "../../Constant/Footer";
import TopBar from "../../Constant/TopBar";
import Validations from "../../Utility/Validations";
import * as userActions from '../../../actions/index';
import { Link } from "react-router-dom";
import ApplyAdhocLeaveModal from '../AdhocLeaveModals/ApplyAdhocLeaveModal';
import * as actionType from "../../../constants/actionTypes";
import { Navigate } from "react-router-dom";
import ReactDatePicker from 'react-datepicker';
import DatePicker from "react-datepicker";

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#e7f5fe" : "",
        },
        backgroundColor: state.isSelected ? "#4a8fb8" : "",
    }),
};
const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info?.school_code;

// var week = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']

function withParams(Component) {
    return props => <Component {...props} location={useLocation()} />;
}

class ApplyAdhocLeave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            select_all_days: false,
            days: [],
            confirm_modal: false,
            studentName: [],
            studentNameArray: [],
            studentIdArray: [],
            dormitoryNameArray: [],
            dormitoryIdArray: [],
            classNameArray: [],
            classIdArray: [],
            gender: [],
            avatar: [],
            day_list: [],
            adhoc_leave_form: {
                student_ids: {
                    type: "hidden",
                    label: "Student  Id",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                    options: [],
                    avatar: [],
                    gender: []
                },

                weekends: {
                    type: "text",
                    label: "Activity Days *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },

                activity_id: {
                    type: "hidden",
                    label: "Activity *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                    options: []
                },

                venue: {
                    type: "text",
                    label: "Venue",
                    value: "as per activity",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },

                departure_date: {
                    type: "date",
                    label: "Program Start Date *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select Start Date",
                    validations: { required: true },
                },

                start_time: {
                    type: "time",
                    label: "Start Time *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select Start Time",
                    validations: { required: true },
                },

                departure_travel_mode_id: {
                    type: "hidden",
                    label: "Travel Out *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                    options: []
                },

                arrival_date: {
                    type: "date",
                    label: "Program End Date *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select End Date",
                    validations: { required: true },
                },

                end_time: {
                    type: "time",
                    label: "End Time *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select End time",
                    validations: { required: true },
                },

                arrival_travel_mode_id: {
                    type: "hidden",
                    label: "Travel In *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                    options: []
                },

                parents_inst: {
                    type: "text",
                    label: "Parent Instructions",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Instructions",
                    validations: { required: false },
                },

                manager_inst: {
                    type: "text",
                    label: "Manager Instructions",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Instructions",
                    validations: { required: false },
                },
            }
        }
    }



    handleSelectChange(e, identifier) {
        const update_adhoc_leave_form = this.state.adhoc_leave_form;
        const form_element = { ...update_adhoc_leave_form[identifier] };
        form_element.value = e.value;
        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;
        let is_form_valid = true;
        for (let identifier in update_adhoc_leave_form) {
            is_form_valid = update_adhoc_leave_form[identifier].valid && is_form_valid;
        }
        update_adhoc_leave_form[identifier] = form_element;
        this.setState({ adhoc_leave_form: update_adhoc_leave_form })
    }
    inputChangeHandler(e, identifier) {
        let value = identifier === "venue" ? e.target.value.substring(0,30) : e.target.value;
        const update_adhoc_leave_form = this.state.adhoc_leave_form;
        const form_element = { ...update_adhoc_leave_form[identifier] };
        form_element.value = value;
        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;
        let is_form_valid = true;
        for (let identifier in update_adhoc_leave_form) {
            is_form_valid = update_adhoc_leave_form[identifier].valid && is_form_valid;
        }
        update_adhoc_leave_form[identifier] = form_element;
        this.setState({ adhoc_leave_form: update_adhoc_leave_form })
    }
    propgramDateHandler(e, identifier) {
        const update_adhoc_leave_form = this.state.adhoc_leave_form;
        const form_element = { ...update_adhoc_leave_form[identifier] };
        form_element.value = e.target.value;
        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;
        let is_form_valid = true;
        for (let identifier in update_adhoc_leave_form) {
            is_form_valid = update_adhoc_leave_form[identifier].valid && is_form_valid;
        }
        update_adhoc_leave_form[identifier] = form_element;
        this.setState({ adhoc_leave_form: update_adhoc_leave_form })

    }
    propgramTimeHandler(e, identifier) {
        const update_adhoc_leave_form = this.state.adhoc_leave_form;
        const form_element = { ...update_adhoc_leave_form[identifier] };
        form_element.value = e;
        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;
        let is_form_valid = true;
        for (let identifier in update_adhoc_leave_form) {
            is_form_valid = update_adhoc_leave_form[identifier].valid && is_form_valid;
        }
        update_adhoc_leave_form[identifier] = form_element;
        this.setState({ adhoc_leave_form: update_adhoc_leave_form })
    }
    weekDayChangeHandler(e, identifier, day) {
        const update_days = this.state.days;
        const update_adhoc_leave_form = this.state.adhoc_leave_form;
        if (e.target.value === "false") {
            const filter_days = update_days.map((item) => {
                if (item.label === day.label) {
                    return { ...item, value: true }
                } else {
                    return item
                }
            })
            const filter_day_list = []
            filter_days.forEach((item) => {
                if (item.value === true) {
                    filter_day_list.push(item.label)
                }
            })
            this.setState({ select_all_days: filter_day_list.length === 7 ? true : false })
            this.setState({ days: filter_days })
            update_adhoc_leave_form[identifier].value = filter_day_list.join("-")
        } else {
            const filter_days = update_days.map((item) => {
                if (item.label === day.label) {
                    return { ...item, value: false }
                } else {
                    return item
                }
            })
            const filter_day_list = []
            filter_days.forEach((item) => {
                if (item.value === true) {
                    filter_day_list.push(item.label)
                }
            })
            this.setState({ select_all_days: filter_day_list.length === 7 ? true : false })
            this.setState({ days: filter_days })
            update_adhoc_leave_form[identifier].value = filter_day_list.join("-")
        }
        this.setState({ adhoc_leave_form: update_adhoc_leave_form })
    }

    selectAllDays = (e) => {
        const update_days = this.state.days;
        const update_adhoc_leave_form = this.state.adhoc_leave_form;
        if (e.target.value === "false") {
            const filter_days = update_days.map((item) => {
                return { ...item, value: true }
            })
            const filter_day_list = []
            filter_days.forEach((item) => {
                if (item.value === true) {
                    filter_day_list.push(item.label)
                }
            })
            update_adhoc_leave_form["weekends"].value = filter_day_list.join("-")
            this.setState({ days: filter_days })
            this.setState({ select_all_days: true })
            this.setState({ adhoc_leave_form: update_adhoc_leave_form })
        } else {
            const filter_days = update_days.map((item) => {
                return { ...item, value: false }
            })
            const filter_day_list = []
            filter_days.forEach((item) => {
                if (item.value === true) {
                    filter_day_list.push(item.label)
                }
            })
            update_adhoc_leave_form["weekends"].value = filter_day_list.join("-")
            this.setState({ days: filter_days })
            this.setState({ select_all_days: false })
            this.setState({ adhoc_leave_form: update_adhoc_leave_form })
        }
    }


    componentDidMount() {
        this.GetAllActivity();
        this.GetAllTravelModes();
        const update_adhoc_leave_form = this.state.adhoc_leave_form;
        const { location } = this.props;
        console.log("location", location);
        const dataArray = location.state.data;
        const StudentNameArray = [];
        const StudentIdArray = [];
        const DormitoryNameArray = [];
        const DormitoryIdArray = [];
        const ClassNameArray = [];
        const ClassIdArray = [];
        const Gender = [];
        const Avatar = []

        dataArray.forEach(element => {
            StudentNameArray.push(`${element.student_first_name} ${element.student_last_name}`)
            StudentIdArray.push(element.student_id)
            if (DormitoryNameArray.includes(element.dormitory_name) === false) {
                DormitoryNameArray.push(`${element.dormitory_name}`)
            }
            if (DormitoryIdArray.includes(element.dormitory_id) === false) {
                DormitoryIdArray.push(element.dormitory_id)
            }
            if (ClassNameArray.includes(element.class_name) === false) {
                ClassNameArray.push(`${element.class_name}`)
            }
            if (ClassIdArray.includes(element.class_id) === false) {
                ClassIdArray.push(`${element.class_id}`)
            }
            if (Gender.includes(element.gender) === false) {
                Gender.push(element.gender)
            }
            Avatar.push(element.student_avatar === "" ? null : element.student_avatar)
        });
        this.setState({ adhoc_leave_form: update_adhoc_leave_form });

        this.setState({ studentNameArray: StudentNameArray })
        this.setState({ studentIdArray: StudentIdArray })
        this.setState({ dormitoryNameArray: DormitoryNameArray })
        this.setState({ dormitoryIdArray: DormitoryIdArray })
        this.setState({ classNameArray: ClassNameArray })
        this.setState({ classIdArray: ClassIdArray })
        this.setState({ gender: Gender })
        this.setState({ avatar: Avatar })
        update_adhoc_leave_form['student_ids'].options = StudentNameArray;
        update_adhoc_leave_form['student_ids'].value = JSON.stringify(StudentIdArray);
        update_adhoc_leave_form['student_ids'].avatar = Avatar;
        update_adhoc_leave_form['student_ids'].gender = Gender;

        const all_days = [
            { label: 'Sunday', value: false },
            { label: 'Monday', value: false },
            { label: 'Tuesday', value: false },
            { label: 'Wednesday', value: false },
            { label: 'Thursday', value: false },
            { label: 'Friday', value: false },
            { label: 'Saturday', value: false }
        ]
        this.setState({ days: all_days })


        // for(let h=0;h<=12;h++){
        //     for(let m=1;m<=60;m++){
        //         console.log("Dsfsdgdg",m);
        //     }
        // }
    }




    GetAllTravelModes = async () => {
        this.props.onGetAllTravelModes().then((response) => {
            let country_level = [];
            country_level.push({
                label: "Select Travel Modes",
                value: ""
            })
            const update_adhoc_leave_form = this.state.adhoc_leave_form;
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    country_level.push({
                        label: arrayData[key].travel_mode_name,
                        value: arrayData[key].travel_mode_id,
                    });
                }
            }
            update_adhoc_leave_form['departure_travel_mode_id'].options = country_level;
            update_adhoc_leave_form['arrival_travel_mode_id'].options = country_level;
            this.setState({ adhoc_leave_form: update_adhoc_leave_form });

        });
    };

    GetAllActivity = async () => {
        this.props.onGetAllActivity().then((response) => {
            let country_level = [];
            country_level.push({
                label: "Select Activity",
                value: ""
            })
            const update_adhoc_leave_form = this.state.adhoc_leave_form;
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    country_level.push({
                        label: arrayData[key].activity_name,
                        value: arrayData[key].activity_id,
                    });
                }
            }
            update_adhoc_leave_form['activity_id'].options = country_level;
            this.setState({ adhoc_leave_form: update_adhoc_leave_form });

        });

    };




    // SubmitHandler = async (event) => {
    //     event.preventDefault();
    //     let is_form_valid = true;
    //     const update_adhoc_leave_form = this.state.adhoc_leave_form;
    //     for (let key in update_adhoc_leave_form) {
    //         let form_element = update_adhoc_leave_form[key];
    //         let validate_element = Validations(form_element);
    //         is_form_valid = validate_element.valid === false ? false : is_form_valid;
    //         form_element.onBlur_out = true;
    //         form_element.valid = validate_element.valid;
    //         form_element.error_msg = validate_element.error_msg;
    //         update_adhoc_leave_form[key] = form_element;
    //     }
    //     this.setState({ adhoc_leave_form: update_adhoc_leave_form })

    //     if (is_form_valid) {
    //         const form_data = {};
    //         for (let key in this.state.adhoc_leave_form) {
    //             form_data[key] = this.state.adhoc_leave_form[key].value;
    //         }
    //         console.log("update_adhoc_leave_form", form_data);
    //         this.props.onCreateAdhocLeave(form_data).then((response) => {
    //             if (response.success === true) {
    //                 toast.success(response.message, {
    //                     position: toast.POSITION.TOP_CENTER,
    //                 });
    //                 setTimeout(() => {
    //                     this.setState({
    //                         isAuthenticated: true,
    //                         redirect_url: `/${school_code}/leave/Adhoc-leave-details`,
    //                     });
    //                 }, 500);
    //             } else {
    //                 toast.error(response.message, {
    //                     position: toast.POSITION.TOP_CENTER,
    //                 });
    //             }
    //         })
    //     }
    // }


    modalHide = () => { this.setState({ confirm_modal: false }) };
    showModal = (event) => {
        let is_form_valid = true;
        const update_adhoc_leave_form = this.state.adhoc_leave_form;
        for (let key in update_adhoc_leave_form) {
            let form_element = update_adhoc_leave_form[key];
            let validate_element = Validations(form_element);
            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;
            update_adhoc_leave_form[key] = form_element;
        }
        this.setState({ adhoc_leave_form: update_adhoc_leave_form })
        console.log("dafsdfsdgdg", update_adhoc_leave_form);
        if (is_form_valid) {
            this.setState({ confirm_modal: true })
        }
    };
    SubmitHandler = async () => {
        const form_data = {};
        for (let key in this.state.adhoc_leave_form) {
            if (key === "start_time" || key === "end_time") {
                form_data[key] = moment(this.state.adhoc_leave_form[key].value).format('HH:mm');
            } else {
                form_data[key] = this.state.adhoc_leave_form[key].value;
            }
        }
        this.props.onCreateAdhocLeave(form_data).then((response) => {
            if (response.success === true) {
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                setTimeout(() => {
                    this.setState({
                        isAuthenticated: true,
                        redirect_url: `/${school_code}/leave/Adhoc-leave-details`,
                    });
                }, 500);
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        })
    }
    getCurrentDate() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }


    render() {
        const { adhoc_leave_form } = this.state;
        const { studentNameArray, studentIdArray, dormitoryNameArray, dormitoryIdArray, classNameArray, classIdArray, gender, avatar } = this.state;
        const { confirm_modal } = this.state;
        const { select_all_days, days } = this.state;
        return (
            <>
                {this.state.isAuthenticated ? <Navigate to={this.state.redirect_url} /> : null}
                <div
                    id="kt_body"
                    className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed"
                >
                    {this.state.confirm_modal && (
                        <ApplyAdhocLeaveModal
                            confirm_modal={confirm_modal}
                            modalHide={() => this.modalHide()}
                            SubmitHandler={(event) => this.SubmitHandler(event)}
                        />
                    )}
                    <div className="d-flex flex-column flex-root">
                        <div className="page d-flex flex-row flex-column-fluid">
                            <SideBarLeave />
                            <div
                                className="wrapper d-flex flex-column flex-row-fluid "
                                id="kt_wrapper"
                            >
                                <TopBar />
                                <div
                                    className="content d-flex flex-column flex-column-fluid"
                                    id="kt_content"
                                >
                                    <div className="post d-flex flex-column-fluid" id="kt_post">
                                        <div id="kt_content_container" className="container-fluid">
                                            <div className="row justify-content-center">
                                                <div className="col-md-12">
                                                    <div className="card card-xl-stretch">
                                                        <div className="card-header align-items-center border-0  justify-content-between">
                                                            <h2 className="card-title align-items-center">Apply For AdHoc Leave</h2>
                                                            <Link to={`/${school_code}/leave/Adhoc-leave-details`} className='d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path d="M18 17L13 12L18 7M11 17L6 12L11 7" stroke="#4a8fb8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>Back</Link>
                                                        </div>

                                                        <form onSubmit={this.SubmitHandler}>
                                                            <div className="card-body">
                                                                <div className="d-flex gap-10 align-items-center heading-strong" >
                                                                    {console.log("ssssssssssssssssssssss", adhoc_leave_form.student_ids.avatar)}
                                                                    <span>
                                                                        {adhoc_leave_form.student_ids.avatar.length === 1 ?
                                                                            (adhoc_leave_form.student_ids.avatar[0] !== null ?
                                                                                <img src={`${actionType.environment_url}/${adhoc_leave_form.student_ids.avatar[0]}`}
                                                                                    alt='Student Avatar'
                                                                                    className="img-thumbnail  custom-thumbnail"
                                                                                />
                                                                                :
                                                                                <img src={adhoc_leave_form.student_ids.gender[0] === "male" ? "/media/avatars/boy.jpg" : "/media/avatars/girl.jpg"}
                                                                                    alt='Student Avatar'
                                                                                    className="img-thumbnail  custom-thumbnail"
                                                                                />)
                                                                            : null
                                                                        }
                                                                    </span>
                                                                </div>

                                                                <div className=" row mt-5" >
                                                                    <div className='col-md-2'>
                                                                        <label className="fw-bold mb-2">Welcome </label>
                                                                    </div>
                                                                    <div className='col-md-5'>
                                                                        {adhoc_leave_form.student_ids.options.join(" , ")}
                                                                    </div>
                                                                </div>
                                                                <div className=" row mt-5" >
                                                                    <div className='col-md-2'>
                                                                        <label className="fw-bold mb-2">Year Level</label>
                                                                    </div>
                                                                    <div className='col-md-5'>
                                                                        {classNameArray.join(" , ")}
                                                                    </div>
                                                                </div>
                                                                <div className='row mt-5'>
                                                                    <div className='col-md-2'>
                                                                        <label className="fw-bold mb-2">Dormitory</label>
                                                                    </div>
                                                                    <div className='col-md-5'>
                                                                        {dormitoryNameArray.join(" , ")}
                                                                    </div>
                                                                </div>

                                                                <div className="row mt-5">
                                                                    <div className="col-md-2">
                                                                        <label className='fw-bold mb-2'>{adhoc_leave_form.weekends.label}</label>
                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        <div className='d-flex gap-6 align-items-center align-check-box flex-wrap'>
                                                                            {days.map((day) => {
                                                                                return (
                                                                                    <div className="form-check">
                                                                                        <input className="form-check-input" type="checkbox" value={day.value} checked={day.value} id={`flex${day.label}`} onChange={(e) => this.weekDayChangeHandler(e, "weekends", day)} />
                                                                                        <label className="form-check-label" for={`flex${day.label}`}>
                                                                                            {day.label}
                                                                                        </label>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                            <div className="form-check">
                                                                                <input className="form-check-input" type="checkbox" value={select_all_days} checked={select_all_days} id="Select All" onChange={(e) => this.selectAllDays(e, "SelectAll")} />
                                                                                <label className="form-check-label" for="Select All">Select All</label>
                                                                            </div>
                                                                        </div>
                                                                        {!adhoc_leave_form.weekends.valid && adhoc_leave_form.weekends.onBlur_out ? <div className="error field-error">{adhoc_leave_form.weekends.error_msg}</div> : ""}
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-5 gap-y-5">
                                                                    <div className="col-md-6">
                                                                        <label className='fw-bold mb-2'>{adhoc_leave_form.start_time.label}</label>
                                                                        <div className='position-relative adhoc_leave_form_datePicker'>
                                                                            <span className='position-absolute apply_adhoc_leave_date_and_time'>
                                                                                <i class="far fa-clock"></i>
                                                                            </span>
                                                                            {/* <input
                                                                                id="session-date"
                                                                                type={adhoc_leave_form.start_time.type}
                                                                                className="form-control bg-transparent"
                                                                                placeholder={adhoc_leave_form.start_time.placeholder}
                                                                                value={adhoc_leave_form.start_time.value !== "" ? adhoc_leave_form.start_time.value : ""}
                                                                                onChange={(event) => this.propgramTimeHandler(event, "start_time")}
                                                                                autoComplete="off"
                                                                                required
                                                                            /> */}
                                                                            <DatePicker
                                                                                wrapperClassName="adhoc_leave_form_datePicker_width"
                                                                                className="form-control bg-transparent w-100"
                                                                                selected={adhoc_leave_form.start_time.value !== "" ? adhoc_leave_form.start_time.value : ""}
                                                                                onChange={(event) => this.propgramTimeHandler(event, "start_time")}
                                                                                showTimeSelect
                                                                                showTimeSelectOnly
                                                                                timeIntervals={15}
                                                                                timeCaption="Time"
                                                                                dateFormat="h:mm aa"
                                                                                placeholderText={adhoc_leave_form.start_time.placeholder}

                                                                            />
                                                                        </div>
                                                                        {!adhoc_leave_form.start_time.valid && adhoc_leave_form.start_time.onBlur_out ? <div className="error field-error">{adhoc_leave_form.start_time.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-md-6">
                                                                        <label className='fw-bold mb-2'>{adhoc_leave_form.end_time.label}</label>
                                                                        <div className='position-relative adhoc_leave_form_datePicker' >
                                                                            <span className='position-absolute apply_adhoc_leave_date_and_time'>
                                                                                <i class="far fa-clock"></i>
                                                                            </span>
                                                                            {/* <input
                                                                                id="session-date"
                                                                                type={adhoc_leave_form.end_time.type}
                                                                                className="form-control bg-transparent"
                                                                                placeholder={adhoc_leave_form.end_time.placeholder}
                                                                                value={adhoc_leave_form.end_time.value !== "" ? adhoc_leave_form.end_time.value : ""}
                                                                                onChange={(event) => this.propgramTimeHandler(event, "end_time")}
                                                                                autoComplete="off"
                                                                                required
                                                                            /> */}
                                                                            <DatePicker
                                                                                wrapperClassName="adhoc_leave_form_datePicker_width"
                                                                                className="form-control bg-transparent w-100"
                                                                                selected={adhoc_leave_form.end_time.value !== "" ? adhoc_leave_form.end_time.value : ""}
                                                                                onChange={(event) => this.propgramTimeHandler(event, "end_time")}
                                                                                showTimeSelect
                                                                                showTimeSelectOnly
                                                                                timeIntervals={15}
                                                                                timeCaption="Time"
                                                                                dateFormat="h:mm aa"
                                                                                placeholderText={adhoc_leave_form.end_time.placeholder}

                                                                            />
                                                                        </div>
                                                                        {!adhoc_leave_form.end_time.valid && adhoc_leave_form.end_time.onBlur_out ? <div className="error field-error">{adhoc_leave_form.end_time.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-md-6">
                                                                        <label className="fw-bold mb-2">{adhoc_leave_form.departure_travel_mode_id.label}</label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={adhoc_leave_form.departure_travel_mode_id.options}
                                                                            value={adhoc_leave_form.departure_travel_mode_id.options.filter(function (option) {
                                                                                return option.value === adhoc_leave_form.departure_travel_mode_id.value;
                                                                            })}
                                                                            onChange={(newValue) => this.handleSelectChange(newValue, "departure_travel_mode_id")}
                                                                            autoComplete="off"
                                                                        />
                                                                        {!adhoc_leave_form.departure_travel_mode_id.valid && adhoc_leave_form.departure_travel_mode_id.onBlur_out ? <div className="error field-error">{adhoc_leave_form.departure_travel_mode_id.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-md-6">
                                                                        <label className="fw-bold mb-2">{adhoc_leave_form.arrival_travel_mode_id.label}</label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={adhoc_leave_form.arrival_travel_mode_id.options}
                                                                            value={adhoc_leave_form.arrival_travel_mode_id.options.filter(function (option) {
                                                                                return option.value === adhoc_leave_form.arrival_travel_mode_id.value;
                                                                            })}
                                                                            onChange={(newValue) => this.handleSelectChange(newValue, "arrival_travel_mode_id")}
                                                                            autoComplete="off"
                                                                        />
                                                                        {!adhoc_leave_form.arrival_travel_mode_id.valid && adhoc_leave_form.arrival_travel_mode_id.onBlur_out ? <div className="error field-error">{adhoc_leave_form.arrival_travel_mode_id.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-md-6">
                                                                        <label className='fw-bold mb-2'>{adhoc_leave_form.activity_id.label}</label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={adhoc_leave_form.activity_id.options}
                                                                            value={adhoc_leave_form.activity_id.options.filter(function (option) {
                                                                                return option.value === adhoc_leave_form.activity_id.value;
                                                                            })}
                                                                            onChange={(newValue) => this.handleSelectChange(newValue, "activity_id")}
                                                                        />
                                                                        {!adhoc_leave_form.activity_id.valid && adhoc_leave_form.activity_id.onBlur_out ? <div className="error field-error">{adhoc_leave_form.activity_id.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-md-6">
                                                                        <label className='fw-bold mb-2'>{adhoc_leave_form.venue.label}</label>
                                                                        <input
                                                                            type={adhoc_leave_form.venue.type}
                                                                            value={adhoc_leave_form.venue.value ? adhoc_leave_form.venue.value : ""}
                                                                            className="form-control"
                                                                            placeholder={adhoc_leave_form.venue.placeholder}
                                                                            onChange={(event) => this.inputChangeHandler(event, "venue")}
                                                                            autoComplete="on"
                                                                        />
                                                                        {!adhoc_leave_form.venue.valid && adhoc_leave_form.venue.onBlur_out ? <div className="error field-error">{adhoc_leave_form.venue.error_msg}</div> : ""}

                                                                    </div>
                                                                    
                                                                    <div className="col-md-6">
                                                                        <label className='fw-bold mb-2'>{adhoc_leave_form.departure_date.label}</label>
                                                                        <div className='position-relative'>
                                                                            <span className='position-absolute apply_adhoc_leave_date_and_time' onClick={() => document.getElementById('session-date').click()}>
                                                                                <i class="far fa-calendar-alt"></i>
                                                                            </span>
                                                                            <input
                                                                                id="session-date"
                                                                                type={adhoc_leave_form.departure_date.type}
                                                                                className="form-control bg-transparent"
                                                                                placeholder={adhoc_leave_form.departure_date.placeholder}
                                                                                value={adhoc_leave_form.departure_date.value !== "" ? adhoc_leave_form.departure_date.value : ""}
                                                                                onChange={(event) => this.propgramDateHandler(event, "departure_date")}
                                                                                autoComplete="off"
                                                                                min={this.getCurrentDate()}
                                                                                required
                                                                            />
                                                                        </div>
                                                                        {!adhoc_leave_form.departure_date.valid && adhoc_leave_form.departure_date.onBlur_out ? <div className="error field-error">{adhoc_leave_form.departure_date.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-md-6">
                                                                        <label className='fw-bold mb-2'>{adhoc_leave_form.arrival_date.label}</label>
                                                                        <div className='position-relative'>
                                                                            <span className='position-absolute apply_adhoc_leave_date_and_time'>
                                                                                <i class="far fa-calendar-alt mt-2px"></i>
                                                                            </span>
                                                                            <input
                                                                                list="times"
                                                                                id="session-date"
                                                                                required
                                                                                type={adhoc_leave_form.arrival_date.type}
                                                                                className="form-control bg-transparent"
                                                                                placeholder={adhoc_leave_form.arrival_date.placeholder}
                                                                                value={adhoc_leave_form.arrival_date.value !== "" ? adhoc_leave_form.arrival_date.value : ""}
                                                                                onChange={(event) => this.propgramDateHandler(event, "arrival_date")}
                                                                                autoComplete="off"
                                                                                min={adhoc_leave_form.departure_date.value}
                                                                            />

                                                                        </div>
                                                                        {!adhoc_leave_form.arrival_date.valid && adhoc_leave_form.arrival_date.onBlur_out ? <div className="error field-error">{adhoc_leave_form.arrival_date.error_msg}</div> : ""}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className='col-md-6'>
                                                                        <div className="mt-5">
                                                                            <label className='fw-bold mb-2'>{adhoc_leave_form.parents_inst.label}</label>
                                                                            <textarea
                                                                                className="form-control"
                                                                                placeholder={adhoc_leave_form.parents_inst.placeholder}
                                                                                id="floatingTextarea2"
                                                                                value={adhoc_leave_form.parents_inst.value !== "" ? adhoc_leave_form.parents_inst.value : ""}
                                                                                onChange={(event) => this.inputChangeHandler(event, "parents_inst")}
                                                                            >
                                                                            </textarea>
                                                                        </div>
                                                                    </div>

                                                                    <div className='col-md-6'>
                                                                        <div className="mt-5">
                                                                            <label className='fw-bold mb-2'>{adhoc_leave_form.manager_inst.label}</label>
                                                                            <textarea
                                                                                className="form-control"
                                                                                placeholder={adhoc_leave_form.manager_inst.placeholder}
                                                                                id="floatingTextarea2"
                                                                                value={adhoc_leave_form.manager_inst.value !== "" ? adhoc_leave_form.manager_inst.value : ""}
                                                                                onChange={(event) => this.inputChangeHandler(event, "manager_inst")}
                                                                            >
                                                                            </textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='d-flex align-items-right me-10 mb-10'>
                                                                <button type='button' className='btn btn-primary ms-auto w-125' onClick={(e) => this.showModal(e)}> Submit</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Footer />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {

    };
}

const mapDispatchToProps = {
    // onAddOrUpdateStudentHost: userActions.AddOrUpdateStudentHost,
    // onGetApprovedStudentHost: userActions.GetApprovedStudentHost,
    onGetAllActivity: userActions.GetAllActivity,
    onGetAllTravelModes: userActions.GetAllTravelModes,
    onCreateAdhocLeave: userActions.CreateAdhocLeave,

};
export default connect(mapStateToProps, mapDispatchToProps)(withParams(ApplyAdhocLeave));

