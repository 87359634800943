import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";
import * as userActions from '../../../actions/index';
import Select from 'react-select';
import Validations from "../../Utility/Validations";
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import { useDispatch, connect } from "react-redux";
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: state.isFocused ? "#e7f5fe" : "",
    },
    backgroundColor: state.isSelected ? "#4a8fb8" : "",
  }),
};
const DepartureScheduleChangeModal = (props) => {
  console.log("props==============", props.archiveLeaveData);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [planned_depart_mode_id, setPlannedDepartureModeId] = useState([]);
  const [edit_departure_form, setEditDepartureForm] = useState(
    {
      planned_depart_date: {
        type: "date",
        label: "Date",
        value: "",
        valid: true,
        error_msg: "",
        onBlur_out: false,
        placeholder: "",
        validations: { required: true },
      },
      planned_depart_time: {
        type: "time",
        label: "Time",
        value: "",
        valid: true,
        error_msg: "",
        onBlur_out: false,
        placeholder: "",
        validations: { required: true },
      },
      planned_depart_mode_id: {
        type: "text",
        label: "Mode Travel",
        value: "",
        valid: true,
        error_msg: "",
        onBlur_out: false,
        placeholder: "Host Relation",
        validations: { required: true },
        options: []
      },
      schedule_changed_reason: {
        type: "text",
        label: "Reason for Changed Leave Times",
        value: true,
        valid: true,
        error_msg: "",
        validations: { required: false },
      },
      depart_status: {
        type: "text",
        label: "Student Host Comment",
        value: "schedule_changed",
        valid: true,
        error_msg: "Student Host Comment",
        validations: { required: false },
      },
      border_leave_id: {
        type: "text",
        label: "Host's Mobile Number *",
        value: "",
        valid: true,
        error_msg: "",
        onBlur_out: false,
        placeholder: "Host's Mobile Number",
        validations: { required: true },
      },
      student_id: {
        type: "hidden",
        label: "student id",
        value: "",
        valid: true,
        error_msg: "",
        onBlur_out: false,
        placeholder: "student id",
        validations: { required: false },
      },
      border_leave_depart_stu_id: {
        type: "hidden",
        label: "student id",
        value: "",
        valid: true,
        error_msg: "",
        onBlur_out: false,
        placeholder: "departure student id",
        validations: { required: false },
      },
    }
  )

  useState(() => {
    setShowModal(props.showChangeShedule)
    let hours = parseInt(props.archiveLeaveData.departure_students_data.planned_depart_time.split(':')[0], 10)
    let minute = parseInt(props.archiveLeaveData.departure_students_data.planned_depart_time.split(':')[1], 10)
    const update_edit_departure_form = { ...edit_departure_form }
    console.log("props=========ggggg",props.archiveLeaveData.departure_date);
    update_edit_departure_form['planned_depart_time'].value = moment().hour(hours).minute(minute);
    update_edit_departure_form['planned_depart_date'].value = props.archiveLeaveData.departure_students_data.planned_depart_date;
    update_edit_departure_form['planned_depart_mode_id'].value = props.archiveLeaveData.departure_students_data.planned_depart_mode_id;
    update_edit_departure_form['border_leave_id'].value = props.archiveLeaveData.border_leave_id;
    update_edit_departure_form['student_id'].value = props.archiveLeaveData.student_id;
    update_edit_departure_form['border_leave_depart_stu_id'].value = props.archiveLeaveData.departure_students_data.border_leave_depart_stu_id;
    setEditDepartureForm(update_edit_departure_form)
  }, [props])

  const closeModal = () => {
    props.hideDepartStuLeaveChangeModal(false)
  };

  function getCurrentDate() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(currentDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }


  const inputChangeHandler = (e, identifier) => {
    const update_departure_form = { ...edit_departure_form };
    if (identifier === "planned_depart_time") {
      update_departure_form[identifier].value = moment(e._d).format("HH:mm");
      console.log("moment(e._d).format",moment(e._d).format("HH:mm"));
    } else {
      update_departure_form[identifier].value = e.target.value;
    }
    setEditDepartureForm(update_departure_form)
  }

  const handleSelectChange = (data, identifier, type) => {
    const update_departure_form = { ...edit_departure_form };
    update_departure_form[identifier].value = data.value;
    setEditDepartureForm(update_departure_form)
  }

  const SubmitHandler = (event) => {
    event.preventDefault();
    let is_form_valid = true;
    const update_departure_form = { ...edit_departure_form };
    for (let key in update_departure_form) {
      let form_element = update_departure_form[key];
      let validate_element = Validations(form_element);
      is_form_valid = validate_element.valid === false ? false : is_form_valid;
      form_element.onBlur_out = true;
      form_element.valid = validate_element.valid;
      form_element.error_msg = validate_element.error_msg;
      update_departure_form[key] = form_element;
    }
    setEditDepartureForm(update_departure_form)
    if (is_form_valid) {
      const form_data = {};
      for (let key in edit_departure_form) {
        if (key === "planned_depart_time") {
          if (typeof (edit_departure_form[key].value === "object")) {
            form_data[key] = edit_departure_form[key].value;
          } else {
            form_data[key] = edit_departure_form[key].value;
          }
        } else {
          form_data[key] = edit_departure_form[key].value;
        }
      }
      props.DepartStuLeaveChange(form_data)
    }
  }


  useEffect(() => {
    GetAllTravelModes()
  }, []);
  const GetAllTravelModes = async () => {
    props.onGetAllTravelModes().then((response) => {
      if (response.success === true) {
        const arrayData = response.data.rows;
        const state_data = []
        state_data.push({
          label: "Modes Of Travel",
          value: "",
        })
        for (let key in arrayData) {
          state_data.push({
            label: arrayData[key].travel_mode_name,
            value: arrayData[key].travel_mode_id,
          })
        }
        setPlannedDepartureModeId(state_data);
        const update_departure_form = { ...edit_departure_form }
        update_departure_form['planned_depart_mode_id'].options = state_data
        setPlannedDepartureModeId(update_departure_form)
      }
    })
  };


  return (
    <div>
      <Modal size="md" show={showModal} onHide={closeModal}>
        <form onSubmit={(e) => SubmitHandler(e)}>
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Departure On Different Date/Time
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid">
            <div className="mb-3">
              <label className='form-label'>{edit_departure_form.planned_depart_date.label}</label>
              <div className='position-relative'>
                <span className='position-absolute apply_adhoc_leave_date_and_time' onClick={() => document.getElementById('session-date').click()}>
                  <i class="far fa-calendar-alt"></i>
                </span>
                <input
                  id="session-date"
                  type={edit_departure_form.planned_depart_date.type}
                  className="form-control bg-transparent"
                  placeholder={edit_departure_form.planned_depart_date.placeholder}
                  value={edit_departure_form.planned_depart_date.value}
                  onChange={(event) => inputChangeHandler(event, "planned_depart_date")}
                  autoComplete="off"
                  required
                  min={getCurrentDate()}
                />
              </div>
              {!edit_departure_form.planned_depart_date.valid && edit_departure_form.planned_depart_date.onBlur_out ? <div className="error field-error">{edit_departure_form.planned_depart_date.error_msg}</div> : ""}
            </div>


            <div className="mb-3">
              <label className='form-label'>{edit_departure_form.planned_depart_time.label}</label>
              <div className='position-relative adhoc_leave_form_datePicker'>
                <span className='position-absolute apply_adhoc_leave_date_and_time'>
                  <i class="far fa-clock"></i>
                </span>
                <TimePicker
                  showSecond={false}
                  minuteStep={5}
                  use12Hours={true}
                  defaultValue={edit_departure_form.planned_depart_time.value}
                  className="leave_time_picker w-100 bg-transparent"
                  popupClassName="popupClassName"
                  inputReadOnly={true}
                  onChange={(event) => inputChangeHandler(event, "planned_depart_time")}
                />
              </div>
              {/* <input
                type={edit_departure_form.planned_depart_time.type}
                className="form-control"
                placeholder={edit_departure_form.planned_depart_time.placeholder}
                value={edit_departure_form.planned_depart_time.value}
                onChange={(event) => inputChangeHandler(event, "planned_depart_time")}
                autoComplete="off"
              /> */}
              {!edit_departure_form.planned_depart_time.valid && edit_departure_form.planned_depart_time.onBlur_out ? <div className="error field-error">{edit_departure_form.planned_depart_time.error_msg}</div> : ""}
            </div>


            <div className="mb-3">
              <label className='form-label'>{edit_departure_form.planned_depart_mode_id.label}</label>
              <Select
                styles={customStyles}
                className="react-bootstrap-typeahead tag-outer"
                options={edit_departure_form.planned_depart_mode_id.options}
                value={edit_departure_form.planned_depart_mode_id.options.filter(function (option) {
                  return option.value === edit_departure_form.planned_depart_mode_id.value;
                })}
                onChange={(newValue) => handleSelectChange(newValue, "planned_depart_mode_id")}
                autoComplete="off"
              />
              {!edit_departure_form.planned_depart_mode_id.valid && edit_departure_form.planned_depart_mode_id.onBlur_out ? <div className="error field-error">{edit_departure_form.planned_depart_mode_id.error_msg}</div> : ""}
            </div>


            <div className="mb-3">
              <label className='form-label'>{edit_departure_form.schedule_changed_reason.label}</label>
              <textarea
                className="form-control h-150"
                placeholder={edit_departure_form.schedule_changed_reason.placeholder}
                id="floatingTextarea2"
                onChange={(event) => inputChangeHandler(event, "schedule_changed_reason")}
              ></textarea>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary btn-sm" onClick={closeModal}>
              Close
            </button>
            <button class="btn btn-primary btn-sm" type="submit">
              Save Changes
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  )
}
function mapStateToProps(state) {
  return {

  };
}

const mapDispatchToProps = {
  onGetAllTravelModes: userActions.GetAllTravelModes
};
export default connect(mapStateToProps, mapDispatchToProps)(DepartureScheduleChangeModal);
