
import React, { useState ,useEffect } from "react";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updateBooleanValue } from "../../../reducers/Snapshot";

const ReturnApprovalModal = (props) => {
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false);
  const [leaveData, setLeaveData] = useState({})

  useEffect(() => {
    setShowModal(props.showReturnStudent)
    setLeaveData(props.returnLeaveData)
  }, [props])
  const closeModal = () => {
    props.hideReturnStudentModal(false);
  };

  const handleSubmit = () => {
    const form_data = {
      border_leave_id: leaveData.border_leave_id,
      return_status: 'returned',
      border_leave_return_stu_id: leaveData?.return_students_data?.border_leave_return_stu_id,
      student_id: leaveData.student_id
    }
    dispatch(updateBooleanValue(true))
    props.ReturnStuLeaveChange(form_data);
  };
  return (
    <>
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Return Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">Are you sure you want to Return this student?</Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="secondary" className="btn-sm" onClick={closeModal}>
            Cancel
          </Button>
          <Button variant="primary" className="px-10 btn-sm" onClick={handleSubmit}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ReturnApprovalModal;
