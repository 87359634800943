import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomLink from "../component/Settings/CustomLink/CustomLink";
import CustomLinkAdd from "../component/Settings/CustomLink/CustomLinkAdd";
import CustomLinkEdit from "../component/Settings/CustomLink/CustomLinkEdit";

const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" :user_info?.school_code;


const CustomLinks = (props) => {
    const Renderview = () => {
        let location = useLocation();
        let userId = useParams();

        if (location.pathname === `/${school_code}/settings/custom-link` || location.pathname === `/${school_code}/settings/custom-link/`) {
            return <CustomLink />;
        }
        if (location.pathname === `/${school_code}/settings/custom-link/edit-custom-link/${userId.id}` || location.pathname === `/${school_code}/settings/custom-link/edit-custom-link/${userId.id}/`) {
            return <CustomLinkEdit  custom_link_uuid={userId.id} /> ;
        }
        if (location.pathname === `/${school_code}/settings/custom-link/add-custom-link` || location.pathname === `/${school_code}/settings/custom-link/add-custom-link/`) {
            return <CustomLinkAdd /> ;
        }
        
    };

    return (
        <React.Fragment>
            <ToastContainer />
            {Renderview()}
        </React.Fragment>
    );
};
export default CustomLinks;