import React from "react";

import Validations from "../Utility/Validations";
import Sidebar from "../Constant/sidebar";
import Footer from "../Constant/Footer";
import TopBar from "../Constant/TopBar";
// import Select from "react-select";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

import { toast } from "react-toastify";
import * as userActions from "../../actions/index";
import { Tooltip } from "@mui/material";

// import * as actionType from "../../constants/actionTypes";

const permission_info = JSON.parse(localStorage.getItem("permissions"));
const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info.school_code;

class ClassEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            class_edit_form: {
                class_name: {
                    type: "select",
                    label: "Select Year *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "City",
                    validations: { required: true },
                    options: [
                        { label: "Select Year", value: "" },
                        { label: "1st", value: "1st" },
                        { label: "2nd", value: "2nd" },
                        { label: "3rd", value: "3rd" },
                        { label: "4th", value: "4th" },
                        { label: "5th", value: "5th" },
                        { label: "6th", value: "6th" },
                        { label: "7th", value: "7th" },
                        { label: "8th", value: "8th" },
                        { label: "9th", value: "9th" },
                        { label: "10th", value: "10th" },
                        { label: "11th", value: "11th" },
                        { label: "12th", value: "12th" },
                    ],
                },

                class_code: {
                    type: "text",
                    label: "Year Code *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true , max_length: 3},
                },
                class_desc: {
                    type: "text",
                    label: "Year Description *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true , max_length:  8 },
                },

                class_uuid: {
                    type: "text",
                    label: "class_uuid",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },
                class_no: {
                    type: "text",
                    label: "class_no",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },

            },
        };
    }


    GetLoginUserPermissions() {
        this.props.onGetLoginUserPermissions().then((response) => {
            if (response.success === true) {
            } else {
            }
        });
    }
    componentDidMount() {
        this.GetLoginUserPermissions();
        this.GetClassDetailsById();

    }

    GetClassDetailsById() {
        this.props.onGetClassDetailsById(this.props.class_uuid).then((response) => {
            if (response.success === true) {
                let class_data = response.data;
                console.log("class_data============", class_data);
                const update_class_edit_form = { ...this.state.class_edit_form };
                for (let key in update_class_edit_form) {
                    let form_element = update_class_edit_form[key];
                    if (class_data[key]) {

                        update_class_edit_form['class_code'].value = class_data.class_code;
                        update_class_edit_form['class_name'].value = class_data.class_name;
                        update_class_edit_form['class_desc'].value = class_data.class_desc;
                        update_class_edit_form['class_uuid'].value = class_data.class_uuid;
                        update_class_edit_form['class_no'].value = class_data.class_no;

                        this.setState({ class_edit_form: update_class_edit_form });
                        update_class_edit_form[key] = form_element;
                    }
                }
                this.setState({ class_edit_form: update_class_edit_form });
            } else {
            }
        });

    }
    inputChangeHandler(event, identifier) {
        const update_class_edit_form = { ...this.state.class_edit_form };
        const form_element = { ...update_class_edit_form[identifier] };
        form_element.value = event.target.value;
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_class_edit_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_class_edit_form) {
            is_form_valid = update_class_edit_form[identifier].valid && is_form_valid;
        }
        this.setState({
            class_edit_form: update_class_edit_form,
            is_form_valid: is_form_valid,
        });
    }

    handleChange(data, identifier, type) {
        const update_class_edit_form = { ...this.state.class_edit_form };
        update_class_edit_form[identifier].value = data.value;
        this.setState({ class_edit_form: update_class_edit_form });
    }

    SubmitHandler = async (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_class_edit_form = this.state.class_edit_form;
        for (let key in update_class_edit_form) {
            let form_element = update_class_edit_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_class_edit_form[key] = form_element;
        }
        this.setState({ class_edit_form: update_class_edit_form });
        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.class_edit_form) {
                form_data[key] = this.state.class_edit_form[key].value;
            }

            this.props.onUpdateClass(form_data).then((response) => {
                if (response.success === true) {
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: `/${school_code}/class-list`,
                        });
                    }, 1000);
                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            });
        }

    };

    render() {
        const { class_edit_form } = this.state;

        return (
            <React.Fragment>
                {this.state.isAuthenticated ? <Navigate to={this.state.redirect_url} /> : null}
                <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed demo h-100">
                    <div className="d-flex flex-column flex-root h-100">
                        <div className="page d-flex flex-row flex-column-fluid">
                            <Sidebar></Sidebar>

                            {/* <LeftSideBar></LeftSideBar> */}

                            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                                {/* <Topbar></Topbar> */}

                                <TopBar></TopBar>
                                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                    <div className="post d-flex flex-column-fluid" id="kt_post">
                                        <div id="kt_content_container" className="container-fluid">
                                            <div className="school-edit-form ">
                                                <div className="" id="Snapshot">
                                                    <form onSubmit={this.SubmitHandler}>
                                                        <div className="card mb-5 tabs-outer">
                                                            <div className="card-header position-relative py-0 details-bg">
                                                                <h2 className="card-title  m-0">Edit Year</h2>
                                                            </div>

                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-lg-6 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{class_edit_form.class_name.label}</label>
                                                                        <Tooltip title="This is to accommodate your school systems name conventions:- 
So here you merely count how many years this cohort has been attending school.  Also you ONLY add a “Year Code” and “Year Description *” for the applicable Year Levels you have in Boarding" placement="top">
                                                                            <i className="fas fa-question-circle ms-2"></i>
                                                                        </Tooltip>
                                                                        <input type="text" name="fname" className="form-control " readOnly
                                                                            value={class_edit_form.class_name.value ? class_edit_form.class_name.value : ""}

                                                                        />

                                                                        {!class_edit_form.class_name.valid && class_edit_form.class_name.onBlur_out ? <div className="error field-error">{class_edit_form.class_name.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-6 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{class_edit_form.class_code.label} </label>
                                                                        <Tooltip title="This is just an abbreviation of “Year Description *” which may be used in interfaces which do not have sufficient space.
You might choose for “Year 10” to read “Y10” or “Form 6” to read “F6”" placement="top">
                                                                            <i className="fas fa-question-circle ms-2"></i>
                                                                        </Tooltip>
                                                                        <input
                                                                            type={class_edit_form.class_code.type}
                                                                            value={class_edit_form.class_code.value ? class_edit_form.class_code.value : ""}
                                                                            className="form-control"
                                                                            placeholder={class_edit_form.class_code.placeholder}
                                                                            onChange={(event) => this.inputChangeHandler(event, "class_code")}
                                                                        />

                                                                        {!class_edit_form.class_code.valid && class_edit_form.class_code.onBlur_out ? <div className="error field-error">{class_edit_form.class_code.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-12 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{class_edit_form.class_desc.label}</label>
                                                                        <Tooltip title="This is the typical or normal name for the cohort that your school uses on a daily basis. ie “Form 6” or “Std 10” or “Year 12”" placement="top">
                                                                            <i className="fas fa-question-circle ms-2"></i>
                                                                        </Tooltip>
                                                                        <textarea
                                                                            type={class_edit_form.class_desc.type}
                                                                            value={class_edit_form.class_desc.value ? class_edit_form.class_desc.value : ""}
                                                                            className="form-control autoresizing"
                                                                            placeholder={class_edit_form.class_desc.placeholder}
                                                                            onChange={(event) => this.inputChangeHandler(event, "class_desc")}
                                                                        />

                                                                        {!class_edit_form.class_desc.valid && class_edit_form.class_desc.onBlur_out ? <div className="error field-error">{class_edit_form.class_desc.error_msg}</div> : ""}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-12 d-flex justify-content-end">
                                                            <div className="d-flex align-items-end">
                                                                <button type="submit" className="btn btn-sm btn-primary apply-btn min-150px addschool-button">
                                                                    Update Year
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Footer />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}

const mapDispatchToProps = {
    onUpdateClass: userActions.UpdateClass,
    onGetClassDetailsById: userActions.GetClassDetailsById,
    onGetLoginUserPermissions: userActions.GetLoginUserPermissions,
};
export default connect(mapStateToProps, mapDispatchToProps)(ClassEdit);

