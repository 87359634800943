import React, { useState, useEffect, useRef } from "react";


const Timer = (props) => {
    const intervalRef = useRef()
    const { initMinute = 0, initSeconds = 30, SubmitHandler } = props
    const [minutes, setMinutes] = useState(initMinute)
    const [seconds, setSeconds] = useState(initSeconds)


    useEffect(() => {
        if(!window.location.pathname){
            localStorage.setItem("second_val",seconds)
        };
        if(window.location.pathname){
            if(localStorage.getItem("second_val")){
                setSeconds(localStorage.getItem("second_val"))
            }
        }
        // let myInterval = 
        intervalRef.current = setInterval(() => {
            if(minutes === 0 && seconds === 0) {
                clearInterval(intervalRef.current)
                if (SubmitHandler){
                    SubmitHandler()
                }
            }
               else if (seconds > 0) {
                    setSeconds((prevSecond) => prevSecond - 1);
                } else {
                // if (seconds === 0) {
                  
                    if (minutes === 0) {
                        clearInterval(intervalRef.current);
                       
                    } else {
                        setMinutes((prevMinutes) => prevMinutes - 1);
                        setSeconds(59)
                        
                    }
                }
            }, 1000)
       
        return () => {
            clearInterval(intervalRef.current)
        }
    }, [minutes, seconds, ])

    

    console.log("seconds", seconds)

    return (
       
        <h1 className="me-4 mt-1">
        {minutes < 10 ? `0${minutes}` : minutes}:
         {seconds < 10 ? `0${seconds}` : seconds}
        </h1>
    )
}
export default Timer;