import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
const ChangeDormitoryItem = (props) => {
  
    const [perpagedata, setperpagedata] = useState(10);
    // const [isChecked , setisChecked] = useState(true);
    const [columns] = useState([
        {
            text: "",
            dataField: "Actions",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <div key={"repeat_" + row.country_id}>
                        {console.log("row_val============",row)}
                        <div className="card-1 p-6 mb-4 rounded bg-light-info">
                            <div className="d-flex flex-stack mb-3">
                                <div className="d-flex align-items-center">
                                    <input type="checkbox"
                                        className="me-4 big-checkbox"
                                        checked={row.checked}
                                        onClick={(event) => {
                                            props.handleClick(event,index,row);
                                        }}
                                    ></input>
                                    <h3 className="mb-0 text-primary superadmin-headings link-color  fw-bold">
                                        {row.student_first_name + " " + row.student_last_name}
                                    </h3>
                                </div>
                                <div className="d-flex align-items-center">
                                    {row.is_student_activate === true ? (
                                        <span className="badge badge-light-success px-4 py-2">Active</span>
                                    ) : (
                                        <span className="badge badge-light-danger px-4 py-2">Inactive</span>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex text-secondary align-items-center">
                                <div className="d-flex align-items-center">
                                    <Tooltip title="Student unique pin" placement="top">
                                                <i className="fas fa-list-ol me-2 fs-6"></i>
                                    </Tooltip>
                                    <p className="mb-0 heding-element me-4 ">{row.unique_pin} </p>
                                </div>
                           
                                <div className="d-flex align-items-center">
                    
                                    <Tooltip title="Student year" placement="top">
                                    <i className="fas fa-chalkboard-teacher me-2 fs-6"></i>
                                    </Tooltip>
                                    <p className="mb-0 heding-element me-4">{row.class_name} </p>
                                </div>

                                <div className="d-flex align-items-center">
                                <Tooltip title="Gender" placement="top">
                                <i className="fas fa-venus-mars me-2 fs-6"></i>
                                    </Tooltip>
                                  
                                    <p className="mb-0 heding-element me-4">{row.gender} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            },
        },
    ]);
    const handleTableChange = (type, { sortField, sortOrder, page }) => {
        if (type === "sort") {
            props.propsfiltersort(sortField);
            props.propsfilterorder(sortOrder);
        }
        if (type === "pagination") {
            props.props(page);
        }
    };
    const RemotePagination = ({ columns, dataField, order, data, page, sizePerPage, onTableChange, totalSize }) => (
        <PaginationProvider
            pagination={paginationFactory({
                custom: true,
                page,
                sizePerPage,
                totalSize,
            })}
        >
            {({ paginationProps, paginationTableProps }) => (
                <>
                    <div className="table-responsive common-table superadmin_table">
                        <BootstrapTable
                            remote
                            bootstrap4
                            data={data}
                            keyField="student_uuid"
                            sort={{ dataField: dataField, order: order }}
                            columns={columns}
                            {...paginationTableProps}
                            onTableChange={onTableChange}
                            sizePerPageList={sizePerPage}
                            headerClasses="fw-bolder text-white bg-dark-row"
                            classes="table-bordered align-middle custom-table-style table-fields"
                            noDataIndication={() => <span className="text-center text-gray-400">No records found</span>}
                        />
                    </div>
                    <div className="d-flex flex-stack justify-content-between flex-wrap pt-3 pagination-main">
                        {totalSize > 0 && (
                            <div className="pagination_limt">
                                <select id='select' name='group' className='form-control input-sm form-select form-select-sm form-select-solid' size='1' value={perpagedata}
                                    onChange={(event) => {
                                        props.propsfilterpage(1);
                                        setperpagedata(event.target.value);
                                        props.propsfilterlimit(parseInt(event.target.value));
                                    }}
                                >
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={250}>250</option>
                                    <option value={500}>500</option>
                                </select>
                                {(parseInt(sizePerPage) * parseInt(page)) > totalSize ? (
                                    <div className="count_number">Displaying {(parseInt(sizePerPage) * parseInt(page) + parseInt(1)) - (parseInt(perpagedata))}-{totalSize} of {totalSize} records</div>
                                ) : (
                                    <div className="count_number">Displaying {(parseInt(sizePerPage) * parseInt(page) + parseInt(1)) - (parseInt(perpagedata))}-{parseInt(sizePerPage) * parseInt(page)} of {totalSize} records</div>
                                )}
                            </div>
                        )}
                        <div className="pagination_page">
                            <PaginationListStandalone {...paginationProps} />
                        </div>
                    </div>
                </>
            )}
        </PaginationProvider>
    );
    return (
        <React.Fragment>
            <div className="row gy-5 g-xl-8">
                {/* <label>Select All <input type="checkbox" className="big-checkbox"
           
             ></input></label> */}
                <div className="col-xl-12">
                  
                            <div className="table-responsive">
                                <div id="kt_profile_overview_table" className="dataTables_wrapper dt-bootstrap4 no-footer team-list-table">
                                    <div className="table-responsive">
                                        <div className="card-body p-0 bg-white radious-style radious-users  mt-5">
                                            {/* <Spin spinning={props.loading}> */}
                                            <RemotePagination
                                                data={props.data}
                                                columns={columns}
                                                page={props.filterpage}
                                                totalSize={props.ChangeDormitorycount}
                                                sizePerPage={props.filterlimit}
                                                order={props.filterorder}
                                                onTableChange={handleTableChange}
                                            />
                                            {/* </Spin> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                      
                </div>
            </div>
        </React.Fragment>
    );
};
export default ChangeDormitoryItem;