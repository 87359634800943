import React from "react";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Validations from "../../component/Utility/Validations";
import * as userActions from "../../actions/index";
import moment from "moment";



const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info?.school_code;


class signin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            hiddenpassword: true,

            login_form: {
                email: {
                    type: "email",
                    //label: "Your email address",
                    value: "",
                    valid: true,
                    error_msg: "",
                    placeholder: "Enter your email address",
                    validations: { required: true, email: true },
                },
                password: {
                    type: "password",
                    //label: "Password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    placeholder: "Enter your password",
                    validations: { required: true, min_length: 8, password: true },
                },
                remember: {
                    valid: true,
                    error_msg: "",
                    type: "checkbox",
                    isChecked: false,
                    value: "remember",
                    label: "Keep me signed in",
                    validations: { required: false },
                },
            },
            is_form_valid: false,
            passwordshow: false,

            redirect_url: "",
            isAuthenticated: false,
        };
        this.toggleShow = this.toggleShow.bind(this);
    }

    toggleShow() {
        this.setState({ hiddenpassword: !this.state.hiddenpassword });
    }


    inputChangeHandler(event, identifier) {
        const update_login_form = { ...this.state.login_form };
        const form_element = { ...update_login_form[identifier] };
        if (identifier === "remember") {
            form_element.isChecked = event.target.checked;
        }
        //  else if (identifier === "email") {
        //     form_element.value = event.target.value.toLowerCase();
        // }
        else {
            form_element.value = event.target.value;
        }
        update_login_form[identifier] = form_element;
        this.setState({ login_form: update_login_form });

        clearTimeout(this.typingTimeout); // Clear the previous timeout

        this.typingTimeout = setTimeout(() => {
            // Check Validation
            let validate_element = Validations(form_element);
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_login_form[identifier] = form_element;
            let is_form_valid = true;
            for (let identifier in update_login_form) {
                is_form_valid = update_login_form[identifier].valid && is_form_valid;
            }


            this.setState({
                login_form: update_login_form,
                is_form_valid: is_form_valid,
            });
        }, 2000);
    }

    loginSubmitHandler = (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_login_form = this.state.login_form;
        update_login_form.email.value = update_login_form.email.value.toLowerCase();
        for (let key in update_login_form) {
            let form_element = update_login_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            // form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_login_form[key] = form_element;
        }
        this.setState({ login_form: update_login_form });

        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.login_form) {
                if (key === "remember") {
                    if (this.state.login_form[key].isChecked === false) {
                        break;
                    }
                }
                // if (key === "email") {
                //     console.log("email",this.state.login_form.email)
                //    let mail = this.state.login_form.email.value.toLowerCase();
                //    console.log("mail", mail)
                //    form_data["email"] = mail;
                // }
                form_data[key] = this.state.login_form[key].value;
            }
            this.props.onSignIn(form_data).then((response) => {
                if (response.success === true) {
                    console.log("form_data", response)

                    this.props.onGetLoginUserPermissions().then((response) => {
                        if (response.success === true) {
                            setTimeout(() => {
                                window.location.href = `${response.data.user_school.school_code}/dashboard`;
                            }, 500);
                        } else {
                        }
                    });



                    setTimeout(() => {
                        if (response.data.role_type === 1) {
                            window.location.href = "/superadmin/school";
                        } else{
                            if (response.data.user_school.subscriptionData.type === "demo") {
                                if (response.data.user_school.subscriptionData.end_date < moment(new Date()).format('YYYY-MM-DD')) {
                                    toast.error("Your trial period has concluded. Kindly get in touch with BSS support to purchase a subscription. ", {
                                        position: toast.POSITION.TOP_CENTER,
                                    });
                                } else {
                                    window.location.href = `${response.data.user_school.school_code}/dashboard`;
                                }
                            } else {
                                if(response.data.user_school.subscriptionData.end_date < moment(new Date()).format('YYYY-MM-DD')){
                                    toast.error("Your subscription has expired. Please contact BSS support to renew and extend your subscription.", {
                                        position: toast.POSITION.TOP_CENTER,
                                    });
                                }else{
                                    window.location.href = `${response.data.user_school.school_code}/dashboard`;
                                }
                            }
                        }
                        // this.setState({
                        //     isAuthenticated: true,
                        //     redirect_url: "/dashboard",
                        // });
                    }, 500);
                    // this.props.onGetAuthUser().then((response) => {
                    //     if (response.success === true) {                           
                    //         setTimeout(() => {
                    //             window.location.href = "/dashboard";
                    //             // this.setState({
                    //             //     isAuthenticated: true,
                    //             //     redirect_url: "/dashboard",
                    //             // });
                    //         }, 500);
                    //     } else {
                    //         toast.error(response.message, {
                    //             position: toast.POSITION.TOP_CENTER,
                    //         });
                    //     }
                    // });
                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            });
        }
    };

    render() {
        const { login_form } = this.state;
        console.log('login_form=========', login_form)
        return (
            <React.Fragment>
                <ToastContainer />
                {this.state.isAuthenticated ? <Navigate to={this.state.redirect_url} /> : null}
                <div className="d-flex flex-column flex-root  h-100">
                    <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
                        <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
                            <Link to="/" className="mb-8">
                                {/* <img alt="Logo" src="/assets/media/logos/logo.png" className="h-100px" /> */}
                                <img alt="Logo" src="/assets/media/logos/TRIANGLE PNG (WOP) (2).png" style={{height: '130px'}} />
                            </Link>
                            <div className="w-lg-500px bg-body rounded shadow-sm p-8 p-lg-10 mx-auto">
                                <form className="form w-100" onSubmit={this.loginSubmitHandler}>
                                    <div className="text-center mb-10">
                                        <h1 className="text-dark mb-3">Log In</h1>
                                        <h6>(Boarding Admin & Boarding Staff)</h6>
                                    </div>
                                    <div className="fv-row mb-10">
                                        <label className="form-label fw-bolder text-dark">Email</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder={login_form.email.placeholder}
                                            value={login_form.email.value ? login_form.email.value : ""}
                                            onChange={(event) => this.inputChangeHandler(event, "email")}
                                        />
                                        {!login_form.email.valid ? <div className="error field-error">{login_form.email.error_msg}</div> : ""}
                                    </div>

                                    <div className="fv-row mb-10">
                                        <div className="d-flex flex-stack mb-2">
                                            <label className="form-label fw-bolder text-dark mb-0">Password</label>
                                        </div>
                                        <div className="position-relative">
                                            <input
                                                type={this.state.hiddenpassword ? 'password' : 'text'}
                                                className="form-control"
                                                placeholder={login_form.password.placeholder}
                                                value={login_form.password.value ? login_form.password.value : ""}
                                                onChange={(event) => this.inputChangeHandler(event, "password")}
                                            />
                                            <span onClick={this.toggleShow} className="btn btn-icon position-absolute translate-middle top-50 end-0" data-kt-password-meter-control="visibility">

                                                {this.state.hiddenpassword ? <i className="bi bi-eye-slash fs-2"></i> : <i className="bi bi-eye fs-2"></i>}

                                            </span>

                                        </div>

                                        {!login_form.password.valid ? <div className="error field-error">{login_form.password.error_msg}</div> : ""}
                                    </div>

                                    <div className="fv-row mb-10 text-end">
                                        <Link to="/forgot-password" className="fw-bolder">
                                            Forgot Password ?
                                        </Link>
                                    </div>
                                    <div className="text-center">
                                        <button type="submit" className="btn btn-lg btn-primary w-100">
                                            <span className="indicator-label">Login</span>
                                            <span className="indicator-progress">
                                                Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        loader: state.Auth.loader,
        error: state.Auth.error,
    };
}
const mapDispatchToProps = {
    onSignIn: userActions.SignIn,
    onGetLoginUserPermissions: userActions.GetLoginUserPermissions,
    // onGetAuthUser: userActions.GetAuthUser,
};
export default connect(mapStateToProps, mapDispatchToProps)(signin);