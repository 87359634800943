import axios from "axios";
import * as actionType from "../../constants/actionTypes";
let qs = require("qs");

/*** CreateLeaveRejectReason Action ***/
export const CreateLeaveRejectReasonStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.CreateLeaveRejectReason_START,
    };
};
export const CreateLeaveRejectReasonSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.CreateLeaveRejectReason_SUCCESS,
    };
};
export const CreateLeaveRejectReasonFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.CreateLeaveRejectReason_FAIL,
    };
};
export const CreateLeaveRejectReason = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    
    return (dispatch) => {
        dispatch(CreateLeaveRejectReasonStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/CreateLeaveRejectReason`,
        })
            .then(function (response) {
                dispatch(CreateLeaveRejectReasonSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateLeaveRejectReasonFail("Something went wrong, Please try again."));
                return error;
            });
    };
};




/*** GetAllLeaveRejectReasons Action ***/

export const GetAllLeaveRejectReasonsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllLeaveRejectReasons_START,
    };
};
export const GetAllLeaveRejectReasonsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllLeaveRejectReasons_SUCCESS,
    };
};
export const GetAllLeaveRejectReasonsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllLeaveRejectReasons_FAIL,
    };
};
export const GetAllLeaveRejectReasons = (page, limit, sort, order, search ) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllLeaveRejectReasonsStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllLeaveRejectReasons`,
            params: {
                page: page,
                limit: limit,
                sort: sort,
                order: order,
                search:search,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllLeaveRejectReasonsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllLeaveRejectReasonsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** GetLeaveRejectReasonById Action ***/
export const GetLeaveRejectReasonByIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetLeaveRejectReasonById_START,
    };
};
export const GetLeaveRejectReasonByIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetLeaveRejectReasonById_SUCCESS,
    };
};
export const GetLeaveRejectReasonByIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetLeaveRejectReasonById_FAIL,
    };
};
export const GetLeaveRejectReasonById = (leave_reject_reason_uuid) => {

    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

   
    return (dispatch) => {
        dispatch(GetLeaveRejectReasonByIdStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetLeaveRejectReasonById`,
            params: {
                leave_reject_reason_uuid   : leave_reject_reason_uuid,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetLeaveRejectReasonByIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetLeaveRejectReasonByIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** UpdateLeaveRejectReason Action ***/

export const UpdateLeaveRejectReasonStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateLeaveRejectReason_START,
    };
};
export const UpdateLeaveRejectReasonSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateLeaveRejectReason_SUCCESS,
    };
};
export const UpdateLeaveRejectReasonFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateLeaveRejectReason_FAIL,
    };
};

export const UpdateLeaveRejectReason = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(UpdateLeaveRejectReasonStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/UpdateLeaveRejectReason`,
        })
            .then(function (response) {
                dispatch(UpdateLeaveRejectReasonSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateLeaveRejectReasonFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** UpdateLeaveRejectStatus Action ***/

export const UpdateLeaveRejectStatusStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateLeaveRejectStatus_START,
    };
};

export const UpdateLeaveRejectStatusSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateLeaveRejectStatus_SUCCESS,
    };
};

export const UpdateLeaveRejectStatusFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateLeaveRejectStatus_FAIL,
    };
};

export const UpdateLeaveRejectStatus = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(UpdateLeaveRejectStatusStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/UpdateLeaveRejectStatus`,
        })
            .then(function (response) {
                dispatch(UpdateLeaveRejectStatusSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateLeaveRejectStatusFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** DeleteLeaveRejectReason Action ***/

export const DeleteLeaveRejectReasonStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.DeleteLeaveRejectReason_START,
    };
};
export const DeleteLeaveRejectReasonSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.DeleteLeaveRejectReason_SUCCESS,
    };
};
export const DeleteLeaveRejectReasonFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.DeleteLeaveRejectReason_FAIL,
    };
};
export const DeleteLeaveRejectReason = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(DeleteLeaveRejectReasonStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "delete",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
                
            },
            url: `${actionType.environment_url}/api/bss-admin/DeleteLeaveRejectReason`,
        })
            .then(function (response) {
                console.log("resposne", response.data);
                dispatch(DeleteLeaveRejectReasonSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                console.log("resposne fail", error);
                dispatch(DeleteLeaveRejectReasonFail("Something went wrong, Please try again."));
                return error;
            });
    };
};