import React, { useState, useEffect } from "react";
import moment from "moment";
import "moment-timezone";

export default function Cdate({ time }) {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    const timezone = user_info?.user_school?.timezone;
    const current = new Date();
    const [currentTime, setCurrentTime] = useState(moment(current)?.tz(timezone)?.format('MMMM Do YYYY, h:mm:ss a'));
    useEffect(() => {
        const intervalId = setInterval(() => {
            if (time) {
                setCurrentTime(moment().tz(timezone).format('h:mm:ss a'));
            } else {
                setCurrentTime(moment().tz(timezone).format('MMMM Do YYYY, h:mm:ss a'));
            }

        }, 1000);
        return () => clearInterval(intervalId);
    }, []);
    return (
        <div className="">
            {time ? <h5 className="text-secondary  mb-0">{currentTime}</h5> : <h5 className="text-secondary  mb-0">Current date is {currentTime}</h5>}

        </div>
    );
}


function HeaderTime() {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    const timezone = user_info?.user_school?.timezone;
    const [currentTime, setCurrentTime] = useState(moment()?.tz(timezone)?.format('MMMM Do YYYY, h:mm:ss a'));
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(moment().tz(timezone).format('MMMM Do YYYY, h:mm:ss a'));
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);
    return (
        <div className="">
            <h5 className="text-secondary  mb-0">{currentTime}</h5>
        </div>
    );
}

export { HeaderTime }
