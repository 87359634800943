import React from "react";
import { Link } from "react-router-dom";
// import addNotifications from "react-push-notification";
import * as actionType from "../../constants/actionTypes";
// import logos from '../../../public/media/logos/logo';
import Notification from "./Notification";
import {HeaderTime} from "./date" 
const TopBar = (props) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (
        <>
            <div id="kt_header" className="header align-items-stretch ">
                <div className="container-fluid d-flex align-items-stretch justify-content-between ">
                    <div className="d-flex align-items-center d-lg-none ms-n3 me-1 " title="Show aside menu">
                        <div className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px" id="kt_aside_mobile_toggle">
                            <span className="svg-icon svg-icon-2x mt-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">

                                    <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="black" />
                                    <path
                                        opacity="0.3"
                                        d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                        fill="black"
                                    />
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0 ktheaderbar">
                        <span className="d-lg-none">
                            {/* <img alt="Logo" src="assets/media/logos/logo-2.svg" className="h-30px" /> */}
                            <Link to="/dashboard">
                                {" "}
                                <img alt="Logo" src="/assets/media/logos/logo.png" className="h-30px" />
                            </Link>
                        </span>
                    </div>
                    {/* <div "container-fluid d-flex align-items-stretch justify-content-between ">
                   

<div className="d-flex align-items-center position-relative">
                <span className="svg-icon svg-icon-1 position-absolute ms-4">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{marginTop:"12px"}}>
                        <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                        <path
                            d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                </span>
                <input type="text" className="form-control form-control-sm w-350px ps-12 search-out fs-6" placeholder="Search " style={{background: "transparent"}  , {marginTop:"14px"}} />      
            </div>
            </div>

            <div className="d-flex align-items-stretch flex-shrink-0" style={{marginLeft:"500px"}}>
                <div className="d-flex align-items-center" id="kt_header_user_menu_toggle">
                    <div className="d-flex fs-8 fw-bold">Logged in as school admin | Dikonia Test School</div>
                    </div>
                    </div> */}

                    <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1 gettopbarmenu">

                        <div className="d-flex align-items-stretch" id="kt_header_nav">
                            <div
                                className="header-menu align-items-stretch"
                                data-kt-drawer="true"
                                data-kt-drawer-name="header-menu"
                                data-kt-drawer-activate="{default: false, lg: true}"
                                data-kt-drawer-overlay="true"
                                data-kt-drawer-width="{default:'200px', '300px': '250px'}"
                                data-kt-drawer-direction="end"
                                data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
                                data-kt-swapper="true"
                                data-kt-swapper-mode="prepend"
                                data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
                            >
                                <div
                                    className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch"
                                    id="#kt_header_menu"
                                    data-kt-menu="true"
                                >
                                    <div className="menu-item me-lg-1 d-main-link">
                                        {/* <span className="menu-link py-3">
                                            <Link to="/dashboard" className="text-muted text-hover-primary">
                                                <span className="menu-title">Dashboard</span>
                                            </Link>
                                        </span> */}
                                        <h2 className="text-secondary ">
                                            {(user_info?.school_name) ? user_info?.school_name : user_info?.user_school?.school_name}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="d-flex align-items-stretch" id="kt_header_nav">
                            <div
                                className="header-menu align-items-stretch"
                                data-kt-drawer="true"
                                data-kt-drawer-name="header-menu"
                                data-kt-drawer-activate="{default: false, lg: true}"
                                data-kt-drawer-overlay="true"
                                data-kt-drawer-width="{default:'200px', '300px': '250px'}"
                                data-kt-drawer-direction="end"
                                data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
                                data-kt-swapper="true"
                                data-kt-swapper-mode="prepend"
                                data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
                            >
                                <div
                                    className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch"
                                    id="#kt_header_menu"
                                    data-kt-menu="true"
                                >
                                    <div className="menu-item me-lg-1 d-main-link">
                                        {/* <span className="menu-link py-3">
                                            <Link to="/dashboard" className="text-muted text-hover-primary">
                                                <span className="menu-title">Dashboard</span>
                                            </Link>
                                        </span> */}
                                        <span className="text-secondary ">
                                            <HeaderTime/>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="d-flex align-items-stretch flex-shrink-0 ">
                            <div className="d-flex align-items-center px-5">
                                <Notification callNotification={props.callNotification} />
                            </div>
                            <div className="d-flex align-items-center" id="kt_header_user_menu_toggle">
                                {/* <div className="d-flex fw-bold">{(user_info?.role_type === 2) ? "Logged in as School Admin" : "Logged in as Manager"} |  {(user_info?.school_name) ? user_info?.school_name : user_info?.user_school?.school_name} </div> */}

                                <div className="d-flex fw-bold">{(user_info?.role_type === 2) ? "Logged in as School Admin" : "Logged in as Manager"}</div>
                            </div>

                            <div className="d-flex align-items-center ms-1 ms-lg-3">
                                <b> {user_info?.first_name ? user_info?.first_name + " " + user_info?.last_name : "BSS"}</b>
                            </div>

                            <div className="d-flex align-items-center ms-1 ms-lg-3" id="kt_header_user_menu_toggle">
                                <div id="topaccountmenudata" className="cursor-pointer symbol symbol-30px symbol-md-40px topaccountmenudata " data-kt-menu-placement="bottom-end">
                                    {/* <img alt="Logo" src={user_info?.user_school?.school_logo ? actionType?.environment_url + '/' + user_info?.user_school?.school_logo : "/assets/media/school-logo.png"} /> */}
                                    <img alt="Logo" src={user_info?.avatar ? actionType?.environment_url + '/' + user_info?.avatar : "/assets/media/school-logo.png"} />

                                </div>
                                <div
                                    id="topaccountmenu"
                                    className="topaccountmenu menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px profile-dropdown"
                                    data-kt-menu="true"
                                >
                                    <div className="menu-item px-3">
                                        <div className="menu-content d-flex align-items-center px-3">
                                            <div className="symbol symbol-50px me-5">
                                                {/* <img alt="Logo" src={user_info?.user_school?.school_logo ? actionType?.environment_url + '/' + user_info?.user_school?.school_logo : "/assets/media/school-logo.png"} /> */}

                                                <img alt="Logo" src={user_info?.avatar ? actionType?.environment_url + '/' + user_info?.avatar : "/assets/media/school-logo.png"} />

                                            </div>
                                            <div className="d-flex flex-column">
                                                <div className="fw-bolder d-flex align-items-center fs-5">
                                                    {user_info?.first_name ? user_info?.first_name + " " + user_info?.last_name : "BSS"}
                                                </div>
                                                <span className="fw-bold text-muted text-hover-primary fs-7 email-info">
                                                    {user_info?.email ? user_info?.email : "bss@gmail.com"}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="separator my-2"></div>
                                    <div className="menu-item px-5">
                                        <Link to="/account" className="menu-link px-5">
                                            My Account
                                        </Link>
                                    </div>
                                    <div className="menu-item px-5">
                                        <Link to="/logout" className="menu-link px-5">
                                            Sign Out
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TopBar;