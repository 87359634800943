import React , {useState , useEffect , useTransition} from "react";
import { Modal } from "react-bootstrap";


    const RollOverStudentModal = (props , { delayResend = "30" }) => {
        const [delay, setDelay] = useState(+delayResend);
        const [minutes, setMinutes] = useState(0);
        const [seconds, setSeconds] = useState(0);
      
        const [isPending, startTransition] = useTransition();
      
        useEffect(() => {
          const RollOverStudentModal = setInterval(() => {
            startTransition(() => {
             setDelay(delay - 1);
             setMinutes(Math.floor(delay / 60));
             setSeconds(Math.floor(delay % 60));
         
           });
           
          }, 1000);
      
          if (delay === 0) {
            clearInterval(RollOverStudentModal);
          }
      
          return () => {
            clearInterval(RollOverStudentModal);
          };
        });

        


        return (
            <React.Fragment>
                <Modal
                    backdrop="static"
                    keyboard={false}
                    className="add_archive_modal confirm_modal"
                    show={this.props.add_archive_modal}
                    onHide={() => {
                        props.add_archive_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Archive Manager</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                       






                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }

export default RollOverStudentModal;
