import React from "react";
import { Navigate } from "react-router-dom";
import Validations from "../Utility/Validations";
import Sidebar from "../Constant/sidebar";
import Footer from "../Constant/Footer";
import TopBar from "../Constant/TopBar";
import * as userActions from "../../actions/index";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import moment from "moment";
import { Link } from "react-router-dom";
import Select from "react-select";

// import * as actionType from "../../constants/actionTypes";
import { connect } from "react-redux";

import { toast } from "react-toastify";

const permission_info = JSON.parse(localStorage.getItem("permissions"));
const user_id = JSON.parse(localStorage.getItem("user_id"));
const user_uuid = JSON.parse(localStorage.getItem("user_uuid"));
const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info.school_code;

class ManagerPermission extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      manger_name: "",
      user_id: "",
      user_uuid: "",
      permission_edit_form: {
        dorm_data: [],
        class_data: [],
        add_permission: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        permission_detail_id: {
          type: "checkbox",
          label: "view",
          value: "",
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        snapshot_gender_both: {
          type: "radio",
          label: "Gender",
          value: false,
          valid: true,
          error_msg: "",
          onBlur_out: false,
          validations: { required: true },
        },
        snapshot_gender_female: {
          type: "radio",
          label: "Gender",
          value: false,
          valid: true,
          error_msg: "",
          onBlur_out: false,
          validations: { required: true },
        },
        snapshot_gender_male: {
          type: "radio",
          label: "Gender",
          value: false,
          valid: true,
          error_msg: "",
          onBlur_out: false,
          validations: { required: true },
        },

        view_weekend_leave: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        add_weekend_leave: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        edit_weekend_leave: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },


        add_host: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        edit_host: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        view_host: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        view_medical_comment: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        add_medical_comment: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        view_pastoral_comment: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        add_pastoral_comment: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },


        // Web Permission Permissions =================================

        add_class: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        edit_class: {
          type: "checkbox",
          label: "All",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        view_class: {
          type: "checkbox",
          label: "All",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        activate_deactivate_class: {
          type: "checkbox",
          label: "All",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        add_dormitory: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        edit_dormitory: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        view_dormitory: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        activate_deactivate_dormitory: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        edit_school_profile: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        view_school_profile: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        add_user: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        edit_user: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        view_user: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        activate_deactivate_user: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        edit_permission: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        view_permission: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        add_role: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          onBlur_out: false,
          validations: { required: false },
        },
        edit_role: {
          type: "checkbox",
          label: "Edit",
          value: false,
          valid: true,
          error_msg: "",
          onBlur_out: false,
          validations: { required: false },
        },
        add_duplicate_role: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          onBlur_out: false,
          validations: { required: false },
        },
        deactivate_role: {
          type: "checkbox",
          label: "Deactive",
          value: false,
          valid: true,
          error_msg: "",
          onBlur_out: false,
          validations: { required: false },
        },
        delete_role: {
          type: "checkbox",
          label: "Delete",
          value: false,
          valid: true,
          error_msg: "",
          onBlur_out: false,
          validations: { required: false },
        },

        view_snapshot: {
          type: "checkbox",
          label: "View",
          value: false,
          valid: true,
          error_msg: "",
          onBlur_out: false,
          validations: { required: false },
        },

        view_activities: {
          type: "checkbox",
          label: "View",
          value: false,
          valid: true,
          error_msg: "",
          onBlur_out: false,
          validations: { required: false },
        },

        // Students Permissions =================================

        view_student: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        edit_student: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        activate_deactivate_student: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        add_student: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        create_tempid: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        delete_student: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        add_allergic_students: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        view_allergic_students: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        edit_allergic_students: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        delete_allergic_students: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },


        add_flag_students: {
          type: "checkbox",
          label: "view",
          value: "",
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        view_flag_students: {
          type: "checkbox",
          label: "view",
          value: "",
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        edit_flag_students: {
          type: "checkbox",
          label: "view",
          value: "",
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        delete_flag_students: {
          type: "checkbox",
          label: "view",
          value: "",
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        // deactivate_flag_students: {
        //   type: "checkbox",
        //   label: "view",
        //   value: "",
        //   valid: true,
        //   error_msg: "",
        //   validations: { required: false },
        // },


        add_grounded_students: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        view_grounded_students: {
          type: "checkbox",
          label: "view",
          value: "",
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        edit_grounded_students: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        delete_grounded_students: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        // deactivate_grounded_students: {
        //   type: "checkbox",
        //   label: "view",
        //   value: "",
        //   valid: true,
        //   error_msg: "",
        //   validations: { required: false },
        // },

        view_import_students: {
          type: "checkbox",
          label: "view",
          value: "",
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        view_duplicate_students: {
          type: "checkbox",
          label: "view",
          value: "",
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        view_change_dormitory: {
          type: "checkbox",
          label: "view",
          value: "",
          valid: true,
          error_msg: "",
          validations: { required: false },
        },


        // Setting Permissions =================================

        view_attendance_report: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        view_message: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        edit_message: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        view_custom_links: {
          type: "checkbox",
          label: "view",
          value: "",
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        view_rollover_students: {
          type: "checkbox",
          label: "view",
          value: "",
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        view_rollcall_session_time: {
          type: "checkbox",
          label: "view",
          value: "",
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        view_calendar: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        add_calendar: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        edit_calendar: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        view_oncampus_details: {
          type: "checkbox",
          label: "view",
          value: "",
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        // Leave Permissions =================================

        view_boarder_leaves: {
          type: "checkbox",
          label: "View",
          value: false,
          valid: true,
          error_msg: "",
          onBlur_out: false,
          validations: { required: false },
        },
        add_boarder_leaves: {
          type: "checkbox",
          label: "View",
          value: false,
          valid: true,
          error_msg: "",
          onBlur_out: false,
          validations: { required: false },
        },
        edit_boarder_leaves: {
          type: "checkbox",
          label: "View",
          value: false,
          valid: true,
          error_msg: "",
          onBlur_out: false,
          validations: { required: false },
        },

        view_adhoc_leave: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        add_adhoc_leave: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        edit_adhoc_leave: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        view_reports: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        view_travel_mode: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        add_travel_mode: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        edit_travel_mode: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        delete_travel_mode: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        activate_deactivate_travel_mode: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        view_rejection_reason: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        add_rejection_reason: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        edit_rejection_reason: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        delete_rejection_reason: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        activate_deactivate_rejection_reason: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

      },
      class_id: {
        type: "select",
        label: "Select Class *",
        value: "",
        valid: true,
        error_msg: "",
        onBlur_out: false,
        placeholder: "",
        validations: { required: true },
        options: [],
      },
      dormitory_id: {
        type: "select",
        label: "Select Dormitory *",
        value: "",
        valid: true,
        error_msg: "",
        onBlur_out: false,
        placeholder: "",
        validations: { required: true },
        options: [],
      },
    };
  }

  componentDidMount() {
    const user_id = JSON.parse(localStorage.getItem("user_id"));
    const user_uuid = JSON.parse(localStorage.getItem("user_uuid"));
    this.setState({ user_uuid: user_uuid })
    this.setState({ user_id: user_id }, () => {
      this.GetManagerPermissonDetailsById();
    });
    this.GetLoginUserPermissions();
    this.GetSchoolManagerById()
  }
  GetLoginUserPermissions() {
    this.props.onGetLoginUserPermissions().then((response) => {
      if (response.success === true) {
      } else {
      }
    });
  }


  GetSchoolManagerById() {
    this.props.onGetSchoolManagerById(user_uuid).then((response) => {
      if (response.success === true) {
        this.setState({ manger_name: response.data.first_name + " " + response.data.last_name })
      } else {
      }
    });
  }


  GetManagerPermissonDetailsById() {
    this.props.onGetManagerPermissonDetailsById(this.state.user_id).then((response) => {
      if (response.success === true) {
        let permission_res = response.data;
        const update_manager_edit_form = { ...this.state.permission_edit_form };
        if (permission_res?.perm_class_dorm) {
          const dormData = permission_res.perm_class_dorm.map((item) => {
            if (item.permi_dorm_data) {
              return {
                value: item?.permi_dorm_data?.dormitory_id,
                label: item?.permi_dorm_data?.dormitory_name
              }
            }
          });
          update_manager_edit_form.dorm_data = dormData.filter((item) => item !== undefined);
        }

        if (permission_res?.perm_class_dorm) {
          const classData = permission_res?.perm_class_dorm.map((item) => {
            if (item.permi_class_data) {
              return {
                value: item.permi_class_data.class_id,
                label: item.permi_class_data.class_name
              }
            }
          });
          update_manager_edit_form.class_data = classData.filter((item) => item !== undefined);
        }


        for (let key in update_manager_edit_form) {
          let form_element = update_manager_edit_form[key];
          if (permission_res[key]) {
            update_manager_edit_form["add_permission"].value = permission_res?.add_permission;
            update_manager_edit_form["permission_detail_id"].value = permission_res?.permission_detail_id;
            update_manager_edit_form["snapshot_gender_both"].value = permission_res?.snapshot_gender_both;
            update_manager_edit_form["snapshot_gender_female"].value = permission_res?.snapshot_gender_female;
            update_manager_edit_form["snapshot_gender_male"].value = permission_res?.snapshot_gender_male;


            update_manager_edit_form["view_weekend_leave"].value = permission_res?.view_weekend_leave;
            update_manager_edit_form["add_weekend_leave"].value = permission_res?.add_weekend_leave;
            update_manager_edit_form["edit_weekend_leave"].value = permission_res?.edit_weekend_leave;
            update_manager_edit_form["view_host"].value = permission_res?.view_host;
            update_manager_edit_form["add_host"].value = permission_res?.add_host;
            update_manager_edit_form["edit_host"].value = permission_res?.edit_host;
            update_manager_edit_form["view_medical_comment"].value = permission_res?.view_medical_comment;
            update_manager_edit_form["add_medical_comment"].value = permission_res?.add_medical_comment;
            update_manager_edit_form["view_pastoral_comment"].value = permission_res?.view_pastoral_comment;
            update_manager_edit_form["add_pastoral_comment"].value = permission_res?.add_pastoral_comment;



            // <======================================== Web Permission  ===========================>

            update_manager_edit_form["add_class"].value = permission_res?.add_class;
            update_manager_edit_form["edit_class"].value = permission_res?.edit_class;
            update_manager_edit_form["view_class"].value = permission_res?.view_class;
            update_manager_edit_form["activate_deactivate_class"].value = permission_res?.activate_deactivate_class;

            update_manager_edit_form["add_dormitory"].value = permission_res?.add_dormitory;
            update_manager_edit_form["edit_dormitory"].value = permission_res?.edit_dormitory;
            update_manager_edit_form["view_dormitory"].value = permission_res?.view_dormitory;
            update_manager_edit_form["activate_deactivate_dormitory"].value = permission_res?.activate_deactivate_dormitory;

            update_manager_edit_form["view_school_profile"].value = permission_res?.view_school_profile;
            update_manager_edit_form["edit_school_profile"].value = permission_res?.edit_school_profile;

            update_manager_edit_form["add_user"].value = permission_res?.add_user;
            update_manager_edit_form["edit_user"].value = permission_res?.edit_user;
            update_manager_edit_form["view_user"].value = permission_res?.view_user;
            update_manager_edit_form["activate_deactivate_user"].value = permission_res?.activate_deactivate_user;

            update_manager_edit_form["edit_permission"].value = permission_res?.edit_permission;
            update_manager_edit_form["view_permission"].value = permission_res?.view_permission;
            update_manager_edit_form['add_role'].value = permission_res?.add_role;
            update_manager_edit_form['edit_role'].value = permission_res?.edit_role;
            update_manager_edit_form['add_duplicate_role'].value = permission_res?.add_duplicate_role;
            update_manager_edit_form['delete_role'].value = permission_res?.delete_role;
            update_manager_edit_form['deactivate_role'].value = permission_res?.deactivate_role;

            update_manager_edit_form['view_snapshot'].value = permission_res?.view_snapshot;

            update_manager_edit_form['view_activities'].value = permission_res?.view_activities;


            // <======================================== Student   ===========================>

            update_manager_edit_form["view_student"].value = permission_res?.view_student;
            update_manager_edit_form["activate_deactivate_student"].value = permission_res?.activate_deactivate_student;
            update_manager_edit_form["edit_student"].value = permission_res?.edit_student;
            update_manager_edit_form["create_tempid"].value = permission_res?.create_tempid;
            update_manager_edit_form["delete_student"].value = permission_res?.delete_student;

            update_manager_edit_form["add_student"].value = permission_res?.add_student;

            update_manager_edit_form["add_allergic_students"].value = permission_res?.add_allergic_students;
            update_manager_edit_form["view_allergic_students"].value = permission_res?.view_allergic_students;
            update_manager_edit_form["edit_allergic_students"].value = permission_res?.edit_allergic_students;
            update_manager_edit_form["delete_allergic_students"].value = permission_res?.delete_allergic_students;

            update_manager_edit_form["add_grounded_students"].value = permission_res?.add_grounded_students;
            update_manager_edit_form["view_grounded_students"].value = permission_res?.view_grounded_students;
            update_manager_edit_form["edit_grounded_students"].value = permission_res?.edit_grounded_students;
            update_manager_edit_form["delete_grounded_students"].value = permission_res?.delete_grounded_students;
            // update_manager_edit_form["deactivate_grounded_students"].value = permission_res?.deactivate_grounded_students;

            update_manager_edit_form["add_flag_students"].value = permission_res?.add_flag_students;
            update_manager_edit_form["view_flag_students"].value = permission_res?.view_flag_students;
            update_manager_edit_form["edit_flag_students"].value = permission_res?.edit_flag_students;
            update_manager_edit_form["delete_flag_students"].value = permission_res?.delete_flag_students;
            // update_manager_edit_form["deactivate_flag_students"].value = permission_res?.deactivate_flag_students;

            update_manager_edit_form["view_import_students"].value = permission_res?.view_import_students;

            update_manager_edit_form["view_duplicate_students"].value = permission_res?.view_duplicate_students;

            update_manager_edit_form["view_change_dormitory"].value = permission_res?.view_change_dormitory;


            // <======================================== Setting Tab ========================>

            update_manager_edit_form["view_attendance_report"].value = permission_res?.view_attendance_report;

            update_manager_edit_form["view_message"].value = permission_res?.view_message;
            update_manager_edit_form["edit_message"].value = permission_res?.edit_message;

            update_manager_edit_form["view_custom_links"].value = permission_res?.view_custom_links;

            update_manager_edit_form["view_rollover_students"].value = permission_res?.view_rollover_students;

            update_manager_edit_form["view_rollcall_session_time"].value = permission_res?.view_rollcall_session_time;

            update_manager_edit_form["view_oncampus_details"].value = permission_res?.view_oncampus_details;

            update_manager_edit_form["view_calendar"].value = permission_res?.view_calendar;
            update_manager_edit_form["add_calendar"].value = permission_res?.add_calendar;
            update_manager_edit_form["edit_calendar"].value = permission_res?.edit_calendar;


            // <======================================== leave permissions========================>

            update_manager_edit_form["view_boarder_leaves"].value = permission_res?.view_boarder_leaves;
            update_manager_edit_form["add_boarder_leaves"].value = permission_res?.add_boarder_leaves;
            update_manager_edit_form["edit_boarder_leaves"].value = permission_res?.edit_boarder_leaves;

            update_manager_edit_form["view_adhoc_leave"].value = permission_res?.view_adhoc_leave;
            update_manager_edit_form["add_adhoc_leave"].value = permission_res?.add_adhoc_leave;
            update_manager_edit_form["edit_adhoc_leave"].value = permission_res?.edit_adhoc_leave;

            update_manager_edit_form["view_reports"].value = permission_res?.view_reports;

            update_manager_edit_form["view_travel_mode"].value = permission_res?.view_travel_mode;
            update_manager_edit_form["add_travel_mode"].value = permission_res?.add_travel_mode;
            update_manager_edit_form["edit_travel_mode"].value = permission_res?.edit_travel_mode;
            update_manager_edit_form["delete_travel_mode"].value = permission_res?.delete_travel_mode;
            update_manager_edit_form["activate_deactivate_travel_mode"].value = permission_res?.activate_deactivate_travel_mode;


            update_manager_edit_form["view_rejection_reason"].value = permission_res?.view_rejection_reason;
            update_manager_edit_form["add_rejection_reason"].value = permission_res?.add_rejection_reason;
            update_manager_edit_form["edit_rejection_reason"].value = permission_res?.edit_rejection_reason;
            update_manager_edit_form["delete_rejection_reason"].value = permission_res?.delete_rejection_reason;
            update_manager_edit_form["activate_deactivate_rejection_reason"].value = permission_res?.activate_deactivate_rejection_reason;


            update_manager_edit_form[key] = form_element;
          }
        }
        this.setState({ permission_edit_form: update_manager_edit_form });
        console.log("permission update from api", update_manager_edit_form);

      } else {
      }
    });
  }


  handleChange(data, identifier, type) {
    const update_manager_edit_form = { ...this.state.manager_edit_form };
    update_manager_edit_form[identifier].value = data.value;
    this.setState({ manager_edit_form: update_manager_edit_form });
  }

  ChangePermissions = async (permission_status) => {
    const update_manager_edit_form = { ...this.state.permission_edit_form };
    for (let identifier in update_manager_edit_form) {
      let form_element = update_manager_edit_form[identifier];

      if (
        form_element.type === "checkbox" &&
        identifier !== "students_specific"
      ) {
        form_element.value = permission_status;
        update_manager_edit_form[identifier] = form_element;
      }
    }
    this.setState({ permission_edit_form: update_manager_edit_form });
  };

  inputChangeHandler(event, identifier) {
    const update_manager_edit_form = { ...this.state.permission_edit_form };
    const form_element = { ...update_manager_edit_form[identifier] };
    if (form_element.type === "checkbox") {
      form_element.value = event.target.checked;
    } else {
      form_element.value = event.target.value;
    }
    update_manager_edit_form[identifier] = form_element;
    this.setState({ permission_edit_form: update_manager_edit_form });
  }



  SubmitHandlerPermission = (event) => {
    event.preventDefault();
    let is_form_valid = true;
    const update_manager_edit_form = this.state.permission_edit_form;
    const userId = this.state.user_id;
    this.setState({ permission_edit_form: update_manager_edit_form });
    if (is_form_valid) {
      const form_data = {};
      for (let key in this.state.permission_edit_form) {
        form_data[key] = this.state.permission_edit_form[key].value;
      }

      let classes = this.state.permission_edit_form.class_data;
      console.log("classes", classes)
      let update_class_data = classes.map((item) => {
        return {
          class_id: item.value,
          class_name: item.label
        };
      });

      let dorms = this.state.permission_edit_form.dorm_data;
      console.log("dorms", dorms);
      let update_dormitory_data = dorms.map((item) => {
        return {
          dormitory_id: item.value,
          dormitory_name: item.label
        };
      });

      form_data["user_id"] = userId;
      form_data["dorm_data"] = JSON.stringify(update_dormitory_data);
      form_data["class_data"] = JSON.stringify(update_class_data);

      this.props.onUpdateManagerPermissions(form_data).then(async (response) => {
        if (response.success === true) {
          toast.success(response.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          setTimeout(() => {
            this.setState({
              isAuthenticated: true,
              redirect_url: `/${school_code}/manager-list`,
            });
          }, 500);
        } else {
          toast.error(response.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
    }
  };


  render() {
    const { permission_edit_form } = this.state;

    return (
      <React.Fragment>
        {this.state.isAuthenticated ? (
          <Navigate to={this.state.redirect_url} />
        ) : null}

        <div
          id="kt_body"
          className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed demo h-100"
        >
          <div className="d-flex flex-column flex-root h-100">
            <div className="page d-flex flex-row flex-column-fluid">
              <Sidebar></Sidebar>

              <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                <TopBar></TopBar>
                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                  <div className="post d-flex flex-column-fluid" id="kt_post">
                    <div id="kt_content_container" className="container-fluid">
                      <form onSubmit={this.SubmitHandlerPermission}>
                        <div className="d-flex justify-content-between">
                          <ul className="nav nav-tabs home-tabs" id="myTab" role="tablist">
                            <li className="nav-item me-6" role="presentation">
                              <Link to={`/${school_code}/manager-list/edit-manager/${this.state.user_uuid}`} className="nav-link" id="EditManager-tab" type="button" aria-controls="home" aria-selected="true">
                                Edit Manager
                              </Link>
                            </li>

                            <li className="nav-item me-6" role="presentation">
                              <Link to={`/${school_code}/manager-list/edit-manager-permission`} className="nav-link active" id="EditPermission-tab" type="button" aria-controls="process" aria-selected="false">
                                Edit Permissions
                              </Link>
                            </li>
                          </ul>
                        </div>



                        <div className="school-edit-form">
                          <div className="card" id=""><div className="card-body tabs-outer">
                            <div className="d-flex border-0 mb-5 justify-content-end">
                              <button type="reset" className="btn btn-sm btn-light me-2 min-100px" onClick={(event) => this.ChangePermissions(false)}>
                                Cancel
                              </button>
                              <button type="submit" className="btn btn-sm btn-primary min-100px" >
                                Update
                              </button>
                            </div>

                            <div className="permission-wrap mb-4">
                              <div className="d-flex permission-title">
                                <div className="title-left">
                                  <div className="d-flex align-items-center">
                                    <h6 className="mb-0">Web Permission</h6>
                                    <span className="d-none d-lg-block bullet h-20px w-1px bg-black mx-4"></span>
                                    <span>Your are currently editing Manager :&nbsp;</span>
                                    <span className="card-title m-0">{this.state.manger_name}</span>
                                  </div>
                                </div>
                                <div className="select-btn-out">Select :
                                  <button type="button" className="m-btn btn btn-secondary btn-sm" onClick={(event) => this.ChangePermissions(true)}>
                                    All
                                  </button>
                                  |
                                  <button type="button" className="m-btn btn btn-secondary btn-sm" onClick={(event) => this.ChangePermissions(false)}>
                                    None
                                  </button>
                                </div>
                              </div>

                              <div className="mt-2">
                                <div className="sec-row">
                                  <div className="row">
                                    <div className="col-md-2">
                                      <div className="ps-3">
                                        <b>Manage Class</b>
                                      </div>
                                    </div>
                                    <div className="col-md-10">
                                      <div className="row">
                                        <div className="col-sm-4 col-md-3">
                                          <div className="checkbox-inline">
                                            <label className="checkbox cursor-pointer ">
                                              <input
                                                type="checkbox"
                                                className="form-check-input me-1"
                                                value={permission_edit_form.add_class.value}
                                                checked={permission_edit_form.add_class.value}
                                                onChange={(event) => this.inputChangeHandler(event, "add_class")}
                                              />
                                              <span className="mb-0">
                                                Add Class
                                              </span>
                                            </label>
                                          </div>
                                        </div>

                                        <div className="col-sm-4 col-md-3">
                                          <div className="checkbox-inline">
                                            <label className="checkbox cursor-pointer ">
                                              <input
                                                type="checkbox"
                                                className="form-check-input me-1"
                                                value={permission_edit_form.edit_class.value}
                                                checked={permission_edit_form.edit_class.value}
                                                onChange={(event) => this.inputChangeHandler(event, "edit_class")}
                                              />
                                              <span className="mb-0">
                                                Edit Class
                                              </span>
                                            </label>
                                          </div>
                                        </div>

                                        <div className="col-sm-4 col-md-3">
                                          <div className="checkbox-inline">
                                            <label className="checkbox cursor-pointer ">
                                              <input
                                                type="checkbox"
                                                className="form-check-input me-1"
                                                value={permission_edit_form.view_class.value}
                                                checked={permission_edit_form.view_class.value}
                                                onChange={(event) => this.inputChangeHandler(event, "view_class")}
                                              />
                                              <span className="mb-0">
                                                View Class
                                              </span>
                                            </label>
                                          </div>
                                        </div>

                                        <div className="col-sm-4 col-md-3">
                                          <div className="checkbox-inline">
                                            <label className="checkbox cursor-pointer ">
                                              <input
                                                type="checkbox"
                                                className="form-check-input me-1"
                                                value={permission_edit_form.activate_deactivate_class.value}
                                                checked={permission_edit_form.activate_deactivate_class.value}
                                                onChange={(event) => this.inputChangeHandler(event, "activate_deactivate_class")}
                                              />
                                              <span className="mb-0">
                                                Activate/Deactivate Class
                                              </span>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="sec-row">
                                  <div className="row">
                                    <div className="col-md-2">
                                      <div className="ps-3">
                                        <b>Manage Dormitory</b>
                                      </div>
                                    </div>
                                    <div className="col-md-10">
                                      <div className="row">
                                        <div className="col-sm-4 col-md-3">
                                          <div className="checkbox-inline">
                                            <label className="checkbox cursor-pointer ">
                                              <input
                                                type="checkbox"
                                                className="form-check-input me-1"
                                                value={permission_edit_form.add_dormitory.value}
                                                checked={permission_edit_form.add_dormitory.value}
                                                onChange={(event) => this.inputChangeHandler(event, "add_dormitory")}
                                              />
                                              <span className="mb-0">
                                                Add Dormitory
                                              </span>
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col-sm-4 col-md-3">
                                          <div className="checkbox-inline">
                                            <label className="checkbox cursor-pointer ">
                                              <input
                                                type="checkbox"
                                                className="form-check-input me-1"
                                                value={permission_edit_form.edit_dormitory.value}
                                                checked={permission_edit_form.edit_dormitory.value}
                                                onChange={(event) => this.inputChangeHandler(event, "edit_dormitory")}
                                              />
                                              <span className="mb-0">
                                                Edit Dormitory
                                              </span>
                                            </label>
                                          </div>
                                        </div>

                                        <div className="col-sm-4 col-md-3">
                                          <div className="checkbox-inline">
                                            <label className="checkbox cursor-pointer ">
                                              <input
                                                type="checkbox"
                                                className="form-check-input me-1"
                                                value={permission_edit_form.view_dormitory.value}
                                                checked={permission_edit_form.view_dormitory.value}
                                                onChange={(event) => this.inputChangeHandler(event, "view_dormitory")}
                                              />
                                              <span className="mb-0">
                                                View Dormitory
                                              </span>
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col-sm-4 col-md-3">
                                          <div className="checkbox-inline">
                                            <label className="checkbox cursor-pointer ">
                                              <input
                                                type="checkbox"
                                                className="form-check-input me-1"
                                                value={permission_edit_form.activate_deactivate_dormitory.value}
                                                checked={permission_edit_form.activate_deactivate_dormitory.value}
                                                onChange={(event) => this.inputChangeHandler(event, "activate_deactivate_dormitory")}
                                              />
                                              <span className="mb-0">
                                                Activate/Deactivate Dormitory
                                              </span>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-12 sec-row">
                                  <div className="row">
                                    <div className="col-md-2">
                                      <div class="ps-3">
                                        <b>Manage School Profile</b>
                                      </div>
                                    </div>
                                    <div className="col-md-10">
                                      <div className="row">
                                        <div className="col-sm-4">
                                          <div className="checkbox-inline">
                                            <label className="checkbox cursor-pointer ">
                                              <input
                                                type="checkbox"
                                                className="form-check-input me-1"
                                                value={permission_edit_form.edit_school_profile.value}
                                                checked={permission_edit_form.edit_school_profile.value}
                                                onChange={(event) => this.inputChangeHandler(event, "edit_school_profile")}
                                              />
                                              <span className="mb-0">
                                                Edit School Profile
                                              </span>
                                            </label>
                                          </div>
                                        </div>

                                        <div className="col-sm-4">
                                          <div className="checkbox-inline">
                                            <label className="checkbox cursor-pointer ">
                                              <input
                                                type="checkbox"
                                                className="form-check-input me-1"
                                                value={permission_edit_form.view_school_profile.value}
                                                checked={permission_edit_form.view_school_profile.value}
                                                onChange={(event) => this.inputChangeHandler(event, "view_school_profile")}
                                              />
                                              <span className="mb-0">
                                                View School Profile
                                              </span>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-12 sec-row">
                                  <div className="row">
                                    <div className="col-md-2 text-start">
                                      <div className="ps-3"><b>Manage Managers</b></div>
                                    </div>
                                    <div className="col-md-10">
                                      <div className="row">
                                        <div className="col-sm-4">
                                          <div className="checkbox-inline">
                                            <label className="checkbox cursor-pointer ">
                                              <input
                                                type="checkbox"
                                                className="form-check-input me-1"
                                                value={permission_edit_form.add_user.value}
                                                checked={permission_edit_form.add_user.value}
                                                onChange={(event) => this.inputChangeHandler(event, "add_user")}
                                              />
                                              <span className="mb-0">
                                                Add Manager
                                              </span>
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col-sm-4">
                                          <div className="checkbox-inline">
                                            <label className="checkbox cursor-pointer ">
                                              <input
                                                type="checkbox"
                                                className="form-check-input me-1"
                                                value={permission_edit_form.edit_user.value}
                                                checked={permission_edit_form.edit_user.value}
                                                onChange={(event) => this.inputChangeHandler(event, "edit_user")}
                                              />
                                              <span className="mb-0">
                                                Edit Manager
                                              </span>
                                            </label>
                                          </div>
                                        </div>

                                        <div className="col-sm-4">
                                          <div className="checkbox-inline">
                                            <label className="checkbox cursor-pointer ">
                                              <input
                                                type="checkbox"
                                                className="form-check-input me-1"
                                                value={permission_edit_form.view_user.value}
                                                checked={permission_edit_form.view_user.value}
                                                onChange={(event) => this.inputChangeHandler(event, "view_user")}
                                              />
                                              <span className="mb-0">
                                                View Managers
                                              </span>
                                            </label>
                                          </div>
                                        </div>

                                        <div className="col-sm-4 mt-4">
                                          <div className="checkbox-inline">
                                            <label className="checkbox cursor-pointer ">
                                              <input
                                                type="checkbox"
                                                className="form-check-input me-1"
                                                value={permission_edit_form.activate_deactivate_user.value}
                                                checked={permission_edit_form.activate_deactivate_user.value}
                                                onChange={(event) => this.inputChangeHandler(event, "activate_deactivate_user")}
                                              />
                                              <span className="mb-0">
                                                Manager Archive
                                              </span>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="sec-row">
                                  <div className="row">
                                    <div className="col-md-2">
                                      <div className="ps-3">
                                        <b>Manage Role and Permissions</b>
                                      </div>
                                    </div>
                                    <div className="col-md-10">
                                      <div className="row">
                                        <div className="col-sm-4 col-md-3">
                                          <div className="checkbox-inline">
                                            <label className="checkbox cursor-pointer ">
                                              <input
                                                type="checkbox"
                                                className="form-check-input me-1"
                                                value={permission_edit_form.edit_permission.value}
                                                checked={permission_edit_form.edit_permission.value}
                                                onChange={(event) => this.inputChangeHandler(event, "edit_permission")}
                                              />
                                              <span className="mb-0">
                                                Edit Permission
                                              </span>
                                            </label>
                                          </div>
                                        </div>

                                        <div className="col-sm-4 col-md-3">
                                          <div className="checkbox-inline">
                                            <label className="checkbox cursor-pointer ">
                                              <input
                                                type="checkbox"
                                                className="form-check-input me-1"
                                                value={permission_edit_form.view_permission.value}
                                                checked={permission_edit_form.view_permission.value}
                                                onChange={(event) => this.inputChangeHandler(event, "view_permission")}
                                              />
                                              <span className="mb-0">
                                                View Permission
                                              </span>
                                            </label>
                                          </div>
                                        </div>


                                        <div className="col-sm-4 col-md-3">
                                          <div className="checkbox-inline">
                                            <label className="checkbox cursor-pointer ">
                                              <input
                                                type="checkbox"
                                                className="form-check-input me-1"
                                                value={permission_edit_form.add_role.value}
                                                checked={permission_edit_form.add_role.value}
                                                onChange={(event) => this.inputChangeHandler(event, "add_role")}
                                              />
                                              <span className="mb-0">
                                                Add Role Name
                                              </span>
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col-sm-4 col-md-3">
                                          <div className="checkbox-inline">
                                            <label className="checkbox cursor-pointer ">
                                              <input
                                                type="checkbox"
                                                className="form-check-input me-1"
                                                value={permission_edit_form.edit_role.value}
                                                checked={permission_edit_form.edit_role.value}
                                                onChange={(event) => this.inputChangeHandler(event, "edit_role")}
                                              />
                                              <span className="mb-0">
                                                Edit Role Name
                                              </span>
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col-sm-4 col-md-3 mt-4">
                                          <div className="checkbox-inline">
                                            <label className="checkbox cursor-pointer ">
                                              <input
                                                type="checkbox"
                                                className="form-check-input me-1"
                                                value={permission_edit_form.add_duplicate_role.value}
                                                checked={permission_edit_form.add_duplicate_role.value}
                                                onChange={(event) => this.inputChangeHandler(event, "add_duplicate_role")}
                                              />
                                              <span className="mb-0">
                                                Add Duplicate Role
                                              </span>
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col-sm-4 col-md-3 mt-4">
                                          <div className="checkbox-inline">
                                            <label className="checkbox cursor-pointer ">
                                              <input
                                                type="checkbox"
                                                className="form-check-input me-1"
                                                value={permission_edit_form.deactivate_role.value}
                                                checked={permission_edit_form.deactivate_role.value}
                                                onChange={(event) => this.inputChangeHandler(event, "deactivate_role")}
                                              />
                                              <span className="mb-0">
                                                Deactivate Role
                                              </span>
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col-sm-4 col-md-3 mt-4">
                                          <div className="checkbox-inline">
                                            <label className="checkbox cursor-pointer ">
                                              <input
                                                type="checkbox"
                                                className="form-check-input me-1"
                                                value={permission_edit_form.delete_role.value}
                                                checked={permission_edit_form.delete_role.value}
                                                onChange={(event) => this.inputChangeHandler(event, "delete_role")}
                                              />
                                              <span className="mb-0">
                                                Delete Role
                                              </span>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* 
                                  <div className="col-md-12 sec-row manage-gender">
                                    <div className="row">
                                      <div className="col-md-2">
                                        <div class="ps-3">  <b>Manage Gender</b></div>
                                      </div>
                                      <div className="col-md-10">
                                        <div className="row">
                                          <div className="col-sm-4">
                                            <label className="cursor-pointer">
                                              <input
                                                type="radio"
                                                id="gender1"
                                                name="gen"
                                                className="form-check-input me-2 cursor-pointer"
                                                value={permission_edit_form.snapshot_gender_male.value}
                                                checked = {permission_edit_form.snapshot_gender_male.value === true ? permission_edit_form.snapshot_gender_male.value === true : null}
                                                onChange={(event) => this.inputChangeHandler(event, "snapshot_gender_male")}
                                              />
                                              Male
                                            </label>
                                          </div>
                                          <div className="col-sm-4">
                                            <label className="cursor-pointer">
                                              <input
                                                 type="radio"
                                                 id="gender2"
                                                 name="gen"
                                                className="form-check-input me-2 cursor-pointer"
                                                value={permission_edit_form.snapshot_gender_female.value}
                                                checked = {permission_edit_form.snapshot_gender_female.value === true ? permission_edit_form.snapshot_gender_female.value === true : null}
                                                onChange={(event) => this.inputChangeHandler( event, "snapshot_gender_female")}
                                              />
                                              Female
                                            </label>
                                          </div>
                                          <div className="col-sm-4">
                                            <label className="cursor-pointer">
                                              <input
                                                type="radio"
                                                id="genderall"
                                                name="gen"
                                                className="form-check-input me-2 cursor-pointer"
                                                value={permission_edit_form.snapshot_gender_both.value }
                                                checked = {permission_edit_form.snapshot_gender_both.value === true ? permission_edit_form.snapshot_gender_both.value === true : null }
                                                onChange={(event) => this.inputChangeHandler( event, "snapshot_gender_both")}
                                              />
                                              All
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div> */}



                                <div className=" sec-row border-bottom-0">
                                  <div className="row">
                                    <div className="col-md-2">
                                      <div className="ps-3">
                                        <b>Snapshot</b>
                                      </div>
                                    </div>
                                    <div className="col-md-10">
                                      <div className="row">
                                        <div className="col-sm-4 col-md-3">
                                          <div className="checkbox-inline">
                                            <label className="checkbox cursor-pointer ">
                                              <input
                                                type="checkbox"
                                                className="form-check-input me-1"
                                                value={permission_edit_form.view_snapshot.value}
                                                checked={permission_edit_form.view_snapshot.value}
                                                onChange={(event) => this.inputChangeHandler(event, "view_snapshot")}
                                              />
                                              <span className="mb-0">
                                                View Snapshot
                                              </span>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className=" sec-row border-bottom-0">
                                  <div className="row">
                                    <div className="col-md-2">
                                      <div className="ps-3">
                                        <b>Manage Activities</b>
                                      </div>
                                    </div>
                                    <div className="col-md-10">
                                      <div className="row">
                                        <div className="col-sm-4 col-md-3">
                                          <div className="checkbox-inline">
                                            <label className="checkbox cursor-pointer ">
                                              <input
                                                type="checkbox"
                                                className="form-check-input me-1"
                                                value={permission_edit_form.view_activities.value}
                                                checked={permission_edit_form.view_activities.value}
                                                onChange={(event) => this.inputChangeHandler(event, "view_activities")
                                                }
                                              />
                                              <span className="mb-0">
                                                View Activities
                                              </span>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>





                            <div className="permission-wrap mb-4">
                              <div className="d-flex permission-title">
                                <div className="title-left">
                                  <h6 className="mb-0">Students</h6>
                                </div>
                              </div>
                              <div className=" sec-row">
                                <div className="row">
                                  <div className="col-md-2">
                                    <div className="ps-3">
                                      <b>Student list</b>
                                    </div>
                                  </div>
                                  <div className="col-md-10">
                                    <div className="row">
                                      <div className="col-sm-4 col-md-3">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.view_student.value}
                                              checked={permission_edit_form.view_student.value}
                                              onChange={(event) => this.inputChangeHandler(event, "view_student")}
                                            />
                                            <span className="mb-0">
                                              View Student
                                            </span>
                                          </label>
                                        </div>
                                      </div>

                                      <div className="col-sm-4 col-md-3">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.activate_deactivate_student.value}
                                              checked={permission_edit_form.activate_deactivate_student.value}
                                              onChange={(event) => this.inputChangeHandler(event, "activate_deactivate_student")}
                                            />
                                            <span className="mb-0">
                                              Activate/Deactivate Student
                                            </span>
                                          </label>
                                        </div>
                                      </div>

                                      <div className="col-sm-4 col-md-3">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.edit_student.value}
                                              checked={permission_edit_form.edit_student.value}
                                              onChange={(event) => this.inputChangeHandler(event, "edit_student")}
                                            />
                                            <span className="mb-0">
                                              Edit Student
                                            </span>
                                          </label>
                                        </div>
                                      </div>

                                      <div className="col-sm-4 col-md-3">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.create_tempid.value}
                                              checked={permission_edit_form.create_tempid.value}
                                              onChange={(event) => this.inputChangeHandler(event, "create_tempid")}
                                            />
                                            <span className="mb-0">
                                              Create Temp Id
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-sm-4 col-md-3 mt-4">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.delete_student.value}
                                              checked={permission_edit_form.delete_student.value}
                                              onChange={(event) => this.inputChangeHandler(event, "delete_student")}
                                            />
                                            <span className="mb-0">
                                              Delete Student
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className=" sec-row">
                                <div className="row">
                                  <div className="col-md-2">
                                    <div className="ps-3">
                                      <b>Add Student</b>
                                    </div>
                                  </div>
                                  <div className="col-md-10">
                                    <div className="row">
                                      <div className="col-sm-4">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.add_student.value}
                                              checked={permission_edit_form.add_student.value}
                                              onChange={(event) => this.inputChangeHandler(event, "add_student")}
                                            />
                                            <span className="mb-0">
                                              Add Student
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className=" sec-row">
                                <div className="row">
                                  <div className="col-md-2">
                                    <div className="ps-3">
                                      <b>Allergic Students</b>
                                    </div>
                                  </div>
                                  <div className="col-md-10">
                                    <div className="row">
                                      <div className="col-sm-4 col-md-3">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.add_allergic_students.value}
                                              checked={permission_edit_form.add_allergic_students.value}
                                              onChange={(event) => this.inputChangeHandler(event, "add_allergic_students")}
                                            />
                                            <span className="mb-0">
                                              Add
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-sm-4 col-md-3">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.view_allergic_students.value}
                                              checked={permission_edit_form.view_allergic_students.value}
                                              onChange={(event) => this.inputChangeHandler(event, "view_allergic_students")}
                                            />
                                            <span className="mb-0">
                                              View
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-sm-4 col-md-3">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.edit_allergic_students.value}
                                              checked={permission_edit_form.edit_allergic_students.value}
                                              onChange={(event) => this.inputChangeHandler(event, "edit_allergic_students")}
                                            />
                                            <span className="mb-0">
                                              Edit
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-sm-4 col-md-3">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.delete_allergic_students.value}
                                              checked={permission_edit_form.delete_allergic_students.value}
                                              onChange={(event) => this.inputChangeHandler(event, "delete_allergic_students")}
                                            />
                                            <span className="mb-0">
                                              Delete
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className=" sec-row">
                                <div className="row">
                                  <div className="col-md-2">
                                    <div className="ps-3">
                                      <b>Student Reminder</b>
                                    </div>
                                  </div>
                                  <div className="col-md-10">
                                    <div className="row">
                                      <div className="col-sm-4 col-md-3">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.add_flag_students.value}
                                              checked={permission_edit_form.add_flag_students.value}
                                              onChange={(event) => this.inputChangeHandler(event, "add_flag_students")}
                                            />
                                            <span className="mb-0">
                                              Add
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-sm-4 col-md-3">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.view_flag_students.value}
                                              checked={permission_edit_form.view_flag_students.value}
                                              onChange={(event) => this.inputChangeHandler(event, "view_flag_students")}
                                            />
                                            <span className="mb-0">
                                              View
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-sm-4 col-md-3">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.edit_flag_students.value}
                                              checked={permission_edit_form.edit_flag_students.value}
                                              onChange={(event) => this.inputChangeHandler(event, "edit_flag_students")}
                                            />
                                            <span className="mb-0">
                                              Edit
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-sm-4 col-md-3">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.delete_flag_students.value}
                                              checked={permission_edit_form.delete_flag_students.value}
                                              onChange={(event) => this.inputChangeHandler(event, "delete_flag_students")}
                                            />
                                            <span className="mb-0">
                                              Delete
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className=" sec-row">
                                <div className="row">
                                  <div className="col-md-2">
                                    <div className="ps-3">
                                      <b>Grounded Student</b>
                                    </div>
                                  </div>
                                  <div className="col-md-10">
                                    <div className="row">
                                      <div className="col-sm-4 col-md-3">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.add_grounded_students.value}
                                              checked={permission_edit_form.add_grounded_students.value}
                                              onChange={(event) => this.inputChangeHandler(event, "add_grounded_students")}
                                            />
                                            <span className="mb-0">
                                              Add
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-sm-4 col-md-3">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.view_grounded_students.value}
                                              checked={permission_edit_form.view_grounded_students.value}
                                              onChange={(event) => this.inputChangeHandler(event, "view_grounded_students")}
                                            />
                                            <span className="mb-0">
                                              View
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-sm-4 col-md-3">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.edit_grounded_students.value}
                                              checked={permission_edit_form.edit_grounded_students.value}
                                              onChange={(event) => this.inputChangeHandler(event, "edit_grounded_students")}
                                            />
                                            <span className="mb-0">
                                              Edit
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-sm-4 col-md-3">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.view_custom_links.value}
                                              checked={permission_edit_form.view_custom_links.value}
                                              onChange={(event) => this.inputChangeHandler(event, "view_custom_links")}
                                            />
                                            <span className="mb-0">
                                              Delete
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className=" sec-row">
                                <div className="row">
                                  <div className="col-md-2">
                                    <div className="ps-3">
                                      <b>Import Students</b>
                                    </div>
                                  </div>
                                  <div className="col-md-10">
                                    <div className="row">
                                      <div className="col-sm-4 col-md-3">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.view_import_students.value}
                                              checked={permission_edit_form.view_import_students.value}
                                              onChange={(event) => this.inputChangeHandler(event, "view_import_students")}
                                            />
                                            <span className="mb-0">
                                              Access
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className=" sec-row">
                                <div className="row">
                                  <div className="col-md-2">
                                    <div className="ps-3">
                                      <b>Duplicate Students</b>
                                    </div>
                                  </div>
                                  <div className="col-md-10">
                                    <div className="row">
                                      <div className="col-sm-4 col-md-3">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.view_duplicate_students.value}
                                              checked={permission_edit_form.view_duplicate_students.value}
                                              onChange={(event) => this.inputChangeHandler(event, "view_duplicate_students")}
                                            />
                                            <span clps-3assName="mb-0">
                                              Access
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className=" sec-row border-bottom-0">
                                <div className="row">
                                  <div className="col-md-2">
                                    <div className="ps-3">
                                      <b>Change Dormitory</b>
                                    </div>
                                  </div>
                                  <div className="col-md-10">
                                    <div className="row">
                                      <div className="col-sm-4 col-md-3">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.view_change_dormitory.value}
                                              checked={permission_edit_form.view_change_dormitory.value}
                                              onChange={(event) => this.inputChangeHandler(event, "view_change_dormitory")}
                                            />
                                            <span className="mb-0">
                                              Access
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>






                            <div className="permission-wrap mb-4">
                              <div className="d-flex permission-title">
                                <div className="title-left">
                                  <h6 className="mb-0">Setting Tab</h6>
                                </div>
                              </div>


                              <div className=" sec-row">
                                <div className="row">
                                  <div className="col-md-2">
                                    <div className="ps-3">
                                      <b>Attendance Reports</b>
                                    </div>
                                  </div>
                                  <div className="col-md-10">
                                    <div className="row">
                                      <div className="col-sm-4 col-md-3">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.view_attendance_report.value}
                                              checked={permission_edit_form.view_attendance_report.value}
                                              onChange={(event) => this.inputChangeHandler(event, "view_attendance_report")}
                                            />
                                            <span className="mb-0">
                                              View
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className=" sec-row">
                                <div className="row">
                                  <div className="col-md-2">
                                    <div className="ps-3">
                                      <b>Messages</b>
                                    </div>
                                  </div>
                                  <div className="col-md-10">
                                    <div className="row">
                                      <div className="col-sm-4">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.edit_message.value}
                                              checked={permission_edit_form.edit_message.value}
                                              onChange={(event) => this.inputChangeHandler(event, "edit_message")}
                                            />
                                            <span className="mb-0">
                                              Edit Message
                                            </span>
                                          </label>
                                        </div>
                                      </div>

                                      <div className="col-sm-4">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.view_message.value}
                                              checked={permission_edit_form.view_message.value}
                                              onChange={(event) => this.inputChangeHandler(event, "view_message")}
                                            />
                                            <span className="mb-0">
                                              View Message
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>


                              <div className=" sec-row">
                                <div className="row">
                                  <div className="col-md-2">
                                    <div className="ps-3">
                                      <b>Custom Links</b>
                                    </div>
                                  </div>
                                  <div className="col-md-10">
                                    <div className="row">
                                      <div className="col-sm-4 col-md-3">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.view_custom_links.value}
                                              checked={permission_edit_form.view_custom_links.value}
                                              onChange={(event) => this.inputChangeHandler(event, "view_custom_links")}
                                            />
                                            <span className="mb-0">
                                              Set
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>


                              <div className=" sec-row">
                                <div className="row">
                                  <div className="col-md-2">
                                    <div className="ps-3">
                                      <b>Student Rollover</b>
                                    </div>
                                  </div>

                                  <div className="col-md-10">
                                    <div className="row">
                                      <div className="col-sm-4 col-md-3">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.view_rollover_students.value}
                                              checked={permission_edit_form.view_rollover_students.value}
                                              onChange={(event) => this.inputChangeHandler(event, "view_rollover_students")}
                                            />
                                            <span className="mb-0">
                                              Access
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>


                              <div className=" sec-row">
                                <div className="row">
                                  <div className="col-md-2">
                                    <div className="ps-3">
                                      <b>Roll call Session Time*</b>
                                    </div>
                                  </div>

                                  <div className="col-md-10">
                                    <div className="row">
                                      <div className="col-sm-4 col-md-3">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.view_rollcall_session_time.value}
                                              checked={permission_edit_form.view_rollcall_session_time.value}
                                              onChange={(event) => this.inputChangeHandler(event, "view_rollcall_session_time")}
                                            />
                                            <span className="mb-0">
                                              Set
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>


                              <div className=" sec-row">
                                <div className="row">
                                  <div className="col-md-2">
                                    <div className="ps-3">
                                      <b>On Campus Details</b>
                                    </div>
                                  </div>

                                  <div className="col-md-10">
                                    <div className="row">
                                      <div className="col-sm-4 col-md-3">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.view_oncampus_details.value}
                                              checked={permission_edit_form.view_oncampus_details.value}
                                              onChange={(event) => this.inputChangeHandler(event, "view_oncampus_details")}
                                            />
                                            <span className="mb-0">
                                              Set
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>


                              <div className=" sec-row border-bottom-0">
                                <div className="row">
                                  <div className="col-md-2">
                                    <div className="ps-3">
                                      <b>Calendar</b>
                                    </div>
                                  </div>

                                  <div className="col-md-10">
                                    <div className="row">
                                      <div className="col-sm-4 col-md-4">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.view_calendar.value}
                                              checked={permission_edit_form.view_calendar.value}
                                              onChange={(event) => this.inputChangeHandler(event, "view_calendar")}
                                            />
                                            <span className="mb-0">
                                              View
                                            </span>
                                          </label>
                                        </div>
                                      </div>

                                      <div className="col-sm-4 col-md-4">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.add_calendar.value}
                                              checked={permission_edit_form.add_calendar.value}
                                              onChange={(event) => this.inputChangeHandler(event, "add_calendar")}
                                            />
                                            <span className="mb-0">
                                              Add
                                            </span>
                                          </label>
                                        </div>
                                      </div>

                                      <div className="col-sm-4 col-md-4">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.edit_calendar.value}
                                              checked={permission_edit_form.edit_calendar.value}
                                              onChange={(event) => this.inputChangeHandler(event, "edit_calendar")}
                                            />
                                            <span className="mb-0">
                                              Edit
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>

                            <div className="permission-wrap mb-4">
                              <div className="d-flex permission-title">
                                <div className="title-left">
                                  <h6 className="mb-0">Leave Permissions</h6>
                                </div>
                              </div>


                              <div className=" sec-row">
                                <div className="row">
                                  <div className="col-md-2">
                                    <div className="ps-3">
                                      <b>Manage Boarders Leave</b>
                                    </div>
                                  </div>
                                  <div className="col-md-10">
                                    <div className="row">
                                      <div className="col-sm-4">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.view_boarder_leaves.value}
                                              checked={permission_edit_form.view_boarder_leaves.value}
                                              onChange={(event) => this.inputChangeHandler(event, "view_boarder_leaves")}
                                            />
                                            <span className="mb-0">
                                              View
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-sm-4">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.add_boarder_leaves.value}
                                              checked={permission_edit_form.add_boarder_leaves.value}
                                              onChange={(event) => this.inputChangeHandler(event, "add_boarder_leaves")}
                                            />
                                            <span className="mb-0">
                                              Add
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-sm-4">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.edit_boarder_leaves.value}
                                              checked={permission_edit_form.edit_boarder_leaves.value}
                                              onChange={(event) => this.inputChangeHandler(event, "edit_boarder_leaves")}
                                            />
                                            <span className="mb-0">
                                              Edit
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className=" sec-row">
                                <div className="row">
                                  <div className="col-md-2">
                                    <div className="ps-3">
                                      <b>Manage Adhoc Leave</b>
                                    </div>
                                  </div>
                                  <div className="col-md-10">
                                    <div className="row">
                                      <div className="col-sm-4">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.view_adhoc_leave.value}
                                              checked={permission_edit_form.view_adhoc_leave.value}
                                              onChange={(event) => this.inputChangeHandler(event, "view_adhoc_leave")}
                                            />
                                            <span className="mb-0">
                                              View
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-sm-4">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.add_adhoc_leave.value}
                                              checked={permission_edit_form.add_adhoc_leave.value}
                                              onChange={(event) => this.inputChangeHandler(event, "add_adhoc_leave")}
                                            />
                                            <span className="mb-0">
                                              Add
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-sm-4">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.edit_adhoc_leave.value}
                                              checked={permission_edit_form.edit_adhoc_leave.value}
                                              onChange={(event) => this.inputChangeHandler(event, "edit_adhoc_leave")}
                                            />
                                            <span className="mb-0">
                                              Edit
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className=" sec-row">
                                <div className="row">
                                  <div className="col-md-2">
                                    <div className="ps-3">
                                      <b>Manage Reports</b>
                                    </div>
                                  </div>
                                  <div className="col-md-10">
                                    <div className="row">
                                      <div className="col-sm-4">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.view_reports.value}
                                              checked={permission_edit_form.view_reports.value}
                                              onChange={(event) => this.inputChangeHandler(event, "view_reports")}
                                            />
                                            <span className="mb-0">
                                              View
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className=" sec-row">
                                <div className="row">
                                  <div className="col-md-2">
                                    <div className="ps-3">
                                      <b>Manage Travel Mode</b>
                                    </div>
                                  </div>
                                  <div className="col-md-10">
                                    <div className="row">
                                      <div className="col-sm-3">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.view_travel_mode.value}
                                              checked={permission_edit_form.view_travel_mode.value}
                                              onChange={(event) => this.inputChangeHandler(event, "view_travel_mode")}
                                            />
                                            <span className="mb-0">
                                              View
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-sm-3">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.add_travel_mode.value}
                                              checked={permission_edit_form.add_travel_mode.value}
                                              onChange={(event) => this.inputChangeHandler(event, "add_travel_mode")}
                                            />
                                            <span className="mb-0">
                                              Add
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-sm-3">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.edit_travel_mode.value}
                                              checked={permission_edit_form.edit_travel_mode.value}
                                              onChange={(event) => this.inputChangeHandler(event, "edit_travel_mode")}
                                            />
                                            <span className="mb-0">
                                              Edit
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-sm-3">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.delete_travel_mode.value}
                                              checked={permission_edit_form.delete_travel_mode.value}
                                              onChange={(event) => this.inputChangeHandler(event, "delete_travel_mode")}
                                            />
                                            <span className="mb-0">
                                              Delete
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-sm-3">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.activate_deactivate_travel_mode.value}
                                              checked={permission_edit_form.activate_deactivate_travel_mode.value}
                                              onChange={(event) => this.inputChangeHandler(event, "activate_deactivate_travel_mode")}
                                            />
                                            <span className="mb-0">
                                              Activate/Deactivate Mode
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className=" sec-row">
                                <div className="row">
                                  <div className="col-md-2">
                                    <div className="ps-3">
                                      <b>Manage Leave Rejection Reason</b>
                                    </div>
                                  </div>
                                  <div className="col-md-10">
                                    <div className="row">
                                      <div className="col-sm-3">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.view_rejection_reason.value}
                                              checked={permission_edit_form.view_rejection_reason.value}
                                              onChange={(event) => this.inputChangeHandler(event, "view_rejection_reason")}
                                            />
                                            <span className="mb-0">
                                              View
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-sm-3">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.add_rejection_reason.value}
                                              checked={permission_edit_form.add_rejection_reason.value}
                                              onChange={(event) => this.inputChangeHandler(event, "add_rejection_reason")}
                                            />
                                            <span className="mb-0">
                                              Add
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-sm-3">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.edit_rejection_reason.value}
                                              checked={permission_edit_form.edit_rejection_reason.value}
                                              onChange={(event) => this.inputChangeHandler(event, "edit_rejection_reason")}
                                            />
                                            <span className="mb-0">
                                              Edit
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-sm-3">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.delete_rejection_reason.value}
                                              checked={permission_edit_form.delete_rejection_reason.value}
                                              onChange={(event) => this.inputChangeHandler(event, "delete_rejection_reason")}
                                            />
                                            <span className="mb-0">
                                              Delete
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-sm-3">
                                        <div className="checkbox-inline">
                                          <label className="checkbox cursor-pointer ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input me-1"
                                              value={permission_edit_form.activate_deactivate_rejection_reason.value}
                                              checked={permission_edit_form.activate_deactivate_rejection_reason.value}
                                              onChange={(event) => this.inputChangeHandler(event, "activate_deactivate_rejection_reason")}
                                            />
                                            <span className="mb-0">
                                              Activate/Deactivate Reason
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex border-0 justify-content-end">
                              <button
                                type="reset"
                                className="btn btn-sm btn-light me-2 min-150px"
                                onClick={(event) => this.ChangePermissions(false)}
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                className="btn btn-sm btn-primary min-150px"
                              >
                                Update
                              </button>
                            </div>
                          </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    // error: state.students.error,
    // loader: state.students.loader,
    // isAuthenticated: state.students.isAuthenticated,
  };
}

const mapDispatchToProps = {
  onUpdateManagerPermissions: userActions.UpdateManagerPermissions,
  onUpdateSchooManager: userActions.UpdateSchooManager,
  onGetSchoolManagerById: userActions.GetSchoolManagerById,
  onGetManagerPermissonDetailsById: userActions.GetManagerPermissonDetailsById,
  onGetAllDormitoryStudent: userActions.GetAllDormitoryStudent,
  onGetAllClassesList: userActions.GetAllClassesList,
  onGetLoginUserPermissions: userActions.GetLoginUserPermissions,
};
export default connect(mapStateToProps, mapDispatchToProps)(ManagerPermission);