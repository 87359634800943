
// import {socket } from '../../constants/actionTypes'
import { toast } from "react-toastify";
import React, { useState, useEffect, useCallback,  useRef } from "react";
import * as userActions from "../../actions/index";
// import addNotification from 'react-push-notification';
// import { toast } from "react-toastify";
import { connect, useDispatch } from "react-redux";
// import Dropdown from 'react-bootstrap/Dropdown';
import moment from "moment";
import "moment-timezone";
import { useNavigate } from "react-router-dom";
import { socket } from "../../constants/actionTypes";
import Scrollbar from "../UI/ScrollBar";
const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
// localStorage.removeItem("bss_authuser");
// const Socket_Toast = JSON.parse(localStorage.getItem("Socket_calls"));

const user_id = user_info?.user_id;

let notificationGloabal;
const NotificationComponent = (props) => {
    const dispatch = useDispatch();
    // const [message, setMessage] = useState(["Message", "Hello", "Mitika"]);
    const [studentData, setStudentData] = useState([]);
    const [socketcalled, setSocketcalled] = useState();
    
    // const [clickOutside, setClickOutside] = useState(false);

    const [notificationCount, setNotificationCount] = useState();
    const [show, setShow] = useState(false);
    const firstOpen = useRef(true);
    const myref = useRef();
    const navigate = useNavigate();
    useEffect(()=> {
        const handleClickOutside = e => {
            if (!myref.current.contains(e.target)) {
                // setClickOutside(true);
                setShow(false)
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [myref])

    useEffect(()=> {
        const Socket_Toast = JSON.parse(localStorage.getItem("Socket_calls"));
        setSocketcalled(Socket_Toast)
    })

   const showNotification = () => {
    if (firstOpen.current) {
        firstOpen.current = !firstOpen.current
    }
    if(show === false) {
        setShow(true)
        GetStuLocNotificationsbyUserId()
    } else if(show === true){
        setShow(false)
    }
//     if(show === true) {
//     if(studentData !== [] || studentData !== "" ) {
//      markViewed()
// }}
   }
    // console.log("NOTIFICATION COUNT", notificationCount)
        const ConfirmRemove = () => {

            let Payload = {user_id: user_id}
            props.onDeleteStuLocNotificationsbyUserId(Payload).then((response) => {
                // console.log("delte api response-----222222222", response)
                if (response.success === true) {
                    GetStuLocNotificationsbyUserId();
                    // notificationCount = 0;
                    setNotificationCount(0)
                 
                } else {}
            });
        };
        const markViewed = () => {
            
            let Payload = {user_id: user_id}
            // console.log('Notification viewed', user_id)
            // console.log("PAYLOAD--33333", Payload)

            if(user_id !== undefined) {
            props.onUpdateStuLocNotificationStatus(Payload).then((response) => {
        if (response.success === true) {
            setNotificationCount(0)
            // console.log("---student-data", studentData)
        } else { }
    });
}
};
    useEffect(() => {
        GetStuLocNotificationsbyUserId()
        // console.log("Notification API call in useEffect on click in snapshot")
        // console.log("2222-----notificationNo@@@", Socket_Toast)
    }, [props.callNotification, socketcalled])



    const GetStuLocNotificationsbyUserId = useCallback(() => {
            dispatch(userActions.GetStuLocNotificationsbyUserId()).then((response) => {
                if (response.success === true) {
                //    console.log("Notification response",response.data)
                   const arrayData = response.data.reverse();
                   let messageLevel = [];
                   let notificationNo = 0; 

                   for (let key in arrayData) {
                    
                    messageLevel.push({

                        date: arrayData[key]?.created_date,
                        student_first_name: arrayData[key]?.bss_student?.student_first_name,
                        student_last_name: arrayData[key]?.bss_student?.student_last_name,
                        class_name: arrayData[key]?.bss_student?.class_name,
                        student_id: arrayData[key]?.bss_student?.student_id,
                        dormitory_id: arrayData[key]?.bss_student?.dormitory_data.dormitory_id,
                        dormitory_name: arrayData[key]?.bss_student?.dormitory_data?.dormitory_name,
                        manager_name: arrayData[key]?.bss_user?.first_name + " " + arrayData[key]?.bss_user?.last_name, 
                        manager_id:  arrayData[key]?.bss_user?.user_id,
                        session_name: arrayData[key]?.atten_user_data?.attendance_title ? arrayData[key]?.atten_user_data?.attendance_title : "",
                        is_notification_read: arrayData[key]?.is_notification_read 
                    });
                    if (arrayData[key]?.is_notification_read === false) {
                        notificationNo = notificationNo  + 1
                    } else {} 
                }
                    setNotificationCount(notificationNo)
                   setStudentData(messageLevel)
                   notificationGloabal = notificationNo
    
                } else {
                    // notify.show(response.message, "error");
                }
            });
        }, [dispatch]);
        // useEffect(() => {
        //     GetStuLocNotificationsbyUserId();
        //     // eslint-disable-next-line
        // }, []);
    // console.log("MESSAGE MESSAGE for notification from API", studentData)

    const displayNotification = ({ date, student_first_name, student_last_name, class_name, dormitory_name, manager_name, session_name, is_notification_read}) => {
        let session_title;
        if(session_name){
          session_title = session_name
        } else { session_title = "Snapshot"}
    
        const current = new Date(date);
        const timezone = user_info.user_school.timezone;
        const time = moment(current).tz(timezone).format('MMMM Do YYYY, h:mm a');
        
        return (
          <span className={is_notification_read === false ? 'notification unread' : 'notification read'}>
             {`${student_first_name }  ${student_last_name} of ${class_name} from ${dormitory_name}  has been listed as MIA by ${manager_name} at ${time} via ${session_title} `} 
            </span>
        // )};
        // // let time = 
        // return (
        //   <span className="notification"
        //   style={is_notification_read === false ? {backgroundColor: "#b6b7b8"}: {backgroundColor: "white"}}>
        //      {`${student_first_name }  ${student_last_name} of ${class_name} from ${dormitory_name}  has been listed as MIA by ${manager_name} at ${time} via ${session_title} `} 
        //     </span>
        )};
    
    return (
        <div className="page" ref={myref}>
        <div className="bell"  onClick={() => showNotification()} style={{ position: "relative"}}>
        <i className="fas fa-bell"></i>
        { notificationCount >0 && 
        <div className='badge p-2' style={{ borderRadius: "50%" }}>
            {notificationCount}
            </div> }
        </div>
        
    {show && ( 
        <div className="notifications">
        <div className='d-flex justify-content-between notification_block'> 
        <span className='fw-bold fs-3'>Notification</span>
        <button className='background btn-link' onClick={() => ConfirmRemove(studentData.manager_id)}> Mark as read </button>
        {markViewed()}
        </div>
          <Scrollbar>
          {studentData.map((item) => displayNotification(item))}
          </Scrollbar>
          
        </div>
      )}
        </div>
    );
};


const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
    onUpdateStuLocNotificationStatus: userActions.UpdateStuLocNotificationStatus,
    onDeleteStuLocNotificationsbyUserId: userActions.DeleteStuLocNotificationsbyUserId,
};



export default connect(mapStateToProps, mapDispatchToProps) (NotificationComponent);