import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";

const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

const school_code = user_info?.school_code === null || user_info === null ? "" : user_info?.school_code;

const StatusFormatter = ({ row, handleChange }) => (
    <div key={"repeat_" + row.activity_uuid}>
        <span className="ms-2 form-check form-switch form-switch-sm form-check-custom form-check-solid">
            <input 
                type="checkbox" 
                className="form-check-input" 
                checked={row.is_activity_activate}
                onChange={(event) => {
                    handleChange(event, row.is_activity_activate, row.activity_uuid);
                }}
            />
        </span>
    </div>
);

const ActionFormatter = ({ row }) => (
    <div key={"repeat_" + row.activity_uuid} className="text-right">
        <span className="edit_icon me-2">
            <Link to={`/${school_code}/leave/ad-hoc-activity-off-campus/edit-ad-hoc-activity/${row.activity_uuid}`}
                state={{ data: row }}
                className="mx-1 btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
            >
                <span className="svg-icon svg-icon-3">
                    <i className="fas fa-pen"></i>
                </span>
            </Link>
        </span>
    </div>
);

const AddHocActivityItems = (props) => {
    const [perPageData, setPerPageData] = useState(10);

    const columns = [
        {
            text: 'S.No.',
            dataField: 'perpage',
        },
        {
            text: "Activity",
            dataField: "activity_name",
        },
        {
            text: "Status",
            dataField: "Actions",
            formatter: (cellContent, row, index, extraData) => <StatusFormatter row={row} handleChange={props.handleChange} />,
        },
        {
            text: "Action",
            dataField: "Actions",
            formatter: (cellContent, row, index, extraData) => <ActionFormatter row={row} />,
        },
    ];

    const handleTableChange = (type, { sortField, sortOrder, page }) => {
        if (type === "sort") {
            props.propsfiltersort(sortField);
            props.propsfilterorder(sortOrder);
        }

        if (type === "pagination") {
            props.propsfilterpage(page);
        }
    };
    const RemotePagination = ({ columns, dataField, order, data, page, sizePerPage, onTableChange, totalSize }) => (
        <PaginationProvider
            pagination={paginationFactory({
                custom: true,
                page,
                sizePerPage,
                totalSize,
            })}
        >
            {({ paginationProps, paginationTableProps }) => (
                <>
                    <div className="table-responsive common-table custom-message-table">
                        <BootstrapTable
                            remote
                            bootstrap4
                            data={data}
                            keyField="activity_uuid"
                            sort={{ dataField: dataField, order: order }}
                            columns={columns}
                            {...paginationTableProps}
                            onTableChange={onTableChange}
                            sizePerPageList={sizePerPage}
                            headerClasses="fw-bolder text-white bg-dark-row"
                            classes="table align-middle table-row-bordered  fs-6 gs-4 gy-4 gx-4"
                            noDataIndication={() => <span className="text-center text-gray-400">No records found</span>}
                        />
                    </div>
                    <div className="d-flex flex-stack justify-content-between flex-wrap pt-3 pagination-main">

                        {totalSize > 0 && (
                            <div className="pagination_limt">
                                <select 
                                    id='select' 
                                    name='group' 
                                    className='form-control input-sm form-select form-select-sm form-select-solid' 
                                    size='1' 
                                    value={perPageData}
                                    onChange={(event) => {
                                        props.propsfilterpage(1);
                                        setPerPageData(event.target.value);
                                        props.propsfilterlimit(parseInt(event.target.value));
                                    }}
                                >
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={250}>250</option>
                                    <option value={500}>500</option>
                                </select>
                                {(parseInt(sizePerPage) * parseInt(page)) > totalSize ? (
                                    <div className="count_number">Displaying {(parseInt(sizePerPage) * parseInt(page) + 1) - parseInt(perPageData)}-{totalSize} of {totalSize} records</div>
                                ) : (
                                    <div className="count_number">Displaying {(parseInt(sizePerPage) * parseInt(page) + 1) - parseInt(perPageData)}-{parseInt(sizePerPage) * parseInt(page)} of {totalSize} records</div>
                                )}
                            </div>
                        )}
                        <div className="pagination_page">
                            <PaginationListStandalone {...paginationProps} />
                        </div>
                    </div>
                </>
            )}
        </PaginationProvider>
    );
    return (
        <React.Fragment>
            <div className="card-body">
                <div className="table-responsive">
                    <div id="kt_profile_overview_table" className="dataTables_wrapper dt-bootstrap4 no-footer team-list-table">
                        <div className="table-responsive">
                            <RemotePagination
                                data={props.data}
                                columns={columns}
                                page={props.filterpage}
                                totalSize={props.addActivityListcount}
                                sizePerPage={props.filterlimit}
                                order={props.filterorder}
                                onTableChange={handleTableChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AddHocActivityItems;
