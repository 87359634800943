import React from "react";
import Validations from "../../Utility/Validations";
import * as userActions from "../../../actions/index";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Sidebar from "../../Constant/sidebar";
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";
import { Navigate } from "react-router-dom";

const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info.school_code;

class EditOnCampusModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            oncampus_form: {
                location_name: {
                    type: "text",
                    label: "Edit Location",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                on_campus_location_uuid: {
                    type: "text",
                    label: "",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
            },

        };
    }

    componentDidMount() {

        this.GetOnCampusLocationById();

    }

    GetOnCampusLocationById() {
        this.props.onGetOnCampusLocationById(this.props.on_campus_location_uuid).then((response) => {
            console.log("PROPS", this.props);
            if (response.success === true) {
                let oncampus_data = response.data;
                const update_oncampus_form = { ...this.state.oncampus_form };
                for (let key in update_oncampus_form) {
                    let form_element = update_oncampus_form[key];
                    if (oncampus_data[key]) {

                        update_oncampus_form['location_name'].value = oncampus_data.location_name;
                        update_oncampus_form['on_campus_location_uuid'].value = oncampus_data.on_campus_location_uuid;

                        this.setState({ oncampus_form: update_oncampus_form });
                        update_oncampus_form[key] = form_element;
                    }
                }

                this.setState({ oncampus_form: update_oncampus_form });
            } else {
            }
        });

    }


    inputChangeHandler(event, identifier) {
        const update_oncampus_form = { ...this.state.oncampus_form };
        const form_element = { ...update_oncampus_form[identifier] };
        form_element.value = event.target.value;
        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_oncampus_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_oncampus_form) {
            is_form_valid = update_oncampus_form[identifier].valid && is_form_valid;
        }
        this.setState({
            oncampus_form: update_oncampus_form,
            is_form_valid: is_form_valid,
        });
    }




    SubmitHandler = async (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_oncampus_form = this.state.oncampus_form;
        for (let key in update_oncampus_form) {
            let form_element = update_oncampus_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_oncampus_form[key] = form_element;
        }
        this.setState({ oncampus_form: update_oncampus_form });
        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.oncampus_form) {
                form_data[key] = this.state.oncampus_form[key].value;
            }
            console.log("form_data===", form_data)

            this.props.onUpdateOnCampusLocationDetails(form_data).then((response) => {
                if (response.success === true) {
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: `${school_code}/settings/on-campus-location`,
                        });
                    }, 1000);
                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            });
        }

    };
    render() {

        const { oncampus_form } = this.state;
        console.log(oncampus_form);
        return (
            <React.Fragment>
                {this.state.isAuthenticated ? <Navigate to={this.state.redirect_url} /> : null}

                <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed" >
                    <div className="d-flex flex-column flex-root">
                        <div className="page d-flex flex-row flex-column-fluid">
                            <Sidebar />
                            <div className="wrapper d-flex flex-column flex-row-fluid " id="kt_wrapper">
                                <TopBar />
                                <div className="content d-flex flex-column flex-column-fluid " id="kt_content">
                                    <div className="post d-flex flex-column-fluid" id="kt_post">
                                        <div id="kt_content_container" className="container-fluid d-flex justify-content-center">
                                            <div className="col-md-6">
                                                <div className="card">
                                                    <div className="card-header border-bottom pt-5 pb-5">
                                                        <h3 className="card-title align-items-start flex-column">
                                                            <span className="card-label fw-bolder fs-3 mb-1">Edit On Campus Details</span>
                                                        </h3>
                                                    </div>
                                                    <div className="card-body">
                                                        <form className="form w-100" onSubmit={this.SubmitHandler}>
                                                            <div className="row">
                                                                <div className="col-lg-12 fv-row">
                                                                    <div className="d-flex flex-column align-items-center">
                                                                        <label className="fw-bold  white-space-nowrap mb-5 d-flex w-100">{oncampus_form.location_name.label}</label>

                                                                        <input
                                                                            type={oncampus_form.location_name.type}
                                                                            value={oncampus_form.location_name.value ? oncampus_form.location_name.value : ""}
                                                                            className="form-control"
                                                                            placeholder={oncampus_form.location_name.placeholder}
                                                                            onChange={(event) => this.inputChangeHandler(event, "location_name")}
                                                                        />

                                                                        {!oncampus_form.location_name.valid && oncampus_form.location_name.onBlur_out ? <div className="error field-error">{oncampus_form.location_name.error_msg}</div> : ""}
                                                                        <button type="submit" className="btn btn-sm btn-primary mt-5">
                                                                            Save
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Footer />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}

const mapDispatchToProps = {
    onUpdateOnCampusLocationDetails: userActions.UpdateOnCampusLocationDetails,
    onGetOnCampusLocationById: userActions.GetOnCampusLocationById,
};
export default connect(mapStateToProps, mapDispatchToProps)(EditOnCampusModal);
