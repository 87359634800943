import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "react-bootstrap";

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';


const ImportCsvModal = ({ showModal, csv_student_details, import_student_form, showModalHandler, getDatabyFirstModal, openSecondModal }) => {
    const [csv_student_detail, setCsv_student_detail] = useState([])
    useEffect(() => {
        setCsv_student_detail(csv_student_details)

    }, [csv_student_details])

    console.log("response============");
    const changeVailid = (e, index) => {
        let setObj = csv_student_detail.map((item, ind) => {
            if (index === ind) {
                return ({ ...item, valid: e.target.checked })
            } else {
                return item
            }
        })
        setCsv_student_detail(setObj)
    }

    return (
        <>

            <Modal
                backdrop="static"
                keyboard={false}
                className="confirm_delete_modal confirm_modal"
                show={showModal}
                aria-labelledby="lg-modal"
                size="xl"
            >
                <Modal.Header>
                    <Modal.Title>Import Students</Modal.Title>
                    <button type="reset" onClick={() => showModalHandler(false, csv_student_detail)} >
                        <i class="fa fa-times" aria-hidden="true"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="card">
                        <div className="row">
                            <div className="col-12">
                                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                    <TableContainer sx={{ maxHeight: 440 }}>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Sr.No</TableCell>
                                                    <TableCell style={{ minWidth: "50px" }}>Select</TableCell>
                                                    <TableCell style={{ minWidth: "200px" }} align="left">First Name</TableCell>
                                                    <TableCell style={{ minWidth: "200px" }} align="left">Last Name</TableCell>
                                                    <TableCell style={{ minWidth: "200px" }} align="left">Student E-Mail Address </TableCell>
                                                    <TableCell style={{ minWidth: "200px" }} align="left" >Preferred Name</TableCell>
                                                    <TableCell style={{ minWidth: "200px" }} align="left">Date Of Birth</TableCell>
                                                    <TableCell style={{ minWidth: "200px" }} align="left">Gender</TableCell>
                                                    <TableCell style={{ minWidth: "200px" }} align="left">Student Phone</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {csv_student_detail && csv_student_detail.map((row, index) => {
                                                    return (
                                                        <TableRow hover>
                                                            {/* {console.log("row_data", row?.error[0] === undefined ? "" : row?.error[0].toString())} */}
                                                            <TableCell>{index + 1}</TableCell>

                                                            <TableCell><Checkbox onClick={(event) => changeVailid(event, index)} disabled={row.error !== undefined ? true : false} checked={row.valid} size="small" /></TableCell>

                                                            <TableCell className={row.student_first_name === "" ? "text-danger" : null} >{row.student_first_name === "" ? "Required *" : row.student_first_name}</TableCell>

                                                            <TableCell className={row.student_last_name === "" ? "text-danger" : null} >{row.student_last_name === "" ? "Required *" : row.student_last_name}</TableCell>

                                                            <TableCell className={row.error !== undefined ? (row?.error[0].toString().includes("email") || row.student_email === "" ? "text-danger" : "") : ""}>{row.student_email === "" ? "Required *" : row.student_email}</TableCell>

                                                            <TableCell className={row.error !== undefined ? (row?.error[0].toString().includes("perferred") || row.preferred_name === "" ? "text-danger" : "") : ""}>{row.preferred_name === "" ? "Required *" : row.preferred_name}</TableCell>

                                                            <TableCell className={row.date_of_birth === "" ? "text-danger" : null}>{row.date_of_birth === "" ? "Required *" : row.date_of_birth}</TableCell>

                                                            <TableCell className={row.gender === "" ? "text-danger" : null}>{row.gender === "" ? "Required *" : row.gender}</TableCell>

                                                            <TableCell className={row.error !== undefined ? (row?.error[0].toString().includes("student phone") || row.student_phone === "" ? "text-danger" : "") : ""}>{row.student_phone === "" ? "" : row.student_phone}</TableCell>


                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                </Paper>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex align-items-end">
                        <button type="reset" className="btn btn-sm btn-light me-2 min-150px ms-auto" onClick={() => showModalHandler(false, csv_student_detail)} >
                            Cancel
                        </button>
                        <button type="button" onClick={() => openSecondModal(csv_student_detail)} className="btn btn-sm btn-primary apply-btn min-150px ms-auto">
                            Upload
                        </button>
                    </div>
                </Modal.Footer>
            </Modal >
        </>
    )
};


export default ImportCsvModal