import axios from "axios";
import * as actionType from "../constants/actionTypes";
// let qs = require("qs");

/*** SignIn Action ***/
export const SignInStart = () => {
    return {
        error: null,
        loader: true,
        type: actionType.SignIn_START,
    };
};
export const SignInSuccess = (response) => {
    return {
        token: false,
        loader: false,
        type: actionType.SignIn_SUCCESS,
    };
};
export const SignInFail = (error) => {
    return {
        error: error,
        loader: false,
        type: actionType.SignIn_FAIL,
    };
};
export const SignIn = (form_data) => {
    return (dispatch) => {
        dispatch(SignInStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/SignIn`,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {
                if (response.data.success === true) {
                    localStorage.setItem("bss_authtoken", response.data.token);
                    localStorage.setItem("bss_authuser", JSON.stringify(response.data.data));
                }
                dispatch(SignInSuccess("User login successfully !!!"));
                return response.data;
            })
            .catch(function (error) {
                dispatch(SignInFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


export const isAuthorize = () => {
    const bss_authuser = localStorage.getItem("bss_authuser");
    const bss_authtoken = localStorage.getItem("bss_authtoken");
    if (bss_authuser !== null && bss_authtoken !== null){
        return bss_authtoken ? true : false;
    }
    //return bss_authtoken ? true : false;
};

/*** Logout Action ***/
export const logout = () => {
    return axios({
        method: "post",
        url: `${actionType.environment_url}/api/Logout`,
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
        },
    })
        .then(function (response) {
            if (response.data.Success === true) {
                localStorage.removeItem("bss_authuser");
                localStorage.removeItem("bss_authtoken");
                localStorage.removeItem("Rollcal_Final");
                localStorage.removeItem("Rollcal_List");
                localStorage.removeItem("Rollcal_Data_Global");
                localStorage.removeItem("RollCall_time");
                localStorage.removeItem("SessionName");
                
                localStorage.removeItem("Dormitory_filters");
                localStorage.removeItem("Class_filters");
                localStorage.removeItem("Gender_filters");
                localStorage.removeItem("role_id");
                localStorage.removeItem("user_uuid");

                localStorage.removeItem("permissions");
                // localStorage.removeItem("check_box_all");
                localStorage.removeItem("status_select_all");

                localStorage.removeItem("Manager_id");
                localStorage.removeItem("Socket_calls");
                
            }
            return response.data;
        })
        .catch(function (error) {
            return error;
        });
};


/*** ForgotPassword Action ***/
export const ForgotPasswordStart = () => {
    return {
        error: null,
        loader: true,
        type: actionType.ForgotPassword_START,
    };
};
export const ForgotPasswordSuccess = (response) => {
    return {
        token: false,
        loader: false,
        type: actionType.ForgotPassword_SUCCESS,
    };
};
export const ForgotPasswordFail = (error) => {
    return {
        error: error,
        loader: false,
        type: actionType.ForgotPassword_FAIL,
    };
};
export const ForgotPassword = (form_data) => {
    return (dispatch) => {
        dispatch(ForgotPasswordStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/ForgotPassword`,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {
                dispatch(ForgotPasswordSuccess("User login successfully !!!"));
                return response.data;
            })
            .catch(function (error) {
                dispatch(ForgotPasswordFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** ResetPassword Action ***/
export const ResetPasswordStart = () => {
    return {
        error: null,
        loader: true,
        type: actionType.ResetPassword_START,
    };
};
export const ResetPasswordSuccess = (response) => {
    return {
        token: false,
        loader: false,
        type: actionType.ResetPassword_SUCCESS,
    };
};
export const ResetPasswordFail = (error) => {
    return {
        error: error,
        loader: false,
        type: actionType.ResetPassword_FAIL,
    };
};
export const ResetPassword = (form_data) => {
    return (dispatch) => {
        dispatch(ResetPasswordStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/ResetPassword`,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {
                dispatch(ResetPasswordSuccess("User login successfully !!!"));
                return response.data;
            })
            .catch(function (error) {
                dispatch(ResetPasswordFail("Something went wrong, Please try again."));
                return error;
            });
    };
};