import React from "react";
import Sidebar from "../../Constant/sidebar";
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";
import Select from "react-select";
import Validations from "../../Utility/Validations";
import { connect } from "react-redux";
import * as userActions from "../../../actions/index";
import { toast } from "react-toastify";
import RollOverStudentModal from "./Modal/RolloverStudentModal";
import Timer from "./Timer";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import RolloverConfirmation from "./Modal/RolloverConfirmation";


const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#e7f5fe" : "",
        },
        backgroundColor: state.isSelected ? "#4a8fb8" : "",
    }),
};

class RollOverStudent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rolloverLastClass:false,
            showModal: false,
            // rolloverobject:{},
            // arrayActivateData :0,
            rollover_ter: null,
            // isTimerRunning: false,
            rollover_undo: false,
            confirm_modal: false,
            editconfirmremovedata: [],
            add_archive_modal: false,

            rollover_form: {
                class_no: {
                    type: "select",
                    label: "Select class *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select Class",
                    validations: { required: true },
                    options: [],
                },

                class_id: {
                    type: "select",
                    label: "Select class *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                    options: [],
                },
            },
        };
    }

    showAddModalHandle = (event) => {
        this.state.setconfirm_modal(false);
    };

    RemoveCourseModule = (event, course_data) => {
        this.state.seteditconfirmremovedata(course_data);
        this.state.setconfirm_modal(true);
    };



    AddArchiveSubmit = async (form_data) => {
        this.props.onSelectCountry(form_data).then((response) => {
            if (response.success === true) {
                // console.log(form_data);
                this.state.setadd_archive_modal(false)
                toast.success('Country added successfully.', { autoClose: 15000 }, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }

        });
    }

    showAddModalHandleCountry = async (event) => {
        this.state.setadd_archive_modal(false)
    }

    componentDidMount() {
        this.GetAllClassesDetailsRollOver();

    }


    GetAllClassesDetailsRollOver = async (form_data) => {
        this.props.onGetAllClassesDetailsRollOver().then((response) => {
            console.log("response", response);
            let country_level = [];
            country_level.push({
                label: "Select Class",
                value: "",
                class_no: null,
                is_class_activate: null
            });
            const update_rollover_form = { ...this.state.rollover_form };
            if (response.success === true) {

                const arrayData = response.data.rows;
                // c
                for (let key in arrayData) {
                    if (arrayData[key].is_class_activate) {
                        country_level.push({
                            label: arrayData[key].class_name,
                            value: arrayData[key].class_id,
                            class_no: arrayData[key].class_no,
                            is_class_activate: arrayData[key].is_class_activate
                        });
                    }
                }
            }
            update_rollover_form['class_id'].options = country_level;
            this.setState({ rollover_form: update_rollover_form });
        });
    }
    inputChangeHandler(event, identifier) {
        const update_rollover_form = { ...this.state.rollover_form };
        const form_element = { ...update_rollover_form[identifier] };
        form_element.value = event.target.value;
        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_rollover_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_rollover_form) {
            is_form_valid = update_rollover_form[identifier].valid && is_form_valid;
        }
        this.setState({
            rollover_form: update_rollover_form,
            is_form_valid: is_form_valid,
        });
    }

    handleChange(data, identifier, type) {
        console.log("data", data);
        const update_rollover_form = { ...this.state.rollover_form };
        if (identifier === 'class_id') {
            update_rollover_form['class_no'].value = data.class_no;
        }
        update_rollover_form[identifier].value = data.value;
        this.setState({ rollover_form: update_rollover_form });
        const maxClassNo = Math.max(
            ...this.state.rollover_form.class_id.options.map((item) => item.class_no)
        );
        
        console.log("Largest class_no:", maxClassNo);
        if(maxClassNo == data.class_no){
            this.setState({ rolloverLastClass: true });
        }else{
            this.setState({ rolloverLastClass: false });
        }
    }


    startTimer = () => {
        this.setState({ rollover_undo: true })
        // console.log("seconds", seconds)
        // this.SubmitHandler()
    }
    stopTimer = () => {
        this.setState({ rollover_undo: false })
        this.setState({ showModal: false });
    }

    checkfunc = () => {
        console.log("check func call")
    }


    handleClose = () => {
        this.setState({ Rollover_Modal:false })
    }

    SubmitHandler = async (event, identifier) => {
        this.setState({ showModal: false });
        this.setState({ Rollover_Modal:true })
        // event.preventDefault();
        console.log("check func call")
        this.setState({ rollover_undo: false })
        let is_form_valid = true;
        const update_rollover_form = this.state.rollover_form;
        for (let key in update_rollover_form) {
            let form_element = update_rollover_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_rollover_form[key] = form_element;
        }
        this.setState({ rollover_form: update_rollover_form });
        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.rollover_form) {
                form_data[key] = this.state.rollover_form[key].value;
            }
            // if (identifier === "rollover_undo") {
            //     this.setState({ rollover_undo: false })
            //     clearTimeout(this.state.rollover_ter)
            // } else if (identifier === "submit_form") {
            let rolloverobject;
            for (let i = 0; i <= update_rollover_form.class_id.options.length - 1; i++) {
                if (update_rollover_form.class_id.options[i].class_no === form_data.class_no) {
                    rolloverobject = update_rollover_form.class_id.options[i].is_class_activate;
                }
            }
            console.log("rolloverobject", rolloverobject);
            if (rolloverobject) {
                // let timeOutTerminate = setTimeout(() => {
                this.props.onStudentsRollOver(form_data).then((response) => {
                    if (response.success === true) {
                        toast.success(response.message, {
                            position: toast.POSITION.TOP_CENTER,
                        });
                        setTimeout(() => {
                            this.setState({ rollover_undo: false })
                            //     this.setState({
                            //         isAuthenticated: true,
                            //         redirect_url: "/",
                            //     });
                        }, 500);
                      
                    } else {
                        toast.error(response.message, {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                });

                // },30000);
                // this.setState({ rollover_ter: timeOutTerminate })
            } else {
                toast.error("rollover Class is deactiveted", {
                    position: toast.POSITION.TOP_CENTER,
                });
                this.setState({ rollover_undo: false })
            }

            // } else {

            // }

        }
    };

    handleShowModal = () => {
        this.setState({ showModal: true });
    };
    
    handleCloseModal = () => {
        this.setState({ showModal: false });
    };

    render() {
        const { rollover_form } = this.state;

        console.log("rollover_form===", this.state.rolloverLastClass);        

        return (
            <>

                {this.state.add_archive_modal &&
                    <RollOverStudentModal
                        add_archive_modal={this.state.add_archive_modal}
                        AddArchiveSubmit={(event) => this.state.AddArchiveSubmit(event)}
                        add_archive_modal_action={(modal_action) => this.state.showAddModalHandleCountry(modal_action)}
                    />
                }
                {this.state.Rollover_Modal && <div style={{ position: "absolute", width: "100%", minHeight: "100vh", backgroundColor: "#00000061", zIndex: "999999999" }}>
                    <div style={{ width: "550px", margin: "150px auto" }}>
                        <Card className="text-center">
                            <Card.Header className="text-center">
                                <div style={{ padding: "20px", margin: "auto" }}>
                                    <h2 style={{ textAlign: "center" }}> Revise On Campus Destinations</h2>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    Please revise On Campus Destinations Permissions for all Age Groups as the System has rolled over each student to the next Year Level.
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer className="text-muted">
                                <Button variant="primary" onClick={() =>this.handleClose()}>OK</Button>
                            </Card.Footer>
                        </Card>
                    </div>
                </div>}


                <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
                    <div className="d-flex flex-column flex-root">
                        <div className="page d-flex flex-row flex-column-fluid">
                            <Sidebar />
                            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                                <TopBar />
                                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                    <div className="post d-flex flex-column-fluid" id="kt_post">
                                        <div id="kt_content_container" className="container-fluid">
                                            <div className="school-edit-form">
                                                <div className="" id="Snapshot">
                                             
                                                        <form onSubmit={this.SubmitHandler}>
                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <div className="card  tabs-outer">
                                                                        <div className="card-header position-relative py-0 details-bg">
                                                                            <h2 className="card-title  m-0">Student Rollover</h2>
                                                                        </div>

                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-sm-12">

                                                                                    {/* <div className="col-lg-6 fv-row mb-5">
                                                                                        <label className="fw-bold mb-2">{rollover_form.class_name.label}</label>
                                                                                        <Select
                                                                                            styles={customStyles}
                                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                                            options={rollover_form.class_name.options}
                                                                                            value={rollover_form.class_name.options.filter(function (option) {
                                                                                                return option.value === rollover_form.class_name.value;
                                                                                            })}
                                                                                            onChange={(newValue) => this.handleChange(newValue, "class_name")}
                                                                                        />

                                                                                        {!rollover_form.class_name.valid && rollover_form.class_name.onBlur_out ? (
                                                                                            <div className="error field-error">{rollover_form.class_name.error_msg}</div>
                                                                                        ) : (
                                                                                            ""
                                                                                        )}
                                                                                    </div> */}


                                                                                    <div className="col-lg-4 fv-row mb-5 col-xxl-3">
                                                                                        <label className="fw-bold">{rollover_form.class_id.label}</label>
                                                                                        {/* {console.log("class_id", rollover_form.class_id.value)} */}
                                                                                        <Select
                                                                                            styles={customStyles}
                                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                                            options={rollover_form.class_id.options}
                                                                                            value={rollover_form.class_id.options.filter(function (option) {
                                                                                                return option.value === rollover_form.class_id.value;
                                                                                            })}
                                                                                            onChange={(newValue) => this.handleChange(newValue, "class_id")}
                                                                                        />
                                                                                    </div>

                                                                                    <div className="col-lg-6 fv-row mb-5 col-xxl-6">
                                                                                        <p>
                                                                                            You are beginning Rollover process.  Please carefully read instructions to avoid costly and time sensitive errors. 
                                                                                        </p>
                                                                                        <p> <b>ALWAYS:</b> Always - begin by rolling over the highest year level first - then work down to the lowest.  
                                                                                        By starting with a lower year level and working up - you will end up with EVERY student in the same year level.  :-)   You really don’t want that!  ;-O  </p>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-sm-12 d-flex justify-content-end">
                                                                                    <div className="d-flex align-items-end">
                                                                                        {/* {console.log("timeer", this.state.counter)} */}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-12 d-flex justify-content-end">
                                                                                    <div className="d-flex align-items-end">
                                                                                        {this.state.rollover_undo ?
                                                                                            <div className="d-flex">
                                                                                                <Timer SubmitHandler={this.SubmitHandler} />
                                                                                                <button
                                                                                                    onClick={this.stopTimer}
                                                                                                    // onClick={(e) => this.SubmitHandler(e, "rollover_undo")} 
                                                                                                    className="btn btn-sm btn-danger apply-btn min-150px">
                                                                                                    Undo
                                                                                                </button>
                                                                                                <button
                                                                                                    onClick={this.SubmitHandler}
                                                                                                    // onClick={(e) => this.SubmitHandler(e, "rollover_undo")} 
                                                                                                    className="btn btn-sm btn-primary apply-btn min-150px ms-5">
                                                                                                    Skip Time
                                                                                                </button>
                                                                                            </div>
                                                                                            : 
                                                                                            <>
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-sm btn-primary apply-btn min-150px"
                                                                                                    onClick={this.handleShowModal}
                                                                                                    disabled={rollover_form.class_id.value === ""}
                                                                                                >
                                                                                                    Rollover
                                                                                                </button>

                                                                                                <RolloverConfirmation
                                                                                                    show={this.state.showModal}
                                                                                                    onClose={this.handleCloseModal}
                                                                                                    startTimer={this.startTimer}
                                                                                                    rolloverLastClass={this.state.rolloverLastClass}
                                                                                                />
                                                                                            </>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Footer />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}
const mapDispatchToProps = {

    onGetAllClassesDetailsRollOver: userActions.GetAllClassesDetailsRollOver,
    onStudentsRollOver: userActions.StudentsRollOver,


};
export default connect(mapStateToProps, mapDispatchToProps)(RollOverStudent);
