import React, { useState, useEffect, useCallback, useRef } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";
import SideBarLeave from "../../Constant/SideBarLeave";
import { toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import * as userActions from "../../../actions/index";
// 
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';

const customStyles = {
   option: (provided, state) => ({
      ...provided,
      "&:hover": {
         backgroundColor: state.isFocused ? "#e7f5fe" : "",
      },
      backgroundColor: state.isSelected ? "#4a8fb8" : "",
   }),
};

const PresentStudentList = () => {
   const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
   const school_code = user_info?.school_code ?? null;
   const dispatch = useDispatch();
   const navigate = useNavigate();

   const inputRef = useRef(null);

   const [page, setPage] = useState(1);
   const [limit, setLimit] = useState(10);
   const [count, setCount] = useState();
   const [sort, setSort] = useState("student_first_name");
   const [order, setOrder] = useState("asc");
   const [search, setSearch] = useState("");
   const [is_student_activate, setis_student_activate] = useState(true);
   const [student_ages, setstudent_ages] = useState("");

   const [dormitory_id, setdormitory_id] = useState([]);
   const [dormitory_ids, setdormitory_ids] = useState("");
   const [class_id, setclass_id] = useState([]);
   const [class_ids, setclass_ids] = useState("");
   const [gender, setGender] = useState('');
   const [StudentList, setStudentList] = useState([]);


   const row_limit = [
      { value: 5, label: 5 },
      { value: 10, label: 10 },
      { value: 15, label: 15 },
      { value: 20, label: 20 },
      { value: 25, label: 25 }
   ]


   function renderApplyLink(item) {
      const { student_id, class_id, dormitory_id, class_name, dormitory_data: { dormitory_name }, student_first_name, student_last_name, student_avatar, gender } = item;
      console.log("item================", gender);
      const student_name = `${student_first_name || ''}   ${student_last_name || ''}`;
      const queryParams = {
         student_id, class_id, dormitory_id, class_name, dormitory_name, student_name, student_avatar, gender
      };
      const queryString = new URLSearchParams(queryParams).toString();
      return (
         <Link
            to={`/${school_code}/leave/apply-border-leave`}
            className="btn btn-primary btn-flex btn-sm border-0 fw-bolder px-4 px-lg-6"
         >
            Apply Now
         </Link>
      );
   }
   const GetAllStudents = async () => {
      dispatch(userActions.GetAllStudents(page, limit, sort, order, is_student_activate, search, student_ages, dormitory_ids, class_ids, gender)).then((response) => {
         if (response.success === true) {
            const arrayData = response.data.rows;
            setCount(response.data.count)
            setStudentList(arrayData);
         }
         else if (response.status === 401) {
            toast.error(response.message, {
               position: toast.POSITION.TOP_CENTER,
            });
            setTimeout(() => {
               navigate('/logout')

            }, 5000);
         }
         else {
         }
      });
   };


   const GetAllClassesDetailsStudent = useCallback(() => {
      dispatch(userActions.GetAllClassesDetailsStudent()).then((response) => {
         if (response.success === true) {
            let state_data = [];
            state_data.push({
               label: "Select Year",
               value: "",
            });
            const arrayData = response.data.rows;
            for (let key in arrayData) {
               state_data.push({
                  label: arrayData[key].class_name,
                  value: arrayData[key].class_id,
                  class_id: arrayData[key].class_id,
               });
            }
            setclass_id(state_data);
         } else {

         }
      });
   }, [dispatch]);

   const GetAllDormitoryStudent = useCallback(() => {
      dispatch(userActions.GetAllDormitoryStudent()).then((response) => {
         if (response.success === true) {
            let state_data = [];
            state_data.push({
               label: "Select Dormitory",
               value: "",
            });
            const arrayData = response.data.rows;
            for (let key in arrayData) {
               state_data.push({
                  label: arrayData[key].dormitory_name,
                  value: arrayData[key].dormitory_id,
                  dormitory_id: arrayData[key].dormitory_id,
               });
            }
            setdormitory_id(state_data);
         } else {
         }
      });
   }, [dispatch]);

   useEffect(() => {
      GetAllClassesDetailsStudent();
      GetAllDormitoryStudent();
   }, []);
   useEffect(() => {
      GetAllStudents();
   }, [page, limit, sort, order, is_student_activate, search, student_ages, dormitory_ids, class_ids, gender])


   return (
      <>

         <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed" >
            <div className="d-flex flex-column flex-root">
               <div className="page d-flex flex-row flex-column-fluid">
                  <SideBarLeave />
                  <div className="wrapper d-flex flex-column flex-row-fluid " id="kt_wrapper">
                     <TopBar />
                     <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="post d-flex flex-column-fluid" id="kt_post">
                           <div id="kt_content_container" className="container-fluid">
                              <div className='row'>
                                 <div className='col-md-12'>
                                    <div className='card'>
                                       <div className='card-header align-items-center'>
                                          <h3 className='card-title'>Student Search</h3>
                                       </div>
                                       <div className='card-body'>
                                          <div className='row'>
                                             <div className='col-10'>
                                                <div className='row'>
                                                   <div className='col'>
                                                      <label className="fw-bold mb-3">Select Year</label>
                                                      <Select
                                                         styles={customStyles}
                                                         className="react-bootstrap-typeahead tag-outer" options={class_id}
                                                         value={class_id.filter((option) => {
                                                            return option.value === class_ids;
                                                         })}
                                                         onChange={(event) => {
                                                            setclass_ids(event.value);
                                                         }}
                                                      />
                                                   </div>
                                                   <div className="col">
                                                      <label className="fw-bold mb-3">Select Dormitory</label>
                                                      <Select
                                                         styles={customStyles}
                                                         className="react-bootstrap-typeahead tag-outer"
                                                         options={dormitory_id}
                                                         value={dormitory_id.filter((option) => {
                                                            return option.value === dormitory_ids;

                                                         })}
                                                         onChange={(event) => {
                                                            setdormitory_ids(event.value);
                                                         }}
                                                      />
                                                   </div>
                                                   <div className='col'>
                                                      <div className='form-group'>
                                                         <label className="fw-bold mb-3">Student Name</label>
                                                         <div className="position-relative">
                                                          
                                                            <div className='position-relative'>
                                                               <span className='position-absolute apply_adhoc_leave_date_and_time'>
                                                               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><rect opacity="1" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="#999"></rect><path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="#999"></path></svg>
                                                               </span>
                                                               <input
                                                                  id="session-date"
                                                                  type="search"
                                                                  className="form-control bg-transparent"
                                                                  placeholder="Search..."
                                                                  value={search}
                                                                  onChange={(e) => setSearch(e.target.value)}
                                                                  required
                                                               />
                                                            </div>

                                                         </div>
                                                      </div>
                                                   </div>


                                                   <div className='col'>
                                                      <label className="fw-bold mb-3">Gender</label>
                                                      <div className="d-flex mt-2 radio-btn">
                                                         <label className="cursor-pointer d-flex align-items-center">
                                                            <input
                                                               type="radio"
                                                               id="gende"
                                                               name="gen"
                                                               className="form-check-input me-2 cursor-pointer"
                                                               value="male"
                                                               onChange={(event) => { setGender(event.target.value); }}
                                                               checked={gender === "male" ? true : false}
                                                            />
                                                            Male
                                                         </label>
                                                         <label className="cursor-pointer d-flex align-items-center">
                                                            <input
                                                               type="radio"
                                                               id="gender"
                                                               name="gen"
                                                               className="form-check-input me-2 cursor-pointer"
                                                               value="female"
                                                               onChange={(event) => { setGender(event.target.value); }}
                                                               checked={gender === "female" ? true : false}
                                                            />
                                                            Female
                                                         </label>

                                                         <label className="cursor-pointer d-flex align-items-center">
                                                            <input
                                                               type="radio"
                                                               id="gender"
                                                               name="gen"
                                                               className="form-check-input me-2 cursor-pointer"
                                                               value=""
                                                               onChange={(event) => { setGender(event.target.value); }}
                                                               checked={gender === "" ? true : false}
                                                            />
                                                            Both
                                                         </label>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                             <div className='col'>
                                                <button type="reset" className="btn btn-secondary btn-sm float-end mt-7"
                                                   onClick={(event) => {
                                                      setclass_id("");
                                                      setdormitory_id("");
                                                      setSearch("")
                                                      setGender("")
                                                   }} >Clear Search
                                                </button>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className='row'>
                                 <div className="col-lg-12 mt-5">
                                    <div className='card'>
                                       <div className="card-header align-items-center">
                                          <h3 className="card-title">Apply for Boarders leave from the below list</h3>
                                       </div>
                                       <div className='card-body'>
                                          <div className='table-responsive'>
                                             <MDBTable className='table-row-bordered'>
                                                <MDBTableHead>
                                                   <tr>
                                                      <th>Sr.No</th>
                                                      <th>Student Name</th>
                                                      <th>Year</th>
                                                      <th>Dormitory</th>
                                                      <th>Gender</th>
                                                      <th>Parent Name</th>
                                                      <th>Student Phone</th>
                                                      <th>Actions</th>
                                                   </tr>
                                                </MDBTableHead>
                                                <MDBTableBody>
                                                   {StudentList.map((item, index) => {
                                                      { console.log("item=============", item) }
                                                      return (
                                                         <tr key={item.student_id}>
                                                            <td scope='row'>{index + 1}</td>
                                                            <td>{`${item.student_first_name} ${item.student_last_name}`}</td>
                                                            <td>{item.class_name}</td>
                                                            <td>{item.dormitory_data.dormitory_name}</td>
                                                            <td>{item.gender}</td>
                                                            <td>{item.first_point_email
                                                               == 'f' || item.first_point_email === null ? item?.parent_data?.father_name : item?.parent_data?.mother_name}</td>
                                                            <td>{item.student_phone}</td>
                                                            <td>
                                                               <Link
                                                                  to={`/${school_code}/leave/apply-border-leave/${item.student_uuid}`}
                                                                  className="btn btn-primary btn-flex btn-sm border-0 fw-bolder px-4 px-lg-6"
                                                               >
                                                                  Apply Now
                                                               </Link>
                                                            </td>
                                                         </tr>
                                                      )
                                                   })
                                                   }
                                                </MDBTableBody>
                                             </MDBTable>
                                             {/* <MDBTable>
                                                <MDBTableHead>
                                                   <tr style={{ borderBottom: "1x solid #cccccc3d" }}>
                                                      <th>Sr.No</th>
                                                      <th>Student Name</th>
                                                      <th>Year</th>
                                                      <th>Dormitory</th>
                                                      <th>Gender</th>
                                                      <th>Parent Name</th>
                                                      <th>Student Phone</th>
                                                      <th>Actions</th>
                                                   </tr>
                                                </MDBTableHead>
                                                <MDBTableBody>
                                                   {StudentList.length === 0 ?
                                                      StudentList.slice(0, 10).map((item, index) => {
                                                         return (
                                                            <tr key={item.student_id} style={{ borderBottom: "1px solid #cccccc3d" }}>
                                                               <th scope='row'>{index + 1}</th>
                                                               <td>{`${item.student_first_name} ${item.student_last_name}`}</td>
                                                               <td>{item.class_name}</td>
                                                               <td>{item.dormitory_data.dormitory_name}</td>
                                                               <td>{item.gender}</td>
                                                               <td>{item.first_point_email
                                                                  == 'f' || item.first_point_email === null ? item.parent_data.father_name : item.parent_data.mother_name}</td>
                                                               <td>{item.student_phone}</td>
                                                               <td>
                                                                  <OverlayTrigger overlay={
                                                                     <Tooltip id="tooltip-disabled">Apply Now</Tooltip>
                                                                  }>
                                                                     <a href="#" className="btn btn-primary btn-flex btn-sm  border-0 fw-bolder px-4 px-lg-6" type="button">Apply Now</a>

                                                                  </OverlayTrigger>
                                                               </td>
                                                            </tr>
                                                         )
                                                      }) : StudentList.map((item, index) => {
                                                         return (
                                                            <tr key={item.student_id} style={{ borderBottom: "1px solid #cccccc3d" }}>
                                                               <th scope='row'>{index + 1}</th>
                                                               <td>{`${item.student_first_name} ${item.student_last_name}`}</td>
                                                               <td>{item.class_name}</td>
                                                               <td>{item.dormitory_data.dormitory_name}</td>
                                                               <td>{item.gender}</td>
                                                               <td>{item.first_point_email
                                                                  == 'f' || item.first_point_email === null ? item.parent_data?.father_name : item.parent_data?.mother_name}</td>
                                                               <td>{item.student_phone}</td>
                                                               <td>
                                                                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Apply Now</Tooltip>}>
                                                                     {renderApplyLink(item)}
                                                                  </OverlayTrigger>
                                                               </td>
                                                            </tr>
                                                         )
                                                      })}
                                                </MDBTableBody>
                                             </MDBTable> */}
                                          </div>
                                          <div className="d-flex justify-content-between">
                                             <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                defaultValue={row_limit[1]}
                                                name="color"
                                                options={row_limit}
                                                onChange={(e) => setLimit(e.value)}
                                             />
                                             <Stack>
                                                <Pagination
                                                   count={Math.ceil(count / limit)}
                                                   page={page}
                                                   onChange={(e, value) => setPage(value)}
                                                   color="primary"
                                                />
                                             </Stack>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        </div>
                        <Footer />
                    
                  </div>

               </div>
            </div>
         </div>
      </>
   )
}

export default PresentStudentList;