import React from "react";
import Sidebar from "../../../Constant/sidebar";
import Footer from "../../../Constant/Footer";
import TopBar from "../../../Constant/TopBar";
import { connect } from "react-redux";
import * as userActions from "../../../../actions/index";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ApprovedHostModal from "./ApprovedHostModal";
const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" :user_info?.school_code;
class ApprovedHost extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        console.log("props", props);
        this.state = {
            popupModal: false,
            hostIndex: 0,
            hiddenpassword: true,
            student_host_data: [],
            student_id: {
                value: ""
            },
            approved_Manager: {
                manager_name: "",
                aproved_date: ""
            },
        }
    }
    componentDidMount() {
        this.GetStudentAndParentById();
        this.setApprovedHost();
    }
    setApprovedHost = () => {
        const local_stoage_data = JSON.parse(window.localStorage.getItem('bss_authuser'));
        const update_approved_manager = this.state.approved_Manager;
        update_approved_manager['manager_name'] = local_stoage_data.first_name + " " + local_stoage_data.last_name;
        this.setState({ approved_Manager: update_approved_manager })
    }
    GetStudentAndParentById = () => {
        this.props.onGetStudentAndParentById(this.props.user_uuid).then((response) => {
            const update_student_host_data = this.state.student_host_data;
            const update_student_id = this.state.student_id;
            let student_data = response.data;
            if (response.success === true) {
                if (student_data?.stu_host_data) {
                    student_data.stu_host_data.forEach((value) => {
                        update_student_host_data.push({
                            host_id: value.host_id,
                            host_name: value?.host_name,
                            host_email: value?.host_email,
                            host_uuid: value?.host_uuid,
                            host_relation: value?.stu_host?.host_relation,
                            host_contact: value?.host_contact,
                            host_address: value?.host_address,
                            is_host_approved: value?.stu_host.is_host_approved,
                            remark_boarding: value?.remark_boarding,
                            remark_host: value?.remark_host,
                            remark_parents: value?.remark_parents,
                            student_host_comment: value?.stu_host?.student_host_comment,
                            student_host_uuid: value?.stu_host?.student_host_uuid,

                        })

                    })

                }


                if (student_data?.stu_host_data.length === 0) {
                    student_data?.parent_data?.parents_as_host?.forEach((host) => {

                        let parentType = host?.parent_type;
                        let hostName = '';
                        let hostEmail = '';
                        let hostContact = '';
                        let hostAddress = '';

                        if (parentType === "father") {
                            hostName = student_data?.parent_data?.father_name;
                            hostEmail = student_data?.parent_data?.father_email;
                            hostContact = student_data?.parent_data?.father_phone;
                            hostAddress =student_data?.parent_data?.parent_address.length ===0 ? student_data?.parent_data?.parent_address[0]?.address_line1 + " "
                                + student_data?.parent_data?.parent_address[0]?.address_line2 + " "
                                + student_data?.parent_data?.parent_address[0]?.address_line3 + " "
                                + student_data?.parent_data?.parent_address[0]?.address_line4 + " "
                                + student_data?.parent_data?.parent_address[0]?.parent_country : "";
                        } else if (parentType === "mother") {
                            hostName = student_data?.parent_data?.mother_name;
                            hostEmail = student_data?.parent_data?.mother_email;
                            hostContact = student_data?.parent_data?.mother_phone;
                            hostAddress = student_data?.parent_data?.parent_address[1]?.address_line1 + " "
                                + student_data?.parent_data?.parent_address[1]?.address_line2 + " "
                                + student_data?.parent_data?.parent_address[1]?.address_line3 + " "
                                + student_data?.parent_data?.parent_address[1]?.address_line4 + " "
                                + student_data?.parent_data?.parent_address[1]?.parent_country;;
                        }

                        update_student_host_data?.push({
                            host_id: host?.host_id,
                            host_name: hostName,
                            host_email: hostEmail,
                            host_relation: parentType,
                            host_contact: hostContact,
                            host_address: hostAddress,
                            is_host_approved: host.is_host_approved,
                            student_host_comment: host.student_host_comment
                        });

                    })

                }
                
                else if (student_data?.stu_host_data.length > 0) {
                    let fatherHost = student_data.stu_host_data.find(host => host.stu_host.host_relation === "father")
                    const fatherAddress = student_data.parent_data.parent_address.find(address => address.parent_address_type === 'father')
                    console.log("student_data?.stu_host_data",fatherAddress);
                    if (!fatherHost) {
                        let father = student_data?.parent_data?.parents_as_host.find(item => item.parent_type === "father")
                        if (father) {
                            update_student_host_data?.push({
                                host_name: student_data?.parent_data?.father_name,
                                host_email: student_data?.parent_data?.father_email,
                                host_relation: father.parent_type,
                                host_contact: student_data?.parent_data?.father_phone,
                                host_address: fatherAddress === "undefined" ? fatherAddress?.address_line1 + " "
                                    + fatherAddress?.address_line2 + " "
                                    + fatherAddress?.address_line3 + " "
                                    + fatherAddress?.address_line4 + " "
                                    + fatherAddress?.parent_country : "",
                                is_host_approved: father.is_host_approved,
                                student_host_comment: father.student_host_comment
                            })
                        }
                    }

                    let motherHost = student_data.stu_host_data.find(host => host.stu_host.host_relation === "mother")

                    if (!motherHost) {
                        let mother = student_data?.parent_data?.parents_as_host.find(item => item.parent_type === "mother")
                        const motherAddress = student_data.parent_data.parent_address.find(address => address.parent_address_type === 'mother')
                        if (mother) {
                            if (motherAddress) {
                                update_student_host_data?.push({
                                    host_name: student_data?.parent_data?.mother_name,
                                    host_email: student_data?.parent_data?.mother_email,
                                    host_relation: mother.parent_type,
                                    host_contact: student_data?.parent_data?.mother_phone,
                                    host_address: motherAddress === "undefined" ? motherAddress?.address_line1 + " "
                                        + motherAddress?.address_line2 + " "
                                        + motherAddress?.address_line3 + " "
                                        + motherAddress?.address_line4 + " "
                                        + motherAddress?.parent_country : "",
                                    is_host_approved: mother.is_host_approved,
                                    student_host_comment: mother.student_host_comment
                                })
                            }
                            if (!motherAddress) {
                                update_student_host_data?.push({
                                    host_name: student_data?.parent_data?.mother_name,
                                    host_email: student_data?.parent_data?.mother_email,
                                    host_relation: mother.parent_type,
                                    host_contact: student_data?.parent_data?.mother_phone,
                                    host_address:  motherAddress === "undefined" ? fatherAddress?.address_line1 + " "
                                        + fatherAddress?.address_line2 + " "
                                        + fatherAddress?.address_line3 + " "
                                        + fatherAddress?.address_line4 + " "
                                        + fatherAddress?.parent_country : "",
                                    is_host_approved: mother.is_host_approved,
                                    student_host_comment: mother.student_host_comment
                                })
                            }

                        }
                    }

                }





                this.setState({ student_host_data: update_student_host_data });
                update_student_id.value = response.data.student_id;
                this.setState({ student_id: update_student_id })
            } else {
            }
        });
    }

    hostApprovedValidation(i) {
        this.setState({ popupModal: true })
        this.setState({ hostIndex: i })

    }
    GetStudentHostPdfById = (event) => {
        this.props.onGetStudentHostPdfById(this.props.user_uuid).then((response) => {
            if (response.success === true) {
                const csv_url = response.file;
                this.DownloadPdf(csv_url);
                // GetAllCourses();
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
            else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    };
    GetStudentHostCsvFileById = (event) => {
        this.props.onGetStudentHostCsvFileById(this.props.user_uuid).then((response) => {
            if (response.success === true) {
                const csv_url = response.file;
                this.DownloadPdf(csv_url);
                // GetAllCourses();
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
            else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    };
    DownloadPdf = (csv_url) => {
        const link = document.createElement("a");
        link.href = csv_url;
        link.target = "_blank"
        // link.setAttribute("download");
        document.body.appendChild(link);
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }

    render() {

        console.log("this.state.student_host_data", this.state.student_host_data)

        return (
            <React.Fragment>
                <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
                    <div className="d-flex flex-column flex-root">
                        <div className="page d-flex flex-row flex-column-fluid">
                            <Sidebar />
                            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                                <TopBar />
                                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                    <div className="post d-flex flex-column-fluid" id="kt_post">
                                        <div id="kt_content_container" className="container-fluid">
                                            <ul className="nav nav-tabs home-tabs" id="myTab" role="tablist" style={{display:"flex", justifyContent:"space-between", alignItems:"flex-end"}}>
                                                <div style={{display:"flex"}}>
                                                    <li className="nav-item me-6" role="presentation">
                                                        <Link to={`/${school_code}/student/student-list/student-profile/${this.props.user_uuid}`} className="nav-link" id="StudentProfile-tab" type="button" aria-controls="home" aria-selected="true">
                                                            Student Profile
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item me-6" role="presentation">
                                                        <Link to={`/${school_code}/student/student-list/approved-host/${this.props.user_uuid}`} className="nav-link active" id="ApprovedHosts-tab" type="button" aria-controls="process" aria-selected="true">
                                                            Approved Hosts
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item me-6" role="presentation">
                                                        <Link to={`/${school_code}/student/student-list/leave-history/${this.props.user_uuid}`} className="nav-link" id="LeaveHistory-tab" type="button" aria-controls="process" aria-selected="false">
                                                            Leave History
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item me-6" role="presentation">
                                                        <Link to={`/${school_code}/student/student-list/attendance-history/${this.props.user_uuid}`} className="nav-link" id="AttendanceHistory-tab" type="button" aria-controls="process" aria-selected="false">
                                                            Attendance History
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item me-6" role="presentation">
                                                        <Link to={`/${school_code}/student/student-list/pastoral-notes/${this.props.user_uuid}`} className="nav-link" id="PastoralNotes-tab" type="button" aria-controls="process" aria-selected="false">
                                                            Pastoral Notes
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item me-6" role="presentation">
                                                        <Link to={`/${school_code}/student/student-list/medical-notes/${this.props.user_uuid}`} className="nav-link" id="MedicalNotes-tab" type="button" aria-controls="process" aria-selected="false">
                                                            Medical Notes
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item me-6" role="presentation">
                                                        <Link to={`/${school_code}/student/student-list/allergy-reports/${this.props.user_uuid}`} className="nav-link" id="AllergyReport-tab" type="button" aria-controls="process" aria-selected="false">
                                                            Allergy Reports
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item me-6" role="presentation">
                                                        <Link to={`/${school_code}/student/student-list/adhoc-leave/${this.props.user_uuid}`} className="nav-link" id="AdhocLeave-tab" type="button" aria-controls="process" aria-selected="false">
                                                        AdhocLeave
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item me-6" role="presentation">
                                                        <Link to={`/${school_code}/student/student-list/adhoc-actual/${this.props.user_uuid}`} className="nav-link" id="AdhocLeave-tab" type="button" aria-controls="process" aria-selected="false">
                                                            Adhoc Actual
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item me-6" role="presentation">
                                                        <Link to={`/${school_code}/student/student-list/file-storage/${this.props.user_uuid}`} className="nav-link" id="AdhocLeave-tab" type="button" aria-controls="process" aria-selected="false">
                                                            File Storage
                                                        </Link>
                                                    </li>
                                                </div>
                                                <div className="d-flex align-items-center gap-2">
                                                    <b> {this.props?.studentData?.student_first_name ? this.props?.studentData?.student_first_name + " " + this.props?.studentData?.student_last_name : "BSS"}</b>
                                                    <div id="topaccountmenudata" className="symbol symbol-30px symbol-md-40px">
                                                        <img alt="Logo" style={{marginBottom:"4px"}} 
                                                            src={
                                                                this.props?.studentData?.student_avatar !== null && this.props?.studentData?.student_avatar !== "" 
                                                                ? "https://bssnew.dikonia.in/" + this.props?.studentData?.student_avatar
                                                                : (this.props?.studentData?.gender === "male" 
                                                                    ? "https://bssnew.dikonia.in/media/avatars/boy.jpg" 
                                                                    : "https://bssnew.dikonia.in/media/avatars/girl.jpg")

                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </ul>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="card  tabs-outer bg-none">
                                                        <div className="card-header position-relative py-0 details-bg d-flex align-items-center justify-content-between">
                                                            <h2 className="card-title  m-0">Approved Host</h2>
                                                            <div className="">
                                                                <button onClick={() => this.GetStudentHostPdfById()} className="btn btn-mini btn-primary btn-block pull-right btn-sm me-4">PDF</button>
                                                                <button onClick={() => this.GetStudentHostCsvFileById()} className=" btn btn-mini btn-info btn-block pull-right btn-sm">.Csv</button>
                                                            </div>
                                                        </div>
                                                        <div className="card-body h-100 bg-white">
                                                        <div className="row gap-y-20">
                                                            {this.state.student_host_data.length > 0 ? this.state.student_host_data.map((host, index) => {
                                                                console.log("host-----", host)
                                                                return (

                                                                    <div className="col-lg-12">
                                                                            <div className="approved-host-block">
                                                                            <div className="row">
                                                                                <div className="col-sm-4 approve-host">
                                                                                    {host.is_host_approved ?
                                                                                        <div className="d-flex flex-column align-items-center">
                                                                                            <img src="/assets/media/host-approved.png" class="img-fluid "></img>
                                                                                            <p>Approved By - {this.state.approved_Manager.manager_name}</p>
                                                                                            <p>{this.state.approved_Manager.aproved_date}</p>
                                                                                        </div>
                                                                                        :

                                                                                        <ApprovedHostModal
                                                                                            // student_host_comment={host.stu_host.student_host_comment}
                                                                                            onApproveHost={this.props.onApproveHost}
                                                                                            host={host}
                                                                                            student_host_data={this.state.student_host_data}
                                                                                            user_uuid={this.props.user_uuid}
                                                                                        />
                                                                                    }
                                                                                    {/* <button type="submit" className="btn btn-sm btn-success min-150px" onClick={(event) => this.hostApprovedValidation(index)}>Approved</button> */}
                                                                                </div>

                                                                                <div className="col-sm-8 approve-host uppy-Dashboard-inner">
                                                                                <div class="approved-host-table approved-table-list">

                                                                                        <table className="">
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                    <td>Name </td>
                                                                                                    <td><span className="fw-medium fs-13">{host.host_name && host.host_name.charAt(0).toUpperCase() + host.host_name.slice(1)}</span></td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                    <td>Email </td>
                                                                                                    <td> <span className="fw-medium fs-13">{host.host_email}</span></td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                    <td>Contact </td>
                                                                                                    <td><span className="fw-medium fs-13">{host.host_contact}</span></td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                    <td>Relation </td>
                                                                                                    <td><span className="fw-medium fs-13">{host.host_relation && host.host_relation.charAt(0).toUpperCase() + host.host_relation.slice(1)}</span></td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                    <td>Address </td>
                                                                                                    {console.log("host.host_address",host)}
                                                                                                    <td>      <span className="fw-medium fs-13">{host.host_address === undefined ? "" : host.host_address}</span></td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                    <td>Parent's remark </td>
                                                                                                    <td>    <span className="fw-medium fs-13">{host.remark_parents}</span></td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                    <td>host's remarks</td>
                                                                                                    <td>           <span className="fw-medium fs-13">{host.remark_host}</span></td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                    <td>Student Host Comment</td>
                                                                                                    <td>  <span className="fw-medium fs-13">{host.student_host_comment}</span></td>
                                                                                                    </tr>
                                                                                                </tbody>

                                                                                        </table>

                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                 
                                                                )
                                                            })
                                                        

                                                            : <div className="text-center fw-bold text-muted">No Host Found</div>
                                                            }
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Footer />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

}
function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onGetStudentAndParentById: userActions.GetStudentAndParentById,
    onApproveHost: userActions.ApproveHost,
    onGetStudentHostCsvFileById: userActions.GetStudentHostCsvFileById,
    onGetStudentHostPdfById: userActions.GetStudentHostPdfById

};
export default connect(mapStateToProps, mapDispatchToProps)(ApprovedHost);