import React from "react";
import Sidebar from "../../Constant/sidebar";
import Footer from "../../Constant/Footer";
import TopBar from "../../Constant/TopBar";
import Reports from "./ReportContainer";
function PermissionList() {
    return (
        <>
            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <Sidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid heding-element" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-fluid">
                              
                                        <div className="row gx-5 gx-xl-10">
                                            <div className="col-xl-12">
                                                <div className="tab-content" id="myTabContent">
                                                    <div className="tab-pane fade show active" id="Snapshot" role="tabpanel" aria-labelledby="Snapshot-tab">
                                                        <div className="card tabs-outer">
                                                            <div className="card-header position-relative py-0 details-bg">
                                                                {/* <!--begin::Card title--> */}
                                                                <h2 className="card-title  m-0">Attendance Reports</h2>
                                                            </div>

                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="common-table p-0">
                                                                        {/* <!--begin::Table--> */}

                                                                        <Reports />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PermissionList;
