import React from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import Validations from "../../../Utility/Validations";

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#e7f5fe" : "",
        },
        backgroundColor: state.isSelected ? "#4a8fb8" : "",
    }),
};

class AddCountryModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            course_form: {
                country_id: {
                    type: "select",
                    label: "Select country",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select country",
                    validations: { required: true },
                    options: [],
                },
                state_name: {
                    type: "text",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: true },
                    options: [],
                },
            },
            Getallcountry: [],
        };
    }

    componentDidMount() {
        let student_data = this.props.Getallcountry;
        let CountryID = this.props.CountryID;
        const update_course_form = { ...this.state.course_form };
        update_course_form["country_id"].options = student_data;
        update_course_form["country_id"].value = parseInt(CountryID);
        this.setState({ course_form: update_course_form });
    }

    handleChange(event, identifier) {
        const update_course_form = { ...this.state.course_form };
        const form_element = { ...update_course_form[identifier] };

        if (identifier === "country_id") {
            form_element.value = event.value;
        } else {
            form_element.value = event.target.value;
        }

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_course_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_course_form) {
            is_form_valid = update_course_form[identifier].valid && is_form_valid;
        }

        this.setState({
            course_form: update_course_form,
            is_form_valid: is_form_valid,
        });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_course_form = this.state.course_form;
        for (let key in update_course_form) {
            let form_element = update_course_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_course_form[key] = form_element;
        }
        this.setState({ course_form: update_course_form });

        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.course_form) {
                form_data[key] = this.state.course_form[key].value;
            }
            this.props.AddCountrySubmit(form_data);
        }
    };

    render() {
        const { course_form } = this.state;
        return (
            <React.Fragment>
                <Modal
                    backdrop="static"
                    keyboard={false}
                    className="add_country_modal confirm_modal"
                    show={this.props.add_country_modal}
                    onHide={() => {
                        this.props.add_country_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Add State</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="form w-100" onSubmit={this.SubmitHandler}>
                            <div className="row">
                                <div className="col-lg-12 fv-row mb-4">
                                    <div className="row">
                                        <div className="col-lg-12 fv-row mb-5">
                                            <label className="fw-bold mb-2">Country Name * </label>
                                            <Select
                                                styles={customStyles}
                                                name={"country_id"}
                                                className="react-bootstrap-typeahead tag-outer"
                                                placeholder="Select country"
                                                options={course_form.country_id.options}
                                                value={course_form.country_id.options.filter(function (option) {
                                                    return option.value === course_form.country_id.value;
                                                })}
                                                onChange={(newValue) => this.handleChange(newValue, "country_id", "country_idvalue")}
                                            />
                                            {!course_form.country_id.valid ? <div className="error field-error">{course_form.country_id.error_msg}</div> : ""}
                                        </div>
                                        <div className="col-lg-12 fv-row mb-5">
                                            <label className="fw-bold mb-2">State Name * </label>
                                            <input
                                                placeholder=""
                                                className="form-control"
                                                value={course_form.state_name.value ? course_form.state_name.value : ""}
                                                onChange={(event) => this.handleChange(event, "state_name")}
                                                onBlur={(event) => {
                                                    course_form.state_name.onBlur_out = true;
                                                    this.setState({
                                                        course_form: course_form,
                                                    });
                                                }}
                                            />

                                            {!course_form.state_name.valid ? <div className="error field-error">{course_form.state_name.error_msg}</div> : ""}
                                        </div>
                                        <div className="col-sm-12 mt-4 d-flex justify-content-end">
                                            <button type="submit" className="btn btn-sm btn-primary">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default AddCountryModal;
