import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

const DeleteModal1 = ({modalState,toggleModal,DeleteAllergy,confirmRemoveData}) => {
    const [modalOpen, setModalOpen] = useState(modalState);

    console.log("confirmRemoveData",confirmRemoveData);

    useEffect(() => {
        setModalOpen(modalState);
    }, [modalState]);



    const confirmDeleteAllergy = () =>{
        DeleteAllergy(confirmRemoveData)
    }
    return (
        <Modal
            backdrop="static"
            keyboard={false}
            className="confirm_delete_modal confirm_modal"

            show={modalOpen}
            onHide={toggleModal}
            aria-labelledby="md-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>Delete All Allergy's</Modal.Title>

            </Modal.Header>
            <Modal.Body className="p-0">
                <div className="row m-5">
                    <h6> Are you sure you want to delete All Allergy's. You will lost All Allergic data of this student.</h6>
                </div>

                <div className="col-sm-12 m-4 d-flex justify-content-center">
                    <button type="submit"
                        className="btn btn-sm btn-primary"
                        onClick={()=>confirmDeleteAllergy()}>
                        Delete
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};


const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#e7f5fe" : "",
        },
        backgroundColor: state.isSelected ? "#4a8fb8" : "",
    }),
};



export { DeleteModal1 as DeleteAllergyModal };