import React from "react";

import Validations from "../../Utility/Validations";
import Footer from "../../Constant/Footer";
import TopBar from "../../Constant/TopBar";
import { toast } from "react-toastify";
import * as userActions from "../../../actions/index";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import SideBarLeave from "../../Constant/SideBarLeave";


const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info.school_code;
class EditLeaveRejectionReason extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            edit_leave_rejection_reason: {
                reject_reason: {
                    type: "text",
                    label: "Leave Rejection Reasons",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },

                leave_reject_reason_uuid: {
                    type: "text",
                    label: " leave_reject_reason_uuid",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },

            },
        };
    }

    componentDidMount() {

        this.GetLeaveRejectReasonById();

    }

    GetLeaveRejectReasonById() {
        this.props.onGetLeaveRejectReasonById(this.props.leave_reject_reason_uuid).then((response) => {
            console.log("message_data", this.props)
            if (response.success === true) {
                let message_data = response.data;
                console.log("=======", response);
                const update_edit_leave_rejection_reason = { ...this.state.edit_leave_rejection_reason };
                for (let key in update_edit_leave_rejection_reason) {
                    let form_element = update_edit_leave_rejection_reason[key];
                    if (message_data[key]) {


                        update_edit_leave_rejection_reason['reject_reason'].value = message_data.reject_reason;
                        update_edit_leave_rejection_reason['leave_reject_reason_uuid'].value = message_data.leave_reject_reason_uuid;



                        this.setState({ edit_leave_rejection_reason: update_edit_leave_rejection_reason });
                        update_edit_leave_rejection_reason[key] = form_element;
                    }
                }


                this.setState({ edit_leave_rejection_reason: update_edit_leave_rejection_reason });
            } else {
            }
        });

    }

    inputChangeHandler(event, identifier) {
        const update_edit_leave_rejection_reason = { ...this.state.edit_leave_rejection_reason };
        const form_element = { ...update_edit_leave_rejection_reason[identifier] };
        form_element.value = event.target.value;
        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_edit_leave_rejection_reason[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_edit_leave_rejection_reason) {
            is_form_valid = update_edit_leave_rejection_reason[identifier].valid && is_form_valid;
        }
        this.setState({
            edit_leave_rejection_reason: update_edit_leave_rejection_reason,
            is_form_valid: is_form_valid,
        });
    }

    SubmitHandler = async (event) => {
        console.log("gfdgjdfkj");
        event.preventDefault();
        let is_form_valid = true;
        const update_edit_leave_rejection_reason = this.state.edit_leave_rejection_reason;
        for (let key in update_edit_leave_rejection_reason) {
            let form_element = update_edit_leave_rejection_reason[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_edit_leave_rejection_reason[key] = form_element;
        }
        this.setState({ edit_leave_rejection_reason: update_edit_leave_rejection_reason });
        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.edit_leave_rejection_reason) {
                form_data[key] = this.state.edit_leave_rejection_reason[key].value;
            }
            this.props.onUpdateLeaveRejectReason(form_data).then((response) => {
                if (response.success === true) {
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: `/${school_code}/leave/manage-leave-rejection-reason`,
                        });
                    }, 1000);
                } else {
                    console.log("error");
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }

            });

            console.log("form_data===", form_data);
        }
    };



    render() {
        const { edit_leave_rejection_reason } = this.state;

        console.log("edit_leave_rejection_reason===", edit_leave_rejection_reason);

        return (
            <React.Fragment>
            {this.state.isAuthenticated ? <Navigate to={this.state.redirect_url} /> : null}

            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed" >
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                      <SideBarLeave />
                        <div className="wrapper d-flex flex-column flex-row-fluid " id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid " id="kt_content">

                            <div className="post d-flex flex-column-fluid" id="kt_post">

                                <div id="kt_content_container" className="container-fluid">
                                    <div className="row center-wrap">
                                    <div className="col-md-6">
                                        <div className="card">
                                            <div className="card-header border-bottom pt-5 pb-5">
                                                <h3 className="card-title align-items-start flex-column">
                                                  Update Leave Rejection Reasons
                                                </h3>
                                              
                                            </div>
                                          <div className="card-body">
                                            <form className="form w-100" onSubmit={this.SubmitHandler}>
                                   
                                           
                                                        <div className="row">


                                                            <div className="col-lg-12 fv-row">
                                                                <div className="d-flex flex-column align-items-center">
                                                                <label className="fw-bold  white-space-nowrap mb-5 d-flex w-100">{edit_leave_rejection_reason.reject_reason.label}</label>

                                                                <textarea
                                                                    type={edit_leave_rejection_reason.reject_reason.type}
                                                                    value={edit_leave_rejection_reason.reject_reason.value ? edit_leave_rejection_reason.reject_reason.value : ""}
                                                                    className="form-control autoresizing1"
                                                                    placeholder={edit_leave_rejection_reason.reject_reason.placeholder}
                                                                    onChange={(event) => this.inputChangeHandler(event, "reject_reason")}
                                                                />

                                                                {!edit_leave_rejection_reason.reject_reason.valid && edit_leave_rejection_reason.reject_reason.onBlur_out ? <div className="error field-error">{edit_leave_rejection_reason.reject_reason.error_msg}</div> : ""}
                                                                <button type="submit" className="btn btn-sm btn-primary mt-5">
                                                                    Save
                                                                </button>
                                                            </div>
                                                            </div>


                                                         
                                                        </div>
                                            
                                               
                                            </form>

                                            </div>








                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
            </div>


        </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}
const mapDispatchToProps = {

    onUpdateLeaveRejectReason: userActions.UpdateLeaveRejectReason,
    onGetLeaveRejectReasonById: userActions.GetLeaveRejectReasonById,


};
export default connect(mapStateToProps, mapDispatchToProps)(EditLeaveRejectionReason);

