import axios from "axios";
import * as actionType from "../constants/actionTypes";
let qs = require("qs");



/*** CreateStudentAndParents Action ***/
export const CreateStudentAndParentsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.CreateStudentAndParents_START,
    };
};
export const CreateStudentAndParentsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.CreateStudentAndParents_SUCCESS,
    };
};
export const CreateStudentAndParentsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.CreateStudentAndParents_FAIL,
    };
};
export const CreateStudentAndParents = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    console.log("====",user_info);
    console.log("====",user_info.school_code);
 
    return (dispatch) => {
        dispatch(CreateStudentAndParentsStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
                // "Content-Type": "application/json",
                // Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                // country_host: user_info.country_host ,
                // school_code: user_info.school_code,
                // school_detail_id: user_info.school_detail_id
            },
          
            url: `${actionType.environment_url}/api/bss-admin/CreateStudentAndParents`,
        })
            .then(function (response) {
                dispatch(CreateStudentAndParentsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateStudentAndParentsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetAllDormitoryStudent Action ***/
export const GetAllDormitoryStudentStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllDormitoryStudent_START,
    };
};
export const GetAllDormitoryStudentSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllDormitoryStudent_SUCCESS,
    };
};
export const GetAllDormitoryStudentFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllDormitoryStudent_FAIL,
    };
};
export const GetAllDormitoryStudent = (dormitory_status) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllDormitoryStudentStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllDormitory`,
            params: {
                dormitory_status:dormitory_status,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllDormitoryStudentSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllDormitoryStudentFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** GetAllManagersBySchoolIdStudent Action ***/

export const GetAllManagersBySchoolIdStudentStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllManagersBySchoolIdStudent_START,
    };
};
export const GetAllManagersBySchoolIdStudentSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllManagersBySchoolIdStudent_SUCCESS,
    };
};
export const GetAllManagersBySchoolIdStudentFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllManagersBySchoolIdStudent_FAIL,
    };
};
export const GetAllManagersBySchoolIdStudent = (is_user_activate,school_detail_id) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllManagersBySchoolIdStudentStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
                school_detail_id: user_info.school_detail_id,
            },

            url: `${actionType.environment_url}/api/bss-admin/GetAllManagersBySchoolId`,
            params: {
               
                is_user_activate: is_user_activate,
                school_detail_id: user_info.school_detail_id,
                
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllManagersBySchoolIdStudentSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllManagersBySchoolIdStudentFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** GetAllStudents Action ***/

export const GetAllStudentsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllStudents_START,
    };
};
export const GetAllStudentsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllStudents_SUCCESS,
    };
};
export const GetAllStudentsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllStudents_FAIL,
    };
};
export const GetAllStudents = (page, limit, sort, order, is_student_activate, search, student_age, dormitory_id, class_id, gender ) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllStudentsStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllStudents`,
            params: {
                page: page,
                limit: limit,
                sort: sort,
                order: order,
                search: search,
                is_student_activate:is_student_activate,
                student_age:student_age,
                dormitory_id:dormitory_id,
                class_id:class_id,
                gender:gender,

            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllStudentsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllStudentsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};




/*** DeactivateStudent Action ***/

export const UpdateStudentStatusStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateStudentStatus_START,
    };
};

export const UpdateStudentStatusSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateStudentStatus_SUCCESS,
    };
};

export const UpdateStudentStatusFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateStudentStatus_FAIL,
    };
};

export const UpdateStudentStatus = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(UpdateStudentStatusStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/UpdateStudentStatus`,
        })
            .then(function (response) {
                dispatch(UpdateStudentStatusSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateStudentStatusFail("Something went wrong, Please try again."));
                return error;
            });
    };
};





/*** GetStudentAndParentById Action ***/


export const GetStudentAndParentByIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetStudentAndParentById_START,
    };
};
export const GetStudentAndParentByIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetStudentAndParentById_SUCCESS,
    };
};
export const GetStudentAndParentByIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetStudentAndParentById_FAIL,
    };
};
export const GetStudentAndParentById = (student_uuid) => {
    console.log('stu_id',student_uuid);
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

   
    return (dispatch) => {
        dispatch(GetStudentAndParentByIdStart());
        return axios({
            method: "get",        
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetStudentAndParentById`,
            params: {
                student_uuid: student_uuid,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetStudentAndParentByIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetStudentAndParentByIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};






/*** UpdateStudentPersonal Action ***/

export const UpdateStudentPersonalStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateStudentPersonal_START,
    };
};
export const UpdateStudentPersonalSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateStudentPersonal_SUCCESS,
    };
};
export const UpdateStudentPersonalFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateStudentPersonal_FAIL,
    };
};

export const UpdateStudentPersonal = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(UpdateStudentPersonalStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/UpdateStudentPersonal`,
        })
            .then(function (response) {
                dispatch(UpdateStudentPersonalSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateStudentPersonalFail("Something went wrong, Please try again."));
                return error;
            });
    };
};





/*** UpdateParentPersonal Action ***/

export const UpdateParentPersonalStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateParentPersonal_START,
    };
};
export const UpdateParentPersonalSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateParentPersonal_SUCCESS,
    };
};
export const UpdateParentPersonalFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateParentPersonal_FAIL,
    };
};

export const UpdateParentPersonal = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(UpdateParentPersonalStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/UpdateParentPersonal`,
        })
            .then(function (response) {
                dispatch(UpdateParentPersonalSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateParentPersonalFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetAllClassesDetailsStudent Action ***/
export const GetAllClassesDetailsStudentStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllClassesDetailsStudent_START,
    };
};
export const GetAllClassesDetailsStudentSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllClassesDetailsStudent_SUCCESS,
    };
};
export const GetAllClassesDetailsStudentFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllClassesDetailsStudent_FAIL,
    };
};
export const GetAllClassesDetailsStudent = (page, limit, sort, order, is_class_activate ) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllClassesDetailsStudentStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllClassesDetails`,
            params: {
                page: page,
                limit: limit,
                sort: sort,
                order: order,
                is_class_activate:is_class_activate,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllClassesDetailsStudentSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllClassesDetailsStudentFail("Something went wrong, Please try again."));
                return error;
            });
    };
};




/*** ExportStudentsCsv Action ***/
export const ExportStudentsCsvStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.ExportStudentsCsv_START,
    };
};


export const ExportStudentsCsvSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.ExportStudentsCsv_SUCCESS,
    };
};


export const ExportStudentsCsvFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.ExportStudentsCsv_FAIL,
    };
};

export const ExportStudentsCsv = (page, limit, sort, order, is_student_activate,search,gender,dormitory_id,class_id,student_age) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(ExportStudentsCsvStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
                school_detail_id: user_info.school_detail_id,
            },

            url: `${actionType.environment_url}/api/bss-admin/ExportStudentsCsv`,
            params: {
                page: page,
                limit: limit,
                sort: sort,
                order: order,
                search: search,
                is_student_activate: is_student_activate,
                gender: gender,
                dormitory_id: dormitory_id,
                class_id: class_id,
                student_age: student_age,

                // school_detail_id: user_info.school_detail_id,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(ExportStudentsCsvSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(ExportStudentsCsvFail("Something went wrong, Please try again."));
                return error;
            });
    };
};




/*** ExportStudentsPdf Action ***/
export const ExportStudentsPdfStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.ExportStudentsPdf_START,
    };
};


export const ExportStudentsPdfSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.ExportStudentsPdf_SUCCESS,
    };
};


export const ExportStudentsPdfFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.ExportStudentsPdf_FAIL,
    };
};

export const ExportStudentsPdf = (page, limit, sort, order, is_student_activate,search,gender,dormitory_id,class_id,student_age,current_local_time) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(ExportStudentsPdfStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
                school_detail_id: user_info.school_detail_id,
            },

            url: `${actionType.environment_url}/api/bss-admin/ExportStudentsPdf`,
            params: {
                page: page,
                limit: limit,
                sort: sort,
                order: order,
                search: search,
                is_student_activate: is_student_activate,
                gender: gender,
                dormitory_id: dormitory_id,
                class_id: class_id,
                student_age: student_age,
                current_local_time:current_local_time,

                // school_detail_id: user_info.school_detail_id,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(ExportStudentsPdfSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(ExportStudentsPdfFail("Something went wrong, Please try again."));
                return error;
            });
    };
};





/*** GetParentDetailsByFatherEmail Action ***/


export const GetParentDetailsByFatherEmailStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetParentDetailsByFatherEmail_START,
    };
};
export const GetParentDetailsByFatherEmailSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetParentDetailsByFatherEmail_SUCCESS,
    };
};
export const GetParentDetailsByFatherEmailFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetParentDetailsByFatherEmail_FAIL,
    };
};
export const GetParentDetailsByFatherEmail = (father_email) => {
    console.log('stu_id',father_email);
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

   
    return (dispatch) => {
        dispatch(GetParentDetailsByFatherEmailStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetParentDetailsByParentEmail`,
            params: {
                father_email: father_email,
             
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetParentDetailsByFatherEmailSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetParentDetailsByFatherEmailFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** GetParentDetailsByMotherEmail Action ***/


export const GetParentDetailsByMotherEmailStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetParentDetailsByMotherEmail_START,
    };
};
export const GetParentDetailsByMotherEmailSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetParentDetailsByMotherEmail_SUCCESS,
    };
};
export const GetParentDetailsByMotherEmailFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetParentDetailsByMotherEmail_FAIL,
    };
};
export const GetParentDetailsByMotherEmail = (mother_email) => {
    console.log('stu_id',mother_email);
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

   
    return (dispatch) => {
        dispatch(GetParentDetailsByMotherEmailStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetParentDetailsByParentEmail`,
            params: {
                mother_email: mother_email,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetParentDetailsByMotherEmailSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetParentDetailsByMotherEmailFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** GetStudentHostByHostcontact Action ***/


export const GetStudentHostByHostEmailStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetStudentHostByHostEmail_START,
    };
};
export const GetStudentHostByHostEmailSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetStudentHostByHostEmail_SUCCESS,
    };
};
export const GetStudentHostByHostEmailFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetStudentHostByHostEmail_FAIL,
    };
};
export const GetStudentHostByHostEmail = (host_email) => {
    console.log('stu_id',host_email);
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
   
    return (dispatch) => {
        dispatch(GetStudentHostByHostEmailStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetStudentHostByHostEmail`,
            params: {
                host_email: host_email,
             
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetStudentHostByHostEmailSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetStudentHostByHostEmailFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetStudentHostByHostContact Action ***/


export const GetStudentHostByHostContactStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetStudentHostByHostContact_START,
    };
};
export const GetStudentHostByHostContactSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetStudentHostByHostContact_SUCCESS,
    };
};
export const GetStudentHostByHostContactFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetStudentHostByHostContact_FAIL,
    };
};
export const GetStudentHostByHostContact = (host_contact) => {
    console.log('stu_id',host_contact);
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
   
    return (dispatch) => {
        dispatch(GetStudentHostByHostContactStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetStudentHostByHostEmail`,
            params: {
                host_contact: host_contact,
             
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetStudentHostByHostContactSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetStudentHostByHostContactFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** GetAllStudentCountForChart Action ***/

export const GetAllStudentCountForChartStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllStudentCountForChart_START,
    };
};
export const GetAllStudentCountForChartSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllStudentCountForChart_SUCCESS,
    };
};
export const GetAllStudentCountForChartFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllStudentCountForChart_FAIL,
    };
};
export const GetAllStudentCountForChart = ( is_student_activate, search, dormitory_id, student_age,  class_id, gender ) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllStudentCountForChartStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllStudentCountForChart`,
            params: {

                is_student_activate:is_student_activate,
                search: search,
                dormitory_id:dormitory_id,
                student_age:student_age, 
                class_id:class_id,
                gender:gender,

            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllStudentCountForChartSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllStudentCountForChartFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** ChangeStudentsDormitory Action ***/

export const ChangeStudentsDormitoryStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.ChangeStudentsDormitory_START,
    };
};

export const ChangeStudentsDormitorySuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.ChangeStudentsDormitory_SUCCESS,
    };
};

export const ChangeStudentsDormitoryFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.ChangeStudentsDormitory_FAIL,
    };
};

export const ChangeStudentsDormitory = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(ChangeStudentsDormitoryStart());
        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/ChangeStudentsDormitory`,
        })
            .then(function (response) {
                dispatch(ChangeStudentsDormitorySuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(ChangeStudentsDormitoryFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** Delete Student Allergy Action ***/


export const DeleteStudentAllergyByIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.DeleteStudentAllergyById_START,
    };
};
export const DeleteStudentAllergyByIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.DeleteStudentAllergyById_SUCCESS,
    };
};
export const DeleteStudentAllergyByIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.DeleteStudentAllergyById_FAIL,
    };
};


export const DeleteStudentAllergyById = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(DeleteStudentAllergyByIdStart());
        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        return axios({
            method: "delete",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url:`${actionType.environment_url}/api/bss-admin/DeleteStudentAllergyById`,
   
        })
        .then(function (response) {
            dispatch(DeleteStudentAllergyByIdSuccess(response.data));
            return response.data;
        })
        .catch(function (error) {
            dispatch(DeleteStudentAllergyByIdFail("Something went wrong, Please try again."));
            return error;
        });
    };
};



/*** Edit host data Action ***/

export const AddOrUpdateStudentHostStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.AddOrUpdateStudentHost_START,
    };
};
export const AddOrUpdateStudentHostSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.AddOrUpdateStudentHost_SUCCESS,
    };
};
export const AddOrUpdateStudentHostFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.AddOrUpdateStudentHost_FAIL,
    };
};

export const AddOrUpdateStudentHost = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    // console.log("form_data============",form_data);
    return (dispatch) => {
        dispatch(AddOrUpdateStudentHostStart());
        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url:`${actionType.environment_url}/api/bss-admin/AddOrUpdateStudentHost`,
   
        })
        .then(function (response) {
            dispatch(AddOrUpdateStudentHostSuccess(response.data));
            return response.data;
        })
        .catch(function (error) {
            dispatch(AddOrUpdateStudentHostFail("Something went wrong, Please try again."));
            return error;
        });
    };
};





/*** Approved host ***/

export const ApproveHostStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.ApproveHost_START,
    };
};
export const ApproveHostSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.ApproveHost_SUCCESS,
    };
};
export const ApproveHostFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.ApproveHost_FAIL,
    };
};

export const ApproveHost = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    // console.log("form_data============",form_data);
    return (dispatch) => {
        dispatch(ApproveHostStart());
        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url:`${actionType.environment_url}/api/bss-admin/ApproveHost`,
   
        })
        .then(function (response) {
            dispatch(ApproveHostSuccess(response.data));
            return response.data;
        })
        .catch(function (error) {
            dispatch(ApproveHostFail("Something went wrong, Please try again."));
            return error;
        });
    };
};






/*** GetStudentDiaryCommentsById ***/


export const GetStudentDiaryCommentsByIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetStudentDiaryCommentsById_START,
    };
};
export const GetStudentDiaryCommentsByIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetStudentDiaryCommentsById_SUCCESS,
    };
};
export const GetStudentDiaryCommentsByIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetStudentDiaryCommentsById_FAIL,
    };
};
export const GetStudentDiaryCommentsById = (student_id,diary_comment_type) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetStudentDiaryCommentsByIdStart());
        return axios({
            method: "get",        
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetStudentDiaryCommentsById`,
            params: {
                student_id:student_id,
                diary_comment_type:diary_comment_type
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetStudentDiaryCommentsByIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetStudentDiaryCommentsByIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** Delete Parent Address Action ***/


export const RemoveParentAddressStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.RemoveParentAddress_START,
    };
};
export const RemoveParentAddressSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.RemoveParentAddress_SUCCESS,
    };
};
export const RemoveParentAddressFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.RemoveParentAddress_FAIL,
    };
};


export const RemoveParentAddress = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    console.log("form_data", form_data)
    return (dispatch) => {
        dispatch(RemoveParentAddressStart());
        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        return axios({
            method: "delete",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url:`${actionType.environment_url}/api/bss-admin/RemoveParentAddress`,
   
        })
        .then(function (response) {
            dispatch(RemoveParentAddressSuccess(response.data));
            return response.data;
        })
        .catch(function (error) {
            dispatch(RemoveParentAddressFail("Something went wrong, Please try again."));
            return error;
        });
    };
};




/*** Delete Student Action ***/


export const DeleteStudentStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.DeleteStudent_START,
    };
};
export const DeleteStudentSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.DeleteStudent_SUCCESS,
    };
};
export const DeleteStudentFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.DeleteStudent_FAIL,
    };
};


export const DeleteStudent = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    console.log("form_data", form_data)
    return (dispatch) => {
        dispatch(DeleteStudentStart());
        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        return axios({
            method: "delete",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url:`${actionType.environment_url}/api/bss-admin/DeleteStudent`,
   
        })
        .then(function (response) {
            dispatch(DeleteStudentSuccess(response.data));
            return response.data;
        })
        .catch(function (error) {
            dispatch(DeleteStudentFail("Something went wrong, Please try again."));
            return error;
        });
    };
};



/*** GetStudentHostCsvFileById ***/


export const GetStudentHostCsvFileByIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetStudentHostCsvFileById_START,
    };
};
export const GetStudentHostCsvFileByIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetStudentHostCsvFileById_SUCCESS,
    };
};
export const GetStudentHostCsvFileByIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetStudentHostCsvFileById_FAIL,
    };
};
export const GetStudentHostCsvFileById = (student_uuid) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetStudentHostCsvFileByIdStart());
        return axios({
            method: "get",        
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetStudentHostCsvFileById`,
            params: {
                student_uuid:student_uuid,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetStudentHostCsvFileByIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetStudentHostCsvFileByIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** GetStudentHostPdfById ***/


export const GetStudentHostPdfByIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetStudentHostPdfById_START,
    };
};
export const GetStudentHostPdfByIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetStudentHostPdfById_SUCCESS,
    };
};
export const GetStudentHostPdfByIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetStudentHostPdfById_FAIL,
    };
};
export const GetStudentHostPdfById = (student_uuid,) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetStudentHostPdfByIdStart());
        return axios({
            method: "get",        
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetStudentHostPdfById`,
            params: {
                student_uuid:student_uuid,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetStudentHostPdfByIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetStudentHostPdfByIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** GetStudentAttandenceById ***/


export const GetStudentAttandenceByIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetStudentAttandenceById_START,
    };
};
export const GetStudentAttandenceByIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetStudentAttandenceById_SUCCESS,
    };
};
export const GetStudentAttandenceByIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetStudentAttandenceById_FAIL,
    };
};
export const GetStudentAttandenceById = (start_date,end_date,student_uuid,) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetStudentAttandenceByIdStart());
        return axios({
            method: "get",        
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetStudentAttandenceById`,
            params: {
                start_date:start_date,
                end_date:end_date,
                student_uuid:student_uuid,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetStudentAttandenceByIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetStudentAttandenceByIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetStudentAttandencePdfReport ***/

export const GetStudentAttandencePdfByStudentIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetStudentAttandencePdfByStudentId_START,
    };
};
export const GetStudentAttandencePdfByStudentIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetStudentAttandencePdfByStudentId_SUCCESS,
    };
};
export const GetStudentAttandencePdfByStudentIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetStudentAttandencePdfByStudentId_FAIL,
    };
};
export const GetStudentAttandencePdfByStudentId = (start_date, end_date, student_uuid) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetStudentAttandencePdfByStudentIdStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/ExportStudentsAttendancePdfByStudentId`,
            params: {
                start_date: start_date,
                end_date: end_date,
                student_uuid: student_uuid
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetStudentAttandencePdfByStudentIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetStudentAttandencePdfByStudentIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** ResendStudentAndParentsPassword ***/

export const ResendStudentAndParentsPasswordStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.ResendStudentAndParentsPassword_START,
    };
};
export const ResendStudentAndParentsPasswordSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.ResendStudentAndParentsPassword_SUCCESS,
    };
};
export const ResendStudentAndParentsPasswordFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.ResendStudentAndParentsPassword_FAIL,
    };
};

export const ResendStudentAndParentsPassword = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    // console.log("form_data============",form_data);
    return (dispatch) => {
        dispatch(ResendStudentAndParentsPasswordStart());
        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url:`${actionType.environment_url}/api/bss-admin/ResendStudentAndParentsPassword`,
        })
        .then(function (response) {
            dispatch(ResendStudentAndParentsPasswordSuccess(response.data));
            return response.data;
        })
        .catch(function (error) {
            dispatch(ResendStudentAndParentsPasswordFail("Something went wrong, Please try again."));
            return error;
        });
    };
};




/*** HistoryOfLeaves ***/


export const HistoryOfLeavesStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.HistoryOfLeaves_START,
    };
};
export const HistoryOfLeavesSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.HistoryOfLeaves_SUCCESS,
    };
};
export const HistoryOfLeavesFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.HistoryOfLeaves_FAIL,
    };
};
export const HistoryOfLeaves = (student_uuid,) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(HistoryOfLeavesStart());
        return axios({
            method: "get",        
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/HistoryOfLeaves`,
            params: {
                student_uuid: student_uuid,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(HistoryOfLeavesSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(HistoryOfLeavesFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** GetAllAdhocLeaveByStudentId ***/


export const GetAllAdhocLeaveByStudentIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllAdhocLeaveByStudentId_START,
    };
};
export const GetAllAdhocLeaveByStudentIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllAdhocLeaveByStudentId_SUCCESS,
    };
};
export const GetAllAdhocLeaveByStudentIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllAdhocLeaveByStudentId_FAIL,
    };
};
export const GetAllAdhocLeaveByStudentId = (student_uuid, sort, order) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllAdhocLeaveByStudentIdStart());
        return axios({
            method: "get",        
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllAdhocLeaveByStudentId`,
            params: {
                student_uuid: student_uuid,
                sort: sort, 
                order: order
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllAdhocLeaveByStudentIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllAdhocLeaveByStudentIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** CreateStudentFileStorage Action ***/
export const CreateStudentFileStorageStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.CreateStudentFileStorage_START,
    };
};
export const CreateStudentFileStorageSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.CreateStudentFileStorage_SUCCESS,
    };
};
export const CreateStudentFileStorageFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.CreateStudentFileStorage_FAIL,
    };
};
export const CreateStudentFileStorage = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    console.log("====",user_info);
    console.log("====",user_info.school_code);
 
    return (dispatch) => {
        dispatch(CreateStudentFileStorageStart());

        // let formdata = new FormData();
        // for (let key in form_data) {
        //     formdata.append(key, form_data[key]);
        // }

        return axios({
            method: "post",
            data: form_data,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
                // "Content-Type": "application/json",
                // Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                // country_host: user_info.country_host ,
                // school_code: user_info.school_code,
                // school_detail_id: user_info.school_detail_id
            },
          
            url: `${actionType.environment_url}/api/bss-admin/CreateStudentFileStorage`,
        })
            .then(function (response) {
                dispatch(CreateStudentFileStorageSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateStudentFileStorageFail("Something went wrong, Please try again."));
                return error;
            });
    };
};




/*** GetStudentFileStorage ***/


export const GetStudentFileStorageStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetStudentFileStorage_START,
    };
};
export const GetStudentFileStorageSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetStudentFileStorage_SUCCESS,
    };
};
export const GetStudentFileStorageFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetStudentFileStorage_FAIL,
    };
};
export const GetStudentFileStorage = (student_id, page, limit, file_type, search) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetStudentFileStorageStart());
        return axios({
            method: "get",        
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetStudentFileStorage?student_id=${student_id}`,
            params: {
                page: page,
                limit: limit,
                file_type: file_type,
                search: search
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetStudentFileStorageSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetStudentFileStorageFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** Delete StudentFileStorage ***/


export const DeleteStudentFileStorageStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.DeleteStudentFileStorage_START,
    };
};
export const DeleteStudentFileStorageSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.DeleteStudentFileStorage_SUCCESS,
    };
};
export const DeleteStudentFileStorageFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.DeleteStudentFileStorage_FAIL,
    };
};


export const DeleteStudentFileStorage = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    console.log("form_data", form_data)
    return (dispatch) => {
        dispatch(DeleteStudentFileStorageStart());
        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        return axios({
            method: "delete",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url:`${actionType.environment_url}/api/bss-admin/DeleteStudentFileStorage`,
   
        })
        .then(function (response) {
            dispatch(DeleteStudentFileStorageSuccess(response.data));
            return response.data;
        })
        .catch(function (error) {
            dispatch(DeleteStudentFileStorageFail("Something went wrong, Please try again."));
            return error;
        });
    };
};



/*** GetStudentFileDownload ***/


export const GetStudentFileDownloadStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetStudentFileDownload_START,
    };
};
export const GetStudentFileDownloadSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetStudentFileDownload_SUCCESS,
    };
};
export const GetStudentFileDownloadFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetStudentFileDownload_FAIL,
    };
};
export const GetStudentFileDownload = (student_wallet_id) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetStudentFileDownloadStart());
        return axios({
            method: "get",        
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/DownloadFileById?student_wallet_id=${student_wallet_id}`,
            // params: {
            //     // sort: sort, 
            //     // order: order,
            //     page: 1,
            //     limit:50
            // },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetStudentFileDownloadSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetStudentFileDownloadFail("Something went wrong, Please try again."));
                return error;
            });
    };
};