import React from "react";
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";
import SideBarLeave from "../../Constant/SideBarLeave";
import { connect } from "react-redux";
import * as userActions from "../../../actions/index";
import Select from "react-select";
import { toast } from "react-toastify";
import HostModal from "./HostModal";
import EditStudentHost from "./EditStudentHost";
import Pagination from '@mui/material/Pagination';

import Stack from '@mui/material/Stack';
import "react-datepicker/dist/react-datepicker.css";


const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#e7f5fe" : "",
        },
        backgroundColor: state.isSelected ? "#4a8fb8" : "",


    }),
};

class ManageHost extends React.Component {
    constructor(props) {
        super(props);
        this.row_limit = [
            { value: 5, label: 5 },
            { value: 10, label: 10 },
            { value: 15, label: 15 },
            { value: 20, label: 20 },
            { value: 25, label: 25 }
        ];
        this.state = {
            count:"",
            show_edit_host_moadal: false,
            edit_host_data: {},
            manage_host_filter_form: {
                page: 1,
                limit: 10,
                sort: "host_name",
                order: "asc",
            },
            manage_host_search_form: {
                host_name: {
                    type: "text",
                    label: "Host Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Host Name",
                    validations: { required: false },
                },
                student_name: {
                    type: "text",
                    label: "Student Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Student Name",
                    validations: { required: false },
                },
                dormitory_id: {
                    type: "select",
                    label: "Select Dormitory",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select Dormitory",
                    validations: { required: false },
                    options: [],
                },
                class_id: {
                    type: "select",
                    label: "Select class",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select class",
                    validations: { required: false },
                    options: [],
                },
            },
            all_host_data: [],
        };
    }

    componentDidMount() {
        this.GetAllStudentsHost();
        this.GetAllDormitoryStudent();
        this.GetAllClassesDetailsStudent();
    }
    GetAllStudentsHost = async () => {
        const search_data = { ...this.state.manage_host_search_form }
        const searchfilter = { ...this.state.manage_host_filter_form }
        this.props.onGetAllStudentsHost(searchfilter.page, searchfilter.limit, searchfilter.sort, searchfilter.order, search_data.host_name.value, search_data.student_name.value, search_data.class_id.value, search_data.dormitory_id.value).then((response) => {
            let all_host_data_array = [];
            if (response.success === true) {
                this.setState({count:response.data.count})
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    all_host_data_array = arrayData.map((item,index) => {
                        return ({
                            selialNo : 0 + searchfilter.limit * (searchfilter.page - 1) + (index+1),
                            host_address: item.host_address,
                            host_contact: item.host_contact,
                            host_email: item.host_email,
                            host_id: item.host_id,
                            host_name: item.host_name,
                            host_uuid: item.host_uuid,
                            remark_boarding: item.remark_boarding,
                            remark_host: item.remark_host,
                            remark_parents: item.remark_parents,

                            host_relation: item?.stu_host?.host_relation,
                            host_status: item?.stu_host?.host_status,
                            is_host_approved: item?.stu_host?.is_host_approved,
                            student_host_comment: item?.stu_host?.student_host_comment,
                            student_host_id: item?.stu_host?.student_host_id,

                            student_first_name: item?.stu_host?.stu_data?.student_first_name,
                            student_id: item?.stu_host?.stu_data?.student_id,
                            student_last_name: item?.stu_host?.stu_data?.student_last_name,
                            student_uuid: item?.stu_host?.stu_data?.student_uuid
                        }
                        )
                    });
                }
            }
            this.setState({ all_host_data: all_host_data_array });
        });
    }



    GetAllDormitoryStudent = () => {
        const update_manage_host_search_form = this.state.manage_host_search_form;
        this.props.onGetAllDormitoryStudent().then((response) => {
            if (response.success === true) {
                let state_data = [];
                state_data.push({
                    label: "Select Dormitory",
                    value: "",
                });
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    state_data.push({
                        label: arrayData[key].dormitory_name,
                        value: arrayData[key].dormitory_id,
                        dormitory_id: arrayData[key].dormitory_id,
                    });
                }
                update_manage_host_search_form.dormitory_id.options = state_data;
                this.setState({ manage_host_search_form: update_manage_host_search_form });
            } else {
            }
        });
    };
    GetAllClassesDetailsStudent = () => {
        const update_manage_host_search_form = this.state.manage_host_search_form;
        this.props.onGetAllClassesDetailsStudent().then((response) => {
            if (response.success === true) {
                let state_data = [];
                state_data.push({
                    label: "Class",
                    value: "",
                });
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    state_data.push({
                        label: arrayData[key].class_name,
                        value: arrayData[key].class_id,
                        class_id: arrayData[key].class_id,
                    });
                }
                update_manage_host_search_form.class_id.options = state_data;
                this.setState({ manage_host_search_form: update_manage_host_search_form });
            } else {

            }
        });
    };

    handleChangeSearch = (event, identifire) => {
        const update_manage_host_search_form = this.state.manage_host_search_form;
        if (identifire === "host_name") {
            update_manage_host_search_form[identifire].value = event.target.value;
            this.GetAllStudentsHost(event.target.value);
        } if (identifire === "student_name") {
            update_manage_host_search_form[identifire].value = event.target.value;
            this.GetAllStudentsHost(event.target.value);
        } if (identifire === "class_id") {
            update_manage_host_search_form[identifire].value = event.value;
            this.GetAllStudentsHost(event.value);
        } if (identifire === "dormitory_id") {
            update_manage_host_search_form[identifire].value = event.value;
            this.GetAllStudentsHost(event.value);
        }
        this.setState({ manage_host_search_form: update_manage_host_search_form })
    }

    clearFilterHandler = () => {
        const update_manage_host_search_form = this.state.manage_host_search_form;
        const update_manage_host_filter_form = this.state.manage_host_filter_form;
        update_manage_host_search_form['class_id'].value = "";
        update_manage_host_search_form['dormitory_id'].value = "";
        update_manage_host_search_form['host_name'].value = "";
        update_manage_host_search_form['student_name'].value = "";
        update_manage_host_filter_form.limit = 10;
        update_manage_host_filter_form.page = 1;
        this.setState({ manage_host_search_form: update_manage_host_search_form })
        this.setState({ manage_host_filter_form: update_manage_host_filter_form })
        this.GetAllStudentsHost();
    }
    editHostMOdalHandler = (data) => {
        this.setState({ edit_host_data: data })
        this.setState({ show_edit_host_moadal: true })
    }
    hideAddHostModal = (value) => {
        this.setState({ show_edit_host_moadal: value })
    }
    AddOrUpdateStudentHost = (host_data) => {
        this.props.onAddOrUpdateStudentHost(host_data).then((response) => {
            if (response.success === true) {
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                this.setState({ show_edit_host_moadal: false })
                this.GetAllStudentsHost();
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    }
    changeWidth = (e, value) => {
        const update_manage_host_filter_form = this.state.manage_host_filter_form;
        update_manage_host_filter_form.limit = parseInt(e.value, 10);
        this.GetAllStudentsHost(parseInt(e.value));
        this.setState({ manage_host_filter_form: update_manage_host_filter_form })
    };
    handleChange = (event, value) => {
        const update_manage_host_filter_form = this.state.manage_host_filter_form;
        update_manage_host_filter_form.page = value;
        this.GetAllStudentsHost(parseInt(value));
        this.setState({ manage_host_filter_form: update_manage_host_filter_form })
    };
    render() {

        const { manage_host_search_form ,count,manage_host_filter_form} = this.state;
        const { all_host_data } = this.state;
        const { show_edit_host_moadal, edit_host_data } = this.state;
        return (
            <React.Fragment>
                {show_edit_host_moadal &&
                    <EditStudentHost
                        hideAddHostModal={(value) => this.hideAddHostModal(value)}
                        show_edit_host_moadal={show_edit_host_moadal}
                        item={edit_host_data}
                        AddOrUpdateStudentHost={(host_data) => this.AddOrUpdateStudentHost(host_data)}
                    />
                }
                <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed" >
                    <div className="d-flex flex-column flex-root">
                        <div className="page d-flex flex-row flex-column-fluid">
                            <SideBarLeave />
                            <div className="wrapper d-flex flex-column flex-row-fluid " id="kt_wrapper">
                                <TopBar />
                                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                    <div className="post d-flex flex-column-fluid" id="kt_post">
                                        {/* <!--begin::Container--> */}
                                        <div id="kt_content_container" className="container-fluid">
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <div className='card'>
                                                        <div className="card-header align-items-center">
                                                            <h3 className="m-0 fw-bolder">Host Search</h3>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className='row'>
                                                                <div className='col-md-10'>
                                                                    <div className='row'>
                                                                        <div className="col-md-3">
                                                                            <label className='form-label'>{manage_host_search_form.host_name.label}</label>
                                                                            <input
                                                                                class="form-control"
                                                                                type={manage_host_search_form.host_name.type}
                                                                                placeholder={manage_host_search_form.host_name.placeholder}
                                                                                value={manage_host_search_form.host_name.value}
                                                                                onChange={(newValue) => this.handleChangeSearch(newValue, "host_name")}
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label className='form-label'>{manage_host_search_form.student_name.label}</label>
                                                                            <input
                                                                                class="form-control"
                                                                                type={manage_host_search_form.student_name.type}
                                                                                placeholder={manage_host_search_form.student_name.placeholder}
                                                                                value={manage_host_search_form.student_name.value}
                                                                                onChange={(newValue) => this.handleChangeSearch(newValue, "student_name")}
                                                                            />

                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label className='form-label'>{manage_host_search_form.dormitory_id.label}</label>
                                                                            <Select
                                                                                styles={customStyles}
                                                                                className="react-bootstrap-typeahead tag-outer wrap-select-boot"
                                                                                options={manage_host_search_form.dormitory_id.options}
                                                                                value={manage_host_search_form.dormitory_id.options.filter(function (option) {
                                                                                    return option.value === manage_host_search_form.dormitory_id.value;
                                                                                })}
                                                                                onChange={(newValue) => this.handleChangeSearch(newValue, "dormitory_id")}
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label className='form-label'>{manage_host_search_form.class_id.label}</label>
                                                                            <div className="">
                                                                                <Select
                                                                                    styles={customStyles}
                                                                                    className="react-bootstrap-typeahead tag-outer wrap-select-boot"
                                                                                    options={manage_host_search_form.class_id.options}
                                                                                    value={manage_host_search_form.class_id.options.filter(function (option) {
                                                                                        return option.value === manage_host_search_form.class_id.value;
                                                                                    })}
                                                                                    onChange={(newValue) => this.handleChangeSearch(newValue, "class_id")}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="row">
                                                                        <div className="">
                                                                            <div className="d-flex justify-content-end align-items-end mt-7">
                                                                                <button className="ms-3  btn btn-secondary btn-sm" onClick={() => this.clearFilterHandler()}>
                                                                                    Clear Search
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className="col-lg-12 mt-5">
                                                    <div className='card'>
                                                        <div className="card-header align-items-center">
                                                            <h3 className="m-0 fw-bolder">Host Details</h3>
                                                        </div>
                                                        <div className='card-body'>
                                                            <div className='table-responsive'>
                                                                <table className='table table-row-bordered mb-0'>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Sr. No</th>
                                                                            <th>Host Name</th>
                                                                            <th>Student Name</th>
                                                                            <th>Host Address</th>
                                                                            <th>Host Relation</th>
                                                                            <th>Host Status</th>
                                                                            <th>Approved/Unapproved</th>
                                                                            <th>Host Comment</th>
                                                                            <th className="text-right">Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {all_host_data.map((item) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td>{item.selialNo}</td>
                                                                                    <td>{item.host_name}</td>
                                                                                    <td>{item.student_first_name + " " + item.student_last_name}</td>
                                                                                    <td>{item.host_address}</td>
                                                                                    <td>{item.host_relation}</td>
                                                                                    <td>
                                                                                        {item.host_status ? (
                                                                                            <span className="badge badge-light-success px-4 py-2">Active</span>
                                                                                        ) : (
                                                                                            <span className="badge badge-light-danger px-4 py-2">Inactive</span>
                                                                                        )}
                                                                                    </td>
                                                                                    <td>
                                                                                        {item.is_host_approved ? (<span className="MuiButton-textPrimary">Approved</span>)  :
                                                                                            <HostModal
                                                                                                item={item}
                                                                                                onApproveHost={this.props.onApproveHost}
                                                                                            >
                                                                                            </HostModal>
                                                                                        }
                                                                                    </td>
                                                                                    <td>{item.student_host_comment}</td>
                                                                                    <td>
                                                                                        <div className="d-flex justify-content-end flex-shrink-0">
                                                                                            <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" onClick={() => this.editHostMOdalHandler(item)}>
                                                                                                <i class="fas fa-pen"></i>
                                                                                            </span>
                                                                                            {/* <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                                                                                                <i class="fas fa-trash"></i>
                                                                                            </span> */}
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })}

                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                            <div className='mt-5 pt-5'>
                                                                <div className="d-flex justify-content-between">
                                                                    <Select
                                                                        menuPlacement="top"
                                                                        className="basic-single"
                                                                        classNamePrefix="select"
                                                                        defaultValue={this.row_limit[1]}
                                                                        name="color"
                                                                        options={this.row_limit}
                                                                        onChange={this.changeWidth}
                                                                    />
                                                                    <Stack spacing={1} >
                                                                        <Pagination
                                                                            count={Math.ceil(count / manage_host_filter_form.limit)}
                                                                            page={manage_host_search_form.page}
                                                                            onChange={this.handleChange}
                                                                            color="primary"
                                                                        />
                                                                    </Stack>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Footer />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}
const mapDispatchToProps = {

    // onUpdateLeaveRejectReason: userActions.UpdateLeaveRejectReason,
    onGetAllStudentsHost: userActions.GetAllStudentsHost,
    onGetAllDormitoryStudent: userActions.GetAllDormitoryStudent,
    onGetAllClassesDetailsStudent: userActions.GetAllClassesDetailsStudent,
    onApproveHost: userActions.ApproveHost,
    onAddOrUpdateStudentHost: userActions.AddOrUpdateStudentHost,


};
export default connect(mapStateToProps, mapDispatchToProps)(ManageHost);

