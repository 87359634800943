import React, { useEffect, useState, useRef } from "react";
import { useOnClickOutside } from 'usehooks-ts'
import moment from "moment";
import "moment-timezone";

const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const timezone = user_info?.user_school?.timezone;

let setTimer = ''

const BoarderLeaveReturnTimeHandeler = (props) => {
    const ref = useRef(null)

    const [hours_data, setHours_data] = useState([])
    const [minutes_data, setMinutes_data] = useState([])
    const [am_pm_data, setAm_pm_data] = useState([])

    const [hours_drop, setHours_drop] = useState([])
    const [minutes_drop, setMinutes_drop] = useState([])
    const [am_pm_drop, setAm_pm_drop] = useState([])

    const [hours, setHours] = useState("")
    const [minutes, setMinutes] = useState("")
    const [Am_Pm, setAm_Pm] = useState("")

    const [show_time, setShowTime] = useState(false)
    const [time, setTime] = useState(props.placeholder)

    useEffect(()=>{
        if(props.returnTime === ""){
            setTime(props.placeholder)
        }
    },[props.returnTime])

    useEffect(() => {
        let timeArray = props.selectDepartureTime.split(':');
        let hours_1 = parseInt(timeArray[0]);
        let hours_in_12_format = hours_1 > 12 ? parseInt(timeArray[0]) - 12 : hours_1;
        let minutes_1 = parseInt(timeArray[1]);
        let hours_array = []
        let minutess_array = []
        if (props.selectDepartureDate === props.selectReturnDate) {
            hours_data.forEach((h) => {
                if (hours_1 > 12) {
                    hours_array.push({
                        ...h,
                        disabled: hours_in_12_format > h.value ? true : false
                    })
                } else {
                    hours_array.push({
                        ...h,
                        disabled: false
                    })
                }
            })
            minutes_data.forEach((min) => {
                minutess_array.push(min)
            })
        } else {
            hours_data.forEach((h) => {
                hours_array.push(h)
            })
            minutes_data.forEach((min) => {
                minutess_array.push(min)
            })
        }
        setHours_drop(hours_array)
        setMinutes_drop(minutess_array)
    }, [props.selectDepartureDate, props.selectReturnDate, props.selectDepartureTime, hours_data, minutes_data])

    useEffect(() => {
        let timeArray = props.selectDepartureTime.split(':');
        let hours_1 = parseInt(timeArray[0]);
        let minutes_1 = parseInt(timeArray[1]);
        let hours_array = []
        let minutess_array = []
        let am_pm_array = []
        if (props.selectDepartureDate === props.selectReturnDate) {
            if (props.selectDepartureTime === "") {
                let getAM_PM = moment(new Date())?.tz(timezone)?.format('A');
                am_pm_data.forEach((ap) => {
                    if (getAM_PM === "PM") {
                        am_pm_array.push({
                            ...ap,
                            disabled: ap.value === "PM" ? false : true
                        })
                    } else {
                        am_pm_array.push(ap)
                    }
                })
            } else {
                let getAM_PM = hours_1 > 12 ? "PM" : "AM";
                am_pm_data.forEach((ap) => {
                    if (getAM_PM === "PM") {
                        am_pm_array.push({
                            ...ap,
                            disabled: ap.value === "PM" ? false : true
                        })
                    } else {
                        am_pm_array.push(ap)
                    }
                })
            }
        } else {
            am_pm_data.forEach((ap) => {
                am_pm_array.push({
                    disabled: false,
                    ...ap
                })
            })
        }
        setAm_pm_drop(am_pm_array)
    }, [props.selectDepartureDate, props.selectReturnDate, props.selectDepartureTime, am_pm_data])

    useEffect(() => {
        let hours_array = []
        let minutess_array = []
        let am_pm_array = []
        for (let i = 1; i <= 12; i++) {
            hours_array.push({
                disabled: false,
                value: i <= 9 ? `0${i}` : `${i}`,
                label: i <= 9 ? `0${i}` : `${i}`
            })
        }
        {
            let i = 0
            while (i < 60) {
                minutess_array.push({
                    disabled: false,
                    value: i <= 9 ? `0${i}` : `${i}`,
                    label: i <= 9 ? `0${i}` : `${i}`
                })
                i = i + 5
            }
        }
        am_pm_array = [{
            disabled: false,
            value: "AM",
            label: "AM"
        }, {
            disabled: false,
            value: "PM",
            label: "PM"
        }]
        setAm_pm_data(am_pm_array)
        setHours_data(hours_array)
        setMinutes_data(minutess_array)
    }, [])




    useEffect(() => {
        const getTodayDate = moment(new Date())?.tz(timezone)?.format('MMMM Do YYYY');
        const getAM_PM = moment(new Date())?.tz(timezone)?.format('A');
        const getTodayHour = moment(new Date())?.tz(timezone)?.hours()
        const getTodayMinu = moment(new Date())?.tz(timezone)?.minutes()
        const getComponentDate = moment(props.selectDate)?.tz(timezone)?.format('MMMM Do YYYY');



        const hour = new Date().getHours() > 12 ? new Date().getHours() - 12 : new Date().getHours();
        const current_hours = (Math.floor(new Date().getMinutes() / 5) * 5) + 5 > 55 ? hour + 1 : hour;
        const current_Minutes = (Math.floor(new Date().getMinutes() / 5) * 5) + 5 > 55 ? "00" : (Math.floor(new Date().getMinutes() / 5) * 5) + 5
        setAm_Pm(getAM_PM)
        setHours(current_hours < 9 ? `0${current_hours}` : current_hours)
        setMinutes(current_Minutes < 9 ? `0${current_Minutes}` : `${current_Minutes}`)
    }, [props.selectDate]);





    const setleavetime = (value, identifier) => {
        if ("hours" == identifier) {
            setHours(value)
            let timeArray = props.selectDepartureTime.split(':');
            let hours_1 = parseInt(timeArray[0]);
            let hours_in_12_format = hours_1 > 12 ? parseInt(timeArray[0]) - 12 : hours_1;
            let minutes_1 = parseInt(timeArray[1]);
            let hours_array = []
            let minutess_array = []
            if (props.selectDepartureDate === props.selectReturnDate) {
                if (parseInt(value) === hours_in_12_format) {
                    minutes_data.forEach((min) => {
                        minutess_array.push({
                            ...min,
                            disabled: false
                        })
                    })
                    setMinutes_drop(minutess_array)
                } else {
                    setMinutes_drop(minutes_data)
                }
            }
            setTime(`${value}:${minutes} ${Am_Pm}`)
            setTimer = `${value}:${minutes} ${Am_Pm}`
            sendTimeForparent()
        } else if ("minutes" == identifier) {
            setMinutes(value)
            setTime(`${hours}:${value} ${Am_Pm}`)
            setTimer = `${hours}:${value} ${Am_Pm}`
            sendTimeForparent()
        } else {
            setAm_Pm(value)
            setTime(`${hours}:${minutes} ${value}`)
            setTimer = `${hours}:${minutes} ${value}`
            sendTimeForparent()
            setShowTime(false)
        }
    }
    const sendTimeForparent = () => {
        props.onChange(setTimer)
    }
    const handleClickOutside = () => {
        setShowTime(false)
    }
    useOnClickOutside(ref, handleClickOutside)

    return (
        <>
            <div ref={ref} className='position-relative'>
                <span className="watchPosition" onClick={() => setShowTime(!show_time)}><i class="far fa-clock"></i></span>
                <input
                    className="form-control"
                    value={time}
                    onClick={() => setShowTime(!show_time)}
                />
                {
                    show_time &&
                    <div className='position-absolute bg-white shadow' style={{ zIndex: 9 }}>
                        <div className='d-flex border p-5'>
                            <div className='hours_class'>
                                {hours_drop.map((hour) => {
                                    return (
                                        <div className='p-0 m-0'>
                                            <button type='button' disabled={hour.disabled} onClick={() => setleavetime(hour.value, "hours")} className='border-0 bg-white px-4 py-1'>
                                                {hour.label}
                                            </button>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='minutes_class'>
                                {minutes_drop.map((minute) => {
                                    return (
                                        <div className='p-0 m-0'>
                                            <button type='button' disabled={minute.disabled} onClick={() => setleavetime(minute.value, "minutes")} className='border-0 bg-white px-4 py-1'>
                                                {minute.label}
                                            </button>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='am_pm_class'>
                                {am_pm_drop.map((item) => {
                                    return (
                                        <div className='p-0 m-0'>
                                            <button type='button' disabled={item.disabled} onClick={() => setleavetime(item.value, "am/pm")} className='border-0 bg-white px-4 py-1'>
                                                {item.label}
                                            </button>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>

    )
}

export default BoarderLeaveReturnTimeHandeler;