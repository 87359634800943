import axios from "axios";
import * as actionType from "../../constants/actionTypes";
import { CropLandscapeOutlined } from "@mui/icons-material";
let qs = require("qs");


//*** CreateHostResposibilties ***/
export const CreateHostResposibiltiesStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.CreateHostResposibilties_START,
    };
};
export const CreateHostResposibiltiesSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.CreateHostResposibilties_SUCCESS,
    };
};
export const CreateHostResposibiltiesFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.CreateHostResposibilties_FAIL,
    };
};
export const CreateHostResposibilties = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    
    return (dispatch) => {
        dispatch(CreateHostResposibiltiesStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info?.country_host ,
                school_code: user_info?.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/CreateHostResposibilties`,
        })
            .then(function (response) {
                dispatch(CreateHostResposibiltiesSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateHostResposibiltiesFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** GetHostResposibilties ***/


export const GetHostResposibiltiesStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetHostResposibilties_START,
    };
};
export const GetHostResposibiltiesSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetHostResposibilties_SUCCESS,
    };
};
export const GetHostResposibiltiesFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetHostResposibilties_FAIL,
    };
};
export const GetHostResposibilties = () => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetHostResposibiltiesStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetHostResposibilties`,
            params: {},
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetHostResposibiltiesSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetHostResposibiltiesFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** GetTravelModeById Action ***/
export const GetTravelModeByIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetTravelModeById_START,
    };
};
export const GetTravelModeByIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetTravelModeById_SUCCESS,
    };
};
export const GetTravelModeByIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetTravelModeById_FAIL,
    };
};
export const GetTravelModeById = (travel_mode_uuid) => {

    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

   
    return (dispatch) => {
        dispatch(GetTravelModeByIdStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetTravelModeById`,
            params: {
                travel_mode_uuid   : travel_mode_uuid,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetTravelModeByIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetTravelModeByIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


