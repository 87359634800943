import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import moment from "moment";
import { subDays } from "date-fns";


const ExtendTrialMdal = ({ extendShow,subscriptionData, toggleExtendModal, UpdateSchoolSubscription }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [school_detail_id, setSchool_detail_id] = useState("")
  const [row, setRow] = useState()
  const [startDate, setStartDate] = useState(null)
  const [startDateDefault, setStartDateDefault] = useState("")
  const [endDate, setEndDate] = useState(null)
  const [currentDate, setCurrentDate] = useState(null)
  const [weekOption] = useState([
    { value: "", label: "Select Duration Time" },
    { value: 7 * 1, label: "1 Week" },
    { value: 7 * 2, label: "2 Weeks" },
    { value: 7 * 3, label: "3 Weeks" },
    { value: 7 * 4, label: "4 Weeks" },
    { value: 7 * 5, label: "5 Weeks" },
  ])
  const [duration, setDuration] = useState("")
  console.log("extandData", subscriptionData);

  useEffect(() => {
    setModalOpen(extendShow);
    setRow(subscriptionData)
    setSchool_detail_id(subscriptionData.school_detail_id)
    setStartDateDefault(subscriptionData.start_date)
    setEndDate(subscriptionData.end_date)
  }, [extendShow]);

  const dateHandler = (date) => {
    const newDate = new Date(date);
    setCurrentDate(newDate);
    setStartDate(new Date(newDate));

    const newEndDate = new Date(newDate);
    newEndDate.setDate(newEndDate.getDate() + duration);
    setEndDate(newEndDate);
  }


  useEffect(() => {
    if (currentDate) {
      const newDate = new Date(currentDate);
      setStartDate(new Date(newDate));
      const newEndDate = new Date(newDate);
      newEndDate.setDate(newEndDate.getDate() + duration);
      setEndDate(newEndDate);
    }
  }, [currentDate, duration]);

  const dataSubmit = (event) => {
    const payload = {
      school_detail_id: school_detail_id,
      start_date: startDate === "" ? startDateDefault : moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
      type: "demo",
      amount:""
    }
    UpdateSchoolSubscription(event, payload)
    setStartDate('')
    setDuration('')
    
  }
  return (
    <Modal
      backdrop={true}
      keyboard={false}
      className="confirm_delete_modal confirm_modal"
      show={modalOpen}
      aria-labelledby="md-modal"
      onHide={() => toggleExtendModal()}
    >
      <Modal.Header>
        <Modal.Title>Extend Trial</Modal.Title>
        <span className="pointer" onClick={(event) => toggleExtendModal()}>
          <i className="fas fa-times me-2 fs-6" ></i>
        </span>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-12">
            <label className="fw-bold mb-2">Trial Duration</label>
            <Select
              type="select"
              value={weekOption.filter(option => option.value === duration)}
              options={weekOption}
              onChange={(event) => setDuration(event.value)}
            />
          </div>
          <div className="col-12 mt-4">
            <label className="fw-bold mb-2">Trial Start Date</label>
            <div className="">
              <ReactDatePicker
              minDate={subDays(new Date(),0)}
                showIcon
                dateFormat="yyyy/MM/dd"
                wrapperClassName="trial_datePicker_superadmin"
                className="form-control"
                selected={startDate}
                onChange={(date) => dateHandler(date)}
              />
            </div>
          </div>
          <div className="col-12 mt-4">
            <label className="fw-bold mb-2">Trial End Date *</label>
            <input className="form-control"
              value={moment(endDate).format('YYYY/MM/DD')}
              readOnly
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-sm btn-secondary apply-btn min-75px me-4" onClick={(event) => toggleExtendModal()}>Cancel</button>
        <button className="btn btn-sm btn-primary apply-btn min-75px" onClick={() => dataSubmit()}>Ok</button>
      </Modal.Footer>
    </Modal>
  );
};



export default ExtendTrialMdal;

