import React, { useState } from "react";
import Sidebar from "../../Constant/sidebar";
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";
import DeleteConfirmRemove from "../../Utility/DeleteConfirmRemove";
import StudentImport from "./ImpStd";
import ImportCSV from "../../../Import_student_sample_files.csv"

export default function ImportStudent() {
    const [confirm_modal, setconfirm_modal] = useState(false);
    const [editconfirmremovedata, seteditconfirmremovedata] = useState([]);

    const showAddModalHandle = (event) => {
        setconfirm_modal(false);
    };
    const RemoveCourseModule = (event, course_data) => {
        seteditconfirmremovedata(course_data);
        setconfirm_modal(true);
    };

    const ConfirmRemove = (form_data) => {
        console.log("form_data===", form_data);
        setconfirm_modal(false);
        // props.onDeleteCourse(form_data).then((response) => {
        //     if (response.success === true) {
        //         GetAllCourses();
        //         toast.success(response.message, {
        //             position: toast.POSITION.TOP_CENTER,
        //         });
        //         setadd_course_modal(false);
        //         setedit_course_modal(false);
        //         setconfirm_modal(false);
        //     } else {
        //         toast.error(response.message, {
        //             position: toast.POSITION.TOP_CENTER,
        //         });
        //     }
        // });
    };
    return (
        <>
            {confirm_modal && (
                <DeleteConfirmRemove
                    title={`Delete Student?`}
                    bodytitle="Are you sure you want to delete this Student?"
                    editconfirmremovedata={editconfirmremovedata}
                    confirm_modal={confirm_modal}
                    confirmButtonText="Delete"
                    ConfirmRemove={(event) => ConfirmRemove(event)}
                    delete_confirm_modal_action={(modal_action) => showAddModalHandle(modal_action)}
                />
            )}

            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <Sidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-fluid">
                                        <div className="school-edit-form">
                                            <div className="" id="Snapshot">
                                                <div className="card-body p-0">
                                                    <div className="row">
                                                        <div className="col-sm-8">
                                                            <StudentImport />
                                                        </div>

                                                        <div className="col-sm-4">
                                                            <div className="card ">
                                                                {/* <!--begin::Header--> */}
                                                                <div className="card-header border-bottom border-bottom-1">
                                                                    <h3 className="card-title align-items-start flex-column">
                                                                        <span className="card-label fw-bold text-dark">Download Sample File</span>
                                                                    </h3>
                                                                </div>

                                                                <div className="card-body p-6">
                                                                    <div className="d-flex flex-column align-items-center">
                                                                        <span className="download-csv">
                                                                            {" "}
                                                                            <a href={ImportCSV} download="Sample File"><i className="fas fa-file-csv"></i></a>
                                                                        </span>
                                                                        <a href={ImportCSV} download="Sample File"> <span className="btn btn-sm btn-primary apply-btn add-btns">Download Sample .csv </span> </a>
                                                                    </div>
                                                                    <div className="d-flex flex-column">
                                                                        <h4 className="import-title">Import Instruction</h4>

                                                                        <div className="import-out">
                                                                            <ul>
                                                                                <li>
                                                                                    Use the above ‘Sample File’ as a template to import your student data to your school portal - in batches. Download the file & populate it without changing the header structure of the .csv file. This means you may perform functions like resize column width, change filename, but . . . DON’T change Column Headings/Titles. Respect the example formatting ie use this (DD/MM/YYYY) Date of birth formatting in CSV., laundry numbers can be alphanumeric, don’t try to change left, right or center alignment, font size etc. Most spreadsheet apps will do it fine. ie Excel or Sheets.
                                                                                    <br />
                                                                                    <br />
                                                                                    Add your data in accordance with the sample student’s Jess Smith & Bill Jones. You may copy and paste preformatted data (like with colour or different fonts) but then when you save, just ensure you save as a .csv and then you will likely get some warning about the data not remaining the way it currently appears – it won’t, and that is not a problem because it MUST be a .csv, which has no formatting.
                                                                                    <br />
                                                                                    <br />
                                                                                    The more information (data) you add, the more data you can work with in your school portal. As an absolute minimum, add data to column A,B,C,H, IorJ, P, R, SorT. Do not delete the vacant columns, just leave them blank. Remember the old IT adage GIGO.
                                                                                </li>
                                                                                <li>
                                                                                You DO need to create a separate CSV file for each:- *for each Class of each Dorm. See video on Website. Filename is your choice, but it should help you, when naming your various .csv files for upload. For example, filename may be “SrBrownDormGr12”, remember there is a field for gender in the CSV file so you MUST use either Male or Female. Of course you may have Male or Female within the same .csv file - that would indicate you have both Male or Female in the same residence & of course, you have exceptionally astute staff!
                                                                                    <br />
                                                                                    <br />
                                                                                    The process is easier than it reads – just make a start and you should be fine – Oh and don’t worry about the sample students, Jess & Bill, they should not duplicate but you can also just delete them once you are finished importing.If you are evaluating the system using “Grayleaf Academy'' then have a go at creating your own group of students to import – you may even ‘build’ a ‘new’ residence and populate that! Don’t forget to assign your staff to the relevant dorms. Senior Staff can make these changes remotely via the dashboard of the Roll Call App if required at short notice.
                                                                                </li>

                                                                                <li>Re-read Step 1 & 2</li>
                                                                                <li>
                                                                                The process merely requires a little practice, please check the resources on our website and the .pdf manual. Finally support@myboardingwizard.com will respond to your help requests. There is a small equitable charge if you would like us to set up your school for you. Simply request for us a template – a spreadsheet file with, at least the data from point 1, above. You might request us to compile only the CSV files or your whole system – as you prefer. We however, prefer you to do it on your own as that is excellent insight into the system for whomever you choose as your administrator, and we like to think we have designed it very user friendly. Grayleaf Academy should get you there quite easily.
                                                                                    <br />
                                                                                    <br />
                                                                                    *If you can begin by creating .csv files. One for each Class (year level) of each Dorm and naming them accordingly – your work is done! STEPS
                                                                                    <br />
                                                                                    <br />
                                                                                    i) Work out how many files you will be needing.
                                                                                    <br />
                                                                                    ii) Name them – Write it down.
                                                                                    <br />
                                                                                    iii) Take the sample CSV file above – and do a “Save As” function applying each name that you have written down. Be sure that you are “Saving As” keeping the correct Filename, that you wrote down - as well as File Type, namely .csv
                                                                                    <br />
                                                                                    iv) Now go and populate each of those csv files you just created! Easiest is to copy n paste from another file that your ever helpful IT department has given you.
                                                                                    <br />
                                                                                    v) Maybe your IT support team will be kind enough to populate each of those files for you? All pretty easy considering this is the ‘hardest’ function that the Wizard can throw at you!
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
