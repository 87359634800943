import React, { useState ,useEffect} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import "react-datepicker/dist/react-datepicker.css";
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Select from 'react-select';
import moment from "moment";
// import { Link } from "react-router-dom";
const firstIndex = 0;
const OperationalComment  = (props) => {
    console.log("all_data=============",props);

    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [data, setData] = useState(props.data.slice(firstIndex, pageSize));
    const row_limit = [
        { value: 5, label: 5 },
        { value: 10, label: 10 },
        { value: 15, label: 15 },
        { value: 20, label: 20 },
        { value: 25, label: 25 }
    ]
    useEffect(() => {
        setData(props.data.slice(0, pageSize));
    }, [props.data, pageSize]);
    const handleChange = (event, value) => {
        setPage(value);
        setData(props.data.slice(firstIndex + pageSize * (value - 1), pageSize * value));
    };

    //Change width
    const changeWidth = (e, value) => {
        setPageSize(parseInt(e.value, 10));
    };

    let searchArray = []
    if (props?.searchComment?.length > 0) {
        searchArray.push(props?.searchComment)
    }


    return (
        <React.Fragment>
            <div className="row gy-5 g-xl-8">
                <div className="col-xl-12">
                    
                                <div id="kt_profile_overview_table" className="dataTables_wrapper dt-bootstrap4 no-footer team-list-table">
                                    <div className="table-responsive">
                                        <div className="bg-white radious-style radious-users  mt-5">
                                            <MDBTable className="table-row-bordered">
                                                <MDBTableHead>
                                                    <tr>
                                                        <th style={{ width: "4%" }} scope='col'>S.No.</th>
                                                        <th style={{ width: "auto" }} scope='col'>Operational Comments</th>
                                                    </tr>
                                                </MDBTableHead>
                                                <MDBTableBody>
                                                    {data.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <th scope='row'>{index + 1}</th>
                                                                <td>
                                                                    <div>
                                                                        <span className="fw-bold">{item.created_date}</span>
                                                                        <span className="fw-bold ms-3">{item.created_by} :-</span>
                                                                    </div>
                                                                    <div>
                                                                        <Highlighter
                                                                            highlightStyle={{ backgroundColor: "#ffe00096", borderRadius: "25px" }}
                                                                            highlightClassName="YourDiaryHighlightClass"
                                                                            searchWords={[...searchArray]}
                                                                            autoEscape={true}
                                                                            textToHighlight={item.operational_desc}
                                                                            caseSensitive={props.caseSensitive}
                                                                        />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </MDBTableBody>
                                            </MDBTable>
                                            <div className="d-flex justify-content-between">
                                                <Select
                                                    menuPlacement="top"
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    defaultValue={row_limit[1]}
                                                    name="color"
                                                    options={row_limit}
                                                    onChange={changeWidth}
                                                />
                                                <Stack spacing={1} >
                                                    <Pagination
                                                        count={Math.ceil(props.data.length / pageSize)}
                                                        page={page}
                                                        onChange={handleChange}
                                                        color="primary"
                                                    />
                                                </Stack>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
            </div>
        </React.Fragment>
    );
};
export default OperationalComment;