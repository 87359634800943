import moment from "moment";
import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
// import Moment from "moment";

const SchoolItems = (props) => {

    const [perpagedata, setperpagedata] = useState(10);
    const [columns] = useState([


        {
            text: "",
            dataField: "Actions",
            formatter: (cellContent, row, index, extraData) => {
                console.log("ROW", row);
                return (
                    <div key={"repeat_" + row.country_id}>
                        <div className="card-1 p-6 mb-4 rounded bg-light-info">
                            {/* {console.log("row_data", row.subscriptionData.end_date < moment(new Date()).format('YYYY-MM-DD'))} */}
                            <div className="d-flex flex-stack mb-5">
                                <h3 className="mb-0 text-primary superadmin-headings">{row.school_name}</h3>
                                <div className="d-flex align-items-center">
                                    <div className="me-5">
                                        {
                                            row.subscriptionData !== null && (
                                                row.subscriptionData.type === "demo" ?
                                                    (
                                                        row.subscriptionData.end_date < moment(new Date()).format('YYYY-MM-DD') ?
                                                            <span className="badge badge-light-info px-4 py-2">Trial Expired</span> :
                                                            <span className="badge badge-light-info px-4 py-2">Trial</span>
                                                    )
                                                    :
                                                    (
                                                        row.subscriptionData.end_date < moment(new Date()).format('YYYY-MM-DD') ?
                                                            <span className="badge badge-light-danger px-4 py-2">Subscription Expired</span> :
                                                            <span className="badge badge-light-danger px-4 py-2">Subscribed</span>
                                                    )
                                            )

                                        }
                                    </div>
                                    <div >
                                        {row.is_school_activate === true ? (
                                            <span className="badge badge-light-success px-4 py-2">Active</span>
                                        ) : (
                                            <span className="badge badge-light-danger px-4 py-2">Inactive</span>
                                        )}
                                    </div>
                                </div>
                            </div>


                            <div className="d-flex text-secondary align-items-center mb-3">
                                <div className="d-flex me-4 align-items-center">
                                    <i className="far fa-user me-2 fs-6"></i>
                                    <p className="mb-0 heding-element ">{row.admin_user[0].first_name} {row.admin_user[0].last_name}</p>
                                </div>
                                <div className="d-flex align-items-center">
                                    <i className="bi bi-envelope me-2 fs-6"></i>
                                    <p className="mb-0 heding-element ">{row.admin_user[0].email}</p>
                                </div>
                            </div>
                            <div className="d-flex text-secondary align-items-center mb-3">
                                <Tooltip title="Session Start Month/Year" placement="top">
                                    <div className="d-flex me-4 align-items-center">
                                        <i className="bi bi-hourglass-top me-2 fs-6"></i>
                                        <p className="mb-0 heding-element ">{(row.session_start_month)}-{row.session_start_year}</p>
                                    </div>
                                </Tooltip>
                                <Tooltip title="Session End Month/Year" placement="top">
                                    <div className="d-flex align-items-center">
                                        <i className="bi bi-hourglass-bottom me-2 fs-6"></i>
                                        <p className="mb-0 heding-element ">{(row.session_end_month)}-{row.session_end_year}</p>
                                    </div>
                                </Tooltip>
                            </div>
                            <div className="d-flex text-secondary align-items-center mb-3">
                                <div className="d-flex align-items-center">
                                    <i className="bi bi-geo-alt me-2 fs-6"></i>
                                    <p className="mb-0 heding-element ">{row.city} ({row.country})</p>
                                </div>
                            </div>
                            <div className="d-flex text-secondary align-items-center mb-3">
                                <Tooltip title="Subscription Start Date" placement="top">
                                    <div className="d-flex me-4 align-items-center">
                                    <i class="bi bi-calendar-date me-2 fs-6"></i>
                                        <p className="mb-0 heding-element ">{row.subscriptionData.start_date}</p>
                                    </div>
                                </Tooltip>
                                <Tooltip title="Subscription End Date" placement="top">
                                    <div className="d-flex align-items-center">
                                        <i class="bi bi-calendar-date me-2 fs-6"></i>
                                        <p className="mb-0 heding-element ">{row.subscriptionData.end_date}</p>
                                    </div>
                                </Tooltip>
                            </div>
                            <div className="d-flex text-secondary align-items-center mb-3">
                                <div className="d-flex align-items-center me-4">
                                    <Link to={"/superadmin/edit-school/" + row.school_detail_uuid}><i className="far fa-edit me-2 fs-6"></i>Edit
                                    </Link>
                                </div>


                                {/* <div className="d-flex align-items-center me-4 pointer">
                                    <span
                                        onClick={(event) => props.RemoveCourseModule(event, row)}
                                    ><i className="far fa-trash-alt me-2 fs-6"></i>
                                        Delete</span>
                                </div> */}

                                {console.log("row===========================", row.subscriptionData)}
                                <div className="d-flex align-items-center me-4">
                                    <span className="pointer"
                                        onClick={(event) => {
                                            props.handleChangeLoginAsSchoolAdmin(event, row.admin_user[0].user_uuid, row.school_code, row.subscriptionData);
                                        }}
                                    ><i className="bi bi-box-arrow-right me-2 fs-6"></i>Login as school admin</span>
                                </div>


                            </div>

                            <div className="d-flex text-secondary align-items-center mb-3">
                                <div>

                                    {row && row.is_school_activate === true ? (
                                        <div className="d-flex align-items-center"
                                            onClick={(event) => {
                                                props.handleChange(event, row.school_detail_uuid, false);
                                            }}
                                        >
                                            <span className="deactivate-button1" >
                                                <i className="bi bi-bell-slash me-2 fs-6"></i>Deactivate School
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="d-flex align-items-center"
                                            onClick={(event) => {
                                                props.handleChange(event, row.school_detail_uuid, true);
                                            }}
                                        >
                                            <span className="deactivate-button1">
                                                <i className="bi bi-bell me-2 fs-6"></i>Activate School
                                            </span>
                                        </div>
                                    )}

                                </div>


                                {/* <span className="deactivate-button1" onClick={() => setData(<span className="badge badge-danger px-4 py-2 ms-2 active-button">Inactive</span>)}><i className="bi bi-bell-slash me-2 fs-6"></i>Deactivate School</span> */}


                                <div className="d-flex align-items-center ms-4 pointer">
                                    <span
                                        onClick={(event) => props.handleDelete(event, row)}
                                    ><i className="far fa-trash-alt me-2 fs-6"></i>
                                        Delete</span>
                                </div>
                                <div className="d-flex align-items-center ms-4 pointer">
                                    {row.subscriptionData !== null && (row.subscriptionData.type === "subscription" ?
                                        <span>
                                            <i class="far fa-calendar me-2 fs-6"></i>Extend Trial
                                        </span>
                                        :
                                        <span onClick={(event) => props.extendTrial(event, row)}>
                                            <i class="far fa-calendar me-2 fs-6"></i>Extend Trial
                                        </span>
                                    )}
                                </div>
                                <div className="d-flex align-items-center ms-4 pointer">
                                    {row.subscriptionData !== null &&
                                        <span onClick={(event) => props.startSubscription(event, row)}>
                                            <i class="far fa-paper-plane me-2 fs-6"></i>Start Subscription
                                        </span>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                );
            },
        },
    ]);
    const handleTableChange = (type, { sortField, sortOrder, page }) => {
        if (type === "sort") {
            props.propsfiltersort(sortField);
            props.propsfilterorder(sortOrder);
        }

        if (type === "pagination") {
            props.propsfilterpage(page);
        }
    };
    const RemotePagination = ({ columns, dataField, order, data, page, sizePerPage, onTableChange, totalSize }) => (
        <PaginationProvider
            pagination={paginationFactory({
                custom: true,
                page,
                sizePerPage,
                totalSize,
            })}
        >
            {({ paginationProps, paginationTableProps }) => (
                <>
                    <div className="table-responsive common-table superadmin_table">
                        <BootstrapTable
                            remote
                            bootstrap4
                            data={data}
                            keyField="student_uuid"
                            sort={{ dataField: dataField, order: order }}
                            columns={columns}
                            {...paginationTableProps}
                            onTableChange={onTableChange}
                            sizePerPageList={sizePerPage}
                            headerClasses="fw-bolder text-white bg-dark-row"
                            classes="table-bordered align-middle custom-table-style table-fields"
                            noDataIndication={() => <span className="text-center text-gray-400">No records found</span>}
                        />
                    </div>
                    <div className="d-flex flex-stack justify-content-between flex-wrap pt-3 pagination-main">

                        {totalSize > 0 && (
                            <div className="pagination_limt">
                                <select id='select' name='group' className='form-control input-sm form-select form-select-sm form-select-solid' size='1' value={perpagedata}
                                    onChange={(event) => {
                                        props.propsfilterpage(1);
                                        setperpagedata(event.target.value);
                                        props.propsfilterlimit(parseInt(event.target.value));
                                    }}
                                >
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={250}>250</option>
                                    <option value={500}>500</option>
                                </select>
                                {(parseInt(sizePerPage) * parseInt(page)) > totalSize ? (
                                    <div className="count_number">Displaying {(parseInt(sizePerPage) * parseInt(page) + parseInt(1)) - (parseInt(perpagedata))}-{totalSize} of {totalSize} records</div>
                                ) : (
                                    <div className="count_number">Displaying {(parseInt(sizePerPage) * parseInt(page) + parseInt(1)) - (parseInt(perpagedata))}-{parseInt(sizePerPage) * parseInt(page)} of {totalSize} records</div>
                                )}
                            </div>
                        )}

                        <div className="pagination_page">
                            <PaginationListStandalone {...paginationProps} />
                        </div>
                    </div>
                </>
            )}
        </PaginationProvider>
    );
    return (
        <React.Fragment>
            <div className="row gy-5 g-xl-8">
                <div className="col-xl-12">

                    <div className="table-responsive">
                        <div id="kt_profile_overview_table" className="dataTables_wrapper dt-bootstrap4 no-footer team-list-table">
                            <div className="table-responsive">
                                <div className="card-body p-0 bg-white radious-style radious-users  mt-5">
                                    {/* <Spin spinning={props.loading}> */}
                                    <RemotePagination
                                        data={props.data}
                                        columns={columns}
                                        page={props.filterpage}
                                        totalSize={props.SchoolListcount}
                                        sizePerPage={props.filterlimit}
                                        order={props.filterorder}
                                        onTableChange={handleTableChange}
                                    />
                                    {/* </Spin> */}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default SchoolItems;