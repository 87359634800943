import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";
import ReactPhoneInput from 'react-phone-input-2'


class EditStudentHost extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      lgShow: false,
      edit_host_page: {
        host_address: {
          type: "text",
          label: "Host's Address *",
          value: "",
          valid: true,
          error_msg: "",
          onBlur_out: false,
          placeholder: "Host's Address",
          validations: { required: true },
        },
        host_id: {
          type: "text",
          label: "Host id",
          value: "",
          valid: true,
          error_msg: "",
          onBlur_out: false,
          placeholder: "Host id",
          validations: { required: false },
        },
        host_name: {
          type: "text",
          label: "Host Name *",
          value: "",
          valid: true,
          error_msg: "",
          onBlur_out: false,
          placeholder: "Host Name",
          validations: { required: true },
        },
        host_uuid: {
          type: "text",
          label: "Host uuid",
          value: false,
          valid: true,
          error_msg: "",
          onBlur_out: false,
          placeholder: "Host uuid",
          validations: { required: false },
        },



        host_relation: {
          type: "text",
          label: "Host Relation *",
          value: "",
          valid: true,
          error_msg: "",
          onBlur_out: false,
          placeholder: "Host Relation",
          validations: { required: true },
        },
        host_status: {
          type: "hidden",
          label: "Status",
          value: "null",
          valid: true,
          error_msg: "",
          onBlur_out: false,
          validations: { required: false },
        },
        is_host_approved: {
          type: "checkbox",
          label: "Automatically approved as Host",
          value: "",
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        student_host_comment: {
          type: "text",
          label: "Student Host Comment",
          value: "",
          valid: true,
          error_msg: "Student Host Comment",
          validations: { required: false },
        },
        student_host_id: {
          type: "text",
          label: "Student Host Id",
          value: "",
          valid: true,
          error_msg: "",
          onBlur_out: false,
          placeholder: "Student Host Id",
          validations: { required: false },
        },


        host_email: {
          type: "email",
          label: "Host's Email *",
          value: "",
          valid: true,
          error_msg: "",
          onBlur_out: false,
          placeholder: "Host's Email",
          validations: { required: true, email: true },
        },
        host_contact: {
          type: "text",
          label: "Host's Mobile Number *",
          value: "",
          valid: true,
          error_msg: "",
          onBlur_out: false,
          placeholder: "Host's Mobile Number",
          validations: { required: true },
        },


        student_first_name: {
          type: "text",
          label: "Student Name",
          value: "",
          valid: true,
          error_msg: "",
          onBlur_out: false,
          placeholder: "Student First Name",
          validations: { required: false },
        },
        student_last_name: {
          type: "text",
          label: "Student Name",
          value: "",
          valid: true,
          error_msg: "",
          onBlur_out: false,
          placeholder: "Student last Name",
          validations: { required: false },
        },
        student_id: {
          type: "text",
          label: "Host id",
          value: "",
          valid: true,
          error_msg: "",
          onBlur_out: false,
          placeholder: "student id",
          validations: { required: false },
        },
        student_uuid: {
          type: "text",
          label: "student uuid",
          value: "",
          valid: true,
          error_msg: "",
          onBlur_out: false,
          placeholder: "student uuid",
          validations: { required: false },
        },
        unique_pin: {
          type: "text",
          label: "uniq pin",
          value: "",
          valid: true,
          error_msg: "",
          onBlur_out: false,
          placeholder: "uniq pin",
          validations: { required: false },
        },

      }
    }
  }
  componentDidMount() {
    const updade_edit_host_page = { ...this.state.edit_host_page }
    const host_data = this.props.item;
    const stu_host_data = this.props.item;
    for (let key in updade_edit_host_page) {
      if (stu_host_data[key]) {
        updade_edit_host_page[key].value = stu_host_data[key]
      }
      if (host_data[key]) {
        updade_edit_host_page[key].value = host_data[key]
      }
      this.setState({ edit_host_page: updade_edit_host_page })
    }
    this.setState({ lgShow: this.props.show_edit_host_moadal })

  }
  closeModal = () => {
    this.props.hideAddHostModal(false)
  }


  inputChangeHandler(e, identifire) {
    const updade_edit_host_page = { ...this.state.edit_host_page };
    for (let key in updade_edit_host_page) {
      if (key === identifire) {
        updade_edit_host_page[key].value = e.target.value;
      }
    }
    this.setState({ edit_host_page: updade_edit_host_page })
  }


  inputPhoneHandler = (e, identifier) => {
    const updade_edit_host_page = { ...this.state.edit_host_page };
    updade_edit_host_page[identifier].value = e;
    this.setState({ edit_host_page: updade_edit_host_page })
  }


  SubmitHandlerHost(event) {
    event.preventDefault();
    let is_form_valid = true;
    const update_edit_host_page = this.state.edit_host_page;
    for (let key in update_edit_host_page) {
      let form_element = update_edit_host_page[key];
      update_edit_host_page[key] = form_element;
    }
    this.setState({ edit_host_page: update_edit_host_page });
    console.log("host_data=========", update_edit_host_page);
    if (is_form_valid) {
      const form_data = {}
      let old_host_data = this.state.edit_host_page;
      let update_host_data = []
      update_host_data.push({
        "host_id": old_host_data.host_id.value,
        "host_name": old_host_data.host_name.value,
        "host_email": old_host_data.host_email.value,
        "host_relation": old_host_data.host_relation.value,
        "host_contact": old_host_data.host_contact.value,
        "host_address": old_host_data.host_address.value,
        "is_host_approved": old_host_data.is_host_approved.value,
        // "remark_boarding": old_host_data.remark_boarding.value,
        // "remark_host": old_host_data.remark_host.value,
        // "remark_parents": old_host_data.remark_parents.value,
        "student_host_comment": old_host_data.student_host_comment.value,
      })
      console.log("form_data", update_host_data);
      form_data['student_id'] = this.state.edit_host_page.student_id.value;
      form_data['host_data'] = JSON.stringify(update_host_data);
      this.props.AddOrUpdateStudentHost(form_data)
    }
  }
  render() {
    const { edit_host_page } = this.state;
    return (
      <>
        <Modal
          size="lg"
          show={this.state.lgShow}
          onHide={this.closeModal}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Edit Host
            </Modal.Title>
          </Modal.Header>
      
            <form onSubmit={(e) => this.SubmitHandlerHost(e)}>
            <Modal.Body className="show-grid">
              <div className='row'>
              <div className="col-md-6 mb-4">
                <label className="form-label">{edit_host_page.student_first_name.label}</label>
                <input
                  type={edit_host_page.student_first_name.type}
                  className="form-control"
                  value={edit_host_page.student_first_name.value + " " + edit_host_page.student_last_name.value}
                  readOnly
                />
              </div>
              <div className="col-md-6 mb-4">
                <label className="form-label">{edit_host_page.host_name.label}</label>
                <input
                  type={edit_host_page.host_name.type}
                  className="form-control"
                  value={edit_host_page.host_name.value ? edit_host_page.host_name.value : ""}
                  onChange={(e) => this.inputChangeHandler(e, "host_name")}
                />
              </div>
              <div className="col-md-6 mb-4">
                <label className="form-label">{edit_host_page.host_email.label}</label>
                <input
                  type={edit_host_page.host_email.type}
                  className="form-control"
                  value={edit_host_page.host_email.value ? edit_host_page.host_email.value : ""}
                  onChange={(e) => this.inputChangeHandler(e, "host_email")}
                />
              </div>
              <div className="col-md-6 mb-4">
                <label className="form-label">{edit_host_page.host_contact.label}</label>
                {console.log("host_contact",edit_host_page.host_contact.value)}
                <ReactPhoneInput
                  countryCodeEditable={false}
                  value={edit_host_page.host_contact.value ? edit_host_page.host_contact.value : ""}
                  onChange={(event) => this.inputPhoneHandler(event, "host_contact")}
                  enableLongNumbers={true}
                  autoComplete="off"
                />
                {!edit_host_page.host_contact.valid && edit_host_page.host_contact.onBlur_out ? <div className="error field-error">{edit_host_page.host_contact.error_msg}</div> : ""}

              </div>
              <div className="col-md-6 mb-4">
                <label className="form-label">{edit_host_page.host_relation.label}</label>
                <input
                  type={edit_host_page.host_relation.type}
                  className="form-control"
                  value={edit_host_page.host_relation.value ? edit_host_page.host_relation.value : ""}
                  onChange={(e) => this.inputChangeHandler(e, "host_relation")}
                />
              </div>
              <div className="col-md-6 mb-4">
                <label className="form-label">{edit_host_page.host_address.label}</label>
                {console.log("ost_address",edit_host_page.host_address.value)}
                <input
                  type={edit_host_page.host_address.type}
                  className="form-control"
                  value={edit_host_page.host_address.value ? edit_host_page.host_address.value : ""}
                  onChange={(e) => this.inputChangeHandler(e, "host_address")}
                />
              </div>
              <div className="mb-4">
                <label className="form-label">{edit_host_page.student_host_comment.label}</label>
                <input
                  type={edit_host_page.student_host_comment.type}
                  className="form-control"
                  value={edit_host_page.student_host_comment.value ? edit_host_page.student_host_comment.value : ""}
                  onChange={(e) => this.inputChangeHandler(e, "student_host_comment")}
                />
              </div>
              <div className="mb-0">
                <div className='row'>
                  <div className='col-12'>
                    <label className="form-label">{edit_host_page.host_status.label}</label>
                    <span className='ms-5'>
                      {edit_host_page.host_status.value ? (
                        <h1 className="badge badge-light-success px-4 py-2">Active</h1>
                      ) : (
                        <h1 className="badge badge-light-danger px-4 py-2">Inactive</h1>
                      )}
                    </span>
                  </div>
                  <div className='col-12'>
                    <label className="form-label">{edit_host_page.is_host_approved.label}</label>
                    <span className='ms-5'>
                      {edit_host_page.is_host_approved.value ? (
                        <h1 className="badge badge-light-success px-4 py-2">Approved</h1>
                      ) : (
                        <h1 className="badge badge-light-danger px-4 py-2">Unapproved</h1>
                      )}
                    </span>
                  </div>
                  </div>
                </div>
              </div>
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-secondary btn-sm" onClick={this.closeModal}>
                  Close
                </button>
                <button class="btn btn-primary btn-sm" type="submit" onClick={this.handleClose}>
                  Save Changes
                </button>
              </Modal.Footer>
            </form>
          
        </Modal>
      </>
    );
  }
}

export default EditStudentHost;

