import axios from "axios";
import * as actionType from "../constants/actionTypes";
let qs = require("qs");


/*** StudentsRollOver Action ***/

export const StudentsRollOverStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.StudentsRollOver_START,
    };
};
export const StudentsRollOverSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.StudentsRollOver_SUCCESS,
    };
};
export const StudentsRollOverFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.StudentsRollOver_FAIL,
    };
};

export const StudentsRollOver = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(StudentsRollOverStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/StudentsRollOver`,
        })
            .then(function (response) {
                dispatch(StudentsRollOverSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(StudentsRollOverFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** GetAllClassesDetailsRollOver Action ***/
export const GetAllClassesDetailsRollOverStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllClassesDetailsRollOver_START,
    };
};
export const GetAllClassesDetailsRollOverSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllClassesDetailsRollOver_SUCCESS,
    };
};
export const GetAllClassesDetailsRollOverFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllClassesDetailsRollOver_FAIL,
    };
};
export const GetAllClassesDetailsRollOver = (page, limit, sort, order, is_class_activate ) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllClassesDetailsRollOverStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllClassesDetails`,
            params: {
                page: page,
                limit: limit,
                sort: sort,
                order: order,
                is_class_activate:is_class_activate,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllClassesDetailsRollOverSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllClassesDetailsRollOverFail("Something went wrong, Please try again."));
                return error;
            });
    };
};
