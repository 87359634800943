import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import "react-datepicker/dist/react-datepicker.css";
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Select from 'react-select';
import moment from "moment";
import { tr } from "date-fns/locale";
// import { Link } from "react-router-dom";
const firstIndex = 0;
const AttendanceHistoryItem = (props) => {
    console.log("all_data=============", props);

    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [data, setData] = useState(props.data.slice(firstIndex, pageSize));
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    const timezone = user_info?.user_school.timezone;
    const row_limit = [
        { value: 5, label: 5 },
        { value: 10, label: 10 },
        { value: 15, label: 15 },
        { value: 20, label: 20 },
        { value: 25, label: 25 }
    ]
    useEffect(() => {
        setData(props.data.slice(0, pageSize));
    }, [props.data, pageSize]);

    const handleChange = (event, value) => {
        setPage(value);
        setData(props.data.slice(firstIndex + pageSize * (value - 1), pageSize * value));
    };

    const changeWidth = (e, value) => {
        setPageSize(parseInt(e.value, 10));
    };

    return (
        <React.Fragment>
            <div className="row gy-5 g-xl-8">
                <div className="col-xl-12">
                   
                        <div className="card-body">
                            <div className="table-responsive">
                                <div id="kt_profile_overview_table" className="dataTables_wrapper dt-bootstrap4 no-footer team-list-table">
                                    <div className="table-responsive">
                                        <div className="bg-white radious-style radious-users">
                                            {data.map((item) => {
                                                return (
                                                    <div className="mb-5">
                                                        <div className="mb-5">
                                                            <div className="w-100 d-flex align-items-center justify-content-between">
                                                                <h4 className=" fs-6 d-flex">
                                                                    Rollcall Conducted By
                                                                    <span className="d-none d-lg-block bullet h-20px w-1px bg-secondary mx-4"></span>
                                                                    <span className=" fs-6 text-muted">
                                                                        {item.attend_user.first_name + " " + item.attend_user.last_name}
                                                                    </span>
                                                                    <span className="d-none d-lg-block bullet h-20px w-1px mx-4"></span>
                                                                    Session name
                                                                    <span className="d-none d-lg-block bullet h-20px w-1px bg-secondary mx-4"></span>
                                                                    <span className="fs-6 text-muted">
                                                                        ({item.attendance_title})
                                                                    </span>
                                                                </h4>
                                                                <h4 className="fs-6 text-muted"> ({moment(item.created_date).tz(timezone).format('MMMM Do YYYY, h:mm a')})</h4>
                                                            </div>
                                                        </div>

                                                        <div className="table-responsive">
                                                            <table className="table align-middle border rounded table-row-solid fs-6 g-5 dataTable no-footer table-bordered-set mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Roll No.</th>
                                                                        <th>Dormitory</th>
                                                                        <th>Attendance</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {item.atten_data.map((atten_item) => {
                                                                        return (
                                                                            <tr>
                                                                                <td className="w-100px">{atten_item.atten_student.unique_pin}</td>
                                                                                {/* <td>{atten_item.atten_student.student_first_name + " " + atten_item.atten_student.student_last_name}</td>
                                                                                <td>{atten_item.atten_student.class_name}</td>
                                                                                <td>{atten_item.atten_student.gender}</td> */}
                                                                                <td>{atten_item.atten_student.dormitory_data.dormitory_name}</td>
                                                                                <td className="w-200px">
                                                                                    {atten_item.is_attendance === true ?
                                                                                        <span style={{color:"#009A66"}}>Present</span>
                                                                                        : <span style={
                                                                                            atten_item.attendance_reason[0].reason_name && atten_item.attendance_reason[0].reason_name === 'On Campus' ? { color: "rgb(53, 102, 205)"} : atten_item.attendance_reason[0].reason_name === 'KL' ? {color: "rgb(13, 192, 255)"} : atten_item.attendance_reason[0].reason_name === 'MIA' ? {color: "rgb(203, 0, 50)"} : atten_item.attendance_reason[0].reason_name === 'ad-hoc' ? {color: "#FDE101"}: atten_item.attendance_reason[0].reason_name === 'SL' ? {color: "#FF6500"} : {color: "#CB0032"}
                                                                                        }>Absent({atten_item.attendance_reason[0] ? atten_item.attendance_reason[0].reason_name : ""})</span>
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>

                                                )
                                            })

                                            }
                                            {console.log("attendence_data============", data)}

                                            <div className="d-flex justify-content-between mt-7">
                                                <Select
                                                    menuPlacement="top"
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    defaultValue={row_limit[1]}
                                                    name="color"
                                                    options={row_limit}
                                                    onChange={changeWidth}
                                                />
                                                <Stack spacing={1} >
                                                    <Pagination
                                                        count={Math.ceil(props.data.length / pageSize)}
                                                        page={page}
                                                        onChange={handleChange}
                                                        color="primary"
                                                    />
                                                </Stack>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </React.Fragment>
    );
};
export default AttendanceHistoryItem;