import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MessageAdd from "../component/Settings/Messages/MessageAdd";
import MessageEdit from "../component/Settings/Messages/MessageEdit";
import MessagesList from "../component/Settings/Messages/MessagesList";



const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" :user_info?.school_code;
const Message = (props) => {
    const Renderview = () => {
        let location = useLocation();
        let userId = useParams();

        if (location.pathname === `/${school_code}/settings/messages` || location.pathname === `/${school_code}/settings/messages/`) {
            return <MessagesList /> ;
        }
        if (location.pathname === `/${school_code}/settings/messages/edit-message/${userId.id}` || location.pathname === `/${school_code}/settings/messages/edit-message/${userId.id}/`) {
            return <MessageEdit user_message_uuid={userId.id}                   /> ;
        }
        if (location.pathname === `/${school_code}/settings/messages/add-message` || location.pathname === `/${school_code}/settings/messages/add-message/`) {
            return <MessageAdd /> ;
        }

     
    };

    return (
        <React.Fragment>
            <ToastContainer />
            {Renderview()}
        </React.Fragment>
    );
};
export default Message;