import axios from "axios";
import * as actionType from "../constants/actionTypes";
let qs = require("qs");

/*** CreateUserMessage Action ***/
export const CreateUserMessageStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.CreateUserMessage_START,
    };
};
export const CreateUserMessageSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.CreateUserMessage_SUCCESS,
    };
};
export const CreateUserMessageFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.CreateUserMessage_FAIL,
    };
};
export const CreateUserMessage = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    
    return (dispatch) => {
        dispatch(CreateUserMessageStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/CreateUserMessage`,
        })
            .then(function (response) {
                dispatch(CreateUserMessageSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateUserMessageFail("Something went wrong, Please try again."));
                return error;
            });
    };
};





/*** GetAllUserMessages Action ***/
export const GetAllUserMessagesStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllUserMessages_START,
    };
};
export const GetAllUserMessagesSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllUserMessages_SUCCESS,
    };
};
export const GetAllUserMessagesFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllUserMessages_FAIL,
    };
};
export const GetAllUserMessages = (page, limit, sort, order, search ) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllUserMessagesStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllUserMessages`,
            params: {
                page: page,
                limit: limit,
                sort: sort,
                order: order,
                search:search,
               
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllUserMessagesSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllUserMessagesFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetUserMessageById Action ***/
export const GetUserMessageByIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetUserMessageById_START,
    };
};
export const GetUserMessageByIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetUserMessageById_SUCCESS,
    };
};
export const GetUserMessageByIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetUserMessageById_FAIL,
    };
};
export const GetUserMessageById = (user_message_uuid) => {

    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

   
    return (dispatch) => {
        dispatch(GetUserMessageByIdStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetUserMessageById`,
            params: {
                user_message_uuid   : user_message_uuid,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetUserMessageByIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetUserMessageByIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};




/*** DeleteUserMessage Action ***/

export const DeleteUserMessageStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.DeleteUserMessage_START,
    };
};
export const DeleteUserMessageSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.DeleteUserMessage_SUCCESS,
    };
};
export const DeleteUserMessageFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.DeleteUserMessage_FAIL,
    };
};
export const DeleteUserMessage = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(DeleteUserMessageStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "delete",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
                
            },
            url: `${actionType.environment_url}/api/bss-admin/DeleteUserMessage`,
        })
            .then(function (response) {
                console.log("resposne", response.data);
                dispatch(DeleteUserMessageSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                console.log("resposne fail", error);
                dispatch(DeleteUserMessageFail("Something went wrong, Please try again."));
                return error;
            });
    };
};




/*** UpdateUserMessage Action ***/

export const UpdateUserMessageStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateUserMessage_START,
    };
};
export const UpdateUserMessageSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateUserMessage_SUCCESS,
    };
};
export const UpdateUserMessageFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateUserMessage_FAIL,
    };
};

export const UpdateUserMessage = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(UpdateUserMessageStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/UpdateUserMessage`,
        })
            .then(function (response) {
                dispatch(UpdateUserMessageSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateUserMessageFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** UpdateUserMessageStatus Action ***/

export const UpdateUserMessageStatusStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateUserMessageStatus_START,
    };
};

export const UpdateUserMessageStatusSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateUserMessageStatus_SUCCESS,
    };
};

export const UpdateUserMessageStatusFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateUserMessageStatus_FAIL,
    };
};

export const UpdateUserMessageStatus = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(UpdateUserMessageStatusStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/UpdateUserMessageStatus`,
        })
            .then(function (response) {
                dispatch(UpdateUserMessageStatusSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateUserMessageStatusFail("Something went wrong, Please try again."));
                return error;
            });
    };
};





