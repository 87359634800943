import React from "react";
// import Gleap from "gleap";
import { connect } from "react-redux";
import { socket } from "../src/constants/actionTypes";
import { toast } from "react-toastify";
import Routers from "./Routers";
import moment from "moment";
import "moment-timezone";
// import * as userActions from "./actions/index";
import TopBar from "./component/Constant/TopBar";
const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const schoolName = user_info?.user_school?.actual_school_code;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      permission_checked: true,
      socket_called: 0,
    };
  }

  componentDidMount() {
    socket.on("new-notification", (resp) => {
      console.log("socket", resp);
      let session_title;
      if (resp.attendance_title) {
        session_title = resp.attendance_title;
      } else {
        session_title = "Snapshot";
      }

      const current = new Date(resp.created_date);
      const timezone = user_info?.user_school?.timezone;
      const time = moment(current).tz(timezone).format("MMMM Do YYYY, h:mm a");

      let counting = this.state.socket_called;
      this.setState({ socket_called: counting + 1 });

      console.log("response 11 of notification in socket.on", resp);
      if (resp.school_code === schoolName) {
        toast.warning(
          ` ${resp.student_first_name} ${resp.student_last_name} from ${resp.dormitory_name}  has been listed as MIA by ${resp.user_name} at ${time} via ${session_title}`,
          { position: toast.POSITION.BOTTOM_RIGHT }
        );
      }
    });
  }

  render() {
    console.log("Count", this.state.socket_called);
    localStorage.setItem(
      "Socket_calls",
      JSON.stringify(this.state.socket_called)
    );
    if (this.state.permission_checked === false) {
      return null;
    }

    return (
      <div className="App">
        <Routers />
      </div>
    );
  }
}


function mapStateToProps(state) {
  // return {
  //   loader: state.Auth.loader,
  //   error: state.Auth.error,
  // };
}
const mapDispatchToProps = {
  //onGetAuthUser: userActions.GetAuthUser,
};
// export default App;
export default connect(mapStateToProps, mapDispatchToProps)(App);
