import axios from "axios";
import * as actionType from "../constants/actionTypes";
import { logout } from "./auth";
let qs = require("qs");



/*** GetAllStudentsAllergy Action ***/

export const GetAllStudentsAllergyStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllStudentsAllergy_START,
    };
};
export const GetAllStudentsAllergySuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllStudentsAllergy_SUCCESS,
    };
};
export const GetAllStudentsAllergyFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllStudentsAllergy_FAIL,
    };
};




export const GetAllStudentsAllergy = (class_id, dormitory_id, page, limit, sort, order, is_student_activate, search, student_age,) => {
    // console.log("Ewrwetretwtrt",class_id);
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllStudentsAllergyStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info?.country_host,
                school_code: user_info?.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllStudents`,
            params: {
                page: page,
                limit: limit,
                sort: sort,
                order: order,
                search: search,
                is_student_activate: is_student_activate,
                student_age: student_age,
                dormitory_id: dormitory_id,
                class_id: class_id,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllStudentsAllergySuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllStudentsAllergyFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** AddStudentNewAllergyDetails Action ***/

export const AddStudentNewAllergyDetailsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.AddStudentNewAllergyDetails_START,
    };
};
export const AddStudentNewAllergyDetailsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.AddStudentNewAllergyDetails_SUCCESS,
    };
};
export const AddStudentNewAllergyDetailsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.AddStudentNewAllergyDetails_FAIL,
    };
};
export const AddStudentNewAllergyDetails = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(AddStudentNewAllergyDetailsStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info?.country_host,
                school_code: user_info?.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/AddStudentNewAllergyDetails`,
        })
            .then(function (response) {
                dispatch(AddStudentNewAllergyDetailsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(AddStudentNewAllergyDetailsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};






/***  GetAllergicStudentDetailById ***/



export const GetAllergicStudentDetailByIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllergicStudentDetailById_START,
    };
};
export const GetAllergicStudentDetailByIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllergicStudentDetailById_SUCCESS,
    };
};
export const GetAllergicStudentDetailByIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllergicStudentDetailById_FAIL,
    };
};
export const GetAllergicStudentDetailById = (student_uuid) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllergicStudentDetailByIdStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info?.country_host,
                school_code: user_info?.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllergicStudentDetailById`,
            params: {
                student_uuid: student_uuid,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllergicStudentDetailByIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllergicStudentDetailByIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};




/***  GetAllAllergicStudentsDetail ***/



export const GetAllAllergicStudentsDetailStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllAllergicStudentsDetail_START,
    };
};
export const GetAllAllergicStudentsDetailSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllAllergicStudentsDetail_SUCCESS,
    };
};
export const GetAllAllergicStudentsDetailFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllAllergicStudentsDetail_FAIL,
    };
};
export const GetAllAllergicStudentsDetail = (page, limit, sort, order, is_student_activate, search, student_age, dormitory_id, class_id, gender,allergy_name) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllAllergicStudentsDetailStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info?.country_host,
                school_code: user_info?.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllAllergicStudentsDetail`,
            params: {
                page: page,
                limit: limit,
                sort: sort,
                order: order,
                search: search,
                is_student_activate: is_student_activate,
                student_age: student_age,
                dormitory_id: dormitory_id,
                class_id: class_id,
                gender: gender,
                allergy_name:allergy_name
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllAllergicStudentsDetailSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllAllergicStudentsDetailFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** GetAllNonAllergicStudentsList ***/



export const GetAllNonAllergicStudentsListStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllNonAllergicStudentsList_START,
    };
};
export const GetAllNonAllergicStudentsListSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllNonAllergicStudentsList_SUCCESS,
    };
};
export const GetAllNonAllergicStudentsListFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllNonAllergicStudentsList_FAIL,
    };
};
export const GetAllNonAllergicStudentsList = (class_id, dormitory_id, gender, student_age) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllNonAllergicStudentsListStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info?.country_host,
                school_code: user_info?.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllNonAllergicStudentsList`,
            params: {
                class_id: class_id,
                dormitory_id: dormitory_id,
                gender: gender,
                student_age: student_age
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllNonAllergicStudentsListSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllNonAllergicStudentsListFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** AddOrUpdateStudentAllergyDetails ***/


export const AddOrUpdateStudentAllergyDetailsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.AddOrUpdateStudentAllergyDetails_START,
    };
};
export const AddOrUpdateStudentAllergyDetailsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.AddOrUpdateStudentAllergyDetails_SUCCESS,
    };
};
export const AddOrUpdateStudentAllergyDetailsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.AddOrUpdateStudentAllergyDetails_FAIL,
    };
};

export const AddOrUpdateStudentAllergyDetails = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(AddOrUpdateStudentAllergyDetailsStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/AddOrUpdateStudentAllergyDetails`,
        })
            .then(function (response) {
                dispatch(AddOrUpdateStudentAllergyDetailsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(AddOrUpdateStudentAllergyDetailsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};





/*** RemoveAllAllergyOfStudent ***/

export const RemoveAllAllergyOfStudentStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.RemoveAllAllergyOfStudent_START,
    };
};
export const RemoveAllAllergyOfStudentSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.RemoveAllAllergyOfStudent_SUCCESS,
    };
};
export const RemoveAllAllergyOfStudentFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.RemoveAllAllergyOfStudent_FAIL,
    };
};
export const RemoveAllAllergyOfStudent = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    console.log("action_file", form_data);
    return (dispatch) => {
        dispatch(RemoveAllAllergyOfStudentStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "delete",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info?.country_host,
                school_code: user_info?.school_code,

            },
            url: `${actionType.environment_url}/api/bss-admin/RemoveAllAllergyOfStudent`,
        })
            .then(function (response) {
                console.log("resposne", response.data);
                dispatch(RemoveAllAllergyOfStudentSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(RemoveAllAllergyOfStudentFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** GenerateAllergicStudentsReport ***/
export const GenerateAllergicStudentsReportStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GenerateAllergicStudentsReport_START,
    };
};
export const GenerateAllergicStudentsReportSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GenerateAllergicStudentsReport_SUCCESS,
    };
};
export const GenerateAllergicStudentsReportFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GenerateAllergicStudentsReport_FAIL,
    };
};
export const GenerateAllergicStudentsReport = (dormitory_id, class_id, student_id, search, gender,allergy_name) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GenerateAllergicStudentsReportStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GenerateAllergicStudentsReport`,
            params: {

                dormitory_id: dormitory_id,
                class_id: class_id,
                student_id: student_id,
                search : search,
                gender: gender,
                allergy_name:allergy_name
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GenerateAllergicStudentsReportSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GenerateAllergicStudentsReportFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** GetAllAllergyList ***/

export const GetAllAllergyListStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllAllergyList_START,
    };
};
export const GetAllAllergyListSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllAllergyList_SUCCESS,
    };
};
export const GetAllAllergyListFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllAllergyList_FAIL,
    };
};
export const GetAllAllergyList = (dormitory_id, class_id, student_id, search, gender) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllAllergyListStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllAllergyList`,
            params: {

                dormitory_id: dormitory_id,
                class_id: class_id,
                student_id: student_id,
                search : search,
                gender: gender
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllAllergyListSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllAllergyListFail("Something went wrong, Please try again."));
                return error;
            });
    };
};





