import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import Sidebar from "../Constant/sidebar";
import Footer from "../Constant/Footer";
import TopBar from "../Constant/TopBar";
import { Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import * as userActions from "../../actions/index";
import AddRollModal from "../Permissions/AddRollModal";
import EditableRow from "./EditableRow";
import ReadOnlyRow from "./ReadOnlyRow";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Select from 'react-select';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};
const permission_info = JSON.parse(localStorage.getItem("permissions"));

function PermissionList(props) {
    const dispatch = useDispatch();
    const [roleList, setRoleList] = useState();
    const [editRoleId, setEditRoleId] = useState(null);
    const [all_role_name, setAll_role_name] = useState([])
    const [allRoleObject,setAllRoleObject] =useState([])
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(25)
    const [count, setCount] = useState(0)
    const [is_role_activate, setIs_role_activate] = useState(true)
    const row_limit = [
        { value: 25, label: 25 },
        { value: 50, label: 50 },
        { value: 75, label: 75 },
        { value: 100, label: 100 },
    ]

    const [editRoleRow, setEditRoleRow] = useState({
        role_name: "",
    });

    const GetAllRoleDetails = async () => {
        dispatch(userActions.GetAllRoleDetails(page, limit, is_role_activate)).then((response) => {
            if (response.success === true) {
                const arrayData = response.data.rows;
                setCount(response.data.count)
                let MemberId_level = [];
                let role_names = []
                for (let key in arrayData) {
                    MemberId_level.push({
                        role_name: arrayData[key].role_name,
                        role_uuid: arrayData[key].role_uuid,
                        role_id: arrayData[key].role_id,
                        is_role_activate: arrayData[key].is_role_activate,
                    });
                    role_names.push(arrayData[key].role_name)
                }
                setRoleList(MemberId_level);
                setAll_role_name(role_names)
                setAllRoleObject(MemberId_level)
            } else if (response.status === 401) {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
            else {
            }
        });
    };

    const GetLoginUserPermissions = useCallback(async () => {
        dispatch(userActions.GetLoginUserPermissions()).then((response) => {
            if (response.success === true) {
            } else if (response.status === 401) {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
            }
        });
    }, [dispatch]);

    const CreateDuplicateRoleAndPerm = useCallback(async (role_uuid, role_name) => {
        const Payload = { role_uuid: role_uuid, role_name: role_name }
        dispatch(userActions.CreateDuplicateRoleAndPerm(Payload)).then((response) => {
            if (response.success === true) {
                GetAllRoleDetails();
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else if (response.status === 401) {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
            }
        });
    }, [dispatch])

    const DeleteRole = async (role_uuid) => {
        const Payload = { role_uuid: role_uuid }
        dispatch(userActions.DeleteRole(Payload)).then((response) => {
            if (response.success === true) {
                GetAllRoleDetails();
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else if (response.status === 401) {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
            }
        });
    }

    useEffect(() => {
        GetAllRoleDetails();
        GetLoginUserPermissions();
    }, [page, limit, is_role_activate])

    const handleEdit = (role_id) => {
        localStorage.setItem('role_id', JSON.stringify(role_id));
    };

    // edit name function   ================

    const editName = (event, item, i) => {
        const updateRoleList = roleList.map((item, index) => {
            if (index === i) {
                return { ...item, role_name: event.target.value };
            }
            return item;
        })
        setRoleList(updateRoleList)
    }

    // for enter button function ==================

    const onEnter_editName = (e, item) => {
        if (e.key === "Enter" || e.keyCode === 13) {
            updateRoleName(item.role_uuid, item.role_name)
        }
    }

    const updateRoleName = (role_uuid, role_name) => {
        const findRole = allRoleObject.find((item)=>{return item.role_uuid === role_uuid})
        if (role_name === "") {
            toast.error("Add role name", {
                position: toast.POSITION.TOP_CENTER,
            });
        } 
        else if (findRole.role_uuid === role_uuid && findRole.role_name === role_name) {
            let Payload = {
                role_uuid: role_uuid,
                role_name: role_name
            }
            props.onUpdateRole(Payload).then((response) => {
                if (response.success === true) {
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });

                    setTimeout(() => {
                        GetAllRoleDetails()
                    }, 5000);
                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            });
            setEditRoleId(null);
        } 
        else if(findRole.role_uuid === role_uuid && findRole.role_name !== role_name && !all_role_name.includes(role_name.trim())) {
            let Payload = {
                role_uuid: role_uuid,
                role_name: role_name
            }
            props.onUpdateRole(Payload).then((response) => {
                if (response.success === true) {
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });

                    setTimeout(() => {
                        GetAllRoleDetails()
                    }, 5000);
                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            });
            setEditRoleId(null);
        }else{   
            toast.error("This role name is already exist", {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    }

    const handleChange = async (event, role_uuid, value, role_name) => {
        const Payload = {
            role_uuid: role_uuid,
            is_role_activate: value,
            role_name: role_name
        };
        props.onUpdateRoleStatus(Payload).then((response) => {
            if (response.success === true) {
                GetAllRoleDetails();
                // setfilterpage(1);
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });

            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    }
    // cancel button ===============

    const handleCancelClick = () => {
        setEditRoleId(null);
    };

    // for readonly component to Editable row component =============

    const handleEditClick = (event, item, index) => {
        setEditRoleId(index);
        const formValues = {
            role_name: item.role_name,
        };
        setEditRoleRow(formValues);
    };

    const filterRow = (e) => {
        if (e.target.value == 2) {
            setIs_role_activate(false)
        } else if (e.target.value == 3) {
            setIs_role_activate("")
        } else {
            setIs_role_activate(true)
        }
    }
    const handleChange_filter = (event, value) => {
        setPage(value);
    };
    const changeWidth = (v) => {
        setLimit(v.value)
    }
   
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const items = reorder(roleList, result.source.index, result.destination.index);
        const form_data = {};
        form_data['reorderedRoles'] = JSON.stringify(items)
        props.onReorderRoles(form_data).then((response) => {
            if (response.success === true) {
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
        setRoleList(items)
    }

    return (
        <>

            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <Sidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid heding-element" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-fluid">
                                        <div className="row gx-5 gx-xl-10">
                                            <div className="col-xl-12">
                                                <div className="tab-content" id="myTabContent">
                                                    <div className="tab-pane fade show active" id="Snapshot" role="tabpanel" aria-labelledby="Snapshot-tab">
                                                        <div className="card tabs-outer">
                                                            <div className="card-header position-relative py-0 details-bg">
                                                                {/* <!--begin::Card title--> */}
                                                                <h2 className="card-title  m-0 d-flex">
                                                                    Role and Permissions{" "}
                                                                    <Tooltip title="Until you are familiar with this system - it may be prudent to be liberal when you “assign” staff (aka Managers) access to these ”Permissions” It may assist with becoming more familiar with this system & it’s routines.
                                                                        They can always be revised at a later stage." placement="top">
                                                                        <i className="fas fa-question-circle ms-2"></i>
                                                                    </Tooltip>
                                                                </h2>
                                                                <div className="card-toolbar me-auto ms-5">
                                                                    {permission_info?.add_role !== false && (<AddRollModal GetAllRoleDetails={GetAllRoleDetails} />)}
                                                                </div>
                                                                <div className="d-flex align-items-center">
                                                                    <select
                                                                        style={{ borderRadius: "18px" }}
                                                                        class="form-select form-select-sm me-4" id="mySelect" aria-label=".form-select-sm example" onChange={filterRow}>
                                                                        <option selected value="1">
                                                                            Active
                                                                        </option>
                                                                        <option value="2">Deactivated</option>
                                                                        <option value="3">All</option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="table-responsive common-table custom-message-table inner-table-set">
                                                                        {/* <!--begin::Table--> */}
                                                                        <table className="table table-row-bordered align-middle gs-0 gy-2 my-0 ">
                                                                            {/* <!--begin::Table head--> */}
                                                                            <thead>
                                                                                <tr className="fw-bolder">
                                                                                    <th className="pb-3">#</th>
                                                                                    <th className="pb-3 text-justify">Role Name</th>
                                                                                    <th className="pb-3 text-justify">Active/Deactivated</th>
                                                                                    <th className="pb-3 text-justify">Status</th>
                                                                                    <th className="pb-3 text-end w-25">Permissions</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <DragDropContext onDragEnd={onDragEnd}>
                                                                                    <Droppable droppableId="droppable">
                                                                                        {(provided, snapshot) => (
                                                                                            <tr
                                                                                                {...provided.droppableProps}
                                                                                                ref={provided.innerRef}
                                                                                            >
                                                                                                <td colspan="5">
                                                                                                    {roleList ? roleList.map((item, index) => (
                                                                                                        editRoleId === index ?
                                                                                                            <EditableRow
                                                                                                                index={index}
                                                                                                                item={item}
                                                                                                                editRoleRow={editRoleRow}
                                                                                                                updateRoleName={updateRoleName}
                                                                                                                handleCancelClick={handleCancelClick}
                                                                                                                editName={editName}
                                                                                                                onEnter_editName={onEnter_editName}
                                                                                                                handleChange={handleChange}
                                                                                                                permission_info={permission_info}
                                                                                                                handleEdit={handleEdit}
                                                                                                                CreateDuplicateRoleAndPerm={CreateDuplicateRoleAndPerm}
                                                                                                                all_role_name={all_role_name}
                                                                                                                DeleteRole={DeleteRole}
                                                                                                            />
                                                                                                            :
                                                                                                            // draggableId={item.role_uuid}
                                                                                                            <Draggable key={item.role_uuid} draggableId={item.role_uuid} index={index}>
                                                                                                                {(provided, snapshot) => (

                                                                                                                    <table
                                                                                                                        ref={provided.innerRef}
                                                                                                                        {...provided.draggableProps}
                                                                                                                        {...provided.dragHandleProps}
                                                                                                                    // style={getItemStyle(
                                                                                                                    //     snapshot.isDragging,
                                                                                                                    //     provided.draggableProps.style
                                                                                                                    // )}
                                                                                                                    >

                                                                                                                        <ReadOnlyRow
                                                                                                                            index={index}
                                                                                                                            item={item}
                                                                                                                            handleEditClick={handleEditClick}
                                                                                                                            handleChange={handleChange}
                                                                                                                            permission_info={permission_info}
                                                                                                                            handleEdit={handleEdit}
                                                                                                                            CreateDuplicateRoleAndPerm={CreateDuplicateRoleAndPerm}
                                                                                                                            all_role_name={all_role_name}
                                                                                                                            DeleteRole={DeleteRole}
                                                                                                                        />
                                                                                                                    </table>

                                                                                                                )}
                                                                                                            </Draggable>
                                                                                                    )) : null}
                                                                                                </td>
                                                                                            </tr>
                                                                                        )}
                                                                                    </Droppable>
                                                                                </DragDropContext>
                                                                            </tbody>
                                                                        </table>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex justify-content-between px-10 pb-10">
                                                                <Select
                                                                    menuPlacement="top"
                                                                    className="basic-single"
                                                                    classNamePrefix="select"
                                                                    defaultValue={row_limit[0]}
                                                                    name="color"
                                                                    options={row_limit}
                                                                    onChange={changeWidth}
                                                                />
                                                                <Stack spacing={1} >
                                                                    <Pagination
                                                                        count={Math.ceil(count / limit)}
                                                                        page={page}
                                                                        onChange={handleChange_filter}
                                                                        color="primary"
                                                                    />
                                                                </Stack>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
    onGetAllRoleList: userActions.GetAllRoleList,
    onGetLoginUserPermissions: userActions.GetLoginUserPermissions,
    onUpdateRole: userActions.UpdateRole,
    onUpdateRoleStatus: userActions.UpdateRoleStatus,
    onReorderRoles: userActions.ReorderRoles,

};
export default connect(mapStateToProps, mapDispatchToProps)(PermissionList);
