import React from "react";
import { Navigate } from "react-router-dom";
import Select from "react-select";
import { connect } from "react-redux";

import Validations from "../../Utility/Validations";
import Footer from "../../Constant/Footer";
import SuperAdminTopbar from "../topbar";
import SuperAdminSideMenu from "../Sidemenu";
import { toast } from "react-toastify";

import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import Tooltip from '@mui/material/Tooltip';
import AvatarImageCropper from 'react-avatar-image-cropper';
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { subDays } from "date-fns";


import * as userActions from "../../../actions/index";

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#e7f5fe" : "",
        },
        backgroundColor: state.isSelected ? "#4a8fb8" : "",
    }),
};

class SchoolAdd extends React.Component {
    constructor(props) {
        super(props);
        window.addEventListener('keydown', this.handleKeyPress);
        this.state = {
            openStartTrialDiv: true,
            openStartSubscriptionDiv: false,
            display_profile_picture: true,
            display_shadow_picture: false,
            start_current_date: null,

            display_freetrial: true,
            display_subscription: false,

            showerror: false,
            hiddenpassword: true,

            OpenImageModal: false,
            identifiers: "",
            school_form: {
                school_name: {
                    type: "text",
                    label: "School Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "School Name",
                    validations: { required: true },
                },

                school_code: {
                    type: "text",
                    label: "School Code *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "School Code",
                    validations: { required: true, schoolcode: true },
                },
                // actual_school_code: {
                //     type: "text",
                //     label: "School Code",
                //     value: "",
                //     valid: true,
                //     error_msg: "",
                //     onBlur_out: false,
                //     placeholder: "School Code",
                //     validations: { required: false },
                // },

                weekend_day_from: {
                    type: "select",
                    label: "Weekend Start Day *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Select weekend day",
                    validations: { required: true },
                    options: [
                        { label: "From Day", value: "" },
                        { label: "Sunday", value: "Sunday" },
                        { label: "Monday", value: "Monday" },
                        { label: "Tuesday", value: "Tuesday" },
                        { label: "Wednesday", value: "Wednesday" },
                        { label: "Thursday", value: "Thursday" },
                        { label: "Friday", value: "Friday" },
                        { label: "Saturday", value: "Saturday" },
                    ],
                },

                weekend_day_to: {
                    type: "select",
                    label: "Weekend Finish Day *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Select weekend day",
                    validations: { required: true },
                    options: [
                        { label: "To Day", value: "" },
                        { label: "Sunday", value: "Sunday" },
                        { label: "Monday", value: "Monday" },
                        { label: "Tuesday", value: "Tuesday" },
                        { label: "Wednesday", value: "Wednesday" },
                        { label: "Thursday", value: "Thursday" },
                        { label: "Friday", value: "Friday" },
                        { label: "Saturday", value: "Saturday" },
                    ],
                },

                cut_off_day: {
                    type: "select",
                    label: "Cut off Day *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Select Cut off Day",
                    validations: { required: true },
                    options: [
                        { label: "Cut off Day", value: "" },
                        { label: "Sunday", value: "Sunday" },
                        { label: "Monday", value: "Monday" },
                        { label: "Tuesday", value: "Tuesday" },
                        { label: "Wednesday", value: "Wednesday" },
                        { label: "Thursday", value: "Thursday" },
                        { label: "Friday", value: "Friday" },
                        { label: "Saturday", value: "Saturday" },
                    ],
                },

                email: {
                    type: "email",
                    label: "Primary E-mail *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "dikonia@gmail.com",
                    validations: { required: true, email: true },
                },

                password: {
                    type: "password",
                    label: "Password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Abc@123",
                    validations: { required: true, min_length: 8, password: true },
                },

                contact_one: {
                    type: "tel",
                    label: "Primary Contact Number *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "9876543210",
                    validations: { required: true, number: true },
                },

                contact_two: {
                    type: "tel",
                    label: "Alternate Contact Number",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "9876543210",
                    validations: { required: false, number: true },
                },
                country: {
                    type: "text",
                    label: "",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "9876543210",
                    validations: { required: true },
                },

                country_id: {
                    type: "select",
                    label: "Country / Region *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Select weekend day",
                    validations: { required: true },
                    options: [],
                },

                state_id: {
                    type: "select",
                    label: "State *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "State",
                    validations: { required: true },
                    options: [{ label: "State ", value: "" },],
                },
                state: {
                    type: "text",
                    label: "",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "9876543210",
                    validations: { required: true },
                },
                city_id: {
                    type: "select",
                    label: "City *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "City",
                    validations: { required: true },
                    options: [
                        { label: "City", value: "" },
                    ],
                },
                city: {
                    type: "text",
                    label: "",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "9876543210",
                    validations: { required: true },
                },
                school_address: {
                    type: "text",
                    label: "Physical Address *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "",
                    validations: { required: true },
                },
                zipcode: {
                    type: "text",
                    label: "Physical Address *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "1234",
                    validations: { required: true },
                },

                country_host: {
                    type: "hidden",
                    label: "country_host",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "dikonia@gmail.com",
                    validations: { required: false },
                },

                country_code: {
                    type: "hidden",
                    label: "country_host",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "dikonia@gmail.com",
                    validations: { required: false },
                },

                highest_class: {
                    type: "select",
                    label: "Highest Year *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "City",
                    validations: { required: true },
                    options: [
                        { label: "Highest Year", value: "" },
                        { label: "1st Year", value: 1 },
                        { label: "2nd Year", value: 2 },
                        { label: "3rd Year", value: 3 },
                        { label: "4th Year", value: 4 },
                        { label: "5th Year", value: 5 },
                        { label: "6th Year", value: 6 },
                        { label: "7th Year", value: 7 },
                        { label: "8th Year", value: 8 },
                        { label: "9th Year", value: 9 },
                        { label: "10th Year", value: 10 },
                        { label: "11th Year", value: 11 },
                        { label: "12th Year", value: 12 },
                        { label: "13th Year", value: 13 },
                        { label: "14th Year", value: 14 },
                    ],
                },
                domain_name: {
                    type: "text",
                    label: "Add School Email Domain *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "dikonia@gmail.com",
                    validations: { required: true },
                },

                first_name: {
                    type: "text",
                    label: "First Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "First Name *",
                    validations: { required: true },
                },

                last_name: {
                    type: "text",
                    label: "Last Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Last Name *",
                    validations: { required: true },
                },

                weekend_time_from: {
                    type: "time",
                    label: "Select weekend time from",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,

                    validations: { required: true },
                },

                weekend_time_to: {
                    type: "time",
                    label: "Select weekend time to",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "08:08",
                    validations: { required: true },
                },

                cut_off_time: {
                    type: "time",
                    label: "Select cutoff",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "08:08",
                    validations: { required: true },
                },
                session_start_month: {
                    type: "select",
                    label: "Session Start Month *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "City",
                    validations: { required: true },
                    month_num: "",
                    options: [
                        { label: "Select Session Start Month", value: "" },
                        { label: "January", value: "January", month: 1 },
                        { label: "February", value: "February", month: 2 },
                        { label: "March", value: "March", month: 3 },
                        { label: "April", value: "April", month: 4 },
                        { label: "May", value: "May", month: 5 },
                        { label: "June", value: "June", month: 6 },
                        { label: "July", value: "July", month: 7 },
                        { label: "August", value: "August", month: 8 },
                        { label: "September", value: "September", month: 9 },
                        { label: "October", value: "October", month: 10 },
                        { label: "November", value: "November", month: 11 },
                        { label: "December", value: "December", month: 12 },
                    ],
                },
                session_end_month: {
                    type: "select",
                    label: "Session End Month *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "City",
                    validations: { required: true },
                    month_num: "",
                    options: [
                        { label: "Select Session End Month", value: "" },
                        { label: "January", value: "January", month: 1 },
                        { label: "February", value: "February", month: 2 },
                        { label: "March", value: "March", month: 3 },
                        { label: "April", value: "April", month: 4 },
                        { label: "May", value: "May", month: 5 },
                        { label: "June", value: "June", month: 6 },
                        { label: "July", value: "July", month: 7 },
                        { label: "August", value: "August", month: 8 },
                        { label: "September", value: "September", month: 9 },
                        { label: "October", value: "October", month: 10 },
                        { label: "November", value: "November", month: 11 },
                        { label: "December", value: "December", month: 12 },
                    ],
                },

                session_start_year: {
                    type: "select",
                    label: "Session Start Year *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "City",
                    validations: { required: true },
                    options: [],
                },
                session_end_year: {
                    type: "select",
                    label: "Session End Year *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "City",
                    validations: { required: true },
                    options: [],
                },
                timezone: {
                    type: "select",
                    label: " ",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Time Zone",
                    validations: { required: true },
                    options: [{ label: "Time Zone ", value: "" },],
                },
                school_logo: {
                    label: "File",
                    type: "file",
                    value: '',
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                    thumbnail_image: "/assets/media/school-logo.png",
                },


                other_contact_1_name: {
                    type: "text",
                    label: "Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Last Name *",
                    validations: { required: false },
                },
                other_contact_1_position: {
                    type: "text",
                    label: "Position",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Last Name *",
                    validations: { required: false },
                },
                other_contact_1_email: {
                    type: "email",
                    label: "Email",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Last Name *",
                    validations: { required: false },
                },
                other_contact_1_contact: {
                    type: "tel",
                    label: "Contact",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Last Name *",
                    validations: { required: false },
                },
                other_contact_2_name: {
                    type: "text",
                    label: "Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Last Name *",
                    validations: { required: false },
                },
                other_contact_2_position: {
                    type: "text",
                    label: "Position",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Last Name *",
                    validations: { required: false },
                },
                other_contact_2_email: {
                    type: "email",
                    label: "Email",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Last Name *",
                    validations: { required: false },
                },
                other_contact_2_contact: {
                    type: "tel",
                    label: "Contact",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Last Name *",
                    validations: { required: false },
                },



                avatar: {
                    label: "File",
                    type: "file",
                    value: '',
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                    thumbnail_image: "/assets/media/school-logo.png",
                },
            },
            trial_and_subscription_form: {
                type: {
                    label: "File",
                    type: "file",
                    value: "demo",
                    valid: true,
                    error_msg: "",
                    validations: { required: true },
                },
                start_date: {
                    label: "Start Date *",
                    type: "date",
                    value: null,
                    valid: true,
                    error_msg: "",
                    validations: { required: true },
                },
                end_date: {
                    label: "End Date *",
                    type: "date",
                    value: null,
                    valid: true,
                    error_msg: "",
                    validations: { required: true },
                },
                amount: {
                    label: "Amount *",
                    type: "number",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                duration: {
                    label: "Duration *",
                    type: "Select",
                    value: '',
                    valid: true,
                    options: [
                        { value: 1, label: "1 Week" },
                        { value: 2, label: "2 Weeks" },
                        { value: 3, label: "3 Weeks" },
                        { value: 4, label: "4 Weeks" },
                        { value: 5, label: "5 Weeks" },
                    ],
                    error_msg: "",
                    validations: { required: false },
                },
                year_duration: {
                    label: "Duration *",
                    type: "Select",
                    value: 1,
                    valid: true,
                    options: [
                        { value: 1, label: "1 Year" },
                        { value: 2, label: "2 Years" },
                        { value: 3, label: "3 Years" },
                        { value: 4, label: "4 Years" },
                        { value: 5, label: "5 Years" },
                    ],
                    error_msg: "",
                    validations: { required: false },
                }
            },



        };
        this.toggleShow = this.toggleShow.bind(this);
    }

    toggleShow() {
        this.setState({ hiddenpassword: !this.state.hiddenpassword });
    }

    componentDidMount(prevProps, prevState) {
        this.GetAllSelectedCountries();
        let maxOffset = 10;
        let thisYear = (new Date()).getFullYear();
        let allYears = [
            {
                label: " Select Year",
                value: ""
            }
        ];
        for (let x = 0; x < maxOffset; x++) {
            allYears.push({
                label: thisYear + x,
                value: thisYear + x,
            })
        }
        const update_school_form = { ...this.state.school_form };
        update_school_form['session_start_year'].options = allYears;
        update_school_form['session_end_year'].options = allYears;
    }

    inputChangeHandlerimage = (blob, identifiers) => {
        console.log("blob", identifiers);
        const src = window.URL.createObjectURL(blob);
        let url = URL.createObjectURL(blob);
        let file = new File([blob], "image.png", { lastModified: new Date().getTime(), type: blob.type })
        const update_school_form = { ...this.state.school_form };
        const form_element = { ...update_school_form[identifiers] };
        form_element.thumbnail_image = url;

        form_element.value = file;
        update_school_form[identifiers] = form_element;
        this.setState({ school_form: update_school_form });
        this.setState({ OpenImageModal: !this.state.OpenImageModal })
    };

    setIdentifiers = (value) => {
        this.setState({ OpenImageModal: !this.state.OpenImageModal })
        this.setState({ identifiers: value })
    }

    inputChangeHandler(event, identifier) {
        console.log("ffffffffffffffff", identifier);
        const update_school_form = { ...this.state.school_form };
        const form_element = { ...update_school_form[identifier] };
        if (identifier === 'school_code') {
            let school_code = event.target.value
            const regex = /^[a-zA-Z\s]+$/;
            if (regex.test(school_code)) {
                form_element.value = school_code;
                this.setState({ showerror: false });
            } else {
                if (school_code === "") {
                    form_element.value = school_code;
                    this.setState({ showerror: false });
                } else {
                    this.setState({ showerror: true });
                }
            }
        } else if (identifier === 'contact_one') {
            form_element.value = event;
        } else if (identifier === 'contact_two') {
            form_element.value = event;
        } else if (identifier === 'domain_name') {
            form_element.value = event.target.value.toLowerCase();
        } else if (identifier === "other_contact_1_contact") {
            form_element.value = event;
        } else if (identifier === "other_contact_2_contact") {
            form_element.value = event;
        } else {
            form_element.value = event.target.value;
        }

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_school_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_school_form) {
            is_form_valid = update_school_form[identifier].valid && is_form_valid;
        }
        this.setState({
            school_form: update_school_form,
            is_form_valid: is_form_valid,
        });
    }


    handleChange(data, identifier, type) {
        const update_school_form = { ...this.state.school_form };
        console.log('====================================', data, identifier);
        if (identifier === 'country_id') {
            this.GetAllStatesByCountryId(data.value);
            update_school_form['country'].value = data.label;
            update_school_form['country_id'].value = data.value;
            update_school_form['country_code'].value = data.country_code.toLowerCase();
            update_school_form['country_host'].value = "localhost";
            this.GetAllTimezonesByCountryCode(data.country_code);
        }
        else if (identifier === 'state_id') {
            update_school_form['state'].value = data.label;
            update_school_form['state_id'].value = data.value;
            this.GetAllCitiesByStateId(data.value);
        }
        else if (identifier === 'city_id') {
            update_school_form['city'].value = data.label;
            update_school_form['city_id'].value = data.value;
        }


        else if (identifier === "session_start_month" || identifier === "session_end_month") {
            if (identifier === 'session_start_month') {
                if (update_school_form.session_end_year.value === update_school_form.session_start_year.value) {
                    if (update_school_form.session_end_month.month_num === "" || update_school_form.session_end_month.month_num > data.month) {
                        update_school_form[identifier].value = data.value;
                        update_school_form[identifier].month_num = data.month;
                    } else {
                        toast.error("The end year of a session cannot be earlier than the start year. Please select the correct end year.", {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                } else if (update_school_form.session_end_year.value === "" || update_school_form.session_start_year.value === "" || update_school_form.session_start_year.value < update_school_form.session_end_year.value) {
                    update_school_form[identifier].value = data.value;
                    update_school_form[identifier].month_num = data.month;
                } else {
                    toast.error("The end year of a session cannot be earlier than the start year. Please select the correct end year.", {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            }
            if (identifier === "session_end_month") {
                if (update_school_form.session_end_year.value === update_school_form.session_start_year.value) {
                    if (update_school_form.session_start_month.month_num === "" || update_school_form.session_start_month.month_num < data.month) {
                        update_school_form[identifier].value = data.value;
                        update_school_form[identifier].month_num = data.month;
                    } else {
                        toast.error("The end year of a session cannot be earlier than the start year. Please select the correct end year.", {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                } else if (update_school_form.session_end_year.value === "" || update_school_form.session_start_year.value === "" || update_school_form.session_end_year.value > update_school_form.session_start_year.value) {
                    update_school_form[identifier].value = data.value;
                    update_school_form[identifier].month_num = data.month;
                } else {
                    toast.error("The end year of a session cannot be earlier than the start year. Please select the correct end year.", {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            }
        }


        else if (identifier === 'session_end_year' || identifier === 'session_start_year') {
            if (identifier === 'session_start_year') {
                if (update_school_form.session_end_year.value === "" || update_school_form.session_end_year.value > data.label) {
                    update_school_form[identifier].value = data.label;
                } else if (update_school_form.session_end_year.value === data.label) {
                    if (update_school_form.session_start_month.month_num < update_school_form.session_end_month.month_num) {
                        update_school_form[identifier].value = data.label;
                    } else {
                        toast.error("The end year of a session cannot be earlier than the start year. Please select the correct end year.", {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                } else {
                    update_school_form[identifier].value = "";
                    toast.error("The end year of a session cannot be earlier than the start year. Please select the correct end year.", {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            }
            if (identifier === 'session_end_year') {
                console.log('identifier====================================', identifier, data);
                if (update_school_form.session_start_year.value < data.label) {
                    update_school_form[identifier].value = data.label;
                } else if (update_school_form.session_start_year.value === data.label) {
                    if (update_school_form.session_start_month.month_num < update_school_form.session_end_month.month_num) {
                        update_school_form[identifier].value = data.label;
                    } else {
                        toast.error("The end year of a session cannot be earlier than the start year. Please select the correct end year.", {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                } else {
                    update_school_form[identifier].value = "";
                }
            }
        }
        else {
            update_school_form[identifier].value = data.value;
        }
        this.setState({ school_form: update_school_form });
    }

    GetAllSelectedCountries = async (form_data) => {
        this.props.onGetAllSelectedCountries().then((response) => {
            let country_level = [];
            country_level.push({
                label: "Country",
                value: "",
            });
            const update_school_form = { ...this.state.school_form };
            if (response.success === true) {

                const arrayData = response.data.rows;
                console.log(arrayData);
                for (let key in arrayData) {
                    country_level.push({
                        label: arrayData[key].name,
                        value: arrayData[key].country_id,
                        country_code: arrayData[key].country_code,
                        country_host: arrayData[key].country_host,

                    });
                }
            }
            update_school_form['country_id'].options = country_level;
            this.setState({ school_form: update_school_form });
        });
    }

    GetAllStatesByCountryId = async (CountryID) => {
        this.props.onGetAllStatesByCountryId(CountryID).then((response) => {
            let state_level = [];
            state_level.push({
                label: "State",
                value: "",
            });
            console.log("country=========", response);
            const update_school_form = { ...this.state.school_form };
            if (response.success === true) {

                const arrayData = response.data.rows;

                for (let key in arrayData) {
                    if (arrayData[key].is_activate === true) {
                        state_level.push({
                            label: arrayData[key].state_name,
                            value: arrayData[key].state_id,
                        });
                    }

                }
            }
            update_school_form['state_id'].options = state_level;
            this.setState({ school_form: update_school_form });
        });
    }

    GetAllCitiesByStateId = async (state_id) => {
        this.props.onGetAllCitiesByStateId(state_id).then((response) => {
            let City_level = [];
            City_level.push({
                label: "City",
                value: "",
            });
            const update_school_form = { ...this.state.school_form };
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    if (arrayData[key].is_activate === true) {
                        City_level.push({
                            label: arrayData[key].city_name,
                            value: arrayData[key].city_id,
                        });
                    }
                }
            }
            update_school_form['city_id'].options = City_level;
            update_school_form['city_id'].value = City_level.length === 1 ? update_school_form['state_id'].value : "";
            const city_name = update_school_form['state_id'].options.find((city) => {
                return city.value === update_school_form['state_id'].value
            });
            update_school_form['city'].value = city_name.label

            update_school_form['city_id'].cityName = city_name.label


            this.setState({ school_form: update_school_form });
        });
    }
    GetAllTimezonesByCountryCode = async (country_code) => {
        userActions.GetAllTimezonesByCountryCode(country_code).then((response) => {
            let state_level = [];
            state_level.push({
                label: "Time Zone",
                value: "",
            });
            const update_school_form = { ...this.state.school_form };
            if (response.success === true) {
                const arrayData = response.data;

                for (let key in arrayData) {
                    state_level.push({
                        label: arrayData[key].timezone,
                        value: arrayData[key].timezone,
                    });
                }
            }
            update_school_form['timezone'].options = state_level;
            this.setState({ school_form: update_school_form });
        });
    }

    profilePictureHandler = (event, identifier) => {
        if (identifier === "shadow_profile") {
            this.setState({ display_shadow_picture: true })
            this.setState({ display_profile_picture: false })
        } else {
            this.setState({ display_shadow_picture: false })
            this.setState({ display_profile_picture: true })
        }
    }

    toggleDuration = (event, identifier) => {
        const update_trial_and_subscription_form = { ...this.state.trial_and_subscription_form };

        if (identifier === "freetrial") {
            update_trial_and_subscription_form['type'].value = "demo";
            update_trial_and_subscription_form['amount'].validations['required'] = false;
            this.resetFormFields(update_trial_and_subscription_form);
            
            const startDate = new Date();
            const weeks = 1; // default value
            update_trial_and_subscription_form['duration'].value = weeks;
            const newEndDate = new Date(startDate);
            newEndDate.setDate(newEndDate.getDate() + (weeks * 7));
            update_trial_and_subscription_form['end_date'].value = newEndDate;

            this.setState({ 
                trial_and_subscription_form: update_trial_and_subscription_form, 
                openStartTrialDiv: true, 
                openStartSubscriptionDiv: false 
            });
        } else {
            update_trial_and_subscription_form['type'].value = "subscription";
            update_trial_and_subscription_form['amount'].validations['required'] = true;
            this.resetFormFields(update_trial_and_subscription_form);

            const startDate = new Date();
            const years = 1; // default value
            update_trial_and_subscription_form['year_duration'].value = years;
            const newEndDate = new Date(startDate);
            newEndDate.setFullYear(newEndDate.getFullYear() + years);
            update_trial_and_subscription_form['end_date'].value = newEndDate;

            this.setState({ 
                trial_and_subscription_form: update_trial_and_subscription_form, 
                openStartSubscriptionDiv: true, 
                openStartTrialDiv: false 
            });
        }
    }

    resetFormFields = (form) => {
        form['start_date'].value = new Date();
        form['end_date'].value = null;
        // form['amount'].value = "";
        form['duration'].value = '';
        form['year_duration'].value = 1;
    }
    
    
 
    SubmitHandler = async (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_school_form = this.state.school_form;
        update_school_form.email.value = update_school_form.email.value.toLowerCase();
        for (let key in update_school_form) {
            let form_element = update_school_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_school_form[key] = form_element;
        }
        this.setState({ school_form: update_school_form });
        const update_trial_and_subscription_form = this.state.trial_and_subscription_form;
        for (let key in update_trial_and_subscription_form) {
            let form_element = update_trial_and_subscription_form[key];
            let validate_element = Validations(form_element);
            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;
            update_trial_and_subscription_form[key] = form_element;
        }
        this.setState({ trial_and_subscription_form: update_trial_and_subscription_form })
        console.log("========================", this.state.trial_and_subscription_form);
        console.log("========================", this.state.school_form);
        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.school_form) {
                if (key === "domain_name") {
                    form_data[key] = "@" + this.state.school_form[key].value;
                } else {
                    form_data[key] = this.state.school_form[key].value;
                }
            }
            form_data['type'] = this.state.trial_and_subscription_form.type.value;
            form_data['start_date'] = moment(this.state.trial_and_subscription_form.start_date.value).format("YYYY-MM-DD");
            form_data['end_date'] = moment(this.state.trial_and_subscription_form.end_date.value).format("YYYY-MM-DD");
            form_data['amount'] = this.state.trial_and_subscription_form.amount.value === "" ? "" : parseInt(this.state.trial_and_subscription_form.amount.value);

            if (form_data.email.slice(form_data.email.lastIndexOf("@"), form_data.email.length) === form_data.domain_name) {
                // if (form_data.session_start_year < form_data.session_end_year) {
                    this.props.onCreateSchool(form_data).then((response) => {
                        if (response.success === true) {
                            toast.success(response.message, {
                                position: toast.POSITION.TOP_CENTER,
                            });
                            setTimeout(() => {
                                this.setState({
                                    isAuthenticated: true,
                                    redirect_url: "/superadmin/school",
                                });
                            }, 500);
                        } else {
                            toast.error(response.message, {
                                position: toast.POSITION.TOP_CENTER,
                            });
                        }

                    });
                // } else {
                //     toast.error("Please select correct Session end date and Session Start date", {
                //         position: toast.POSITION.TOP_CENTER,
                //     });
                // }

            } else {
                toast.error("School domain name and Email domain are not same", {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        }
    };

    // openStartTrial = () => {
    //     const update_trial_and_subscription_form = this.state.trial_and_subscription_form;
    //     update_trial_and_subscription_form['type'].value = "demo";
    //     update_trial_and_subscription_form['amount'].validations['required'] = false;
    //     this.setState({ trial_and_subscription_form: update_trial_and_subscription_form })
    //     this.setState({ openStartTrialDiv: true })
    //     this.setState({ openStartSubscriptionDiv: false })

    // }
    // openStartSubscription = () => {
    //     const update_trial_and_subscription_form = this.state.trial_and_subscription_form;
    //     update_trial_and_subscription_form['type'].value = "subscription";
    //     update_trial_and_subscription_form['amount'].validations['required'] = true;
    //     this.setState({ trial_and_subscription_form: update_trial_and_subscription_form })
    //     this.setState({ openStartSubscriptionDiv: true })
    //     this.setState({ openStartTrialDiv: false })
    // }
    durationhandler = (e, identity) => {
        const update_trial_and_subscription_form = { ...this.state.trial_and_subscription_form };
        const startDate = new Date(); // Ensure start date is set to today's date if not provided
        if (identity === "set_year") {
            update_trial_and_subscription_form['year_duration'].value = e.value;
            const years = e.value;
            const newEndDate = new Date(startDate);
            newEndDate.setFullYear(newEndDate.getFullYear() + years);
            update_trial_and_subscription_form['end_date'].value = newEndDate;
        } else {
            update_trial_and_subscription_form['duration'].value = e.value;
            const weeks = e.value;
            const newEndDate = new Date(startDate);
            newEndDate.setDate(newEndDate.getDate() + (weeks * 7));
            update_trial_and_subscription_form['end_date'].value = newEndDate;
        }
        update_trial_and_subscription_form['start_date'].value = startDate; // Ensure start date is updated in the form

        this.setState({ trial_and_subscription_form: update_trial_and_subscription_form });
    }

    dateHandler = (date, identity) => {
        console.log(date,"::dfd")
        const update_trial_and_subscription_form = { ...this.state.trial_and_subscription_form };
        
        if (identity === "trial" || identity === "subscription") {
            update_trial_and_subscription_form['start_date'].value = date;
            this.setState({ start_current_date: date });
            const weeks = update_trial_and_subscription_form['duration'].value;
            const newEndDate = new Date(date.getTime() + (weeks * 7 * 24 * 60 * 60 * 1000));
            update_trial_and_subscription_form['end_date'].value = newEndDate;
        } else if (identity === "amount") {
            update_trial_and_subscription_form['amount'].value = date?.target?.value;
        } else {
            const years = update_trial_and_subscription_form['year_duration'].value;
            const newEndDate = new Date(date);
            newEndDate.setFullYear(newEndDate.getFullYear() + years);
            update_trial_and_subscription_form['end_date'].value = newEndDate;
        }

        this.setState({ trial_and_subscription_form: update_trial_and_subscription_form });
    }
    

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyPress);
    }

    handleKeyPress = (event) => {
        if (event.keyCode === 27) {
            this.setState({ OpenImageModal: false })
        }
    };


    render() {
        const { school_form } = this.state;
        const { trial_and_subscription_form } = this.state;
        const { year_duration } = this.state;


        return (
            <React.Fragment>
                {this.state.OpenImageModal &&
                    <div className="upload-image-outer-div" >
                        <span style={{ float: "right" }} className="pointer m-5" onClick={() => this.setState({ OpenImageModal: !this.state.OpenImageModal })}>
                            <i class="fas fa-times fa-2x" style={{ color: "#ffffff", }}></i>
                        </span>
                       
                        <div className="upload-image-inner-div">
                            <AvatarImageCropper
                                rootStyle={{ borderRadius: "50%" }}
                                cancelBtnStyle={{ display: "none" }}
                                apply={(blob) => this.inputChangeHandlerimage(blob, this.state.identifiers)}
                                className={"image_crop_style"}
                                sliderConStyle={{ margin: "20px", backgroundColor: "none" }}
                                applyBtnStyle={{}}
                                isBack={true} />
                        </div>
                        <div className="text-center mt-10">
                            <span className="">The image maximum size is 2MB. Please upload png/jpg/jpeg image</span>
                        </div>
                    </div>
                }
                {this.state.isAuthenticated ? <Navigate to={this.state.redirect_url} /> : null}
                <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed" >
                    <div className="d-flex flex-column flex-root">
                        <div className="page d-flex flex-row flex-column-fluid">
                            < SuperAdminSideMenu />
                            <div className="wrapper d-flex flex-column flex-row-fluid " id="kt_wrapper">
                                <SuperAdminTopbar></SuperAdminTopbar>
                                <div className=" d-flex flex-column flex-row-fluid" id="kt_wrapper">
                                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                        <div className="post d-flex flex-column-fluid" id="kt_post">
                                            <div id="kt_content_container" className="container-fluid">
                                                <div className="school-edit-form ">
                                                    <div className="" id="Snapshot">
                                                        <form onSubmit={this.SubmitHandler}>
                                                            <h4 className="card-title text-gray-800 fw-bolder m-0 mb-5">Add School</h4>
                                                            <div className="card mb-5 tabs-outer">
                                                                <div className="card-header position-relative">
                                                                    <h5 className="card-title text-gray-800 fw-bolder m-0">School Detail</h5>
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-lg-6 fv-row mb-5">
                                                                            <div className="row">
                                                                                <div className="col-sm-8">
                                                                                    <h4 className="fw-bold mb-2 ">School Logo</h4>
                                                                                    <div className="profile-image-out p-0">
                                                                                        <img alt="profile" src={school_form.school_logo.thumbnail_image} className="img-fluid" />
                                                                               
                                                                                        <div className="d-flex justify-content-center h-100 flex-column align-items-center">
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-sm btn-outline-primary px-6 rounded-50 "
                                                                                                onClick={() => this.setIdentifiers("school_logo")}
                                                                                            >
                                                                                                Upload Logo
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-lg-6">
                                                                            <div className="row">
                                                                                <div className="col-lg-6 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{school_form.school_name.label} </label>

                                                                                    <input
                                                                                        type={school_form.school_name.type}
                                                                                        value={school_form.school_name.value ? school_form.school_name.value : ""}
                                                                                        className="form-control"
                                                                                        placeholder={school_form.school_name.placeholder}
                                                                                        onChange={(event) => this.inputChangeHandler(event, "school_name")}
                                                                                    />

                                                                                    {!school_form.school_name.valid && school_form.school_name.onBlur_out ? <div className="error field-error">{school_form.school_name.error_msg}</div> : ""}
                                                                                </div>

                                                                                <div className="col-lg-6 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{school_form.school_code.label}</label>
                                                                                    <input
                                                                                        type={school_form.school_code.type}
                                                                                        value={school_form.school_code.value ? school_form.school_code.value : ""}
                                                                                        className="form-control"
                                                                                        placeholder={school_form.school_code.placeholder}
                                                                                        onChange={(event) => this.inputChangeHandler(event, "school_code")}
                                                                                    />

                                                                                    {!school_form.school_code.valid && school_form.school_code.onBlur_out ? <div className="error field-error">{school_form.school_code.error_msg}</div> : ""}
                                                                                    {this.state.showerror && <div className="text-danger">The school code can only contain Alphabet characters.</div>}

                                                                                </div>

                                                                                <div className="col-lg-6 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">Zip Code *</label>
                                                                                    <input
                                                                                        type={school_form.zipcode.type}
                                                                                        value={school_form.zipcode.value ? school_form.zipcode.value : ""}
                                                                                        className="form-control"
                                                                                        placeholder={school_form.zipcode.placeholder}
                                                                                        onChange={(event) => this.inputChangeHandler(event, "zipcode")}
                                                                                    />

                                                                                    {!school_form.zipcode.valid && school_form.zipcode.onBlur_out ? <div className="error field-error">{school_form.zipcode.error_msg}</div> : ""}
                                                                                </div>
                                                                                {console.log('school_form.country_id============================', school_form.country_id)}

                                                                                <div className="col-lg-6 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{school_form.country_id.label}</label>
                                                                                    <Select
                                                                                        styles={customStyles}
                                                                                        className="react-bootstrap-typeahead tag-outer"
                                                                                        options={school_form.country_id.options}
                                                                                        value={school_form.country_id.options.filter(function (option) {
                                                                                            return option.value === school_form.country_id.value;
                                                                                        })}
                                                                                        onChange={(newValue) => this.handleChange(newValue, "country_id", "weekend_day from value")}
                                                                                    />

                                                                                    {!school_form.country_id.valid && school_form.country_id.onBlur_out ? <div className="error field-error">{school_form.country.error_msg}</div> : ""}
                                                                                </div>

                                                                                <div className="col-lg-6 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{school_form.state_id.label}</label>
                                                                                    <Select
                                                                                        styles={customStyles}
                                                                                        className="react-bootstrap-typeahead tag-outer"
                                                                                        options={school_form.state_id.options}
                                                                                        value={school_form.state_id.options.filter(function (option) {
                                                                                            return option.value === school_form.state_id.value;
                                                                                        })}
                                                                                        onChange={(newValue) => this.handleChange(newValue, "state_id", "weekend_day from value")}
                                                                                    />

                                                                                    {!school_form.state_id.valid && school_form.state_id.onBlur_out ? <div className="error field-error">{school_form.state.error_msg}</div> : ""}
                                                                                </div>

                                                                                <div className="col-lg-6 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{school_form.city_id.label}</label>
                                                                                    {
                                                                                        school_form.city_id.options.length > 1 ?
                                                                                            <Select
                                                                                                styles={customStyles}
                                                                                                className="react-bootstrap-typeahead tag-outer"
                                                                                                options={school_form.city_id.options}
                                                                                                value={school_form.city_id.options.filter(function (option) {
                                                                                                    return option.value === school_form.city_id.value;
                                                                                                })}
                                                                                                onChange={(newValue) => this.handleChange(newValue, "city_id", "weekend_day from value")}
                                                                                            />
                                                                                            :
                                                                                            <input
                                                                                                type="text"
                                                                                                value={school_form.city_id.cityName}
                                                                                                className="form-control"
                                                                                                readOnly
                                                                                            />
                                                                                    }

                                                                                    {!school_form.city_id.valid && school_form.city_id.onBlur_out ? <div className="error field-error">{school_form.city.error_msg}</div> : ""}
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-lg-3 fv-row mb-5">
                                                                            <label className="fw-bold mb-2">Physical Address *</label>
                                                                            <input
                                                                                type={school_form.school_address.type}
                                                                                value={school_form.school_address.value ? school_form.school_address.value : ""}
                                                                                className="form-control"
                                                                                placeholder={school_form.school_address.placeholder}
                                                                                onChange={(event) => this.inputChangeHandler(event, "school_address")}
                                                                            />
                                                                            {!school_form.school_address.valid && school_form.school_address.onBlur_out ? (
                                                                                <div className="error field-error">{school_form.school_address.error_msg}</div>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </div>
                                                                        <div className="col-lg-3 fv-row mb-5">
                                                                            <label className="fw-bold mb-2">Time Zone *
                                                                            </label>
                                                                            <Select
                                                                                styles={customStyles}
                                                                                className="react-bootstrap-typeahead tag-outer"
                                                                                options={school_form.timezone.options}
                                                                                value={school_form.timezone.options.filter(function (option) {
                                                                                    return option.value === school_form.timezone.value;
                                                                                })}
                                                                                onChange={(newValue) => this.handleChange(newValue, "timezone", "weekend_day from value")}
                                                                            />
                                                                            {!school_form.timezone.valid && school_form.timezone.onBlur_out ? <div className="error field-error">{school_form.city.error_msg}</div> : ""}
                                                                        </div>
                                                                        <div className="col-lg-3 fv-row mb-5">
                                                                            <label className="fw-bold mb-2">{school_form.highest_class.label}</label>
                                                                            <Select
                                                                                styles={customStyles}
                                                                                className="react-bootstrap-typeahead tag-outer"
                                                                                options={school_form.highest_class.options}
                                                                                value={school_form.highest_class.options.filter(function (option) {
                                                                                    return option.value === school_form.highest_class.value;
                                                                                })}
                                                                                onChange={(newValue) => this.handleChange(newValue, "highest_class")}
                                                                            />

                                                                            {!school_form.highest_class.valid && school_form.highest_class.onBlur_out ? <div className="error field-error">{school_form.highest_class.error_msg}</div> : ""}
                                                                        </div>
                                                                        <div className="col-lg-3 fv-row mb-5">
                                                                            <label className="fw-bold mb-2">{school_form.domain_name.label}</label>
                                                                            <Tooltip title="An example is   @theharrowschool.org.uk" placement="top">
                                                                                <i className="fas fa-question-circle ms-2"></i>
                                                                            </Tooltip>
                                                                            <div className="position-relative">
                                                                                <p className="position-absolute mt-2 ms-3">@</p>
                                                                                <input
                                                                                    type={school_form.domain_name.type}
                                                                                    styles={customStyles}
                                                                                    className="form-control ps-7"
                                                                                    value={school_form.domain_name.value ? school_form.domain_name.value : ""}
                                                                                    onChange={(newValue) => this.inputChangeHandler(newValue, "domain_name")}
                                                                                />
                                                                            </div>
                                                                            {!school_form.domain_name.valid && school_form.domain_name.onBlur_out ? <div className="error field-error">{school_form.domain_name.error_msg}</div> : ""}
                                                                        </div>
                                                                    </div>


                                                                    <hr className="mb-8  mt-3"></hr>
                                                                    <div className="row">
                                                                        <h6 className="mb-5">Other School Contact 1</h6>

                                                                        <div className="col-lg-3 fv-row mb-5">
                                                                            <label className="fw-bold mb-2">{school_form.other_contact_1_name.label}</label>
                                                                            <input
                                                                                type={school_form.other_contact_1_name.type}
                                                                                styles={customStyles}
                                                                                className="form-control ps-7"
                                                                                value={school_form.other_contact_1_name.value ? school_form.other_contact_1_name.value : ""}
                                                                                onChange={(newValue) => this.inputChangeHandler(newValue, "other_contact_1_name")}
                                                                            />
                                                                        </div>
                                                                        <div className="col-lg-3 fv-row mb-5">
                                                                            <label className="fw-bold mb-2">{school_form.other_contact_1_position.label}</label>
                                                                            <input
                                                                                type={school_form.other_contact_1_position.type}
                                                                                styles={customStyles}
                                                                                className="form-control ps-7"
                                                                                value={school_form.other_contact_1_position.value ? school_form.other_contact_1_position.value : ""}
                                                                                onChange={(newValue) => this.inputChangeHandler(newValue, "other_contact_1_position")}
                                                                            />
                                                                        </div>
                                                                        <div className="col-lg-3 fv-row mb-5">
                                                                            <label className="fw-bold mb-2">{school_form.other_contact_1_contact.label}</label>
                                                                            <ReactPhoneInput
                                                                                countryCodeEditable={false}
                                                                                country={school_form.country_code.value ? school_form.country_code.value : ""}
                                                                                value={school_form.other_contact_1_contact.value ? school_form.other_contact_1_contact.value : ""}
                                                                                onChange={(event) => this.inputChangeHandler(event, "other_contact_1_contact")}
                                                                                enableLongNumbers={true}
                                                                            />
                                                                        </div>
                                                                        <div className="col-lg-3 fv-row mb-5">
                                                                            <label className="fw-bold mb-2">{school_form.other_contact_1_email.label}</label>
                                                                            <input
                                                                                type={school_form.other_contact_1_email.type}
                                                                                styles={customStyles}
                                                                                className="form-control ps-7"
                                                                                value={school_form.other_contact_1_email.value ? school_form.other_contact_1_email.value : ""}
                                                                                onChange={(newValue) => this.inputChangeHandler(newValue, "other_contact_1_email")}
                                                                            />
                                                                        </div>
                                                                    </div>



                                                                    <div className="row">
                                                                        <h6 className="mb-5">Other School Contact 2</h6>
                                                                        <div className="col-lg-3 fv-row mb-5">
                                                                            <label className="fw-bold mb-2">{school_form.other_contact_2_name.label}</label>
                                                                            <input
                                                                                type={school_form.other_contact_2_name.type}
                                                                                styles={customStyles}
                                                                                className="form-control ps-7"
                                                                                value={school_form.other_contact_2_name.value ? school_form.other_contact_2_name.value : ""}
                                                                                onChange={(newValue) => this.inputChangeHandler(newValue, "other_contact_2_name")}
                                                                            />
                                                                        </div>
                                                                        <div className="col-lg-3 fv-row mb-5">
                                                                            <label className="fw-bold mb-2">{school_form.other_contact_2_position.label}</label>
                                                                            <input
                                                                                type={school_form.other_contact_2_position.type}
                                                                                styles={customStyles}
                                                                                className="form-control ps-7"
                                                                                value={school_form.other_contact_2_position.value ? school_form.other_contact_2_position.value : ""}
                                                                                onChange={(newValue) => this.inputChangeHandler(newValue, "other_contact_2_position")}
                                                                            />
                                                                        </div>
                                                                        <div className="col-lg-3 fv-row mb-5">
                                                                            <label className="fw-bold mb-2">{school_form.other_contact_2_contact.label}</label>
                                                                            <ReactPhoneInput
                                                                                countryCodeEditable={false}
                                                                                country={school_form.country_code.value ? school_form.country_code.value : ""}
                                                                                value={school_form.other_contact_2_contact.value ? school_form.other_contact_2_contact.value : ""}
                                                                                onChange={(event) => this.inputChangeHandler(event, "other_contact_2_contact")}
                                                                                enableLongNumbers={true}
                                                                            />
                                                                        </div>
                                                                        <div className="col-lg-3 fv-row mb-5">
                                                                            <label className="fw-bold mb-2">{school_form.other_contact_2_email.label}</label>
                                                                            <input
                                                                                type={school_form.other_contact_2_email.type}
                                                                                styles={customStyles}
                                                                                className="form-control ps-7"
                                                                                value={school_form.other_contact_2_email.value ? school_form.other_contact_2_email.value : ""}
                                                                                onChange={(newValue) => this.inputChangeHandler(newValue, "other_contact_2_email")}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                            </div>


                                                            <div className="card mb-5 tabs-outer">
                                                                <div className="card-header position-relative details-bg">
                                                                    <h3 className="card-title text-gray-800 fw-bolder m-0">Weekend Details</h3>
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-lg-3">
                                                                            <label className="fw-bold mb-2 d-flex">{school_form.weekend_day_from.label}
                                                                                <Tooltip title="Define “Weekend Start” & “Weekend Finish” on this School Leave system" placement="top">
                                                                                    <i className="fas fa-question-circle ms-2"></i>
                                                                                </Tooltip>
                                                                            </label>
                                                                            <Select
                                                                                styles={customStyles}
                                                                                name={"weekend_day_from"}
                                                                                className="react-bootstrap-typeahead tag-outer "
                                                                                options={school_form.weekend_day_from.options}
                                                                                value={school_form.weekend_day_from.options.filter(function (option) {
                                                                                    return option.value === school_form.weekend_day_from.value;
                                                                                })}
                                                                                onChange={(newValue) => this.handleChange(newValue, "weekend_day_from", "weekend_day from value")}
                                                                            />

                                                                            {!school_form.weekend_day_from.valid && school_form.weekend_day_from.onBlur_out ? <div className="error field-error">{school_form.weekend_day_from.error_msg}</div> : ""}
                                                                        </div>

                                                                        <div className="col-lg-3 fv-row mb-5">
                                                                            <label className="fw-bold mb-2">Weekend Start Time  *</label>
                                                                            <input type="time" name="fname" className="form-control time-input" placeholder="13:30" onChange={(event) => this.inputChangeHandler(event, "weekend_time_from")} />
                                                                            {!school_form.weekend_time_from.valid && school_form.weekend_time_from.onBlur_out ? <div className="error field-error">{school_form.weekend_time_from.error_msg}</div> : ""}
                                                                        </div>

                                                                        <div className="col-lg-3 fv-row mb-5">
                                                                            <label className="fw-bold mb-2">{school_form.weekend_day_to.label}</label>
                                                                            <Select
                                                                                styles={customStyles}
                                                                                className="react-bootstrap-typeahead tag-outer"
                                                                                options={school_form.weekend_day_to.options}
                                                                                value={school_form.weekend_day_to.options.filter(function (option) {
                                                                                    return option.value === school_form.weekend_day_to.value;
                                                                                })}
                                                                                onChange={(newValue) => this.handleChange(newValue, "weekend_day_to", "weekend_day to value")}
                                                                            />

                                                                            {!school_form.weekend_day_to.valid && school_form.weekend_day_to.onBlur_out ? <div className="error field-error">{school_form.weekend_day_to.error_msg}</div> : ""}
                                                                        </div>

                                                                        <div className="col-lg-3 fv-row mb-5">
                                                                            <label className="fw-bold mb-2">Weekend Finish Time *</label>
                                                                            <input type="time" name="fname" className="form-control time-input" placeholder="13:30" onChange={(event) => this.inputChangeHandler(event, "weekend_time_to")} />
                                                                            {!school_form.weekend_time_to.valid && school_form.weekend_time_to.onBlur_out ? <div className="error field-error">{school_form.weekend_time_to.error_msg}</div> : ""}
                                                                        </div>

                                                                        <div className="col-lg-6 fv-row mb-5">

                                                                            <label className="fw-bold mb-2 d-flex">{school_form.cut_off_day.label}
                                                                                <Tooltip title="For Boarders to submit their Leave Applications" placement="top">
                                                                                    <i className="fas fa-question-circle ms-2"></i>
                                                                                </Tooltip>
                                                                            </label>
                                                                            <Select
                                                                                styles={customStyles}
                                                                                className="react-bootstrap-typeahead tag-outer"
                                                                                options={school_form.cut_off_day.options}
                                                                                value={school_form.cut_off_day.options.filter(function (option) {
                                                                                    return option.value === school_form.cut_off_day.value;
                                                                                })}
                                                                                onChange={(newValue) => this.handleChange(newValue, "cut_off_day", "weekend_day to value")}
                                                                            />

                                                                            {!school_form.cut_off_day.valid && school_form.cut_off_day.onBlur_out ? <div className="error field-error">{school_form.cut_off_day.error_msg}</div> : ""}
                                                                        </div>

                                                                        <div className="col-lg-6 fv-row mb-5">
                                                                            <label className="fw-bold mb-2 d-flex">Cut off time *

                                                                                <Tooltip title="For Boarders to submit their Leave Applications" placement="top">
                                                                                    <i className="fas fa-question-circle ms-2"></i>
                                                                                </Tooltip>
                                                                            </label>

                                                                            <input type="time" name="fname" className="form-control time-input" placeholder="13:30" onChange={(event) => this.inputChangeHandler(event, "cut_off_time")} />
                                                                            {!school_form.cut_off_time.valid && school_form.cut_off_time.onBlur_out ? <div className="error field-error">{school_form.cut_off_time.error_msg}</div> : ""}
                                                                        </div>
                                                                    </div>
                                                                    <hr className="mb-8  mt-3"></hr>
                                                                    <div className="row">
                                                                        <div className="col-lg-6 fv-row mb-5">
                                                                            <label className="fw-bold mb-2">{school_form.session_start_month.label}</label>
                                                                            <Select
                                                                                styles={customStyles}
                                                                                className="react-bootstrap-typeahead tag-outer"
                                                                                options={school_form.session_start_month.options}
                                                                                value={school_form.session_start_month.options.filter(function (option) {
                                                                                    return option.value === school_form.session_start_month.value;
                                                                                })}
                                                                                onChange={(newValue) => this.handleChange(newValue, "session_start_month")}
                                                                            />

                                                                            {!school_form.session_start_month.valid && school_form.session_start_month.onBlur_out ? <div className="error field-error">{school_form.session_start_month.error_msg}</div> : ""}
                                                                        </div>
                                                                        <div className="col-lg-6 fv-row mb-5">
                                                                            <label className="fw-bold mb-2">{school_form.session_start_year.label}</label>
                                                                            <Select
                                                                                styles={customStyles}
                                                                                className="react-bootstrap-typeahead tag-outer"
                                                                                options={school_form.session_start_year.options}
                                                                                value={school_form.session_start_year.options.filter(function (option) {
                                                                                    return option.value === school_form.session_start_year.value;
                                                                                })}
                                                                                onChange={(newValue) => this.handleChange(newValue, "session_start_year")}
                                                                            />

                                                                            {!school_form.session_start_year.valid && school_form.session_start_year.onBlur_out ? <div className="error field-error">{school_form.session_start_year.error_msg}</div> : ""}
                                                                        </div>
                                                                        <div className="col-lg-6 fv-row mb-5">
                                                                            <label className="fw-bold mb-2">{school_form.session_end_month.label}</label>
                                                                            <Select
                                                                                styles={customStyles}
                                                                                className="react-bootstrap-typeahead tag-outer"
                                                                                options={school_form.session_end_month.options}
                                                                                value={school_form.session_end_month.options.filter(function (option) {
                                                                                    return option.value === school_form.session_end_month.value;
                                                                                })}
                                                                                onChange={(newValue) => this.handleChange(newValue, "session_end_month")}
                                                                            />

                                                                            {!school_form.session_end_month.valid && school_form.session_end_month.onBlur_out ? <div className="error field-error">{school_form.session_end_month.error_msg}</div> : ""}
                                                                        </div>
                                                                        <div className="col-lg-6 fv-row mb-5">
                                                                            <label className="fw-bold mb-2">{school_form.session_end_year.label}</label>
                                                                            <Select
                                                                                styles={customStyles}
                                                                                className="react-bootstrap-typeahead tag-outer"
                                                                                options={school_form.session_end_year.options}
                                                                                value={school_form.session_end_year.options.filter(function (option) {
                                                                                    return option.value === school_form.session_end_year.value;
                                                                                })}
                                                                                onChange={(newValue) => this.handleChange(newValue, "session_end_year")}
                                                                            />

                                                                            {!school_form.session_end_year.valid && school_form.session_end_year.onBlur_out ? <div className="error field-error">{school_form.session_end_year.error_msg}</div> : ""}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>




                                                            <div className="card mb-5 tabs-outer">
                                                                <div className="card-header position-relative details-bg">
                                                                    <h3 className="card-title text-gray-800 fw-bolder m-0">School Admin Details</h3>
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-lg-6 fv-row">
                                                                            <h4 className="fw-bold mb-2">School Admin Image
                                                                                <Tooltip title="Note: When adding an image or “Choose file” .jpg files tend to work best. Also to ‘fit’ the image, it is best to work with a square image, or at least the background should be square." placement="top">
                                                                                    <i className="fas fa-question-circle ms-2"></i>
                                                                                </Tooltip>
                                                                            </h4>

                                                                            <div className="row">
                                                                                <div className="col-sm-12 mb-5">
                                                                                    <div className="profile-image-out p-0">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-8">
                                                                                                <img alt="profile" src={school_form.avatar.thumbnail_image} className="img-fluid" />
                                                                                     
                                                                                                <div className=" h-100 flex-column align-items-center">
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="btn btn-sm btn-outline-primary px-6 rounded-50 "
                                                                                                        onClick={() => this.setIdentifiers("avatar")}
                                                                                                    >
                                                                                                        Upload Image
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-lg-6">
                                                                            <div className="row">
                                                                                <div className="col-lg-6 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{school_form.first_name.label} </label>
                                                                                    <input
                                                                                        type={school_form.first_name.type}
                                                                                        value={school_form.first_name.value ? school_form.first_name.value : ""}
                                                                                        className="form-control"
                                                                                        placeholder={school_form.first_name.placeholder}
                                                                                        onChange={(event) => this.inputChangeHandler(event, "first_name")}
                                                                                    />
                                                                                    {!school_form.first_name.valid && school_form.first_name.onBlur_out ? <div className="error field-error">{school_form.first_name.error_msg}</div> : ""}
                                                                                </div>

                                                                                <div className="col-lg-6 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{school_form.last_name.label} </label>
                                                                                    <input
                                                                                        type={school_form.last_name.type}
                                                                                        value={school_form.last_name.value ? school_form.last_name.value : ""}
                                                                                        className="form-control"
                                                                                        placeholder={school_form.last_name.placeholder}
                                                                                        onChange={(event) => this.inputChangeHandler(event, "last_name")}
                                                                                    />
                                                                                    {!school_form.last_name.valid && school_form.last_name.onBlur_out ? <div className="error field-error">{school_form.last_name.error_msg}</div> : ""}
                                                                                </div>

                                                                                <div className="col-lg-6 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{school_form.contact_one.label}</label>
                                                                                    <ReactPhoneInput
                                                                                        countryCodeEditable={false}
                                                                                        country={school_form.country_code.value ? school_form.country_code.value : ""}
                                                                                        value={school_form.contact_one.value ? school_form.contact_one.value : ""}
                                                                                        onChange={(event) => this.inputChangeHandler(event, "contact_one")}
                                                                                        //  onChange={this.handleOnChange}
                                                                                        enableLongNumbers={true}
                                                                                    />
                                                                                    {!school_form.contact_one.valid && school_form.contact_one.onBlur_out ? <div className="error field-error">{school_form.contact_one.error_msg}</div> : ""}
                                                                                </div>

                                                                                <div className="col-lg-6 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{school_form.contact_two.label}</label>
                                                                                    <ReactPhoneInput
                                                                                        countryCodeEditable={false}
                                                                                        country={school_form.country_code.value ? school_form.country_code.value : ""}
                                                                                        value={school_form.contact_two.value ? school_form.contact_two.value : ""}
                                                                                        onChange={(event) => this.inputChangeHandler(event, "contact_two")}
                                                                                        //  onChange={this.handleOnChange}
                                                                                        enableLongNumbers={true}
                                                                                    />
                                                                                </div>

                                                                                <div className="col-xl-12 col-xxl-6 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">Primary Email * (Will be used for Admin Login)</label>
                                                                                    <input
                                                                                        type={school_form.email.type}
                                                                                        value={school_form.email.value ? school_form.email.value : ""}
                                                                                        className="form-control"
                                                                                        placeholder={school_form.email.placeholder}
                                                                                        onChange={(event) => this.inputChangeHandler(event, "email")}
                                                                                    />
                                                                                    {!school_form.email.valid && school_form.email.onBlur_out ? <div className="error field-error">{school_form.email.error_msg}</div> : ""}
                                                                                </div>

                                                                                <div className="col-xl-12 col-xxl-6 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">Assign Password *</label>
                                                                                    <div className="position-relative">
                                                                                        <input
                                                                                            type={this.state.hiddenpassword ? 'password' : 'text'}
                                                                                            value={school_form.password.value ? school_form.password.value : ""}
                                                                                            className="form-control"
                                                                                            placeholder={school_form.password.placeholder}
                                                                                            onChange={(event) => this.inputChangeHandler(event, "password")}
                                                                                        />
                                                                                        <span onClick={this.toggleShow} className="btn btn-icon position-absolute translate-middle top-50 end-0" data-kt-password-meter-control="visibility">
                                                                                            {this.state.hiddenpassword ? <i className="bi bi-eye-slash fs-2"></i> : <i className="bi bi-eye fs-2"></i>}
                                                                                        </span>
                                                                                    </div>
                                                                                    {!school_form.password.valid && school_form.password.onBlur_out ? <div className="error field-error">{school_form.password.error_msg}</div> : ""}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="card mb-5 tabs-outer">
                                                                <div className="card-header position-relative details-bg">
                                                                    <h5 className="card-title text-gray-800 fw-bolder m-0">School Settings</h5>
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="row mb-5">
                                                                        <div class="col pt-3">
                                                                            <label class="fw-bold" for="exampleRadios1">
                                                                                <input class="form-check-input me-2" type="radio" onChange={(event) => this.profilePictureHandler(event, "shadow_profile")} name="exampleRadios" id="exampleRadios1" value={this.state.display_shadow_picture} checked={this.state.display_shadow_picture} />
                                                                                Display Male/Female Shadow
                                                                            </label>
                                                                        </div>
                                                                        <div class="col pt-3">
                                                                            <label class="fw-bold" for="exampleRadios2">
                                                                                <input class="form-check-input me-2" type="radio" onChange={(event) => this.profilePictureHandler(event, "picture_profile")} name="exampleRadios" id="exampleRadios2" value={this.state.display_profile_picture} checked={this.state.display_profile_picture} />
                                                                                Display Student Image
                                                                            </label>
                                                                        </div>
                                                                        <div class="col pt-3">
                                                                            <label class="fw-bold" for="freetrial">
                                                                                <input class="form-check-input me-2" type="radio" onChange={(event) => this.toggleDuration(event, "freetrial")} 
                                                                                name="freetrial" id="freetrial" value={this.state.openStartTrialDiv} checked={this.state.openStartTrialDiv} />
                                                                                Start a free trial
                                                                            </label>
                                                                        </div>
                                                                        <div class="col pt-3">
                                                                            <label class="fw-bold" for="subscription">
                                                                                <input class="form-check-input me-2" type="radio"  onChange={(event) => this.toggleDuration(event, "subscription")}   
                                                                                name="subscription" id="subscription" value={this.state.openStartSubscriptionDiv} checked={this.state.openStartSubscriptionDiv} />
                                                                                Start Subscription
                                                                            </label>
                                                                        </div>
                                                                        {/* <div className="col  sub-btn-group">
                                                                            <div className="d-flex justify-content-end">
                                                                            <button type="button" className="btn btn-danger btn-sm me-5" onClick={() => this.openStartTrial()}>Start a free trial</button>
                                                                            <button type="button" className="btn btn-success btn-sm" onClick={() => this.openStartSubscription()}>Start Subscription</button>
                                                                            </div>
                                                                        </div> */}
                                                                    </div>
                                                                    <div className="border-top"></div>
                                                                    <div className="row mb-5">
                                                                        {this.state.openStartTrialDiv &&
                                                                            <div className="col-12">
                                                                                <div className="row mt-5">
                                                                                    <div className="col-12">
                                                                                        <h1>Trial Duration</h1>
                                                                                    </div>
                                                                                    <div className="col-4">
                                                                                        <label className="fw-bold mb-2">{trial_and_subscription_form.duration.label}</label>
                                                                                        <Select
                                                                                            type={trial_and_subscription_form.duration.type}
                                                                                            value={trial_and_subscription_form.duration.options.filter(option => option.value === trial_and_subscription_form.duration.value)}
                                                                                            options={trial_and_subscription_form.duration.options}
                                                                                            onChange={(event) => this.durationhandler(event, "set_week")}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-4">
                                                                                        <label className="fw-bold mb-2">Trial Start Date *</label>
                                                                                        <div className="">
                                                                                            <ReactDatePicker
                                                                                                minDate={subDays(new Date(), 0)}
                                                                                                showIcon
                                                                                                dateFormat='yyyy/MM/dd'
                                                                                                wrapperClassName="trial_datePicker_superadmin"
                                                                                                className="form-control"
                                                                                                selected={trial_and_subscription_form.start_date.value}
                                                                                                onChange={(date) => this.dateHandler(date, "trial")}
                                                                                            />
                                                                                            {!trial_and_subscription_form.start_date.valid && trial_and_subscription_form.start_date.onBlur_out ? <div className="error field-error">{trial_and_subscription_form.start_date.error_msg}</div> : ""}
                                                                                        </div>

                                                                                    </div>
                                                                                    <div className="col-4">
                                                                                        <label className="fw-bold mb-2">Trial End Date</label>
                                                                                        <input className="form-control"
                                                                                            value={trial_and_subscription_form.end_date.value !== "" ?
                                                                                                moment(trial_and_subscription_form.end_date.value).format('YYYY/MM/DD') : ""}
                                                                                            readOnly
                                                                                        />
                                                                                        {!trial_and_subscription_form.end_date.valid && trial_and_subscription_form.end_date.onBlur_out ? <div className="error field-error">{trial_and_subscription_form.end_date.error_msg}</div> : ""}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }




                                                                        {this.state.openStartSubscriptionDiv &&
                                                                            <div className="col-12 border-top">
                                                                                <div className="row mt-5">
                                                                                    <div className="col-12">
                                                                                        <h1>Start Subscription</h1>
                                                                                    </div>
                                                                                    <div className="col-3">
                                                                                        <label className="fw-bold mb-2">Subscripition Plan</label>
                                                                                        <Select
                                                                                            type={trial_and_subscription_form.year_duration.type}
                                                                                            value={trial_and_subscription_form.year_duration.options.filter(option => option.value === trial_and_subscription_form.year_duration.value)}
                                                                                            options={trial_and_subscription_form.year_duration.options}
                                                                                            onChange={(event) => this.durationhandler(event, "set_year")}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-3">
                                                                                        <label className="fw-bold mb-2">{trial_and_subscription_form.start_date.label}</label>
                                                                                        <div className="">
                                                                                            <ReactDatePicker
                                                                                                minDate={subDays(new Date(), 0)}
                                                                                                showIcon
                                                                                                dateFormat="yyyy/MM/dd"
                                                                                                wrapperClassName="trial_datePicker_superadmin"
                                                                                                className="form-control"
                                                                                                selected={trial_and_subscription_form.start_date.value}
                                                                                                onChange={(date) => this.dateHandler(date, "subscription")}
                                                                                            />
                                                                                            {!trial_and_subscription_form.start_date.valid && trial_and_subscription_form.start_date.onBlur_out ? <div className="error field-error">{trial_and_subscription_form.start_date.error_msg}</div> : ""}
                                                                                        </div>

                                                                                    </div>
                                                                                    <div className="col-3">
                                                                                        <label className="fw-bold mb-2">{trial_and_subscription_form.end_date.label}</label>
                                                                                        <input className="form-control"
                                                                                            value={trial_and_subscription_form.end_date.value !== "" ?
                                                                                                moment(trial_and_subscription_form.end_date.value).format('YYYY/MM/DD') : ""}
                                                                                            readOnly
                                                                                        />
                                                                                        {!trial_and_subscription_form.end_date.valid && trial_and_subscription_form.end_date.onBlur_out ? <div className="error field-error">{trial_and_subscription_form.end_date.error_msg}</div> : ""}
                                                                                    </div>
                                                                                    <div className="col-3">
                                                                                        <label className="fw-bold mb-2">Amount</label>
                                                                                        <input
                                                                                            className="form-control amount_subscription"
                                                                                            type="number"
                                                                                            value={trial_and_subscription_form.amount.value}
                                                                                            onChange={(date) => this.dateHandler(date, "amount")}
                                                                                        />
                                                                                        {!trial_and_subscription_form.amount.valid && trial_and_subscription_form.amount.onBlur_out ? <div className="error field-error">{trial_and_subscription_form.amount.error_msg}</div> : ""}
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12 d-flex justify-content-end">
                                                                <div className="d-flex align-items-end">
                                                                    <button type="submit" className="btn btn-sm btn-primary apply-btn min-150px">
                                                                        Add School
                                                                    </button>
                                                                </div>
                                                            </div>
                                                           
                                                        </form>
                                                    </div>



                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Footer />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onGetAllSelectedCountries: userActions.GetAllSelectedCountries,
    onGetAllStatesByCountryId: userActions.GetAllStatesByCountryId,
    onGetAllCitiesByStateId: userActions.GetAllCitiesByStateId,
    onCreateSchool: userActions.CreateSchool,



};
export default connect(mapStateToProps, mapDispatchToProps)(SchoolAdd);