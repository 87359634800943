import React from "react";
import Footer from "../Constant/Footer";
import Sidebar from "../Constant/sidebar";
import TopBar from "../Constant/TopBar";
import { connect } from "react-redux";
import * as userActions from "../../actions/index";
import * as actionType from "../../constants/actionTypes";
import Validations from "../Utility/Validations";
import { ToastContainer, toast } from "react-toastify";
import AvatarImageCropper from 'react-avatar-image-cropper';

const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" :user_info.school_code;


class ManagerAccount extends React.Component {
    constructor(props) {
        super(props);
        this.manager_uuid = "";
        this.state = {


            current_password_hidden: true,
            new_password__hidden: true,
            confirm_new_password__hidden: true,

            OpenImageModal: false,

            account_setting: {
                avatar: {
                    label: "File",
                    type: "file",
                    value: '',
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                    thumbnail_image: "/assets/media/school-logo.png",
                },

                first_name: {
                    type: "text",
                    label: "First Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "First Name",
                    validations: { required: true },
                },
                last_name: {
                    type: "text",
                    label: "Last Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Last Name",
                    validations: { required: true },
                },

                email: {
                    type: "email",
                    label: "Email",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Email",
                    validations: { required: true },
                },
                contact_one: {
                    type: "tel",
                    label: "Contact Number",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Contact Number",
                    validations: { required: true, min_length_phone: 7 },
                },
                designation: {
                    type: "text",
                    label: "Designation",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Designation",
                    validations: { required: false },
                },
                user_id: {
                    type: "text",
                    label: "User ID",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "User ID",
                    validations: { required: true },
                },
                user_uuid: {
                    type: "text",
                    label: "user_uuid",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },
            },
            manager_password: {
                password: {
                    type: "text",
                    label: "Current Password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                new_password: {
                    type: "password",
                    label: "New Password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },

                confirm_new_password: {
                    type: "password",
                    label: "Confirm New Password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                user_uuid: {
                    type: "text",
                    label: "user_uuid",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },

            },
        }
    }

    componentDidMount() {
        this.GetSchoolManagerById();
    }


    GetSchoolManagerById() {
        const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
        this.manager_uuid = user_info.user_uuid;
        this.props.onGetSchoolManagerById(this.manager_uuid).then((response) => {
            console.log("manager_data", response);
            console.log("manager_data",user_info);

            if (response.success === true) {
                let manager_data = response.data;
                const update_account_setting = { ...this.state.account_setting };
                const update_manager_password = { ...this.state.manager_password };
                for (let key in update_manager_password) {
                    if (manager_data[key]) {
                        update_manager_password['password'].value = manager_data.password;
                        update_manager_password['user_uuid'].value = manager_data.user_uuid;
                    }
                }
                this.setState({ manager_password: update_manager_password });
                for (let key in update_account_setting) {
                    if (manager_data[key]) {
                        if (manager_data.avatar !== null && manager_data.avatar !== '') {
                            update_account_setting['avatar'].thumbnail_image = actionType.environment_url + '/' + manager_data.avatar;
                            update_account_setting['avatar'].value = manager_data.avatar;
                        }
                        let form_element = manager_data[key];
                        update_account_setting[key].value = form_element

                        // update_account_setting['first_name'].value = manager_data.first_name;
                        // update_account_setting['last_name'].value = manager_data.last_name;
                        // update_account_setting['email'].value = manager_data.email;
                        // update_account_setting['contact_one'].value = manager_data.contact_one;
                        // update_account_setting['designation'].value = manager_data.designation;
                        // update_account_setting[key] = form_element;

                    }
                }
                this.setState({ account_setting: update_account_setting });
                console.log("user_info", this.state.account_setting);

            }
        });
    }


  


    inputChangeHandlerimage = blob => {
        const src = window.URL.createObjectURL(blob);
        let url = URL.createObjectURL(blob);
        let file = new File([blob], "image.png", { lastModified: new Date().getTime(), type: blob.type })
        const update_account_setting = { ...this.state.account_setting };
        const form_element = { ...update_account_setting["avatar"] };
        form_element.thumbnail_image = url;

        form_element.value = file;
        update_account_setting["avatar"] = form_element;
        this.setState({ account_setting: update_account_setting });
        this.setState({ OpenImageModal: !this.state.OpenImageModal })
    };
 
    toggleShow(identifier) {
        if (identifier === "current_password") { this.setState({ current_password_hidden: !this.state.current_password_hidden }) };
        if (identifier === "new_password") { this.setState({ new_password__hidden: !this.state.new_password__hidden }) };
        if (identifier === "confirm_new_password") { this.setState({ confirm_new_password__hidden: !this.state.confirm_new_password__hidden }) };
    }

    inputChangeHandler(event, identifier) {
        const update_account_setting = { ...this.state.account_setting };
        const form_element = { ...update_account_setting[identifier] };
        if (identifier === '') {
            form_element.value = event;
        }
        else if (identifier === 'first_name') {
            form_element.value = event.target.value;
        }
        else if (identifier === 'last_name') {
            form_element.value = event.target.value;
        }
        else if (identifier === 'contact_one') {
            form_element.value = event.target.value;
        }
        else if (identifier === 'designation') {
            form_element.value = event.target.value;
        }
        else if (identifier === 'email') {
            form_element.value = event.target.value;
        }
        else {
            form_element.value = event.target.value;
        }

        // Check Validation

        update_account_setting[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_account_setting) {
            is_form_valid = update_account_setting[identifier].valid && is_form_valid;
        }
        this.setState({
            account_setting: update_account_setting,
            is_form_valid: is_form_valid,
        });
    }

    passwordChangeHandler(event, identifier) {
        const update_manager_password = { ...this.state.manager_password }
        const form_element = { ...update_manager_password[identifier] };
        form_element.value = event.target.value;
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;
        update_manager_password[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_manager_password) {
            is_form_valid = update_manager_password[identifier].valid && is_form_valid;
        }
        this.setState({ manager_password: update_manager_password })
    }



    SubmitHandler = async (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_account_setting = this.state.account_setting;
        for (let key in update_account_setting) {
            let form_element = update_account_setting[key];
            let validate_element = Validations(form_element);
            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;
            update_account_setting[key] = form_element;
        }
        this.setState({ account_setting: update_account_setting });
        if (is_form_valid) {
            const form_data = {};
            const update_account_setting = { ...this.state.account_setting };
            for (let key in update_account_setting) {
                let form_element = update_account_setting[key];
                form_data[key] = form_element.value;
            }
            this.props.onUpdateUserProfile(form_data).then((response) => {
                if (response.success === true) {
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: `/${school_code}/dashboard`,
                        });
                    }, 500);
                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            });
        }
    };



    SubmitManagerPassword = async (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_manager_password = { ...this.state.manager_password }
        for (let key in update_manager_password) {
            let form_element = update_manager_password[key];
            let validate_element = Validations(form_element);
            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;
            update_manager_password[key] = form_element;
        }
        this.setState({ manager_password: update_manager_password });
        if (is_form_valid) {
            const update_manager_password = { ...this.state.manager_password }
            const form_data = {};
            console.log("this.manager_uuid", update_manager_password);
            form_data['password'] = update_manager_password['new_password'].value
            form_data['confirm_password'] = update_manager_password['confirm_new_password'].value
            form_data['user_uuid'] = update_manager_password['user_uuid'].value
            this.props.onUpdateManagerPassword(form_data).then((response) => {
                if (response.success === true) {
                    // toast.success('🦄 Wow so easy!', {
                    //     position: "top-right",
                    //     autoClose: 5000,
                    //     hideProgressBar: false,
                    //     closeOnClick: true,
                    //     pauseOnHover: true,
                    //     draggable: true,
                    //     progress: undefined,
                    //     theme: "light",
                    // });
                    // setTimeout(() => {
                    //     this.setState({
                    //         isAuthenticated: true,
                    //         redirect_url: "/",
                    //     });
                    // }, 500);
                } else {
                    // toast.error(response.message, {
                    //     position: toast.POSITION.TOP_CENTER,
                    // });
                }
            });

        }
    }

    render() {
        const { account_setting } = this.state;
        const { manager_password } = this.state;
        return (
            <>
                {this.state.OpenImageModal &&
                    <div className="upload-image-outer-div"  >
                        <span style={{ float: "right" }} className="pointer m-5" onClick={() => this.setState({ OpenImageModal: !this.state.OpenImageModal })}>
                            <i class="fas fa-times fa-2x" sixe="xl" style={{ color: "#ffffff", }}></i>
                        </span>
                        <div className="upload-image-inner-div">
                            <AvatarImageCropper
                                rootStyle={{ borderRadius: "50%" }}
                                cancelBtnStyle={{ display: "none" }}
                                apply={this.inputChangeHandlerimage}
                                className={"image_crop_style"}
                                sliderConStyle={{ margin: "20px", backgroundColor: "none" }}
                                applyBtnStyle={{}}
                                isBack={true}
                            />
                        </div>
                    </div>
                }
                <div>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
                </div>
                <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed" >
                    <div className="d-flex flex-column flex-root">
                        <div className="page d-flex flex-row flex-column-fluid">
                            <Sidebar />
                            <div className="wrapper d-flex flex-column flex-row-fluid " id="kt_wrapper">
                                <TopBar />
                                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                    <div className="post d-flex flex-column-fluid" id="kt_post">
                                        {/* <!--begin::Container--> */}
                                        <div id="kt_content_container" className="container-fluid">
                                            <div className="card mt-10 mb-xl-10">
                                                <form onSubmit={this.SubmitHandler}>
                                                    <div className="card-header border-0 cursor-pointer" >
                                                        <div className="card-title m-0">
                                                            <h3 className="fw-bolder m-0">Account Settings</h3>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="row mb-6">

                                                            <div className="col-lg-4 d-flex flex-column align-items-center">
                                                                <div className="profile-img-wrap me-auto">
                                                                    <div className="image-style">
                                                                        <img alt="profile" src={account_setting.avatar.thumbnail_image} className="img-fluid h-100 w-100 rounded-circle" />
                                                                    </div>
                                                                    <div className="edit-progile-img-btn mt-4" >
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-sm btn-outline-primary px-10 rounded-50 "
                                                                            onClick={() => this.setState({ OpenImageModal: !this.state.OpenImageModal })}
                                                                        >
                                                                            Upload Image
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-8 pt-5">
                                                                <div className="row mb-6">

                                                                    <div className="col-md-6">
                                                                        <label className="fw-bold fs-6 mb-4">{account_setting.first_name.label}</label>
                                                                        <input
                                                                            type={account_setting.first_name.type}
                                                                            value={account_setting.first_name.value ? account_setting.first_name.value : ""}
                                                                            className="form-control"
                                                                            placeholder={account_setting.first_name.placeholder}
                                                                            onChange={(event) => this.inputChangeHandler(event, "first_name")}
                                                                        />

                                                                        {!account_setting.first_name.valid && account_setting.first_name.onBlur_out ? (
                                                                            <div className="error field-error">{account_setting.first_name.error_msg}</div>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <label className="fw-bold fs-6 mb-4">{account_setting.last_name.label}</label>

                                                                        <input
                                                                            type={account_setting.last_name.type}
                                                                            value={account_setting.last_name.value ? account_setting.last_name.value : ""}
                                                                            className="form-control"
                                                                            placeholder={account_setting.last_name.placeholder}
                                                                            onChange={(event) => this.inputChangeHandler(event, "last_name")}
                                                                        />

                                                                        {!account_setting.last_name.valid && account_setting.last_name.onBlur_out ? (
                                                                            <div className="error field-error">{account_setting.last_name.error_msg}</div>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                {/* <div className="row mb-6">

                                                                    <label className="fw-bold fs-6 mb-5">Gender *</label>

                                                                    <div className="col-md-12">
                                                                        <label className="cursor-pointer me-5 fw-bold ">
                                                                            <input
                                                                                type="radio"
                                                                                id="gender" 
                                                                                name="gen" 
                                                                                className="form-check-input me-2 cursor-pointer" 
                                                                                value="male"
                                                                                onChange={(event) => this.inputChangeHandler(event)}
                                                                            />
                                                                            Male
                                                                        </label>
                                                                        <label className="cursor-pointer fw-bold ">
                                                                            <input 
                                                                                type="radio" 
                                                                                id="gender" 
                                                                                name="gen" 
                                                                                className="form-check-input me-2 cursor-pointer" 
                                                                                value="female"
                                                                                onChange={(event) => this.inputChangeHandler(event)}
                                                                            />
                                                                            Female
                                                                        </label>
                                                                    </div>
                                                                </div> */}


                                                                <div className="row mb-6">
                                                                    <div className="col-lg-12">
                                                                        <div className="row">
                                                                            <div className="col-6">
                                                                                <label className="fw-bold fs-6 mb-4">{account_setting.contact_one.label}</label>
                                                                                <input
                                                                                    type={account_setting.contact_one.type}
                                                                                    value={account_setting.contact_one.value ? account_setting.contact_one.value : ""}
                                                                                    className="form-control"
                                                                                    placeholder={account_setting.contact_one.placeholder}
                                                                                    onChange={(event) => this.inputChangeHandler(event, "contact_one")}
                                                                                />
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <label className="fw-bold fs-6 mb-4">{account_setting.designation.label}</label>
                                                                                <input
                                                                                    type={account_setting.designation.type}
                                                                                    value={account_setting.designation.value ? account_setting.designation.value : ""}
                                                                                    className="form-control"
                                                                                    placeholder={account_setting.designation.placeholder}
                                                                                    onChange={(event) => this.inputChangeHandler(event, "designation")}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        {!account_setting.contact_one.valid && account_setting.contact_one.onBlur_out ? (
                                                                            <div className="error field-error">{account_setting.contact_one.error_msg}</div>
                                                                        ) : (
                                                                            ""
                                                                        )}

                                                                    </div>
                                                                </div>
                                                                <div className="row mb-6">
                                                                    <label className="  fw-bold fs-6 mb-4">{account_setting.email.label}</label>
                                                                    <div className="col-lg-12">
                                                                        <input
                                                                            type={account_setting.email.type}
                                                                            value={account_setting.email.value ? account_setting.email.value : ""}
                                                                            className="form-control"
                                                                            placeholder={account_setting.email.placeholder}
                                                                            onChange={(event) => this.inputChangeHandler(event, "email")}
                                                                        />
                                                                        {!account_setting.email.valid && account_setting.email.onBlur_out ? (
                                                                            <div className="error field-error">{account_setting.email.error_msg}</div>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                                                        <button type="submit" className="btn btn-primary px-6 btn-sm" id="kt_account_profile_details_submit">Save Changes</button>
                                                    </div>
                                                </form>
                                            </div>





                                            <div className="card mt-10 mb-xl-10">
                                                <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_signin_method">
                                                    <div className="card-title m-0">
                                                        <h3 className="fw-bolder m-0">Change Password</h3>
                                                    </div>
                                                </div>

                                                <form onSubmit={this.SubmitManagerPassword}>
                                                    <div className="card-body">
                                                        <div className="row mb-1">
                                                            <div className="col-lg-4">
                                                                <label className="form-label fs-6 fw-bolder mb-3">{manager_password.password.label} </label>
                                                                <div className="position-relative">
                                                                    <input
                                                                        type={this.state.current_password_hidden ? 'password' : 'text'}
                                                                        value={manager_password.password.value ? manager_password.password.value : ""}
                                                                        className="form-control"
                                                                    />
                                                                    <span onClick={() => this.toggleShow("current_password")} className="btn btn-icon position-absolute translate-middle top-50 end-0" data-kt-password-meter-control="visibility">
                                                                        {this.state.current_password_hidden ? <i className="bi bi-eye-slash fs-2"></i> : <i className="bi bi-eye fs-2"></i>}
                                                                    </span>
                                                                </div>
                                                                {!manager_password.password.valid && manager_password.password.onBlur_out ? (
                                                                    <div className="error field-error">{manager_password.password.error_msg}</div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>

                                                            <div className="col-lg-4">
                                                                <label className="form-label fs-6 fw-bolder mb-3">{manager_password.new_password.label} </label>
                                                                <div className="position-relative">
                                                                    <input
                                                                        type={this.state.new_password__hidden ? 'password' : 'text'}
                                                                        value={manager_password.new_password.value ? manager_password.new_password.value : ""}
                                                                        className="form-control"
                                                                        placeholder={manager_password.new_password.placeholder}
                                                                        onChange={(event) => this.passwordChangeHandler(event, "new_password")}
                                                                    />
                                                                    <span onClick={() => this.toggleShow("new_password")} className="btn btn-icon position-absolute translate-middle top-50 end-0" data-kt-password-meter-control="visibility">
                                                                        {this.state.new_password__hidden ? <i className="bi bi-eye-slash fs-2"></i> : <i className="bi bi-eye fs-2"></i>}
                                                                    </span>
                                                                </div>
                                                                {!manager_password.new_password.valid ?
                                                                    (<div className="error field-error">{manager_password.new_password.error_msg}</div>)
                                                                    : ("")
                                                                }
                                                            </div>

                                                            <div className="col-lg-4">
                                                                <label className="form-label fs-6 fw-bolder mb-3">{manager_password.confirm_new_password.label} </label>
                                                                <div className="position-relative">
                                                                    <input
                                                                        type={this.state.confirm_new_password__hidden ? 'password' : 'text'}
                                                                        value={manager_password.confirm_new_password.value ? manager_password.confirm_new_password.value : ""}
                                                                        className="form-control"
                                                                        placeholder={manager_password.confirm_new_password.placeholder}
                                                                        onChange={(event) => this.passwordChangeHandler(event, "confirm_new_password")}
                                                                    />
                                                                    <span onClick={() => this.toggleShow("confirm_new_password")} className="btn btn-icon position-absolute translate-middle top-50 end-0" data-kt-password-meter-control="visibility">
                                                                        {this.state.confirm_new_password__hidden ? <i className="bi bi-eye-slash fs-2"></i> : <i className="bi bi-eye fs-2"></i>}
                                                                    </span>
                                                                </div>
                                                                {!manager_password.confirm_new_password.valid ?
                                                                    (<div className="error field-error">{manager_password.confirm_new_password.error_msg}</div>)
                                                                    : ("")
                                                                }
                                                            </div>
                                                        </div>
                                                        <br></br>

                                                        <div className="d-flex justify-content-end">
                                                            <button id="kt_password_submit" type="submit" className="btn btn-primary  px-6 btn-sm" >Update Password</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Footer />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}
const mapDispatchToProps = {

    onGetSchoolManagerById: userActions.GetSchoolManagerById,
    onUpdateManagerPassword: userActions.UpdateManagerPassword,
    onUpdateUserProfile: userActions.UpdateUserProfile,

};
export default connect(mapStateToProps, mapDispatchToProps)(ManagerAccount);


