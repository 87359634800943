import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../Constant/sidebar";
import TopBar from "../Constant/TopBar";
import Footer from "../Constant/Footer";
import DashboardContent from "./dashboardcontent";
import { connect } from "react-redux";
import * as userActions from "../../actions/index";

// const permission_info = JSON.parse(localStorage.getItem("permissions"));
class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.GetLoginUserPermissions();
      }
    
      GetLoginUserPermissions() {
        this.props.onGetLoginUserPermissions().then((response) => {
          if (response.success === true) {
          } else {
          }
        });
      }
      
    SubmitHandler = async (event) => {};
    render() {
        const { dashboard_form } = this.state;
        const permission_info = JSON.parse(localStorage.getItem("permissions"));
 
        return (
            <>
                <div className="d-flex flex-column flex-root header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed h-100">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <Sidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-fluid">
                                        <div className="tab-content" id="myTabContent">
                                            <div className="tab-pane fade show active" id="Dashboard" role="tabpanel" aria-labelledby="Dashboard-tab">
                                              

                                                {/* <center>   <h1 className="text-gray-800 fw-bolder m-0 ">Dashboard</h1> </center>
                                                 <br /><br /> */} 
                                                <React.Fragment><ToastContainer/></React.Fragment>
                                                <DashboardContent></DashboardContent>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!--end::Container--> */}
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
      // error: state.students.error,
      // loader: state.students.loader,
      // isAuthenticated: state.students.isAuthenticated,
    };
  }
  
  const mapDispatchToProps = {

    onGetLoginUserPermissions: userActions.GetLoginUserPermissions,
  };
  export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
  