import React from "react";

import Validations from "../../Utility/Validations";
import Sidebar from "../../Constant/sidebar";
import Footer from "../../Constant/Footer";
import TopBar from "../../Constant/TopBar";

import { connect } from "react-redux";

import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as userActions from "../../../actions/index"
// import * as actionType from "../../../constants/actionTypes";

const permission_info = JSON.parse(localStorage.getItem("permissions"));
const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" :user_info?.school_code;
class EditGenericStudent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            edit_generic_student_form: {
                student_id: {
                    type: "select",
                    label: "Student Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Student Name",
                    validations: { required: true },
                    options: [],
                },
                student_name: {
                    type: "text",
                    label: "Description *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Description *",
                    validations: { required: true },
                },

                generic_start_date: {
                    type: "date",
                    label: "Start Date",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },

                generic_end_date: {
                    type: "date",
                    label: "End Date",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },

                generic_desc: {
                    type: "text",
                    label: "Generic Description *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "generic_desc *",
                    validations: { required: true },
                },
                student_generic_uuid: {
                    type: "text",
                    label: "student uuid",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },
            },
        };
    }

    componentDidMount() {

        this.GetStudentGenericById();
        this.GetAllStudentsGeneric();
        this.GetLoginUserPermissions();
    }
    GetLoginUserPermissions() {
        this.props.onGetLoginUserPermissions().then((response) => {
          if (response.success === true) {
          } else {
          }
        });
      }


    GetAllStudentsGeneric = async (form_data) => {
        this.props.onGetAllStudentsGeneric().then((response) => {
            let country_level = [];
            country_level.push({
                label: "Select Student",
                value: "",
            });
            const update_edit_generic_student_form = { ...this.state.edit_generic_student_form };
            if (response.success === true) {

                const arrayData = response.data.rows;
                console.log(arrayData);
                for (let key in arrayData) {
                    country_level.push({
                        label: arrayData[key].student_first_name + " " + arrayData[key].student_last_name,
                        value: arrayData[key].student_id,
                        student_id: arrayData[key].student_id,
                    });
                }
            }
            update_edit_generic_student_form['student_id'].options = country_level;
            this.setState({ edit_generic_student_form: update_edit_generic_student_form });
        });
    }


    GetStudentGenericById() {
        this.props.onGetStudentGenericById(this.props.user_uuid).then((response) => {
            // console.log("HHHeeelllooo",this.props)
            if (response.success === true) {

                let generic_data = response.data;

                const update_edit_generic_student_form = { ...this.state.edit_generic_student_form };
                for (let key in update_edit_generic_student_form) {
                    let form_element = update_edit_generic_student_form[key];

                    if (generic_data[key]) {


                        form_element.value = generic_data[key];


                        update_edit_generic_student_form['student_name'].value = generic_data.generic_student.student_first_name + " " + generic_data.generic_student.student_last_name; update_edit_generic_student_form['generic_start_date'].value = generic_data.generic_start_date;
                        update_edit_generic_student_form['generic_end_date'].value = generic_data.generic_end_date;
                        update_edit_generic_student_form['generic_desc'].value = generic_data.generic_desc;



                        update_edit_generic_student_form['student_generic_uuid'].value = generic_data.student_generic_uuid;
                        this.setState({ edit_generic_student_form: update_edit_generic_student_form });
                        update_edit_generic_student_form[key] = form_element;
                    }
                }
                this.setState({ edit_generic_student_form: update_edit_generic_student_form });
            } else {
            }
        });

    }



    inputChangeHandler(event, identifier) {
        let value = event.target.value.substring(0,70)
        const update_edit_generic_student_form = { ...this.state.edit_generic_student_form };
        const form_element = { ...update_edit_generic_student_form[identifier] };
        form_element.value = value;
        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_edit_generic_student_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_edit_generic_student_form) {
            is_form_valid = update_edit_generic_student_form[identifier].valid && is_form_valid;
        }
        this.setState({
            edit_generic_student_form: update_edit_generic_student_form,
            is_form_valid: is_form_valid,
        });
    }

    handleChange(data, identifier, type) {
        const update_edit_generic_student_form = { ...this.state.edit_generic_student_form };
        update_edit_generic_student_form[identifier].value = data.value;
        this.setState({ edit_generic_student_form: update_edit_generic_student_form });
    }
    SubmitHandler = async (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_edit_generic_student_form = this.state.edit_generic_student_form;
        for (let key in update_edit_generic_student_form) {
            let form_element = update_edit_generic_student_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_edit_generic_student_form[key] = form_element;
        }
        this.setState({ edit_generic_student_form: update_edit_generic_student_form });
        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.edit_generic_student_form) {
                form_data[key] = this.state.edit_generic_student_form[key].value;
            }
            console.log("form_data===", form_data);
            this.props.onUpdateStudentGeneric(form_data).then((response) => {
                if (response.success === true) {
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: `/${school_code}/student/flag-student`,
                        });
                    }, 1000);
                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            });
        }

    };
    render() {
        const { edit_generic_student_form } = this.state;

        console.log("edit_generic_student_form===", edit_generic_student_form);

        return (
            <React.Fragment>
                {this.state.isAuthenticated ? <Navigate to={this.state.redirect_url} /> : null}
                <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
                    <div className="d-flex flex-column flex-root">
                        <div className="page d-flex flex-row flex-column-fluid">
                            <Sidebar />
                            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                                <TopBar />
                                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                    <div className="post d-flex flex-column-fluid" id="kt_post">
                                        <div id="kt_content_container" className="container-fluid">
                                            <div className="school-edit-form">
                                                <div className="" id="Snapshot">
                                                    <div className="card-body">
                                                        <h4 className="card-title m-0 mb-5">Edit Student Reminder</h4>
                                                        <div className="row">


                                                            <div className="col-sm-12">
                                                                <form onSubmit={this.SubmitHandler}>
                                                                    <div className="card mb-5 tabs-outer">
                                                                        <div className="card-header position-relative py-0 details-bg">
                                                                              <h2 className="card-title  m-0">Student Details</h2>
                                                                        </div>

                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-lg-4 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{edit_generic_student_form.student_id.label}</label>
                                                                                    {/* <Select
                                                                                styles={customStyles}
                                                                                className="react-bootstrap-typeahead tag-outer"
                                                                                options={edit_generic_student_form.student_id.options}
                                                                                value={edit_generic_student_form.student_id.options.filter(function (option) {
                                                                                    return option.value === edit_generic_student_form.student_id.value;
                                                                                })}
                                                                                onChange={(newValue) => this.handleChange(newValue, "student_id", "weekend_day from value")}
                                                                            /> */}

                                                                                    <input type="text" name="fname" className="form-control " readOnly
                                                                                        value={edit_generic_student_form.student_name.value ? edit_generic_student_form.student_name.value : ""}

                                                                                    />

                                                                                    {!edit_generic_student_form.student_id.valid && edit_generic_student_form.student_id.onBlur_out ? (
                                                                                        <div className="error field-error">{edit_generic_student_form.student_id.error_msg}</div>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </div>

                                                                                <div className="col-lg-4 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">Start Date *</label>
                                                                                    <input
                                                                                        type={edit_generic_student_form.generic_start_date.type}
                                                                                        name="fname"
                                                                                        className="form-control time-input"
                                                                                        placeholder=""
                                                                                        value={edit_generic_student_form.generic_start_date.value ? edit_generic_student_form.generic_start_date.value : ""}
                                                                                        onChange={(event) => this.inputChangeHandler(event, "generic_start_date")}
                                                                                    />
                                                                                    {!edit_generic_student_form.generic_start_date.valid && edit_generic_student_form.generic_start_date.onBlur_out ? (
                                                                                        <div className="error field-error">{edit_generic_student_form.generic_start_date.error_msg}</div>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </div>

                                                                                <div className="col-lg-4 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">End Date *</label>
                                                                                    <input
                                                                                        type={edit_generic_student_form.generic_end_date.type}
                                                                                        name="fname"
                                                                                        className="form-control time-input"
                                                                                        placeholder=""
                                                                                        value={edit_generic_student_form.generic_end_date.value ? edit_generic_student_form.generic_end_date.value : ""}
                                                                                        onChange={(event) => this.inputChangeHandler(event, "generic_end_date")}
                                                                                    />
                                                                                    {!edit_generic_student_form.generic_end_date.valid && edit_generic_student_form.generic_end_date.onBlur_out ? (
                                                                                        <div className="error field-error">{edit_generic_student_form.generic_end_date.error_msg}</div>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </div>

                                                                                <div className="col-lg-12 fv-row">
                                                                                    <label className="fw-bold mb-2">Description *</label>
                                                                                    <textarea
                                                                                        placeholder=""
                                                                                        className="form-control notes-main mb-2"
                                                                                        value={edit_generic_student_form.generic_desc.value ? edit_generic_student_form.generic_desc.value : ""}
                                                                                        onChange={(event) => this.inputChangeHandler(event, "generic_desc")}
                                                                                    />
                                                                                    <span className="countLength">Max 70 Characters: {edit_generic_student_form.generic_desc.value?.length}</span>
                                                                                    {!edit_generic_student_form.generic_desc.value && edit_generic_student_form.generic_desc.onBlur_out ? (
                                                                                        <div className="error field-error">{edit_generic_student_form.generic_desc.error_msg}</div>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-12 d-flex justify-content-end">
                                                                        <div className="d-flex align-items-end">
                                                                            <button type="submit" className="btn btn-sm btn-primary apply-btn min-150px">
                                                                                Update
                                                                            </button>
                                                                        </div>
                                                                    </div>

                                                                </form>
                                                            </div>


                                                            {/* <div className="col-sm-4">
                                                            <div className="card ">
                                                            
                                                                <StudentSearch />
                                                            </div>
                                                        </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Footer />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}

const mapDispatchToProps = {
    onUpdateStudentGeneric: userActions.UpdateStudentGeneric,
    onGetStudentGenericById: userActions.GetStudentGenericById,
    onGetAllStudentsGeneric: userActions.GetAllStudentsGeneric,
    onGetLoginUserPermissions: userActions.GetLoginUserPermissions,
};
export default connect(mapStateToProps, mapDispatchToProps)(EditGenericStudent);


