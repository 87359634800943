import axios from "axios";
import * as actionType from "../../constants/actionTypes";
let qs = require("qs");




export const ListofApprovedLeavesStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.ListofApprovedLeaves_START,
    };
};
export const ListofApprovedLeavesSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.ListofApprovedLeaves_SUCCESS,
    };
};
export const ListofApprovedLeavesFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.ListofApprovedLeaves_FAIL,
    };
};
export const ListofApprovedLeaves = (page, limit, sort, order, class_id, class_name, dormitory_id, dormitory_name, gender, to_date, from_date, search, is_pdf, is_csv) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(ListofApprovedLeavesStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/ListofApprovedLeaves`,
            params: {
                page: page,
                limit: limit,
                sort: sort,
                order: order,
                class_id: class_id,
                class_name: class_name,
                dormitory_id: dormitory_id,
                dormitory_name: dormitory_name,
                gender: gender,
                to_date: to_date,
                from_date: from_date,
                search: search,
                is_pdf: is_pdf,
                is_csv: is_csv
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(ListofApprovedLeavesSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(ListofApprovedLeavesFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


export const NonReturnNotificationReportStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.NonReturnNotificationReport_START,
    };
};
export const NonReturnNotificationReportSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.NonReturnNotificationReport_SUCCESS,
    };
};
export const NonReturnNotificationReportFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.NonReturnNotificationReport_FAIL,
    };
};
export const NonReturnNotificationReport = (page, limit, sort, order, class_id, class_name, dormitory_id, dormitory_name, gender, to_date, toTime, from_date, fromTime, search, is_pdf, is_csv) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(NonReturnNotificationReportStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/NonReturnNotificationReport`,
            params: {
                page: page,
                limit: limit,
                sort: sort,
                order: order,
                class_id: class_id,
                class_name: class_name,
                dormitory_id: dormitory_id,
                dormitory_name: dormitory_name,
                gender: gender,
                to_date: to_date,
                end_time: toTime,
                from_date: from_date,
                start_time: fromTime,
                search: search,
                is_pdf: is_pdf,
                is_csv: is_csv
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(NonReturnNotificationReportSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(NonReturnNotificationReportFail("Something went wrong, Please try again."));
                return error;
            });
    };
};




export const StudentDepartureReportStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.StudentDepartureReport_START,
    };
};
export const StudentDepartureReportSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.StudentDepartureReport_SUCCESS,
    };
};
export const StudentDepartureReportFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.StudentDepartureReport_FAIL,
    };
};
export const StudentDepartureReport = (page, limit, sort, order, class_id, class_name, dormitory_id, dormitory_name, gender, to_date, from_date, search, is_pdf, is_csv) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(StudentDepartureReportStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/StudentDepartureReport`,
            params: {
                page: page,
                limit: limit,
                sort: sort,
                order: order,
                class_id: class_id,
                class_name: class_name,
                dormitory_id: dormitory_id,
                dormitory_name: dormitory_name,
                gender: gender,
                to_date: to_date,
                from_date: from_date,
                search: search,
                is_pdf: is_pdf, 
                is_csv: is_csv
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(StudentDepartureReportSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(StudentDepartureReportFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


export const StudentDueBackReportStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.StudentDueBackReport_START,
    };
};
export const StudentDueBackReportSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.StudentDueBackReport_SUCCESS,
    };
};
export const StudentDueBackReportFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.StudentDueBackReport_FAIL,
    };
};
export const StudentDueBackReport = (page, limit, sort, order, class_id, class_name, dormitory_id, dormitory_name, gender, to_date, toTime, from_date, fromTime, search, is_pdf, is_csv) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(StudentDueBackReportStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/StudentDueBackReport`,
            params: {
                page: page,
                limit: limit,
                sort: sort,
                order: order,
                class_id: class_id,
                class_name: class_name,
                dormitory_id: dormitory_id,
                dormitory_name: dormitory_name,
                gender: gender,
                to_date: to_date,
                end_time: toTime,
                from_date: from_date,
                start_time: fromTime,
                search: search,
                is_pdf: is_pdf, 
                is_csv: is_csv
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(StudentDueBackReportSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(StudentDueBackReportFail("Something went wrong, Please try again."));
                return error;
            });
    };
};





export const BoardersLeaveHistoryReportStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.BoardersLeaveHistoryReport_START,
    };
};
export const BoardersLeaveHistoryReportSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.BoardersLeaveHistoryReport_SUCCESS,
    };
};
export const BoardersLeaveHistoryReportFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.BoardersLeaveHistoryReport_FAIL,
    };
};
export const BoardersLeaveHistoryReport = (page, limit, sort, order, class_id, class_name, dormitory_id, dormitory_name, gender, to_date, from_date, search, is_pdf, is_csv) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(BoardersLeaveHistoryReportStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/BoardersLeaveHistoryReport`,
            params: {
                page: page,
                limit: limit,
                sort: sort,
                order: order,
                class_id: class_id,
                class_name: class_name,
                dormitory_id: dormitory_id,
                dormitory_name: dormitory_name,
                gender: gender,
                to_date: to_date,
                from_date: from_date,
                search: search,
                is_pdf: is_pdf, 
                is_csv: is_csv
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(BoardersLeaveHistoryReportSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(BoardersLeaveHistoryReportFail("Something went wrong, Please try again."));
                return error;
            });
    };
};





export const AdhocReportStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.AdhocReport_START,
    };
};
export const AdhocReportSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.AdhocReport_SUCCESS,
    };
};
export const AdhocReportFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.AdhocReport_FAIL,
    };
};
export const AdhocReport = (page, limit, sort, order, class_id, class_name, dormitory_id, dormitory_name, gender, to_date, toTime, from_date, fromTime, search, is_pdf, is_csv) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(AdhocReportStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/AdhocReport`,
            params: {
                page: page,
                limit: limit,
                sort: sort,
                order: order,
                class_id: class_id,
                class_name: class_name,
                dormitory_id: dormitory_id,
                dormitory_name: dormitory_name,
                gender: gender,
                to_date: to_date,
                end_time: toTime,
                from_date: from_date,
                start_time:fromTime,
                search: search,
                is_pdf: is_pdf, 
                is_csv: is_csv
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(AdhocReportSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(AdhocReportFail("Something went wrong, Please try again."));
                return error;
            });
    };
};




export const AdhocHistoryReportStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.AdhocHistoryReport_START,
    };
};
export const AdhocHistoryReportSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.AdhocHistoryReport_SUCCESS,
    };
};
export const AdhocHistoryReportFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.AdhocHistoryReport_FAIL,
    };
};
export const AdhocHistoryReport = (page, limit, sort, order, class_id, class_name, dormitory_id, dormitory_name, gender, to_date, from_date, search, start_time, end_time, is_pdf, is_csv) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(AdhocHistoryReportStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/AdhocHistoryReport`,
            params: {
                page: page,
                limit: limit,
                sort: sort,
                order: order,
                class_id: class_id,
                class_name: class_name,
                dormitory_id: dormitory_id,
                dormitory_name: dormitory_name,
                gender: gender,
                to_date: to_date,
                from_date: from_date,
                search: search,
                start_time: start_time,
                end_time: end_time,
                is_pdf: is_pdf,
                is_csv: is_csv
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(AdhocHistoryReportSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(AdhocHistoryReportFail("Something went wrong, Please try again."));
                return error;
            });
    };
};





export const StudentsAllergicReportStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.StudentsAllergicReport_START,
    };
};
export const StudentsAllergicReportSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.StudentsAllergicReport_SUCCESS,
    };
};
export const StudentsAllergicReportFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.StudentsAllergicReport_FAIL,
    };
};
export const StudentsAllergicReport = (page, limit, sort, order, class_id, class_name, dormitory_id, dormitory_name, gender, to_date, from_date, search, is_pdf, is_csv, isPagination, allergy_name="") => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    console.log("Redux Action - isPagination:", isPagination);
    return (dispatch) => {
        dispatch(StudentsAllergicReportStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/StudentsAllergicReport`,
            params: {
                page: isPagination ? page : "",
                limit: isPagination ? limit : "",
                sort: sort,
                order: order,
                class_id: class_id,
                class_name: class_name,
                dormitory_id: dormitory_id,
                dormitory_name: dormitory_name,
                gender: gender,
                to_date: to_date,
                from_date: from_date,
                search: search,
                allergy_name: allergy_name,
                is_pdf: is_pdf,
                is_csv: is_csv
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(StudentsAllergicReportSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(StudentsAllergicReportFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** HostConnectedToStudentsReport ***/

export const HostConnectedToStudentsReportStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.HostConnectedToStudentsReport_START,
    };
};
export const HostConnectedToStudentsReportSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.HostConnectedToStudentsReport_SUCCESS,
    };
};
export const HostConnectedToStudentsReportFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.HostConnectedToStudentsReport_FAIL,
    };
};
export const HostConnectedToStudentsReport = (page, limit, is_host_approved, is_pdf, isPagination) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(BssHostReportStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/HostConnectedToStudentsReport`,
            params: {
                page: isPagination ? page : "",
                limit: isPagination ? limit : "",
                is_host_approved: is_host_approved,
                is_pdf: is_pdf
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(HostConnectedToStudentsReportSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(HostConnectedToStudentsReportFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** BssStudentHostReport ***/

export const BssStudentHostReportStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.BssStudentHostReport_START,
    };
};
export const BssStudentHostReportSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.BssStudentHostReport_SUCCESS,
    };
};
export const BssStudentHostReportFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.BssStudentHostReport_FAIL,
    };
};
export const BssStudentHostReport = (page, limit, is_host_approved, is_pdf, isPagination) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(BssHostReportStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/BssStudentHostReport`,
            params: {
                page: isPagination ? page : "",
                limit: isPagination ? limit : "",
                is_host_approved: is_host_approved,
                is_pdf: is_pdf
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(BssStudentHostReportSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(BssStudentHostReportFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetAllStudentsHost ***/

export const BssHostReportStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.BssHostReport_START,
    };
};
export const BssHostReportSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.BssHostReport_SUCCESS,
    };
};
export const BssHostReportFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.BssHostReport_FAIL,
    };
};
export const BssHostReport = (page, limit, is_host_approved, is_pdf, isPagination) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(BssHostReportStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/BssHostReport`,
            params: {
                page: isPagination ? page : "",
                limit: isPagination ? limit : "",
                is_host_approved: is_host_approved,
                is_pdf: is_pdf
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(BssHostReportSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(BssHostReportFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** GetCateringReport ***/

export const GetCateringReportStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetCateringReport_START,
    };
};
export const GetCateringReportSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetCateringReport_SUCCESS,
    };
};
export const GetCateringReportFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetCateringReport_FAIL,
    };
};
export const GetCateringReport = (dormitory_id, dormitory_name, date,is_pdf) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetCateringReportStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetCateringReport`,
            params: {
                dormitory_ids: JSON.stringify(dormitory_id), // Send dormitory_ids as a JSON string
                dormitory_name: JSON.stringify(dormitory_name),
                date: date,
                is_pdf:is_pdf,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params, { encode: false }); // Avoids encoding brackets and commas
            },
        })
            .then(function (response) {
                dispatch(GetCateringReportSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetCateringReportFail("Something went wrong, Please try again."));
                return error;
            });
    };
};




/*** GetCateringReportDetailsByMealType ***/

export const GetCateringReportDetailsByMealTypeStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetCateringReportDetailsByMealType_START,
    };
};
export const GetCateringReportDetailsByMealTypeSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetCateringReportDetailsByMealType_SUCCESS,
    };
};
export const GetCateringReportDetailsByMealTypeFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetCateringReportDetailsByMealType_FAIL,
    };
};
export const GetCateringReportDetailsByMealType = (meal_type, meal_time, dormitory_id, dormitory_name, date, is_pdf_report) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetCateringReportDetailsByMealTypeStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetCateringReportDetailsByMealType`,
            params: {
                meal_type: meal_type,
                meal_time: meal_time,
                dormitory_ids: JSON.stringify(dormitory_id), // Send dormitory_ids as a JSON string
                dormitory_name: JSON.stringify(dormitory_name),
                date: date,
                is_pdf: is_pdf_report
            },
            paramsSerializer: (params) => {
                // return qs.stringify(params);
                return qs.stringify(params, { encode: false }); // Avoids encoding brackets and commas
            },
        })
            .then(function (response) {
                dispatch(GetCateringReportDetailsByMealTypeSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetCateringReportDetailsByMealTypeFail("Something went wrong, Please try again."));
                return error;
            });
    };
};








