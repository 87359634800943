import React from "react";
import  {ReportPiechart,  ReportPiechart2, ReportPiechart3, ReportPiechart4, ReportPiechart5, ReportPiechart6 } from "./PieCharts";

export default function Reports() {
    return (
        <>
            <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-4">
                    <ReportPiechart />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                    <ReportPiechart2 />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                    <ReportPiechart3 />
                </div>
            </div>
     
            <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-4 mt-5">
                    <ReportPiechart4 />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4 mt-5">
                    <ReportPiechart5 />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4 mt-5">
                    <ReportPiechart6 />
                </div>
            </div>
        </>
    );
}
