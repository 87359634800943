import axios from "axios";
import * as actionType from "../constants/actionTypes";
let qs = require("qs");



/*** CreateOperationalComment Action ***/
export const CreateOperationalCommentStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.CreateOperationalComment_START,
    };
};
export const CreateOperationalCommentSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.CreateOperationalComment_SUCCESS,
    };
};
export const CreateOperationalCommentFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.CreateOperationalComment_FAIL,
    };
};
export const CreateOperationalComment = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(CreateOperationalCommentStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/CreateOperationalComment`,
        })
            .then(function (response) {
                dispatch(CreateOperationalCommentSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateOperationalCommentFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** CreateDiaryPastoralComments Action ***/
export const CreateDiaryPastoralCommentsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.CreateDiaryPastoralComments_START,
    };
};
export const CreateDiaryPastoralCommentsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.CreateDiaryPastoralComments_SUCCESS,
    };
};
export const CreateDiaryPastoralCommentsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.CreateDiaryPastoralComments_FAIL,
    };
};
export const CreateDiaryPastoralComments = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(CreateDiaryPastoralCommentsStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/CreateDiaryPastoralComments`,
        })
            .then(function (response) {
                dispatch(CreateDiaryPastoralCommentsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateDiaryPastoralCommentsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** CreateDiaryMedicalComment Action ***/

export const CreateDiaryMedicalCommentStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.CreateDiaryMedicalComment_START,
    };
};
export const CreateDiaryMedicalCommentSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.CreateDiaryMedicalComment_SUCCESS,
    };
};
export const CreateDiaryMedicalCommentFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.CreateDiaryMedicalComment_FAIL,
    };
};
export const CreateDiaryMedicalComment = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(CreateDiaryMedicalCommentStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/CreateDiaryMedicalComment`,
        })
            .then(function (response) {
                dispatch(CreateDiaryMedicalCommentSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateDiaryMedicalCommentFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** GetAllOperationalComments Action ***/
export const GetAllOperationalCommentsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllOperationalComments_START,
    };
};
export const GetAllOperationalCommentsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllOperationalComments_SUCCESS,
    };
};
export const GetAllOperationalCommentsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllOperationalComments_FAIL,
    };
};
export const GetAllOperationalComments = (page, limit) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllOperationalCommentsStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllOperationalComments`,
            params: {
                page: page,
                limit: limit,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllOperationalCommentsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllOperationalCommentsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** GetAllDiaryPastoralComments Action ***/
export const GetAllDiaryPastoralCommentsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllDiaryPastoralComments_START,
    };
};
export const GetAllDiaryPastoralCommentsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllDiaryPastoralComments_SUCCESS,
    };
};
export const GetAllDiaryPastoralCommentsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllDiaryPastoralComments_FAIL,
    };
};
export const GetAllDiaryPastoralComments = (page, limit) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllDiaryPastoralCommentsStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllDiaryPastoralComments`,
            params: {
                page: page,
                limit: limit,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllDiaryPastoralCommentsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllDiaryPastoralCommentsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** GetAllDiaryMedicalComments Action ***/
export const GetAllDiaryMedicalCommentsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllDiaryMedicalComments_START,
    };
};
export const GetAllDiaryMedicalCommentsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllDiaryMedicalComments_SUCCESS,
    };
};
export const GetAllDiaryMedicalCommentsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllDiaryMedicalComments_FAIL,
    };
};
export const GetAllDiaryMedicalComments = (page, limit) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllDiaryMedicalCommentsStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllDiaryMedicalComments`,
            params: {
                page: page,
                limit: limit,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllDiaryMedicalCommentsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllDiaryMedicalCommentsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};




/*** UpdateDiaryMedicalComments Action ***/
export const UpdateDiaryMedicalCommentsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateDiaryMedicalComments_START,
    };
};
export const UpdateDiaryMedicalCommentsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateDiaryMedicalComments_SUCCESS,
    };
};
export const UpdateDiaryMedicalCommentsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateDiaryMedicalComments_FAIL,
    };
};

export const UpdateDiaryMedicalComments = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(UpdateDiaryMedicalCommentsStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/UpdateDiaryMedicalComments`,
        })
            .then(function (response) {
                dispatch(UpdateDiaryMedicalCommentsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateDiaryMedicalCommentsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** GenerateAllergicReportForDashboard ***/


export const GenerateAllergicReportForDashboardStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GenerateAllergicReportForDashboard_START,
    };
};
export const GenerateAllergicReportForDashboardSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GenerateAllergicReportForDashboard_SUCCESS,
    };
};
export const GenerateAllergicReportForDashboardFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GenerateAllergicReportForDashboard_FAIL,
    };
};

export const GenerateAllergicReportForDashboard = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(GenerateAllergicReportForDashboardStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GenerateAllergicReportForDashboard`,
            params: {},
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GenerateAllergicReportForDashboardSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GenerateAllergicReportForDashboardFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


