import React, { useState, useEffect, useTransition } from "react";
import { Modal } from "react-bootstrap";

export default function RolloverConfirmation({ show, onClose, startTimer, rolloverLastClass }) {
    console.log(rolloverLastClass, "::SDfsdfsdf546>")
    return (
        <React.Fragment>
            <Modal
                backdrop="static"
                keyboard={false}
                className="add_archive_modal confirm_modal"
                show={show}
                onHide={onClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Rollover</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {rolloverLastClass ?
                            "You are rolling over your final year students - this means they are departing your school.  They will be archived and accessible - through archives, on this system by any manager with Permissions to access Archives. Do you wish to continue?"
                            :
                            <p style={{ textAlign: "start" }}>
                                Please review On Campus destinations & Permissions for all age groups as the system has rolled each student in this group, over to the next year level.
                                Note:  Students dormitories have not changed - they have only being progressed to their next year level.  If dorm changes are required - please do this manually, at this stage :-)
                                <br /> <br />
                                Thank you
                                <br />
                                go-liberty team
                            </p>
                        }
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={onClose}>Cancel</button>
                    <button className="btn btn-primary" onClick={startTimer}>Confirm</button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}
