import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";

import Footer from "../Constant/Footer";
import TopBar from "../Constant/TopBar";
import { toast } from "react-toastify";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";


import * as userActions from "../../actions/index";

import ManagerItem from "./ManagerItem";


import DeleteConfirmRemove from "../Utility/DeleteConfirmRemove";
import Sidebar from "../Constant/sidebar";
import AddArchiveModal from "./Modal/AddArchiveModal"
import AddUnarchivedModal from "./Modal/AddUnarchivedModal";
// import Cdate from "../Constant/date";
const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info.school_code;

const permission_info = JSON.parse(localStorage.getItem("permissions"));
const ManagerList = (props) => {

    const dispatch = useDispatch();

    const [user_uuid, setuser_uuid] = useState("");

    const [filterlimit, setfilterlimit] = useState(10);
    const [filterpage, setfilterpage] = useState(1);
    const [filterorder, setfilterorder] = useState("asc");
    const [filtersort, setfiltersort] = useState("first_name");
    const [filtersearch, setfiltersearch] = useState(null);

    const [ManagerList, setManagerList] = useState([]);
    const [ManagerListcount, setManagerListcount] = useState(0);
    const [is_user_activate, setis_user_activate] = useState(true);
    const [userActivate, setUserActivate] = useState(false)

    const [confirm_modal, setconfirm_modal] = useState(false);
    const [editconfirmremovedata, seteditconfirmremovedata] = useState([]);
    const [add_archive_modal, setadd_archive_modal] = useState(false);
    const [add_unarchived_modal, setadd_unarchived_modal] = useState(false);

    const [userName, setUserName] = useState("")

    const navigate = useNavigate();

    const showAddModalHandle = (event) => {
        setconfirm_modal(false);
    };
    const RemoveCourseModule = (event, course_data) => {
        seteditconfirmremovedata(course_data);
        setconfirm_modal(true);
    };

    const AddArchivepoup = async (event, user_name, user_uuid, user_activate) => {
        let user_uuids = user_uuid;
        setUserName(user_name)
        setuser_uuid(user_uuid)
        setUserActivate(user_activate)
        localStorage.setItem('archive_data', user_uuids);
        setadd_archive_modal(true)
    };

    const AddUnarchivepoup = async (event, user_name, user_uuid, user_activate) => {
        let user_uuids = user_uuid;
        setUserName(user_name)
        setuser_uuid(user_uuid)
        setUserActivate(user_activate)
        localStorage.setItem('archive_data', user_uuids);
        setadd_unarchived_modal(true)
    }

    const GetLoginUserPermissions = useCallback(async () => {
        dispatch(userActions.GetLoginUserPermissions()).then((response) => {
            if (response.success === true) {
            }
            else if (response.status === 401) {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
            else {
            }
        });
    }, [dispatch]);
    useEffect(() => {
        GetLoginUserPermissions();
    }, []);


    const GetAllManagersBySchoolId = useCallback(async () => {
        dispatch(userActions.GetAllManagersBySchoolId(filterpage, filterlimit, filtersort, filterorder, is_user_activate, filtersearch)).then((response) => {
            if (response.success === true) {
                const arrayData = response.data.rows;
                let MemberId_level = [];
                const sumvalue = 1;
                for (let key in arrayData) {
                    const perpage = ((parseInt(filterpage) - parseInt(sumvalue)) * parseInt(filterlimit) + parseInt(key) + parseInt(sumvalue));
                    MemberId_level.push({
                        perpage: perpage,
                        first_name: arrayData[key].first_name,
                        last_name: arrayData[key].last_name,
                        email: arrayData[key].email,
                        contact_one: arrayData[key].contact_one,
                        designation: arrayData[key].designation,
                        user_uuid: arrayData[key].user_uuid,
                        is_user_activate: arrayData[key].is_user_activate,

                    });
                }
                setManagerList(arrayData);
                setManagerListcount(response.data.count);
            }
            else if (response.status === 401) {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                setTimeout(() => {
                    navigate('/logout')
                }, 5000);
            }
            else {
            }
        });
    }, [dispatch, filterpage, filterlimit, filtersort, filterorder, is_user_activate, filtersearch]);



    const ExportManagersCsv = (event) => {
        props.onExportManagersCsv(filterpage, filterlimit, filtersort, filterorder, is_user_activate, filtersearch).then((response) => {
            if (response.success === true) {
                const csv_url = response.file;
                DownloadCsv(csv_url);
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    };

    const DownloadCsv = (csv_url) => {
        const link = document.createElement("a");
        link.href = csv_url;
        // link.setAttribute("download");
        document.body.appendChild(link);
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }


    const handleChange = async (event, user_uuid, value) => {
        console.log("hello")
        const Payload = {
            user_uuid: user_uuid,
            is_user_activate: value,
        };
        props.onArchiveOrUnarchiveManager(Payload).then((response) => {
            if (response.success === true) {
                GetAllManagersBySchoolId();
                setfilterpage(1);
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    }


    const ExportManagersPdf = (event) => {
        props.onExportManagersPdf(filterpage, filterlimit, filtersort, filterorder, is_user_activate, filtersearch).then((response) => {
            if (response.success === true) {
                const csv_url = response.file;
                const link = document.createElement("a");
                link.href = csv_url;
                link.target = "_blank";
                link.setAttribute('download', 'manager.pdf');
                document.body.appendChild(link);
                link.click();
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
            else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    };

    const ArchiveOrUnarchiveManager = async (form_data) => {
        props.onArchiveOrUnarchiveManager(form_data).then((response) => {
            if (response.success === true) {
                setadd_archive_modal(false)
                setadd_unarchived_modal(false)
                localStorage.removeItem("archive_data");
                GetAllManagersBySchoolId()
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }

        });
    }

    const showAddModalHandleCountry = async (event) => {
        setadd_archive_modal(false)
        setadd_unarchived_modal(false)
        localStorage.removeItem("archive_data");
    }

    useEffect(() => {
        GetAllManagersBySchoolId();
    }, [GetAllManagersBySchoolId, add_archive_modal, filterpage, filterlimit, filtersort, filterorder, is_user_activate, filtersearch]);


    return (
        <>

            {confirm_modal && (
                <DeleteConfirmRemove
                    title={`Delete School?`}
                    bodytitle="Are you sure you want to delete this Dormitory?"
                    editconfirmremovedata={editconfirmremovedata}
                    confirm_modal={confirm_modal}
                    confirmButtonText="Delete"
                    // ConfirmRemove={(event) => ConfirmRemove(event)}
                    delete_confirm_modal_action={(modal_action) => showAddModalHandle(modal_action)}
                />
            )}

            {add_archive_modal &&
                <AddArchiveModal
                    userUuid={user_uuid}
                    userName={userName}
                    userActivate={userActivate}
                    add_archive_modal={add_archive_modal}
                    ArchiveOrUnarchiveManager={(event) => ArchiveOrUnarchiveManager(event)}
                    add_archive_modal_action={(modal_action) => showAddModalHandleCountry(modal_action)}
                />
            }
            {add_unarchived_modal &&
                <AddUnarchivedModal
                    userUuid={user_uuid}
                    userName={userName}
                    userActivate={userActivate}
                    add_unarchived_modal={add_unarchived_modal}
                    ArchiveOrUnarchiveManager={(event) => ArchiveOrUnarchiveManager(event)}
                    add_unarchive_modal_action={(modal_action) => showAddModalHandleCountry(modal_action)}
                />
            }

            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed" >
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <Sidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid " id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    {/* <!--begin::Container--> */}
                                    <div id="kt_content_container" className="container-fluid">
                                        <div className="tab-content" id="myTabContent">
                                            <div className="tab-pane fade" id="Snapshot" role="tabpanel" aria-labelledby="Snapshot-tab"></div>

                                            <div className="tab-pane fade show active" id="Rollcall" role="tabpanel" aria-labelledby="Rollcall-tab">
                                                <div className="row">
                                                    <div className="col-sm-8">
                                                        <div className="card  tabs-outer">
                                                            <div className="card-header position-relative py-0 details-bg">
                                                                <h2 className="card-title m-0">Manager Detail</h2>
                                                                <div className="card-toolbar">
                                                                    {permission_info?.add_user !== false &&
                                                                        (<Link to={`/${school_code}/manager-list/add-manager`} className="btn btn-sm btn-primary apply-btn add-btns addschool-button">
                                                                            Add Manager
                                                                        </Link>)}
                                                                </div>
                                                            </div>

                                                            <div className="card-body">
                                                                <div className="card-toolbar">

                                                                    <div className="d-flex align-items-center justify-content-between ">

                                                                        {/* <!-- <h4 className="card-title text-gray-800 fw-bolder m-0">Schools</h4> --> */}

                                                                        <div className="d-flex align-items-center position-relative">
                                                                            <span className="svg-icon svg-icon-1 position-absolute ms-4">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                                                                    <path
                                                                                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                                                                        fill="currentColor"
                                                                                    ></path>
                                                                                </svg>
                                                                            </span>
                                                                            <input type="text" className="form-control form-control-sm w-350px ps-12 search-out fs-6" placeholder="Search " style={{ background: "transparent" }}
                                                                                onChange={(event) => {
                                                                                    setfiltersearch(event.target.value);
                                                                                    setfilterpage(1);
                                                                                }}
                                                                            />
                                                                        </div>


                                                                        <div className="card-toolbar">
                                                                            <Dropdown>
                                                                                <Dropdown.Toggle variant="mute" className="p-0 border-0">
                                                                                    <span
                                                                                        className="toggle_button_arrow btn btn-color-gray-400 btn-active-color-primary justify-content-between d-flex export-btn-out "
                                                                                        data-kt-menu-trigger="click"
                                                                                        data-kt-menu-placement="bottom-end"
                                                                                        data-kt-menu-overflow="true"
                                                                                    >
                                                                                        Export
                                                                                        <span className="menu-arrow ms-2">
                                                                                            <i className="fas fa-angle-down"></i>
                                                                                        </span>
                                                                                    </span>
                                                                                </Dropdown.Toggle>
                                                                                <Dropdown.Menu className="p-0">
                                                                                    <Dropdown.Item>
                                                                                        <div className="menu-item">
                                                                                            <span className="menu-link">
                                                                                                <i className="bi bi-file-earmark-pdf me-2"></i>
                                                                                                <span className="fs-8 color1 student_clear_filter" target="_blank" rel="noreferrer" onClick={(event) => ExportManagersPdf(event)}> Export PDF </span>
                                                                                            </span>
                                                                                        </div>
                                                                                    </Dropdown.Item>
                                                                                    <div className="separator"></div>
                                                                                    <Dropdown.Item>
                                                                                        <div className="menu-item">
                                                                                            <span className="menu-link">
                                                                                                <i className="bi bi-file-earmark-excel me-2"></i>
                                                                                                <span className="fs-8 color1 student_clear_filter" target="_blank" onClick={(event) => ExportManagersCsv(event)}>Export Excel</span>
                                                                                                {/* Export Excel */}
                                                                                            </span>
                                                                                        </div>
                                                                                    </Dropdown.Item>
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-sm-12">
                                                                            <ManagerItem
                                                                                ManagerListcount={ManagerListcount}
                                                                                data={ManagerList}
                                                                                filterlimit={filterlimit}
                                                                                filterpage={filterpage}
                                                                                RemoveCourseModule={(event, data) => RemoveCourseModule(event, data)}
                                                                                filtersort={filtersort}
                                                                                filterorder={filterorder}
                                                                                propsfiltersort={(field) => setfiltersort(field)}
                                                                                propsfilterorder={(order) => setfilterorder(order)}
                                                                                propsfilterpage={(event) => setfilterpage(event)}
                                                                                propsfiltersearch={(event) => setfiltersearch(event)}
                                                                                AddArchivepoup={(event, user_name, user_uuid, user_activate) => AddArchivepoup(event, user_name, user_uuid, user_activate)}
                                                                                AddUnarchivepoup={(event, user_name, user_uuid, user_activate) => AddUnarchivepoup(event, user_name, user_uuid, user_activate)}
                                                                                propsfilterlimit={(filterlimit) => {
                                                                                    setfilterlimit(filterlimit);
                                                                                }}
                                                                                handleChange={(event, user_uuid, value) => handleChange(event, user_uuid, value)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="col-sm-4">
                                                        <div className="card ">
                                                            <div className="card-header border-bottom border-bottom-1">
                                                                <h3 className="card-title align-items-start flex-column">
                                                                    <span className="card-label fw-bold text-dark">Filters</span>
                                                                </h3>
                                                            </div>
                                                            <div className="card-body p-6">
                                                                <div className="d-flex flex-column">
                                                                    <h5>Sort By</h5>
                                                                    <div className="d-flex">
                                                                        <button
                                                                            className={"filter-badge me-2 filter-button " +
                                                                                (filtersort === "first_name" ? "btn btn-primary active-filter " : "btn btn-dark")
                                                                            }
                                                                            onClick={(event) => {
                                                                                if (filterorder === 'desc') {
                                                                                    setfilterorder('asc');
                                                                                }
                                                                                else {
                                                                                    setfilterorder('desc');
                                                                                }
                                                                                setfiltersort('first_name');
                                                                                setfilterpage(1);

                                                                            }}
                                                                        >
                                                                            Name
                                                                        </button>
                                                                        <button
                                                                            className={"filter-badge me-2 filter-button " +
                                                                                (filtersort === "created_date" ? "btn btn-primary active-filter " : "btn btn-dark")
                                                                            }

                                                                            onClick={(event) => {
                                                                                if (filterorder === 'desc') {
                                                                                    setfilterorder('asc');
                                                                                }
                                                                                else {
                                                                                    setfilterorder('desc');
                                                                                }
                                                                                setfiltersort('created_date');
                                                                                setfilterpage(1);
                                                                            }}
                                                                        >
                                                                            Date Added
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex flex-column mt-6">
                                                                    <h5>Show</h5>
                                                                    <div className="d-flex align-items-center">
                                                                        <span
                                                                            className={"filter-badge me-2 filter-button " +
                                                                                (is_user_activate === "" ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                            }
                                                                            onClick={(event) => {
                                                                                setis_user_activate("");
                                                                                setfilterpage(1);

                                                                            }}
                                                                        >
                                                                            All
                                                                        </span>
                                                                        <span
                                                                            className={"filter-badge me-2 filter-button " +
                                                                                (is_user_activate === true ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                            }
                                                                            onClick={(event) => {
                                                                                setis_user_activate(true);
                                                                                setfilterpage(1);

                                                                            }}
                                                                        >
                                                                            Active
                                                                        </span>
                                                                        <span
                                                                            className={"filter-badge me-2 filter-button " +
                                                                                (is_user_activate === false ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                            }
                                                                            onClick={(event) => {
                                                                                setis_user_activate(false);
                                                                                setfilterpage(1);

                                                                            }}
                                                                        >
                                                                            Archived
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!--end::Container--> */}
                                </div>
                            </div>
                            <Footer></Footer>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {

    onExportManagersCsv: userActions.ExportManagersCsv,
    onExportManagersPdf: userActions.ExportManagersPdf,
    onArchiveOrUnarchiveManager: userActions.ArchiveOrUnarchiveManager,



};
export default connect(mapStateToProps, mapDispatchToProps)(ManagerList);