import React, { useState, useEffect, useCallback } from "react";
import Sidebar from "../../Constant/sidebar";
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as userActions from "../../../actions/index";
import Select from "react-select";
import ChangeDormitoryItem from "./ChangeDormitoryItem"

var domirtory_data = []

const ChangeDormitory = (props) => {
    const dispatch = useDispatch();

    const [isChecked, setisChecked] = useState(false);
    const [uuid, setuuid] = useState([]);
    const [dormitory_id_to, setdormitory_id_to] = useState([]);
    const [dormitory_id_tos, setdormitory_id_tos] = useState();
    const [dormitory_id_froms, setdormitory_id_froms] = useState("");

    const [filterlimit, setfilterlimit] = useState(10);
    const [filterpage, setfilterpage] = useState(1);
    const [filterorder, setfilterorder] = useState("asc");
    const [filtersort, setfiltersort] = useState("student_first_name");
    const [filtersearch, setfiltersearch] = useState(null);
    const [ChangeDormitory, setChangeDormitory] = useState([]);
    const [ChangeDormitorycount, setChangeDormitorycount] = useState(0);

    const [dormitory_id_from, setdormitory_id_from] = useState([])
    const [AllChecked, setAllChecked] = useState(true)


    const [data, setData] = useState([])
    const [data2, setData2] = useState([])

    const navigate = useNavigate();

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            "&:hover": {
                backgroundColor: state.isFocused ? "#e7f5fe" : "",
            },
            backgroundColor: state.isSelected ? "#4a8fb8" : "",
        }),
    };

    const GetAllDormitoryStudent = useCallback(() => {
        dispatch(userActions.GetAllDormitoryStudent()).then((response) => {
            console.log("response===========", response);
            if (response.success === true) {
                let state_data = [];
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    if (arrayData[key].dormitory_status === true) {
                        state_data.push({
                            label: arrayData[key].dormitory_name,
                            value: arrayData[key].dormitory_id,
                            dormitory_id: arrayData[key].dormitory_id,
                            dormitory_type: arrayData[key].dormitory_type

                        });
                    }
                }
                setdormitory_id_to(state_data);
            } else {
                //notify.show(response.message, "error");
            }
        });
    }, [dispatch]);



    const GetStudentsByDormitoryId = useCallback(async () => {
        dispatch(userActions.GetStudentsByDormitoryId(filterpage, filterlimit, filtersort, filterorder, filtersearch, dormitory_id_tos)).then((response) => {
            if (response.success === true) {
                const arrayData = response.data.rows.map((item) => {
                    return { ...item, checked: false }
                });
                setChangeDormitory(arrayData)
                domirtory_data = arrayData
                setChangeDormitorycount(response.data.count);
            } else if (response.status === 401) {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                setTimeout(() => {
                    navigate('/logout')

                }, 5000);
            }
            else {
            }
        });
        // eslint-disable-next-line
    }, [dispatch, filterpage, filterlimit, filtersort, filterorder, filtersearch, dormitory_id_tos]);

    useEffect(() => {
        GetStudentsByDormitoryId();
        GetAllDormitoryStudent();

    }, [filterpage, filterlimit, filtersort, filterorder, filtersearch, dormitory_id_tos]);


    const handleChangeDormitory = (event) => {
        const Payload = {
            dormitory_id: dormitory_id_froms,
            students_uuid_data: JSON.stringify(uuid),
        };
        if (uuid.length > 0) {
            props.onChangeStudentsDormitory(Payload).then((response) => {
                if (response.success === true) {
                    GetStudentsByDormitoryId();
                    setfilterpage(1);
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                } else {
                    console.log("select chnge Dormitory", dormitory_id_froms);
                    if (dormitory_id_froms === "") {
                        toast.error("select To Dormitory", {
                            position: toast.POSITION.TOP_CENTER,
                        });
                        GetStudentsByDormitoryId();
                    } else {
                        toast.error(response.message, {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            });

            setAllChecked(true)
            setuuid([])
        } else {
            toast.error("select student", {
                position: toast.POSITION.TOP_CENTER,
            });
        }

    }


    const selectDormitoryTo = (event) => {
        setdormitory_id_tos(event.value);
        let filter_dromitory_from = []
        if (event.dormitory_type === "boy") {
            dormitory_id_to.forEach((ele) => {
                if((ele.dormitory_type === "boy" || ele.dormitory_type === "both") && event.value !== ele.value){
                    filter_dromitory_from.push(ele)
                }
            })
        } else if (event.dormitory_type === "girl") {
            dormitory_id_to.forEach((ele) => {
                if((ele.dormitory_type === "girl" || ele.dormitory_type === "both") && event.value !== ele.value){
                    filter_dromitory_from.push(ele)
                }
            })
        } else {
            dormitory_id_to.forEach((ele) => {
                if(event.value !== ele.value){
                    filter_dromitory_from.push(ele)
                }
            })
        }
        // let filter_dromitory_from = dormitory_id_to.filter(element => element.value !== event.value)
        setdormitory_id_from(filter_dromitory_from)
    }

    const handleClick = (event, index, row) => {
        if (event.target.checked === true) {
            let filter_domirtory_data = domirtory_data.map((item) => {
                if (item.student_uuid === row.student_uuid) {
                    return { ...item, checked: true }
                } else {
                    return item
                }
            });
            setChangeDormitory(filter_domirtory_data)
            domirtory_data = filter_domirtory_data
        } else {
            let filter_domirtory_data = domirtory_data.map((item) => {
                if (item.student_uuid === row.student_uuid) {
                    return { ...item, checked: false }
                } else {
                    return item
                }
            });
            setChangeDormitory(filter_domirtory_data)
            domirtory_data = filter_domirtory_data
        }
        let selected_uuid = []
        domirtory_data.forEach((items) => {
            if (items.checked === true) {
                selected_uuid.push(items.student_uuid)
            }
        })
        setuuid(selected_uuid)
    };


    const handleAllChecked = (event, indendifire) => {
        console.log("sADAsdfasfasd", ChangeDormitory);
        const uuid_all = []
        if (indendifire === "Select_All") {
            let dormitoryCheckBox = ChangeDormitory.map(item => {
                return { ...item, checked: true }
            })
            for (let i = 0; i <= ChangeDormitory.length - 1; i++) {
                uuid_all.push(ChangeDormitory[i].student_uuid)
            }
            setChangeDormitory(dormitoryCheckBox)
            domirtory_data = dormitoryCheckBox
            setAllChecked(false)
        } else {
            let dormitoryCheckBox = ChangeDormitory.map(item => {
                return { ...item, checked: false }
            })
            for (let i = 0; i <= ChangeDormitory.length - 1; i++) {
                uuid_all.pop()
            }
            setChangeDormitory(dormitoryCheckBox)
            domirtory_data = dormitoryCheckBox
            setAllChecked(true)
        }
        setuuid(uuid_all);
    }

    return (
        <>
            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed" >
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <Sidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid " id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">


                                    <div id="kt_content_container" className="container-fluid">
                                        <div className="tab-content" id="myTabContent">
                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <div className="card">
                                                        <div className="card-header align-items-center">
                                                            <h2 className="card-title">Change Dormitory</h2>
                                                            {AllChecked ?
                                                                <span className=" btn btn-sm btn-primary apply-btn add-btns me-2" onClick={(event) => handleAllChecked(event, "Select_All")}>Select All</span>
                                                                :
                                                                <span className=" btn btn-sm btn-primary apply-btn add-btns me-2" onClick={(event) => handleAllChecked(event, "Unselect_All")}>Unselect All</span>
                                                            }
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row mb-6     align-items-end">

                                                                <div className="col col-lg-4 fv-row">
                                                                    <label className="mb-2">From</label>
                                                                    <Select
                                                                        placeholder="Select Dormitory"
                                                                        id="to_dormitory"
                                                                        styles={customStyles}
                                                                        className="react-bootstrap-typeahead tag-outer text-dark "
                                                                        options={dormitory_id_to}
                                                                        value={dormitory_id_to.filter(function (option) {
                                                                            return option.value === dormitory_id_tos;
                                                                        })}
                                                                        onChange={(event) => {
                                                                            selectDormitoryTo(event)
                                                                        }}
                                                                    />
                                                                </div>

                                                                <div className="col col-lg-4">
                                                                    <div className="d-flex align-items-center position-relative">
                                                                        <span className="svg-icon svg-icon-1 position-absolute ms-4">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                                                                <path
                                                                                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                                                                    fill="currentColor"
                                                                                ></path>
                                                                            </svg>
                                                                        </span>

                                                                        <input type="text" className="form-control form-control-sm w-350px ps-12 search-out fs-6" placeholder="Search " style={{ background: "transparent" }}
                                                                            onChange={(event) => {
                                                                                setfiltersearch(event.target.value);
                                                                                setfilterpage(1);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <ChangeDormitoryItem
                                                                // AllChecked={AllChecked}
                                                                ChangeDormitorycount={ChangeDormitorycount}
                                                                data={ChangeDormitory}
                                                                filterlimit={filterlimit}
                                                                filterpage={filterpage}
                                                                isChecked={isChecked}
                                                                propsisChecked={(event) => setisChecked(event)}
                                                                propsfilterpage={(event) => setfilterpage(event)}
                                                                handleClick={(event, index, row) => handleClick(event, index, row)}
                                                                propsfilterlimit={(filterlimit) => {
                                                                    setfilterlimit(filterlimit);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-4">
                                                    <div className="card mb-5">
                                                        <div className="card-header border-bottom border-bottom-1 p-6">

                                                            <h3 className="mb-0 align-items-center">Filters</h3>
                                                        </div>
                                                        <div className="card-body p-6">
                                                            <div className="d-flex flex-column">
                                                                <h5>Sort By</h5>
                                                                <div className="d-flex">
                                                                    <button className=
                                                                        {"filter-badge me-2 filter-button " +
                                                                            (filtersort === "student_first_name" ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                        }
                                                                        onClick={(event) => {
                                                                            if (filterorder === 'desc') {
                                                                                setfilterorder('asc');
                                                                            }
                                                                            else {
                                                                                setfilterorder('desc');
                                                                            }
                                                                            setfiltersort('student_first_name');
                                                                            setfilterpage(1);
                                                                        }}
                                                                    >Name</button>
                                                                    <button className=
                                                                        {"filter-badge me-2 filter-button " +
                                                                            (filtersort === "class_id" ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                        }
                                                                        onClick={(event) => {
                                                                            if (filterorder === 'desc') {
                                                                                setfilterorder('asc');
                                                                            }
                                                                            else {
                                                                                setfilterorder('desc');
                                                                            }
                                                                            setfiltersort('class_id');
                                                                            setfilterpage(1);

                                                                        }}
                                                                    >Class</button>

                                                                    <button className=

                                                                        {"filter-badge me-2 filter-button " +
                                                                            (filtersort === "student_id" ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                        }
                                                                        onClick={(event) => {
                                                                            if (filterorder === 'desc') {
                                                                                setfilterorder('asc');
                                                                            }
                                                                            else {
                                                                                setfilterorder('desc');
                                                                            }
                                                                            setfiltersort('student_id');
                                                                            setfilterpage(1);
                                                                        }}
                                                                    >Student Id</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <form>
                                                        <div className="card">
                                                            <div className="card-body p-6">
                                                                <div className="row">
                                                                    <div className="col-lg-12 fv-row">
                                                                        <label className="fw-bold mb-2">Change Dormitory </label>
                                                                        <div className=" mb-6">
                                                                            {/* <div className="col-lg-12 fv-row mb-5">
                                                                                <label>From</label>
                                                                                <Select
                                                                                    placeholder="Select Dormitory"
                                                                                    id="to_dormitory"
                                                                                    styles={customStyles}
                                                                                    className="react-bootstrap-typeahead tag-outer text-dark"
                                                                                    options={dormitory_id_to}
                                                                                    value={dormitory_id_to.filter(function (option) {
                                                                                        return option.value === dormitory_id_tos;
                                                                                    })}
                                                                                    onChange={(event) => {
                                                                                        selectDormitoryTo(event)
                                                                                    }}
                                                                                />
                                                                            </div> */}
                                                                            <div className="mb-6">
                                                                                <label>To</label>
                                                                                <Select
                                                                                    styles={customStyles}
                                                                                    className="react-bootstrap-typeahead tag-outer"
                                                                                    options={dormitory_id_from}
                                                                                    value={dormitory_id_to.filter(function (option) {
                                                                                        return option.value === dormitory_id_froms;
                                                                                    })}
                                                                                    onChange={(event) => {
                                                                                        setdormitory_id_froms(event.value);
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <span className="btn btn-sm btn-primary student-search"
                                                                            onClick={() => {
                                                                                handleChangeDormitory();
                                                                            }}>Submit</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {

    onGetAllDormitoryStudent: userActions.GetAllDormitoryStudent,
    onChangeStudentsDormitory: userActions.ChangeStudentsDormitory,

};
export default connect(mapStateToProps, mapDispatchToProps)(ChangeDormitory);