import React from "react";
import Select from "react-select";
import Validations from "../../Utility/Validations";
import Sidebar from "../../Constant/sidebar";
import Footer from "../../Constant/Footer";
import TopBar from "../../Constant/TopBar";


import { connect } from "react-redux";
import * as userActions from "../../../actions/index";
// eslint-disable-next-line
import { Navigate } from "react-router-dom";


import { toast } from "react-toastify";

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#e7f5fe" : "",
        },
        backgroundColor: state.isSelected ? "#4a8fb8" : "",
    }),
};
const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info?.school_code;

class AddGenericStudent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            add_generic_student_form: {
                student_id: {
                    type: "select",
                    label: "Select student Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Student Name",
                    validations: { required: true },
                    options: [],
                },

                student_name: {
                    type: "text",
                    label: "Select student Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Student Name",
                    validations: { required: true },

                },

                generic_start_date: {
                    type: "date",
                    label: "Start Date",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },

                generic_end_date: {
                    type: "date",
                    label: "End Date",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },

                generic_desc: {
                    type: "text",
                    label: "Generic Description *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
            },


            class_id: {
                type: "select",
                label: "Select class",
                value: "",
                valid: true,
                error_msg: "",
                onBlur_out: false,
                placeholder: "City",
                validations: { required: false },
                options: [],
            },

            dormitory_id: {
                type: "select",
                label: "Select Dormitory",
                value: "",
                valid: true,
                error_msg: "",
                onBlur_out: false,
                placeholder: "Select Dormitory *",
                validations: { required: false },
                options: [],
            },

            gender: {
                type: "radio",
                label: "Select Gender",
                value: "",
                valid: true,
                error_msg: "",
                onBlur_out: false,
                validations: { required: false },
            },

            student_age: {
                type: "select",
                label: "Select Age",
                value: "",
                valid: true,
                error_msg: "",
                onBlur_out: false,
                placeholder: "",
                validations: { required: false },
                options: [
                    { label: "Select Age", value: "" },
                    { label: "1", value: "1" },
                    { label: "2", value: "2" },
                    { label: "3", value: "3" },
                    { label: "4", value: "4" },
                    { label: "5", value: "5" },
                    { label: "6", value: "6" },
                    { label: "7", value: "7" },
                    { label: "8", value: "8" },
                    { label: "9", value: "9" },
                    { label: "10", value: "10" },
                    { label: "11", value: "11" },
                    { label: "12", value: "12" },
                    { label: "13", value: "13" },
                    { label: "14", value: "14" },
                    { label: "15", value: "15" },
                    { label: "16", value: "16" },
                    { label: "17", value: "17" },
                    { label: "18", value: "18" },
                    { label: "19", value: "19" },
                    { label: "20", value: "20" },
                    { label: "21", value: "21" },
                    { label: "22", value: "22" },
                    { label: "23", value: "23" },
                    { label: "24", value: "24" },
                    { label: "25", value: "25" },
                    { label: "26", value: "26" },
                    { label: "27", value: "27" },
                    { label: "28", value: "28" },
                    { label: "29", value: "29" },
                    { label: "30", value: "30" },
                ]
            },
        };
    }

    componentDidMount() {
        this.GetAllStudentsGeneric("", "", "", "");
        this.GetAllDormitoryStudent();
        this.GetLoginUserPermissions();
        this.GetAllClassesDetailsStudent();

    }
    GetLoginUserPermissions() {
        this.props.onGetLoginUserPermissions().then((response) => {
            if (response.success === true) {
            } else {
            }
        });
    }

    GetAllStudentsGeneric = async (class_id, dormitory_id, student_age, gender) => {
        this.props.onGetAllStudentsGeneric(class_id, dormitory_id, student_age, gender).then((response) => {
            let country_level = [];
            country_level.push({
                label: "Select Student",
                value: "",
            });
            const update_add_generic_student_form = { ...this.state.add_generic_student_form };
            if (response.success === true) {

                const arrayData = response.data.rows;
                console.log(arrayData);
                for (let key in arrayData) {
                    country_level.push({
                        label: arrayData[key].student_first_name + " " + arrayData[key].student_last_name,
                        value: arrayData[key].student_id,
                        student_id: arrayData[key].student_id,
                    });
                }
            }
            update_add_generic_student_form['student_id'].options = country_level;
            this.setState({ add_generic_student_form: update_add_generic_student_form });
        });
    }

    inputChangeHandlerCheckbox(event, identifier) {
        const update_add_generic_student_form = { ...this.state.add_generic_student_form };
        const form_element = { ...update_add_generic_student_form[identifier] };

        if (form_element.type === "checkbox") {
            console.log("=====", event.target.checked);
            form_element.value = event.target.checked;
        } else {
            form_element.value = event.target.value;
        }

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_add_generic_student_form[identifier] = form_element;

        this.setState({ add_generic_student_form: update_add_generic_student_form });
    }

    inputChangeHandler(event, identifier) {    
        let value = event.target.value.substring(0,70)
        const update_add_generic_student_form = { ...this.state.add_generic_student_form };
        const form_element = { ...update_add_generic_student_form[identifier] };
        form_element.value = value;
        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_add_generic_student_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_add_generic_student_form) {
            is_form_valid = update_add_generic_student_form[identifier].valid && is_form_valid;
        }
        this.setState({
            add_generic_student_form: update_add_generic_student_form,
            is_form_valid: is_form_valid,
        });
        
    }

    handleChange(data, identifier, type) {
        const update_add_generic_student_form = { ...this.state.add_generic_student_form };
        update_add_generic_student_form[identifier].value = data.value;

        update_add_generic_student_form.student_id.options.forEach((item) => {
            if (item.value === data.value) {
                update_add_generic_student_form["student_name"].value = item.label;

            }

        })



        this.setState({ add_generic_student_form: update_add_generic_student_form });
    }

    SubmitHandler = async (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_add_generic_student_form = this.state.add_generic_student_form;
        for (let key in update_add_generic_student_form) {
            let form_element = update_add_generic_student_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_add_generic_student_form[key] = form_element;
        }
        this.setState({ add_generic_student_form: update_add_generic_student_form });
        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.add_generic_student_form) {
                form_data[key] = this.state.add_generic_student_form[key].value;
            }
            this.props.onCreateStudentGeneric(form_data).then((response) => {
                if (response.success === true) {
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: `/${school_code}/student/flag-student`,
                        });
                    }, 1000);
                } else {
                    console.log("error");
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }

            });
        }
    };


    GetAllDormitoryStudent = async (form_data) => {
        this.props.onGetAllDormitoryStudent().then((response) => {
            let country_level = [];
            country_level.push({
                label: "Select Dormitory",
                value: "",
            });
            const update_dormitory_id = { ...this.state.dormitory_id };
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    country_level.push({
                        label: arrayData[key].dormitory_name,
                        value: arrayData[key].dormitory_id,
                    });
                }
            }
            update_dormitory_id.options = country_level;
            this.setState({ dormitory_id: update_dormitory_id });
        });
    }

    GetAllClassesDetailsStudent = async (form_data) => {
        this.props.onGetAllClassesDetailsStudent().then((response) => {
            let country_level = [];
            country_level.push({
                label: "Select Class",
                value: "",
            });
            const update_class_id = { ...this.state.class_id };
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    country_level.push({
                        label: arrayData[key].class_name,
                        value: arrayData[key].class_id,
                        class_name: arrayData[key].class_name,

                    });
                }
            }
            update_class_id.options = country_level;
            this.setState({ class_id: update_class_id });
        });
    }


    inputChangeHandlerSearch(event, identifier) {
        const update_student_search_form = { ...this.state.student_search_form };
        const form_element = { ...update_student_search_form[identifier] };
        form_element.value = event.target.value;
        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_student_search_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_student_search_form) {
            is_form_valid = update_student_search_form[identifier].valid && is_form_valid;
        }
        this.setState({
            student_search_form: update_student_search_form,
            is_form_valid: is_form_valid,
        });
    }

    inputChangeHandlerSearch(event, identifier) {
        const update_gender = { ...this.state.gender };
        update_gender.value = event.target.value;
        this.setState({ gender: update_gender });
        this.GetAllStudentsGeneric(this.state.class_id.value, this.state.dormitory_id.value, this.state.student_age.value, event.target.value);

    }
    handleChangeSearch(data, identifier, type) {
        if (identifier === 'class_id') {
            const update_class_id = { ...this.state.class_id };
            update_class_id.value = data.value
            this.setState({ class_id: update_class_id });
            this.GetAllStudentsGeneric(data.value, this.state.dormitory_id.value, this.state.student_age.value, this.state.gender.value);
        }
        if (identifier === 'dormitory_id') {
            const update_dormitory_id = { ...this.state.dormitory_id };
            update_dormitory_id.value = data.value
            this.setState({ dormitory_id: update_dormitory_id });
            this.GetAllStudentsGeneric(this.state.class_id.value, data.value, this.state.student_age.value, this.state.gender.value);
        }
        if (identifier === 'student_age') {
            const update_student_age = { ...this.state.student_age };
            update_student_age.value = data.value
            this.setState({ student_age: update_student_age });
            this.GetAllStudentsGeneric(this.state.class_id.value, this.state.dormitory_id.value, data.value, this.state.gender.value);
        }
    }
    clearSearch() {
        const update_class_id = { ...this.state.class_id };
        update_class_id.value = ""
        this.setState({ class_id: update_class_id });

        const update_dormitory_id = { ...this.state.dormitory_id };
        update_dormitory_id.value = ""
        this.setState({ dormitory_id: update_dormitory_id });

        const update_gender = { ...this.state.gender };
        update_gender.value = "";
        this.setState({ gender: update_gender });

        const update_student_age = { ...this.state.student_age };
        update_student_age.value = ""
        this.setState({ student_age: update_student_age });
        
        this.GetAllStudentsGeneric("", "", "", "");
    }






    render() {
        const { add_generic_student_form } = this.state;
        const { class_id, dormitory_id, student_age, gender } = this.state;

        return (
            <React.Fragment>
                {this.state.isAuthenticated ? <Navigate to={this.state.redirect_url} /> : null}

                <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
                    <div className="d-flex flex-column flex-root">
                        <div className="page d-flex flex-row flex-column-fluid">
                            <Sidebar />
                            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                                <TopBar />
                                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                    <div className="post d-flex flex-column-fluid" id="kt_post">
                                        <div id="kt_content_container" className="container-fluid">
                                            <div className="school-edit-form">
                                                <div className="" id="Snapshot">
                                  
                                                        <h2 className="card-title  mt-0 mb-5">Add Student Reminder</h2>
                                                        <div className="row">


                                                            <div className="col-sm-8">
                                                                <form onSubmit={this.SubmitHandler}>
                                                                    <div className="card mb-5 tabs-outer">
                                                                        <div className="card-header position-relative py-0 details-bg">
                                                                            <h2 className="card-title  m-0">Student Details</h2>
                                                                        </div>

                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-lg-4 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{add_generic_student_form.student_id.label}</label>
                                                                                    <Select
                                                                                        styles={customStyles}
                                                                                        className="react-bootstrap-typeahead tag-outer"
                                                                                        options={add_generic_student_form.student_id.options}
                                                                                        value={add_generic_student_form.student_id.options.filter(function (option) {
                                                                                            return option.value === add_generic_student_form.student_id.value;
                                                                                        })}
                                                                                        onChange={(newValue) => this.handleChange(newValue, "student_id", "weekend_day from value")}
                                                                                    />

                                                                                    {!add_generic_student_form.student_id.valid && add_generic_student_form.student_id.onBlur_out ? (
                                                                                        <div className="error field-error">{add_generic_student_form.student_id.error_msg}</div>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </div>

                                                                                <div className="col-lg-4 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">Select start Date *</label>
                                                                                    <input
                                                                                        type={add_generic_student_form.generic_start_date.type}
                                                                                        name="fname"
                                                                                        className="form-control time-input"
                                                                                        placeholder="13:30"
                                                                                        onChange={(event) => this.inputChangeHandler(event, "generic_start_date")}
                                                                                    />
                                                                                    {!add_generic_student_form.generic_start_date.valid && add_generic_student_form.generic_start_date.onBlur_out ? (
                                                                                        <div className="error field-error">{add_generic_student_form.generic_start_date.error_msg}</div>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </div>



                                                                                <div className="col-lg-4 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">Select end Date *</label>
                                                                                    <input
                                                                                        type={add_generic_student_form.generic_end_date.type}
                                                                                        name="fname"
                                                                                        className="form-control time-input"
                                                                                        placeholder="13:30"
                                                                                        onChange={(event) => this.inputChangeHandler(event, "generic_end_date")}
                                                                                    />
                                                                                    {!add_generic_student_form.generic_end_date.valid && add_generic_student_form.generic_end_date.onBlur_out ? (
                                                                                        <div className="error field-error">{add_generic_student_form.generic_end_date.error_msg}</div>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </div>

                                                                                <div className="col-lg-12 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">Description *</label>
                                                                                    <textarea placeholder="" className="form-control notes-main mb-2"
                                                                                        value={add_generic_student_form.generic_desc.value}
                                                                                        onChange={(event) => this.inputChangeHandler(event, "generic_desc")} 
                                                                                    />
                                                                                    <span className="countLength">Max 70 Characters: {add_generic_student_form.generic_desc.value?.length}</span>
                                                                                    {!add_generic_student_form.generic_desc.value && add_generic_student_form.generic_desc.onBlur_out ? (
                                                                                        <div className="error field-error">{add_generic_student_form.generic_desc.error_msg}</div>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                     
                                                                        
                                                                    <div className="col-sm-12 d-flex justify-content-end">
                                                                        <div className="d-flex align-items-end">
                                                                            <button type="submit" className="btn btn-sm btn-primary apply-btn min-150px">
                                                                                Save Details
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                    </div>

                                                                </form>
                                                            </div>



                                                            <div className="col-sm-4">
                                                     
                                                                    <div className="card  mb-xl-10 tabs-outer">

                                                                        <div className="card-header border-bottom border-bottom-1 align-items-center">
                                                                            <h3 className="card-title align-items-start flex-column">
                                                                                <span className="card-label fw-bold text-dark">Student Search</span>
                                                                            </h3>
                                                                            <button onClick={() => this.clearSearch()} type="reset" className="clear-btn fs-6 pointer btn btn-link btn-color-info btn-active-color-primary">Clear Search</button>
                                                                        </div>

                                                                        <div className="card-body add-new-schools p-6">
                                                                            <div className="row">
                                                                                <div className="col-lg-12 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{class_id.label}</label>
                                                                                    <Select
                                                                                        styles={customStyles}
                                                                                        className="react-bootstrap-typeahead tag-outer"
                                                                                        options={class_id.options}
                                                                                        value={class_id.options.filter(function (option) {
                                                                                            return option.value === class_id.value;
                                                                                        })}
                                                                                        onChange={(newValue) => this.handleChangeSearch(newValue, "class_id")}
                                                                                    />
                                                                                </div>

                                                                                <div className="col-lg-12 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{dormitory_id.label}</label>
                                                                                    <Select
                                                                                        styles={customStyles}
                                                                                        className="react-bootstrap-typeahead tag-outer"
                                                                                        options={dormitory_id.options}
                                                                                        value={dormitory_id.options.filter(function (option) {
                                                                                            return option.value === dormitory_id.value;
                                                                                        })}
                                                                                        onChange={(newValue) => this.handleChangeSearch(newValue, "dormitory_id")}
                                                                                    />
                                                                                </div>


                                                                                <div className="col-lg-12 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{gender.label}</label>
                                                                                    <div className="d-flex">
                                                                                        <label className="cursor-pointer">
                                                                                            <input type="radio" id="gender" name="gen" className="form-check-input me-2 cursor-pointer" value="male" onChange={(event) => this.inputChangeHandlerSearch(event, "gender")} checked={gender.value === "male" ? true : false}/>
                                                                                            Male
                                                                                        </label>

                                                                                        <label className="ms-8 cursor-pointer">
                                                                                            <input type="radio" id="gender1" name="gen" className="form-check-input me-2 cursor-pointer" value="female" onChange={(event) => this.inputChangeHandlerSearch(event, "gender")} checked={gender.value === "female" ? true : false}/>
                                                                                            Female
                                                                                        </label>

                                                                                        <label className="ms-8 cursor-pointer">
                                                                                            <input type="radio" id="gender2" name="gen" className="form-check-input me-2 cursor-pointer" value="" onChange={(event) => this.inputChangeHandlerSearch(event, "gender")} checked={gender.value === "" ? true : false} />
                                                                                            All
                                                                                        </label>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-lg-12 fv-row">
                                                                                    <label className="fw-bold mb-2">{student_age.label}</label>
                                                                                    <Select
                                                                                        styles={customStyles}
                                                                                        className="react-bootstrap-typeahead tag-outer"
                                                                                        options={student_age.options}
                                                                                        value={student_age.options.filter(function (option) {
                                                                                            return option.value === student_age.value;
                                                                                        })}
                                                                                        onChange={(newValue) => this.handleChangeSearch(newValue, "student_age")}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                              
                                                            </div>
                                                        </div>
                                                    </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Footer />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}
const mapDispatchToProps = {

    onCreateStudentGeneric: userActions.CreateStudentGeneric,
    onGetAllStudentsGeneric: userActions.GetAllStudentsGeneric,
    // onGetAllManagersBySchoolIdStudent: userActions.GetAllManagersBySchoolIdStudent,
    onGetAllDormitoryStudent: userActions.GetAllDormitoryStudent,
    onGetAllClassesDetailsStudent: userActions.GetAllClassesDetailsStudent,
    onGetLoginUserPermissions: userActions.GetLoginUserPermissions,


};
export default connect(mapStateToProps, mapDispatchToProps)(AddGenericStudent);
