import axios from "axios";
import * as actionType from "../constants/actionTypes";
let qs = require("qs");


/*** GetWeeklyAbsentReasonForChart Action ***/

export const GetWeeklyAbsentReasonForChartStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetWeeklyAbsentReasonForChart_START,
    };
};
export const GetWeeklyAbsentReasonForChartSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetWeeklyAbsentReasonForChart_SUCCESS,
    };
};
export const GetWeeklyAbsentReasonForChartFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetWeeklyAbsentReasonForChart_FAIL,
    };
};
export const GetWeeklyAbsentReasonForChart = () => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetWeeklyAbsentReasonForChartStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetWeeklyAbsentReasonForChart`,
            params: {},
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetWeeklyAbsentReasonForChartSuccess(response.data));
                return response.data;
                console.log("CHECK____------")
            })
            .catch(function (error) {
                dispatch(GetWeeklyAbsentReasonForChartFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** GetWeeklyAttendanceCountForChart Action ***/

export const GetWeeklyAttendanceCountForChartStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetWeeklyAttendanceCountForChart_START,
    };
};
export const GetWeeklyAttendanceCountForChartSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetWeeklyAttendanceCountForChart_SUCCESS,
    };
};
export const GetWeeklyAttendanceCountForChartFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetWeeklyAttendanceCountForChart_FAIL,
    };
};
export const GetWeeklyAttendanceCountForChart = () => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetWeeklyAttendanceCountForChartStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetWeeklyAttendanceCountForChart`,
            params: {},
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetWeeklyAttendanceCountForChartSuccess(response.data));
                return response.data;
                
            })
            .catch(function (error) {
                dispatch(GetWeeklyAttendanceCountForChartFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetWeeklyAbsentReasonForChart Action ***/

export const GetMonthlyAbsentReasonForChartStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetMonthlyAbsentReasonForChart_START,
    };
};
export const GetMonthlyAbsentReasonForChartSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetMonthlyAbsentReasonForChart_SUCCESS,
    };
};
export const GetMonthlyAbsentReasonForChartFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetMonthlyAbsentReasonForChart_FAIL,
    };
};
export const GetMonthlyAbsentReasonForChart = () => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetMonthlyAbsentReasonForChartStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetMonthlyAbsentReasonForChart`,
            params: {},
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetMonthlyAbsentReasonForChartSuccess(response.data));
                return response.data;
                
            })
            .catch(function (error) {
                dispatch(GetMonthlyAbsentReasonForChartFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetWeeklyAbsentReasonForChart Action ***/

export const GetYearlyAbsentReasonForChartStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetYearlyAbsentReasonForChart_START,
    };
};
export const GetYearlyAbsentReasonForChartSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetYearlyAbsentReasonForChart_SUCCESS,
    };
};
export const GetYearlyAbsentReasonForChartFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetYearlyAbsentReasonForChart_FAIL,
    };
};
export const GetYearlyAbsentReasonForChart = () => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetYearlyAbsentReasonForChartStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetYearlyAbsentReasonForChart`,
            params: {},
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetYearlyAbsentReasonForChartSuccess(response.data));
                return response.data;
                
            })
            .catch(function (error) {
                dispatch(GetYearlyAbsentReasonForChartFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetWeeklyAbsentReasonForChart Action ***/

export const GetMonthlyAttendanceCountForChartStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetMonthlyAttendanceCountForChart_START,
    };
};
export const GetMonthlyAttendanceCountForChartSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetMonthlyAttendanceCountForChart_SUCCESS,
    };
};
export const GetMonthlyAttendanceCountForChartFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetMonthlyAttendanceCountForChart_FAIL,
    };
};
export const GetMonthlyAttendanceCountForChart = () => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetMonthlyAttendanceCountForChartStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetMonthlyAttendanceCountForChart`,
            params: {},
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetMonthlyAttendanceCountForChartSuccess(response.data));
                return response.data;
                
            })
            .catch(function (error) {
                dispatch(GetMonthlyAttendanceCountForChartFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** GetYearlyAttendanceCountForChart Action ***/

export const GetYearlyAttendanceCountForChartStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetYearlyAttendanceCountForChart_START,
    };
};
export const GetYearlyAttendanceCountForChartSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetYearlyAttendanceCountForChart_SUCCESS,
    };
};
export const GetYearlyAttendanceCountForChartFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetYearlyAttendanceCountForChart_FAIL,
    };
};
export const GetYearlyAttendanceCountForChart = () => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetYearlyAttendanceCountForChartStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetYearlyAttendanceCountForChart`,
            params: {},
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetYearlyAttendanceCountForChartSuccess(response.data));
                return response.data;
                
            })
            .catch(function (error) {
                dispatch(GetYearlyAttendanceCountForChartFail("Something went wrong, Please try again."));
                return error;
            });
    };
};






/******  WeekWiseAttendanceReasonsChart *******/


export const WeekWiseAttendanceReasonsChartStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.WeekWiseAttendanceReasonsChart_START,
    };
};
export const WeekWiseAttendanceReasonsChartSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.WeekWiseAttendanceReasonsChart_SUCCESS,
    };
};
export const WeekWiseAttendanceReasonsChartFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.WeekWiseAttendanceReasonsChart_FAIL,
    };
};
export const WeekWiseAttendanceReasonsChart = (start_date, end_date) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(WeekWiseAttendanceReasonsChartStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/WeekWiseAttendanceReasonsChart`,
            params: {
                start_date: start_date,
                end_date: end_date
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(WeekWiseAttendanceReasonsChartSuccess(response.data));
                return response.data;
                
            })
            .catch(function (error) {
                dispatch(WeekWiseAttendanceReasonsChartFail("Something went wrong, Please try again."));
                return error;
            });
    };
};




/******  ExportWeeklyAttendanceReasonsPdf *******/


export const ExportWeeklyAttendanceReasonsPdfStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.ExportWeeklyAttendanceReasonsPdf_START,
    };
};
export const ExportWeeklyAttendanceReasonsPdfSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.ExportWeeklyAttendanceReasonsPdf_SUCCESS,
    };
};
export const ExportWeeklyAttendanceReasonsPdfFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.ExportWeeklyAttendanceReasonsPdf_FAIL,
    };
};
export const ExportWeeklyAttendanceReasonsPdf = (start_date, end_date) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(ExportWeeklyAttendanceReasonsPdfStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/ExportWeeklyAttendanceReasonsPdf`,
            params: {
                start_date: start_date,
                end_date: end_date
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(ExportWeeklyAttendanceReasonsPdfSuccess(response.data));
                return response.data;
                
            })
            .catch(function (error) {
                dispatch(ExportWeeklyAttendanceReasonsPdfFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** ResetAllReasonsdata ***/

export const ResetAllReasonsdataStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.ResetAllReasonsdata_START,
    };
};
export const ResetAllReasonsdataSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.ResetAllReasonsdata_SUCCESS,
    };
};
export const ResetAllReasonsdataFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.ResetAllReasonsdata_FAIL,
    };
};
export const ResetAllReasonsdata = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(ResetAllReasonsdataStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "delete",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/ResetAllReasonsdata`,
        })
            .then(function (response) {
                console.log("resposne", response.data);
                dispatch(ResetAllReasonsdataSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                console.log("resposne fail", error);
                dispatch(ResetAllReasonsdataFail("Something went wrong, Please try again."));
                return error;
            });
    };
};






