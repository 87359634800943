import axios from "axios";
import * as actionType from "../constants/actionTypes";
let qs = require("qs");


/*** CreateCustomLink Action ***/
export const CreateCustomLinkStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.CreateCustomLink_START,
    };
};
export const CreateCustomLinkSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.CreateCustomLink_SUCCESS,
    };
};
export const CreateCustomLinkFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.CreateCustomLink_FAIL,
    };
};
export const CreateCustomLink = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    
    return (dispatch) => {
        dispatch(CreateCustomLinkStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/CreateCustomLink`,
        })
            .then(function (response) {
                dispatch(CreateCustomLinkSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateCustomLinkFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** GetAllCustomLinks Action ***/
export const GetAllCustomLinksStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllCustomLinks_START,
    };
};
export const GetAllCustomLinksSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllCustomLinks_SUCCESS,
    };
};
export const GetAllCustomLinksFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllCustomLinks_FAIL,
    };
};
export const GetAllCustomLinks = (page, limit,search ) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllCustomLinksStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllCustomLinks`,
            params: {
                page: page,
                limit: limit,
                search: search,
              },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllCustomLinksSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllCustomLinksFail("Something went wrong, Please try again."));
                return error;
            });
    };
};




/*** GetCustomLinkById Action ***/
export const GetCustomLinkByIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetCustomLinkById_START,
    };
};
export const GetCustomLinkByIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetCustomLinkById_SUCCESS,
    };
};
export const GetCustomLinkByIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetCustomLinkById_FAIL,
    };
};
export const GetCustomLinkById = (custom_link_uuid) => {

    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

   
    return (dispatch) => {
        dispatch(GetCustomLinkByIdStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetCustomLinkById`,
            params: {
                custom_link_uuid: custom_link_uuid,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetCustomLinkByIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetCustomLinkByIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** UpdateCustomLink Action ***/

export const UpdateCustomLinkStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateCustomLink_START,
    };
};
export const UpdateCustomLinkSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateCustomLink_SUCCESS,
    };
};
export const UpdateCustomLinkFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateCustomLink_FAIL,
    };
};

export const UpdateCustomLink = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(UpdateCustomLinkStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/UpdateCustomLink`,
        })
            .then(function (response) {
                dispatch(UpdateCustomLinkSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateCustomLinkFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** DeleteCustomLink Action ***/

export const DeleteCustomLinkStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.DeleteCustomLink_START,
    };
};
export const DeleteCustomLinkSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.DeleteCustomLink_SUCCESS,
    };
};
export const DeleteCustomLinkFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.DeleteCustomLink_FAIL,
    };
};
export const DeleteCustomLink = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(DeleteCustomLinkStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "delete",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
                
            },
            url: `${actionType.environment_url}/api/bss-admin/DeleteCustomLink`,
        })
            .then(function (response) {
                console.log("resposne", response.data);
                dispatch(DeleteCustomLinkSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                console.log("resposne fail", error);
                dispatch(DeleteCustomLinkFail("Something went wrong, Please try again."));
                return error;
            });
    };
};
