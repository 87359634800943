import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import Sidebar from "../Constant/sidebar";
import Footer from "../Constant/Footer";
import TopBar from "../Constant/TopBar";
// import { Tooltip } from "@mui/material";
import { toast } from "react-toastify";
// import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";
import * as userActions from "../../actions/index";
import Validations from "../Utility/Validations";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import PermissionsEditModal from "./PermissionsEditModal";
import { Tooltip } from "@mui/material";
import { Beforeunload } from 'react-beforeunload';


const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info.school_code;


class PermissionEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      beforeLoad: "",
      role_name: "",
      role_uuid: "",
      role_id: "",
      show_modal: false,
      permission_add_form: {
        // select_dormitory_head: {
        //     type: "select",
        //     label: "Dormitory Head *",
        //     value: "",
        //     valid: true,
        //     error_msg: "",
        //     onBlur_out: false,
        //     placeholder: "City",
        //     validations: { required: true },
        //     options: [
        //         { label: "All *", value: "" },
        //         { label: "Pratham", value: "Pratham" },
        //         { label: "Rohit", value: "Rohit" },
        //         { label: "Bhatia.", value: "Bhatia." },
        //     ],
        // },

        add_class: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        edit_class: {
          type: "checkbox",
          label: "All",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        view_class: {
          type: "checkbox",
          label: "All",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        // manage_class_delete: {
        //     type: "checkbox",
        //     label: "All",
        //     value: false,
        //     valid: true,
        //     error_msg: "",
        //     validations: { required: false },
        // },
        activate_deactivate_class: {
          type: "checkbox",
          label: "All",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        add_student: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        edit_student: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        view_student: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        delete_student: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        activate_deactivate_student: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        // manage_reasons_add: {
        //   type: "checkbox",
        //   label: "Add",
        //   value: false,
        //   valid: true,
        //   error_msg: "",
        //   validations: { required: false },
        // },

        // manage_reasons_edit: {
        //   type: "checkbox",
        //   label: "Add",
        //   value: false,
        //   valid: true,
        //   error_msg: "",
        //   validations: { required: false },
        // },

        // manage_reasons_view: {
        //   type: "checkbox",
        //   label: "Add",
        //   value: false,
        //   valid: true,
        //   error_msg: "",
        //   validations: { required: false },
        // },

        // delete_rejection_reason: {
        //   type: "checkbox",
        //   label: "Add",
        //   value: false,
        //   valid: true,
        //   error_msg: "",
        //   validations: { required: false },
        // },

        add_host: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        add_allergic_students: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        view_allergic_students: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        edit_allergic_students: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        delete_allergic_students: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        edit_host: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        view_host: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        // parents_approval_host: {
        //   type: "checkbox",
        //   label: "Add",
        //   value: false,
        //   valid: true,
        //   error_msg: "",
        //   validations: { required: false },
        // },
        // activate_deactivate_host: {
        //   type: "checkbox",
        //   label: "Add",
        //   value: false,
        //   valid: true,
        //   error_msg: "",
        //   validations: { required: false },
        // },


        add_weekend_leave: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        edit_weekend_leave: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        view_weekend_leave: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },


        add_user: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        edit_user: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        view_user: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        activate_deactivate_user: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        add_dormitory: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        edit_dormitory: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        view_dormitory: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        activate_deactivate_dormitory: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },


        add_permission: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        edit_permission: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        view_permission: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        edit_message: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        view_message: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        edit_school_profile: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        view_school_profile: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        create_tempid: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        // add_dorm_comment: {
        //   type: "checkbox",
        //   label: "Add",
        //   value: false,
        //   valid: true,
        //   error_msg: "",
        //   validations: { required: false },
        // },

        add_medical_comment: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        add_pastoral_comment: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        // view_dorm_comment: {
        //   type: "checkbox",
        //   label: "view",
        //   value: false,
        //   valid: true,
        //   error_msg: "",
        //   validations: { required: false },
        // },

        view_medical_comment: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        view_pastoral_comment: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        // require_stu_access_pin: {
        //   type: "checkbox",
        //   label: "view",
        //   value: false,
        //   valid: true,
        //   error_msg: "",
        //   validations: { required: false },
        // },

        // snapshot_not_require_input_pin_to_access_interface: {
        //   type: "checkbox",
        //   label: "view",
        //   value: false,
        //   valid: true,
        //   error_msg: "",
        //   validations: { required: false },
        // },

        // student_kiosk_mode: {
        //   type: "checkbox",
        //   label: "view",
        //   value: false,
        //   valid: true,
        //   error_msg: "",
        //   validations: { required: false },
        // },

        // snapshot_no_student_kiosk_mode_staff_managed_only: {
        //   type: "checkbox",
        //   label: "view",
        //   value: false,
        //   valid: true,
        //   error_msg: "",
        //   validations: { required: false },
        // },

        // dis_stu_avatar_onsnapshot: {
        //   type: "checkbox",
        //   label: "view",
        //   value: false,
        //   valid: true,
        //   error_msg: "",
        //   validations: { required: false },
        // },

        // dis_male_female_shadow_snapshot: {
        //   type: "checkbox",
        //   label: "view",
        //   value: false,
        //   valid: true,
        //   error_msg: "",
        //   validations: { required: false },
        // },

        dorm_data: [],
        class_data: [],

        // head_check: {
        //   type: "checkbox",
        //   label: "view",
        //   value: false,
        //   valid: true,
        //   error_msg: "",
        //   validations: { required: false },
        // },

        dormitory_dikonia: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        dormitory_testr: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        dormitory_southzone: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        dormitory_beta: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        role_permission_id: {
          type: "text",
          label: "view",
          value: "",
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        add_grounded_students: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        deactivate_grounded_students: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        delete_grounded_students: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        edit_grounded_students: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        view_grounded_students: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        view_calendar: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        edit_calendar: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        add_calendar: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        view_change_dormitory: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        view_custom_links: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        view_duplicate_students: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        view_import_students: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        view_rollover_students: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        view_rollcall_session_time: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        view_oncampus_details: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        view_attendance_report: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        view_flag_students: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        add_flag_students: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        edit_flag_students: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        deactivate_flag_students: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        delete_flag_students: {
          type: "checkbox",
          label: "view",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        // manage_gender: {
        //     type: "radio",
        //     label: "Gender",
        //     value: "",
        //     valid: true,
        //     error_msg: "",
        //     onBlur_out: false,
        //     validations: { required: true },
        // },

        // snapshot_gender_both: {
        //   type: "radio",
        //   label: "Gender",
        //   value: false,
        //   valid: true,
        //   error_msg: "",
        //   onBlur_out: false,
        //   validations: { required: false },
        // },
        // snapshot_gender_female: {
        //   type: "radio",
        //   label: "Gender",
        //   value: false,
        //   valid: true,
        //   error_msg: "",
        //   onBlur_out: false,
        //   validations: { required: false },
        // },
        // snapshot_gender_male: {
        //   type: "radio",
        //   label: "Gender",
        //   value: false,
        //   valid: true,
        //   error_msg: "",
        //   onBlur_out: false,
        //   validations: { required: false },
        // },



        add_role: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          onBlur_out: false,
          validations: { required: false },
        },
        delete_role: {
          type: "checkbox",
          label: "Delete",
          value: false,
          valid: true,
          error_msg: "",
          onBlur_out: false,
          validations: { required: false },
        },
        edit_role: {
          type: "checkbox",
          label: "Edit",
          value: false,
          valid: true,
          error_msg: "",
          onBlur_out: false,
          validations: { required: false },
        },
        deactivate_role: {
          type: "checkbox",
          label: "Deactive",
          value: false,
          valid: true,
          error_msg: "",
          onBlur_out: false,
          validations: { required: false },
        },
        add_duplicate_role: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          onBlur_out: false,
          validations: { required: false },
        },
        view_activities: {
          type: "checkbox",
          label: "View",
          value: false,
          valid: true,
          error_msg: "",
          onBlur_out: false,
          validations: { required: false },
        },
        view_snapshot: {
          type: "checkbox",
          label: "View",
          value: false,
          valid: true,
          error_msg: "",
          onBlur_out: false,
          validations: { required: false },
        },

        view_boarder_leaves: {
          type: "checkbox",
          label: "View",
          value: false,
          valid: true,
          error_msg: "",
          onBlur_out: false,
          validations: { required: false },
        },
        add_boarder_leaves: {
          type: "checkbox",
          label: "View",
          value: false,
          valid: true,
          error_msg: "",
          onBlur_out: false,
          validations: { required: false },
        },
        edit_boarder_leaves: {
          type: "checkbox",
          label: "View",
          value: false,
          valid: true,
          error_msg: "",
          onBlur_out: false,
          validations: { required: false },
        },

        view_adhoc_leave: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        add_adhoc_leave: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        edit_adhoc_leave: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        view_reports: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        view_travel_mode: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        add_travel_mode: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        edit_travel_mode: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        delete_travel_mode: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        activate_deactivate_travel_mode: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

        view_rejection_reason: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        add_rejection_reason: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        edit_rejection_reason: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        delete_rejection_reason: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },
        activate_deactivate_rejection_reason: {
          type: "checkbox",
          label: "Add",
          value: false,
          valid: true,
          error_msg: "",
          validations: { required: false },
        },

      },

      dormitory_id: {
        type: "select",
        label: "Select Dormitory *",
        value: "",
        valid: true,
        error_msg: "",
        onBlur_out: false,
        placeholder: "",
        validations: { required: false },
        options: [],
      },
      class_id: {
        type: "select",
        label: "Select Class *",
        value: "",
        valid: true,
        error_msg: "",
        onBlur_out: false,
        placeholder: "",
        validations: { required: false },
        options: [],
      },
    };
  }

  componentDidMount() {
    this.GetLoginUserPermissions();
    this.GetPermissionsByRoleId();
    this.GetAllDormitoryStudent();
    this.GetAllClassesList();
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    const roleID = JSON.parse(localStorage.getItem("role_id"));
    this.setState({ role_id: roleID })

    this.setState({ beforeLoad: "beforeLoad" })
  }

  GetPermissionsByRoleId() {
    this.props.onGetPermissionsByRoleId(this.props.role_uuid).then((response) => {
      if (response.success === true) {
        // console.log("response GetPermissionsByRoleId.rows", response.data.roll_perm);
        let permission_res = response?.data?.roll_perm;
        console.log("dormData===========", permission_res)

        let name = response?.data?.role_name;
        this.setState({ role_name: name });

        const update_permission_add_form = { ...this.state.permission_add_form };


        if (permission_res?.bss_role_perm_class_dorms) {
          const dormData = permission_res?.bss_role_perm_class_dorms?.map((item) => {
            if (item?.roll_perm_dorm) {
              return item?.roll_perm_dorm.dormitory_id
            }
          });

          if (!dormData || dormData.length === 0) {
            const allOptions = this.state.dormitory_id.options;
            const selectedOptions = allOptions.filter((option) => option.value !== "all")

            update_permission_add_form.dorm_data = selectedOptions.map((option) => option.value)
          } else {
            update_permission_add_form.dorm_data = dormData.filter((item) => item !== undefined);
          }
        } else {
          console.log("CHEC1")
          const allOptions = this.state.dormitory_id.options;
          const selectedOptions = allOptions.filter((option) => option.value !== "all")

          update_permission_add_form.dorm_data = selectedOptions.map((option) => option.value)
        }

        if (permission_res?.bss_role_perm_class_dorms) {
          const classData = permission_res?.bss_role_perm_class_dorms?.map((item) => {
            if (item?.roll_perm_class) {
              return item?.roll_perm_class.class_id
            }
          });
          if (!classData || classData.length === 0) {
            const allOptions = this.state.class_id.options;
            const selectedOptions = allOptions.filter((option) => option.value !== "all")

            update_permission_add_form.class_data = selectedOptions.map((option) => option.value)
          } else {
            update_permission_add_form.class_data = classData.filter((item) => item !== undefined);
          }
        } else {
          console.log("CHEC")
          const allOptions = this.state.class_id.options;
          const selectedOptions = allOptions.filter((option) => option.value !== "all")

          update_permission_add_form.class_data = selectedOptions.map((option) => option.value)
        }



        for (let key in update_permission_add_form) {
          let form_element = update_permission_add_form[key];

          if (update_permission_add_form[key]) {
            update_permission_add_form["role_permission_id"].value = permission_res?.role_permission_id;

            // update_permission_add_form["activate_deactivate_host"].value = permission_res?.activate_deactivate_host;
            // update_permission_add_form["add_dorm_comment"].value = permission_res?.add_dorm_comment;
            // update_permission_add_form["dis_male_female_shadow_snapshot"].value = permission_res?.dis_male_female_shadow_snapshot;
            // update_permission_add_form["dis_stu_avatar_onsnapshot"].value = permission_res?.dis_stu_avatar_onsnapshot;
            // update_permission_add_form["head_check"].value = permission_res?.head_check;
            // update_permission_add_form["parents_approval_host"].value = permission_res?.parents_approval_host;
            // update_permission_add_form["require_stu_access_pin"].value = permission_res?.require_stu_access_pin;
            // update_permission_add_form["snapshot_gender_both"].value = permission_res?.snapshot_gender_both;
            // update_permission_add_form["snapshot_gender_female"].value = permission_res?.snapshot_gender_female;
            // update_permission_add_form["snapshot_gender_male"].value = permission_res?.snapshot_gender_male;
            // update_permission_add_form["view_dorm_comment"].value = permission_res?.view_dorm_comment;
            // update_permission_add_form["student_kiosk_mode"].value = permission_res?.student_kiosk_mode;
            // update_permission_add_form["student_kiosk_mode"].value = permission_res?.student_kiosk_mode;
            
            update_permission_add_form["add_host"].value = permission_res?.add_host;
            update_permission_add_form["view_weekend_leave"].value = permission_res?.view_weekend_leave;
            update_permission_add_form["add_weekend_leave"].value = permission_res?.add_weekend_leave;
            update_permission_add_form["edit_weekend_leave"].value = permission_res?.edit_weekend_leave;
            update_permission_add_form["view_host"].value = permission_res?.view_host;
            update_permission_add_form["edit_host"].value = permission_res?.edit_host;
            update_permission_add_form["view_medical_comment"].value = permission_res?.view_medical_comment;
            update_permission_add_form["add_medical_comment"].value = permission_res?.add_medical_comment;
            update_permission_add_form["view_pastoral_comment"].value = permission_res?.view_pastoral_comment;
            update_permission_add_form["add_pastoral_comment"].value = permission_res?.add_pastoral_comment;



            // <======================================== Web Permission  ===========================>


            update_permission_add_form["add_class"].value = permission_res?.add_class;
            update_permission_add_form["edit_class"].value = permission_res?.edit_class;
            update_permission_add_form["view_class"].value = permission_res === null ? true : permission_res?.view_class;
            update_permission_add_form["activate_deactivate_class"].value = permission_res?.activate_deactivate_class;

            update_permission_add_form["add_dormitory"].value = permission_res?.add_dormitory;
            update_permission_add_form["edit_dormitory"].value = permission_res?.edit_dormitory;
            update_permission_add_form["view_dormitory"].value = permission_res?.view_dormitory;
            update_permission_add_form["activate_deactivate_dormitory"].value = permission_res?.activate_deactivate_dormitory;

            update_permission_add_form["view_school_profile"].value = permission_res === null ? true : permission_res?.view_school_profile;
            update_permission_add_form["edit_school_profile"].value = permission_res?.edit_school_profile;

            update_permission_add_form["add_user"].value = permission_res?.add_user;
            update_permission_add_form["edit_user"].value = permission_res?.edit_user;
            update_permission_add_form["view_user"].value = permission_res?.view_user;
            update_permission_add_form["activate_deactivate_user"].value = permission_res?.activate_deactivate_user;

            update_permission_add_form["edit_permission"].value = permission_res?.edit_permission;
            update_permission_add_form["view_permission"].value = permission_res?.view_permission;
            update_permission_add_form['add_role'].value = permission_res?.add_role;
            update_permission_add_form['edit_role'].value = permission_res?.edit_role;
            update_permission_add_form['add_duplicate_role'].value = permission_res?.add_duplicate_role;
            update_permission_add_form['deactivate_role'].value = permission_res?.deactivate_role;
            update_permission_add_form['delete_role'].value = permission_res?.delete_role;

            update_permission_add_form['view_snapshot'].value = permission_res?.view_snapshot;

            update_permission_add_form['view_activities'].value = permission_res?.view_activities;


            // <======================================== Student   ===========================>

            update_permission_add_form["view_student"].value = permission_res?.view_student;
            update_permission_add_form["activate_deactivate_student"].value = permission_res?.activate_deactivate_student;
            update_permission_add_form["edit_student"].value = permission_res?.edit_student;
            update_permission_add_form["create_tempid"].value = permission_res?.create_tempid;
            update_permission_add_form["delete_student"].value = permission_res?.delete_student;

            update_permission_add_form["add_student"].value = permission_res?.add_student;

            update_permission_add_form["add_allergic_students"].value = permission_res?.add_allergic_students;
            update_permission_add_form["view_allergic_students"].value = permission_res?.view_allergic_students;
            update_permission_add_form["edit_allergic_students"].value = permission_res?.edit_allergic_students;
            update_permission_add_form["delete_allergic_students"].value = permission_res?.delete_allergic_students;

            update_permission_add_form["add_grounded_students"].value = permission_res?.add_grounded_students;
            update_permission_add_form["view_grounded_students"].value = permission_res?.view_grounded_students;
            update_permission_add_form["edit_grounded_students"].value = permission_res?.edit_grounded_students;
            update_permission_add_form["delete_grounded_students"].value = permission_res?.delete_grounded_students;
            // update_permission_add_form["deactivate_grounded_students"].value = permission_res?.deactivate_grounded_students;

            update_permission_add_form["add_flag_students"].value = permission_res?.add_flag_students;
            update_permission_add_form["view_flag_students"].value = permission_res?.view_flag_students;
            update_permission_add_form["edit_flag_students"].value = permission_res?.edit_flag_students;
            update_permission_add_form["delete_flag_students"].value = permission_res?.delete_flag_students;
            // update_permission_add_form["deactivate_flag_students"].value = permission_res?.deactivate_flag_students;

            update_permission_add_form["view_import_students"].value = permission_res?.view_import_students;

            update_permission_add_form["view_duplicate_students"].value = permission_res?.view_duplicate_students;

            update_permission_add_form["view_change_dormitory"].value = permission_res?.view_change_dormitory;

            // <======================================== Setting Tab ========================>

            update_permission_add_form["view_attendance_report"].value = permission_res?.view_attendance_report;

            update_permission_add_form["view_message"].value = permission_res?.view_message;
            update_permission_add_form["edit_message"].value = permission_res?.edit_message;

            update_permission_add_form["view_custom_links"].value = permission_res?.view_custom_links;

            update_permission_add_form["view_rollover_students"].value = permission_res?.view_rollover_students;

            update_permission_add_form["view_rollcall_session_time"].value = permission_res?.view_rollcall_session_time;

            update_permission_add_form["view_oncampus_details"].value = permission_res?.view_oncampus_details;

            update_permission_add_form["view_calendar"].value = permission_res === null ? true : permission_res?.view_calendar;
            update_permission_add_form["edit_calendar"].value = permission_res === null ? true : permission_res?.edit_calendar;
            update_permission_add_form["add_calendar"].value = permission_res === null ? true : permission_res?.add_calendar;

            // <======================================== leave permissions========================>

            update_permission_add_form["view_boarder_leaves"].value = permission_res?.view_boarder_leaves;
            update_permission_add_form["add_boarder_leaves"].value = permission_res?.add_boarder_leaves;
            update_permission_add_form["edit_boarder_leaves"].value = permission_res?.edit_boarder_leaves;

            update_permission_add_form["view_adhoc_leave"].value = permission_res?.view_adhoc_leave;
            update_permission_add_form["add_adhoc_leave"].value = permission_res?.add_adhoc_leave;
            update_permission_add_form["edit_adhoc_leave"].value = permission_res?.edit_adhoc_leave;

            update_permission_add_form["view_reports"].value = permission_res?.view_reports;

            update_permission_add_form["view_travel_mode"].value = permission_res?.view_travel_mode;
            update_permission_add_form["add_travel_mode"].value = permission_res?.add_travel_mode;
            update_permission_add_form["edit_travel_mode"].value = permission_res?.edit_travel_mode;
            update_permission_add_form["delete_travel_mode"].value = permission_res?.delete_travel_mode;
            update_permission_add_form["activate_deactivate_travel_mode"].value = permission_res?.activate_deactivate_travel_mode;

            update_permission_add_form["view_rejection_reason"].value = permission_res?.view_rejection_reason;
            update_permission_add_form["add_rejection_reason"].value = permission_res?.add_rejection_reason;
            update_permission_add_form["edit_rejection_reason"].value = permission_res?.edit_rejection_reason;
            update_permission_add_form["delete_rejection_reason"].value = permission_res?.delete_rejection_reason;
            update_permission_add_form["activate_deactivate_rejection_reason"].value = permission_res?.activate_deactivate_rejection_reason;


            update_permission_add_form[key] = form_element;
          }

          this.setState({ permission_add_form: update_permission_add_form });
        }
        console.log(
          "update--- update_permission_add_form 111",
          update_permission_add_form
        );
      } else {
      }
    });
  }

  GetLoginUserPermissions() {
    this.props.onGetLoginUserPermissions().then((response) => {
      if (response.success === true) {
      } else {
      }
    });
  }


  GetAllDormitoryStudent = async (form_data) => {
    this.props.onGetAllDormitoryStudent().then((response) => {
      let country_level = [];

      const update_dormitory_id = this.state.dormitory_id;
      if (response.success === true) {

        const arrayData = response.data.rows;
        for (let key in arrayData) {
          if (arrayData[key].dormitory_status === true) {
            country_level.push({
              label: arrayData[key].dormitory_name,
              value: arrayData[key].dormitory_id,
            });
          }
        }
      }
      const selectAllOption = {
        label: "Select All",
        value: "all"
      }
      country_level.unshift(selectAllOption)
      update_dormitory_id.options = country_level;
      this.setState({ dormitory_id: update_dormitory_id });
    });
    this.GetPermissionsByRoleId();
  }

  GetAllClassesList = async (form_data) => {
    this.props.onGetAllClassesList().then((response) => {
      let country_level = [];
      const update_class_id = this.state.class_id;
      if (response.success === true) {
        const arrayData = response.data.rows;
        for (let key in arrayData) {
          if (arrayData[key].is_class_activate === true) {
            country_level.push({
              label: arrayData[key].class_name,
              value: arrayData[key].class_id,

            });
          }
        }
      }

      const selectAllOption = {
        label: "Select All",
        value: "all"
      }
      country_level.unshift(selectAllOption)

      update_class_id.options = country_level;
      this.setState({ class_id: update_class_id });
    });
    this.GetPermissionsByRoleId();
  }

  handleChangeDorm(data, identifier) {
    console.log("CHECK DORM CHECK", data)
    if (!data || data.length === 0) {
      console.log("CHECK DORM CHECK", data.length)
      const opt = this.state.dormitory_id.options
      data = opt.filter((option) => option.value !== "all");
    } else if (data && data.length > 0 && data[data.length - 1].value === "all") {
      const opt = this.state.dormitory_id.options
      data = opt.filter((option) => option.value !== "all");
    }
    this.setState((prevState) => ({
      permission_add_form: {
        ...prevState.permission_add_form,
        dorm_data: data ? data.map((option) => option.value) : [],
      }
    }));
  }
  handleChangeClass(data, identifier) {
    if (!data || data.length === 0) {
      console.log("CHECK CLASS CHECK", data.length)
      const opt = this.state.class_id.options
      data = opt.filter((option) => option.value !== "all");
    } else if (data && data.length > 0 && data[data.length - 1].value === "all") {
      const opt = this.state.class_id.options
      data = opt.filter((option) => option.value !== "all");
    }
    this.setState((prevState) => ({
      permission_add_form: {
        ...prevState.permission_add_form,
        class_data: data ? data.map((option) => option.value) : [],
      }
    }));

  }


  ChangePermissions = async (permission_status) => {
    const update_permission_add_form = { ...this.state.permission_add_form };
    for (let identifier in update_permission_add_form) {
      let form_element = update_permission_add_form[identifier];

      if (
        form_element.type === "checkbox" &&
        identifier !== "students_specific"
      ) {
        form_element.value = permission_status;
        update_permission_add_form[identifier] = form_element;
      }
    }
    this.setState({ permission_add_form: update_permission_add_form });
  };



  inputChangeHandler(event, identifier) {
    const update_permission_add_form = { ...this.state.permission_add_form };
    const form_element = { ...update_permission_add_form[identifier] };
    console.log("form_element", form_element)

    if (form_element.type === "checkbox") {
      // console.log("=====", event.target.checked);
      form_element.value = event.target.checked;
    }
    // else if (form_element.type === "radio") {
    //   if (identifier === "snapshot_gender_female") {
    //     form_element.value = event.target.value === 'false';
    //     update_permission_add_form['snapshot_gender_both'].value = false;
    //     update_permission_add_form['snapshot_gender_male'].value = false;
    //   } else if (identifier === "snapshot_gender_male") {
    //     form_element.value = event.target.value === 'false';
    //     update_permission_add_form['snapshot_gender_both'].value = false;
    //     update_permission_add_form['snapshot_gender_female'].value = false;
    //   } if (identifier === "snapshot_gender_both") {
    //     form_element.value = event.target.value === 'false';
    //     update_permission_add_form['snapshot_gender_female'].value = false;
    //     update_permission_add_form['snapshot_gender_male'].value = false;
    //   }
    // } 
    else {
      form_element.value = event.target.value;
    }

    // Check Validation
    let validate_element = Validations(form_element);
    form_element.valid = validate_element.valid;
    form_element.error_msg = validate_element.error_msg;

    update_permission_add_form[identifier] = form_element;
    console.log("update_permission_add_form", update_permission_add_form)
    console.log("identifier", identifier)
    this.setState({ permission_add_form: update_permission_add_form });
  }

  SubmitHandler = (event) => {
    event.preventDefault();
    const role_id = this.state.role_id;
    const role_name = this.state.role_name;


    let is_form_valid = true;
    const update_permission_add_form = this.state.permission_add_form;

    this.setState({ permission_add_form: update_permission_add_form });

    if (is_form_valid) {
      const form_data = {};
      for (let key in this.state.permission_add_form) {
        form_data[key] = this.state.permission_add_form[key].value;
        console.log("permission======================", this.state.permission_add_form[key].value);
      }

      let classes = this.state.permission_add_form.class_data;
      let update_class_data = classes.map((item) => {
        return ({
          class_id: item,
        })
      })

      let dorms = this.state.permission_add_form.dorm_data;
      let update_dormitory_data = dorms.map((item) => {
        return ({
          dormitory_id: item,
        })
      })

      form_data['role_id'] = role_id;
      form_data['role_name'] = role_name;

      form_data['dorm_data'] = update_dormitory_data;
      form_data['class_data'] = update_class_data;

      console.log("form_data===", form_data);


      this.props.onCreateOrUpdateRolePermissions(form_data).then(async (response) => {
        if (response.success === true) {
          toast.success("Permissions of specific Role created successfully!", {
            position: toast.POSITION.TOP_CENTER,
          });
          this.GetPermissionsByRoleId();
          setTimeout(() => {
            this.setState({
              show: false,
              isAuthenticated: true,
              redirect_url: `/${school_code}/permissions-list`,
            });
          }, 500);
        } else {
          toast.error(response.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
    }
  };

  handleClose = (value) => {
    console.log("permission_add_form===", value);
    this.setState({ show_modal: false })

  }

  usSavedDataAlert = (event) => {
    event.preventDefault()
  }

  render() {
    const { permission_add_form } = this.state;
    return (
      <>
        {this.state.beforeLoad !== '' && (
          <Beforeunload onBeforeunload={(event) => this.usSavedDataAlert(event)} />
        )}
        {this.state.show_modal && <PermissionsEditModal
          ChangePermissions={(event) => this.ChangePermissions(event)}
          handleClose={(value) => this.handleClose(value)}
          handleShow={this.state.show_modal}
        />}
        {this.state.isAuthenticated ? <Navigate to={this.state.redirect_url} /> : null}
        <div
          id="kt_body"
          className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
        >
          <div className="d-flex flex-column flex-root">
            <div className="page d-flex flex-row flex-column-fluid">
              <Sidebar />

              <div
                className="wrapper d-flex flex-column flex-row-fluid"
                id="kt_wrapper"
              >
                <TopBar />

                <div
                  className="content d-flex flex-column flex-column-fluid"
                  id="kt_content"
                >
                  <div
                    className="post d-flex flex-column-fluid h-100"
                    id="kt_post"
                  >
                    <div id="kt_content_container" className="container-fluid">
                      <div className="row">
                        <div className="col-xl-12">


                          <div className="card">
                            <div className="card-header align-items-center">
                              <h3>Permissions</h3>
                            </div>
                            <form onSubmit={this.SubmitHandler}>
                              <div className="card-body">
                                <div className="col-sm-12">
                                  <div className="mb-5 d-flex justify-content-between  align-items-center ">
                                    <h3 className="m-0">{this.state.role_name}</h3>
                                    <button type="button" className="btn btn-sm btn-light me-2 min-150px ms-auto" onClick={(event) => this.setState({ show_modal: true })}>Cancel</button>
                                    <button type="submit" className="btn btn-sm btn-primary min-150px">Save</button>
                                  </div>



                                  <div className="permission-wrap mb-4">
                                    <div className="d-flex permission-title">
                                      <div className="title-left">
                                        <h6 className="mb-0">Web Permission</h6>
                                      </div>
                                      <div className="select-btn-out">Select :
                                        <button type="button" className="m-btn btn btn-secondary btn-sm" onClick={(event) => this.ChangePermissions(true)}>
                                          All
                                        </button>
                                        |
                                        <button type="button" className="m-btn btn btn-secondary btn-sm" onClick={(event) => this.ChangePermissions(false)}>
                                          None
                                        </button>
                                      </div>
                                    </div>

                                    <div className="mt-2">
                                      <div className="sec-row">
                                        <div className="row">
                                          <div className="col-md-2">
                                            <div className="ps-3">
                                              <b>Manage Class</b>
                                            </div>
                                          </div>
                                          <div className="col-md-10">
                                            <div className="row">
                                              <div className="col-sm-4 col-md-3">
                                                <div className="checkbox-inline">
                                                  <label className="checkbox cursor-pointer ">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input me-1"
                                                      value={permission_add_form.add_class.value}
                                                      checked={permission_add_form.add_class.value}
                                                      onChange={(event) => this.inputChangeHandler(event, "add_class")}
                                                    />
                                                    <span className="mb-0"> Add Class </span>
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="col-sm-4 col-md-3">
                                                <div className="checkbox-inline">
                                                  <label className="checkbox cursor-pointer ">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input me-1"
                                                      value={permission_add_form.edit_class.value}
                                                      checked={permission_add_form.edit_class.value}
                                                      onChange={(event) => this.inputChangeHandler(event, "edit_class")}
                                                    />
                                                    <span className="mb-0">
                                                      Edit Class
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>

                                              <div className="col-sm-4 col-md-3">
                                                <div className="checkbox-inline">
                                                  <label className="checkbox cursor-pointer ">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input me-1"
                                                      value={permission_add_form.view_class.value}
                                                      checked={permission_add_form.view_class.value}
                                                      onChange={(event) => this.inputChangeHandler(event, "view_class")}
                                                    />
                                                    <span className="mb-0">
                                                      View Class
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="col-sm-4 col-md-3">
                                                <div className="checkbox-inline">
                                                  <label className="checkbox cursor-pointer ">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input me-1"
                                                      value={permission_add_form.activate_deactivate_class.value}
                                                      checked={permission_add_form.activate_deactivate_class.value}
                                                      onChange={(event) => this.inputChangeHandler(event, "activate_deactivate_class")}
                                                    />
                                                    <span className="mb-0"> Activate/Deactivate Class </span>
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="sec-row">
                                        <div className="row">
                                          <div className="col-md-2">
                                            <div className="ps-3">
                                              <b>Manage Dormitory</b>
                                            </div>
                                          </div>
                                          <div className="col-md-10">
                                            <div className="row">
                                              <div className="col-sm-4 col-md-3">
                                                <div className="checkbox-inline">
                                                  <label className="checkbox cursor-pointer ">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input me-1"
                                                      value={permission_add_form.add_dormitory.value}
                                                      checked={permission_add_form.add_dormitory.value}
                                                      onChange={(event) => this.inputChangeHandler(event, "add_dormitory")}
                                                    />
                                                    <span className="mb-0">
                                                      Add Dormitory
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="col-sm-4 col-md-3">
                                                <div className="checkbox-inline">
                                                  <label className="checkbox cursor-pointer ">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input me-1"
                                                      value={permission_add_form.edit_dormitory.value}
                                                      checked={permission_add_form.edit_dormitory.value}
                                                      onChange={(event) => this.inputChangeHandler(event, "edit_dormitory")}
                                                    />
                                                    <span className="mb-0">
                                                      Edit Dormitory
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>

                                              <div className="col-sm-4 col-md-3">
                                                <div className="checkbox-inline">
                                                  <label className="checkbox cursor-pointer ">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input me-1"
                                                      value={permission_add_form.view_dormitory.value}
                                                      checked={permission_add_form.view_dormitory.value}
                                                      onChange={(event) => this.inputChangeHandler(event, "view_dormitory")}
                                                    />
                                                    <span className="mb-0">
                                                      View Dormitory
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>

                                              <div className="col-sm-4 col-md-3">
                                                <div className="checkbox-inline">
                                                  <label className="checkbox cursor-pointer ">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input me-1"
                                                      value={permission_add_form.activate_deactivate_dormitory.value}
                                                      checked={permission_add_form.activate_deactivate_dormitory.value}
                                                      onChange={(event) => this.inputChangeHandler(event, "activate_deactivate_dormitory")}
                                                    />
                                                    <span className="mb-0">Activate/Deactivate Dormitory</span>
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-md-12 sec-row">
                                        <div className="row">
                                          <div className="col-md-2">
                                            <div className="ps-3"><b>Manage School Profile</b></div>
                                          </div>
                                          <div className="col-md-10">
                                            <div className="row">
                                              <div className="col-sm-4">
                                                <div className="checkbox-inline">
                                                  <label className="checkbox cursor-pointer ">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input me-1"
                                                      value={permission_add_form.edit_school_profile.value}
                                                      checked={permission_add_form.edit_school_profile.value}
                                                      onChange={(event) => this.inputChangeHandler(event, "edit_school_profile")}
                                                    />
                                                    <span className="mb-0">
                                                      Edit School Profile
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>

                                              <div className="col-sm-4">
                                                <div className="checkbox-inline">
                                                  <label className="checkbox cursor-pointer ">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input me-1"
                                                      value={permission_add_form.view_school_profile.value}
                                                      checked={permission_add_form.view_school_profile.value}
                                                      onChange={(event) => this.inputChangeHandler(event, "view_school_profile")}
                                                    />
                                                    <span className="mb-0">
                                                      View School Profile
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-md-12 sec-row">
                                        <div className="row">
                                          <div className="col-md-2 text-start">
                                            <div className="ps-3">
                                              <b>Manage Managers</b>
                                            </div>
                                          </div>
                                          <div className="col-md-10">
                                            <div className="row">
                                              <div className="col-sm-3">
                                                <div className="checkbox-inline">
                                                  <label className="checkbox cursor-pointer ">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input me-1"
                                                      value={permission_add_form.add_user.value}
                                                      checked={permission_add_form.add_user.value}
                                                      onChange={(event) => this.inputChangeHandler(event, "add_user")}
                                                    />
                                                    <span className="mb-0">
                                                      Add Manager
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="col-sm-3">
                                                <div className="checkbox-inline">
                                                  <label className="checkbox cursor-pointer ">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input me-1"
                                                      value={permission_add_form.edit_user.value}
                                                      checked={permission_add_form.edit_user.value}
                                                      onChange={(event) => this.inputChangeHandler(event, "edit_user")}
                                                    />
                                                    <span className="mb-0">
                                                      Edit Manager
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>

                                              <div className="col-sm-3">
                                                <div className="checkbox-inline">
                                                  <label className="checkbox cursor-pointer ">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input me-1"
                                                      value={permission_add_form.view_user.value}
                                                      checked={permission_add_form.view_user.value}
                                                      onChange={(event) => this.inputChangeHandler(event, "view_user")}
                                                    />
                                                    <span className="mb-0">
                                                      View Managers
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="col-sm-3">
                                                <div className="checkbox-inline">
                                                  <label className="checkbox cursor-pointer ">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input me-1"
                                                      value={permission_add_form.activate_deactivate_user.value}
                                                      checked={permission_add_form.activate_deactivate_user.value}
                                                      onChange={(event) => this.inputChangeHandler(event, "activate_deactivate_user")}
                                                    />
                                                    <span className="mb-0">
                                                      Manager Archive
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>


                                      <div className="sec-row">
                                        <div className="row">
                                          <div className="col-md-2">
                                            <div className="ps-3">
                                              <b>Manage Role and Permissions</b>
                                            </div>
                                          </div>
                                          <div className="col-md-10">
                                            <div className="row">
                                              <div className="col-sm-4 col-md-3">
                                                <div className="checkbox-inline">
                                                  <label className="checkbox cursor-pointer ">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input me-1"
                                                      value={permission_add_form.edit_permission.value}
                                                      checked={permission_add_form.edit_permission.value}
                                                      onChange={(event) => this.inputChangeHandler(event, "edit_permission")}
                                                    />
                                                    <span className="mb-0">
                                                      Edit Permission
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>

                                              <div className="col-sm-4 col-md-3">
                                                <div className="checkbox-inline">
                                                  <label className="checkbox cursor-pointer ">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input me-1"
                                                      value={permission_add_form.view_permission.value}
                                                      checked={permission_add_form.view_permission.value}
                                                      onChange={(event) => this.inputChangeHandler(event, "view_permission")
                                                      }
                                                    />
                                                    <span className="mb-0">
                                                      View Permission
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>

                                              <div className="col-sm-4 col-md-3">
                                                <div className="checkbox-inline">
                                                  <label className="checkbox cursor-pointer ">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input me-1"
                                                      value={permission_add_form.add_role.value}
                                                      checked={permission_add_form.add_role.value}
                                                      onChange={(event) => this.inputChangeHandler(event, "add_role")}
                                                    />
                                                    <span className="mb-0">
                                                      Add Role Name
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>

                                              <div className="col-sm-4 col-md-3">
                                                <div className="checkbox-inline">
                                                  <label className="checkbox cursor-pointer ">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input me-1"
                                                      value={permission_add_form.edit_role.value}
                                                      checked={permission_add_form.edit_role.value}
                                                      onChange={(event) => this.inputChangeHandler(event, "edit_role")}
                                                    />
                                                    <span className="mb-0">
                                                      Edit Role Name
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="col-sm-4 col-md-3 mt-4">
                                                <div className="checkbox-inline">
                                                  <label className="checkbox cursor-pointer ">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input me-1"
                                                      value={permission_add_form.add_duplicate_role.value}
                                                      checked={permission_add_form.add_duplicate_role.value}
                                                      onChange={(event) => this.inputChangeHandler(event, 'add_duplicate_role')}
                                                    />
                                                    <span className="mb-0">
                                                      Add Duplicate Role
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="col-sm-4 col-md-3 mt-4">
                                                <div className="checkbox-inline">
                                                  <label className="checkbox cursor-pointer ">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input me-1"
                                                      value={permission_add_form.deactivate_role.value}
                                                      checked={permission_add_form.deactivate_role.value}
                                                      onChange={(event) => this.inputChangeHandler(event, "deactivate_role")}
                                                    />
                                                    <span className="mb-0">
                                                      Deactivate Role
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="col-sm-4 col-md-3 mt-4">
                                                <div className="checkbox-inline">
                                                  <label className="checkbox cursor-pointer ">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input me-1"
                                                      value={permission_add_form.delete_role.value}
                                                      checked={permission_add_form.delete_role.value}
                                                      onChange={(event) => this.inputChangeHandler(event, "delete_role")}
                                                    />
                                                    <span className="mb-0">
                                                      Delete Role
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {/* <div className="col-md-12 sec-row manage-gender">
                                        <div className="row">
                                          <div className="col-md-2">


                                            <div className="ps-3"><b>Manage Genders</b></div>
                                          </div>
                                          <div className="col-md-10">
                                            <div className="row">
                                              <div className="col-sm-4">
                                                <label className="cursor-pointer">
                                                  <input
                                                    type="radio"
                                                    id="gender1"
                                                    name="gen"
                                                    className="form-check-input me-2 cursor-pointer"
                                                    value={permission_add_form.snapshot_gender_male.value}
                                                    checked={permission_add_form.snapshot_gender_male.value === true ? permission_add_form.snapshot_gender_male.value === true : null}
                                                    onChange={(event) => this.inputChangeHandler(event, "snapshot_gender_male")}
                                                  />
                                                  Male
                                                </label>
                                              </div>
                                              <div className="col-sm-4">
                                                <label className="cursor-pointer">
                                                  <input
                                                    type="radio"
                                                    id="gender2"
                                                    name="gen"
                                                    className="form-check-input me-2 cursor-pointer"
                                                    value={permission_add_form.snapshot_gender_female.value}
                                                    checked={permission_add_form.snapshot_gender_female.value === true ? permission_add_form.snapshot_gender_female.value === true : null}
                                                    onChange={(event) => this.inputChangeHandler(event, "snapshot_gender_female")}
                                                  />
                                                  Female
                                                </label>
                                              </div>
                                              <div className="col-sm-4">
                                                <label className="cursor-pointer">
                                                  <input
                                                    type="radio"
                                                    id="genderall"
                                                    name="gen"
                                                    className="form-check-input me-2 cursor-pointer"
                                                    value={permission_add_form.snapshot_gender_both.value}
                                                    checked={permission_add_form.snapshot_gender_both.value === true ? permission_add_form.snapshot_gender_both.value === true : null}
                                                    onChange={(event) => this.inputChangeHandler(event, "snapshot_gender_both")}
                                                  />
                                                  Both
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div> */}

                                      <div className=" sec-row ">
                                        <div className="row">
                                          <div className="col-md-2">
                                            <div className="ps-3">
                                              <b>Snapshot</b>
                                            </div>
                                          </div>
                                          <div className="col-md-10">
                                            <div className="row">
                                              <div className="col-sm-4 col-md-3">
                                                <div className="checkbox-inline">
                                                  <label className="checkbox cursor-pointer ">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input me-1"
                                                      value={permission_add_form.view_snapshot.value}
                                                      checked={permission_add_form.view_snapshot.value}
                                                      onChange={(event) => this.inputChangeHandler(event, "view_snapshot")
                                                      }
                                                    />
                                                    <span className="mb-0">
                                                      View Snapshot
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className=" sec-row">
                                        <div className="row">
                                          <div className="col-md-2">
                                            <div className="ps-3">
                                              <b>Manage Activities</b>
                                            </div>
                                          </div>
                                          <div className="col-md-10">
                                            <div className="row">
                                              <div className="col-sm-4 col-md-3">
                                                <div className="checkbox-inline">
                                                  <label className="checkbox cursor-pointer ">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input me-1"
                                                      value={permission_add_form.view_activities.value}
                                                      checked={permission_add_form.view_activities.value}
                                                      onChange={(event) => this.inputChangeHandler(event, "view_activities")
                                                      }
                                                    />
                                                    <span className="mb-0">
                                                      View Activities
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>



                                  <div className="permission-wrap mb-4">
                                    <div className="d-flex permission-title">
                                      <div className="title-left">
                                        <h6 className="mb-0">Students</h6>
                                      </div>
                                    </div>
                                    <div className=" sec-row">
                                      <div className="row">
                                        <div className="col-md-2">
                                          <div className="ps-3">
                                            <b>Student list</b>
                                          </div>
                                        </div>
                                        <div className="col-md-10">
                                          <div className="row">
                                            <div className="col-sm-4 col-md-3">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.view_student.value}
                                                    checked={permission_add_form.view_student.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "view_student")}
                                                  />
                                                  <span className="mb-0">
                                                    View Student
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-sm-4 col-md-3">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.activate_deactivate_student.value}
                                                    checked={permission_add_form.activate_deactivate_student.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "activate_deactivate_student")}
                                                  />
                                                  <span className="mb-0">
                                                    Activate/Deactivate Student
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-sm-4 col-md-3">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.edit_student.value}
                                                    checked={permission_add_form.edit_student.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "edit_student")}
                                                  />
                                                  <span className="mb-0">
                                                    Edit Student
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-sm-4 col-md-3">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.create_tempid.value}
                                                    checked={permission_add_form.create_tempid.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "create_tempid")}
                                                  />
                                                  <span className="mb-0">
                                                    Create Temp Id
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-sm-4 col-md-3 mt-4">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.delete_student.value}
                                                    checked={permission_add_form.delete_student.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "delete_student")}
                                                  />
                                                  <span className="mb-0">
                                                    Delete Student
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className=" sec-row">
                                      <div className="row">
                                        <div className="col-md-2">
                                          <div className="ps-3">
                                            <b>Add Student</b>
                                          </div>
                                        </div>
                                        <div className="col-md-10">
                                          <div className="row">
                                            <div className="col-sm-4">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.add_student.value}
                                                    checked={permission_add_form.add_student.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "add_student")}
                                                  />
                                                  <span className="mb-0">
                                                    Add Student
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className=" sec-row">
                                      <div className="row">
                                        <div className="col-md-2">
                                          <div className="ps-3">
                                            <b>Allergic Students</b>
                                          </div>
                                        </div>
                                        <div className="col-md-10">
                                          <div className="row">
                                            <div className="col-sm-4 col-md-3">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.add_allergic_students.value}
                                                    checked={permission_add_form.add_allergic_students.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "add_allergic_students")}
                                                  />
                                                  <span className="mb-0">
                                                    Add
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-sm-4 col-md-3">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.view_allergic_students.value}
                                                    checked={permission_add_form.view_allergic_students.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "view_allergic_students")}
                                                  />
                                                  <span className="mb-0">
                                                    View
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-sm-4 col-md-3">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.edit_allergic_students.value}
                                                    checked={permission_add_form.edit_allergic_students.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "edit_allergic_students")}
                                                  />
                                                  <span className="mb-0">
                                                    Edit
                                                  </span>
                                                </label>
                                              </div>
                                            </div>

                                            <div className="col-sm-4 col-md-3">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.delete_allergic_students.value}
                                                    checked={permission_add_form.delete_allergic_students.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "delete_allergic_students")}
                                                  />
                                                  <span className="mb-0">
                                                    Delete
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className=" sec-row">
                                      <div className="row">
                                        <div className="col-md-2">
                                          <div className="ps-3">

                                            <b>Student Reminder</b>
                                          </div>
                                        </div>
                                        <div className="col-md-10">
                                          <div className="row">
                                            <div className="col-sm-4 col-md-3">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.add_flag_students.value}
                                                    checked={permission_add_form.add_flag_students.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "add_flag_students")}
                                                  />
                                                  <span className="mb-0">
                                                    Add
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-sm-4 col-md-3">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.view_flag_students.value}
                                                    checked={permission_add_form.view_flag_students.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "view_flag_students")}
                                                  />
                                                  <span className="mb-0">
                                                    View
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-sm-4 col-md-3">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.edit_flag_students.value}
                                                    checked={permission_add_form.edit_flag_students.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "edit_flag_students")}
                                                  />
                                                  <span className="mb-0">
                                                    Edit
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-sm-4 col-md-3">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.delete_flag_students.value}
                                                    checked={permission_add_form.delete_flag_students.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "delete_flag_students")}
                                                  />
                                                  <span className="mb-0">
                                                    Delete
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className=" sec-row">
                                      <div className="row">
                                        <div className="col-md-2">
                                          <div className="ps-3">
                                            <b>Grounded Student</b>
                                          </div>
                                        </div>
                                        <div className="col-md-10">
                                          <div className="row">
                                            <div className="col-sm-4 col-md-3">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.add_grounded_students.value}
                                                    checked={permission_add_form.add_grounded_students.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "add_grounded_students")}
                                                  />
                                                  <span className="mb-0">
                                                    Add
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-sm-4 col-md-3">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.view_grounded_students.value}
                                                    checked={permission_add_form.view_grounded_students.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "view_grounded_students")}
                                                  />
                                                  <span className="mb-0">
                                                    View
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-sm-4 col-md-3">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.edit_grounded_students.value}
                                                    checked={permission_add_form.edit_grounded_students.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "edit_grounded_students")}
                                                  />
                                                  <span className="mb-0">
                                                    Edit
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-sm-4 col-md-3">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.delete_grounded_students.value}
                                                    checked={permission_add_form.delete_grounded_students.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "delete_grounded_students")}
                                                  />
                                                  <span className="mb-0">
                                                    Delete
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className=" sec-row">
                                      <div className="row">
                                        <div className="col-md-2">
                                          <div className="ps-3">
                                            <b>Import Students</b>
                                          </div>
                                        </div>
                                        <div className="col-md-10">
                                          <div className="row">
                                            <div className="col-sm-4 col-md-3">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.view_import_students.value}
                                                    checked={permission_add_form.view_import_students.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "view_import_students")}
                                                  />
                                                  <span className="mb-0">
                                                    Access
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className=" sec-row">
                                      <div className="row">
                                        <div className="col-md-2">
                                          <div className="ps-3">
                                            <b>Duplicate Students</b>
                                          </div>
                                        </div>
                                        <div className="col-md-10">
                                          <div className="row">
                                            <div className="col-sm-4 col-md-3">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.view_duplicate_students.value}
                                                    checked={permission_add_form.view_duplicate_students.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "view_duplicate_students")}
                                                  />
                                                  <span clps-3assName="mb-0">
                                                    Access
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className=" sec-row border-bottom-0">
                                      <div className="row">
                                        <div className="col-md-2">
                                          <div className="ps-3">
                                            <b>Change Dormitory</b>
                                          </div>
                                        </div>
                                        <div className="col-md-10">
                                          <div className="row">
                                            <div className="col-sm-4 col-md-3">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.view_change_dormitory.value}
                                                    checked={permission_add_form.view_change_dormitory.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "view_change_dormitory")}
                                                  />
                                                  <span className="mb-0">
                                                    Access
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>


                                  <div className="permission-wrap mb-4">
                                    <div className="d-flex permission-title">
                                      <div className="title-left">
                                        <h6 className="mb-0">Setting Tab</h6>
                                      </div>
                                    </div>
                                    <div className=" sec-row">
                                      <div className="row">
                                        <div className="col-md-2">
                                          <div className="ps-3">
                                            <b>Attendance Reports</b>
                                          </div>
                                        </div>
                                        <div className="col-md-10">
                                          <div className="row">
                                            <div className="col-sm-4 col-md-3">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.view_attendance_report.value}
                                                    checked={permission_add_form.view_attendance_report.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "view_attendance_report")}
                                                  />
                                                  <span className="mb-0">
                                                    View
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className=" sec-row">
                                      <div className="row">
                                        <div className="col-md-2">
                                          <div className="ps-3">
                                            <b>Messages</b>
                                          </div>
                                        </div>
                                        <div className="col-md-10">
                                          <div className="row">

                                            <div className="col-sm-4">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.view_message.value}
                                                    checked={permission_add_form.view_message.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "view_message")}
                                                  />
                                                  <span className="mb-0">
                                                    View Message
                                                  </span>
                                                </label>
                                              </div>
                                            </div>

                                            <div className="col-sm-4">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.edit_message.value}
                                                    checked={permission_add_form.edit_message.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "edit_message")}
                                                  />
                                                  <span className="mb-0"> Edit Message</span>
                                                </label>
                                              </div>
                                            </div>

                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className=" sec-row">
                                      <div className="row">
                                        <div className="col-md-2">
                                          <div className="ps-3">
                                            <b>Custom Links</b>
                                          </div>
                                        </div>
                                        <div className="col-md-10">
                                          <div className="row">
                                            <div className="col-sm-4 col-md-3">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.view_custom_links.value}
                                                    checked={permission_add_form.view_custom_links.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "view_custom_links")}
                                                  />
                                                  <span className="mb-0">
                                                    Set
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className=" sec-row">
                                      <div className="row">
                                        <div className="col-md-2">
                                          <div className="ps-3">
                                            <b>Student Rollover</b>
                                          </div>
                                        </div>
                                        <div className="col-md-10">
                                          <div className="row">
                                            <div className="col-sm-4 col-md-3">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.view_rollover_students.value}
                                                    checked={permission_add_form.view_rollover_students.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "view_rollover_students")}
                                                  />
                                                  <span className="mb-0">
                                                    Access
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className=" sec-row">
                                      <div className="row">
                                        <div className="col-md-2">
                                          <div className="ps-3">
                                            <b>Roll Call Duration</b>
                                          </div>
                                        </div>
                                        <div className="col-md-10">
                                          <div className="row">
                                            <div className="col-sm-4 col-md-3">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.view_rollcall_session_time.value}
                                                    checked={permission_add_form.view_rollcall_session_time.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "view_rollcall_session_time")}
                                                  />
                                                  <span className="mb-0">
                                                    Set
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className=" sec-row">
                                      <div className="row">
                                        <div className="col-md-2">
                                          <div className="ps-3">
                                            <b>On Campus Details</b>
                                          </div>
                                        </div>
                                        <div className="col-md-10">
                                          <div className="row">
                                            <div className="col-sm-4 col-md-3">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.view_oncampus_details.value}
                                                    checked={permission_add_form.view_oncampus_details.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "view_oncampus_details")}
                                                  />
                                                  <span className="mb-0">
                                                    Set
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className=" sec-row border-bottom-0">
                                      <div className="row">
                                        <div className="col-md-2">
                                          <div className="ps-3">
                                            <b>Calendar</b>
                                          </div>
                                        </div>
                                        <div className="col-md-10">
                                          <div className="row">
                                            <div className="col-sm-4 col-md-4">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.view_calendar.value}
                                                    checked={permission_add_form.view_calendar.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "view_calendar")}
                                                  />
                                                  <span className="mb-0">
                                                    View
                                                  </span>
                                                </label>
                                              </div>
                                            </div>

                                            <div className="col-sm-4 col-md-4">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.edit_calendar.value}
                                                    checked={permission_add_form.edit_calendar.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "edit_calendar")}
                                                  />
                                                  <span className="mb-0">
                                                    Edit
                                                  </span>
                                                </label>
                                              </div>
                                            </div>

                                            <div className="col-sm-4 col-md-4">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.add_calendar.value}
                                                    checked={permission_add_form.add_calendar.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "add_calendar")}
                                                  />
                                                  <span className="mb-0">
                                                    Add
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>


                                  <div className="permission-wrap">
                                    <div className="d-flex permission-title">
                                      <div className="title-left">
                                        <h6 className="mb-0">Leave Permissions</h6>
                                      </div>
                                    </div>


                                    <div className=" sec-row">
                                      <div className="row">
                                        <div className="col-md-2">
                                          <div className="ps-3">
                                            <b>Manage Boarders Leave</b>
                                          </div>
                                        </div>
                                        <div className="col-md-10">
                                          <div className="row">
                                            <div className="col-sm-4">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.view_boarder_leaves.value}
                                                    checked={permission_add_form.view_boarder_leaves.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "view_boarder_leaves")}
                                                  />
                                                  <span className="mb-0">
                                                    View
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-sm-4">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.add_boarder_leaves.value}
                                                    checked={permission_add_form.add_boarder_leaves.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "add_boarder_leaves")}
                                                  />
                                                  <span className="mb-0">
                                                    Add
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-sm-4">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.edit_boarder_leaves.value}
                                                    checked={permission_add_form.edit_boarder_leaves.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "edit_boarder_leaves")}
                                                  />
                                                  <span className="mb-0">
                                                    Edit
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className=" sec-row">
                                      <div className="row">
                                        <div className="col-md-2">
                                          <div className="ps-3">
                                            <b>Manage Adhoc Leave</b>
                                          </div>
                                        </div>
                                        <div className="col-md-10">
                                          <div className="row">
                                            <div className="col-sm-4">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.view_adhoc_leave.value}
                                                    checked={permission_add_form.view_adhoc_leave.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "view_adhoc_leave")}
                                                  />
                                                  <span className="mb-0">
                                                    View
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-sm-4">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.add_adhoc_leave.value}
                                                    checked={permission_add_form.add_adhoc_leave.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "add_adhoc_leave")}
                                                  />
                                                  <span className="mb-0">
                                                    Add
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-sm-4">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.edit_adhoc_leave.value}
                                                    checked={permission_add_form.edit_adhoc_leave.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "edit_adhoc_leave")}
                                                  />
                                                  <span className="mb-0">
                                                    Edit
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className=" sec-row">
                                      <div className="row">
                                        <div className="col-md-2">
                                          <div className="ps-3">
                                            <b>Manage Reports</b>
                                          </div>
                                        </div>
                                        <div className="col-md-10">
                                          <div className="row">
                                            <div className="col-sm-4">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.view_reports.value}
                                                    checked={permission_add_form.view_reports.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "view_reports")}
                                                  />
                                                  <span className="mb-0">
                                                    View
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className=" sec-row">
                                      <div className="row">
                                        <div className="col-md-2">
                                          <div className="ps-3">
                                            <b>Manage Travel Mode</b>
                                          </div>
                                        </div>
                                        <div className="col-md-10">
                                          <div className="row">
                                            <div className="col-sm-3">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.view_travel_mode.value}
                                                    checked={permission_add_form.view_travel_mode.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "view_travel_mode")}
                                                  />
                                                  <span className="mb-0">
                                                    View
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-sm-3">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.add_travel_mode.value}
                                                    checked={permission_add_form.add_travel_mode.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "add_travel_mode")}
                                                  />
                                                  <span className="mb-0">
                                                    Add
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-sm-3">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.edit_travel_mode.value}
                                                    checked={permission_add_form.edit_travel_mode.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "edit_travel_mode")}
                                                  />
                                                  <span className="mb-0">
                                                    Edit
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-sm-3">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.delete_travel_mode.value}
                                                    checked={permission_add_form.delete_travel_mode.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "delete_travel_mode")}
                                                  />
                                                  <span className="mb-0">
                                                    Delete
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-sm-3">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.activate_deactivate_travel_mode.value}
                                                    checked={permission_add_form.activate_deactivate_travel_mode.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "activate_deactivate_travel_mode")}
                                                  />
                                                  <span className="mb-0">
                                                    Activate/Deactivate Mode
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className=" sec-row">
                                      <div className="row">
                                        <div className="col-md-2">
                                          <div className="ps-3">
                                            <b>Manage Leave Rejection Reason</b>
                                          </div>
                                        </div>
                                        <div className="col-md-10">
                                          <div className="row">
                                            <div className="col-sm-3">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.view_rejection_reason.value}
                                                    checked={permission_add_form.view_rejection_reason.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "view_rejection_reason")}
                                                  />
                                                  <span className="mb-0">
                                                    View
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-sm-3">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.add_rejection_reason.value}
                                                    checked={permission_add_form.add_rejection_reason.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "add_rejection_reason")}
                                                  />
                                                  <span className="mb-0">
                                                    Add
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-sm-3">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.edit_rejection_reason.value}
                                                    checked={permission_add_form.edit_rejection_reason.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "edit_rejection_reason")}
                                                  />
                                                  <span className="mb-0">
                                                    Edit
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-sm-3">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.delete_rejection_reason.value}
                                                    checked={permission_add_form.delete_rejection_reason.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "delete_rejection_reason")}
                                                  />
                                                  <span className="mb-0">
                                                    Delete
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-sm-3">
                                              <div className="checkbox-inline">
                                                <label className="checkbox cursor-pointer ">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    value={permission_add_form.activate_deactivate_rejection_reason.value}
                                                    checked={permission_add_form.activate_deactivate_rejection_reason.value}
                                                    onChange={(event) => this.inputChangeHandler(event, "activate_deactivate_rejection_reason")}
                                                  />
                                                  <span className="mb-0">
                                                    Activate/Deactivate Reason
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>



                                <div className="d-flex border-0 mt-4 justify-content-end">
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-light me-2 min-150px"
                                    onClick={(event) =>
                                      this.setState({ show_modal: true })
                                    }
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="submit"
                                    className="btn btn-sm btn-primary min-150px"
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Footer />
              </div>
            </div>

            <div
              id="kt_scrolltop"
              className="scrolltop"
              data-kt-scrolltop="true"
            >
              <span className="svg-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="13"
                    y="6"
                    width="13"
                    height="2"
                    rx="1"
                    transform="rotate(90 13 6)"
                    fill="currentColor"
                  />
                  <path
                    d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
const mapDispatchToProps = {
  onCreateOrUpdateRolePermissions: userActions.CreateOrUpdateRolePermissions,
  onGetPermissionsByRoleId: userActions.GetPermissionsByRoleId,
  onGetAllDormitoryStudent: userActions.GetAllDormitoryStudent,
  onGetAllClassesList: userActions.GetAllClassesList,
  onGetLoginUserPermissions: userActions.GetLoginUserPermissions,
};
export default connect(mapStateToProps, mapDispatchToProps)(PermissionEdit);