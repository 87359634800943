import React from "react";
import Validations from "../../Utility/Validations";
import * as userActions from "../../../actions/index";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";
import { Navigate } from "react-router-dom";
import SideBarLeave from "../../Constant/SideBarLeave";

const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info.school_code;
class AddHocActivity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            AddHocActivity: {
                activity_name: {
                    type: "text",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Activity Name",
                    validations: { required: true },
                },

            },

        };
    }



    inputChangeHandler(event, identifier) {
        const update_AddHocActivity = { ...this.state.AddHocActivity };
        const form_element = { ...update_AddHocActivity[identifier] };
        form_element.value = event.target.value;
        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_AddHocActivity[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_AddHocActivity) {
            is_form_valid = update_AddHocActivity[identifier].valid && is_form_valid;
        }
        this.setState({
            AddHocActivity: update_AddHocActivity,
            is_form_valid: is_form_valid,
        });
    }
    SubmitHandler = async (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_AddHocActivity = this.state.AddHocActivity;
        for (let key in update_AddHocActivity) {
            let form_element = update_AddHocActivity[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_AddHocActivity[key] = form_element;
        }
        this.setState({ AddHocActivity: update_AddHocActivity });
        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.AddHocActivity) {
                form_data[key] = this.state.AddHocActivity[key].value;
            }
            this.props.onCreateActivity(form_data).then((response) => {
                if (response.success === true) {
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: `/${school_code}/leave/activity-list`,
                        });
                    }, 1000);
                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            });
        }

    };
    render() {

        const { AddHocActivity } = this.state;
        console.log(AddHocActivity);
        return (
            <React.Fragment>
                {this.state.isAuthenticated ? <Navigate to={this.state.redirect_url} /> : null}

                <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed" >
                    <div className="d-flex flex-column flex-root">
                        <div className="page d-flex flex-row flex-column-fluid">
                            <SideBarLeave />
                            <div className="wrapper d-flex flex-column flex-row-fluid " id="kt_wrapper">
                                <TopBar />
                                <div className="content d-flex flex-column flex-column-fluid " id="kt_content">

                                    <div className="post d-flex flex-column-fluid" id="kt_post">

                                        <div id="kt_content_container" className="container-fluid d-flex justify-content-center">
                                            <div className="col-md-6">
                                                <div className="card">
                                                    <div className="card-header border-bottom pt-5 pb-5">
                                                        <h3 className="card-title align-items-start flex-column">
                                                            Add Activity
                                                        </h3>

                                                    </div>
                                                    <div className="card-body">
                                                        <form className="form w-100" onSubmit={this.SubmitHandler}>
                                                            <div className="row">
                                                                <div className="col-lg-12 fv-row">
                                                                    <div className="d-flex flex-column align-items-center">

                                                                        <input
                                                                            type={AddHocActivity.activity_name.type}
                                                                            value={AddHocActivity.activity_name.value ? AddHocActivity.activity_name.value : ""}
                                                                            className="form-control"
                                                                            placeholder={AddHocActivity.activity_name.placeholder}
                                                                            onChange={(event) => this.inputChangeHandler(event, "activity_name")}
                                                                        />

                                                                        {!AddHocActivity.activity_name.valid && AddHocActivity.activity_name.onBlur_out ? <div className="error field-error">{AddHocActivity.activity_name.error_msg}</div> : ""}
                                                                        <button type="submit" className="btn btn-sm btn-primary ms-4 px-8 mt-5">
                                                                            Save
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    <Footer />
                            
                            </div>
                        </div>
                    </div>
                </div>


            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onCreateActivity: userActions.CreateActivity,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddHocActivity);
