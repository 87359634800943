import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
// import files
import * as userActions from '../../../actions/index';
import Validations from "../../Utility/Validations";
import Footer from "../../Constant/Footer";
import TopBar from "../../Constant/TopBar";
import SideBarLeave from "../../Constant/SideBarLeave";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionType from "../../../constants/actionTypes";
import moment from "moment";
import { Navigate } from "react-router-dom";
import ReactPhoneInput from 'react-phone-input-2'
import DatePicker from "react-datepicker";

const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info?.school_code;
function withParams(Component) {
    return props => <Component {...props} location={useLocation()} />;
}
class EditAdhocLeave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            adhoc_leave_uuid: "",
            student_data: {
                student_name: {
                    type: "text",
                    label: "Student Name",
                    value: '',
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                dormitory_name: {
                    type: "text",
                    label: "Dormitory Name",
                    value: '',
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                year_name: {
                    type: "text",
                    label: "Year Name",
                    value: '',
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                gender: {
                    type: "text",
                    label: "Gender",
                    value: '',
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                dormitory_contact: {
                    type: "number",
                    label: "Dormitory Contact",
                    value: '',
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                unique_pin: {
                    type: "text",
                    label: "Unique Pin",
                    value: '',
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                student_email: {
                    type: "email",
                    label: "Student E-Mail Address",
                    value: '',
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                student_mobile: {
                    type: "number",
                    label: "Student's Mobile Number",
                    value: '',
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
               
                avatar: {
                    type: "image",
                    label: "avatar",
                    value: '',
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
            },
            adhoc_leave_edit_form: {
                weekends: {
                    type: "text",
                    label: "Leave Applied For",
                    value: '',
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                activity: {
                    type: "hidden",
                    label: "Activity",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                venue: {
                    type: "text",
                    label: "Venue",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },


                departure_date: {
                    type: "date",
                    label: "Program Start Date",
                    value: '',
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                start_time: {
                    type: "time",
                    label: "Start Time",
                    value: '',
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                departure_travel_mode: {
                    type: "hidden",
                    label: "Travel Out",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },


                arrival_travel_mode: {
                    type: "hidden",
                    label: "Travel In",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                arrival_date: {
                    type: "date",
                    label: "Program End Date",
                    value: '',
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                end_time: {
                    type: "time",
                    label: "End Time",
                    value: '',
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },

                parents_inst: {
                    type: "text",
                    label: "Parent Instructions",
                    value: '',
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Instructions",
                    validations: { required: true },
                },
                manager_inst: {
                    type: "text",
                    label: "Manager Instructions",
                    value: '',
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Instructions",
                    validations: { required: true },
                },
                created_date_and_time: {
                    type: "text",
                    label: "Adhoc Leave Submit date and time",
                    value: '',
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Instructions",
                    validations: { required: false },
                },
            }
        }
    }


    componentDidMount() {
        this.GetAdhocLeaveDetailById()
    }

    GetAdhocLeaveDetailById() {
        this.setState({ adhoc_leave_uuid: this.props.adhoc_leave_detail_uuid })
        this.props.onGetAdhocLeaveDetailById(this.props.adhoc_leave_detail_uuid).then((response) => {
            if (response.success === true) {

                const student_data = response.data.adhoc_stu_data[0];
                const adhoc_leave_data = response.data
                const update_student_data = this.state.student_data
                const update_adhoc_leave_edit_form = { ...this.state.adhoc_leave_edit_form }
                update_student_data['student_name'].value = student_data.student_first_name + " " + student_data.student_last_name;
                update_student_data['avatar'].value = student_data.student_avatar;
                update_student_data['dormitory_name'].value = student_data.dormitory_data.dormitory_name;
                update_student_data['year_name'].value = student_data.class_name;
                update_student_data['gender'].value = student_data.gender;
                update_student_data['dormitory_contact'].value = student_data.dormitory_data.dormitory_contact;
                update_student_data['student_email'].value = student_data.student_email;
                update_student_data['student_mobile'].value = student_data.student_phone;
                update_student_data['unique_pin'].value = student_data.unique_pin;

                update_adhoc_leave_edit_form['start_time'].value = moment(adhoc_leave_data.start_time, "HH:mm:ss").format("hh:mm A")
                update_adhoc_leave_edit_form['departure_date'].value = moment(adhoc_leave_data.start_date).format('Do MMM. YYYY')
                update_adhoc_leave_edit_form['departure_travel_mode'].value = adhoc_leave_data.depart_mode_data.travel_mode_name

                update_adhoc_leave_edit_form['end_time'].value = moment(adhoc_leave_data.end_time, "HH:mm:ss").format("hh:mm A")
                update_adhoc_leave_edit_form['arrival_date'].value = moment(adhoc_leave_data.end_date).format('Do MMM. YYYY')
                update_adhoc_leave_edit_form['arrival_travel_mode'].value = adhoc_leave_data.arrival_mode_data.travel_mode_name


                update_adhoc_leave_edit_form['parents_inst'].value = adhoc_leave_data.parents_inst
                update_adhoc_leave_edit_form['manager_inst'].value = adhoc_leave_data.manager_inst

                update_adhoc_leave_edit_form['activity'].value = adhoc_leave_data.adhoc_activity_data.activity_name
                update_adhoc_leave_edit_form['weekends'].value = adhoc_leave_data.weekends
                update_adhoc_leave_edit_form['venue'].value = adhoc_leave_data.venue
                update_adhoc_leave_edit_form['created_date_and_time'].value = moment(adhoc_leave_data.created_date).format("Do MMM, YYYY  hh:mm A")
                this.setState({ student_data: update_student_data })
                // this.setState({
                //     student_data: update_student_data,
                //     adhoc_leave_edit_form: update_adhoc_leave_edit_form
                //   });

                const dateTimeStringstart = `${adhoc_leave_data.start_date}T${adhoc_leave_data.start_time}`;
                const dateTimeStringend = `${adhoc_leave_data.end_date}T${adhoc_leave_data.end_time}`;

                const dateTimestart = new Date(dateTimeStringstart);
                const dateTimeend = new Date(dateTimeStringend);

          
                update_adhoc_leave_edit_form['start_time'].value = dateTimestart;
                update_adhoc_leave_edit_form['end_time'].value = dateTimeend;

                this.setState({ adhoc_leave_edit_form: update_adhoc_leave_edit_form })
            }
        });
    }

    propgramTimeHandler(e, identifier) {
        const update_adhoc_leave_form = this.state.adhoc_leave_edit_form;
        const form_element = { ...update_adhoc_leave_form[identifier] };
        form_element.value = e;
        // Check Validation
        // let validate_element = Validations(form_element);
        // form_element.valid = validate_element.valid;
        // form_element.error_msg = validate_element.error_msg;
        // let is_form_valid = true;
        // for (let identifier in update_adhoc_leave_form) {
        //     is_form_valid = update_adhoc_leave_form[identifier].valid && is_form_valid;
        // }
        update_adhoc_leave_form[identifier] = form_element;
        this.setState({ adhoc_leave_edit_form: update_adhoc_leave_form })
    }

    inputChangeHandler(e, identifire) {
        const update_adhoc_leave_edit_form = { ...this.state.adhoc_leave_edit_form }
        update_adhoc_leave_edit_form[identifire].value = e.target.value
        this.setState({ adhoc_leave_edit_form: update_adhoc_leave_edit_form })
    }

    SubmitHandler = async (e) => {
        e.preventDefault();
        let is_form_valid = true;
        const { parents_inst, manager_inst, start_time } = this.state.adhoc_leave_edit_form;
        // const for_error_message = { parents_inst, manager_inst }
        // console.log("for_error_message", for_error_message);
        // for (let key in for_error_message) {
        //     let form_element = for_error_message[key];
        //     let validate_element = Validations(form_element);
        //     is_form_valid = validate_element.valid === false ? false : is_form_valid;
        //     form_element.onBlur_out = true;
        //     form_element.valid = validate_element.valid;
        //     form_element.error_msg = validate_element.error_msg;
        //     for_error_message[key] = form_element;
        // }
        // this.setState({ ...this.state.adhoc_leave_edit_form, parents_inst: for_error_message.parents_inst, manager_inst: for_error_message.manager_inst })
        // if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.adhoc_leave_edit_form) {
                if (key === "manager_inst") {
                    form_data[key] = this.state.adhoc_leave_edit_form[key].value;
                }
                if (key === "parents_inst") {
                    form_data[key] = this.state.adhoc_leave_edit_form[key].value;
                }
                if (key === "start_time") {
                    form_data[key] = moment(this.state.adhoc_leave_edit_form[key].value, "hh:mm A").format("HH:mm");
                }
                if (key === "end_time") {
                    form_data[key] = moment(this.state.adhoc_leave_edit_form[key].value, "hh:mm A").format("HH:mm");
                }
            }
            form_data["adhoc_leave_detail_uuid"] = this.state.adhoc_leave_uuid;
            this.props.onEditAdhocLeave(form_data).then((response) => {
                if (response.success === true) {
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: `/${school_code}/leave/Adhoc-leave-details`,
                        });
                    }, 500);
                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            })

        // }


    }


    render() {
        const { adhoc_leave_edit_form } = this.state
        const { student_data } = this.state
        return (
            <>
                {this.state.isAuthenticated ? <Navigate to={this.state.redirect_url} /> : null}
                <div
                    id="kt_body"
                    className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed"
                >
                    <div className="d-flex flex-column flex-root">
                        <div className="page d-flex flex-row flex-column-fluid">
                            <SideBarLeave />
                            <div
                                className="wrapper d-flex flex-column flex-row-fluid "
                                id="kt_wrapper"
                            >
                                <TopBar />
                                <div
                                    className="content d-flex flex-column flex-column-fluid "
                                    id="kt_content"
                                >
                                    <div className="post d-flex flex-column-fluid" id="kt_post">
                                        <div id="kt_content_container" className="container-fluid">
                                            <form onSubmit={this.SubmitHandler}>
                                                <div className="card ">
                                                    <div className="card-header align-items-center">
                                                        <h3 class="m-0 fw-bolder">Student Details</h3>
                                                        <Link to={`/${school_code}/leave/Adhoc-leave-details`} className='d-flex align-items-center'><i className="fa fa-angle-left me-3" aria-hidden="true"></i>Back</Link>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="profile-image-out p-0">{ }
                                                                    {student_data.avatar.value === null || student_data.avatar.value === "" ?
                                                                        <img src={student_data.gender.value === "male" ? "/media/avatars/boy.jpg" : "/media/avatars/girl.jpg"}
                                                                            alt='Student Avatar'
                                                                            className="img-thumbnail  custom-thumbnail"
                                                                        />
                                                                        :
                                                                        <img src={`${actionType.environment_url}/${student_data.avatar.value}`}
                                                                            alt='Student Avatar'
                                                                            className="img-thumbnail  custom-thumbnail"
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="mb-4">
                                                                    <label className='fw-bold mb-3'>{student_data.student_name.label}</label>
                                                                    <input
                                                                        type="text"
                                                                        value={student_data.student_name.value}
                                                                        className="form-control"
                                                                        readOnly
                                                                    />
                                                                </div>
                                                                <div className="mb-4">
                                                                    <label className='fw-bold mb-3'>{student_data.year_name.label}</label>
                                                                    <input
                                                                        type="text"
                                                                        value={student_data.year_name.value}
                                                                        className="form-control"
                                                                        readOnly
                                                                    />
                                                                </div>
                                                                <div className="mb-4">
                                                                    <label className='fw-bold mb-3'>{student_data.dormitory_name.label}</label>
                                                                    <input
                                                                        type="text"
                                                                        value={student_data.dormitory_name.value}
                                                                        className="form-control"
                                                                        readOnly
                                                                    />
                                                                </div>
                                                                <div className="mb-4">
                                                                    <label className='fw-bold mb-3'>{student_data.gender.label}</label>
                                                                    <input
                                                                        type="text"
                                                                        value={student_data.gender.value}
                                                                        className="form-control"
                                                                        readOnly
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="mb-4">
                                                                    <label className='fw-bold mb-3'>{student_data.student_email.label}</label>
                                                                    <input
                                                                        type="text"
                                                                        value={student_data.student_email.value}
                                                                        className="form-control"
                                                                        readOnly
                                                                    />
                                                                </div>
                                                                <div className="mb-4">
                                                                    <label className='fw-bold mb-3'>{student_data.student_mobile.label}</label>
                                                                    <ReactPhoneInput
                                                                        value={student_data.student_mobile.value}
                                                                        disabled = {true}
                                                                    />
                                                                </div>
                                                                <div className="mb-4">
                                                                    <label className='fw-bold mb-3'>{student_data.unique_pin.label}</label>
                                                                    <input
                                                                        type="text"
                                                                        value={student_data.unique_pin.value}
                                                                        className="form-control"
                                                                        readOnly
                                                                    />
                                                                    
                                                                </div>
                                                                <div className="mb-4">
                                                                    <label className='fw-bold mb-3'>{student_data.dormitory_contact.label}</label>
                                                                    <ReactPhoneInput 
                                                                        value={student_data.dormitory_contact.value}
                                                                        disabled = {true}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {console.log("response", adhoc_leave_edit_form)}

                                                <div className="card mt-5">
                                                    <div className="card-header align-items-center">
                                                        <h3 class="m-0 fw-bolder">Adhoc Leave Details</h3>
                                                        <div>
                                                            <label className='fw-bold'>Adhoc Leave Submit Date/Time : </label>
                                                            <span>{" " + adhoc_leave_edit_form.created_date_and_time.value}</span>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="mb-4">
                                                                    <label className='fw-bold mb-3'>Departure Date</label>
                                                                    <input
                                                                        type="text"
                                                                        value={adhoc_leave_edit_form.departure_date.value}
                                                                        className="form-control"
                                                                        readOnly
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-4">
                                                                <div className="mb-4">
                                                                    <label className='fw-bold mb-3'>Departure Time</label>
                                                                    {/* <input
                                                                        type="text"
                                                                        value={adhoc_leave_edit_form.start_time.value}
                                                                        className="form-control"
                                                                        readOnly
                                                                    /> */}
                                                                    <DatePicker
                                                                        wrapperClassName="adhoc_leave_form_datePicker_width"
                                                                        className="form-control bg-transparent w-100"
                                                                        selected={adhoc_leave_edit_form.start_time.value !== "" ? adhoc_leave_edit_form.start_time.value : ""}
                                                                        onChange={(event) => this.propgramTimeHandler(event, "start_time")}
                                                                        showTimeSelect
                                                                        showTimeSelectOnly
                                                                        timeIntervals={15}
                                                                        timeCaption="Time"
                                                                        dateFormat="h:mm aa"
                                                                        placeholderText={adhoc_leave_edit_form.start_time.placeholder}

                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-4">
                                                                <div className="mb-4">
                                                                    <label className='fw-bold mb-3'>Mode Of Travel On Departure</label>
                                                                    <input
                                                                        value={adhoc_leave_edit_form.departure_travel_mode.value}
                                                                        type="text"
                                                                        className="form-control"
                                                                        readOnly
                                                                    />
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="row">

                                                            <div className="col-md-4">
                                                                <div className="mb-4">
                                                                    <label className='fw-bold mb-3'>Return Date</label>
                                                                    <input
                                                                        type="text"
                                                                        value={adhoc_leave_edit_form.arrival_date.value}
                                                                        className="form-control"
                                                                        readOnly
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-4">
                                                                <div className="mb-4">
                                                                    <label className='fw-bold mb-3'>Return Time</label>
                                                                    {/* <input
                                                                        type="text"
                                                                        value={adhoc_leave_edit_form.end_time.value}
                                                                        className="form-control"
                                                                        readOnly
                                                                    /> */}
                                                                     <DatePicker
                                                                        wrapperClassName="adhoc_leave_form_datePicker_width"
                                                                        className="form-control bg-transparent w-100"
                                                                        selected={adhoc_leave_edit_form.end_time.value !== "" ? adhoc_leave_edit_form.end_time.value : ""}
                                                                        onChange={(event) => this.propgramTimeHandler(event, "end_time")}
                                                                        showTimeSelect
                                                                        showTimeSelectOnly
                                                                        timeIntervals={15}
                                                                        timeCaption="Time"
                                                                        dateFormat="h:mm aa"
                                                                        placeholderText={adhoc_leave_edit_form.end_time.placeholder}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-4">
                                                                <div className="mb-4">
                                                                    <label className='fw-bold mb-3'>Mode of Travel On Return</label>
                                                                    <input
                                                                        type="text"
                                                                        value={adhoc_leave_edit_form.arrival_travel_mode.value}
                                                                        className="form-control"
                                                                        readOnly
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='row'>
                                                            <div className="col-md-4">
                                                                <div className="mb-4">
                                                                    <label className='fw-bold mb-3'>Adhoc leave weekdays</label>
                                                                    <input
                                                                        type="text"
                                                                        value={adhoc_leave_edit_form.weekends.value}
                                                                        className="form-control"
                                                                        readOnly
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="mb-4">
                                                                    <label className='fw-bold mb-3'>Venue</label>
                                                                    <input
                                                                        type="text"
                                                                        value={adhoc_leave_edit_form.venue.value}
                                                                        className="form-control"
                                                                        readOnly
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="mb-4">
                                                                    <label className='fw-bold mb-3'>Activity</label>
                                                                    <input
                                                                        type="text"
                                                                        value={adhoc_leave_edit_form.activity.value}
                                                                        className="form-control"
                                                                        readOnly
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <label className='fw-bold mb-2'>{adhoc_leave_edit_form.parents_inst.label}</label>'
                                                                <div>
                                                                    <textarea
                                                                        className="form-control"
                                                                        placeholder={adhoc_leave_edit_form.parents_inst.placeholder}
                                                                        id="floatingTextarea2"
                                                                        value={adhoc_leave_edit_form.parents_inst.value !== "" ? adhoc_leave_edit_form.parents_inst.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "parents_inst")}
                                                                    />
                                                                    {!adhoc_leave_edit_form.parents_inst.valid && adhoc_leave_edit_form.parents_inst.onBlur_out ? <div className="error field-error">{adhoc_leave_edit_form.parents_inst.error_msg}</div> : ""}

                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <label className='fw-bold mb-2'>{adhoc_leave_edit_form.manager_inst.label}</label>
                                                                <div>
                                                                    <textarea
                                                                        className="form-control"
                                                                        placeholder={adhoc_leave_edit_form.manager_inst.placeholder}
                                                                        id="floatingTextarea2"
                                                                        value={adhoc_leave_edit_form.manager_inst.value !== "" ? adhoc_leave_edit_form.manager_inst.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "manager_inst")}
                                                                    />
                                                                    {!adhoc_leave_edit_form.manager_inst.valid && adhoc_leave_edit_form.manager_inst.onBlur_out ? <div className="error field-error">{adhoc_leave_edit_form.manager_inst.error_msg}</div> : ""}

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-10">
                                                            <div className='col-12'>
                                                                <button
                                                                    type="submit"
                                                                    class="btn btn-primary px-10 py-2 float-end"
                                                                >
                                                                    Edit
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                               
                                </div>
                                <Footer />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}


function mapStateToProps(state) {
    return {

    };
}

const mapDispatchToProps = {
    onGetAdhocLeaveDetailById: userActions.GetAdhocLeaveDetailById,
    onEditAdhocLeave: userActions.EditAdhocLeave
};
export default connect(mapStateToProps, mapDispatchToProps)(withParams(EditAdhocLeave));
