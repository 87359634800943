import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" :user_info.school_code;

const PermissionsEditModal = (props) => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const handleClose = () => {
        props.handleClose(false)
        setShow(false)
    };
    useEffect(()=>{
        setShow(props.handleShow)
        console.log("props_values", props);
    },[])
    // const handleShow = () => setShow(true);
    const submitButton = () => {
        props.ChangePermissions(false)
        setShow(false)
        navigate(`/${school_code}/permissions-list`)
    }

    return (
        <>
            <Modal
                animation={false}
                className='shake_modal'
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Unsaved Page</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <p>Are you sure you want to Leave this page ?</p>
                    <p className='m-0'>Changes you want will not be saved.</p>
                </Modal.Body>
                <Modal.Footer className='justify-content-center border-0'>
                    <Button variant="secondary" size="sm" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={submitButton} size="sm" >OK</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(PermissionsEditModal);
