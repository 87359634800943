import React from "react";
import { useLocation , useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CalenderWrap from "../component/Settings/CalendarEditorDefineHolidays/Calendar";
import OnCampusLocation from "../component/Settings/On Camous Location/OnCampusLocation";
import OnCampusLocationEdit from "../component/Settings/On Camous Location/OnCampusLocationEdit";
import RollCall from "../component/Settings/RollCall/SessionTime";
import RollOverStudent from "../component/Settings/Rollover Student/RolloverStudent";
const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" :user_info?.school_code;
const Class = (props) => {
    const Renderview = () => {
        let location = useLocation();
        let userId = useParams();

        if (location.pathname === `/${school_code}/settings/calendar-holidays` || location.pathname === `/${school_code}/settings/calendar-holidays/`) {
            return <CalenderWrap /> ;
        }
        if (location.pathname === `/${school_code}/settings/on-campus-location` || location.pathname === `/${school_code}/settings/on-campus-location/`) {
            return <OnCampusLocation /> ;
        }
        if (location.pathname === `/${school_code}/settings/on-campus-location/edit-on-campus-location/${userId.id}` || location.pathname === `/${school_code}/settings/on-campus-location/edit-on-campus-location/${userId.id}/`) {
            return <OnCampusLocationEdit  on_campus_location_uuid={userId.id}  /> ;
        }
        if (location.pathname === `/${school_code}/settings/rollover-student` || location.pathname === `/${school_code}/settings/student/rollover-student/`) {
            return <RollOverStudent /> ;
        }
        if (location.pathname === `/${school_code}/settings/roll-call/${userId.id}` || location.pathname === `/${school_code}/settings/roll-call${userId.id}/`) {
            return <RollCall master_setting_uuid={userId.id}/> ;
        }
        if (location.pathname === `/${school_code}/settings/roll-call` || location.pathname === `/${school_code}/settings/roll-call/`) {
            return <RollCall /> ;
        }
    };

    return (
        <React.Fragment>
            <ToastContainer />
            {Renderview()}
        </React.Fragment>
    );
};
export default Class;