import React, { useState, useEffect, useCallback, useRef } from "react";
import Select from "react-select";
import { toast } from "react-toastify";

import Sidebar from "../../Constant/sidebar";
import Cdate from "../../Constant/date";
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";
import Menu from "../Menus";
import * as userActions from "../../../actions/index";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConductRollCallItem from "./ConductRollcallItem";
import Timer from "./Timer";
import moment from "moment";
import "moment-timezone";
import { Tooltip } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { updateBooleanValue } from "../../../reducers/Snapshot";

import { socket } from '../../../constants/actionTypes';



const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#e7f5fe" : "",
        },
        backgroundColor: state.isSelected ? "#4a8fb8" : "",
    }),
    valueContainer: (base) => ({
        ...base,
        maxHeight: 60,
        overflowY: "auto"
    }),
};

const permission_info = JSON.parse(localStorage.getItem("permissions"));
const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info.school_code;
const schoolName = user_info?.user_school?.actual_school_code
const current = new Date();
const manager_name = user_info ? user_info?.first_name + " " + user_info?.last_name : "";
const role_type = user_info?.role_type

var student_ids = '';
var unique = []

var student_Data = [];
const timezone = user_info?.user_school?.timezone;
let date = moment(current)?.tz(timezone)?.format('MMMM Do YYYY, h:mm:ss a');

const ConductRollcall = (props) => {
    const dispatch = useDispatch();
    const booleanValue = useSelector((state) => state.snapshot.booleanValue);
    console.log('booleanValue====================================', booleanValue);
    const sessionName = useRef(null)

    const [start, setStart] = useState(false); // to start  timer
    const [isEditing, setIsEditing] = useState(false);
    const [final, setFinal] = useState([])
    const [filterorder] = useState("desc");
    const [filtersort] = useState("");
    const [StudentList, setStudentList] = useState([]);
    const [allSelectCheck, setAllSelectCheck] = useState(false);
    const [allSelectReason_ids, setAllSelectReason_ids] = useState("");

    const [StudentListcount, setStudentListcount] = useState(0);
    const [dormitory_id, setdormitory_id] = useState([]);
    const [dormitory_ids, setdormitory_ids] = useState(""
        // JSON.parse(localStorage.getItem("Dormitory_filters")) ? JSON.parse(localStorage.getItem("Dormitory_filters")) : ""
    );
    const [custom_name, setcustom_name] = useState("");
    const [class_id, setclass_id] = useState([]);
    const [class_ids, setclass_ids] = useState(""
        // JSON.parse(localStorage.getItem("Class_filters")) ? JSON.parse(localStorage.getItem("Class_filters")) : ""
    );
    const [class_value, setclass_value] = useState([]);
    const [class_values, setclass_values] = useState([]);
    const [both_per, setBoth_per] = useState(true)
    const [male_per, setMale_per] = useState(true)
    const [female_per, setFemale_per] = useState(true)

    const [gender, setgender] = useState(""
        // JSON.parse(localStorage.getItem("Gender_filters")) ? JSON.parse(localStorage.getItem("Gender_filters")) : ''
    );
    const [reason_Id, setReason_Id] = useState([]);
    const [reason_Ids, setReason_Ids] = useState(
        JSON.parse(localStorage.getItem("status_select_all")) ? JSON.parse(localStorage.getItem("status_select_all")) : ''
    );
    const [checkedAll, setCheckedAll] = useState(
        // JSON.parse(localStorage.getItem("check_box_all"))? JSON.parse(localStorage.getItem("check_box_all")) : 
        false
    );

    const [checkedFilter, setCheckedFilter] = useState(true)
    const [socketcalled, setSocketcalled] = useState();
    const [time, setTime] = useState();
    const [compare, setCompare] = useState(
        JSON.parse(localStorage.getItem("Comparision")) ? JSON.parse(localStorage.getItem("Comparision")) : "false"
    );

    const [default_class, setDefault_class] = useState([])
    const [default_dromitary, setDefault_dromitary] = useState([])
    const [filter_def_class, setFilter_def_calss] = useState([])
    const [rollcallApiClass, setRollcallApiClass] = useState(false)
    const [rollcallApiDorm, setRollcallApiDorm] = useState(false)
    const [classApiCall, setClassApiCall] = useState(false)
    const [dormApiCall, setDormApiCall] = useState(false)
    const [localStorageFilter, setLocalStorageFilter] = useState(false)
    const navigate = useNavigate();


    useEffect(() => {
        const Socket_Toast = JSON.parse(localStorage.getItem("Socket_calls"));
        setSocketcalled(Socket_Toast)
    })

    //////////////////// dormitory and class change handler //////////////////

    const inputChangeHandler = (event, identifire) => {
        setLocalStorageFilter(true)
        localStorage.removeItem('Rollcal_List')
        localStorage.removeItem('Rollcal_Data_Global')
        setFinal([])
        let class_values = []
        let dromitory_values = []
        let filter_classes = []
        let filter_dromitary = []
        if (identifire === "class_id") {
            if (event.length > 0) {
                for (let key in event) {
                    class_values.push(event[key].value)
                    filter_classes.push(event[key])
                }
                const default_class_id = []
                class_id.forEach((id) => {
                    if (id.value !== "") {
                        default_class_id.push(id.value)
                    }
                })

                setclass_ids(JSON.stringify(class_values.includes("") ? default_class_id : class_values))
                setDefault_class(event)
            } else {
                const default_class_id = []
                class_id.forEach((id) => {
                    if (id.value !== "") {
                        default_class_id.push(id.value)
                    }
                })

                setclass_ids(JSON.stringify(default_class_id))
                setDefault_class(event)
            }
        } else {
            if (event.length > 0) {

                for (let key in event) {
                    dromitory_values.push(event[key].value)
                    filter_dromitary.push(event[key])
                }
                const default_dormitory_id = []
                dormitory_id.forEach((id) => {
                    if (id.value !== "") {
                        default_dormitory_id.push(id.value)
                    }
                })
                setdormitory_ids(JSON.stringify(dromitory_values.includes("") ? default_dormitory_id : dromitory_values))
                setDefault_dromitary(event)
            }
            else {
                const default_dormitory_id = []
                dormitory_id.forEach((id) => {
                    if (id.value !== "") {
                        default_dormitory_id.push(id.value)
                    }
                })
                setdormitory_ids(JSON.stringify(default_dormitory_id))
                setDefault_dromitary(event)
            }
        }
    }


    //////////////////////// get gender permissions by local storage /////////////////

    useEffect(() => {
        let permissions;
        if (JSON.parse(localStorage.getItem("permissions")) === null) {
        } else {
            permissions = JSON.parse(localStorage.getItem("permissions") ? localStorage.getItem("permissions") : "")
            if (permissions.snapshot_gender_both) {
                setMale_per(true)
                setFemale_per(true)
                setBoth_per(true)
            } else {
                setMale_per(permissions.snapshot_gender_male)
                setFemale_per(permissions.snapshot_gender_female)
                setBoth_per(permissions.snapshot_gender_both)
            }
        }
    }, [])


    /////////////////////////// conduct rollcall student data ////////////////


    const GetAllStudentsforConductRollCall = useCallback(async () => {
        const listLocalStorage = JSON.parse(localStorage.getItem("Rollcal_List"));
        const dataLocalStorage = JSON.parse(localStorage.getItem("Rollcal_Data_Global"));
        dispatch(userActions.GetAllStudentsforConductRollCall(filtersort, filterorder, dormitory_ids, class_ids, gender)).then((response) => {
            if (response.success === true) {
                const resdata = response.data.reason_data;
                const arrayData = response.data.rows;
                let MemberId_level = [];
                let reason_level = []
                for (let key in resdata) {
                    if (resdata[key].reason_id != 2 && resdata[key].reason_id != 5) {
                        reason_level.push({
                            label: resdata[key].reason_name !== "On Campus" ? resdata[key].reason_name : "OC",
                            value: resdata[key].reason_id,
                            reason_Id: resdata[key].reason_id,
                        })
                    }
                }
                reason_level.push({
                    label: 'P',
                    value: 'present',
                    reason_Id: 'present',
                })
                for (let key in arrayData) {
                    const filter_stu_atten_details = arrayData[key].stu_atten_details.map((stu_atten, index) => {
                        const stu_atten_data = [...stu_atten.attendance_reason]
                        if (stu_atten_data.length !== 0) {
                            stu_atten_data[0]['reason_name'] = stu_atten_data[0]?.reason_name === undefined ? "" : stu_atten_data[0].reason_name === "SL" ? "L" : stu_atten_data[0].reason_name;
                        }
                        return { ...stu_atten, attendance_reason: stu_atten_data }
                    })
                    MemberId_level.push({
                        stu_adhoc_data: arrayData[key].stu_adhoc_data.filter(adhoc => adhoc.check_in_out === "out"),
                        student_avatar: arrayData[key].student_avatar,
                        student_first_name: arrayData[key].student_first_name,
                        student_last_name: arrayData[key].student_last_name,
                        gender: arrayData[key].gender,
                        class_name: arrayData[key].class_name,
                        student_id: arrayData[key].student_id,
                        unique_pin: arrayData[key].unique_pin,
                        allergy_details: arrayData[key].allergy_details,
                        grounded_data: arrayData[key].grounded_data,
                        generic_data: arrayData[key].generic_data,
                        dormitory_name: arrayData[key].dormitory_data.dormitory_name,
                        display_image_required: arrayData[key].display_image_required, 

                        stu_atten_details: filter_stu_atten_details,

                        current_loc: arrayData[key]?.current_loc_data !== null ? arrayData[key]?.current_loc_data : "present",
                        current_loc_data: arrayData[key]?.current_loc_data?.current_present_status === true ? "present" :
                            // arrayData[key]?.current_loc_data === null || arrayData[key]?.current_loc_data?.current_loc_reason === null ? 
                            arrayData[key]?.current_loc_data?.current_loc_oncampus === null ? (arrayData[key]?.current_loc_data?.current_loc_reason?.reason_name === "SL" ? "Leave" : arrayData[key]?.current_loc_data?.current_loc_reason?.reason_name) :
                                arrayData[key]?.current_loc_data?.current_loc_oncampus?.location_name,


                        // Uncommented by M..... to check
                        reason_check: arrayData[key].current_loc_data !== null ? arrayData[key]?.current_loc_data?.current_present_status === true ? "present" :
                            arrayData[key]?.current_loc_data?.current_loc_oncampus === null ? arrayData[key]?.current_loc_data?.reason_id :
                                arrayData[key]?.current_loc_data?.current_loc_oncampus?.location_name : null,

                        stu_reason_oncampus_id: arrayData[key].campus_or_reasons_data.length === 0 ? "" : arrayData[key].campus_or_reasons_data[0].stu_reason_oncampus_id,


                        MIA_id: response.data.reason_data[0].reason_id,
                        SL_id: response.data.reason_data[1].reason_id,
                        KL_id: response.data.reason_data[2].reason_id,
                        OnCampus_id: response?.data?.reason_data[3]?.reason_id,
                        MIA_Class: "",
                        SL_Class: "",
                        KL_Class: "",
                        OnCampus_Class: "",
                        checked: false,
                        Present_Class: "",
                    });
                }

                MemberId_level.sort((a, b) => a.student_first_name.localeCompare(b.student_first_name));

                setReason_Id(reason_level);

                console.log("booleanValue", booleanValue);
                if (!listLocalStorage || booleanValue == true || localStorageFilter === true || listLocalStorage.length == 0) {
                    setStudentList(MemberId_level);
                    dispatch(updateBooleanValue(false))
                    setLocalStorageFilter(false)
                }

                if (!dataLocalStorage || booleanValue) {
                    student_Data = MemberId_level;
                }

                if (!dataLocalStorage) {
                    setStudentListcount(response.data.count)
                } else {
                    setStudentListcount(student_Data.length)
                }

            } else if (response.status === 401) {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                setTimeout(() => {
                    navigate('/logout')

                }, 5000);
            } else {

            }
        });
    }, [dispatch, filtersort, filterorder, dormitory_ids, class_ids, gender]);

    const GetRollCallDetails = useCallback(async () => {
        dispatch(userActions.GetRollCallDetails()).then((response) => {
            if (response.success === true) {
                const arrayData = response.data[0];
                setTime((parseInt(arrayData.meta_value_one)));
            }
            else {
            }
        });
    }, [dispatch]);


    useEffect(() => {
        console.log("dddddddddddddddddd", localStorage);
        if (rollcallApiClass === true && rollcallApiDorm === true && classApiCall === true && dormApiCall === true) {
            GetAllStudentsforConductRollCall();
        }
        GetRollCallDetails();
    }, [dormitory_ids, class_ids, gender]);


    ////////////////////////////////// get all classes and dormitorys ////////////////////

    const GetAllClassesDetailsStudent = useCallback(() => {
        dispatch(userActions.GetAllClassesDetailsStudent()).then((response) => {
            if (response.success === true) {
                let state_data = [];
                state_data.push({
                    label: "All Year Levels",
                    value: "",
                });
                const arrayData = response.data.rows;
                if (permission_info) {
                    const permClassDormArray = permission_info?.perm_class_dorm;
                    const filteredPermClass = permClassDormArray.filter(item => item?.class_id !== null)
                    const allowedClassIds = filteredPermClass.map(item => item?.class_id);
                    const filteredData = arrayData.filter(item => allowedClassIds.includes(item.class_id));
                    for (let key in filteredData) {
                        state_data.push({
                            label: filteredData[key].class_name,
                            value: filteredData[key].class_id,
                            index: JSON.parse(key)
                        });
                    }
                } else {
                    for (let key in arrayData) {
                        state_data.push({
                            label: arrayData[key].class_name,
                            value: arrayData[key].class_id,
                            index: JSON.parse(key)
                        });
                    }
                }
                setclass_id(state_data);
                setClassApiCall(true)
            } else {
            }
        });
    }, [dispatch]);


    const GetAllDormitoryStudent = useCallback(() => {
        dispatch(userActions.GetAllDormitoryStudent()).then((response) => {
            if (response.success === true) {
                let state_data = [];
                state_data.push({
                    label: "All Dormitory",
                    value: "",
                });
                const arrayData = response.data.rows;
                if (permission_info) {
                    const permClassDormArray = permission_info?.perm_class_dorm;
                    const filteredPermDorm = permClassDormArray.filter(item => item?.dormitory_id !== null)
                    const allowedDormIds = filteredPermDorm.map(item => item?.dormitory_id);
                    const filteredData = arrayData.filter(item => allowedDormIds.includes(item.dormitory_id));
                    for (let key in filteredData) {
                        state_data.push({
                            label: filteredData[key].dormitory_name,
                            value: filteredData[key].dormitory_id,
                            dormitory_id: filteredData[key].dormitory_id,
                        });
                    }
                } else {
                    for (let key in arrayData) {
                        state_data.push({
                            label: arrayData[key].dormitory_name,
                            value: arrayData[key].dormitory_id,
                            dormitory_id: arrayData[key].dormitory_id,
                        });
                    }
                }
                setdormitory_id(state_data);
                setDormApiCall(true)
            } else {
            }
        });
    }, [dispatch]);


    useEffect(() => {
        GetAllClassesDetailsStudent();
        GetAllDormitoryStudent();
    }, [])


    useEffect(() => {
        const localstrDorm = JSON.parse(localStorage.getItem("Dormitory_filters"));
        const localstrClass = JSON.parse(localStorage.getItem("Class_filters"));
        const localstrGender = JSON.parse(localStorage.getItem("Gender_filters"));
        if (classApiCall) {
            if (localstrClass !== null) {
                setclass_ids(localstrClass)
            } else {
                const default_class_id = []
                class_id.forEach((id) => {
                    if (id.value !== "") {
                        default_class_id.push(id.value)
                    }
                })
                setclass_ids(JSON.stringify(default_class_id))
            }
            setRollcallApiClass(true)
        }

        if (dormApiCall) {
            if (localstrDorm !== null) {
                setdormitory_ids(localstrDorm)
            } else {
                const default_class_dormitory = []
                dormitory_id.forEach((id) => {
                    if (id.value !== "") {
                        default_class_dormitory.push(id.value)
                    }
                })
                setdormitory_ids(JSON.stringify(default_class_dormitory))
            }
            setRollcallApiDorm(true)
        }
        if (localstrGender !== null) {
            if (localstrGender) { setgender(localstrGender) }
        } else {
            if (permission_info === null) {
                setgender("")
            } else {
                if (male_per === true && both_per === false && female_per === false) {
                    setgender("male")
                } else if (female_per == true && male_per === false && both_per === false) {
                    setgender("female")
                } else {
                    setgender("")
                }
            }
        }
    }, [class_id, dormitory_id])



    const GetLoginUserPermissions = useCallback(async () => {
        dispatch(userActions.GetLoginUserPermissions()).then((response) => {
            if (response.success === true) {
            } else if (response.status === 401) {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
            }
        });
    }, [dispatch]);

    useEffect(() => {
        GetLoginUserPermissions();
    }, []);

    ///////////////// filter save on local function checkbox ////////////////////////

    const Savefilers = async (event) => {
        if (checkedFilter === false) {
            setCheckedFilter(true)
        } else {
            setCheckedFilter(false)
        }
    }

    const FilterSave = async (event) => {
        const Payload = {
            dormitory_id: dormitory_ids,
            class_id: class_ids,
            gender: gender,
        };
        if (checkedFilter === true) {
            localStorage.setItem('Comparision', JSON.stringify(compare));
            
            localStorage.setItem('default_dromitary_filters', JSON.stringify(default_dromitary));
            localStorage.setItem('default_class_filters', JSON.stringify(default_class));

            localStorage.setItem('Dormitory_filters', JSON.stringify(dormitory_ids));
            localStorage.setItem('Class_filters', JSON.stringify(class_ids));

            localStorage.setItem('Gender_filters', JSON.stringify(gender));

            toast.success("The filter has been saved to the local storage.", {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    }


    ////////////////////////////////////////////////////////////////////////////////////

    useEffect(() => {
        const finalLocalStorage = JSON.parse(localStorage.getItem("Rollcal_Final"));
        const listLocalStorage = JSON.parse(localStorage.getItem("Rollcal_List"));
        const dataLocalStorage = JSON.parse(localStorage.getItem("Rollcal_Data_Global"));
        const constumLocalStorage = JSON.parse(localStorage.getItem("SessionName"));
        console.log("ddddddddddddddddddddddddddddd", finalLocalStorage, listLocalStorage, dataLocalStorage, constumLocalStorage);
        if (finalLocalStorage) { setFinal(finalLocalStorage) }
        if (listLocalStorage) { setStudentList(listLocalStorage) }
        if (dataLocalStorage) { student_Data = dataLocalStorage }
        if (constumLocalStorage) { setcustom_name(constumLocalStorage) }
    }, []);




    //////////////////////// All select checkbox function /////////////////

    const handleAllChecked = (event) => {
        const timeLocalStorage = JSON.parse(localStorage.getItem("RollCall_time"));
        console.log("timeLocalStorage", timeLocalStorage);
        if (timeLocalStorage != "" || timeLocalStorage != 0) {
            localStorage.setItem('SessionName', JSON.stringify(custom_name));
            setStart(true);
            if (class_ids || dormitory_ids) {
                if (event.target.value == "false") {
                    setCheckedAll(true)
                    let listcheckbox = StudentList.map(item => {
                        if (item.current_loc_data === "ad-hoc" || item.current_loc_data === "Leave") {
                            return { ...item, checked: true }
                        } else {
                            return { ...item, checked: true }
                        }
                    })
                    setStudentList(listcheckbox)
                    student_Data = listcheckbox;
                    localStorage.setItem('Rollcal_List', JSON.stringify(listcheckbox));
                    localStorage.setItem('Rollcal_Data_Global', JSON.stringify(student_Data));
                } else {
                    setCheckedAll(false)
                    let listcheckbox = StudentList.map(item => {
                        return { ...item, checked: false }
                    })
                    setStudentList(listcheckbox)
                    student_Data = listcheckbox;
                    localStorage.setItem('Rollcal_List', JSON.stringify(listcheckbox));
                    localStorage.setItem('Rollcal_Data_Global', JSON.stringify(student_Data));
                }
            } else {
                toast.error("Please select Year or Dormitory", {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        } else {
            toast.error("Please add 'Session Duration' Time from settings to start Rollcall.", {
                position: toast.POSITION.TOP_CENTER,
            });
        }

    }

    ///////////////////////////// a perticular checkbox for student ///////////////////////////

    const toggleChecked = (event) => {
        const timeLocalStorage = JSON.parse(localStorage.getItem("RollCall_time"));
        if (timeLocalStorage != "" || timeLocalStorage != 0) {
            localStorage.setItem('SessionName', JSON.stringify(custom_name));
            setStart(true);
            if (event.target.value === "false") {
                let std_id = event.target.attributes.stu_id.value
                let listcheckbox = []
                student_Data.forEach(item => {
                    if (std_id == item.student_id) {
                        listcheckbox.push({ ...item, checked: true })
                    } else {
                        listcheckbox.push(item);
                    }
                })
                setStudentList(listcheckbox)
                student_Data = listcheckbox;
                localStorage.setItem('Rollcal_List', JSON.stringify(listcheckbox));
                localStorage.setItem('Rollcal_Data_Global', JSON.stringify(student_Data));
            } else {
                let std_id = event.target.attributes.stu_id.value
                let listcheckbox = []
                student_Data.forEach(item => {
                    if (std_id == item.student_id) {
                        listcheckbox.push({ ...item, checked: false })
                    } else {
                        listcheckbox.push(item);
                    }
                })
                setStudentList(listcheckbox)
                student_Data = listcheckbox;
                localStorage.setItem('Rollcal_List', JSON.stringify(listcheckbox));
                localStorage.setItem('Rollcal_Data_Global', JSON.stringify(student_Data));
            }
        } else {
            toast.error("Please add 'Session Duration' Time from settings to start Roll call.", {
                position: toast.POSITION.TOP_CENTER,
            });
        }

    }

    // const IndivisualstatusSelectHandle = (event) => {
    //     setAllSelectCheck(true)
    //     // localStorage.setItem('SessionName', JSON.stringify(custom_name));
    //     // setStart(true);
    //     // setReason_Ids(event.value);
    //     // localStorage.setItem('status_select_all', JSON.stringify(event.value));
    //     var reason_ids;
    //     let attendance = [];
    //     let listcheckbox = []
    //     student_Data.map(item => {
    //         if (item.student_id == event.target.getAttribute('stu_id')) {
    //             reason_ids = event.currentTarget.id
    //         } else {
    //             reason_ids = allSelectReason_ids
    //         }
    //         if (item.checked == true) {
    //             if (reason_ids == 1) {
    //                 listcheckbox.push({ ...item, MIA_Class: "bg-salmon", KL_Class: "", SL_Class: "", OnCampus_Class: "", Present_Class: "" })
    //             } else if (reason_ids == 3) {
    //                 listcheckbox.push({ ...item, MIA_Class: "", KL_Class: "bg-salmon", SL_Class: "", OnCampus_Class: "", Present_Class: "" })
    //             } else if (reason_ids == 4) {
    //                 listcheckbox.push({ ...item, MIA_Class: "", KL_Class: "", SL_Class: "", OnCampus_Class: "bg-salmon", Present_Class: "" })
    //             } else if (reason_ids == "present") {
    //                 listcheckbox.push({ ...item, MIA_Class: "", KL_Class: "", SL_Class: "", OnCampus_Class: "", Present_Class: "bg-salmon" })
    //             }
    //         } else {
    //             listcheckbox.push({ ...item })
    //         }

    //         var stu_ids = item.student_id
    //         var stu_first_name = item.student_first_name
    //         var stu_last_name = item.student_last_name
    //         var dormitory = item.dormitory_name
    //         student_ids = stu_ids
    //         var old_reason = item.reason_check == null ? '' : item.reason_check;
    //         var stu_reason_oncampus_id = item.stu_reason_oncampus_id === "" ? null : item.stu_reason_oncampus_id;

    //         if (item.checked == true) {
    //             attendance.push(
    //                 {
    //                     reason_id: (reason_ids == '' || reason_ids == "present") ? null : parseInt(reason_ids),
    //                     student_id: student_ids,
    //                     school_detail_id: user_info.school_detail_id,
    //                     is_attendance: (reason_ids == '' || reason_ids == "present") ? true : false,
    //                     duration_time: current,
    //                     is_loc_changed: old_reason == reason_ids ? false : true,
    //                     stu_reason_oncampus_id: stu_reason_oncampus_id,
    //                     stu_current_loc_name: (old_reason !== reason_ids && reason_ids === 1 ? "MIA" : ""),
    //                     student_first_name: stu_first_name,
    //                     student_last_name: stu_last_name,
    //                     dormitory_name: dormitory
    //                 }
    //             )
    //         }
    //         else if (item.checked === false) {
    //             if (item?.current_loc_data === "ad-hoc") {
    //                 attendance.push(
    //                     {
    //                         reason_id: 5,
    //                         student_id: student_ids,
    //                         school_detail_id: user_info.school_detail_id,
    //                         is_attendance: false,
    //                         duration_time: current,
    //                         is_loc_changed: false,
    //                         stu_reason_oncampus_id: stu_reason_oncampus_id,
    //                         stu_current_loc_name: "ad-hoc",
    //                         student_first_name: stu_first_name,
    //                         student_last_name: stu_last_name,
    //                         dormitory_name: dormitory
    //                     }
    //                 )
    //             }
    //             if (item?.current_loc_data === "Leave") {
    //                 attendance.push(
    //                     {
    //                         reason_id: 2,
    //                         student_id: student_ids,
    //                         school_detail_id: user_info.school_detail_id,
    //                         is_attendance: false,
    //                         duration_time: current,
    //                         is_loc_changed: false,
    //                         stu_reason_oncampus_id: stu_reason_oncampus_id,
    //                         stu_current_loc_name: "Leave",
    //                         student_first_name: stu_first_name,
    //                         student_last_name: stu_last_name,
    //                         dormitory_name: dormitory
    //                     }
    //                 )
    //             }

    //         }
    //     });
    //     setStudentList(listcheckbox)
    //     setStudentList(listcheckbox)
    //     student_Data = listcheckbox;
    //     setFinal(attendance);
    //     localStorage.setItem('Rollcal_Final', JSON.stringify(attendance));
    //     localStorage.setItem('Rollcal_List', JSON.stringify(listcheckbox));
    //     localStorage.setItem('Rollcal_Data_Global', JSON.stringify(listcheckbox));


    // }

    ////////////////////////// select for change status for All cards /////////////////// 

    const statusSelectHandle = (event) => {
        setAllSelectCheck(true)
        const timeLocalStorage = JSON.parse(localStorage.getItem("RollCall_time"));
        if (timeLocalStorage != "" || timeLocalStorage != 0) {
            localStorage.setItem('SessionName', JSON.stringify(custom_name));
            setStart(true);
            setReason_Ids(event.value);
            localStorage.setItem('status_select_all', JSON.stringify(event.value));
            var reason_ids = event.reason_Id
            let attendance = [];
            let listcheckbox = []
            setAllSelectReason_ids(reason_ids)
            student_Data.map(item => {
                if (item.checked == true) {
                    if (reason_ids == 1) {
                        listcheckbox.push({ ...item, MIA_Class: "bg-salmon", KL_Class: "", SL_Class: "", OnCampus_Class: "", Present_Class: "" })
                    } else if (reason_ids == 3) {
                        listcheckbox.push({ ...item, MIA_Class: "", KL_Class: "bg-salmon", SL_Class: "", OnCampus_Class: "", Present_Class: "" })
                    } else if (reason_ids == 4) {
                        listcheckbox.push({ ...item, MIA_Class: "", KL_Class: "", SL_Class: "", OnCampus_Class: "bg-salmon", Present_Class: "" })
                    } else if (reason_ids == "present") {
                        listcheckbox.push({ ...item, MIA_Class: "", KL_Class: "", SL_Class: "", OnCampus_Class: "", Present_Class: "bg-salmon" })
                    }
                } else {
                    listcheckbox.push({ ...item })
                }

                var stu_ids = item.student_id
                var stu_first_name = item.student_first_name
                var stu_last_name = item.student_last_name
                var dormitory = item.dormitory_name
                student_ids = stu_ids
                var old_reason = item.reason_check == null ? '' : item.reason_check;
                var stu_reason_oncampus_id = item.stu_reason_oncampus_id === "" ? null : item.stu_reason_oncampus_id;

                if (item.checked == true) {
                    attendance.push(
                        {
                            reason_id: (reason_ids == '' || reason_ids == "present") ? null : parseInt(reason_ids),
                            student_id: student_ids,
                            school_detail_id: user_info.school_detail_id,
                            is_attendance: (reason_ids == '' || reason_ids == "present") ? true : false,
                            duration_time: current,
                            is_loc_changed: old_reason == reason_ids ? false : true,
                            stu_reason_oncampus_id: stu_reason_oncampus_id,
                            stu_current_loc_name: (old_reason !== reason_ids && reason_ids === 1 ? "MIA" : ""),
                            student_first_name: stu_first_name,
                            student_last_name: stu_last_name,
                            dormitory_name: dormitory,
                            previous_reason_id: old_reason 
                        }
                    )
                }
                else if (item.checked === false) {
                    if (item?.current_loc_data === "ad-hoc") {
                        attendance.push(
                            {
                                reason_id: 5,
                                student_id: student_ids,
                                school_detail_id: user_info.school_detail_id,
                                is_attendance: false,
                                duration_time: current,
                                is_loc_changed: false,
                                stu_reason_oncampus_id: stu_reason_oncampus_id,
                                stu_current_loc_name: "ad-hoc",
                                student_first_name: stu_first_name,
                                student_last_name: stu_last_name,
                                dormitory_name: dormitory,
                                previous_reason_id: old_reason 
                            }
                        )
                    }
                    if (item?.current_loc_data === "Leave") {
                        attendance.push(
                            {
                                reason_id: 2,
                                student_id: student_ids,
                                school_detail_id: user_info.school_detail_id,
                                is_attendance: false,
                                duration_time: current,
                                is_loc_changed: false,
                                stu_reason_oncampus_id: stu_reason_oncampus_id,
                                stu_current_loc_name: "Leave",
                                student_first_name: stu_first_name,
                                student_last_name: stu_last_name,
                                dormitory_name: dormitory,
                                previous_reason_id: old_reason 
                            }
                        )
                    }
                }
            });
            setStudentList(listcheckbox)
            setStudentList(listcheckbox)
            student_Data = listcheckbox;
            setFinal(attendance);
            localStorage.setItem('Rollcal_Final', JSON.stringify(attendance));
            localStorage.setItem('Rollcal_List', JSON.stringify(listcheckbox));
            localStorage.setItem('Rollcal_Data_Global', JSON.stringify(listcheckbox));
        }
        else {
            toast.error("Please add 'Session Duration' Time from settings to start Roll call.", {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    }

    ///////////////////// location status click function for single card //////////////////

    const handleClick = (event, class_name) => {
        const timeLocalStorage = JSON.parse(localStorage.getItem("RollCall_time"));
        if (timeLocalStorage != "" || timeLocalStorage != 0) {
            if (class_ids || dormitory_ids) {
                localStorage.setItem('SessionName', JSON.stringify(custom_name));
                setStart(true);
                var value = event.target.attributes.value;
                var reason_ids = event.currentTarget.id;
                student_ids = parseInt(event.target.attributes.stu_id.value);
                var old_reason = event.target.attributes.old_reason.value;
                var stu_reason_oncampus_id = event.target.attributes.stu_reason_oncampus_id.value === "" ? null : event.target.attributes.stu_reason_oncampus_id.value;
                var stu_first_name = event.target.attributes.stu_first_name.value
                var stu_last_name = event.target.attributes.stu_last_name.value
                var stu_dorm = event.target.attributes.dorm_name.value
                let updated_List = student_Data.map(item => {
                    if (student_ids == item.student_id) {
                        if (reason_ids == item.MIA_id) {
                            return { ...item, MIA_Class: class_name === "bg-salmon" ? "" : "bg-salmon", KL_Class: "", SL_Class: "", OnCampus_Class: "", Present_Class: "" }
                        } else if (reason_ids == item.KL_id) {
                            return { ...item, MIA_Class: "", KL_Class: class_name === "bg-salmon" ? "" : "bg-salmon", SL_Class: "", OnCampus_Class: "", Present_Class: "" }
                        } else if (reason_ids == item.SL_id) {
                            return { ...item, MIA_Class: "", KL_Class: "", SL_Class: class_name === "bg-salmon" ? "" : "bg-salmon", OnCampus_Class: "", Present_Class: "" }
                        } else if (reason_ids == item.OnCampus_id) {
                            return { ...item, MIA_Class: "", KL_Class: "", SL_Class: "", OnCampus_Class: class_name === "bg-salmon" ? "" : "bg-salmon", Present_Class: "" }
                        } else if (reason_ids == 'present') {
                            return { ...item, MIA_Class: "", KL_Class: "", SL_Class: "", OnCampus_Class: "", Present_Class: class_name === "bg-salmon" ? "" : "bg-salmon" }
                        }
                    } else {
                        return item;
                    }
                });

                setStudentList(updated_List)
                student_Data = updated_List;
                let attendance = final;
                let foundIndex = attendance.findIndex(element => element.student_id == student_ids)
                let foundIndex_2 = student_Data.find(element => element.student_id == student_ids)
                if (foundIndex !== -1) {
                    if (foundIndex_2.MIA_Class === "" && foundIndex_2.Present_Class === "" && foundIndex_2.KL_Class === "" && foundIndex_2.OnCampus_Class === "") {
                        let filterAttendance = attendance.filter((item, index) => {
                            if (item.student_id != foundIndex_2.student_id) {
                                return item
                            }
                        })
                        console.log("foundIndexdddddddddddddd", filterAttendance);
                        setFinal(filterAttendance)
                        localStorage.removeItem('Rollcal_Final')
                        localStorage.setItem('Rollcal_Final', JSON.stringify(filterAttendance));
                    } else {
                        student_Data.forEach((item) => {
                            if (item.student_id == foundIndex_2.student_id) {
                                attendance.push({
                                    reason_id: (reason_ids == '' || reason_ids == "present") ? null : parseInt(reason_ids),
                                    student_id: student_ids,
                                    school_detail_id: user_info.school_detail_id,
                                    is_attendance: (reason_ids == '' || reason_ids == "present") ? true : false,
                                    duration_time: current,
                                    is_loc_changed: old_reason === reason_ids ? false : true,
                                    stu_reason_oncampus_id: parseInt(stu_reason_oncampus_id),
                                    stu_current_loc_name: (old_reason !== reason_ids && reason_ids === "1" ? "MIA" : ""),
                                    student_first_name: stu_first_name,
                                    student_last_name: stu_last_name,
                                    dormitory_name: stu_dorm,
                                    previous_reason_id: old_reason
                                })
                            }
                        })
                        console.log("foundIndexdddddddddddddd", attendance);
                        setFinal(attendance)
                        localStorage.removeItem('Rollcal_Final')
                        localStorage.setItem('Rollcal_Final', JSON.stringify(attendance));
                    }
                } else {
                    if (foundIndex_2.MIA_Class !== "" || foundIndex_2.Present_Class !== "" || foundIndex_2.KL_Class !== "" || foundIndex_2.OnCampus_Class !== "") {
                        student_Data.forEach((item, i) => {
                            console.log("itemssssssssssssssss", item);
                            if (item.student_id == foundIndex_2.student_id) {
                                attendance.push({
                                    reason_id: (reason_ids == '' || reason_ids == "present") ? null : parseInt(reason_ids),
                                    student_id: student_ids,
                                    school_detail_id: user_info.school_detail_id,
                                    is_attendance: (reason_ids == '' || reason_ids == "present") ? true : false,
                                    duration_time: current,
                                    is_loc_changed: old_reason === reason_ids ? false : true,
                                    stu_reason_oncampus_id: parseInt(stu_reason_oncampus_id),
                                    stu_current_loc_name: (old_reason !== reason_ids && reason_ids === "1" ? "MIA" : ""),
                                    student_first_name: stu_first_name,
                                    student_last_name: stu_last_name,
                                    dormitory_name: stu_dorm,
                                    previous_reason_id: old_reason
                                })
                            } else if (item.current_loc_data === "Leave" || item.current_loc_data === "ad-hoc") {
                                attendance.push({
                                    reason_id: item.reason_check,
                                    student_id: item.student_id,
                                    school_detail_id: user_info.school_detail_id,
                                    is_attendance: false,
                                    duration_time: current,
                                    is_loc_changed: false,
                                    stu_reason_oncampus_id: item.stu_reason_oncampus_id,
                                    stu_current_loc_name: item.current_loc_data === "Leave" ? "Leave" : "ad-hoc",
                                    student_first_name: item.student_first_name,
                                    student_last_name: item.stu_first_name,
                                    dormitory_name: stu_dorm,
                                    previous_reason_id: old_reason
                                })
                            }
                        })
                        setFinal(attendance)
                        localStorage.removeItem('Rollcal_Final')
                        localStorage.setItem('Rollcal_Final', JSON.stringify(attendance));
                    }
                }
                // localStorage.removeItem('Rollcal_List')
                // localStorage.removeItem('Rollcal_Data_Global')
                localStorage.setItem('Rollcal_List', JSON.stringify(updated_List));
                localStorage.setItem('Rollcal_Data_Global', JSON.stringify(updated_List));
            } else {
                toast.error("Please select Year or Dormitory", {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        }
        else {
            toast.error("Please add 'Session Duration' Time from settings to start Roll call.", {
                position: toast.POSITION.TOP_CENTER,
            });
        }


    };

    const startEdiitngHandler = () => {
        const uniqueStudents = new Map();
        final.forEach((student) => {
            if (
                (student.stu_current_loc_name === 'ad-hoc' && student.previous_reason_id === '5') ||
                (student.stu_current_loc_name === 'Leave' && student.previous_reason_id === '2')
            ) {
                uniqueStudents.set(student.student_id, student);
            }
        });

        final.forEach((student) => {
            if (
                !uniqueStudents.has(student.student_id) &&
                !(
                    (student.stu_current_loc_name === 'ad-hoc' && student.previous_reason_id !== '5') ||
                    (student.stu_current_loc_name === 'Leave' && student.previous_reason_id !== '2')
                )
            ) {
                uniqueStudents.set(student.student_id, student);
            }
        });

        unique = [...uniqueStudents.values()];

        console.log(unique,"::sasas");
      
        // unique = [...new Map(final.map((m) => [m.student_id, m])).values()];
        console.log(unique,final,"::final")
        const X = StudentListcount - unique.length;
        if (StudentList.length !== 0) {
            // if (unique.length === StudentListcount) {
            if (X == 0) {
                setIsEditing(true);
            } else {
                toast.error("Rollcall session cannot be completed as " + X + " students have not been marked . Please complete Rollcall session before saving", {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        } else if (StudentList.length === 0) {
            if (unique.length === 0) {
                toast.error("Rollcall session cannot be completed as there is no student to conduct rollcall", {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        }
    };

    const submitHandler = async (event) => {
        console.log("dormitory=================dormitory",dormitory_ids);
        console.log("dormitory=================class",class_ids);
        let dormitory_names = []
        let Year_names = []
        if (JSON.parse(dormitory_ids).length === 0) {
            dormitory_names.push("All Dormitory")
        } else {
            JSON.parse(dormitory_ids).forEach((element) => {
                for (let key in dormitory_id) {
                    if (dormitory_id[key].value === element) {
                        dormitory_names.push(dormitory_id[key].label)
                    }
                }
            })
        }
        if (JSON.parse(class_ids).length === 0) {
            Year_names.push("All Years")
        } else {
            JSON.parse(class_ids).forEach((element) => {
                for (let key in class_id) {
                    if (class_id[key].value === element) {
                        Year_names.push(class_id[key].label)
                    }
                }
            })
        }
        const value = `${manager_name}, Year : ${Year_names.join(",  ")}, Drmitory : ${dormitory_names.join(",  ")}`
        const current1 = new Date();
        const timezone = user_info?.user_school?.timezone;
        const date = moment(current1)?.tz(timezone)?.format('MMMM Do YYYY, h:mm a');

        const uniqueStudents = new Map();
        final.forEach((student) => {
            if (
                (student.stu_current_loc_name === 'ad-hoc' && student.previous_reason_id === '5') ||
                (student.stu_current_loc_name === 'Leave' && student.previous_reason_id === '2')
            ) {
                uniqueStudents.set(student.student_id, student);
            }
        });
        final.forEach((student) => {
            if (
                !uniqueStudents.has(student.student_id) &&
                !(
                    (student.stu_current_loc_name === 'ad-hoc' && student.previous_reason_id !== '5') ||
                    (student.stu_current_loc_name === 'Leave' && student.previous_reason_id !== '2')
                )
            ) {
                uniqueStudents.set(student.student_id, student);
            }
        });

        unique = [...uniqueStudents.values()];

        // unique = [...new Map(final.map((m) => [m.student_id, m])).values()];
        
        
        const updatedUnique = unique.map((obj) => ({ ...obj, duration_time: current }));

        const rollcall_data = JSON.stringify(updatedUnique);
        const Payload = {
            attendance_title: sessionName.current.value ? sessionName.current.value : value,
            //attendance_title: custom_name ? custom_name : value,
            rollcall_data: rollcall_data,
        };
        localStorage.removeItem("RollCall_time");
        console.log("payload==============================", JSON.parse(Payload.rollcall_data));
        console.log("payload================", JSON.parse(Payload.rollcall_data).current_loc);
        props.onCreateStudentsConductRollCall(Payload).then((response) => {
            if (response.success === true) {
                GetAllStudentsforConductRollCall();
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                localStorage.setItem('Comparision', JSON.stringify(compare));
                setIsEditing(false);
                localStorage.removeItem("Rollcal_Final");
                localStorage.removeItem("Rollcal_List");
                localStorage.removeItem("Rollcal_Data_Global");
                localStorage.removeItem("RollCall_time");
                localStorage.removeItem("SessionName");
                localStorage.removeItem("status_select_all");
                localStorage.removeItem('check_box_all')
                localStorage.setItem('dormitory_for_snapshot',JSON.stringify(dormitory_ids))
                localStorage.setItem('classes_for_snapshot',JSON.stringify(class_ids))
                setTimeout(() => {
                    navigate(`/${school_code}/snapshot`, { replace: true });
                    localStorage.removeItem("RollCall_time");
                }, 500);
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    }


    const discardedSessionHandler = async (event) => {
        const current1 = new Date();
        const timezone = user_info?.user_school?.timezone;
        const date = moment(current1)?.tz(timezone)?.format('MMMM Do YYYY, h:mm a');
        const Payload = {
            attendance_title: sessionName?.current?.value ? sessionName.current.value : date,
            // attendance_title: custom_name ? custom_name : date,
            session_start_at: current,
            alloted_time_limit: time
        };

        props.onCreateRollCallSessionDiscard(Payload).then((response) => {
            if (response.success === true) {
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                localStorage.removeItem("Rollcal_Final");
                localStorage.removeItem("Rollcal_List");
                localStorage.removeItem("Rollcal_Data_Global");
                localStorage.removeItem("RollCall_time");
                localStorage.removeItem("SessionName");
                localStorage.removeItem("status_select_all");
                localStorage.removeItem('check_box_all')
                setTimeout(() => {
                    navigate(`/${school_code}/dashboard`, { replace: true });
                }, 1000);
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    }




    const hideModal = () => {
        setIsEditing(false);
    };


    /////////////////    value function for select dormitory and select class ////////////////

    const default_for_class = () => {
        const arr = []
        if (rollcallApiClass === true && rollcallApiDorm === true && classApiCall === true && dormApiCall === true) {
            if (class_ids) {
                try {
                    const parsedIds = JSON.parse(class_ids);
                    class_id.forEach((option) => {
                        if (parsedIds.includes(option.value)) {
                            arr.push(option);
                        }
                    });
                } catch (error) {
                    console.error("Error parsing class_ids:", error);
                }
            }
            return arr;
        }
    }


    const default_for_dormitory = () => {
        const localstrDorm = JSON.parse(localStorage.getItem("default_dromitary_filters"));
        const arr = []
        if (rollcallApiClass === true && rollcallApiDorm === true && classApiCall === true && dormApiCall === true) {
            if (dormitory_ids) {
                try {
                    const parsedIds = JSON.parse(dormitory_ids);
                    dormitory_id.forEach((option) => {
                        if (parsedIds.includes(option.value)) {
                            arr.push(option);
                        }
                    });
                } catch (error) {

                }
            }
            return arr;
        }
        
    }


    const sessionNameHandler = (e) => {
        console.log("custome_name==============", sessionName?.current?.value);
        // setcustom_name(sessionName.current.value)
        // setcustom_name(e.target.value)
    }


    return (
        <>
            <div className="d-flex flex-column flex-root header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed h-100">
                <div className="page d-flex flex-row flex-column-fluid">
                    <Sidebar />
                    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                        <TopBar />
                        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                            <div className="post d-flex flex-column-fluid" id="kt_post">
                                <div id="kt_content_container" className="container-fluid">
                                    <div className="mt-0">
                                        <ul className="nav nav-tabs home-tabs">
                                            <li className="nav-item me-6" >
                                                <Link to={`/${school_code}/snapshot`} className="nav-link " type="button" aria-controls="process" aria-selected="false">
                                                    Snapshot
                                                </Link>
                                            </li>
                                            <li className="nav-item me-6" >
                                                <Link to={`/${school_code}/rollcall-report`} className="nav-link " type="button" aria-controls="process" aria-selected="false"    >
                                                    Roll Call Reports
                                                </Link>
                                            </li>
                                            <li className="nav-item me-6" >
                                                <Link to={`/${school_code}/conduct-rollcall`} className="nav-link active" type="button" aria-controls="process" aria-selected="true">
                                                    Conduct Roll Call
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="tab-content" id="myTabContent">
                                        <div className="card">
                                            <div className="card-header position-relative py-0 details-bg">
                                                <h2 className="card-title  m-0">
                                                    Student Details <span className="d-none d-lg-block bullet h-20px w-1px bg-black mx-4"></span>
                                                    <span className="date-block"><Cdate></Cdate></span>
                                                </h2>
                                                <Timer
                                                    Managername={manager_name}
                                                    discardedSessionHandler={discardedSessionHandler}
                                                    start={start}
                                                />
                                            </div>
                                            <div className="card-body p-6">
                                                {isEditing && (
                                                    <div className="event-modal-view" >
                                                        <div className="modal-dialog modal-dialog-centered">
                                                            <div className="modal-content">
                                                                <div className="modal-body text-center pb-0">
                                                                    <h4>All students have been marked.<br />
                                                                        Are you sure you wish to complete this Rollcall session, it cannot be changed after saving?
                                                                    </h4>
                                                                    {/* {
                                                                        role_type === 1 || role_type === 2 || role_type === 3 ?
                                                                            <div className="col-lg-12 fv-row">
                                                                                <p className="fw-bold m-4 text-center">Please select if you want the Roll Call session to be compared with snapshot or not</p>
                                                                                <div className="inline-flex">
                                                                                    <label className="cursor-pointer fw-bold m-4">
                                                                                        <input
                                                                                            type="radio"
                                                                                            id="nocompare"
                                                                                            name="comparision"
                                                                                            className="form-check-input me-2 cursor-pointer"
                                                                                            value="false"
                                                                                            onChange={(event) => { setCompare(event.target.value); }}
                                                                                            checked={compare === 'false' ? "checked" : null}
                                                                                        />
                                                                                        Do Not Compare
                                                                                    </label>
                                                                                    
                                                                                    <label className=" cursor-pointer  fw-bold m-4">
                                                                                        <input
                                                                                            type="radio"
                                                                                            id="compare"
                                                                                            name="comparision"
                                                                                            className="form-check-input me-2 cursor-pointer"
                                                                                            value="true"
                                                                                            onChange={(event) => { setCompare(event.target.value) }}
                                                                                            checked={compare === 'true' ? "checked" : null}
                                                                                        />
                                                                                        Save & Compare
                                                                                    </label>
                                                                                </div>
                                                                            </div> : null} */}
                                                                    <div className="modal-footer flex-center mt-4">
                                                                        <button type="reset" id="kt_modal_add_event_cancel" className="btn btn-light me-3 btn-sm"
                                                                            onClick={hideModal}
                                                                        >
                                                                            Cancel
                                                                        </button>
                                                                        <button className="btn btn-primary btn-sm"
                                                                            onClick={() => {
                                                                                submitHandler();
                                                                            }}
                                                                        >
                                                                            Save
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                <form className="make-strong">
                                                    <div className="row">
                                                        <div className="col">
                                                            <label className="fw-bold mb-2 ">Student Year</label>
                                                            <Select
                                                                styles={customStyles}
                                                                className="react-bootstrap-typeahead tag-outer text-secondary"
                                                                options={class_id}
                                                                value={default_for_class()}
                                                                placeholder={<div className="text-dark">All Year</div>}
                                                                onChange={(event) => inputChangeHandler(event, "class_id")}
                                                                isMulti
                                                                isClearable={false}

                                                            />
                                                        </div>
                                                        <div className="col">
                                                            <label className="fw-bold mb-2 ">Select Dormitory</label>
                                                            <Select
                                                                styles={customStyles}
                                                                className="react-bootstrap-typeahead tag-outer text-secondary"
                                                                options={dormitory_id}
                                                                value={default_for_dormitory()}
                                                                placeholder={<div className="text-dark">All Dormitory</div>}
                                                                onChange={(event) => inputChangeHandler(event, 'dromitary_id')}
                                                                isMulti
                                                                isClearable={false}

                                                            />
                                                        </div>
                                                        <div className="col">
                                                            <label className="fw-bold mb-2 ">Manager Name</label>
                                                            <input className="form-control" type="text" value={manager_name} readOnly />
                                                        </div>
                                                        <div className="col">
                                                            <label className="fw-bold mb-5">Gender</label>

                                                            <div className="d-flex radio-btn">
                                                                <label className="cursor-pointer d-flex align-items-center">
                                                                    <input
                                                                        type="radio"
                                                                        id="gender"
                                                                        name="gen"
                                                                        className="form-check-input me-2 cursor-pointer"
                                                                        value="male"
                                                                        onChange={(event) => { setgender(event.target.value); setLocalStorageFilter(true) }}
                                                                        checked={gender === 'male' ? "checked" : null}
                                                                        defaultChecked={male_per === true && female_per === false && both_per === false ? true : false}
                                                                        disabled={!male_per}
                                                                    />
                                                                    Male
                                                                </label>

                                                                <label className="cursor-pointer d-flex align-items-center">
                                                                    <input
                                                                        type="radio"
                                                                        id="gender1"
                                                                        name="gen"
                                                                        className="form-check-input me-2 cursor-pointer"
                                                                        value="female"
                                                                        onChange={(event) => { setgender(event.target.value); setLocalStorageFilter(true) }}
                                                                        checked={gender === 'female' ? "checked" : null}
                                                                        defaultChecked={female_per === true && male_per === false && both_per === false ? true : false}
                                                                        disabled={!female_per}
                                                                    />
                                                                    Female
                                                                </label>

                                                                <label className="cursor-pointer d-flex align-items-center">
                                                                    <input
                                                                        type="radio"
                                                                        id="gender2"
                                                                        name="gen"
                                                                        className="form-check-input me-2 cursor-pointer"
                                                                        value=""
                                                                        onChange={(event) => { setgender(event.target.value); setLocalStorageFilter(true) }}
                                                                        checked={gender === "" ? "checked" : null}
                                                                        defaultChecked={male_per === true && female_per === true && both_per === true ? true : false}
                                                                        disabled={!both_per}
                                                                    />
                                                                    Both
                                                                </label>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col">
                                                            <label className="fw-bold mb-5">Comparison</label>
                                                            <div className="d-flex radio-btn">
                                                                <label className="cursor-pointer d-flex align-items-center">
                                                                    <input
                                                                        type="radio"
                                                                        id="nocompare"
                                                                        name="comparision"
                                                                        className="form-check-input me-2 cursor-pointer"
                                                                        value="false"
                                                                        onChange={(event) => { setCompare(event.target.value); }}
                                                                        checked={compare === 'false' ? "checked" : null}
                                                                    />
                                                                    Do Not Compare
                                                                </label>

                                                                <label className=" cursor-pointer d-flex align-items-center">
                                                                    <input
                                                                        type="radio"
                                                                        id="compare"
                                                                        name="comparision"
                                                                        className="form-check-input me-2 cursor-pointer"
                                                                        value="true"
                                                                        onChange={(event) => { setCompare(event.target.value) }}
                                                                        checked={compare === 'true' ? "checked" : null}
                                                                    />
                                                                    Save & Compare
                                                                </label>
                                                            </div>
                                                        </div> */}
                                                        <div className="col-lg-12 d-flex     justify-content-center mt-4">
                                                            <div className="d-flex   align-items-center">
                                                                <label className="me-2">
                                                                    Save Filters
                                                                </label>
                                                                <input className="form-check-input me-6"
                                                                    type="checkbox"
                                                                    onClick={() => {
                                                                        Savefilers();
                                                                    }}
                                                                    checked={checkedFilter}
                                                                ></input>
                                                            </div>
                                                            <div className=" col-lg-2 d-flex align-items-center">
                                                                <span type="submit" className="btn btn-sm btn-primary apply-btn min-150px "
                                                                    onClick={() => {
                                                                        FilterSave();
                                                                    }}
                                                                >
                                                                    Apply
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>



                                        <div className="card mt-5">
                                            <div className="card-header">
                                                <div className="d-flex justify-content-start align-items-center flex-wrap">
                                                    <Tooltip title="Default status for Rollcall is “Unmarked / Blank” unless Manager changes default status - for this session only..." placement="top">
                                                        <i className="fas fa-question-circle me-2"></i>
                                                    </Tooltip>
                                                    <div className="d-flex align-items-center me-4">
                                                        <h5 className="card-title m-0 ">Select All</h5>
                                                        <input className="form-check-input widget-9-check m-5 align-middle"
                                                            type="checkbox"
                                                            onChange={(event) => handleAllChecked(event)}
                                                            value={checkedAll}
                                                            checked={checkedAll}
                                                        />
                                                    </div>

                                                    <div className="align-middle mt-4 mb-4 w-260px">

                                                        <Select
                                                            styles={customStyles}
                                                            placeholder='Set Default Status'
                                                            className="react-bootstrap-typeahead tag-outer"
                                                            options={reason_Id}
                                                            value={reason_Id.filter(function (option) {
                                                                return option.value === reason_Ids;
                                                            })}
                                                            onChange={(event) => statusSelectHandle(event)}
                                                        />
                                                    </div>
                                                </div>
                                                <label className="mt-4 mb-4">
                                                    <input
                                                        className="form-control"
                                                        placeholder="Session Name"
                                                        type="text"
                                                        ref={sessionName}
                                                        // value={custom_name}
                                                        onChange={(e) => sessionNameHandler(e)}
                                                    />
                                                </label>
                                            </div>

                                            <div className="card-body">
                                                <ConductRollCallItem
                                                    data={StudentList}
                                                    handleClick={(event, class_name) => handleClick(event, class_name)}
                                                    toggleChecked={(event) => toggleChecked(event)}
                                                />

                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <div className="d-flex align-items-end">

                                                        <button type="submit" className="btn btn-sm btn-primary apply-btn min-150px addschool-button"
                                                            onClick={startEdiitngHandler}
                                                        >
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {

    onExportStudentsCsv: userActions.ExportStudentsCsv,
    onUpdateStudentStatus: userActions.UpdateStudentStatus,
    onExportStudentsPdf: userActions.ExportStudentsPdf,
    onCreateStudentsConductRollCall: userActions.CreateStudentsConductRollCall,
    onCreateRollCallSessionDiscard: userActions.CreateRollCallSessionDiscard,
};
export default connect(mapStateToProps, mapDispatchToProps)(ConductRollcall);
