import axios from "axios";
import * as actionType from "../../constants/actionTypes";
import { CropLandscapeOutlined } from "@mui/icons-material";
let qs = require("qs");


// /*** CreateClass Action ***/
const createAction = (type, error, loader, isAuthenticated) => ({
    error,
    loader,
    isAuthenticated,
    type,
});


export const ApplyBorderLeaveStart = () =>
    createAction(actionType.ApplyBorderLeave_START, null, true, true);

export const ApplyBorderLeaveSuccess = (response) =>
    createAction(
        actionType.ApplyBorderLeave_SUCCESS,
        false,
        false,
        response.status !== 401
    );

export const ApplyBorderLeaveFail = (error) =>
    createAction(
        actionType.ApplyBorderLeave_FAIL,
        error,
        false,
        error.status !== 401
    );

export const ApplyBorderLeave = (form_data) => async (dispatch) => {
    try {
        dispatch(ApplyBorderLeaveStart());
        const user_info = JSON.parse(localStorage.getItem('bss_authuser'));
        const authToken = localStorage.getItem('bss_authtoken');

        const headers = {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${authToken}`,
            country_host: user_info?.country_host,
            school_code: user_info?.school_code,
        };
        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        const config = {
            method: 'post',
            headers,
            url: `${actionType.environment_url}/api/bss-admin/ApplyBorderLeave`,
            data: formdata,
        };
        const response = await axios(config);
        dispatch(ApplyBorderLeaveSuccess(response.data));
        return response.data;
    } catch (error) {
        dispatch(ApplyBorderLeaveFail('Something went wrong, Please try again.'));
        return error;
    }
};

export const GetAllBorderLeavesDetailsStart = () =>
    createAction(actionType.GetAllBorderLeavesDetails_START, null, true, true);

export const GetAllBorderLeavesDetailsSuccess = (response) =>
    createAction(
        actionType.GetAllBorderLeavesDetails_SUCCESS,
        false,
        false,
        response.status !== 401
    );

export const GetAllBorderLeavesDetailsFail = (error) =>
    createAction(
        actionType.GetAllBorderLeavesDetails_FAIL,
        error,
        false,
        error.status !== 401
    );
export const GetAllBorderLeavesDetails = (page, limit, sort, order, search, from_date, to_date, class_ids,
    dormitory_ids) => async (dispatch) => {
        try {
            dispatch(GetAllBorderLeavesDetailsStart());
            const user_info = JSON.parse(localStorage.getItem('bss_authuser'));
            const authToken = localStorage.getItem('bss_authtoken');
            const headers = {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${authToken}`,
                country_host: user_info?.country_host,
                school_code: user_info?.school_code,
            };
            const params = {
                page,
                limit,
                sort,
                order,
                search,
                from_date,
                to_date,
                class_ids,
                dormitory_ids
            };
            const config = {
                method: 'get',
                headers,
                url: `${actionType.environment_url}/api/bss-admin/GetAllBorderLeavesDetails`,
                params,
                paramsSerializer: (params) => qs.stringify(params),
            };
            const response = await axios(config);
            dispatch(GetAllBorderLeavesDetailsSuccess(response.data));
            return response.data;
        } catch (error) {
            dispatch(GetAllBorderLeavesDetailsFail('Something went wrong, Please try again.'));
            return error;
        }
    };

export const GetBorderLeavesDetailByIdStart = () =>
    createAction(actionType.GetBorderLeavesDetailById_START, null, true, true);

export const GetBorderLeavesDetailByIdSuccess = (response) =>
    createAction(
        actionType.GetBorderLeavesDetailById_SUCCESS,
        false,
        false,
        response.status !== 401
    );

export const GetBorderLeavesDetailByIdFail = (error) =>
    createAction(
        actionType.GetBorderLeavesDetailById_FAIL,
        error,
        false,
        error.status !== 401
    );

export const GetBorderLeavesDetailById = (border_leave_id) => async (dispatch) => {
    try {
        dispatch(GetBorderLeavesDetailByIdStart());
        const user_info = JSON.parse(localStorage.getItem('bss_authuser'));
        const authToken = localStorage.getItem('bss_authtoken');
        const headers = {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${authToken}`,
            country_host: user_info?.country_host,
            school_code: user_info?.school_code,
        };
        const params = {
            border_leave_id
        };
        const config = {
            method: 'get',
            headers,
            url: `${actionType.environment_url}/api/bss-admin/GetBorderLeavesDetailById`,
            params,
            paramsSerializer: (params) => qs.stringify(params),
        };
        const response = await axios(config);

        dispatch(GetBorderLeavesDetailByIdSuccess(response.data));
        return response.data;
    } catch (error) {
        dispatch(GetBorderLeavesDetailByIdFail('Something went wrong, Please try again.'));
        return error;
    }
};

export const EditBoardersLeaveStart = () =>
    createAction(actionType.EditBoardersLeave_START, null, true, true);

export const EditBoardersLeaveSuccess = (response) =>
    createAction(
        actionType.EditBoardersLeave_SUCCESS,
        false,
        false,
        response.status !== 401
    );

export const EditBoardersLeaveFail = (error) =>
    createAction(
        actionType.EditBoardersLeave_FAIL,
        error,
        false,
        error.status !== 401
    );

export const EditBoardersLeave = (form_data) => async (dispatch) => {
    try {
        dispatch(EditBoardersLeaveStart());
        const user_info = JSON.parse(localStorage.getItem('bss_authuser'));
        const authToken = localStorage.getItem('bss_authtoken');

        const headers = {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${authToken}`,
            country_host: user_info?.country_host,
            school_code: user_info?.school_code,
        };
        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        const config = {
            method: 'put',
            headers,
            url: `${actionType.environment_url}/api/bss-admin/UpdateBorderLeavesDetails`,
            data: formdata,
        };
        const response = await axios(config);
        dispatch(EditBoardersLeaveSuccess(response.data));
        return response.data;
    } catch (error) {
        dispatch(EditBoardersLeaveFail('Something went wrong, Please try again.'));
        return error;
    }
};

export const BorderLeaveApprovalStart = () =>
    createAction(actionType.BorderLeaveApproval_START, null, true, true);

export const BorderLeaveApprovalSuccess = (response) =>
    createAction(
        actionType.BorderLeaveApproval_SUCCESS,
        false,
        false,
        response.status !== 401
    );

export const BorderLeaveApprovalFail = (error) =>
    createAction(
        actionType.BorderLeaveApproval_FAIL,
        error,
        false,
        error.status !== 401
    );

export const BorderLeaveApproval = (form_data) => async (dispatch) => {
    try {
        dispatch(BorderLeaveApprovalStart());
        const user_info = JSON.parse(localStorage.getItem('bss_authuser'));
        const authToken = localStorage.getItem('bss_authtoken');

        const headers = {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${authToken}`,
            country_host: user_info?.country_host,
            school_code: user_info?.school_code,
        };
        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        const config = {
            method: 'patch',
            headers,
            url: `${actionType.environment_url}/api/bss-admin/BorderLeaveApproval`,
            data: formdata,
        };
        const response = await axios(config);
        dispatch(BorderLeaveApprovalSuccess(response.data));
        return response.data;
    } catch (error) {
        dispatch(BorderLeaveApprovalFail('Something went wrong, Please try again.'));
        return error;
    }
};


export const GetApprovedStudentHostStart = () =>
    createAction(actionType.GetApprovedStudentHost_START, null, true, true);

export const GetApprovedStudentHostSuccess = (response) =>
    createAction(
        actionType.GetApprovedStudentHost_SUCCESS,
        false,
        false,
        response.status !== 401
    );

export const GetApprovedStudentHostFail = (error) =>
    createAction(
        actionType.GetApprovedStudentHost_FAIL,
        error,
        false,
        error.status !== 401
    );

export const GetApprovedStudentHost = (student_id) => async (dispatch) => {
    try {
        dispatch(GetApprovedStudentHostStart());
        const user_info = JSON.parse(localStorage.getItem('bss_authuser'));
        const authToken = localStorage.getItem('bss_authtoken');

        const headers = {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${authToken}`,
            country_host: user_info?.country_host,
            school_code: user_info?.school_code,
        };
        const params = {
            student_id
        }
        const config = {
            method: 'get',
            headers,
            url: `${actionType.environment_url}/api/bss-admin/GetApprovedStudentHost`,
            params
        };
        const response = await axios(config);
        dispatch(GetApprovedStudentHostSuccess(response.data));
        return response.data;
    } catch (error) {
        dispatch(GetApprovedStudentHostFail('Something went wrong, Please try again.'));
        return error;
    }
};


//
export const ArchiveOrUnarchiveBorderLeaveStart = () =>
    createAction(actionType.ArchiveOrUnarchiveBorderLeave_START, null, true, true);

export const ArchiveOrUnarchiveBorderLeaveSuccess = (response) =>
    createAction(
        actionType.ArchiveOrUnarchiveBorderLeave_SUCCESS,
        false,
        false,
        response.status !== 401
    );

export const ArchiveOrUnarchiveBorderLeaveFail = (error) =>
    createAction(
        actionType.ArchiveOrUnarchiveBorderLeave_FAIL,
        error,
        false,
        error.status !== 401
    );

export const ArchiveOrUnarchiveBorderLeave = (form_data) => async (dispatch) => {
    try {
        dispatch(ArchiveOrUnarchiveBorderLeaveStart());
        const user_info = JSON.parse(localStorage.getItem('bss_authuser'));
        const authToken = localStorage.getItem('bss_authtoken');

        const headers = {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${authToken}`,
            country_host: user_info?.country_host,
            school_code: user_info?.school_code,
        };
        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        const config = {
            method: 'put',
            headers,
            url: `${actionType.environment_url}/api/bss-admin/ArchiveOrUnarchiveBorderLeave`,
            data: formdata,
        };
        const response = await axios(config);
        dispatch(ArchiveOrUnarchiveBorderLeaveSuccess(response.data));
        return response.data;
    } catch (error) {
        dispatch(ArchiveOrUnarchiveBorderLeaveFail('Something went wrong, Please try again.'));
        return error;
    }
};


//------archive---------------
export const GetArchiveStudentDetailsStart = () =>
    createAction(actionType.GetArchiveStudentDetails_START, null, true, true);

export const GetArchiveStudentDetailsSuccess = (response) =>
    createAction(
        actionType.GetArchiveStudentDetails_SUCCESS,
        false,
        false,
        response.status !== 401
    );

export const GetArchiveStudentDetailsFail = (error) =>
    createAction(
        actionType.GetArchiveStudentDetails_FAIL,
        error,
        false,
        error.status !== 401
    );
export const GetArchiveStudentDetails = (page, limit, sort, order, search, from_date, to_date, class_ids,
    dormitory_ids) => async (dispatch) => {
        try {
            dispatch(GetArchiveStudentDetailsStart());
            const user_info = JSON.parse(localStorage.getItem('bss_authuser'));
            const authToken = localStorage.getItem('bss_authtoken');
            const headers = {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${authToken}`,
                country_host: user_info?.country_host,
                school_code: user_info?.school_code,
            };
            const params = {
                page,
                limit,
                sort,
                order,
                search,
                from_date,
                to_date,
                class_ids,
                dormitory_ids
            };
            const config = {
                method: 'get',
                headers,
                url: `${actionType.environment_url}/api/bss-admin/GetArchiveStudentDetails`,
                params,
                paramsSerializer: (params) => qs.stringify(params),
            };
            const response = await axios(config);
            dispatch(GetArchiveStudentDetailsSuccess(response.data));
            return response.data;
        } catch (error) {
            dispatch(GetArchiveStudentDetailsFail('Something went wrong, Please try again.'));
            return error;
        }
    };


//------departure student---------------
export const GetDepartureStudentDetailsStart = () =>
    createAction(actionType.GetDepartureStudentDetails_START, null, true, true);

export const GetDepartureStudentDetailsSuccess = (response) =>
    createAction(
        actionType.GetDepartureStudentDetails_SUCCESS,
        false,
        false,
        response.status !== 401
    );

export const GetDepartureStudentDetailsFail = (error) =>
    createAction(
        actionType.GetDepartureStudentDetails_FAIL,
        error,
        false,
        error.status !== 401
    );
export const GetDepartureStudentDetails = (page, limit, sort, order, search, from_date, to_date) => async (dispatch) => {
        try {
            dispatch(GetDepartureStudentDetailsStart());
            const user_info = JSON.parse(localStorage.getItem('bss_authuser'));
            const authToken = localStorage.getItem('bss_authtoken');
            const headers = {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${authToken}`,
                country_host: user_info?.country_host,
                school_code: user_info?.school_code,
            };
            const params = {
                page,
                limit,
                sort,
                order,
                search,
                from_date,
                to_date,
            };
            const config = {
                method: 'get',
                headers,
                url: `${actionType.environment_url}/api/bss-admin/GetDepartureStudentDetails`,
                params,
                paramsSerializer: (params) => qs.stringify(params),
            };
            const response = await axios(config);
            dispatch(GetDepartureStudentDetailsSuccess(response.data));
            return response.data;
        } catch (error) {
            dispatch(GetDepartureStudentDetailsFail('Something went wrong, Please try again.'));
            return error;
        }
    };


//------return student---------------
export const GetReturnStudentDetailsStart = () =>
    createAction(actionType.GetReturnStudentDetails_START, null, true, true);

export const GetReturnStudentDetailsSuccess = (response) =>
    createAction(
        actionType.GetReturnStudentDetails_SUCCESS,
        false,
        false,
        response.status !== 401
    );

export const GetReturnStudentDetailsFail = (error) =>
    createAction(
        actionType.GetReturnStudentDetails_FAIL,
        error,
        false,
        error.status !== 401
    );
export const GetReturnStudentDetails = (page, limit, sort, order, search, from_date, to_date) => async (dispatch) => {
        try {
            dispatch(GetReturnStudentDetailsStart());
            const user_info = JSON.parse(localStorage.getItem('bss_authuser'));
            const authToken = localStorage.getItem('bss_authtoken');
            const headers = {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${authToken}`,
                country_host: user_info?.country_host,
                school_code: user_info?.school_code,
            };
            const params = {
                page,
                limit,
                sort,
                order,
                search,
                from_date,
                to_date,
            };
            const config = {
                method: 'get',
                headers,
                url: `${actionType.environment_url}/api/bss-admin/GetReturnStudentDetails`,
                params,
                paramsSerializer: (params) => qs.stringify(params),
            };
            const response = await axios(config);
            dispatch(GetReturnStudentDetailsSuccess(response.data));
            return response.data;
        } catch (error) {
            dispatch(GetReturnStudentDetailsFail('Something went wrong, Please try again.'));
            return error;
        }
    };



//------boarder dashboard---------------
export const ManageBoarderLeaveDashboardStart = () =>
    createAction(actionType.ManageBoarderLeaveDashboard_START, null, true, true);

export const ManageBoarderLeaveDashboardSuccess = (response) =>
    createAction(
        actionType.ManageBoarderLeaveDashboard_SUCCESS,
        false,
        false,
        response.status !== 401
    );

export const ManageBoarderLeaveDashboardFail = (error) =>
    createAction(
        actionType.ManageBoarderLeaveDashboard_FAIL,
        error,
        false,
        error.status !== 401
    );
export const ManageBoarderLeaveDashboard = () => async (dispatch) => {
    try {
        dispatch(ManageBoarderLeaveDashboardStart());
        const user_info = JSON.parse(localStorage.getItem('bss_authuser'));
        const authToken = localStorage.getItem('bss_authtoken');
        const headers = {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${authToken}`,
            country_host: user_info?.country_host,
            school_code: user_info?.school_code,
        };
        const config = {
            method: 'get',
            headers,
            url: `${actionType.environment_url}/api/bss-admin/ManageBoarderLeaveDashboard`,
        };
        const response = await axios(config);
        dispatch(ManageBoarderLeaveDashboardSuccess(response.data));
        return response.data;
    } catch (error) {
        dispatch(ManageBoarderLeaveDashboardFail('Something went wrong, Please try again.'));
        return error;
    }
};


// departure student approval 
export const DepartStuLeaveChangeStart = () =>
    createAction(actionType.DepartStuLeaveChange_START, null, true, true);

export const DepartStuLeaveChangeSuccess = (response) =>
    createAction(
        actionType.DepartStuLeaveChange_SUCCESS,
        false,
        false,
        response.status !== 401
    );

export const DepartStuLeaveChangeFail = (error) =>
    createAction(
        actionType.DepartStuLeaveChange_FAIL,
        error,
        false,
        error.status !== 401
    );

export const DepartStuLeaveChange = (form_data) => async (dispatch) => {
    try {
        dispatch(DepartStuLeaveChangeStart());
        const user_info = JSON.parse(localStorage.getItem('bss_authuser'));
        const authToken = localStorage.getItem('bss_authtoken');

        const headers = {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${authToken}`,
            country_host: user_info?.country_host,
            school_code: user_info?.school_code,
        };
        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        const config = {
            method: 'patch',
            headers,
            url: `${actionType.environment_url}/api/bss-admin/DepartStuLeaveChange`,
            data: formdata,
        };
        const response = await axios(config);
        dispatch(DepartStuLeaveChangeSuccess(response.data));
        return response.data;
    } catch (error) {
        dispatch(DepartStuLeaveChangeFail('Something went wrong, Please try again.'));
        return error;
    }
};




// return student approval 
export const ReturnStuLeaveChangeStart = () =>
    createAction(actionType.ReturnStuLeaveChange_START, null, true, true);

export const ReturnStuLeaveChangeSuccess = (response) =>
    createAction(
        actionType.ReturnStuLeaveChange_SUCCESS,
        false,
        false,
        response.status !== 401
    );

export const ReturnStuLeaveChangeFail = (error) =>
    createAction(
        actionType.ReturnStuLeaveChange_FAIL,
        error,
        false,
        error.status !== 401
    );

export const ReturnStuLeaveChange = (form_data) => async (dispatch) => {
    try {
        dispatch(ReturnStuLeaveChangeStart());
        const user_info = JSON.parse(localStorage.getItem('bss_authuser'));
        const authToken = localStorage.getItem('bss_authtoken');

        const headers = {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${authToken}`,
            country_host: user_info?.country_host,
            school_code: user_info?.school_code,
        };
        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        const config = {
            method: 'patch',
            headers,
            url: `${actionType.environment_url}/api/bss-admin/ReturnStuLeaveChange`,
            data: formdata,
        };
        const response = await axios(config);
        dispatch(ReturnStuLeaveChangeSuccess(response.data));
        return response.data;
    } catch (error) {
        dispatch(ReturnStuLeaveChangeFail('Something went wrong, Please try again.'));
        return error;
    }
};
