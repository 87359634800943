import React, { useState } from "react";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";

const UnArchiveBorderLeaveModal = ({ ArchiveOrUnarchiveBorderLeave }) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleArchive = () => {
    ArchiveOrUnarchiveBorderLeave();
    setTimeout(closeModal, 2000);
  };

  return (
    <>
      <OverlayTrigger overlay={<Tooltip id="archive-tooltip">Unarchive</Tooltip>}>
        <span
          style={{ color: "red", cursor: "pointer" }}
          className="fas fa-archive  fa-lg"
          onClick={openModal}
        />
      </OverlayTrigger>
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Unarchive Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to unarchive this leave?</Modal.Body>
        <Modal.Footer>
          <Button className="btn-sm" variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button className="btn-sm px-10" variant="primary" onClick={handleArchive}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default UnArchiveBorderLeaveModal;
