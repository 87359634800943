import React from "react";
import { createRoot } from "react-dom/client";

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";

import App from "./App";
import reducersnapshot from "./reducers/Snapshot";
import AuthReducer from "./reducers/Auth";
// import schooltReducer from "./reducers/School";
// import StudentReducer from "./reducers/Students";
// import ProgramReducer from "./reducers/Program";
// import DashboardReducer from "./reducers/Dashboard";
// import RolesAndPermissionsReducer from "./reducers/RolesAndPermissions";

import reportWebVitals from "./reportWebVitals";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers({
  Auth: AuthReducer,
  snapshot:reducersnapshot
  // school: schooltReducer,
  // students: StudentReducer,
  // program: ProgramReducer,
  // dashboard: DashboardReducer,
  // rolesandpermissions: RolesAndPermissionsReducer,
});

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunkMiddleware)));

const routing = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

// ReactDOM.render(routing, document.getElementById("root"));

const container = document.getElementById("root");
const root = createRoot(container);
root.render(routing);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();