import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import Footer from "../../Constant/Footer";
import Sidebar from "../../Constant/sidebar";
import TopBar from "../../Constant/TopBar";

import AddOnCampusModal from "./Modal/AddOnCampusModal"
import { toast } from "react-toastify";

import * as userActions from "../../../actions/index";

import DeleteConfirmRemove from "../../Utility/DeleteConfirmRemove";
import OnCampusLocationItem from "./OnCampusLocationItem";

// export default function SuperAdminCountry() {

const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info.school_code;

const OnCampusLocation = (props) => {

    const [confirm_modal, setconfirm_modal] = useState(false);
    const [editconfirmremovedata, seteditconfirmremovedata] = useState([]);
    const dispatch = useDispatch();
    const [Payments, setPayments] = useState([]);
    const [Paymentscount, setPaymentscount] = useState(0);
    const [filterPayments, setfilterPayments] = useState([])

    const [filterlimit, setfilterlimit] = useState(10);
    const [filterpage, setfilterpage] = useState(1);

    const [add_oncampus_modal, setadd_oncampus_modal] = useState(false);

    const [filterorder, setfilterorder] = useState("asc");
    const [filtersort, setfiltersort] = useState("location_name");
    const [filtersearch, setfiltersearch] = useState(null);
    // const [delete_country_modal, setdelete_country_modal] = useState(false);
    // const [deletecountrydata, setdeletecountrydata] = useState('');

    const showAddModalHandle = (event) => {
        setconfirm_modal(false);
    };
    const RemoveCourseModule = (event, course_data) => {
        seteditconfirmremovedata(course_data);
        setconfirm_modal(true);
    };



    const ConfirmRemove = (form_data) => {
        console.log('form_data===', form_data)
        setconfirm_modal(false);
        props.onDeleteOnCampusLocation(form_data).then((response) => {
            if (response.success === true) {
                GetAllOnCampusLocation();
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                // setadd_course_modal(false);
                // setedit_course_modal(false);
                setconfirm_modal(false);
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    };


    const GetAllOnCampusLocation = useCallback(async () => {
        dispatch(userActions.GetAllOnCampusLocation(filterpage, filterlimit, filtersort, filterorder, filtersearch)).then((response) => {
            if (response.success === true) {
                const arrayData = response.data.rows;
                let MemberId_level = [];
                const sumvalue = 1;
                for (let key in arrayData) {
                    const perpage = ((parseInt(filterpage) - parseInt(sumvalue)) * parseInt(filterlimit) + parseInt(key) + parseInt(sumvalue));

                    MemberId_level.push({
                        perpage: perpage,
                        on_campus_location_uuid: arrayData[key].on_campus_location_uuid,
                        location_name: arrayData[key].location_name,
                        is_on_campus_activate: arrayData[key].is_on_campus_activate,

                    });
                }
                setPayments(MemberId_level);
                setPaymentscount(response.data.count);
            } else {
            }
        });
    }, [dispatch, filterpage, filterlimit, filtersort, filterorder, filtersearch]);
    const AddCountrypoup = async (event) => {
        setadd_oncampus_modal(true)
    }
    const CreateOnCampusLocation = async (form_data) => {
        props.onCreateOnCampusLocation(form_data).then((response) => {
            if (response.success === true) {
                console.log(form_data);
                setadd_oncampus_modal(false)
                GetAllOnCampusLocation();
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }

        });
    }

    const showAddModalHandleCountry = async (event) => {
        setadd_oncampus_modal(false)
    }
    const handleChange = async (event, on_campus_location_uuid, value) => {
        console.log("hello")
        const Payload = {
            on_campus_location_uuid: on_campus_location_uuid,
            is_on_campus_activate: value,
        };
        console.log('Payload===', Payload)


        props.onUpdateOnCampusLocationStatus(Payload).then((response) => {
            if (response.success === true) {
                GetAllOnCampusLocation();
                setfilterpage(1);
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });

    }

    const inputChangeHandler = (event) => {
        console.log("payment", Payments);
        console.log("payment", event.target.value);
        const filterPaymentsArray = Payments.filter((item) => {
            return item.location_name.includes(event.target.value)
        })
        setfilterPayments(filterPaymentsArray)

        console.log("payment======", filterPaymentsArray);

        setfiltersearch(event.target.value);
        setfilterpage(1)

    }

    useEffect(() => {
        GetAllOnCampusLocation();

    }, [GetAllOnCampusLocation, filterpage, filterlimit, filtersort, filterorder, filtersearch]);

    return (
        <>
            {confirm_modal && (
                <DeleteConfirmRemove
                    title={`Delete On-Campus Location?`}
                    bodytitle="Are you sure you want to delete this Location?"
                    editconfirmremovedata={editconfirmremovedata}
                    confirm_modal={confirm_modal}
                    confirmButtonText="Delete"
                    ConfirmRemove={(event) => ConfirmRemove(event)}
                    delete_confirm_modal_action={(modal_action) => showAddModalHandle(modal_action)}
                />
            )}
            {add_oncampus_modal &&
                <AddOnCampusModal
                    add_oncampus_modal={add_oncampus_modal}
                    CreateOnCampusLocation={(event) => CreateOnCampusLocation(event)}
                    add_oncampus_modal_action={(modal_action) => showAddModalHandleCountry(modal_action)}
                // CreateOnCampusLocation={(event) => CreateOnCampusLocation(event)}
                />
            }
            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed" >
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <Sidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid " id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">

                                <div className="post d-flex flex-column-fluid" id="kt_post">

                                    <div id="kt_content_container" className="container-fluid d-flex justify-content-center">
                                        <div className="col-lg-12">

                                            <div className="card">
                                                <div className="card-header border-bottom align-items-center">
                                                    <h3 className="card-title align-items-start flex-column">
                                                        On Campus Location
                                                    </h3>
                                                    <span className="btn btn-sm btn-primary" onClick={(event) => {
                                                        AddCountrypoup(event);
                                                    }} >Add Location</span>
                                                </div>
                                                <div className="p-6 pb-0 d-flex align-items-center justify-content-end">
                                                    {/* <div className="d-flex align-items-center">Show <select className="select2-selection select2-selection--single form-select me-3 ms-3">
                                                    <option>25</option>
                                                    <option>30</option>
                                                </select> entries
                                                </div> */}
                                                    <div className="d-flex align-items-center position-relative">
                                                        <span className="svg-icon svg-icon-1 position-absolute ms-4">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                                                <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
                                                            </svg>
                                                        </span>
                                                        <input type="text" className="form-control form-control-sm w-350px ps-12 search-out fs-6" placeholder="Search " style={{ background: "transparent" }}
                                                            onChange={(event) => inputChangeHandler(event)}
                                                        />
                                                    </div>
                                                </div>

                                                {console.log("filterPayments", filterPayments)}

                                                {
                                                    <OnCampusLocationItem

                                                        Paymentscount={Paymentscount}
                                                        data={filterPayments.length > 0 ? filterPayments : Payments}
                                                        filterlimit={filterlimit}
                                                        filterpage={filterpage}
                                                        RemoveCourseModule={(event, data) => RemoveCourseModule(event, data)}
                                                        handleChange={(event, on_campus_location_uuid, value) => handleChange(event, on_campus_location_uuid, value)}

                                                        propsfilterpage={(event) => setfilterpage(event)}
                                                        propsfilterlimit={(filterlimit) => {
                                                            setfilterlimit(filterlimit);
                                                        }}
                                                    />
                                                }





                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <Footer />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
    onGetAllOnCampusLocation: userActions.GetAllOnCampusLocation,
    onCreateOnCampusLocation: userActions.CreateOnCampusLocation,
    onUpdateOnCampusLocationStatus: userActions.UpdateOnCampusLocationStatus,
    onDeleteOnCampusLocation: userActions.DeleteOnCampusLocation,

};
export default connect(mapStateToProps, mapDispatchToProps)(OnCampusLocation);