import axios from "axios";
import * as actionType from "../constants/actionTypes";
let qs = require("qs");

/*** GetAllDormitoryImport Action ***/
export const GetAllDormitoryImportStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllDormitoryImport_START,
    };
};
export const GetAllDormitoryImportSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllDormitoryImport_SUCCESS,
    };
};
export const GetAllDormitoryImportFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllDormitoryImport_FAIL,
    };
};
export const GetAllDormitoryImport = (dormitory_status) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllDormitoryImportStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllDormitory`,
            params: {
                dormitory_status:dormitory_status,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllDormitoryImportSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllDormitoryImportFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetAllManagersBySchoolIdImport Action ***/

export const GetAllManagersBySchoolIdImportStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllManagersBySchoolIdImport_START,
    };
};
export const GetAllManagersBySchoolIdImportSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllManagersBySchoolIdImport_SUCCESS,
    };
};
export const GetAllManagersBySchoolIdImportFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllManagersBySchoolIdImport_FAIL,
    };
};
export const GetAllManagersBySchoolIdImport = (is_user_activate,school_detail_id) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllManagersBySchoolIdImportStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
                school_detail_id: user_info.school_detail_id,
            },

            url: `${actionType.environment_url}/api/bss-admin/GetAllManagersBySchoolId`,
            params: {
               
                is_user_activate: is_user_activate,
                school_detail_id: user_info.school_detail_id,
                
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllManagersBySchoolIdImportSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllManagersBySchoolIdImportFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** GetAllClassesDetailsImport Action ***/
export const GetAllClassesDetailsImportStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllClassesDetailsImport_START,
    };
};
export const GetAllClassesDetailsImportSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllClassesDetailsImport_SUCCESS,
    };
};
export const GetAllClassesDetailsImportFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllClassesDetailsImport_FAIL,
    };
};
export const GetAllClassesDetailsImport = (page, limit, sort, order, is_class_activate ) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllClassesDetailsImportStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllClassesDetails`,
            params: {
                page: page,
                limit: limit,
                sort: sort,
                order: order,
                is_class_activate:is_class_activate,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllClassesDetailsImportSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllClassesDetailsImportFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** ImportStudentsCsv Action ***/
export const ImportStudentsCsvStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.ImportStudentsCsv_START,
    };
};
export const ImportStudentsCsvSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.ImportStudentsCsv_SUCCESS,
    };
};
export const ImportStudentsCsvFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.ImportStudentsCsv_FAIL,
    };
};
export const ImportStudentsCsv = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    
    return (dispatch) => {
        dispatch(ImportStudentsCsvStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/ImportStudentsCsv`,
        })
            .then(function (response) {
                dispatch(ImportStudentsCsvSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(ImportStudentsCsvFail("Something went wrong, Please try again."));
                return error;
            });
    };
};




/*** GetImportStudentDetails Action ***/

export const GetImportStudentDetailsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetImportStudentDetails_START,
    };
};
export const GetImportStudentDetailsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetImportStudentDetails_SUCCESS,
    };
};
export const GetImportStudentDetailsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetImportStudentDetails_FAIL,
    };
};
export const GetImportStudentDetails = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetImportStudentDetailsStart());
        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        return axios({
            method: "post",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetImportStudentDetails`,
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetImportStudentDetailsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetImportStudentDetailsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** GetAllCountries ***/
export const GetAllCountriesForImportStudentsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllCountriesForImportStudents_START,
    };
};
export const GetAllCountriesForImportStudentsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllCountriesForImportStudents_SUCCESS,
    };
};
export const GetAllCountriesForImportStudentsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllCountriesForImportStudents_FAIL,
    };
};
export const GetAllCountriesForImportStudents = () => {
    return (dispatch) => {
        dispatch(GetAllCountriesForImportStudentsStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetAllCountriesForImportStudents`,
            params: {
               
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllCountriesForImportStudentsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllCountriesForImportStudentsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

