import axios from "axios";
import * as actionType from "../../constants/actionTypes";
import { CropLandscapeOutlined } from "@mui/icons-material";
let qs = require("qs");


// /*** CreateClass Action ***/
const createAction = (type, error, loader, isAuthenticated) => ({
    error,
    loader,
    isAuthenticated,
    type,
});





export const CreateAdhocLeaveStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.CreateAdhocLeave_START,
    };
};
export const CreateAdhocLeaveSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.CreateAdhocLeave_SUCCESS,
    };
};
export const CreateAdhocLeaveFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.CreateAdhocLeave_FAIL,
    };
};

export const CreateAdhocLeave = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(CreateAdhocLeaveStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        return axios({
            method: "post",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/CreateAdhocLeave`,
        })
            .then(function (response) {
                dispatch(CreateAdhocLeaveSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateAdhocLeaveFail("Something went wrong, Please try again."));
                return error;
            });
    };
};




export const GetAllStudentsForAdhocLeaveStart = () =>
    createAction(actionType.GetAllStudentsForAdhocLeave_START, null, true, true);

export const GetAllStudentsForAdhocLeaveSuccess = (response) =>
    createAction(
        actionType.GetAllStudentsForAdhocLeave_SUCCESS,
        false,
        false,
        response.status !== 401
    );

export const GetAllStudentsForAdhocLeaveFail = (error) =>
    createAction(
        actionType.GetAllStudentsForAdhocLeave_FAIL,
        error,
        false,
        error.status !== 401
    );
export const GetAllStudentsForAdhocLeave = (page, limit, sort, order, search, gender, dormitory_id, class_id) => async (dispatch) => {
        try {
            dispatch(GetAllStudentsForAdhocLeaveStart());
            const user_info = JSON.parse(localStorage.getItem('bss_authuser'));
            const authToken = localStorage.getItem('bss_authtoken');
            const headers = {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${authToken}`,
                country_host: user_info?.country_host,
                school_code: user_info?.school_code,
            };
            const params = {
                page,
                limit,
                sort,
                order,
                search,
                gender,
                class_id,
                dormitory_id
            };
            const config = {
                method: 'get',
                headers,
                url: `${actionType.environment_url}/api/bss-admin/GetAllStudentsForAdhocLeave`,
                params,
                paramsSerializer: (params) => qs.stringify(params),
            };
            const response = await axios(config);
            dispatch(GetAllStudentsForAdhocLeaveSuccess(response.data));
            return response.data;
        } catch (error) {
            dispatch(GetAllStudentsForAdhocLeaveFail('Something went wrong, Please try again.'));
            return error;
        }
    };

export const GetAllAdhocLeaveStudentDetailsStart = () =>
    createAction(actionType.GetAllAdhocLeaveStudentDetails_START, null, true, true);

export const GetAllAdhocLeaveStudentDetailsSuccess = (response) =>
    createAction(
        actionType.GetAllAdhocLeaveStudentDetails_SUCCESS,
        false,
        false,
        response.status !== 401
    );

export const GetAllAdhocLeaveStudentDetailsFail = (error) =>
    createAction(
        actionType.GetAllAdhocLeaveStudentDetails_FAIL,
        error,
        false,
        error.status !== 401
    );
export const GetAllAdhocLeaveStudentDetails = (page, limit, sort, order, search, from_date, to_date, class_id,
    dormitory_id, activity_id, pending) => async (dispatch) => {
        try {
            dispatch(GetAllAdhocLeaveStudentDetailsStart());
            const user_info = JSON.parse(localStorage.getItem('bss_authuser'));
            const authToken = localStorage.getItem('bss_authtoken');
            const headers = {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${authToken}`,
                country_host: user_info?.country_host,
                school_code: user_info?.school_code,
            };
            const params = {
                page,
                limit,
                sort,
                order,
                search,
                from_date,
                to_date,
                class_id,
                dormitory_id,
                activity_id,
                pending
            };
            const config = {
                method: 'get',
                headers,
                url: `${actionType.environment_url}/api/bss-admin/GetAllAdhocLeaveStudentDetails`,
                params,
                paramsSerializer: (params) => qs.stringify(params),
            };
            const response = await axios(config);
            dispatch(GetAllAdhocLeaveStudentDetailsSuccess(response.data));
            return response.data;
        } catch (error) {
            dispatch(GetAllAdhocLeaveStudentDetailsFail('Something went wrong, Please try again.'));
            return error;
        }
    };




export const GetAdhocLeaveDetailByIdStart = () =>
    createAction(actionType.GetAdhocLeaveDetailById_START, null, true, true);

export const GetAdhocLeaveDetailByIdSuccess = (response) =>
    createAction(
        actionType.GetAdhocLeaveDetailById_SUCCESS,
        false,
        false,
        response.status !== 401
    );

export const GetAdhocLeaveDetailByIdFail = (error) =>
    createAction(
        actionType.GetAdhocLeaveDetailById_FAIL,
        error,
        false,
        error.status !== 401
    );

export const GetAdhocLeaveDetailById = (adhoc_leave_detail_uuid) => async (dispatch) => {
    try {
        dispatch(GetAdhocLeaveDetailByIdStart());
        const user_info = JSON.parse(localStorage.getItem('bss_authuser'));
        const authToken = localStorage.getItem('bss_authtoken');
        const headers = {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${authToken}`,
            country_host: user_info?.country_host,
            school_code: user_info?.school_code,
        };
        const params = {
            adhoc_leave_detail_uuid
        };
        const config = {
            method: 'get',
            headers,
            url: `${actionType.environment_url}/api/bss-admin/GetAdhocLeaveDetailById`,
            params,
            paramsSerializer: (params) => qs.stringify(params),
        };
        const response = await axios(config);

        dispatch(GetAdhocLeaveDetailByIdSuccess(response.data));
        return response.data;
    } catch (error) {
        dispatch(GetAdhocLeaveDetailByIdFail('Something went wrong, Please try again.'));
        return error;
    }
};

export const EditBoardersLeaveStart = () =>
    createAction(actionType.EditBoardersLeave_START, null, true, true);

export const EditBoardersLeaveSuccess = (response) =>
    createAction(
        actionType.EditBoardersLeave_SUCCESS,
        false,
        false,
        response.status !== 401
    );

export const EditBoardersLeaveFail = (error) =>
    createAction(
        actionType.EditBoardersLeave_FAIL,
        error,
        false,
        error.status !== 401
    );

export const EditBoardersLeave = (form_data) => async (dispatch) => {
    try {
        dispatch(EditBoardersLeaveStart());
        const user_info = JSON.parse(localStorage.getItem('bss_authuser'));
        const authToken = localStorage.getItem('bss_authtoken');

        const headers = {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${authToken}`,
            country_host: user_info?.country_host,
            school_code: user_info?.school_code,
        };
        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        const config = {
            method: 'put',
            headers,
            url: `${actionType.environment_url}/api/bss-admin/UpdateBorderLeavesDetails`,
            data: formdata,
        };
        const response = await axios(config);
        dispatch(EditBoardersLeaveSuccess(response.data));
        return response.data;
    } catch (error) {
        dispatch(EditBoardersLeaveFail('Something went wrong, Please try again.'));
        return error;
    }
};

export const BorderLeaveApprovalStart = () =>
    createAction(actionType.BorderLeaveApproval_START, null, true, true);

export const BorderLeaveApprovalSuccess = (response) =>
    createAction(
        actionType.BorderLeaveApproval_SUCCESS,
        false,
        false,
        response.status !== 401
    );

export const BorderLeaveApprovalFail = (error) =>
    createAction(
        actionType.BorderLeaveApproval_FAIL,
        error,
        false,
        error.status !== 401
    );

export const BorderLeaveApproval = (form_data) => async (dispatch) => {
    try {
        dispatch(BorderLeaveApprovalStart());
        const user_info = JSON.parse(localStorage.getItem('bss_authuser'));
        const authToken = localStorage.getItem('bss_authtoken');

        const headers = {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${authToken}`,
            country_host: user_info?.country_host,
            school_code: user_info?.school_code,
        };
        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        const config = {
            method: 'patch',
            headers,
            url: `${actionType.environment_url}/api/bss-admin/BorderLeaveApproval`,
            data: formdata,
        };
        const response = await axios(config);
        dispatch(BorderLeaveApprovalSuccess(response.data));
        return response.data;
    } catch (error) {
        dispatch(BorderLeaveApprovalFail('Something went wrong, Please try again.'));
        return error;
    }
};


export const GetApprovedStudentHostStart = () =>
    createAction(actionType.GetApprovedStudentHost_START, null, true, true);

export const GetApprovedStudentHostSuccess = (response) =>
    createAction(
        actionType.GetApprovedStudentHost_SUCCESS,
        false,
        false,
        response.status !== 401
    );

export const GetApprovedStudentHostFail = (error) =>
    createAction(
        actionType.GetApprovedStudentHost_FAIL,
        error,
        false,
        error.status !== 401
    );

export const GetApprovedStudentHost = (student_id) => async (dispatch) => {
    try {
        dispatch(GetApprovedStudentHostStart());
        const user_info = JSON.parse(localStorage.getItem('bss_authuser'));
        const authToken = localStorage.getItem('bss_authtoken');

        const headers = {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${authToken}`,
            country_host: user_info?.country_host,
            school_code: user_info?.school_code,
        };
        const params = {
            student_id
        }
        const config = {
            method: 'get',
            headers,
            url: `${actionType.environment_url}/api/bss-admin/GetApprovedStudentHost`,
            params
        };
        const response = await axios(config);
        dispatch(GetApprovedStudentHostSuccess(response.data));
        return response.data;
    } catch (error) {
        dispatch(GetApprovedStudentHostFail('Something went wrong, Please try again.'));
        return error;
    }
};


//
export const ArchiveOrUnarchiveBorderLeaveStart = () =>
    createAction(actionType.ArchiveOrUnarchiveBorderLeave_START, null, true, true);

export const ArchiveOrUnarchiveBorderLeaveSuccess = (response) =>
    createAction(
        actionType.ArchiveOrUnarchiveBorderLeave_SUCCESS,
        false,
        false,
        response.status !== 401
    );

export const ArchiveOrUnarchiveBorderLeaveFail = (error) =>
    createAction(
        actionType.ArchiveOrUnarchiveBorderLeave_FAIL,
        error,
        false,
        error.status !== 401
    );

export const ArchiveOrUnarchiveBorderLeave = (form_data) => async (dispatch) => {
    try {
        dispatch(ArchiveOrUnarchiveBorderLeaveStart());
        const user_info = JSON.parse(localStorage.getItem('bss_authuser'));
        const authToken = localStorage.getItem('bss_authtoken');

        const headers = {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${authToken}`,
            country_host: user_info?.country_host,
            school_code: user_info?.school_code,
        };
        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        const config = {
            method: 'put',
            headers,
            url: `${actionType.environment_url}/api/bss-admin/ArchiveOrUnarchiveBorderLeave`,
            data: formdata,
        };
        const response = await axios(config);
        dispatch(ArchiveOrUnarchiveBorderLeaveSuccess(response.data));
        return response.data;
    } catch (error) {
        dispatch(ArchiveOrUnarchiveBorderLeaveFail('Something went wrong, Please try again.'));
        return error;
    }
};


//------archive---------------
export const GetArchiveStudentDetailsStart = () =>
    createAction(actionType.GetArchiveStudentDetails_START, null, true, true);

export const GetArchiveStudentDetailsSuccess = (response) =>
    createAction(
        actionType.GetArchiveStudentDetails_SUCCESS,
        false,
        false,
        response.status !== 401
    );

export const GetArchiveStudentDetailsFail = (error) =>
    createAction(
        actionType.GetArchiveStudentDetails_FAIL,
        error,
        false,
        error.status !== 401
    );
export const GetArchiveStudentDetails = (page, limit, sort, order, search, from_date, to_date, class_ids,
    dormitory_ids) => async (dispatch) => {
        try {
            dispatch(GetArchiveStudentDetailsStart());
            const user_info = JSON.parse(localStorage.getItem('bss_authuser'));
            const authToken = localStorage.getItem('bss_authtoken');
            const headers = {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${authToken}`,
                country_host: user_info?.country_host,
                school_code: user_info?.school_code,
            };
            const params = {
                page,
                limit,
                sort,
                order,
                search,
                from_date,
                to_date,
                class_ids,
                dormitory_ids
            };
            const config = {
                method: 'get',
                headers,
                url: `${actionType.environment_url}/api/bss-admin/GetArchiveStudentDetails`,
                params,
                paramsSerializer: (params) => qs.stringify(params),
            };
            const response = await axios(config);
            dispatch(GetArchiveStudentDetailsSuccess(response.data));
            return response.data;
        } catch (error) {
            dispatch(GetArchiveStudentDetailsFail('Something went wrong, Please try again.'));
            return error;
        }
    };


//------departure student---------------
export const GetDepartureStudentDetailsStart = () =>
    createAction(actionType.GetDepartureStudentDetails_START, null, true, true);

export const GetDepartureStudentDetailsSuccess = (response) =>
    createAction(
        actionType.GetDepartureStudentDetails_SUCCESS,
        false,
        false,
        response.status !== 401
    );

export const GetDepartureStudentDetailsFail = (error) =>
    createAction(
        actionType.GetDepartureStudentDetails_FAIL,
        error,
        false,
        error.status !== 401
    );
export const GetDepartureStudentDetails = (page, limit, sort, order, search, from_date, to_date) => async (dispatch) => {
    try {
        dispatch(GetDepartureStudentDetailsStart());
        const user_info = JSON.parse(localStorage.getItem('bss_authuser'));
        const authToken = localStorage.getItem('bss_authtoken');
        const headers = {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${authToken}`,
            country_host: user_info?.country_host,
            school_code: user_info?.school_code,
        };
        const params = {
            page,
            limit,
            sort,
            order,
            search,
            from_date,
            to_date,
        };
        const config = {
            method: 'get',
            headers,
            url: `${actionType.environment_url}/api/bss-admin/GetDepartureStudentDetails`,
            params,
            paramsSerializer: (params) => qs.stringify(params),
        };
        const response = await axios(config);
        dispatch(GetDepartureStudentDetailsSuccess(response.data));
        return response.data;
    } catch (error) {
        dispatch(GetDepartureStudentDetailsFail('Something went wrong, Please try again.'));
        return error;
    }
};


//------return student---------------
export const GetReturnStudentDetailsStart = () =>
    createAction(actionType.GetReturnStudentDetails_START, null, true, true);

export const GetReturnStudentDetailsSuccess = (response) =>
    createAction(
        actionType.GetReturnStudentDetails_SUCCESS,
        false,
        false,
        response.status !== 401
    );

export const GetReturnStudentDetailsFail = (error) =>
    createAction(
        actionType.GetReturnStudentDetails_FAIL,
        error,
        false,
        error.status !== 401
    );
export const GetReturnStudentDetails = (page, limit, sort, order, search, from_date, to_date) => async (dispatch) => {
    try {
        dispatch(GetReturnStudentDetailsStart());
        const user_info = JSON.parse(localStorage.getItem('bss_authuser'));
        const authToken = localStorage.getItem('bss_authtoken');
        const headers = {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${authToken}`,
            country_host: user_info?.country_host,
            school_code: user_info?.school_code,
        };
        const params = {
            page,
            limit,
            sort,
            order,
            search,
            from_date,
            to_date,
        };
        const config = {
            method: 'get',
            headers,
            url: `${actionType.environment_url}/api/bss-admin/GetReturnStudentDetails`,
            params,
            paramsSerializer: (params) => qs.stringify(params),
        };
        const response = await axios(config);
        dispatch(GetReturnStudentDetailsSuccess(response.data));
        return response.data;
    } catch (error) {
        dispatch(GetReturnStudentDetailsFail('Something went wrong, Please try again.'));
        return error;
    }
};



//------boarder dashboard---------------
export const ManageBoarderLeaveDashboardStart = () =>
    createAction(actionType.ManageBoarderLeaveDashboard_START, null, true, true);

export const ManageBoarderLeaveDashboardSuccess = (response) =>
    createAction(
        actionType.ManageBoarderLeaveDashboard_SUCCESS,
        false,
        false,
        response.status !== 401
    );

export const ManageBoarderLeaveDashboardFail = (error) =>
    createAction(
        actionType.ManageBoarderLeaveDashboard_FAIL,
        error,
        false,
        error.status !== 401
    );
export const ManageBoarderLeaveDashboard = () => async (dispatch) => {
    try {
        dispatch(ManageBoarderLeaveDashboardStart());
        const user_info = JSON.parse(localStorage.getItem('bss_authuser'));
        const authToken = localStorage.getItem('bss_authtoken');
        const headers = {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${authToken}`,
            country_host: user_info?.country_host,
            school_code: user_info?.school_code,
        };
        const config = {
            method: 'get',
            headers,
            url: `${actionType.environment_url}/api/bss-admin/ManageBoarderLeaveDashboard`,
        };
        const response = await axios(config);
        dispatch(ManageBoarderLeaveDashboardSuccess(response.data));
        return response.data;
    } catch (error) {
        dispatch(ManageBoarderLeaveDashboardFail('Something went wrong, Please try again.'));
        return error;
    }
};


// departure student approval 
export const DepartStuLeaveChangeStart = () =>
    createAction(actionType.DepartStuLeaveChange_START, null, true, true);

export const DepartStuLeaveChangeSuccess = (response) =>
    createAction(
        actionType.DepartStuLeaveChange_SUCCESS,
        false,
        false,
        response.status !== 401
    );

export const DepartStuLeaveChangeFail = (error) =>
    createAction(
        actionType.DepartStuLeaveChange_FAIL,
        error,
        false,
        error.status !== 401
    );

export const DepartStuLeaveChange = (form_data) => async (dispatch) => {
    try {
        dispatch(DepartStuLeaveChangeStart());
        const user_info = JSON.parse(localStorage.getItem('bss_authuser'));
        const authToken = localStorage.getItem('bss_authtoken');

        const headers = {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${authToken}`,
            country_host: user_info?.country_host,
            school_code: user_info?.school_code,
        };
        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        const config = {
            method: 'patch',
            headers,
            url: `${actionType.environment_url}/api/bss-admin/DepartStuLeaveChange`,
            data: formdata,
        };
        const response = await axios(config);
        dispatch(DepartStuLeaveChangeSuccess(response.data));
        return response.data;
    } catch (error) {
        dispatch(DepartStuLeaveChangeFail('Something went wrong, Please try again.'));
        return error;
    }
};




// return student approval 
export const ReturnStuLeaveChangeStart = () =>
    createAction(actionType.ReturnStuLeaveChange_START, null, true, true);

export const ReturnStuLeaveChangeSuccess = (response) =>
    createAction(
        actionType.ReturnStuLeaveChange_SUCCESS,
        false,
        false,
        response.status !== 401
    );

export const ReturnStuLeaveChangeFail = (error) =>
    createAction(
        actionType.ReturnStuLeaveChange_FAIL,
        error,
        false,
        error.status !== 401
    );

export const ReturnStuLeaveChange = (form_data) => async (dispatch) => {
    try {
        dispatch(ReturnStuLeaveChangeStart());
        const user_info = JSON.parse(localStorage.getItem('bss_authuser'));
        const authToken = localStorage.getItem('bss_authtoken');

        const headers = {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${authToken}`,
            country_host: user_info?.country_host,
            school_code: user_info?.school_code,
        };
        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        const config = {
            method: 'patch',
            headers,
            url: `${actionType.environment_url}/api/bss-admin/ReturnStuLeaveChange`,
            data: formdata,
        };
        const response = await axios(config);
        dispatch(ReturnStuLeaveChangeSuccess(response.data));
        return response.data;
    } catch (error) {
        dispatch(ReturnStuLeaveChangeFail('Something went wrong, Please try again.'));
        return error;
    }
};



export const EditAdhocLeaveStart = () =>{
    return{
        error:null,
        loader:true,
        isAuthenticated:true,
        type:actionType.EditAdhocLeave_START
    }
}

export const EditAdhocLeaveSuccess = (response) =>{
    return{
        error:false,
        loader:false,
        isAuthenticated: response.status === 401 ? false : true,
        type:actionType.EditAdhocLeave_SUCCESS
       
    }
}

export const EditAdhocLeaveFail = (error) =>{
    return{
        error:error,
        loader:false,
        isAuthenticated:error.status === 401 ? false : true,
        type:actionType.EditAdhocLeave_FAIL
       
    }
}

export const EditAdhocLeave = (form_data) => async (dispatch) => {
    try {
        dispatch(EditAdhocLeaveStart());
        const user_info = JSON.parse(localStorage.getItem('bss_authuser'));
        const authToken = localStorage.getItem('bss_authtoken');

        const headers = {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${authToken}`,
            country_host: user_info?.country_host,
            school_code: user_info?.school_code,
        };
        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        const config = {
            method: 'put',
            headers,
            url: `${actionType.environment_url}/api/bss-admin/UpdateAdhocLeaveDetails`,
            data: formdata,
        };
        const response = await axios(config);
        dispatch(EditAdhocLeaveSuccess(response.data));
        return response.data;
    } catch (error) {
        dispatch(EditAdhocLeaveFail('Something went wrong, Please try again.'));
        return error;
    }
};




export const UpdateAdhocLeaveTimeScheduleStart = () =>{
    return{
        error:null,
        loader:true,
        isAuthenticated:true,
        type:actionType.UpdateAdhocLeaveTimeSchedule_START
    }
}

export const UpdateAdhocLeaveTimeScheduleSuccess = (response) =>{
    return{
        error:false,
        loader:false,
        isAuthenticated: response.status === 401 ? false : true,
        type:actionType.UpdateAdhocLeaveTimeSchedule_SUCCESS
       
    }
}

export const UpdateAdhocLeaveTimeScheduleFail = (error) =>{
    return{
        error:error,
        loader:false,
        isAuthenticated:error.status === 401 ? false : true,
        type:actionType.UpdateAdhocLeaveTimeSchedule_FAIL
       
    }
}

export const UpdateAdhocLeaveTimeSchedule = (form_data) => async (dispatch) => {
    try {
        dispatch(UpdateAdhocLeaveTimeScheduleStart());
        const user_info = JSON.parse(localStorage.getItem('bss_authuser'));
        const authToken = localStorage.getItem('bss_authtoken');

        const headers = {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${authToken}`,
            country_host: user_info?.country_host,
            school_code: user_info?.school_code,
        };
        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        const config = {
            method: 'put',
            headers,
            url: `${actionType.environment_url}/api/bss-admin/UpdateAdhocLeaveTimeSchedule`,
            data: formdata,
        };
        const response = await axios(config);
        dispatch(UpdateAdhocLeaveTimeScheduleSuccess(response.data));
        return response.data;
    } catch (error) {
        dispatch(UpdateAdhocLeaveTimeScheduleFail('Something went wrong, Please try again.'));
        return error;
    }
};





export const DeleteAdhocLeaveByIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.DeleteAdhocLeaveById_START,
    };
};
export const DeleteAdhocLeaveByIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.DeleteAdhocLeaveById_SUCCESS,
    };
};
export const DeleteAdhocLeaveByIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.DeleteAdhocLeaveById_FAIL,
    };
};
export const DeleteAdhocLeaveById = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(DeleteAdhocLeaveByIdStart());
        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "delete",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/DeleteAdhocLeaveById`,
        })
            .then(function (response) {
                console.log("resposne", response.data);
                dispatch(DeleteAdhocLeaveByIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                console.log("resposne fail", error);
                dispatch(DeleteAdhocLeaveByIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



export const GetAllAdhocLeaveforSignOrSignOutStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllAdhocLeaveforSignOrSignOut_START,
    };
};
export const GetAllAdhocLeaveforSignOrSignOutSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllAdhocLeaveforSignOrSignOut_SUCCESS,
    };
};
export const GetAllAdhocLeaveforSignOrSignOutFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllAdhocLeaveforSignOrSignOut_FAIL,
    };
};
export const GetAllAdhocLeaveforSignOrSignOut = (page, limit, sort, order, dormitory_id,class_id, gender, from_date, to_date, search, adhoc_leave_day) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllAdhocLeaveforSignOrSignOutStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllAdhocLeaveforSignOrSignOut`,
            params: {
                page: page,
                limit: limit,
                sort: sort,
                order: order,
                dormitory_id:dormitory_id,
                class_id:class_id,
                gender:gender,
                from_date:from_date,
                to_date:to_date,
                search:search,
                adhoc_leave_day:adhoc_leave_day
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllAdhocLeaveforSignOrSignOutSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllAdhocLeaveforSignOrSignOutFail("Something went wrong, Please try again."));
                return error;
            });
    };
};




export const UpdateAdhocLeaveSignInOrSignOutStart = () =>{
    return{
        error:null,
        loader:true,
        isAuthenticated:true,
        type:actionType.UpdateAdhocLeaveSignInOrSignOut_START
    }
}

export const UpdateAdhocLeaveSignInOrSignOutSuccess = (response) =>{
    return{
        error:false,
        loader:false,
        isAuthenticated: response.status === 401 ? false : true,
        type:actionType.UpdateAdhocLeaveSignInOrSignOut_SUCCESS
       
    }
}

export const UpdateAdhocLeaveSignInOrSignOutFail = (error) =>{
    return{
        error:error,
        loader:false,
        isAuthenticated:error.status === 401 ? false : true,
        type:actionType.UpdateAdhocLeaveSignInOrSignOut_FAIL
       
    }
}

export const UpdateAdhocLeaveSignInOrSignOut = (form_data) => async (dispatch) => {
    try {
        dispatch(UpdateAdhocLeaveSignInOrSignOutStart());
        const user_info = JSON.parse(localStorage.getItem('bss_authuser'));
        const authToken = localStorage.getItem('bss_authtoken');

        const headers = {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${authToken}`,
            country_host: user_info?.country_host,
            school_code: user_info?.school_code,
        };
        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        const config = {
            method: 'put',
            headers,
            url: `${actionType.environment_url}/api/bss-admin/UpdateAdhocLeaveSignInOrSignOut`,
            data: formdata,
        };
        const response = await axios(config);
        dispatch(UpdateAdhocLeaveSignInOrSignOutSuccess(response.data));
        return response.data;
    } catch (error) {
        dispatch(UpdateAdhocLeaveSignInOrSignOutFail('Something went wrong, Please try again.'));
        return error;
    }
};

