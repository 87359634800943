import axios from "axios";
import * as actionType from "../constants/actionTypes";
let qs = require("qs");

/*** SelectCountry Action ***/
export const SelectCountrystart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.SelectCountry_START,
    };
};
export const SelectCountrysuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.SelectCountry_SUCCESS,
    };
};
export const SelectCountryfail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.SelectCountry_FAIL,
    };
};


export const SelectCountry = (form_data) => {
    return (dispatch) => {
        dispatch(SelectCountrystart());
        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        //console.log(formdata);
        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/superadmin/SelectCountry`,
        })
            .then(function (response) {
                console.log('resposne', response.data);
                dispatch(SelectCountrysuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                console.log('resposne fail', error);
                dispatch(SelectCountryfail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetClients Action ***/
export const GetAllSelectedCountriesStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllSelectedCountries_START,
    };
};
export const GetAllSelectedCountriesSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllSelectedCountries_SUCCESS,
    };
};
export const GetAllSelectedCountriesFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllSelectedCountries_FAIL,
    };
};
export const GetAllSelectedCountries = (page, limit, sort, order, search) => {
    return (dispatch) => {
        dispatch(GetAllSelectedCountriesStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/superadmin/GetAllSelectedCountries`,
            params: {
                page: page,
                limit: limit,
                sort: sort,
                order: order,
                search: search,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllSelectedCountriesSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllSelectedCountriesFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetAllStatesByCountryId Action ***/
export const GetAllStatesByCountryIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllStatesByCountryId_START,
    };
};
export const GetAllStatesByCountryIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllStatesByCountryId_SUCCESS,
    };
};
export const GetAllStatesByCountryIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllStatesByCountryId_FAIL,
    };
};
export const GetAllStatesByCountryId = (country_id, page, limit, sort, order, search) => {
    return (dispatch) => {
        dispatch(GetAllStatesByCountryIdStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetAllStatesByCountryId`,
            params: {
                page: page,
                limit: limit,
                sort: sort,
                order: order,
                search: search,
                country_id: country_id,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllStatesByCountryIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllStatesByCountryIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** CreateNewState Action ***/
export const CreateNewStatestart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.CreateNewState_START,
    };
};
export const CreateNewStatesuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.CreateNewState_SUCCESS,
    };
};
export const CreateNewStatefail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.CreateNewState_FAIL,
    };
};


export const CreateNewState = (form_data) => {
    return (dispatch) => {
        dispatch(CreateNewStatestart());
        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        //console.log(formdata);
        return axios({
            method: "post",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/superadmin/CreateNewState`,
        })
            .then(function (response) {
                console.log('resposne', response.data);
                dispatch(CreateNewStatesuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                console.log('resposne fail', error);
                dispatch(CreateNewStatefail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** GetAllCitiesByStateId Action ***/
export const GetAllCitiesByStateIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllCitiesByStateId_START,
    };
};
export const GetAllCitiesByStateIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllCitiesByStateId_SUCCESS,
    };
};
export const GetAllCitiesByStateIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllCitiesByStateId_FAIL,
    };
};
export const GetAllCitiesByStateId = (state_id, page, limit, sort, order, search) => {
    return (dispatch) => {
        dispatch(GetAllCitiesByStateIdStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetAllCitiesByStateId`,
            params: {
                page: page,
                limit: limit,
                sort: sort,
                order: order,
                search: search,
                state_id: state_id,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllCitiesByStateIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllCitiesByStateIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** CreateNewCity Action ***/
export const CreateNewCitystart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.CreateNewCity_START,
    };
};
export const CreateNewCitysuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.CreateNewCity_SUCCESS,
    };
};
export const CreateNewCityfail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.CreateNewCity_FAIL,
    };
};


export const CreateNewCity = (form_data) => {
    return (dispatch) => {
        dispatch(CreateNewCitystart());
        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        //console.log(formdata);
        return axios({
            method: "post",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/superadmin/CreateNewCity`,
        })
            .then(function (response) {
                console.log('resposne', response.data);
                dispatch(CreateNewCitysuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                console.log('resposne fail', error);
                dispatch(CreateNewCityfail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** DeactivateCity Action ***/
export const DeactivateCitystart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.DeactivateCity_START,
    };
};
export const DeactivateCitysuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.DeactivateCity_SUCCESS,
    };
};
export const DeactivateCityfail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.DeactivateCity_FAIL,
    };
};


export const DeactivateCity = (form_data) => {
    return (dispatch) => {
        dispatch(DeactivateCitystart());
        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        //console.log(formdata);
        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/superadmin/DeactivateCity`,
        })
            .then(function (response) {
                console.log('resposne', response.data);
                dispatch(DeactivateCitysuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                console.log('resposne fail', error);
                dispatch(DeactivateCityfail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** DeactivateState Action ***/
export const DeactivateStatestart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.DeactivateState_START,
    };
};
export const DeactivateStatesuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.DeactivateState_SUCCESS,
    };
};
export const DeactivateStatefail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.DeactivateState_FAIL,
    };
};


export const DeactivateState = (form_data) => {
    return (dispatch) => {
        dispatch(DeactivateStatestart());
        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        //console.log(formdata);
        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/superadmin/DeactivateState`,
        })
            .then(function (response) {
                console.log('resposne', response.data);
                dispatch(DeactivateStatesuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                console.log('resposne fail', error);
                dispatch(DeactivateStatefail("Something went wrong, Please try again."));
                return error;
            });
    };
};