import React from "react";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Validations from "../../component/Utility/Validations";
import * as userActions from "../../actions/index";

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            hiddenpassword: true,

            reaset_password_form: {
                password: {
                    type: "password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    placeholder: "Enter your new Password",
                    validations: { required: true, min_length: 8 , password: true },
                },

                confirm_password: {
                    type: "password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    placeholder: "Confirm Password",
                    validations: { required: true, min_length: 8 , password: true },
                },

                user_uuid: {
                    type: "hidden",
                    label: "country_host",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "dikonia@gmail.com",
                    validations: { required: false },
                },

            },
            is_form_valid: false,
            passwordshow: false,

            redirect_url: "",
            isAuthenticated: false,
        };

        this.toggleShow = this.toggleShow.bind(this);
    }
    toggleShow() {
        this.setState({ hiddenpassword: !this.state.hiddenpassword });
    }

    async componentDidMount() {
    }
    inputChangeHandler(event, identifier) {
        const update_reaset_password_form = { ...this.state.reaset_password_form };
        const form_element = { ...update_reaset_password_form[identifier] };

        form_element.value = event.target.value;

        const queryParams = new URLSearchParams(window.location.search);
        const user_uuid = queryParams.get('token');
        update_reaset_password_form['user_uuid'].value = user_uuid;

        update_reaset_password_form[identifier] = form_element;
        this.setState({ reaset_password_form: update_reaset_password_form });


        clearTimeout(this.typingTimeout); // Clear the previous timeout

        this.typingTimeout = setTimeout(() => {
        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_reaset_password_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_reaset_password_form) {
            is_form_valid = update_reaset_password_form[identifier].valid && is_form_valid;
        }
        console.log("update_reaset_password_form", update_reaset_password_form)
        this.setState({
            reaset_password_form: update_reaset_password_form,
            is_form_valid: is_form_valid,
        });
    }, 2000);
    }

    loginSubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_reaset_password_form = this.state.reaset_password_form;
        for (let key in update_reaset_password_form) {
            let form_element = update_reaset_password_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            // form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_reaset_password_form[key] = form_element;
        }
        this.setState({ reaset_password_form: update_reaset_password_form });

        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.reaset_password_form) {
                form_data[key] = this.state.reaset_password_form[key].value;
            }
            this.props.onResetPassword(form_data).then((response) => {
                if (response.success === true) {
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });


                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: "/",
                        });
                    }, 1000);

                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }


            });
        }
    };

    render() {
        const { reaset_password_form } = this.state;
        console.log("reaset_password_form===", reaset_password_form);
        return (
            <React.Fragment>
                <ToastContainer />
                {this.state.isAuthenticated ? <Navigate to={this.state.redirect_url} /> : null}
                <div className="d-flex flex-column flex-root h-100">
                    <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
                        <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
                            <Link to="/" className="mb-8">
                                {/* <img alt="Logo" src="/assets/media/logos/logo.png" className="h-70px logo" /> */}
                                <img alt="Logo" src="/assets/media/logos/TRIANGLE PNG (WOP) (2).png" style={{height: '130px'}} />
                            </Link>
                            <div className="w-lg-500px bg-body rounded shadow-sm p-8 p-lg-10 mx-auto">
                                <form className="form w-100" onSubmit={this.loginSubmitHandler}>
                                    <div className="text-center mb-10">
                                        <h1 className="text-dark mb-3">Reset Password?</h1>
                                    </div>
                                    <div className="row">
                                        <div className="fv-row mb-5 col-sm-12">
                                            <label className="form-label fw-bolder text-dark">{reaset_password_form.password.label}</label>
                                            <div className="position-relative">
                                            <input
                                                type={this.state.hiddenpassword ? 'password' : 'text'}
                                                className="form-control"
                                                placeholder={reaset_password_form.password.placeholder}
                                                value={reaset_password_form.password.value ? reaset_password_form.password.value : ""}
                                                onChange={(event) => this.inputChangeHandler(event, "password")}
                                            />
                                            <span onClick={this.toggleShow} className="btn btn-icon position-absolute translate-middle top-50 end-0" data-kt-password-meter-control="visibility">

                                                {this.state.hiddenpassword ? <i className="bi bi-eye-slash fs-2"></i> : <i className="bi bi-eye fs-2"></i>}

                                            </span>
                                            </div>

                                            {!reaset_password_form.password.valid ? <div className="error field-error">{reaset_password_form.password.error_msg}</div> : ""}
                                        </div>

                                    </div>

                                    <div className="row">
                                        <div className="fv-row mb-5 col-sm-12">
                                            <label className="form-label fw-bolder text-dark">{reaset_password_form.confirm_password.label}</label>
                                            <div className="position-relative">
                                            <input
                                                type={this.state.hiddenpassword ? 'password' : 'text'}
                                                className="form-control"
                                                placeholder={reaset_password_form.confirm_password.placeholder}
                                                value={reaset_password_form.confirm_password.value ? reaset_password_form.confirm_password.value : ""}
                                                onChange={(event) => this.inputChangeHandler(event, "confirm_password")}
                                            />
                                            <span onClick={this.toggleShow} className="btn btn-icon position-absolute translate-middle top-50 end-0" data-kt-password-meter-control="visibility">

                                                {this.state.hiddenpassword ? <i className="bi bi-eye-slash fs-2"></i> : <i className="bi bi-eye fs-2"></i>}

                                            </span>
                                            </div>
                                            {!reaset_password_form.confirm_password.valid ? <div className="error field-error">{reaset_password_form.confirm_password.error_msg}</div> : ""}
                                        </div>

                                    </div>




                                    <div className="text-center">
                                        <button type="submit" className="btn btn-lg btn-primary w-100">
                                            <span className="indicator-label">Reset Password</span>
                                            <span className="indicator-progress">
                                                Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                        </button>
                                    </div>
                                    <div className="d-flex justify-content-center mt-2">
                                        <Link to="/" className="">
                                            Back to Login
                                        </Link>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        loader: state.Auth.loader,
        error: state.Auth.error,
    };
}
const mapDispatchToProps = {
    onResetPassword: userActions.ResetPassword,
};
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);