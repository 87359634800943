import { useState, useCallback, useEffect } from 'react';
import * as userActions from "../../actions/index";
import { useDispatch } from "react-redux";
// import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'
import { Calendar, dateFnsLocalizer } from 'react-big-calendar'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import enUS from 'date-fns/locale/en-US'
const locales = {
  'en-US': enUS,
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})
function CalendarCard() {
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date())
  const [allEvents, setallEvents] = useState([]);

  const ViewEventsHandler = (event) => {
   
};
const handleSelectSlot = ({ start, end, resourceId }) => {
  
}

  useEffect(() => {
    GetAllEventCalendarDetails();
  }, []);
  const GetAllEventCalendarDetails = useCallback(async () => {
    dispatch(userActions.GetAllEventCalendarDetails()).then((response) => {
        if (response.success === true) {
            const arrayData = response.data;
            let MemberId_level = [];

            for (let key in arrayData) {

                MemberId_level.push({

                    title: arrayData[key].event_calendar_info,
                    start: new Date(arrayData[key].event_start),
                    end: new Date(arrayData[key].event_end),
                    event_calendar_uuid: arrayData[key].event_calendar_uuid

                });
                setallEvents(MemberId_level);
            }
        }

    });
}, [dispatch]);
  return (
    <div className="app">
      <div className="dashboard-calendar-container bg-white" style={{ position: "relative" }}>
        {/* <Calendar onChange={setDate} value={date} /> */}
        <Calendar
          selectable
          localizer={localizer}
          events={allEvents}
          startAccessor="start"
          endAccessor="end"
          onSelectEvent={ViewEventsHandler}
          onSelectSlot={handleSelectSlot}
          style={{ height: 400 }}
        />

      </div>
      {/* <div className="text-center mt-5">
        Selected date: {date.toDateString()}
      </div> */}
    </div>
  )

}

export default CalendarCard;