import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import Sidebar from "../Constant/sidebar";
import Footer from "../Constant/Footer";
import TopBar from "../Constant/TopBar";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import * as userActions from "../../actions/index";
import Validations from "../Utility/Validations";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#e7f5fe" : "",
        },
        backgroundColor: state.isSelected ? "#4a8fb8" : "",
    }),
};


const permission_info = JSON.parse(localStorage.getItem("permissions"));
class PermissionAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            permission_add_form: {


                manage_class_add: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_class_edit: {
                    type: "checkbox",
                    label: "All",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_class_view: {
                    type: "checkbox",
                    label: "All",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                manage_class_delete: {
                    type: "checkbox",
                    label: "All",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                manage_class_activate_deactivate: {
                    type: "checkbox",
                    label: "All",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_students_add: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_students_edit: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_students_view: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_students_delete: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_students_activate_deactivate: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_reasons_add: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_reasons_edit: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_reasons_view: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_reasons_delete: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_host_add: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_host_edit: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                manage_host_view: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                manage_host_parents_approval: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                manage_host_activate_deactivate: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_travel_mode_add: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_travel_mode_edit: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_travel_mode_view: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_travel_mode_delete: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_travel_mode_activate_deactivate: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_leave_rejection_reason_add: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_leave_rejection_reason_edit: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_leave_rejection_reason_view: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_leave_rejection_reason_delete: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_leave_rejection_reason_activate_deactivate: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_weekend_leave_add: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_weekend_leave_edit: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_weekend_leave_view: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_adhoc_leave_add: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_adhoc_leave_edit: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_adhoc_leave_view: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_managers_add: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_managers_edit: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_managers_view: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_managers_delete: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_dormitory_add: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_dormitory_edit: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_dormitory_view: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_dormitory_delete: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_reports_view: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_web_permissions_add: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_web_permissions_edit: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_web_permissions_view: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_misc_message_edit: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_misc_message_view: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_school_profile_edit: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_school_profile_view: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_temp_id_create: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_diary_comment_add_Dorm: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_diary_comment_add_medical: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_diary_comment_add_pastoral: {
                    type: "checkbox",
                    label: "Add",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_diary_comment_view_Dorm: {
                    type: "checkbox",
                    label: "view",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_diary_comment_view_medical: {
                    type: "checkbox",
                    label: "view",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_diary_comment_view_pastoral: {
                    type: "checkbox",
                    label: "view",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                snapshot_require_input_pin_to_access_interface: {
                    type: "checkbox",
                    label: "view",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                snapshot_not_require_input_pin_to_access_interface: {
                    type: "checkbox",
                    label: "view",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                snapshot_allow_student_kiosk_mode: {
                    type: "checkbox",
                    label: "view",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                snapshot_no_student_kiosk_mode_staff_managed_only: {
                    type: "checkbox",
                    label: "view",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                snapshot_display_student_picture_on_snapshot_interface: {
                    type: "checkbox",
                    label: "view",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                snapshot_display_male_female_picture_on_snapshot_interface: {
                    type: "checkbox",
                    label: "view",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                app_permission_head_checks: {
                    type: "checkbox",
                    label: "view",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                dormitory_dikonia: {
                    type: "checkbox",
                    label: "view",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                dormitory_testr: {
                    type: "checkbox",
                    label: "view",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                dormitory_southzone: {
                    type: "checkbox",
                    label: "view",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                dormitory_beta: {
                    type: "checkbox",
                    label: "view",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                manage_gender: {
                    type: "radio",
                    label: "Gender",
                    value: "Both",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: true },
                },
                dorm_data: [],
                class_data: [],
            },
            select_manager: {
                type: "select",
                label: "Select manager *",
                value: "",
                valid: true,
                error_msg: "",
                onBlur_out: false,
                placeholder: "",
                validations: { required: true },
                options: [
                    // { label: "Select Manager", value: "" },
                    // { label: "Pratham", value: "Pratham" },
                    // { label: "Rohit", value: "Rohit" },
                    // { label: "Bhatia.", value: "Bhatia." },
                ],
            },
            dormitory_id: {
                type: "select",
                label: "Select Dormitory *",
                value: "",
                valid: true,
                error_msg: "",
                onBlur_out: false,
                placeholder: "",
                validations: { required: true },
                options: [],
            },
        
            class_id: {
                type: "select",
                label: "Select Class *",
                value: "",
                valid: true,
                error_msg: "",
                onBlur_out: false,
                placeholder: "",
                validations: { required: true },
                options: [],
            },
        };
    }



    componentDidMount() {
        this.GetManagerPermissonDetailsById();
        this.GetAllManagersBySchoolId();
        this.GetAllDormitoryStudent();
        this.GetAllClassesList();
        this.GetLoginUserPermissions();
        const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
      }
    
      GetAllManagersBySchoolId() {
        this.props.onGetAllManagersBySchoolId().then((response) => {
            const update_select_manager = this.state.select_manager ;
          if (response.success === true) {
            let manager_level = [];
    
                const arrayData = response.data.rows;
               
                for (let key in arrayData) {
                    // if (arrayData[key].dormitory_status === true) {
                        manager_level.push({
                            label: arrayData[key].first_name + " "  +arrayData[key].last_name,
                            value: arrayData[key].user_id,
                        });
                    // }
                }
            
                update_select_manager.options = manager_level;
            this.setState({ select_manager: update_select_manager });
          } else {
          }
        });
      }

      GetLoginUserPermissions() {
        this.props.onGetLoginUserPermissions().then((response) => {
          if (response.success === true) {
          } else {
          }
        });
      }
      

      GetManagerPermissonDetailsById() {
        this.props.onGetManagerPermissonDetailsById(308).then((response) => {
            if (response.success === true) {
                console.log("res", response);
                let permission_res = response.data;
        
                const update_permission_add_form = {...this.state.permission_add_form};
        
                console.log(
                  "update_permission_add_form -----------",
                  update_permission_add_form
                );
        
                console.log("permission_res -----------", permission_res);
        
                for (let key in update_permission_add_form) {
                  let form_element = update_permission_add_form[key];
                  
                  if (update_permission_add_form[key]) {
            //    console.log("CHECK")
                update_permission_add_form["manage_class_activate_deactivate"].value = permission_res.activate_deactivate_class;
                update_permission_add_form["manage_host_activate_deactivate"].value = permission_res.activate_deactivate_host;
                update_permission_add_form["manage_leave_rejection_reason_activate_deactivate"].value = permission_res.activate_deactivate_rejection_reason;
    
                // update_permission_add_form['manage_leave_rejection_reason_activate_deactivate'] = permission_res.activate_deactivate_user
    
                update_permission_add_form["manage_students_activate_deactivate"].value = permission_res.activate_deactivate_student;
                update_permission_add_form["manage_travel_mode_activate_deactivate"].value = permission_res.activate_deactivate_travel_mode;
    
                update_permission_add_form["manage_class_add"].value = permission_res.add_class;
                update_permission_add_form["manage_diary_comment_add_Dorm"].value = permission_res.add_dorm_comment;
                update_permission_add_form["manage_dormitory_add"].value = permission_res.add_dormitory;
                update_permission_add_form["manage_host_add"].value = permission_res.add_host;
                update_permission_add_form["manage_web_permissions_edit"].value = permission_res.edit_permission;
                update_permission_add_form['manage_web_permissions_add'] = permission_res.add_permission
                update_permission_add_form["manage_leave_rejection_reason_add"].value = permission_res.add_rejection_reason;
                update_permission_add_form["manage_students_add"].value = permission_res.add_student;
    
                update_permission_add_form["manage_travel_mode_add"].value = permission_res.add_travel_mode;
                update_permission_add_form["manage_managers_add"].value = permission_res.add_user;
                update_permission_add_form["manage_weekend_leave_add"].value = permission_res.add_weekend_leave;
                update_permission_add_form["manage_temp_id_create"].value = permission_res.create_tempid;
    
                // update_permission_add_form[''] = permission_res.created_date
                // update_permission_add_form[''] = permission_res.delete_class
                update_permission_add_form["manage_reasons_delete"].value = permission_res.delete_rejection_reason;
                update_permission_add_form["manage_students_delete"].value =permission_res.delete_student;
                update_permission_add_form["manage_travel_mode_delete"].value = permission_res.delete_travel_mode;
                update_permission_add_form["snapshot_display_male_female_picture_on_snapshot_interface"].value = permission_res.dis_male_female_shadow_snapshot;
                update_permission_add_form[ "snapshot_display_student_picture_on_snapshot_interface" ].value = permission_res.dis_stu_avatar_onsnapshot;
                update_permission_add_form["manage_adhoc_leave_edit"].value = permission_res.edit_adhoc_leave;
                update_permission_add_form["manage_class_edit"].value = permission_res.edit_class;
                update_permission_add_form["manage_dormitory_edit"].value = permission_res.edit_dormitory;
                update_permission_add_form["manage_host_edit"].value = permission_res.edit_host;
                // update_permission_add_form['manage_misc_message_edit'].value  = permission_res.edit_message
    
         
                update_permission_add_form["manage_leave_rejection_reason_edit"].value = permission_res.edit_rejection_reason;
                update_permission_add_form["manage_school_profile_edit"].value = permission_res.edit_school_profile;
                update_permission_add_form["manage_students_edit"].value = permission_res.edit_student;
                update_permission_add_form["manage_travel_mode_edit"].value = permission_res.edit_travel_mode;
                update_permission_add_form["manage_managers_edit"].value = permission_res.edit_user;
                update_permission_add_form["manage_weekend_leave_edit"].value = permission_res.edit_weekend_leave;
                update_permission_add_form["app_permission_head_checks"].value = permission_res.head_check;
                update_permission_add_form["manage_host_parents_approval"].value = permission_res.parents_approval_host;
                // update_permission_add_form[''] = permission_res.perm_class_dorm
                // update_permission_add_form[''] = permission_res.permission_detail_id
                // update_permission_add_form['snapshot_require_input_pin_to_access_interface'] = permission_res.require_stu_access_pin
                // update_permission_add_form[''] = permission_res.student_gender_all
                // update_permission_add_form[''] = permission_res.student_gender_female
                // update_permission_add_form[''] = permission_res.student_gender_male
                // update_permission_add_form[''] = permission_res.student_kiosk_mode
                // update_permission_add_form[''] = permission_res.user_id
    
                update_permission_add_form["manage_class_view"].value = permission_res.view_class;
                update_permission_add_form["manage_diary_comment_view_Dorm"].value = permission_res.view_dorm_comment;
                update_permission_add_form["manage_dormitory_view"].value = permission_res.view_dormitory;
                update_permission_add_form["manage_host_view"].value = permission_res.view_host;
                update_permission_add_form["manage_diary_comment_view_medical"].value = permission_res.view_medical_comment;
                update_permission_add_form["manage_diary_comment_add_medical"].value = permission_res.add_medical_comment;
                update_permission_add_form["manage_misc_message_view"].value = permission_res.view_message;
                update_permission_add_form["manage_diary_comment_view_pastoral"].value = permission_res.view_pastoral_comment;
    
                update_permission_add_form["manage_diary_comment_add_pastoral"].value = permission_res.add_pastoral_comment;
                update_permission_add_form["manage_web_permissions_view"].value = permission_res.view_permission;
                update_permission_add_form["manage_leave_rejection_reason_view"].value = permission_res.view_rejection_reason;
                update_permission_add_form["manage_reports_view"].value = permission_res.view_reports;
                update_permission_add_form["manage_school_profile_view"].value = permission_res.view_school_profile;
                // update_permission_add_form[''].value  = permission_res.view_snapshot
                update_permission_add_form["manage_students_view"].value = permission_res.view_student;
                update_permission_add_form["manage_travel_mode_view"].value = permission_res.view_travel_mode;
                update_permission_add_form["manage_managers_view"].value = permission_res.view_user;
                update_permission_add_form["manage_weekend_leave_view"].value = permission_res.view_weekend_leave;
    
                update_permission_add_form["manage_weekend_leave_view"].value = permission_res.view_adhoc_leave;
    
                update_permission_add_form[key] = form_element;
              }
            }
            this.setState({ permission_add_form: update_permission_add_form });
            console.log("update-----------", update_permission_add_form);
          } else {
          }
        });
      }
    

      GetAllDormitoryStudent = async (form_data) => {
        this.props.onGetAllDormitoryStudent().then((response) => {
            let country_level = [];
            // country_level.push({
            //     label: "Select Dormitory",
            //     value: "",
            // });
            const update_dormitory_id = this.state.dormitory_id ;
            if (response.success === true) {
    
                const arrayData = response.data.rows;
                console.log("111",arrayData);
                for (let key in arrayData) {
                    if (arrayData[key].dormitory_status === true) {
                        country_level.push({
                            label: arrayData[key].dormitory_name,
                            value: arrayData[key].dormitory_id,
                            // dormitory_id_name: arrayData[key].dormitory_id_name,
                        });
                    }
                }
            }
    
            update_dormitory_id.options = country_level;
            this.setState({ dormitory_id: update_dormitory_id });
        });
    }
    
    
    GetAllClassesList = async (form_data) => {
        this.props.onGetAllClassesList().then((response) => {
            let country_level = [];
            // country_level.push({
            //     label: "Select Class",
            //     value: "",
            // });
            const update_class_id = { ...this.state.class_id };
            if (response.success === true) {
    
                const arrayData = response.data.rows;
                // console.log(arrayData);
                for (let key in arrayData) {
                    country_level.push({
                        label: arrayData[key].class_name,
                        value: arrayData[key].class_id,
                        // class_name: arrayData[key].class_name,
                    });
                }
            }
            update_class_id.options = country_level;
            this.setState({ class_id: update_class_id });
        });
    }
    
    handleChangeDorm(data, identifier, type) {
        const update_permission_add_form = { ...this.state.permission_add_form };
        update_permission_add_form['dorm_data'] = data;
    
        this.setState({ permission_add_form: update_permission_add_form })
    }
    handleChangeClass(data, identifier, type) {
        const update_permission_add_form = { ...this.state.permission_add_form };
        update_permission_add_form['class_data'] = data;
        
        this.setState({ permission_add_form: update_permission_add_form })
    }





    handleChange(data, identifier, type) {
        const update_select_manager = this.state.select_manager;
        update_select_manager.value = data.value;
        console.log("EVENT VALUE CHECK", data.value)
        this.setState({ select_manager: update_select_manager });
    }

    ChangePermissions = async (permission_status) => {
        const update_permission_add_form = { ...this.state.permission_add_form };
        for (let identifier in update_permission_add_form) {
            let form_element = update_permission_add_form[identifier];

            if (form_element.type === "checkbox" && identifier !== "students_specific") {
                form_element.value = permission_status;
                update_permission_add_form[identifier] = form_element;
            }
        }
        this.setState({ permission_add_form: update_permission_add_form });
    };

    //      else {
    //         toast.error("Please Select Role", {
    //             position: toast.POSITION.TOP_RIGHT,
    //         });
    //     }
    // };

    //  selectAllCheckboxes = (event) => {

    //     const checkboxes = document.querySelectorAll('input[type=checkbox]');
    //     checkboxes.forEach((cb) => { cb.checked = true; });

    //   }

    //   deselectAllCheckboxes = (event) => {

    //     const checkboxes = document.querySelectorAll('input[type=checkbox]');
    //     checkboxes.forEach((cb) => { cb.checked = false; });
    //   }

    inputChangeHandler(event, identifier) {
        const update_permission_add_form = { ...this.state.permission_add_form };
        const form_element = { ...update_permission_add_form[identifier] };

        if (form_element.type === "checkbox") {
            console.log("=====", event.target.checked);
            form_element.value = event.target.checked;
        } else {
            form_element.value = event.target.value;
        }

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_permission_add_form[identifier] = form_element;

        this.setState({ permission_add_form: update_permission_add_form });
    }

    SubmitHandler = (event) => {
        event.preventDefault();
        console.log("CHECK SUBMIT FORM")
        let is_form_valid = true;
        const update_permission_add_form = this.state.permission_add_form;
    
        // for (let key in update_permission_add_form) {
        //     console.log("Check check")
        //   let form_element = update_permission_add_form[key];
        //   let validate_element = Validations(form_element);
    
        //   is_form_valid = validate_element.valid === false ? false : is_form_valid;
        //   form_element.onBlur_out = true;
        //   form_element.valid = validate_element.valid;
        //   form_element.error_msg = validate_element.error_msg;
    
        //   update_permission_add_form[key] = form_element;
        // }
    
    
    
        this.setState({ permission_add_form: update_permission_add_form });
    
        if (is_form_valid) {
          const form_data = {};
          for (let key in this.state.permission_add_form) {
            form_data[key] = this.state.permission_add_form[key].value;
          }
            let classes = this.state.permission_add_form.class_data;
            console.log("CLASSESS", classes)
            let update_class_data = classes.map((item) => {
              return ({
                class_name: item.label, 
                class_id: item.value, 
               } )
            })
            form_data['class_data'] = JSON.stringify(update_class_data);
    
            let dorms = this.state.permission_add_form.dorm_data;
            let update_dormitory_data = dorms.map((item) => {
              return ({
                class_name: item.label, 
                class_id: item.value, 
               } )
            })
            form_data['dorm_data'] = JSON.stringify(update_dormitory_data);
    
          
          console.log("form_data===", form_data);
        // }
    
        // if (is_form_valid) {
        //     const form_data = new FormData();
        //     for (let key in this.state.permission_add_form) {
        //         if (this.state.permission_add_form[key].type === "checkbox") {
        //             form_data.append(key, this.state.permission_add_form[key].value ? this.state.permission_add_form[key].value : false);
        //         } else {
        //             form_data.append(key, this.state.permission_add_form[key].value ? this.state.permission_add_form[key].value : "");
        //         }
        //     }

        // "Permissions of specific Role created successfully!"
    
            this.props.onUpdateManagerPermissions(form_data).then(async (response) => {
                if (response.success === true) {
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            });
        }
      };
    

    render() {
        const { permission_add_form } = this.state;

        console.log("select_manager", this.state.select_manager)
        console.log("permission_add_form===", permission_add_form);
        return (
            <>
                <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
                    <div className="d-flex flex-column flex-root">
                        <div className="page d-flex flex-row flex-column-fluid">
                            <Sidebar />

                            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                                <TopBar />

                                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                    <div className="post d-flex flex-column-fluid h-100" id="kt_post">
                                        <div id="kt_content_container" className="container-fluid">
                                            <div className="row gy-5 g-xl-8">
                                                <div className="col-xl-12">
                                                    <div className="top-bar mt-10">
                                                        <h4 className="mb-4">Permissions</h4>
                                                    </div>

                                                    <div className="card">
                                                        <form onSubmit={this.SubmitHandler}>
                                                            <div className="card-body">
                                                            <div className="col-lg-4 fv-row mb-10">
                                                                        <label className="fw-bold mb-2">{this.state.select_manager.label}</label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={this.state.select_manager.options}
                                                                            // value={this.state.select_manager.options.filter(function (option) {
                                                                            //     return option.value === this.state.select_manager.value;
                                                                            // })}

                                                                            onChange={(newValue) => this.handleChange(newValue, "select_manager")}
                                                                        />

                                                                        {!this.state.select_manager.valid && this.state.select_manager.onBlur_out ? <div className="error field-error">{this.state.select_manager.error_msg}</div> : ""}
                                                                    </div>

                                                                    
                                                                    <div className="col-sm-12">
                                                                        <div className="permission-wrap mb-4">
                                                                           <div className="d-flex permission-title">
                                                                            <div className="title-left">
                                                                                <h6 className="mb-0">Web Permission</h6>
                                                                            </div>
                                                                            <div className="select-btn-out">
                                                                                Select :{" "}
                                                                                <button type="button" className="m-btn btn btn-secondary btn-sm" onClick={(event) => this.ChangePermissions(true)}>
                                                                                    All
                                                                                </button>
                                                                                |
                                                                                <button type="button" className="m-btn btn btn-secondary btn-sm" onClick={(event) => this.ChangePermissions(false)}>
                                                                                    None
                                                                                </button>
                                                                            </div>
                                                                        </div>

                                                                        <div className="mt-2">
                                                                            <div className="sec-row">
                                                                                <div className="row">
                                                                                    <div className="col-md-2">
                                                                                       <div className="ps-3"> <b>Manage Class</b></div>
                                                                                    </div>
                                                                                    <div className="col-md-10">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4 col-md-3">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_class_add.value}
                                                                                                            checked={permission_add_form.manage_class_add.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_class_add")}
                                                                                                        />
                                                                                                        <span className="mb-0">Add Class</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-sm-4 col-md-3">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_class_edit.value}
                                                                                                            checked={permission_add_form.manage_class_edit.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_class_edit")}
                                                                                                        />
                                                                                                        <span className="mb-0">Edit Class</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-sm-4 col-md-3">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_class_view.value}
                                                                                                            checked={permission_add_form.manage_class_view.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_class_view")}
                                                                                                        />
                                                                                                        <span className="mb-0">View Class</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>

                                                                                            {/* <div className="col-sm-4 mt-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_class_delete.value}
                                                                                                            checked={permission_add_form.manage_class_delete.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_class_delete")}
                                                                                                        />
                                                                                                        <span className="mb-0">Delete Class</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div> */}

                                                                                            <div className="col-sm-4 col-md-3">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_class_activate_deactivate.value}
                                                                                                            checked={permission_add_form.manage_class_activate_deactivate.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_class_activate_deactivate")}
                                                                                                        />
                                                                                                        <span className="mb-0">Activate/Deactivate Class</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            
                                                                            <div className="sec-row">
                                                                                <div className="row">
                                                                                    <div className="col-md-2">
                                                                         
                                                                                        <div className="ps-3"><b>Manage Dormitory</b></div>
                                                                                    </div>
                                                                                    <div className="col-md-10">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4 col-md-3">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_dormitory_add.value}
                                                                                                            checked={permission_add_form.manage_dormitory_add.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_dormitory_add")}
                                                                                                        />
                                                                                                        <span className="mb-0">Add Dormitory</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-sm-4 col-md-3">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_dormitory_edit.value}
                                                                                                            checked={permission_add_form.manage_dormitory_edit.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_dormitory_edit")}
                                                                                                        />
                                                                                                        <span className="mb-0">Edit Dormitory</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-sm-4 col-md-3">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_dormitory_view.value}
                                                                                                            checked={permission_add_form.manage_dormitory_view.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_dormitory_view")}
                                                                                                        />
                                                                                                        <span className="mb-0">View Dormitory</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>

                                                                                            {/* <div className="col-sm-4 mt-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_dormitory_delete.value}
                                                                                                            checked={permission_add_form.manage_dormitory_delete.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_dormitory_delete")}
                                                                                                        />
                                                                                                        <span className="mb-0">Delete Dormitory</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div> */}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="sec-row">
                                                                                <div className="row">
                                                                                    <div className="col-md-2">
                                                                                       
                                                                                        
                                                                                        <div className="ps-3"><b>Manage Web Permissions</b></div>
                                                                                    </div>
                                                                                    <div className="col-md-10">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4 col-md-3">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_web_permissions_add.value}
                                                                                                            checked={permission_add_form.manage_web_permissions_add.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_web_permissions_add")}
                                                                                                        />
                                                                                                        <span className="mb-0">Add Permission</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-sm-4 col-md-3">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_web_permissions_edit.value}
                                                                                                            checked={permission_add_form.manage_web_permissions_edit.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_web_permissions_edit")}
                                                                                                        />
                                                                                                        <span className="mb-0">Edit Permission</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-sm-4 col-md-3">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_web_permissions_view.value}
                                                                                                            checked={permission_add_form.manage_web_permissions_view.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_web_permissions_view")}
                                                                                                        />
                                                                                                        <span className="mb-0">View Permission</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className=" sec-row border-bottom-0">
                                                                                <div className="row">
                                                                                    <div className="col-md-2">
                                                                                 
                                                                                        <div className="ps-3"><b>Snapshot</b></div>
                                                                                    </div>
                                                                                    <div className="col-md-10">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-6">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.snapshot_require_input_pin_to_access_interface.value}
                                                                                                            checked={permission_add_form.snapshot_require_input_pin_to_access_interface.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "snapshot_require_input_pin_to_access_interface")}
                                                                                                        />
                                                                                                        <span className="mb-0">Require student to input 4 digit PIN to access interface</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-sm-6">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.snapshot_not_require_input_pin_to_access_interface.value}
                                                                                                            checked={permission_add_form.snapshot_not_require_input_pin_to_access_interface.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "snapshot_not_require_input_pin_to_access_interface")}
                                                                                                        />
                                                                                                        <span className="mb-0">Do not Require student to input PIN to access interface</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-sm-6 mt-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.snapshot_allow_student_kiosk_mode.value}
                                                                                                            checked={permission_add_form.snapshot_allow_student_kiosk_mode.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "snapshot_allow_student_kiosk_mode")}
                                                                                                        />
                                                                                                        <span className="mb-0">Allow student Kiosk Mode</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-sm-6 mt-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.snapshot_no_student_kiosk_mode_staff_managed_only.value}
                                                                                                            checked={permission_add_form.snapshot_no_student_kiosk_mode_staff_managed_only.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "snapshot_no_student_kiosk_mode_staff_managed_only")}
                                                                                                        />
                                                                                                        <span className="mb-0">No student Kiosk Mode - Staff Managed only</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-sm-6 mt-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.snapshot_display_student_picture_on_snapshot_interface.value}
                                                                                                            checked={permission_add_form.snapshot_display_student_picture_on_snapshot_interface.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "snapshot_display_student_picture_on_snapshot_interface")}
                                                                                                        />
                                                                                                        <span className="mb-0">Display students pictures on snapshot interface</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-sm-6 mt-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.snapshot_display_male_female_picture_on_snapshot_interface.value}
                                                                                                            checked={permission_add_form.snapshot_display_male_female_picture_on_snapshot_interface.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "snapshot_display_male_female_picture_on_snapshot_interface")}
                                                                                                        />
                                                                                                        <span className="mb-0">Display Male/Female shadow on snapshot interface</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-sm-6 mt-4">
                                                                                            <div className="checkbox-inline">
                                                                                            <span className="mb-0">Select Dormitory</span>
                                                                                            <label className="checkbox cursor-pointer ">
                                                                                            <Select
                                                                                            //    styles={customStyles}
                                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                                            options={this.state.dormitory_id.options}
                                                                                            value={this.state.dormitory}
                                                                                            onChange={(newValue) => this.handleChangeDorm(newValue, "dormitory_id", "weekend_day from value")}
                                                                                            isMulti
                                                                                            />
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>

                                              <div className="col-sm-6 mt-4">
                                                <div className="checkbox-inline">
                                                <span className=" ">Select Class</span>
                                                  <label className="checkbox cursor-pointer ">
                                                  <Select
                                                //    styles={customStyles}
                                                    className="react-bootstrap-typeahead tag-outer"
                                                    options={this.state.class_id.options}
                                                    value={this.state.class}
                                                     onChange={(newValue) => this.handleChangeClass(newValue, "class_id", "weekend_day from value")}
                                                     isMulti
                                                    />
                                                  </label>
                                                </div>
                                              </div>

                                                                                            
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                    </div>
                                                                    </div>

                                                                    <div className="permission-wrap mb-4">
                                                                           <div className="d-flex permission-title">
                                                                                <div className="title-left">
                                                                                    <h6 className="mb-0">Students</h6>
                                                                                </div>
                                                                            </div>
                                                                        <div className=" sec-row">
                                                                                <div className="row">
                                                                                    <div className="col-md-2"><div className="ps-3"><b>Student list</b></div></div>
                                                                                    <div className="col-md-10">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4 col-md-3">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value=""
                                                                                                            checked=""
                                                                                                            
                                                                                                        />
                                                                                                        <span className="mb-0">View</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                      
                                                                                            <div className="col-sm-4 col-md-3">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value=""
                                                                                                            checked=""
                                                                                                            
                                                                                                        />
                                                                                                        <span className="mb-0">Archive</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-sm-4 col-md-3">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value=""
                                                                                                            checked=""
                                                                                                            
                                                                                                        />
                                                                                                        <span className="mb-0">Edit</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-sm-4 col-md-3">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value=""
                                                                                                            checked=""
                                                                                                            
                                                                                                        />
                                                                                                        <span className="mb-0">Temp ID</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                             <div className=" sec-row">
                                                                                <div className="row">
                                                                                    <div className="col-md-2"><div className="ps-3"><b>Add Student</b></div></div>
                                                                                    <div className="col-md-10">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4 col-md-3">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value=""
                                                                                                            checked=""
                                                                                                            
                                                                                                        />
                                                                                                        <span className="mb-0">Add Student</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                      
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className=" sec-row">
                                                                                <div className="row">
                                                                                    <div className="col-md-2"><div className="ps-3"><b>Allergic Students</b></div></div>
                                                                                    <div className="col-md-10">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4 col-md-3">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value=""
                                                                                                            checked=""
                                                                                                            
                                                                                                        />
                                                                                                        <span className="mb-0">View</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                      
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className=" sec-row">
                                                                                <div className="row">
                                                                                    <div className="col-md-2"><div className="ps-3"><b>Student Reminder</b></div></div>
                                                                                    <div className="col-md-10">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4 col-md-3">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value=""
                                                                                                            checked=""
                                                                                                            
                                                                                                        />
                                                                                                        <span className="mb-0">Edit</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-sm-4 col-md-3">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value=""
                                                                                                            checked=""
                                                                                                            
                                                                                                        />
                                                                                                        <span className="mb-0">Delete</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-sm-4 col-md-3">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value=""
                                                                                                            checked=""
                                                                                                            
                                                                                                        />
                                                                                                        <span className="mb-0">Deactivate</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-sm-4 col-md-3">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value=""
                                                                                                            checked=""
                                                                                                            
                                                                                                        />
                                                                                                        <span className="mb-0">Add</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className=" sec-row">
                                                                                <div className="row">
                                                                                    <div className="col-md-2"><div className="ps-3"><b>Grounded Student</b></div></div>
                                                                                    <div className="col-md-10">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4 col-md-3">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value=""
                                                                                                            checked=""
                                                                                                        />
                                                                                                        <span className="mb-0">Edit</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-sm-4 col-md-3">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value=""
                                                                                                            checked=""
                                                                                                            
                                                                                                        />
                                                                                                        <span className="mb-0">Delete</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-sm-4 col-md-3">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value=""
                                                                                                            checked=""
                                                                                                            
                                                                                                        />
                                                                                                        <span className="mb-0">Deactivate</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-sm-4 col-md-3">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value=""
                                                                                                            checked=""
                                                                                                            
                                                                                                        />
                                                                                                        <span className="mb-0">Add</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className=" sec-row">
                                                                                <div className="row">
                                                                                    <div className="col-md-2"><div className="ps-3"><b>Import Students</b></div></div>
                                                                                    <div className="col-md-10">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4 col-md-3">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value=""
                                                                                                            checked=""
                                                                                                            
                                                                                                        />
                                                                                                        <span className="mb-0">Access</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className=" sec-row">
                                                                                <div className="row">
                                                                                    <div className="col-md-2"><div className="ps-3"><b>Duplicate Students</b></div></div>
                                                                                    <div className="col-md-10">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4 col-md-3">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value=""
                                                                                                            checked=""
                                                                                                            
                                                                                                        />
                                                                                                        <span clps-3assName="mb-0">Access</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                           <div className=" sec-row border-bottom-0">
                                                                                <div className="row">
                                                                                    <div className="col-md-2"><div className="ps-3"><b>Change Dormitory</b></div></div>
                                                                                    <div className="col-md-10">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4 col-md-3">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value=""
                                                                                                            checked=""
                                                                                                            
                                                                                                        />
                                                                                                        <span className="mb-0">Access</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="permission-wrap mb-4">
                                                                           <div className="d-flex permission-title">
                                                                                <div className="title-left">
                                                                                    <h6 className="mb-0">Setting Tab</h6>
                                                                                </div>
                                                                            </div>
                                                                            <div className=" sec-row">
                                                                                <div className="row">
                                                                                    <div className="col-md-2"><div className="ps-3"><b>Attendance Reports</b></div></div>
                                                                                    <div className="col-md-10">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4 col-md-3">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value=""
                                                                                                            checked=""
                                                                                                            
                                                                                                        />
                                                                                                        <span className="mb-0">View</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className=" sec-row">
                                                                                <div className="row">
                                                                                    <div className="col-md-2"><div className="ps-3"><b>Messages</b></div></div>
                                                                                    <div className="col-md-10">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4 col-md-3">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value=""
                                                                                                            checked=""
                                                                                                            
                                                                                                        />
                                                                                                        <span className="mb-0">View</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className=" sec-row">
                                                                                <div className="row">
                                                                                    <div className="col-md-2"><div className="ps-3"><b>Custom Links</b></div></div>
                                                                                    <div className="col-md-10">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4 col-md-3">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value=""
                                                                                                            checked=""
                                                                                                            
                                                                                                        />
                                                                                                        <span className="mb-0">Set</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className=" sec-row">
                                                                                <div className="row">
                                                                                    <div className="col-md-2"><div className="ps-3"><b>Student Rollover</b></div></div>
                                                                                    <div className="col-md-10">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4 col-md-3">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value=""
                                                                                                            checked=""
                                                                                                            
                                                                                                        />
                                                                                                        <span className="mb-0">Access</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className=" sec-row">
                                                                                <div className="row">
                                                                                    <div className="col-md-2"><div className="ps-3"><b>Roll Call Session Time*</b></div></div>
                                                                                    <div className="col-md-10">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4 col-md-3">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value=""
                                                                                                            checked=""
                                                                                                            
                                                                                                        />
                                                                                                        <span className="mb-0">Set</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className=" sec-row">
                                                                                <div className="row">
                                                                                    <div className="col-md-2"><div className="ps-3"><b>On Campus Details</b></div></div>
                                                                                    <div className="col-md-10">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4 col-md-3">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value=""
                                                                                                            checked=""
                                                                                                            
                                                                                                        />
                                                                                                        <span className="mb-0">Set</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className=" sec-row border-bottom-0">
                                                                                <div className="row">
                                                                                    <div className="col-md-2"><div className="ps-3"><b>Calendar</b></div></div>
                                                                                    <div className="col-md-10">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4 col-md-3">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value=""
                                                                                                            checked=""
                                                                                                            
                                                                                                        />
                                                                                                        <span className="mb-0">View</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                            <div className="col-md-12 sec-row">
                                                                                <div className="row">
                                                                                    <div className="col-md-2 text-end">
                                                                                        <b>Manage Students</b>
                                                                                    </div>
                                                                                    <div className="col-md-10">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_students_add.value}
                                                                                                            checked={permission_add_form.manage_students_add.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_students_add")}
                                                                                                        />
                                                                                                        <span className="mb-0">Add Student</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-sm-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_students_edit.value}
                                                                                                            checked={permission_add_form.manage_students_edit.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_students_edit")}
                                                                                                        />
                                                                                                        <span className="mb-0">Edit Student</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-sm-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_students_view.value}
                                                                                                            checked={permission_add_form.manage_students_view.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_students_view")}
                                                                                                        />
                                                                                                        <span className="mb-0">View Student</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-sm-4 mt-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_students_delete.value}
                                                                                                            checked={permission_add_form.manage_students_delete.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_students_delete")}
                                                                                                        />
                                                                                                        <span className="mb-0">Delete Student</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-sm-4 mt-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_students_activate_deactivate.value}
                                                                                                            checked={permission_add_form.manage_students_activate_deactivate.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_students_activate_deactivate")}
                                                                                                        />
                                                                                                        <span className="mb-0">Activate/Deactivate Student</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-12 sec-row">
                                                                                <div className="row">
                                                                                    <div className="col-md-2 text-end">
                                                                                       
                                                                                        {/* <div className="col-md-2"> */}
                                                                                        <b>Manage Reasons</b>
                                                                                    {/* </div> */}
                                                                                    </div>
                                                                                    <div className="col-md-10">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_reasons_add.value}
                                                                                                            checked={permission_add_form.manage_reasons_add.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_reasons_add")}
                                                                                                        />
                                                                                                        <span className="mb-0">Add Reasons</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-sm-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_reasons_edit.value}
                                                                                                            checked={permission_add_form.manage_reasons_edit.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_reasons_edit")}
                                                                                                        />
                                                                                                        <span className="mb-0">Edit Reasons</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-sm-4 mt-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_reasons_view.value}
                                                                                                            checked={permission_add_form.manage_reasons_view.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_reasons_view")}
                                                                                                        />
                                                                                                        <span className="mb-0">View Reasons</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-sm-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_reasons_delete.value}
                                                                                                            checked={permission_add_form.manage_reasons_delete.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_reasons_delete")}
                                                                                                        />
                                                                                                        <span className="mb-0">Delete Reasons</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-12 sec-row">
                                                                                <div className="row">
                                                                                    <div className="col-md-2 text-end">
                                                                                        <b>Manage Host</b>
                                                                                    </div>
                                                                                    <div className="col-md-10">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_host_add.value}
                                                                                                            checked={permission_add_form.manage_host_add.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_host_add")}
                                                                                                        />
                                                                                                        <span className="mb-0">Add Host</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-sm-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_host_edit.value}
                                                                                                            checked={permission_add_form.manage_host_edit.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_host_edit")}
                                                                                                        />
                                                                                                        <span className="mb-0">Edit Host</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-sm-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_host_view.value}
                                                                                                            checked={permission_add_form.manage_host_view.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_host_view")}
                                                                                                        />
                                                                                                        <span className="mb-0">View Host</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-sm-4 mt-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_host_parents_approval.value}
                                                                                                            checked={permission_add_form.manage_host_parents_approval.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_host_parents_approval")}
                                                                                                        />
                                                                                                        <span className="mb-0">Parents Approval</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-sm-4 mt-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_host_activate_deactivate.value}
                                                                                                            checked={permission_add_form.manage_host_activate_deactivate.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_host_activate_deactivate")}
                                                                                                        />
                                                                                                        <span className="mb-0">Activate/Deactivate Host</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-12 sec-row">
                                                                                <div className="row">
                                                                                    <div className="col-md-2 text-end">
                                                                                        <b>Manage Travel Mode</b>
                                                                                    </div>
                                                                                    <div className="col-md-10">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_travel_mode_add.value}
                                                                                                            checked={permission_add_form.manage_travel_mode_add.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_travel_mode_add")}
                                                                                                        />
                                                                                                        <span className="mb-0">Add Travel Mode</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-sm-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_travel_mode_edit.value}
                                                                                                            checked={permission_add_form.manage_travel_mode_edit.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_travel_mode_edit")}
                                                                                                        />
                                                                                                        <span className="mb-0">Edit Travel Mode</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-sm-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_travel_mode_view.value}
                                                                                                            checked={permission_add_form.manage_travel_mode_view.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_travel_mode_view")}
                                                                                                        />
                                                                                                        <span className="mb-0">View Travel Mode</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-sm-4 mt-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_travel_mode_delete.value}
                                                                                                            checked={permission_add_form.manage_travel_mode_delete.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_travel_mode_delete")}
                                                                                                        />
                                                                                                        <span className="mb-0">Delete Travel Mode</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-sm-4 mt-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_travel_mode_activate_deactivate.value}
                                                                                                            checked={permission_add_form.manage_travel_mode_activate_deactivate.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_travel_mode_activate_deactivate")}
                                                                                                        />
                                                                                                        <span className="mb-0">Activate/Deactivate Mode</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-12 sec-row">
                                                                                <div className="row">
                                                                                    <div className="col-md-2 text-end">
                                                                                        <b>Manage Leave Rejection Reason</b>
                                                                                    </div>
                                                                                    <div className="col-md-10">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_leave_rejection_reason_add.value}
                                                                                                            checked={permission_add_form.manage_leave_rejection_reason_add.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_leave_rejection_reason_add")}
                                                                                                        />
                                                                                                        <span className="mb-0">Add Reason</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-sm-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_leave_rejection_reason_edit.value}
                                                                                                            checked={permission_add_form.manage_leave_rejection_reason_edit.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_leave_rejection_reason_edit")}
                                                                                                        />
                                                                                                        <span className="mb-0">Edit Reason</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-sm-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_leave_rejection_reason_view.value}
                                                                                                            checked={permission_add_form.manage_leave_rejection_reason_view.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_leave_rejection_reason_view")}
                                                                                                        />
                                                                                                        <span className="mb-0">View Reason</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-sm-4 mt-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_leave_rejection_reason_delete.value}
                                                                                                            checked={permission_add_form.manage_leave_rejection_reason_delete.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_leave_rejection_reason_delete")}
                                                                                                        />
                                                                                                        <span className="mb-0">Delete Reason</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-sm-4 mt-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_leave_rejection_reason_activate_deactivate.value}
                                                                                                            checked={permission_add_form.manage_leave_rejection_reason_activate_deactivate.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_leave_rejection_reason_activate_deactivate")}
                                                                                                        />
                                                                                                        <span className="mb-0">Activate/Deactivate Reason</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-12 sec-row">
                                                                                <div className="row">
                                                                                    <div className="col-md-2 text-end">
                                                                                        <b>Manage Weekend Leave</b>
                                                                                    </div>
                                                                                    <div className="col-md-10">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_weekend_leave_add.value}
                                                                                                            checked={permission_add_form.manage_weekend_leave_add.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_weekend_leave_add")}
                                                                                                        />
                                                                                                        <span className="mb-0">Add Weekend Leave</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-sm-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_weekend_leave_edit.value}
                                                                                                            checked={permission_add_form.manage_weekend_leave_edit.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_weekend_leave_edit")}
                                                                                                        />
                                                                                                        <span className="mb-0">Edit Weekend Leave</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-sm-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_weekend_leave_view.value}
                                                                                                            checked={permission_add_form.manage_weekend_leave_view.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_weekend_leave_view")}
                                                                                                        />
                                                                                                        <span className="mb-0">View Weekend Leave</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-12 sec-row">
                                                                                <div className="row">
                                                                                    <div className="col-md-2 text-end">
                                                                                        <b>Manage Adhoc Leave</b>
                                                                                    </div>
                                                                                    <div className="col-md-10">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_adhoc_leave_add.value}
                                                                                                            checked={permission_add_form.manage_adhoc_leave_add.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_adhoc_leave_add")}
                                                                                                        />
                                                                                                        <span className="mb-0">Add Adhoc Leave</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-sm-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_adhoc_leave_edit.value}
                                                                                                            checked={permission_add_form.manage_adhoc_leave_edit.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_adhoc_leave_edit")}
                                                                                                        />
                                                                                                        <span className="mb-0">Edit Adhoc Leave</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-sm-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_weekend_leave_view.value}
                                                                                                            checked={permission_add_form.manage_weekend_leave_view.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_weekend_leave_view")}
                                                                                                        />
                                                                                                        <span className="mb-0">View Adhoc Leave</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-12 sec-row">
                                                                                <div className="row">
                                                                                    <div className="col-md-2 text-end">
                                                                                        <b>Manage Managers</b>
                                                                                    </div>
                                                                                    <div className="col-md-10">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_managers_add.value}
                                                                                                            checked={permission_add_form.manage_managers_add.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_managers_add")}
                                                                                                        />
                                                                                                        <span className="mb-0">Add Manager</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-sm-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_managers_edit.value}
                                                                                                            checked={permission_add_form.manage_managers_edit.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_managers_edit")}
                                                                                                        />
                                                                                                        <span className="mb-0">Edit Manager</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-sm-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_managers_view.value}
                                                                                                            checked={permission_add_form.manage_managers_view.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_managers_view")}
                                                                                                        />
                                                                                                        <span className="mb-0">View Managers</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-sm-4 mt-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_managers_delete.value}
                                                                                                            checked={permission_add_form.manage_managers_delete.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_managers_delete")}
                                                                                                        />
                                                                                                        <span className="mb-0">Delete Manager</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                           

                                                                            <div className="col-md-12 sec-row">
                                                                                <div className="row">
                                                                                    <div className="col-md-2 text-end">
                                                                                        <b>Manage Reports</b>
                                                                                    </div>
                                                                                    <div className="col-md-10">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_reports_view.value}
                                                                                                            checked={permission_add_form.manage_reports_view.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_reports_view")}
                                                                                                        />
                                                                                                        <span className="mb-0">View Reports</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                           

                                                                            <div className="col-md-12 sec-row">
                                                                                <div className="row">
                                                                                    <div className="col-md-2 text-end">
                                                                                        <b>Manage Misc Message</b>
                                                                                    </div>
                                                                                    <div className="col-md-10">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_misc_message_edit.value}
                                                                                                            checked={permission_add_form.manage_misc_message_edit.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_misc_message_edit")}
                                                                                                        />
                                                                                                        <span className="mb-0">Edit Message</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-sm-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_misc_message_view.value}
                                                                                                            checked={permission_add_form.manage_misc_message_view.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_misc_message_view")}
                                                                                                        />
                                                                                                        <span className="mb-0">View Message</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-12 sec-row">
                                                                                <div className="row">
                                                                                    <div className="col-md-2 text-end">
                                                                                        <b>Manage School Profile</b>
                                                                                    </div>
                                                                                    <div className="col-md-10">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_school_profile_edit.value}
                                                                                                            checked={permission_add_form.manage_school_profile_edit.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_school_profile_edit")}
                                                                                                        />
                                                                                                        <span className="mb-0">Edit School Profile</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-sm-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_school_profile_view.value}
                                                                                                            checked={permission_add_form.manage_school_profile_view.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_school_profile_view")}
                                                                                                        />
                                                                                                        <span className="mb-0">View School Profile</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-12 sec-row">
                                                                                <div className="row">
                                                                                    <div className="col-md-2 text-end">
                                                                                        <b>Manage Temp Id</b>
                                                                                    </div>
                                                                                    <div className="col-md-10">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_temp_id_create.value}
                                                                                                            checked={permission_add_form.manage_temp_id_create.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_temp_id_create")}
                                                                                                        />
                                                                                                        <span className="mb-0">Create Temp Id</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-12 sec-row">
                                                                                <div className="row">
                                                                                    <div className="col-md-2 text-end">
                                                                                        <b>Manage Diary Comment</b>
                                                                                    </div>
                                                                                    <div className="col-md-10">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_diary_comment_view_Dorm.value}
                                                                                                            checked={permission_add_form.manage_diary_comment_view_Dorm.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_diary_comment_view_Dorm")}
                                                                                                        />
                                                                                                        <span className="mb-0">View Dorm Comment</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-sm-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_diary_comment_add_Dorm.value}
                                                                                                            checked={permission_add_form.manage_diary_comment_add_Dorm.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_diary_comment_add_Dorm")}
                                                                                                        />
                                                                                                        <span className="mb-0">Add Dorm Comment</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-sm-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_diary_comment_view_pastoral.value}
                                                                                                            checked={permission_add_form.manage_diary_comment_view_pastoral.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_diary_comment_view_pastoral")}
                                                                                                        />
                                                                                                        <span className="mb-0">View Pastoral Comment</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-sm-4 mt-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_diary_comment_view_medical.value}
                                                                                                            checked={permission_add_form.manage_diary_comment_view_medical.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_diary_comment_view_medical")}
                                                                                                        />
                                                                                                        <span className="mb-0">View Medical Comment</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-sm-4 mt-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_diary_comment_add_medical.value}
                                                                                                            checked={permission_add_form.manage_diary_comment_add_medical.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_diary_comment_add_medical")}
                                                                                                        />
                                                                                                        <span className="mb-0">Add Medical Comment</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-sm-4 mt-4">
                                                                                                <div className="checkbox-inline">
                                                                                                    <label className="checkbox cursor-pointer ">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input me-1"
                                                                                                            value={permission_add_form.manage_diary_comment_add_pastoral.value}
                                                                                                            checked={permission_add_form.manage_diary_comment_add_pastoral.value}
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_diary_comment_add_pastoral")}
                                                                                                        />
                                                                                                        <span className="mb-0">Add Pastoral Comment</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-12">
                                                                        <div className="permission-wrap my-6">
                                                                            <div className="d-flex permission-title">
                                                                                <div className="title-left">
                                                                                    <h6 className="mb-0">App Permission</h6>
                                                                                </div>
                                                                                {/* <div className="select-btn-out">
                                                                Select : <button type="button" className="m-btn btn btn-secondary btn-sm">All</button>|<button type="button" className="m-btn btn btn-secondary btn-sm">None</button>
                                                            </div> */}
                                                                            </div>

                                                                            <div className="mt-2">
                                                                                <div className="col-md-12 sec-row">
                                                                                    <div className="row">
                                                                                        <div className="col-md-2 text-end">
                                                                                            <b>App Permission</b>
                                                                                        </div>
                                                                                        <div className="col-md-10">
                                                                                            <div className="row">
                                                                                                <div className="col-sm-4">
                                                                                                    <div className="checkbox-inline">
                                                                                                        <label className="checkbox cursor-pointer ">
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                className="form-check-input me-1"
                                                                                                                value={permission_add_form.app_permission_head_checks.value}
                                                                                                                checked={permission_add_form.app_permission_head_checks.value}
                                                                                                                onChange={(event) => this.inputChangeHandler(event, "app_permission_head_checks")}
                                                                                                            />
                                                                                                            <span className="mb-0">Head Checks</span>
                                                                                                        </label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-md-12 sec-row manage-gender">
                                                                                    <div className="row">
                                                                                        <div className="col-md-2 text-end">
                                                                                            <b>Manage Gender</b>
                                                                                        </div>
                                                                                        <div className="col-md-10">
                                                                                            <div className="row">
                                                                                                <div className="col-sm-4">
                                                                                                    <label className="cursor-pointer">
                                                                                                        <input
                                                                                                            type="radio"
                                                                                                            id="gender"
                                                                                                            name="gen"
                                                                                                            className="form-check-input me-2 cursor-pointer"
                                                                                                            value="Male"
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_gender")}
                                                                                                        />
                                                                                                        Male
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="col-sm-4">
                                                                                                    <label className="cursor-pointer">
                                                                                                        <input
                                                                                                            type="radio"
                                                                                                            id="gender1"
                                                                                                            name="gen"
                                                                                                            className="form-check-input me-2 cursor-pointer"
                                                                                                            value="Female"
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_gender")}
                                                                                                        />
                                                                                                        Female
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="col-sm-4">
                                                                                                    <label className="cursor-pointer">
                                                                                                        <input
                                                                                                            type="radio"
                                                                                                            id="gender2"
                                                                                                            name="gen"
                                                                                                            className="form-check-input me-2 cursor-pointer"
                                                                                                            value="All"
                                                                                                            onChange={(event) => this.inputChangeHandler(event, "manage_gender")}
                                                                                                        />
                                                                                                        Both
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-md-12 sec-row">
                                                                                    <div className="row">
                                                                                        <div className="col-md-2 text-end">
                                                                                            <b>Dormitory</b>
                                                                                        </div>
                                                                                        <div className="col-md-10">
                                                                                            <div className="row">
                                                                                                <div className="col-sm-4">
                                                                                                    <div className="checkbox-inline">
                                                                                                        <label className="checkbox cursor-pointer ">
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                className="form-check-input me-1"
                                                                                                                value={permission_add_form.dormitory_dikonia.value}
                                                                                                                checked={permission_add_form.dormitory_dikonia.value}
                                                                                                                onChange={(event) => this.inputChangeHandler(event, "dormitory_dikonia")}
                                                                                                            />
                                                                                                            <span className="mb-0">Dikonia Domitory </span>
                                                                                                        </label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-sm-4">
                                                                                                    <div className="checkbox-inline">
                                                                                                        <label className="checkbox cursor-pointer ">
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                className="form-check-input me-1"
                                                                                                                value={permission_add_form.dormitory_testr.value}
                                                                                                                checked={permission_add_form.dormitory_testr.value}
                                                                                                                onChange={(event) => this.inputChangeHandler(event, "dormitory_testr")}
                                                                                                            />
                                                                                                            <span className="mb-0">Test R</span>
                                                                                                        </label>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="col-sm-4 mt-4">
                                                                                                    <div className="checkbox-inline">
                                                                                                        <label className="checkbox cursor-pointer ">
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                className="form-check-input me-1"
                                                                                                                value={permission_add_form.dormitory_southzone.value}
                                                                                                                checked={permission_add_form.dormitory_southzone.value}
                                                                                                                onChange={(event) => this.inputChangeHandler(event, "dormitory_southzone")}
                                                                                                            />
                                                                                                            <span className="mb-0">South zone</span>
                                                                                                        </label>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="col-sm-4">
                                                                                                    <div className="checkbox-inline">
                                                                                                        <label className="checkbox cursor-pointer ">
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                className="form-check-input me-1"
                                                                                                                value={permission_add_form.dormitory_beta.value}
                                                                                                                checked={permission_add_form.dormitory_beta.value}
                                                                                                                onChange={(event) => this.inputChangeHandler(event, "dormitory_beta")}
                                                                                                            />
                                                                                                            <span className="mb-0">Beta</span>
                                                                                                        </label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="d-flex border-0">
                                                                            <button type="reset" className="btn btn-sm btn-light me-2 min-150px" onClick={(event) => this.ChangePermissions(false)}>
                                                                                Cancel
                                                                            </button>
                                                                            <button type="submit" className="btn btn-sm btn-primary min-150px">
                                                                                Save
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                            {/* </div> */}
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <Footer />

                                    {/* <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
                        <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
                            <div className="text-dark order-2 order-md-1 fs-7">
                                <span className="text-muted fw-bold me-1">2022©</span>
                                <span target="_blank" className="text-gray-800 text-hover-primary fw-bold"> Value Education Olympiad</span>
                            </div>

                            <p className="order-1 footer-txt">Designed & Developed by <span target="_blank" className="text-hover-primary fw-bold">Dikonia</span></p>
                        </div>
                    </div>
                </div> */}
                                </div>
                            </div>

                            <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
                                <span className="svg-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                                        <path
                                            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                            fill="currentColor"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {};
  }
  const mapDispatchToProps = {
    onUpdateManagerPermissions: userActions.UpdateManagerPermissions,
    onGetManagerPermissonDetailsById: userActions.GetManagerPermissonDetailsById,
    onGetAllManagersBySchoolId: userActions.GetAllManagersBySchoolId,
    onGetAllDormitoryStudent: userActions.GetAllDormitoryStudent,
    onGetAllClassesList: userActions.GetAllClassesList,
    onGetLoginUserPermissions: userActions.GetLoginUserPermissions,
  };
  export default connect(mapStateToProps, mapDispatchToProps)(PermissionAdd);
  
