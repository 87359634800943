

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, Navigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';
import { Check } from "@mui/icons-material";
import { IconButton } from "@mui/material";

const HostLeaveApproval = (props) => {
    const [paramData, setParamData] = useState({})
    const [rulesResponsibilities, setRulesResponsibilities] = useState()
    const [notification, setNotification] = useState(null);
    const [value, setValue] = useState(false)
    const [checkVal, setCheckVal] = useState(false)
    const [buttonVal, setButtonVal] = useState("")
    const [error, setError] = useState(false)

    const param = useParams()
    useEffect(() => {
        GetHostResposibilties()
        GetStudentLeaveDetailsForHostById()
        setParamData(param)
    }, [param, buttonVal])
    const GetHostResposibilties = async () => {
        try {
            const headers = {
                school_code: param.school_code,
                country_host: param.country_host,
            };
            const response = await fetch('https://bssnew.dikonia.in/api/bss-admin/GetHostResposibilties', {
                method: 'GET',
                headers: headers,
            });
            const result = await response.json();
            setRulesResponsibilities(result.data.host_responsibilities)
        } catch (error) {
        }
    };

    const GetStudentLeaveDetailsForHostById = async () => {
        try {
            const headers = {
                school_code: param.school_code,
                country_host: param.country_host,
            };
            const response = await fetch(`https://bssnew.dikonia.in/api/bss-admin/GetStudentLeaveDetailsForHostById?boarder_leave_uuid=${param.uuid}`, {
                method: 'GET',
                headers: headers,
            });
            const result = await response.json();
            setButtonVal(result.data.is_host_approval)
            console.log("result", result.data.is_host_approval);
        } catch (error) {
        }
    };



    const leaveSubmit = (leave) => {
        if (checkVal === false) {
            setError(true)
        } else {

            postData(leave)
        }
    }
    const postData = async (leave) => {
        try {
            const apiUrl = 'https://bssnew.dikonia.in/api/bss-admin/LeaveApprovedByHost';
            const queryParams = new URLSearchParams({
                border_leave_uuid: param.uuid,
                is_host_approval: leave,
            });

            const response = await axios.post(`${apiUrl}?${queryParams}`, null, {
                headers: {
                    school_code: param.school_code,
                    country_host: param.country_host,
                },
            });
            if (response.data.success === true) {
                GetStudentLeaveDetailsForHostById()
                showToast(response.data.message, 3000, true)
            } else {
                GetStudentLeaveDetailsForHostById()
                showToast(response.data.message, 3000, false)
            }
        } catch (error) {
        }
    };

    const showToast = (message, duration = 3000, val) => {

        setNotification(message);
        setValue(val)

        setTimeout(() => {
            setNotification(null);
        }, duration);
    };

    const downloadPdf = async() => {
        try {
            const headers = {
                school_code: param.school_code,
                country_host: param.country_host,
            };
            const response = await fetch(`https://bssnew.dikonia.in/api/bss-admin/CreatePdfOfResponsibility`, {
                method: 'GET',
                headers: headers,
            });
            const result = await response.json();
            DownloadPdf(result.file);
            console.log("result", result);
        } catch (error) {
        }
    }

    const DownloadPdf = (pdf_url) => {
        const link = document.createElement("a");
        link.href = pdf_url;
        link.target = "_blank"
        // link.setAttribute("download");
        document.body.appendChild(link);
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }

    return (
        <React.Fragment>
            {notification && (
                <Toaster message={notification} duration={3000} value={value} />
            )}
            <div>
                <div
                    style={{ fontFamily: "Arial,Helvetica,sans-serif", lineHeight: "1.5", fontWeight: "normal", fontSize: "15px", color: "#2F3044", minHeight: "100vh", margin: 0, padding: 0, width: "100%", backgroundColor: "#edf2f7" }}>
                    <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%"
                        style={{ borderCollapse: "collapse", margin: "0 auto", padding: 0, maxWidth: "600px" }}>
                        <tbody>
                            <tr>
                                <td align="center" valign="center" style={{ textAlign: "center", padding: "40px" }}>
                                    <a href="#" rel="noopener" target="_blank">
                                        <img alt="BSSNEW" src="https://bssnew.dikonia.in/assets/media/logos/logo.png"
                                            style={{ width: "130px" }} />
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td align="left" valign="center">
                                    <div
                                        style={{ textAlign: "left", margin: "0 20px", padding: "30px", backgroundColor: "#ffffff", borderRadius: "6px" }}>
                                        <div style={{ paddingBottom: "20px", fontSize: "17px" }}>
                                            <strong>Welcome to Boarding School Software (pty) Ltd</strong>
                                        </div>
                                        <div className="pb-5 d-flex justify-content-between align-items-center">
                                            <h2>Host Responsibilities</h2>
                                            <div>
                                                <span className="me-4">
                                                    Download this PDF
                                                </span>
                                                <IconButton onClick={()=>downloadPdf()}>
                                                    <i class="fas fa-file-pdf"></i>
                                                </IconButton>

                                            </div>
                                        </div>
                                        <div className="pb-5">
                                            <p>{rulesResponsibilities}</p>
                                        </div>
                                        {buttonVal === "pending" ?
                                            <div className="form-check pb-5">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={checkVal}
                                                    checked={checkVal}
                                                    id="flexCheckChecked"
                                                    onChange={(e) => { setCheckVal(!JSON.parse(e.target.value)); checkVal ? setError(true) : setError(false) }}
                                                />
                                                <label className="form-check-label" for="flexCheckChecked">
                                                    Accept Responsibility as Host
                                                </label>
                                                {error ?
                                                    <p className="text-danger">Note - Please check the box first</p>
                                                    : ""
                                                }
                                            </div> :
                                            ""

                                        }
                                        {console.log('pending===============================', buttonVal)}
                                        {buttonVal === "pending" ?
                                            <div className="pb-5 d-flex justify-content-center btn-sm">
                                                <button
                                                    type="button"
                                                    className="btn btn-danger me-4"
                                                    onClick={() => leaveSubmit("rejected")}
                                                >
                                                    Decline Leave Application
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-success btn-sm"
                                                    onClick={() => leaveSubmit("approved")}
                                                >
                                                    Approve Leave Application
                                                </button>

                                            </div>
                                            :
                                            <div className="pb-5 d-flex justify-content-center btn-sm">
                                                {buttonVal === "rejected"
                                                    ?
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger me-4"
                                                        onClick={() => leaveSubmit("rejected")}
                                                    >
                                                        Decline Leave Application
                                                    </button>
                                                    :
                                                    <button
                                                        type="button"
                                                        className="btn btn-success btn-sm"
                                                        onClick={() => leaveSubmit("approved")}
                                                    >
                                                        Approve Leave Application
                                                    </button>
                                                }
                                            </div>
                                        }
                                        <div>
                                            Kind regards,
                                            <br />
                                            The BSS Team.
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    );

}
function mapStateToProps(state) {
    return {
        loader: state.Auth.loader,
        error: state.Auth.error,
    };
}
const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(HostLeaveApproval);


const Toaster = ({ message, duration, value }) => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(false);
        }, duration);

        return () => clearTimeout(timer);
    }, [duration]);

    return (
        visible && (
            <div className="toaster">
                <div className={`${value ? "alert alert-success" : "alert alert-danger"}`}>
                    <span>{message}</span>
                </div>
            </div>
        )
    );
};

