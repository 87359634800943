import React from "react";
import { useLocation , useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClassList from '../component/Classes/ClassList';
import ClassEdit from '../component/Classes/ClassEdit';
import ClassAdd from '../component/Classes/ClassAdd';


const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" :user_info?.school_code;


const Class = (props) => {
    const Renderview = () => {
        let location = useLocation();
        let userId = useParams();

        if (location.pathname === `/${school_code}/class-list` || location.pathname === `/${school_code}/class-list/`) {
            return <ClassList /> ;
        }
        if (location.pathname === `/${school_code}/class-list/edit-class/${userId.id}` || location.pathname === `/${school_code}/class-list/edit-class/${userId.id}/`) {
            return <ClassEdit  class_uuid={userId.id}  /> ;
        }
        if (location.pathname === `/${school_code}/class-list/add-class` || location.pathname === `/${school_code}/class-list/add-class/`) {
            return <ClassAdd /> ;
        }
       
    };

    return (
        <React.Fragment>
            <ToastContainer />
            {Renderview()}
        </React.Fragment>
    );
};
export default Class;