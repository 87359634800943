import React, { useState, useEffect, useCallback } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";
import SideBarLeave from "../../Constant/SideBarLeave";
import * as userActions from "../../../actions/index";
import { connect } from "react-redux";
import IconButton from '@mui/material/IconButton';
import EditAdhocleaveTime from '../AdhocLeaveModals/EditAdhocleaveTime';
import DelteAdhocLeaveModal from '../AdhocLeaveModals/DeleteAdhocLeaveModal';

const customStyles = {
   option: (provided, state) => ({
      ...provided,
      "&:hover": {
         backgroundColor: state.isFocused ? "#e7f5fe" : "",
      },
      backgroundColor: state.isSelected ? "#4a8fb8" : "",
   }),
};

const AdhocLeaveDetails = (props) => {
   const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
   const school_code = user_info?.school_code ?? null;
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const [rowVisibility, setRowVisibility] = useState({}); // State for row visibility
   const [page, setPage] = useState(1);
   const [limit, setLimit] = useState(50);
   const [count, setCount] = useState();
   const [sort, setSort] = useState("adhoc_leave_id");
   const [order, setOrder] = useState("desc");
   const [search, setSearch] = useState("");
   const [from_date, setFromDate] = useState("");
   const [to_date, setToDate] = useState("");
   const [dormitory_ids, setdormitory_ids] = useState([]);
   const [dormitory_id, setdormitory_id] = useState("");
   const [class_ids, setclass_ids] = useState([]);
   const [class_id, setclass_id] = useState("");
   const [activity_id, setActivityId] = useState('')
   const [activity_ids, setActivityIds] = useState([]);
   const [AdhocLeaveList, setAdhocLeaveList] = useState([]);
   const [confirm_modal, setConfirm_modal] = useState(false)
   const [confirmDeleteModal,setConfirmDeleteModal] = useState(false)
   const [adhocDelete,setAdhocDelete] = useState({})
   const [leave_data, setLeave_data] = useState({})
   const row_limit = [
      { value: 5, label: 5 },
      { value: 10, label: 10 },
      { value: 15, label: 15 },
      { value: 20, label: 20 },
      { value: 25, label: 25 },
      { value: 50, label: 50 }
   ]


   useEffect(() => {
      GetAllAdhocLeaveStudentDetails();
   }, [page, limit, sort, order, search, from_date, to_date, class_id, dormitory_id, activity_id]);

   useEffect(() => {
      GetAllClassesDetailsStudent();
      GetAllDormitoryStudent();
      GetAllActivity();
   }, []);





   // pagination
   const handlePaginationChange = (event, value) => {
      setPage(value);
      GetAllAdhocLeaveStudentDetails(value)
   };
   // for limit 
   const handleLimitChange = (e, value) => {
      const limit = parseInt(e.value);
      setLimit(limit, () => {
         GetAllAdhocLeaveStudentDetails(limit);
      });
   };


   const handleToggleRowVisibility = (index) => {
      setRowVisibility((prevState) => ({
         ...prevState,
         [index]: !prevState[index],
      }));
   };


   const handleErrorResponse = (response) => {
      try {
         if (response.status === 401) {
            toast.error(response.message, {
               position: toast.POSITION.TOP_CENTER,
            });
            setTimeout(() => {
               navigate('/logout');
            }, 5000);
         } else {
         }
      }
      catch (e) {
      }
   };

   const GetAllAdhocLeaveStudentDetails = async () => {
      dispatch(userActions.GetAllAdhocLeaveStudentDetails(page, limit, sort, order, search, from_date, to_date, class_id, dormitory_id, activity_id)).then((response) => {
         if (response.success === true) {
            const arrayData = response.data.rows;
            let filter_arrayData = [];
            filter_arrayData = arrayData.map(element => {
               let weekendArray = [];
               element.adhoc_leave_details.forEach(element_2 => {
                  if (weekendArray.includes(element_2.adhoc_leave_day.slice(0, 3)) === false) {
                     weekendArray.unshift(element_2.adhoc_leave_day.slice(0, 3))
                  }
               })
               return { ...element, weekendArray }
            });
            console.log("arrayData", filter_arrayData);
            setAdhocLeaveList(filter_arrayData);
            setCount(response.data.count)
         }
         else {
            handleErrorResponse(response);
         }
      });
   };

   const GetAllClassesDetailsStudent = useCallback(() => {
      dispatch(userActions.GetAllClassesDetailsStudent()).then((response) => {
         if (response.success === true) {
            const state_data = []
            state_data.push({
               label: "Select Year",
               value: "",
            })
            const arrayData = response.data.rows;
            for (let key in arrayData) {
               state_data.push({
                  label: arrayData[key].class_name,
                  value: arrayData[key].class_id,
               })
            }
            setclass_ids(state_data);
         }
      });
   }, [dispatch]);

   const GetAllDormitoryStudent = useCallback(() => {
      dispatch(userActions.GetAllDormitoryStudent()).then((response) => {
         if (response.success === true) {
            let state_data = [];
            state_data.push({
               label: "Select Dormitory",
               value: "",
            });
            const arrayData = response.data.rows;
            for (let key in arrayData) {
               state_data.push({
                  label: arrayData[key].dormitory_name,
                  value: arrayData[key].dormitory_id,
                  dormitory_id: arrayData[key].dormitory_id,
               });
            }
            setdormitory_ids(state_data);
         } else {
         }
      });
   }, [dispatch]);

   const GetAllActivity = useCallback(async () => {
      dispatch(userActions.GetAllActivity()).then((response) => {
         let country_level = [];
         country_level.push({
            label: "Select Activity",
            value: ""
         })
         if (response.success === true) {
            const arrayData = response.data.rows;
            for (let key in arrayData) {
               country_level.push({
                  label: arrayData[key].activity_name,
                  value: arrayData[key].activity_id,
               });
            }
         }
         setActivityIds(country_level)

      })
   }, [dispatch]);

   const showModal = (leave) => {
      setLeave_data(leave)
      setConfirm_modal(true)
   }
   const modalHide = () => { setConfirm_modal(false) };
   const SubmitHandler = async (form_data) => {
      props.onUpdateAdhocLeaveTimeSchedule(form_data).then((response) => {
         if (response.success === true) {
            setConfirm_modal(false)
            GetAllAdhocLeaveStudentDetails();
            toast.success(response.message, {
               position: toast.POSITION.TOP_CENTER,
            });
         } else {
            toast.error(response.message, {
               position: toast.POSITION.TOP_CENTER,
            });
         }
      })
   }
   const deleteAdhocLeave = (item) => {
      console.log("DFsgfjhfk",item);
      let payload = {}
      if (item.adhoc_leave_detail_id) {
         payload = { adhoc_leave_detail_id: item.adhoc_leave_detail_id}
      } else {
         payload = { adhoc_leave_detail_id: item.adhoc_leave_detail_id }
      }
      setAdhocDelete(payload)
      setConfirmDeleteModal(true)
   }
   const deleteAdhocLeaveById = (payload) => {
      console.log("payload",payload);
      props.onDeleteAdhocLeaveById(payload).then((response) => {
         if (response.success === true) {
            GetAllAdhocLeaveStudentDetails();
            setConfirmDeleteModal(false);
            toast.success(response.message, {
               position: toast.POSITION.TOP_CENTER,
            });
         } else {
            toast.error(response.message, {
               position: toast.POSITION.TOP_CENTER,
            });
         }
      })
   }
   const hideDeleteAdhostModal = () =>{setConfirmDeleteModal(false)}
   return (
      <>
         {confirm_modal && (
            <EditAdhocleaveTime
               confirm_modal={confirm_modal}
               modalHide={() => modalHide()}
               leaveData={leave_data}
               SubmitHandler={(event) => SubmitHandler(event)}
            />
         )}
         {confirmDeleteModal && (
            <DelteAdhocLeaveModal
               confirmDeleteModal = {confirmDeleteModal}
               deleteAdhocLeaveById = {(adhoc_data)=>deleteAdhocLeaveById(adhoc_data)}
               adhocDelete={adhocDelete}
               hideDeleteAdhostModal={(value)=>hideDeleteAdhostModal(value)}
            />
         )}
         <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed" >
            <div className="d-flex flex-column flex-root">
               <div className="page d-flex flex-row flex-column-fluid">
                  <SideBarLeave />
                  <div className="wrapper d-flex flex-column flex-row-fluid " id="kt_wrapper">
                     <TopBar />
                     <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="post d-flex flex-column-fluid" id="kt_post">
                           <div id="kt_content_container" className="container-fluid">
                              <div className='row'>
                                 <div className='col-md-12'>
                                    <div className='card'>
                                       <div className='card-header align-items-center'>
                                          <h3 className='m-0 fw-bolder'>Check Student Adhoc Leave Details</h3>
                                       </div>
                                       <div className='card-body'>
                                          <div className="row filter-top-block filter-block-two  align-items-end">
                                             <div className="col">
                                                <label className='form-label'>Select Year</label>
                                                <Select
                                                   styles={customStyles}
                                                   className="react-bootstrap-typeahead tag-outer select2-selection select2-selection--single "
                                                   options={class_ids}
                                                   value={class_ids.filter((option) => {
                                                      return option.value === class_id;
                                                   })}
                                                   onChange={(event) => {
                                                      setclass_id(event.value);
                                                   }}
                                                />
                                             </div>
                                             <div className="col"><label className='form-label'>Select Dormitory</label>
                                                <Select
                                                   styles={customStyles}
                                                   className="react-bootstrap-typeahead tag-outer"
                                                   options={dormitory_ids}
                                                   value={dormitory_ids.filter((option) => {
                                                      return option.value === dormitory_id;
                                                   })}
                                                   onChange={(event) => {
                                                      setdormitory_id(event.value);
                                                   }}
                                                />
                                             </div>

                                             <div className='col'>
                                                <div className='form-group'>
                                                   <label className='fw-bold mb-3'>Student Name</label>
                                                   <div className="position-relative">
                                                      <span className="search-icon-block">
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none">
                                                      <path d="M19 19L13.0001 13M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z" stroke="#999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                      </svg>
                                                      </span>
                                                      <input 
                                                         type="search" 
                                                         placeholder="Search..." 
                                                         className="form-control pe-10" 
                                                         value={search} 
                                                         onChange={(e) => setSearch(e.target.value)} />
                                                   </div>
                                                </div>
                                             </div>


                                             <div className="col"><label className='form-label'>Select Activity</label>
                                                <Select
                                                   styles={customStyles}
                                                   className="react-bootstrap-typeahead tag-outer"
                                                   options={activity_ids}
                                                   value={activity_ids.filter((option) => {
                                                      return option.value === activity_id;
                                                   })}
                                                   onChange={(event) => {
                                                      setActivityId(event.value);
                                                   }}
                                                />
                                             </div>
                                             <div className="col">
                                                <label className='form-label'>From Date</label>
                                                <div className='position-relative'>
                                                   <span className='position-absolute apply_adhoc_leave_date_and_time'>
                                                      <i class="far fa-calendar-alt mt-2px"></i>
                                                   </span>
                                                   <input
                                                      id="session-date"
                                                      type="date"
                                                      className="form-control"
                                                      placeholder='From Date'
                                                      value={from_date}
                                                      onChange={(e) => setFromDate(e.target.value)}
                                                      required
                                                   />
                                                </div>
                                             </div>
                                             <div className="col">
                                                <label className='form-label'>To Date</label>
                                                <div className='position-relative'>
                                                   <span className='position-absolute apply_adhoc_leave_date_and_time'>
                                                      <i class="far fa-calendar-alt mt-2px"></i>
                                                   </span>
                                                   <input
                                                   id="session-date"
                                                      type="date"
                                                      className="form-control"
                                                      placeholder='To Date'
                                                      value={to_date}
                                                      onChange={(e) => setToDate(e.target.value)}
                                                      required
                                                   />
                                                </div>
                                             </div>
                                             <div className="col">
                                                <div className="d-flex justify-content-end align-items-end">
                                                   <button type="reset" className="ms-3  btn btn-secondary btn-sm btn-color-gray-700 btn-flex btn-active-primary  border-0 fw-bolder px-4 px-lg-6 me-2 me-lg-3 w-200 white-space"
                                                      onClick={(event) => {
                                                         setclass_id("");
                                                         setdormitory_id("");
                                                         setActivityId("");
                                                         setFromDate("");
                                                         setToDate("");
                                                         setSearch("");
                                                      }} >
                                                      Clear Search
                                                   </button>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className='row'>
                                 <div className="col-lg-12 mt-5">
                                    <div className='card'>
                                       <div className="card-header align-items-center">
                                          <h3 className="m-0 fw-bolder">Adhoc Leave Status Details <small className="text-success"><em>(Adhoc Leave: Is always, automatically approved on this system: Be sure it is approved before making this entry!)</em></small></h3>
                                       </div>
                                       <div className='card-body p-6'>
                                          <div className='table-responsive'>
                                             {console.log("dasfsdfgdgdfhdfh", AdhocLeaveList)}

                                             <MDBTable className='table-row-bordered'>
                                                <MDBTableHead>
                                                   <tr>
                                                      <th className='text-nowrap'></th>
                                                      <th className='text-nowrap'>Unique pin</th>
                                                      {/* <th className='text-nowrap'>Sr. No</th> */}
                                                      <th className='text-nowrap'>Student Name</th>
                                                      <th className='text-nowrap'>Year</th>
                                                      <th className='text-nowrap'>Dormitory</th>
                                                      <th className='text-nowrap'>Adhoc Leave Days</th>
                                                      <th className='text-nowrap'>Activity</th>
                                                      <th className='text-nowrap'>Venue</th>
                                                      <th className='text-nowrap'>Submit Time</th>
                                                      <th className='text-nowrap'>Start & End date</th>
                                                      <th className='text-nowrap'>Actions</th>
                                                   </tr>
                                                </MDBTableHead>
                                                <MDBTableBody>
                                                   {AdhocLeaveList.map((item, index) => {
                                                      return (
                                                         <>
                                                            <tr className=''>
                                                               <td className='text-nowrap'>
                                                                  <button
                                                                     className={`btn ${rowVisibility[index]
                                                                        ? 'btn   btn-sm w-32px '
                                                                        : 'btn   btn-sm w-32px '
                                                                        } btn-sm w-32px`}
                                                                     type="button"
                                                                     onClick={() => handleToggleRowVisibility(index)}
                                                                  >
                                                                     <i
                                                                        className={`bi ${rowVisibility[index]
                                                                           ? 'bi up-arrow p-0'
                                                                           : 'bi down-arrow p-0'
                                                                           } p-0`}
                                                                     ></i>
                                                                  </button>
                                                               </td>
                                                               {/* <th className='text-nowrap'>{index + 1}</th> */}
                                                               <td className='text-nowrap'>{item.adhoc_stu_data[0]?.unique_pin || 'N/A'}</td>
                                                               <td className='text-nowrap'>{`${item.adhoc_stu_data[0]?.student_first_name} ${item.adhoc_stu_data[0]?.student_last_name}` || 'N/A'}</td>
                                                               <td className='text-nowrap'>{item.adhoc_stu_data[0]?.class_data?.class_name || 'N/A'}</td>
                                                               <td className='text-nowrap'>{item.adhoc_stu_data[0]?.dormitory_data?.dormitory_name || 'N/A'}</td>
                                                               <td className='text-nowrap'>{item.weekendArray.join("-")}</td>
                                                               <td className='text-nowrap'>{item.adhoc_activity_data?.activity_name || 'N/A'}</td>
                                                               <td className='text-nowrap'>{item.venue || 'N/A'}</td>
                                                               <td className='text-nowrap'>{moment(item.created_date).format('DD/MM/YYYY, HH:mm')}</td>
                                                               <td className='text-nowrap'>{moment(item.start_date).format('DD/MM/YYYY')} - {moment(item.end_date).format('DD/MM/YYYY')}</td>
                                                               <td className='text-nowrap'>
                                                                  <Tooltip title="View Adhoc Leave details" placement="top">
                                                                     <Link to={`/${school_code}/leave/edit-adhoc-leave/${item.adhoc_leave_detail_uuid}`} state={{ data: item }}>
                                                                        <IconButton>
                                                                           <i class="fas fa-eye fs-5"></i>
                                                                        </IconButton>
                                                                     </Link>
                                                                  </Tooltip>

                                                                  <Tooltip title="Delete Adhoc Leave Details" placement="top">
                                                                     <IconButton onClick={() => deleteAdhocLeave(item)}>
                                                                        <i className="far fa-trash-alt fs-5"></i>
                                                                     </IconButton>
                                                                  </Tooltip>
                                                               </td>

                                                            </tr>
                                                            {
                                                               rowVisibility[index] && (
                                                                  <>
                                                                     <tr className='text-bg-light'>
                                                                        <th className='text-nowrap'></th>
                                                                       
                                                                        <th colspan="1" className='text-nowrap'>Weekday</th>
                                                                        <th colspan="1" className='text-nowrap'>Week Date</th>
                                                                        <th colspan="1" className='text-nowrap'>Start Time</th>
                                                                        <th colspan="1" className='text-nowrap'>End Time</th>
                                                                        <th colspan="1" className='text-nowrap'>Actual Start Time</th>
                                                                        <th colspan="1" className='text-nowrap'>Actual End Time</th>
                                                                        <th colspan="3" className='text-nowrap'>Travel Out</th>
                                                                        <th colspan="2" className='text-nowrap'>Travel In</th>
                                                                        <th className='text-nowrap'>Action</th>
                                                                     </tr>
                                                                     {console.log("leave_data", item)}
                                                                     {item.adhoc_leave_details.map((leave) => {
                                                                        console.log(leave,"::datadat")
                                                                        return (
                                                                           <tr className='text-bg-light row-style'>
                                                                              <td className='text-nowrap'></td>
                                                                           
                                                                              <td colspan="1">{leave.adhoc_leave_day}</td>
                                                                              <td colspan="1">{moment(leave.arrival_date).format('DD/MM/YYYY')}</td>
                                                                              <td colspan="1">{moment(leave.start_time, "hh:mm A").format("hh:mm A")}</td>
                                                                              <td colspan="1">{moment(leave.end_time, "hh:mm A").format("hh:mm A")}</td>

                                                                              <td colspan="1">{leave?.actual_start_time ? moment(leave?.actual_start_time, "hh:mm A").format("hh:mm A") : ""}</td>
                                                                              <td colspan="1">{leave?.actual_end_time ? moment(leave?.actual_end_time, "hh:mm A").format("hh:mm A") : ""}</td>

                                                                              <td colspan="3">{item.depart_mode_data.travel_mode_name}</td>
                                                                              <td colspan="2">{item.arrival_mode_data.travel_mode_name}</td>
                                                                              <td className=' text-nowrap'>
                                                                                 <Tooltip title="Edit Adhoc Leave" placement="top">
                                                                                    <IconButton onClick={() => showModal(leave)}>
                                                                                       <i class="far fa-edit me-2 fs-5"></i>
                                                                                    </IconButton>
                                                                                 </Tooltip>
                                                                                 <Tooltip title="Delete Adhoc Leave" placement="top">
                                                                                    <IconButton onClick={() => deleteAdhocLeave(leave)}>
                                                                                       <i className="far fa-trash-alt fs-5"></i>
                                                                                    </IconButton>
                                                                                 </Tooltip>
                                                                              </td>
                                                                           </tr>
                                                                        )
                                                                     })}  
                                                                  </>
                                                               )
                                                            }
                                                         </>


                                                      )
                                                   })}
                                                </MDBTableBody>
                                             </MDBTable>
                                          </div>


                                          <div className="d-flex justify-content-between">
                                             <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                defaultValue={row_limit[5]}
                                                name="color"
                                                options={row_limit}
                                                onChange={handleLimitChange}
                                             />
                                             <Stack>
                                                <Pagination
                                                   count={Math.ceil(count / limit)}
                                                   page={page}
                                                   onChange={handlePaginationChange}
                                                   color="primary"
                                                />
                                             </Stack>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        </div>
                        <Footer />
                    
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}

function mapStateToProps(state) {
   return {

   };
}

const mapDispatchToProps = {
   onGetAllActivity: userActions.GetAllActivity,
   onGetAllTravelModes: userActions.GetAllTravelModes,
   onUpdateAdhocLeaveTimeSchedule: userActions.UpdateAdhocLeaveTimeSchedule,
   onDeleteAdhocLeaveById: userActions.DeleteAdhocLeaveById

};
export default connect(mapStateToProps, mapDispatchToProps)(AdhocLeaveDetails);
