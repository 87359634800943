import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";

import Footer from "../../Constant/Footer";
import SuperAdminTopbar from "../topbar";
import SuperAdminTopMenu from "../Sidemenu";


import * as userActions from "../../../actions/index";
import DashboardItem from "./DashboardItem";

import moment from "moment";

const SuperAdminDashboard = (props) => {
    const dispatch = useDispatch();

    const [filterlimit, setfilterlimit] = useState(10);
    const [filterpage, setfilterpage] = useState(1);
    const [filterorder] = useState("");
    const [filtersort] = useState("school_detail_id");
    const [filtersearch, setfiltersearch] = useState(null);

    const [SuperAdminDashboard, setSuperAdminDashboard] = useState([]);
    const [SuperAdminDashboardcount, setSuperAdminDashboardcount] = useState(0);
    const [is_school_activate] = useState('');


    const GetAllDashboardSchools = useCallback(async () => {
        dispatch(userActions.GetAllDashboardSchools(filterpage, filterlimit, filtersort, filterorder, filtersearch, is_school_activate)).then((response) => {
            if (response.success === true) {
                const arrayData = response.data.rows;
                console.log("arrayData=======",arrayData);
                let MemberId_level = [];
                const sumvalue = 1;
                for (let key in arrayData) {
                    const perpage = ((parseInt(filterpage) - parseInt(sumvalue)) * parseInt(filterlimit) + parseInt(key) + parseInt(sumvalue));

                    MemberId_level.push({
                        perpage: perpage,
                        school_name: arrayData[key].school_name,
                        first_name: arrayData[key].first_name + " " + arrayData[key].last_name,
                        country: arrayData[key].country,
                        
                        is_school_activate: arrayData[key].is_school_activate,
                        last_login_user: arrayData[key].last_login_user,
                        total_user: arrayData[key].total_user,
                        created_date :moment(arrayData[key].created_date).format('DD/MM/YYYY'),
                        last_login_date: arrayData[key].last_login_date === null ? "-" : moment(arrayData[key].last_login_date).format('DD/MM/YYYY')
                    });
                }
                setSuperAdminDashboard(MemberId_level);
                setSuperAdminDashboardcount(response.data.count);
            } else {
            }
        });
    }, [dispatch, filterpage, filterlimit, filtersort, filterorder, filtersearch, is_school_activate]);

    useEffect(() => {
        GetAllDashboardSchools();
    }, [GetAllDashboardSchools, filterpage, filterlimit, filtersort, filterorder, filtersearch, is_school_activate]);



    // const handleChangeLoginAsSchoolAdmin = async (event, user_uuid) => {
    //     const Payload = {
    //         user_uuid: user_uuid,
    //     };
    //     console.log('Payload===', Payload)
    //     props.onLoginAsSchoolAdmin(Payload).then((response) => {
    //         if (response.success === true) {

    //             localStorage.setItem("bss_authtoken", response.token);
    //             localStorage.setItem("bss_authuser", JSON.stringify(response.data));
    //             window.location.href = "/dashboard";

    //         } else {
    //             toast.error(response.message, {
    //                 position: toast.POSITION.TOP_CENTER,
    //             });
    //         }
    //     });

    // }

    return (
        <>
           

            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed" >
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <SuperAdminTopMenu />
                        <div className="wrapper d-flex flex-column flex-row-fluid " id="kt_wrapper">
                            <SuperAdminTopbar></SuperAdminTopbar>
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    {/* <!--begin::Container--> */}
                                    <div id="kt_content_container" className="container-fluid">


                                        <div className="tab-content" id="myTabContent">
                                            <div className="tab-pane fade" id="Snapshot" role="tabpanel" aria-labelledby="Snapshot-tab"></div>

                                            <div className="tab-pane fade show active" id="Rollcall" role="tabpanel" aria-labelledby="Rollcall-tab">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="card tabs-outer">
                                                            <div className="card-header position-relative py-0">
                                                                <h5 className="card-title text-gray-800 fw-bolder m-0">School Detail</h5>
                                                           
                                                                <div className="d-flex align-items-center position-relative">
                                                                            <span className="svg-icon svg-icon-1 position-absolute ms-4">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                                                                    <path
                                                                                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                                                                        fill="currentColor"
                                                                                    ></path>
                                                                                </svg>
                                                                            </span>
                                                                            <input type="text" className="form-control form-control-sm w-350px ps-12 search-out fs-6" placeholder="Search " 
                                                                                onChange={(event) => {
                                                                                    setfiltersearch(event.target.value);
                                                                                    setfilterpage(1);
                                                                                }}

                                                                            />
                                                                        </div>
                                                                    </div>
                                                                               
                                                            <div className="card-body">
                                                                <div className="card-toolbar">
                                                                    <div className="d-flex align-items-center justify-content-between">

                                                                        {/* <!-- <h4 className="card-title text-gray-800 fw-bolder m-0">Schools</h4> --> */}


                                                                   <div className="row">
                                                                    <div className="col-sm-12">

                                                                            {<DashboardItem
                                                                             
                                                                                SuperAdminDashboardcount={SuperAdminDashboardcount}
                                                                                SuperAdminDashboard={SuperAdminDashboard}
                                                                                data={SuperAdminDashboard}
                                                                                propsfilterpage={(event) => setfilterpage(event)}
                                                                                // RemoveCourseModule={(event, data) => RemoveCourseModule(event, data)}
        
                                                                                filterlimit={filterlimit}
                                                                                filterpage={filterpage}
                                                                                propsfilterlimit={(filterlimit) => {
                                                                                    setfilterlimit(filterlimit);
                                                                                }}
                                                                           />}



                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div> 
                                                    </div>




                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!--end::Container--> */}
                                </div>
                            </div>
                            <Footer></Footer>
                        </div>
                    </div>
                </div>
            </div >

        </>
    )
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {

    onLoginAsSchoolAdmin: userActions.LoginAsSchoolAdmin,
    onDeactivateSchool: userActions.DeactivateSchool,
    onDeleteSchoolAndDatabase: userActions.DeleteSchoolAndDatabase,
    // onGetAllCitiesByStateId: userActions.GetAllCitiesByStateId,
    // onCreateNewCity: userActions.CreateNewCity, 
    // onGetAllStatesByCountryId: userActions.GetAllStatesByCountryId,
};
export default connect(mapStateToProps, mapDispatchToProps)(SuperAdminDashboard);