import React, { PureComponent } from "react";

import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

import * as userActions from "../../actions/index";

class logout extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
        };
    }

    componentDidMount() {
        this.setState({ loader: true });
        this.props.onLogout().then((response) => {
            localStorage.removeItem("bss_authuser");
            localStorage.removeItem("bss_authtoken");
            localStorage.removeItem("bss_rollcall");
            localStorage.removeItem("Rollcal_Final");
                localStorage.removeItem("Rollcal_List");
                localStorage.removeItem("Rollcal_Data_Global");
                localStorage.removeItem("RollCall_time");
                localStorage.removeItem("SessionName");
                localStorage.removeItem("Dormitory_filters");
                localStorage.removeItem("Class_filters");
                localStorage.removeItem("Gender_filters");
                localStorage.removeItem("user_id");
                localStorage.removeItem("role_id");
                localStorage.removeItem("user_uuid");
                localStorage.removeItem("user_uuid");
                localStorage.removeItem("Manager_id");
                localStorage.removeItem("permissions");
                localStorage.removeItem('check_box_all')
                // localStorage.removeItem("check_box_all");
                localStorage.removeItem("status_select_all");
            window.location = "/";
        });
    }

    render() {
        return <React.Fragment>{this.state.isAuthenticated ? <Navigate to="/signin" /> : null}</React.Fragment>;
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLogout: () => userActions.logout(),
    };
};
export default connect(null, mapDispatchToProps)(logout);