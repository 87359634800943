import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import "react-datepicker/dist/react-datepicker.css";
import * as actionType from "../../../constants/actionTypes";
import { color, positions } from "@mui/system";
import { Dropdown } from "react-bootstrap";
import Box from '@mui/material/Box';


const adhoc_leave = {
    backgroundColor: "transparent",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    zIndex: 99,
}

const ConductRollCallItem = (props) => {
    // const [perpagedata, setperpagedata] = useState(10);


    const columns = ([


        {
            text: "",
            dataField: "Actions",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <div className="col-md-12 position-relative">
                        {console.log("Item-- reason check", row)}
                        <div className="box-one box-set ">
                            <div className="d-flex justify-content-end">
                                {/* <div className="h-75 w-100"> */}
                                <img alt="Logo" className="img-wrap-style h-25" src={row.student_avatar === null || row.student_avatar === '' || row?.display_image_required === false ? row.gender === 'female' ? "/media/avatars/girl.jpg" : "/media/avatars/boy.jpg" : actionType.environment_url + '/' + row.student_avatar} />
                                {/* </div> */}

                                <div className="justify-content-between top-wrap-block">
                                    <div className="row">
                                        <div className="col-md-7">
                                            <ul className="list-icons-wrap ">
                                                <li>
                                                {row.current_loc_data !== undefined &&

                                                    <div className=" ocm-hover h-100">
                                                        <span className="status-wrap text-white"
                                                            style={{
                                                                backgroundColor: row?.current_loc_data === "present" ? '#009A66 ' :
                                                                    row?.current_loc_data === "KL" ? '#0DC0FF ' :
                                                                        row?.current_loc_data === "Leave" ? '#FF6500' :
                                                                            row?.current_loc_data === "MIA" ? '#CB0032' :
                                                                                row?.current_loc_data === "ad-hoc" ? "#e4d310"
                                                                                    : '#33566CD '
                                                            }}>
                                                            {row.current_loc_data ? row.current_loc_data : ""}
                                                        </span>

                                                        {row.stu_atten_details.length === 0 ? null :
                                                            <div className="hover-oc" >
                                                                {row.stu_atten_details[0] ?
                                                                    <span className={row?.stu_atten_details[0]
                                                                        ? row?.stu_atten_details[0]?.is_attendance === false
                                                                            ? row?.stu_atten_details[0]?.attendance_reason[0]?.reason_name === "MIA"
                                                                                ? "text-danger" : row?.stu_atten_details[0]?.attendance_reason[0]?.reason_name === "L"
                                                                                    ? "text-danger" : "" : "" : ""}
                                                                    >
                                                                        {row?.stu_atten_details[0] ?
                                                                            row?.stu_atten_details[0]?.is_attendance === true ?
                                                                                "P" :
                                                                                row?.stu_atten_details[0]?.attendance_reason[0]?.reason_name === "On Campus" ? "OC" : row?.stu_atten_details[0]?.attendance_reason[0]?.reason_name
                                                                            : ""
                                                                        }
                                                                    </span>
                                                                    :
                                                                    ""
                                                                }

                                                                {row.stu_atten_details[1] ?
                                                                    <span className={row?.stu_atten_details[1]
                                                                        ? row?.stu_atten_details[1]?.is_attendance === false
                                                                            ? row?.stu_atten_details[1]?.attendance_reason[0]?.reason_name === "MIA"
                                                                                ? "text-danger" : row?.stu_atten_details[1]?.attendance_reason[0]?.reason_name === "L"
                                                                                    ? "text-danger" : "" : "" : ""}
                                                                    >
                                                                        {row?.stu_atten_details[1] ? row?.stu_atten_details[1]?.is_attendance === true ? "P" :
                                                                            row?.stu_atten_details[1]?.attendance_reason[0]?.reason_name === "On Campus" ? "OC" : row?.stu_atten_details[1]?.attendance_reason[0]?.reason_name
                                                                            : ""
                                                                        }
                                                                    </span>
                                                                    :
                                                                    ""
                                                                }

                                                                {row?.stu_atten_details[2] ?
                                                                    <span className={row?.stu_atten_details[2]
                                                                        ? row?.stu_atten_details[2]?.is_attendance === false
                                                                            ? row?.stu_atten_details[2]?.attendance_reason[0]?.reason_name === "MIA"
                                                                                ? "text-danger" : row?.stu_atten_details[2]?.attendance_reason[0]?.reason_name === "L"
                                                                                    ? "text-danger" : "" : "" : ""}
                                                                    >
                                                                        {row?.stu_atten_details[2]
                                                                            ? row?.stu_atten_details[2]?.is_attendance === true
                                                                                ? "P" : row?.stu_atten_details[2]?.attendance_reason[0]?.reason_name === "On Campus" ? "OC" : row?.stu_atten_details[2]?.attendance_reason[0]?.reason_name
                                                                            : ""
                                                                        }
                                                                    </span>
                                                                    :
                                                                    ""
                                                                }

                                                                {row?.stu_atten_details[3] ?
                                                                    <span className={row?.stu_atten_details[3]
                                                                        ? row?.stu_atten_details[3]?.is_attendance === false
                                                                            ? row?.stu_atten_details[3]?.attendance_reason[0]?.reason_name === "MIA"
                                                                                ? "text-danger" : row?.stu_atten_details[3]?.attendance_reason[0]?.reason_name === "L"
                                                                                    ? "text-danger" : "" : "" : ""}
                                                                    >
                                                                        {row?.stu_atten_details[3] ? row?.stu_atten_details[3]?.is_attendance === true ? "P" :
                                                                            row?.stu_atten_details[3]?.attendance_reason[0]?.reason_name === "On Campus" ? "OC" : row?.stu_atten_details[3]?.attendance_reason[0]?.reason_name
                                                                            : ""
                                                                        }
                                                                    </span>
                                                                    :
                                                                    ""
                                                                }

                                                                {row?.stu_atten_details[4] ?
                                                                    <span className={row?.stu_atten_details[4]
                                                                        ? row?.stu_atten_details[4]?.is_attendance === false
                                                                            ? row?.stu_atten_details[4]?.attendance_reason[0]?.reason_name === "MIA"
                                                                                ? "text-danger" : row?.stu_atten_details[2]?.attendance_reason[0]?.reason_name === "L"
                                                                                    ? "text-danger" : "" : "" : ""}
                                                                    >
                                                                        {row?.stu_atten_details[4] ? row?.stu_atten_details[4]?.is_attendance === true ? "P" :
                                                                            row?.stu_atten_details[4]?.attendance_reason[0]?.reason_name === "On Campus" ? "OC" : row?.stu_atten_details[4]?.attendance_reason[0]?.reason_name
                                                                            : ""
                                                                        }
                                                                    </span>
                                                                    :
                                                                    ""
                                                                }

                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                </li>

                                                <li>
                                                    <span>
                                                        {row.generic_data.length > 0 && <img style={{ background: `${row?.generic_data?.length > 0 ? "#A64D79" : "#b1b1b1"}` }} src="/media/writing.png" alt="" />}
                                                        {/* {row.generic_data.length > 0 ?
                                                            <img style={{ background: "#A64D79" }} src="/media/writing.png" alt="" />
                                                            :
                                                            <img src="/media/writing.png" alt="" style={{ background: "#b1b1b1" }} />
                                                        } */}
                                                    </span>
                                                    <div className={row?.generic_data == '' ? "" : "hover-block"}>
                                                        {row?.generic_data ? row?.generic_data[0]?.generic_desc : ""}
                                                    </div>
                                                </li>

                                                <li>
                                                    <span>
                                                        {row?.allergy_details?.length > 0 && <img style={{ background: `${row?.allergy_details?.length > 0 ? "#49BBFB" : "#b1b1b1"}` }} src="/media/letter-a.png" alt="" />}
                                                        {/* {row.allergy_details.length > 0 ?
                                                            <img style={{ background: "#49BBFB" }} src="/media/letter-a.png" alt="" />
                                                            :
                                                            <img src="/media/letter-a.png" alt="" style={{ background: "#b1b1b1" }} />
                                                        } */}
                                                    </span>
                                                    <div className={row?.allergy_details == '' ? "" : "hover-block"}>
                                                        {row?.allergy_details.length !== 0 ?
                                                            row?.allergy_details.map(item => { return <li>{item?.allergy_name}</li> })
                                                            : ""
                                                        }
                                                    </div>
                                                </li>

                                                <li>
                                                    <span>
                                                        {row?.grounded_data?.length > 0 && <img style={{ background: `${row?.grounded_data?.length > 0 ? "#7239EA" : "#b1b1b1"}` }} src="/media/anchor-new.png" alt="" />}

                                                        {/* {row.grounded_data.length > 0 ?
                                                            <img style={{ background: "#7239EA" }} src="/media/anchor-new.png" alt="" />
                                                            :
                                                            <img src="/media/anchor-new.png" alt="" style={{ background: "#b1b1b1" }} />
                                                        } */}
                                                    </span>
                                                    <div className={row?.grounded_data == '' ? "" : "hover-block"}>
                                                        {row?.grounded_data ? row?.grounded_data[0]?.grounded_desc : ""}
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="col-md-5 text-end">
                                            <div className="form-check form-check-sm form-check-custom form-check-solid justify-content-end flex-wrap mb-2">
                                                {/* To check/uncheck a student */}
                                                <input className="form-check-input widget-9-check "
                                                    type="checkbox"
                                                    stu_id={row.student_id}
                                                    checked={row.checked}
                                                    value={row.checked}
                                                    // checkednn={row.student_id}  
                                                    onClick={(event) => {
                                                        props.toggleChecked(event);
                                                    }}
                                                    // disabled={row.current_loc_data === "ad-hoc" || row.current_loc_data === "Leave" ? true : false}
                                                />
                                            </div>
                                            {/* <div> */}
                                            {/* Current location of student */}
                                            {/* <span className="status-wrap fs-sm">{row.current_loc_data ? row.current_loc_data : ""}</span> */}
                                            {/* </div> */}


                                        </div>
                                    </div>
                                </div>
                                <div className="doc-block roll-call-tag" style={row.current_loc_data === "Leave" ? { backgroundColor: "#ff650099" } : (row.current_loc_data === "ad-hoc" ? { backgroundColor: "#ffef3087" } : null)}>
                                     {/* <div style={row.current_loc_data === "ad-hoc" || row.current_loc_data === "Leave" ? adhoc_leave : {}}> </div> */}
                                    
                                    <div className={`d-flex  present click ${row.Present_Class}`} style={{width:"35px"}}>
                                        <span
                                            old_reason={row.reason_check == null ? '' : row.reason_check}
                                            stu_first_name={row.student_first_name}
                                            stu_last_name={row.student_last_name}
                                            dorm_name={row.dormitory_name}
                                            stu_id={row.student_id}
                                            id={'present'}
                                            value = {row.checked}
                                            stu_reason_oncampus_id={row.stu_reason_oncampus_id}
                                            className={`present pointer ${row.Present_Class}`}
                                            onClick={(event) => {
                                                props.handleClick(event, row.Present_Class);
                                            }}>P</span>
                                    </div>
                                    {/* Oncampus mark for every student */}
                                    <div className={`d-flex oc click ${row.OnCampus_Class}`} style={{width:"35px"}}>
                                        <span
                                            className={`oc pointer ${row.OnCampus_Class}`}
                                            stu_first_name={row.student_first_name}
                                            stu_last_name={row.student_last_name}
                                            dorm_name={row.dormitory_name}
                                            old_reason={row.reason_check == null ? '' : row.reason_check}
                                            id={row.OnCampus_id}
                                            stu_id={row.student_id}
                                            value = {row.checked}
                                            stu_reason_oncampus_id={row.stu_reason_oncampus_id}
                                            onClick={(event) => {
                                                props.handleClick(event, row.OnCampus_Class);
                                            }}
                                        >OC</span>
                                    </div>

                                    {/* <div className={`d-flex  kl click ${row.KL_Class}`}>
                                        <span className={`kl pointer ${row.KL_Class}`}
                                            stu_first_name={row.student_first_name}
                                            stu_last_name={row.student_last_name}
                                            dorm_name={row.dormitory_name}
                                            old_reason={row.reason_check == null ? '' : row.reason_check}
                                            stu_reason_oncampus_id={row.stu_reason_oncampus_id}
                                            id={row.KL_id}
                                            stu_id={row.student_id}
                                            value = {row.checked}
                                            onClick={(event) => {
                                                props.handleClick(event, row.KL_Class);
                                            }}
                                        >KL</span>
                                    </div> */}

                                    <div className={`d-flex  adhoc click `}>
                                        <span className="adhoc pointer" style={row?.stu_adhoc_data[0] !== undefined ? (row.stu_adhoc_data[0].check_in_out === "out" ? { color: "#fff600" } : {}) : {}} undefined >ADHOC</span>
                                    </div>

                                    {/* <div className={`d-flex  mia click ${row.MIA_Class}`}>
                                        <span className={`text-danger pointer ${row.MIA_Class}`}
                                            stu_first_name={row.student_first_name}
                                            stu_last_name={row.student_last_name}
                                            dorm_name={row.dormitory_name}
                                            id={row.MIA_id}
                                            old_reason={row.reason_check == null ? '' : row.reason_check}
                                            stu_id={row.student_id}
                                            stu_reason_oncampus_id={row.stu_reason_oncampus_id}
                                            value = {row.checked}
                                            onClick={(event) => {
                                                props.handleClick(event, row.MIA_Class);
                                            }}
                                        >MIA</span>
                                    </div> */}

                                    <div className={`d-flex  l click ${row.SL_Class}`} style={{width:"35px"}}>
                                        <span
                                            className={`l pointer ${row.SL_Class}`}
                                            stu_first_name={row.student_first_name}
                                            stu_last_name={row.student_last_name}
                                            dorm_name={row.dormitory_name}
                                            old_reason={row.reason_check == null ? '' : row.reason_check}
                                            id={row.SL_id}
                                            stu_id={row.student_id}
                                            stu_reason_oncampus_id={row.stu_reason_oncampus_id}
                                        // onClick={(event) => {
                                        //     props.handleClick(event, row.SL_Class);
                                        // }}
                                        >L</span>
                                    </div>
                                    <div className="row btn-menu mia-dropdown me-0">
                                        <div className="text-end" style={{margin:"auto 0"}}>
                                        <Dropdown sx={{positions:'relative'}}>
                                            {/* {(row.MIA_Class ||  row.KL_Class) && <Box sx={{width:"5px", height:"5px", backgroundColor:"red", borderRadius:"50%", position:'absolute', right:"0"}}></Box>} */}
                                            {(row.MIA_Class ||  row.KL_Class) && <Box className="blinkDot-indicator"></Box>}
                                            <Dropdown.Toggle id="dropdown-basic" >
                                                <img src="/media/dots.png" alt=""/>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu
                                                style={{width: "150px", display:"flex", flexDirection:"column" ,gap:"3px"}}
                                            >
                                                <div className={`d-flex mia click ${row.MIA_Class}`}>
                                                    <span className={`text-danger pointer ${row.MIA_Class}`}
                                                        stu_first_name={row.student_first_name}
                                                        stu_last_name={row.student_last_name}
                                                        dorm_name={row.dormitory_name}
                                                        id={row.MIA_id}
                                                        old_reason={row.reason_check == null ? '' : row.reason_check}
                                                        stu_id={row.student_id}
                                                        stu_reason_oncampus_id={row.stu_reason_oncampus_id}
                                                        value = {row.checked}
                                                        onClick={(event) => {
                                                            props.handleClick(event, row.MIA_Class);
                                                        }}
                                                    >MIA</span>
                                                </div>

                                                <div className={`d-flex  kl click ${row.KL_Class}`}>
                                                    <span className={`kl pointer ${row.KL_Class}`}
                                                        stu_first_name={row.student_first_name}
                                                        stu_last_name={row.student_last_name}
                                                        dorm_name={row.dormitory_name}
                                                        old_reason={row.reason_check == null ? '' : row.reason_check}
                                                        stu_reason_oncampus_id={row.stu_reason_oncampus_id}
                                                        id={row.KL_id}
                                                        stu_id={row.student_id}
                                                        value = {row.checked}
                                                        onClick={(event) => {
                                                            props.handleClick(event, row.KL_Class);
                                                        }}
                                                    >KL</span>
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* {console.log("CAPITAL LETTER DORMITORY", row.gender.charAt(0).toUpperCase() + row.gender.slice(1))} */}
                            {/* Information of every student */}
                            <div className="box-txt">
                                <h6 className="text-black fw-bolder">{row.student_first_name + " " + row.student_last_name}</h6>
                                <div className="row">
                                    <div className="col-md-7 pe-0">
                                        <p className="mb-0 text-black">{row.dormitory_name.charAt(0).toUpperCase() + row.dormitory_name.slice(1)}</p>
                                        <span className="text-black d-block">{row.unique_pin.charAt(0).toUpperCase() + row.unique_pin.slice(1)}</span>
                                    </div>
                                    <div className="col-md-5">
                                        <p className="mb-0 text-black">{row.class_name.charAt(0).toUpperCase() + row.class_name.slice(1)}</p>
                                        <p className="mb-0 text-black">{row.gender.charAt(0).toUpperCase() + row.gender.slice(1)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            },
        },
    ]);
    const handleTableChange = (type, { sortField, sortOrder, page }) => {
        if (type === "sort") {
            props.propsfiltersort(sortField);
            props.propsfilterorder(sortOrder);
        }
    };

    // const selectRow = {
    //     mode: 'checkbox',
    //     clickToSelect: true,
    //     // color: 
    // }


    const RemotePagination = ({ columns, dataField, order, data, page, sizePerPage, onTableChange, totalSize }) => (
        <PaginationProvider
            pagination={paginationFactory({
                custom: true,
                page,
                sizePerPage,
                totalSize,
            })}
        >
            {({ paginationProps, paginationTableProps }) => (
                <>
                    <div className="table-responsive common-table superadmin_table">
                        <BootstrapTable
                            remote
                            bootstrap4
                            data={data}
                            keyField="student_uuid"
                            sort={{ dataField: dataField, order: order }}
                            columns={columns}
                            {...paginationTableProps}
                            onTableChange={onTableChange}
                            sizePerPageList={sizePerPage}
                            headerClasses="fw-bolder text-white bg-dark-row"
                            classes="table-bordered align-middle custom-table-style table-fields"
                            noDataIndication={() => <span className="text-center text-gray-400">No records found</span>}
                        // selectRow={selectRow}
                        />
                    </div>

                </>
            )}
        </PaginationProvider>
    );

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-xl-12">
                    <div id="std-details-wrap" className="dataTables_wrapper dt-bootstrap4 no-footer team-list-table">
                        <div className="table-responsive">
                            <div className="card-body p-0 bg-white radious-style radious-users">
                                {/* <Spin spinning={props.loading}> */}
                                <RemotePagination
                                    data={props.data}
                                    columns={columns}
                                    page={props.filterpage}
                                    totalSize={props.StudentListcount}
                                    sizePerPage={props.filterlimit}
                                    order={props.filterorder}
                                    dataField={props.filtersort}
                                    onTableChange={handleTableChange}
                                />
                                {/* </Spin> */}
                            </div>
                        </div>
                    </div>


                </div>
            </div>

        </React.Fragment>
    );
};
export default ConductRollCallItem;