import React from 'react';
import { Modal } from "react-bootstrap";
const IncludeEmailModal = (props) => {
    return (
        <React.Fragment>
            <Modal
                size="480px"
                backdrop={true}
                keyboard={false}
                className="add_pastrol_comment_modal confirm_modal"
                show={props.includes}
            >
                <Modal.Header className='border-0 pb-0 justify-content-end'>
                   
                    <span className='cursor-pointer' onClick={() => props.setIncludeModalVlaue(false)}>
                   <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#999">
            <rect x="0" y="7" width="16" height="2" rx="1"/>
            <rect opacity="1" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
        </g>
    </g>
</svg>
                    </span>
                </Modal.Header>
                <Modal.Body className='pt-0'>
                <span className="svg-icon svg-icon-primary svg-icon-3x d-table m-auto mb-4"><svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect x="0" y="0" width="24" height="24"/>
        <circle fill="#009ef7" opacity="0.3" cx="12" cy="12" r="10"/>
        <rect fill="#000000" x="11" y="10" width="2" height="7" rx="1"/>
        <rect fill="#000000" x="11" y="7" width="2" height="2" rx="1"/>
    </g>
</svg></span>
                <Modal.Title className='text-center mb-4'>Include E-mail</Modal.Title>
                  <p className='mb-0 text-center'>This exact message will be emailed to each of the selected student's primary email contacts. Are you sure you wish to continue?</p> 
                </Modal.Body>
                <Modal.Footer className='border-0 justify-content-center pt-0'>
                    <button type="button" class="btn btn-success btn-sm" onClick={() => props.setIncludeModalVlaue(true)}> Yes</button>
                    <button type="button" class="btn btn-danger btn-sm" onClick={() => props.setIncludeModalVlaue(false)}>No</button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default IncludeEmailModal;