
import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";


const ReturnApprovalModal = (props) => {
    const dispatch = useDispatch()
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        setShowModal(props.return_approval_modal)
    }, [])
    const closeModal = () => {
        props.hideReturnStudentModal(false);
    };

    const handleSubmit = () => {
        const form_data = {
            border_leave_id: props.boarerLeaveData.stu_leave_data[0]?.border_leave_id,
            return_status: 'returned',
            border_leave_return_stu_id: props.boarerLeaveData?.stu_leave_data[0]?.return_students_data?.border_leave_return_stu_id,
            student_id: props.boarerLeaveData.student_id
        }
        props.SnapshotReturnStuLeave(form_data);
    };
    
    return (
        <>
            <Modal show={showModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Return Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">Are you sure you want to Return this student?</Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button variant="secondary" className="btn-sm" onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" className="px-10 btn-sm" onClick={handleSubmit}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};


export default ReturnApprovalModal;
