import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";



const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" :user_info?.school_code;
function ApprovedHostModal({ onApproveHost,host,user_uuid,student_host_comment }) {
    console.log("student_host_comment",student_host_comment);
    const navigate = useNavigate();
    const [comment,setComment] = useState("")
    const onChangeHandler = (e) => {
        setComment(e.target.value)
    }
    useEffect(()=>{
        setComment(student_host_comment)
    },[])
    const SubmitHandler = (e) => {
        e.preventDefault();
        const form_data = {}
        form_data['student_host_comment'] = comment;
        form_data['is_host_approved'] = true;
        form_data['student_host_uuid'] = host.student_host_uuid;
        onApproveHost(form_data).then((response) => {
            if (response.success === true) {
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                setTimeout(() => {
                    navigate(`/${school_code}/student/student-list/student-profile/${user_uuid}`)
                }, 500);
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }

        });
    }
    return (
        <>
            <div className='d-flex mb-5 justify-content-end'>
            <button type="button" class="btn btn-sm btn-success min-150px" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Approve Host</button>

            </div>
            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header ">
                            <div className='d-flex flex-column justify-content-center align-items-center'>
                                <h5 class="modal-title" id="staticBackdropLabel">You are Approving this Host for this Student.</h5>
                                <p className="" style={{ fontWeight: "", fontSize: "12px" }}>As Manager you may add relevant comments to this Host/Student arrangement now. They will display when Student requests Leave with this Host</p>
                            </div>

                        </div>
                        <form onSubmit={SubmitHandler}>
                            
                            <div class="modal-body">
                                <div class="text-center py-5">
                                    <textarea value={comment} onChange={(e)=>onChangeHandler(e)} style={{ minWidth: "100%" }}></textarea>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">Approved</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ApprovedHostModal;