
import React, { useState, useEffect, useCallback } from "react";
import * as userActions from "../../../actions/index";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";



const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" :user_info.school_code;

const BlinkingDot = (props) => {
    const [isBlinking, setIsBlinking] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // console.log("props", props)
    // useEffect(() => {
    //     setIsBlinking(showBlink);
    // }, [showBlink])


    const GetAllSnapshotStudents = useCallback(async () => {
        dispatch(userActions.GetAllSnapshotStudents()).then((response) => {
            if (response.success === true) {
                const arrayData = response.data.rows;

            } else if (response.status === 401) {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
            else {
            }
        });
        // eslint-disable-next-line
    }, [dispatch]);



    const handleBlink = (e) => {
        // console.log("props", props)
        // console.log("status in blinking dot  change", e?.target.id)
        // console.log("e.target.value", e.target)
        let student_ids;

        student_ids = props?.id;

        const Payload = {
            student_id: student_ids
        }

        props.onUpdateSnapshotRollCallCompareStatus(Payload).then((response) => {
            if (response.success === true) {

                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                props.navigateToSnapshot();
                GetAllSnapshotStudents();
                setTimeout(() => {
                    navigate(`/${school_code}/snapshot`, { replace: true });
                }, 50);

            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });

    }



    useEffect(() => {
        const interval = setInterval(() => {
            setIsBlinking((prevState) => !prevState);
        }, 500)

        return () => clearInterval(interval);
    }, [])

    const dotStyle = {
        width: '15px',
        height: '15px',
        borderRadius: '100%',
        backgroundColor: isBlinking ? '#FFBF00' : '#FFF5EE',
        // animation: 'blinking is infinite',
    }

    return (
        <>
            <Tooltip title="To turn off the amber light, just click on it or change the status from the menu." placement="top">
                <div style={dotStyle} onClick={handleBlink}></div>
            </Tooltip>
        </>
    )

}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {

    onExportStudentsCsv: userActions.ExportStudentsCsv,
    onUpdateStudentStatus: userActions.UpdateStudentStatus,
    onExportStudentsPdf: userActions.ExportStudentsPdf,
    onCreateStudentsConductRollCall: userActions.CreateStudentsConductRollCall,
    onExportStudentLocationHistoryPdf: userActions.ExportStudentLocationHistoryPdf,
    onSignInStudentReasonOrOncampus: userActions.SignInStudentReasonOrOncampus,
    onSignOutStudentReasonOrOncampus: userActions.SignOutStudentReasonOrOncampus,
    onUpdateSnapshotRollCallCompareStatus: userActions.UpdateSnapshotRollCallCompareStatus,


};
export default connect(mapStateToProps, mapDispatchToProps)(BlinkingDot);