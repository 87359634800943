import axios from "axios";
import * as actionType from "../constants/actionTypes";
let qs = require("qs");


/*** CreateEventCalendar Action ***/
export const CreateEventCalendarStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.CreateEventCalendar_START,
    };
};
export const CreateEventCalendarSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.CreateEventCalendar_SUCCESS,
    };
};
export const CreateEventCalendarFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.CreateEventCalendar_FAIL,
    };
};
export const CreateEventCalendar = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    
    return (dispatch) => {
        dispatch(CreateEventCalendarStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info?.country_host ,
                school_code: user_info?.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/CreateEventCalendar`,
        })
            .then(function (response) {
                dispatch(CreateEventCalendarSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateEventCalendarFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** GetAllEventCalendarDetails Action ***/
export const GetAllEventCalendarDetailsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllEventCalendarDetails_START,
    };
};
export const GetAllEventCalendarDetailsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllEventCalendarDetails_SUCCESS,
    };
};
export const GetAllEventCalendarDetailsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllEventCalendarDetails_FAIL,
    };
};
export const GetAllEventCalendarDetails = () => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllEventCalendarDetailsStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info?.country_host ,
                school_code: user_info?.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllEventCalendarDetails`,
            params: {
               
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllEventCalendarDetailsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllEventCalendarDetailsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** UpdateEventCalendarDetails Action ***/

export const UpdateEventCalendarDetailsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateEventCalendarDetails_START,
    };
};
export const UpdateEventCalendarDetailsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateEventCalendarDetails_SUCCESS,
    };
};
export const UpdateEventCalendarDetailsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateEventCalendarDetails_FAIL,
    };
};

export const UpdateEventCalendarDetails = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(UpdateEventCalendarDetailsStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info?.country_host ,
                school_code: user_info?.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/UpdateEventCalendarDetails`,
        })
            .then(function (response) {
                dispatch(UpdateEventCalendarDetailsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateEventCalendarDetailsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};




/*** DeleteEventCalendarDetails Action ***/

export const DeleteEventCalendarDetailsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.DeleteEventCalendarDetails_START,
    };
};
export const DeleteEventCalendarDetailsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.DeleteEventCalendarDetails_SUCCESS,
    };
};
export const DeleteEventCalendarDetailsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.DeleteEventCalendarDetails_FAIL,
    };
};
export const DeleteEventCalendarDetails = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(DeleteEventCalendarDetailsStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "delete",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info?.country_host ,
                school_code: user_info?.school_code,
                
            },
            url: `${actionType.environment_url}/api/bss-admin/DeleteEventCalendarDetails`,
        })
            .then(function (response) {
                console.log("resposne", response.data);
                dispatch(DeleteEventCalendarDetailsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                console.log("resposne fail", error);
                dispatch(DeleteEventCalendarDetailsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

