import React from "react";
import { Navigate } from "react-router-dom";
import * as actions from "./actions";
import school from "./component/School/school";

const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" :user_info?.school_code;
const AuthRouter = ({ children }) => {
    console.log("school_code",children);
    if (actions.isAuthorize()) {
        if(school_code !== null){
            return <Navigate to={`${school_code}/dashboard`} />;
        }else{
            return <Navigate to={`/dashboard`} />;
        }
    } else {
        return children;
    }
};
export default AuthRouter;