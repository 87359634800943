import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import Footer from "../../Constant/Footer";
import SuperAdminTopbar from "../topbar";
import SuperAdminSideMenu from "../Sidemenu";

import CountryItem from "./CountryItem";
import AddCountryModal from "./Modal/AddCountryModal"
import { toast } from "react-toastify";

import * as userActions from "../../../actions/index";

import DeleteConfirmRemove from "../../Utility/DeleteConfirmRemove";

// export default function SuperAdminCountry() {
const CountryList = (props) => {

    const [confirm_modal, setconfirm_modal] = useState(false);
    const [editconfirmremovedata, seteditconfirmremovedata] = useState([]);

    const dispatch = useDispatch();
    const [Payments, setPayments] = useState([]);
    const [Paymentscount, setPaymentscount] = useState(0);

    const [filterlimit, setfilterlimit] = useState(10);
    const [filterpage, setfilterpage] = useState(1);
    const [Getallcountry, setallcountry] = useState([]);
    const [Getallcountryregion, setallcountryregion] = useState([]);

    const [add_country_modal, setadd_country_modal] = useState(false);
    const [filterorder, setfilterorder] = useState("asc");
    const [filtersort, setfiltersort] = useState("country_id");
    const [filtersearch, setfiltersearch] = useState(null);
    // const [delete_country_modal, setdelete_country_modal] = useState(false);
    // const [deletecountrydata, setdeletecountrydata] = useState('');

    const showAddModalHandle = (event) => {
        setconfirm_modal(false);
    };
    const RemoveCourseModule = (event, course_data) => {
        seteditconfirmremovedata(course_data);
        setconfirm_modal(true);
    };

    const ConfirmRemove = (form_data) => {
        
        const payload = {
            'country_id': form_data.country_id,
            'is_selected': false,
        }
        // setconfirm_modal(false);
        props.onSelectCountry(payload).then((response) => {
            if (response.success === true) {
                GetAllFreeCountry();
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                setconfirm_modal(false);
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    };

 

    const GetAllFreeCountry = useCallback(async () => {
        dispatch(userActions.GetAllSelectedCountries(filterpage, filterlimit, filtersort, filterorder, filtersearch)).then((response) => {
            if (response.success === true) {
                const arrayData = response.data.rows;
                let MemberId_level = [];
                const sumvalue = 1;
                for (let key in arrayData) {
                    const perpage = ((parseInt(filterpage) - parseInt(sumvalue)) * parseInt(filterlimit) + parseInt(key) + parseInt(sumvalue));

                    MemberId_level.push({
                        perpage: perpage,
                        country_id: arrayData[key].country_id,
                        name: arrayData[key].name,

                    });
                }
                setPayments(MemberId_level);
                setPaymentscount(response.data.count);
            } else {
            }
        });
    }, [dispatch, filterpage, filterlimit, filtersort, filterorder, filtersearch]);
   
   
   
    const GetAllCountries = useCallback(() => {
        userActions.GetAllCountries().then((response) => {
            if (response.success === true) {
                let country_level = [];
                country_level.push({
                    label: "Country",
                    value: "",
                });
                const arrayData = response.data;
                for (let key in arrayData) {
                    country_level.push({
                        label: arrayData[key].name,
                        value: arrayData[key].country_id,
                    });
                }
                setallcountry(country_level);
            } else {
            }
        });
    }, []);


    
    const GetAllCountryRegions = useCallback(() => {
        userActions.GetAllCountryRegions().then((response) => {
            if (response.success === true) {
                let country_level = [];
                country_level.push({
                    label: "Region",
                    value: "",
                });
                const arrayData = response.data;
                for (let key in arrayData) {
                    country_level.push({
                        label: arrayData[key].region_name,
                        value: arrayData[key].country_host
                        ,
                    });
                }
                setallcountryregion(country_level);
            } else {
            }
        });
    }, []);



    const AddCountrypoup = async (event) => {
        setadd_country_modal(true)
    }
    const AddCountrySubmit = async (form_data) => {
        props.onSelectCountry(form_data).then((response) => {
            if (response.success === true) {
                console.log(form_data);
                setadd_country_modal(false)
                GetAllFreeCountry();
                toast.success('Country added successfully.', {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }

        });
    }
    const showAddModalHandleCountry = async (event) => {
        setadd_country_modal(false)
    }
    

    useEffect(() => {
        GetAllFreeCountry();
        GetAllCountries();
        GetAllCountryRegions();
    }, [GetAllFreeCountry, GetAllCountries, GetAllCountryRegions ,filterpage, filterlimit, filtersort, filterorder, filtersearch]);

    return (
        <>
            {confirm_modal && (
                <DeleteConfirmRemove
                    title={`Delete Country?`}
                    bodytitle="Are you sure you want to delete this Country?"
                    editconfirmremovedata={editconfirmremovedata}
                    confirm_modal={confirm_modal}
                    confirmButtonText="Delete"
                    ConfirmRemove={(event) => ConfirmRemove(event)}
                    delete_confirm_modal_action={(modal_action) => showAddModalHandle(modal_action)}
                />
            )}
            {add_country_modal &&
                <AddCountryModal
                    Getallcountry={Getallcountry}
                    Getallcountryregion={Getallcountryregion}
                    add_country_modal={add_country_modal}
                    AddCountrySubmit={(event) => AddCountrySubmit(event)}
                    add_country_modal_action={(modal_action) => showAddModalHandleCountry(modal_action)}
                />
            }
            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed" >
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <SuperAdminSideMenu />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <SuperAdminTopbar></SuperAdminTopbar>
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    {/* <!--begin::Container--> */}
                                    <div id="kt_content_container" className="container-fluid">
                                        <div className="tab-content" id="myTabContent">

                                            <div className="tab-pane fade show active" >
                                                <div className="row">
                                                    <div className="col-sm-8">
                                                        <div className="card tabs-outer">
                                                            <div className="card-header position-relative py-0 details-bg">
                                                                <h5 className="card-title text-gray-800 fw-bolder m-0">Country List</h5>
                                                                <div className="card-toolbar">
                                                                    <span className="btn btn-sm btn-primary apply-btn add-btns addschool-button"
                                                                        onClick={(event) => {
                                                                            AddCountrypoup(event);
                                                                        }}
                                                                    >Add Country</span>
                                                                </div>
                                                            </div>

                                                            <div className="card-body">

                                                                
                                                                <div className="card-toolbar border-bottom border-bottom-1 pb-4 mb-6">
                                                                    <div className="d-flex align-items-center justify-content-between">

                                                                        {/* <!-- <h4 className="card-title text-gray-800 fw-bolder m-0">Schools</h4> --> */}

                                                                        <div className="d-flex align-items-center position-relative">
                                                                            <span className="svg-icon svg-icon-1 position-absolute ms-4">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                                                                    <path
                                                                                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                                                                        fill="currentColor"
                                                                                    ></path>
                                                                                </svg>
                                                                            </span>

                                                                            <input type="text" className="form-control form-control-sm w-350px ps-12 search-out fs-6" placeholder="Search " style={{ background: "transparent" }}
                                                                            
                                                                            
                                                                                onChange={(event) => {
                                                                                    setfiltersearch(event.target.value);
                                                                                    setfilterpage(1);
                                                                                }}
                                                                             />
                                                                        </div>
                                                                    </div>
                                                                </div>



                                                                <div className="row">
                                                                    <div className="col-sm-12">

                                                                        {
                                                                            <CountryItem
                                                                                Getallcountry={Getallcountry}
                                                                                Getallcountryregion={Getallcountryregion}
                                                                                Paymentscount={Paymentscount}
                                                                                data={Payments}
                                                                                filterlimit={filterlimit}
                                                                                filterpage={filterpage}
                                                                                RemoveCourseModule={(event, data) => RemoveCourseModule(event, data)}

                                                                                propsfilterpage={(event) => setfilterpage(event)}
                                                                                propsfilterlimit={(filterlimit) => {
                                                                                    setfilterlimit(filterlimit);
                                                                                }}
                                                                                />
                                                                        }



                                                                    </div>
                                                                </div>


                                                            


                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="col-sm-4">
                                                        <div className="card ">
                                                            <div className="card-header border-bottom border-bottom-1">
                                                                <h3 className="card-title align-items-start flex-column">
                                                                    <span className="card-label fw-bold text-dark">Filters</span>
                                                                </h3>
                                                            </div>

                                                            <div className="card-body p-6">
                                                                <div className="d-flex flex-column">
                                                                    <h5>Sort By</h5>
                                                                    <div className="d-flex">
                                                                        <span 
                                                                        
                                                                        className="btn btn-primary active-filter filter-badge me-2 filter-button"
                                                                            
                                                                            
                                                                            onClick={(event) => {
                                                                                if (filterorder === 'desc'){
                                                                                    setfilterorder('asc');
                                                                                }
                                                                                else{
                                                                                    setfilterorder('desc'); 
                                                                                }
                                                                                setfiltersort('name');                                                    
                                                                                setfilterpage(1);

                                                                            }}
                                                                        >Name</span>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!--end::Container--> */}
                                </div>
                            </div>
                            <Footer></Footer>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
    };
    const mapStateToProps = (state) => ({});
    const mapDispatchToProps = {
        onGetAllSelectedCountries: userActions.GetAllSelectedCountries,
        onSelectCountry: userActions.SelectCountry,
    };
    export default connect(mapStateToProps, mapDispatchToProps)(CountryList);