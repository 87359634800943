import React from "react";
import { Navigate } from "react-router-dom";
import * as actions from "./actions";

const ProtectedRouters = ({ children }) => {
    if (actions.isAuthorize()) {
        const user_info = JSON.parse(localStorage.getItem("bss_authuser"));


        if (user_info && user_info?.role_type === 1) {
            return <Navigate to="/superadmin/school" />;
        } else {
            return children
            
        }
        ;
    } else {
        const permission_info = JSON.parse(localStorage.getItem("permissions"));
        if (permission_info === null) {
      return children;
    }
    return <Navigate to="/" />;
  
    }
};
export default ProtectedRouters;