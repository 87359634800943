import React from "react";
import { Link, useLocation } from "react-router-dom";

function withParams(Component) {
    return props => <Component {...props} location={useLocation()} />;
}
const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" :user_info.school_code;

class Menu extends React.Component {
    render() {
        const { location } = this.props;

        {
            return (
                <>
                    <div className="mt-5">
                        <ul className="nav nav-tabs home-tabs">
                            <li className="nav-item me-6" >
                                <Link to={`/${school_code}/snapshot`} className={"nav-link" + 
                                 (location && location.pathname && (location.pathname === `/${school_code}/snapshot` || location.pathname === `/${school_code}/snapshot`   
                             ) ? " active" : "")}
                                 >
                                    Snapshot
                                </Link>
                            </li>
                            <li className="nav-item me-6" >
                                <Link to={`/${school_code}/rollcall-report`} className={"nav-link" + 
                                 (location && location.pathname && (location.pathname === `/${school_code}/rollcall-report` || location.pathname === `/${school_code}/rollcall-report`   
                             ) ? " active" : "")}
                                 >
                                    Rollcall Report
                                </Link>
                            </li>
                            <li className="nav-item me-6" >
                                <Link to={`/${school_code}/conduct-rollcall`} className={"nav-link" + 
                                 (location && location.pathname && (location.pathname === `/${school_code}/conduct-rollcall` || location.pathname === `/${school_code}/conduct-rollcall`   
                             ) ? " active" : "")}
                                 >
                                    Conduct Rollcall
                                </Link>
                            </li>
                        </ul>
                    </div>
                </>
            );
        }
    }
}
export default withParams(Menu);