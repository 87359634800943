import React from "react";
import Validations from "../../Utility/Validations";
import * as userActions from "../../../actions/index";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";
import { Navigate } from "react-router-dom";
import SideBarLeave from "../../Constant/SideBarLeave";


const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info.school_code;
class AddModeOfTravel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            add_mode_of_travel: {
                travel_mode_name: {
                    type: "text",
                    label: "Mode of Travel",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
          
            },

        };
    }





    inputChangeHandler(event, identifier) {
        const update_add_mode_of_travel = { ...this.state.add_mode_of_travel };
        const form_element = { ...update_add_mode_of_travel[identifier] };
        form_element.value = event.target.value;
        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_add_mode_of_travel[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_add_mode_of_travel) {
            is_form_valid = update_add_mode_of_travel[identifier].valid && is_form_valid;
        }
        this.setState({
            add_mode_of_travel: update_add_mode_of_travel,
            is_form_valid: is_form_valid,
        });
    }




    SubmitHandler = async (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_add_mode_of_travel = this.state.add_mode_of_travel;
        for (let key in update_add_mode_of_travel) {
            let form_element = update_add_mode_of_travel[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_add_mode_of_travel[key] = form_element;
        }
        this.setState({ add_mode_of_travel: update_add_mode_of_travel });
        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.add_mode_of_travel) {
                form_data[key] = this.state.add_mode_of_travel[key].value;
            }
            console.log("form_data===", form_data)

            this.props.onCreateTravelMode(form_data).then((response) => {
                if (response.success === true) {
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: `/${school_code}/leave/manage-mode-of-travels`,
                        });
                    }, 1000);
                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            });
        }

    };
    render() {

        const { add_mode_of_travel } = this.state;
        console.log(add_mode_of_travel);
        return (
            <React.Fragment>
                {this.state.isAuthenticated ? <Navigate to={this.state.redirect_url} /> : null}

                <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed" >
                    <div className="d-flex flex-column flex-root">
                        <div className="page d-flex flex-row flex-column-fluid">
                          <SideBarLeave />
                            <div className="wrapper d-flex flex-column flex-row-fluid " id="kt_wrapper">
                                <TopBar />
                                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">

                                <div className="post d-flex flex-column-fluid" id="kt_post">

                                    <div id="kt_content_container" className="container-fluid">
                                        <div className="row center-wrap">
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="card-header border-bottom pt-5 pb-5">
                                                    <h3 className="card-title align-items-start flex-column">
                                                        <span className="card-label fw-bolder fs-3 mb-1">Add Mode of Travel</span>
                                                    </h3>
                                                  
                                                </div>
                                              <div className="card-body">
                                                <form className="form w-100" onSubmit={this.SubmitHandler}>
                                       
                                               
                                                            <div className="row">


                                                                <div className="col-lg-12 fv-row">
                                                                    <div className="d-flex flex-column align-items-center">
                                                                    <label className="fw-bold  white-space-nowrap mb-5 d-flex w-100">{add_mode_of_travel.travel_mode_name.label}</label>

                                                                    <input
                                                                        type={add_mode_of_travel.travel_mode_name.type}
                                                                        value={add_mode_of_travel.travel_mode_name.value ? add_mode_of_travel.travel_mode_name.value : ""}
                                                                        className="form-control"
                                                                        placeholder={add_mode_of_travel.travel_mode_name.placeholder}
                                                                        onChange={(event) => this.inputChangeHandler(event, "travel_mode_name")}
                                                                    />

                                                                    {!add_mode_of_travel.travel_mode_name.valid && add_mode_of_travel.travel_mode_name.onBlur_out ? <div className="error field-error">{add_mode_of_travel.travel_mode_name.error_msg}</div> : ""}
                                                                    <button type="submit" className="btn btn-sm btn-primary mt-5">
                                                                        Save
                                                                    </button>
                                                                </div>
                                                                </div>
                                                            </div>
                                                </form>

                                                </div>

                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}

const mapDispatchToProps = {
    onCreateTravelMode: userActions.CreateTravelMode,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddModeOfTravel);
