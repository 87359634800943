import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Sidebar from "../Constant/sidebar";
import Footer from "../Constant/Footer";
import TopBar from "../Constant/TopBar";
import { useNavigate } from "react-router-dom";
import * as userActions from "../../actions/index";
import moment from "moment";
import "moment-timezone";

const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info.school_code;
function ManagerPermHistory(props) {

    const [permissionHistory, setPermissionHistory] = useState([]);
    const [user_id, setUser_id] = useState();
    const [user_uuid, setUser_uuid] = useState([]);
    const [manager_Name, setManger_name] = useState("")
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const user_id = JSON.parse(localStorage.getItem("user_id"));
        const user_uuid = JSON.parse(localStorage.getItem("user_uuid"));
        setUser_id(user_id);
        setUser_uuid(user_uuid);
        if (user_id !== undefined) {
            GetManagerPermissonHistoryById();
        }
    }, [user_id])
    useEffect(() => {
        GetSchoolManagerById()
    }, [user_uuid])


    const GetSchoolManagerById = () => {
        props.onGetSchoolManagerById(user_uuid).then((response) => {
            if (response.success === true) {
                let manager_data = response.data;
                setManger_name(manager_data.first_name + " " + manager_data.last_name)
            } else {
            }
        });

    }

    const GetManagerPermissonHistoryById = useCallback(async () => {
        dispatch(userActions.GetManagerPermissonHistoryById(user_id)).then((response) => {
            if (response.success === true) {
                const arrayData = response.data;
                let historyData = [];
                for (let key in arrayData) {
                    const current = new Date(arrayData[key].created_date);
                    const timezone = user_info.user_school.timezone;
                    const created = moment(current).tz(timezone).format('MMMM Do YYYY, h:mm a');
                    const dormdata = (typeof (arrayData[key].permission_history_info.dorm_data) === 'string') ? JSON.parse(arrayData[key].permission_history_info.dorm_data) : arrayData[key].permission_history_info.dorm_data
                    const classdata = (typeof (arrayData[key].permission_history_info.class_data) === 'string') ? JSON.parse(arrayData[key].permission_history_info.class_data) : arrayData[key].permission_history_info.class_data
                    historyData.push({
                        created_date: created,
                        permissions: arrayData[key].permission_history_info,
                        dorm_data: dormdata,
                        class_data: classdata
                    });
                }
                setPermissionHistory(historyData);
            } else if (response.status === 401) {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
            }
        });
        // eslint-disable-next-line
    }, [dispatch, user_id]);

    return (
        <>
            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <Sidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-fluid">
                                        <ul className="nav nav-tabs home-tabs" id="myTab" role="tablist">
                                            <li className="nav-item me-6" role="presentation">
                                                <Link to={`/${school_code}/manager-list/manager-profile/${user_uuid}`} className="nav-link" id="ManagerProfile-tab" type="button" aria-controls="home" aria-selected="true">
                                                    Manager Profile
                                                </Link>
                                            </li>

                                            <li className="nav-item me-6" role="presentation">
                                                <Link to={`/${school_code}/manager-list/manager-history`} className="nav-link active" id="PermissionHistory-tab" type="button" aria-controls="process" aria-selected="false">
                                                    Permissions History
                                                </Link>
                                            </li>
                                        </ul>

                                        <div className="row gx-5 gx-xl-10">
                                            {/* <div className="col-xl-12">
                                                <h4 className="card-title text-gray-800 fw-bolder m-0">Historical Permissions</h4>
                                            </div> */}

                                            <div className="col-xl-12">
                                                <div className="card  tabs-outer">
                                                    <div className="card-header position-relative py-0 details-bg">
                                                        {/* <!--begin::Card title--> */}
                                                        {/* <h2 className="card-title  m-0">Historical Permissions</h2>
                                                        <div className="d-flex align-items-center">
                                                            <span>&nbsp;Your are currently viewing :&nbsp;</span>
                                                            <span className="card-title m-0">{"  " + manager_Name}</span>
                                                        </div> */}
                                                        <div className="d-flex align-items-center">
                                                            <h3 className="mb-0">Historical Permissions</h3>
                                                            <span className="d-none d-lg-block bullet h-20px w-1px bg-black mx-4"></span>
                                                            <span>&nbsp;Your are currently viewing :&nbsp;</span>
                                                            <span className="card-title m-0">{manager_Name}</span>
                                                        </div>
                                                    </div>

                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="table-responsive common-table mt-4">
                                                                {/* <!--begin::Table--> */}
                                                                <table className="table table-row-bordered align-middle gs-0 gy-2 my-0 history-out">
                                                                    {/* <!--begin::Table head--> */}
                                                                    <thead>
                                                                        <tr className="fw-bolder">
                                                                            <th className="pb-3">Date</th>
                                                                            {/* <th className="pb-3">Permission Name</th> */}
                                                                            <th className="pb-3 pe-12">Permissions</th>
                                                                            <th className="pb-3 pe-12">Dormitories</th>
                                                                            <th className="pb-3 pe-12">Classes</th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        {permissionHistory && permissionHistory.map((historyItem, index) => (
                                                                            <tr key={index}>
                                                                                <td>{historyItem.created_date}</td>
                                                                                {/* <td>{historyItem.updated_date}</td> */}
                                                                                <td>
                                                                                    <div className="d-flex flex-wrap">
                                                                                        {Object.entries(historyItem.permissions).map(([permissionName, permissionValue]) => (

                                                                                            !["user_id", "dorm_data", "class_data", "created_by", "updated_by", "deleted_by",].includes(permissionName) && (
                                                                                                <span className={`badge badge-light me-1 ${typeof (permissionValue) === 'boolean' ? (permissionValue ? 'badge-success' : 'badge-danger') : (permissionValue == "true" ? 'badge-success' : 'badge-danger')}`} key={permissionName}>
                                                                                                    {permissionName.replace(/_/g, ' ')}
                                                                                                </span>
                                                                                            )
                                                                                        ))}
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    {Array.isArray(historyItem.dorm_data) ? (
                                                                                        historyItem.dorm_data.length !== 0 ?
                                                                                            historyItem.dorm_data.map((dorm) => (
                                                                                                <span className="badge badge-primary me-1" key={dorm.dorm_id}>
                                                                                                    {dorm.dormitory_name}
                                                                                                </span>
                                                                                            ))
                                                                                            : null) : null}

                                                                                </td>
                                                                                <td>
                                                                                    {Array.isArray(historyItem.class_data) ? (
                                                                                        historyItem.class_data.length !== 0 ?
                                                                                            historyItem.class_data.map((standard) => (
                                                                                                <span className="badge badge-primary me-1" key={standard.class_id}>
                                                                                                    {standard.class_name}
                                                                                                </span>
                                                                                            ))
                                                                                            : null) : null}
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>

                                                                <div className="d-flex flex-stack justify-content-end flex-wrap pt-3 pagination-main">
                                                                    <div className="pagination_limt">
                                                                        <select size="1" id="select" name="group" className="form-control input-sm form-select form-select-sm form-select-solid">
                                                                            <option value="10">10</option>
                                                                            <option value="25">25</option>
                                                                            <option value="50">50</option>
                                                                            <option value="100">100</option>
                                                                            <option value="250">250</option>
                                                                            <option value="500">500</option>
                                                                        </select>
                                                                        <div className="count_number ms-2">Displaying 1-10 of 100 records</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
    onGetManagerPermissonHistoryById: userActions.GetManagerPermissonHistoryById,
    onGetSchoolManagerById: userActions.GetSchoolManagerById,

};
export default connect(mapStateToProps, mapDispatchToProps)(ManagerPermHistory);