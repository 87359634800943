import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

function HostModal(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [remark_boarding, setremark_bording] = useState('')
    const navigate = useNavigate();
    const remarkBoarding = (event) => {
        setremark_bording(event.target.value)
    }

    const hostApproveHanler = () => {
        const form_data = {};
        const approved_host_data = { ...props.item }
        form_data['remark_boarding'] = remark_boarding
        form_data['host_uuid'] = approved_host_data['host_uuid'];
        form_data['is_host_approved'] = true;
        props.onApproveHost(form_data).then((response) => {
            if (response.success === true) {
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                setTimeout(() => {
                    navigate("/leave")
                }, 100);
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    }

    return (
        <>
            <Button variant="primary" size="sm" onClick={handleShow}>Unapproved</Button>
           
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Approved Host</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Add Remark Barding</Form.Label>
                            <Form.Control as="textarea" rows={3} onChange={(event) => remarkBoarding(event)} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className='btn-sm' onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" className='btn-sm' onClick={() => hostApproveHanler()}>Understood</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default HostModal;