import React from "react";

import Validations from "../../Utility/Validations";
import Sidebar from "../../Constant/sidebar";
import Footer from "../../Constant/Footer";
import TopBar from "../../Constant/TopBar";
import { toast } from "react-toastify";
import * as userActions from "../../../actions/index";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" :user_info.school_code;

class CustomLinkEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            custom_link_edit_form: {
                custom_link_title: {
                    type: "text",
                    label: "Link Title *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Link Title",
                    validations: { required: true },
                },
                custom_link_url: {
                    type: "text",
                    label: "Url *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "custom_link_url",
                    validations: { required: true },
                },
                custom_link_uuid: {
                    type: "text",
                    label: " custom_link_uuid",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: false },
                },
            },
        };
    }

    componentDidMount() {
        this.GetCustomLinkById();
    }

    GetCustomLinkById() {
        this.props.onGetCustomLinkById(this.props.custom_link_uuid).then((response) => {
            if (response.success === true) {
                let message_data = response.data;
                const update_custom_link_edit_form = { ...this.state.custom_link_edit_form };
                for (let key in update_custom_link_edit_form) {
                    let form_element = update_custom_link_edit_form[key];
                    if (message_data[key]) {
                        update_custom_link_edit_form['custom_link_title'].value = message_data.custom_link_title;
                        update_custom_link_edit_form['custom_link_url'].value = message_data.custom_link_url;
                        update_custom_link_edit_form['custom_link_uuid'].value = message_data.custom_link_uuid;
                        this.setState({ custom_link_edit_form: update_custom_link_edit_form });
                        update_custom_link_edit_form[key] = form_element;
                    }
                }
                this.setState({ custom_link_edit_form: update_custom_link_edit_form });
            } else {
            }
        });

    }

    inputChangeHandler(event, identifier) {
        const update_custom_link_edit_form = { ...this.state.custom_link_edit_form };
        const form_element = { ...update_custom_link_edit_form[identifier] };
        form_element.value = event.target.value;
        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_custom_link_edit_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_custom_link_edit_form) {
            is_form_valid = update_custom_link_edit_form[identifier].valid && is_form_valid;
        }
        this.setState({
            custom_link_edit_form: update_custom_link_edit_form,
            is_form_valid: is_form_valid,
        });
    }

    handleChange(data, identifier, type) {
        const update_custom_link_edit_form = { ...this.state.custom_link_edit_form };
        update_custom_link_edit_form[identifier].value = data.value;
        this.setState({ custom_link_edit_form: update_custom_link_edit_form });
    }

    SubmitHandler = async (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_custom_link_edit_form = this.state.custom_link_edit_form;
        for (let key in update_custom_link_edit_form) {
            let form_element = update_custom_link_edit_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_custom_link_edit_form[key] = form_element;
        }
        this.setState({ custom_link_edit_form: update_custom_link_edit_form });
        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.custom_link_edit_form) {
                form_data[key] = this.state.custom_link_edit_form[key].value;
            }
            this.props.onUpdateCustomLink(form_data).then((response) => {
                if (response.success === true) {
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: `/${school_code}/settings/custom-link`,
                        });
                    }, 1000);
                } else {
                    console.log("error");
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }

            });
        }
    };
    render() {
        const { custom_link_edit_form } = this.state;

        return (
            <React.Fragment>
                {this.state.isAuthenticated ? <Navigate to={this.state.redirect_url} /> : null}
                <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed demo h-100">
                    <div className="d-flex flex-column flex-root h-100">
                        <div className="page d-flex flex-row flex-column-fluid">
                            <Sidebar></Sidebar>
                            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                                <TopBar></TopBar>
                                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                    <div className="post d-flex flex-column-fluid" id="kt_post">
                                        <div id="kt_content_container" className="container-fluid">
                                            <div className="school-edit-form ">
                                                <div className="" id="Snapshot">
                                                    <form onSubmit={this.SubmitHandler}>
                                                        <div className="card mb-5 tabs-outer">
                                                            <div className="card-header position-relative py-0 details-bg">
                                                                <h2 className="card-link_title  card-title m-0">Edit Custom Link</h2>
                                                            </div>

                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-lg-6 fv-row mb-10">
                                                                        <label className="fw-bold mb-2">{custom_link_edit_form.custom_link_title.label}</label>
                                                                        <input
                                                                            type={custom_link_edit_form.custom_link_title.type}
                                                                            value={custom_link_edit_form.custom_link_title.value ? custom_link_edit_form.custom_link_title.value : ""}
                                                                            className="form-control"
                                                                            placeholder={custom_link_edit_form.custom_link_title.placeholder}
                                                                            onChange={(event) => this.inputChangeHandler(event, "custom_link_title")}
                                                                        />

                                                                        {!custom_link_edit_form.custom_link_title.valid && custom_link_edit_form.custom_link_title.onBlur_out ? (
                                                                            <div className="error field-error">{custom_link_edit_form.custom_link_title.error_msg}</div>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>

                                                                    <div className="col-lg-6 fv-row mb-10">
                                                                        <label className="fw-bold mb-2">{custom_link_edit_form.custom_link_url.label}</label>
                                                                        <input
                                                                            type={custom_link_edit_form.custom_link_url.type}
                                                                            value={custom_link_edit_form.custom_link_url.value ? custom_link_edit_form.custom_link_url.value : ""}
                                                                            className="form-control"
                                                                            placeholder={custom_link_edit_form.custom_link_url.placeholder}
                                                                            onChange={(event) => this.inputChangeHandler(event, "custom_link_url")}
                                                                        />

                                                                        {!custom_link_edit_form.custom_link_url.valid && custom_link_edit_form.custom_link_url.onBlur_out ? <div className="error field-error">{custom_link_edit_form.custom_link_url.error_msg}</div> : ""}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        
                                                        <div className="col-sm-12 d-flex justify-content-end">
                                                            <div className="d-flex align-items-end">
                                                                <button type="submit" className="btn btn-sm btn-primary apply-btn min-150px addschool-button">
                                                                    Update Custom Link
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Footer />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}
const mapDispatchToProps = {

    onUpdateCustomLink: userActions.UpdateCustomLink,
    onGetCustomLinkById: userActions.GetCustomLinkById,


};
export default connect(mapStateToProps, mapDispatchToProps)(CustomLinkEdit);
