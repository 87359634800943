import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import React, { useState, useEffect, useCallback } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Footer from "../../Constant/Footer";
import Sidebar from "../../Constant/sidebar";
import TopBar from "../../Constant/TopBar";
import { toast } from "react-toastify";
import * as userActions from "../../../actions/index";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const locales = {
    "en-US": require("date-fns/locale/en-US"),
};

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});

const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" :user_info.school_code;

const permission_info = JSON.parse(localStorage.getItem("permissions"));
const schoolName = user_info?.user_school?.actual_school_code
var student_ids = '';


const CalenderWrap = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [perminssion, setPermission] = useState("")

    console.log("permission_info========", permission_info?.add_calendar);


    const [allEvents, setAllEvents] = useState([]);
    const [isEdiditng, setIsEditing] = useState(false);
    const [updateModal, setupdateModal] = useState(false);
    const [viewdataModal, setviewdataModal] = useState(false);

    const [event_descrption, setevent_descrption] = useState("");
    const [start_date, setstart_date] = useState("");
    const [end_date, setend_date] = useState("");
    const [event_calendar_uuid, setevent_calendar_uuid] = useState("");

    const UpdateEventsHandler = (event) => {

        setupdateModal(true);
        let event_descrption = event.title;
        setevent_descrption(event_descrption);
        let start_date = event.start;
        setstart_date(start_date);
        let end_date = event.end;
        setend_date(end_date);
        let event_calendar_uuid = event.event_calendar_uuid;
        setevent_calendar_uuid(event_calendar_uuid);
    };

    const hideUpdateModal = () => {
        setupdateModal(false);
        setevent_descrption("")
        setstart_date("")
        setend_date("")
    };

    const ViewEventsHandler = (event) => {
        setviewdataModal(true);
        let event_descrption = event.title;
        setevent_descrption(event_descrption);
        let start_date = event.start;
        setstart_date(start_date);
        let end_date = event.end;
        setend_date(end_date);
        let event_calendar_uuid = event.event_calendar_uuid;
        setevent_calendar_uuid(event_calendar_uuid);
    };

    const hideViewModal = () => {
        setupdateModal(true);
        setviewdataModal(false);

    };

    const hideViewModalCross = () => {
        setevent_descrption("")
        setstart_date("")
        setend_date("")
        setviewdataModal(false);

    };

    const startEdiitngHandler = () => {
        setIsEditing(true);
    };
    const hideModal = () => {
        setIsEditing(false);
        setevent_descrption("")
        setstart_date("")
        setend_date("")
    };


    const SubmitHandlerCreate = async () => {
        const Payload = {
            event_calendar_info: event_descrption,
            event_start: new Date(start_date),
            event_end: new Date(end_date),
        };
        props.onCreateEventCalendar(Payload).then((response) => {
            if (response.success === true) {
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                setevent_descrption("")
                setstart_date("")
                setend_date("")
                setIsEditing(false);
                GetAllEventCalendarDetails();

            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });

    }


    const SubmitHandlerUpdate = async () => {
        const Payload = {
            event_calendar_info: event_descrption,
            event_start: new Date(start_date),
            event_end: new Date(end_date),
            event_calendar_uuid: event_calendar_uuid,
        };
        props.onUpdateEventCalendarDetails(Payload).then((response) => {
            if (response.success === true) {

                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                setevent_descrption("")
                setstart_date("")
                setend_date("")
                GetAllEventCalendarDetails();
                setupdateModal(false);


            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    }


    const SubmitHandlerDelete = async () => {
        const Payload = {
            event_calendar_uuid: event_calendar_uuid,
        };
        props.onDeleteEventCalendarDetails(Payload).then((response) => {
            if (response.success === true) {

                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });

                GetAllEventCalendarDetails();
                setviewdataModal(false);
                setevent_descrption("")
                setstart_date("")
                setend_date("")



            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });

    }

    const GetAllEventCalendarDetails = useCallback(async () => {
        dispatch(userActions.GetAllEventCalendarDetails()).then((response) => {
            if (response.success === true) {
                const arrayData = response.data;
                let MemberId_level = [];

                for (let key in arrayData) {

                    MemberId_level.push({

                        title: arrayData[key].event_calendar_info,
                        start: new Date(arrayData[key].event_start),
                        end: new Date(arrayData[key].event_end),
                        event_calendar_uuid: arrayData[key].event_calendar_uuid

                    });
                    setAllEvents(MemberId_level);
                }
            }

            else if (response.status === 401) {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                setTimeout(() => {
                    navigate(`/${schoolName}/dashboard`)
                }, 5000);
            }
            else {
            }
        });
        // eslint-disable-next-line
    }, [dispatch]);

    const handleSelectSlot = ({ start, end, resourceId }) => {
        console.log("Selected", start, end);
        setIsEditing(true);
        setstart_date(start)
        setend_date(start)

        // writeToLocalStorageHere(start);
    }
    useEffect(() => {
        GetAllEventCalendarDetails();
        // setPermission(permission_info !== null ? permission_info : "")
    }, []);



    return (

        <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed" >
            <div className="d-flex flex-column flex-root" >
                <div className="page d-flex flex-row flex-column-fluid">
                    <Sidebar />
                    <div className="wrapper d-flex flex-column flex-row-fluid " id="kt_wrapper">
                        <TopBar />
                        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">

                        <div className="post d-flex flex-column-fluid" id="kt_post">

                            <div id="kt_content_container" className="container-fluid">

                                <div className="card">
                                    <div className="card-header align-items-center">
                                        <h2 className="card-title m-0">Calendar</h2>
                                        <div className="card-toolbar">
                                            {
                                                permission_info?.add_calendar !== false &&
                                                <button onClick={startEdiitngHandler} className="btn btn-flex btn-primary btn-sm" data-kt-calendar="add">

                                                    <span className="svg-icon svg-icon-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor" />
                                                            <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor" />
                                                        </svg>
                                                    </span>
                                                    Add Event
                                                </button>
                                            }
                                        </div>
                                    </div>

                                    <div className="card-body">
                                        {permission_info?.add_calendar !== false &&
                                            <>
                                                {isEdiditng && (
                                                    <div className="event-modal-view" >
                                                        <div className="modal-dialog modal-dialog-centered mw-650px">
                                                            <div className="modal-content">
                                                                <div className="modal-header">

                                                                    <h2 className="fw-bolder mb-0" data-kt-calendar="title ">Add a New Event</h2>
                                                                    <div className="btn btn-icon btn-sm btn-active-icon-primary" onClick={hideModal}>
                                                                        <span className="svg-icon svg-icon-1">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                                                                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                                                            </svg>
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <div className="modal-body">
                                                                    <div className="fv-row mb-5">
                                                                        <label className="fw-bold mb-2">Event Description</label>
                                                                        <input type="text" className="form-control form-control-solid" placeholder="" value={event_descrption}
                                                                            onChange={(e) => {
                                                                                setevent_descrption(e.target.value);
                                                                                console.log("VALUE", event_descrption);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="row date-wraper">
                                                                        <div className="col-lg-6">
                                                                            <label className="fw-bold mb-2 col-md-12">Start Date</label>

                                                                            <DatePicker
                                                                                placeholderText="Start Date"
                                                                                className="form-control form-control-solid w-100"
                                                                                selected={start_date}
                                                                                onChange={(date) => setstart_date(date)}
                                                                                locale="pt-BR"
                                                                                showTimeSelect
                                                                                timeFormat="p"
                                                                                timeIntervals={15}
                                                                                dateFormat="Pp"


                                                                            // selected={start_date}
                                                                            // onChange={(date) => setstart_date(date)}
                                                                            // showTimeSelect
                                                                            // timeFormat="HH:mm"
                                                                            // dateFormat="MMMM d, yyyy h:mm aa"
                                                                            />
                                                                        </div>
                                                                        <div className="col-lg-6">
                                                                            <label className="fw-bold mb-2 col-md-12">End Date</label>
                                                                            <DatePicker
                                                                                placeholderText="End Date"
                                                                                className="form-control form-control-solid w-100"
                                                                                selected={end_date}
                                                                                onChange={(date) => setend_date(date)}
                                                                                locale="pt-BR"
                                                                                showTimeSelect
                                                                                timeFormat="p"
                                                                                timeIntervals={15}
                                                                                dateFormat="Pp"
                                                                            // dateFormat="MM/dd/yyyy h:mm aa"
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="modal-footer flex-center mt-10">
                                                                        <button type="reset" id="kt_modal_add_event_cancel" className="btn btn-light me-3" onClick={hideModal}>Cancel</button>
                                                                        <button className="btn btn-primary" onClick={SubmitHandlerCreate}>
                                                                            Save
                                                                        </button>
                                                                    </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        }


                                            <>
                                                {updateModal && (
                                                    <div className="event-modal-view">
                                                        <div className="modal-dialog modal-dialog-centered mw-650px">
                                                            <div className="modal-content">
                                                                <div className="modal-header">

                                                                    <h2 className="fw-bolder mb-0" data-kt-calendar="title ">Edit event</h2>

                                                                    <div className="btn btn-icon btn-sm btn-active-icon-primary" onClick={hideUpdateModal}>

                                                                        <span className="svg-icon svg-icon-1">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                                                                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                                                            </svg>
                                                                        </span>

                                                                    </div>

                                                                </div>
                                                                <div className="modal-body">

                                                                    <div className="fv-row mb-9">
                                                                        <label className="fs-6 fw-bold mb-2">Event Description</label>
                                                                        <input type="text" className="form-control form-control-solid" placeholder="" value={event_descrption}
                                                                            onChange={(e) => {
                                                                                setevent_descrption(e.target.value);
                                                                                console.log("VALUE", event_descrption);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="d-flex w-100">
                                                                        <div className="pe-3 w-50">
                                                                            <label className="fs-6 fw-bold mb-2">Start Date</label>

                                                                            <DatePicker placeholderText="Start Date" className="form-control form-control-solid" selected={start_date}
                                                                                showTimeSelect
                                                                                dateFormat='dd/MM/yyyy h:mm aa'
                                                                                onChange={(e) => {
                                                                                    setstart_date(e);
                                                                                    console.log("VALUE", start_date);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="ps-3 w-50">
                                                                            <label className="fs-6 fw-bold mb-2">End Date</label>
                                                                            <DatePicker placeholderText="End Date" className="form-control form-control-solid" selected={end_date}
                                                                                showTimeSelect
                                                                                dateFormat='dd/MM/yyyy h:mm aa'
                                                                                onChange={(e) => {
                                                                                    setend_date(e);
                                                                                    console.log("VALUE", end_date);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="modal-footer flex-center mt-10">
                                                                        {/* <button id="kt_modal_add_event_cancel" className="btn btn-outline-danger me-3" onClick={SubmitHandlerDelete}>Delete</button> */}
                                                                        <button className="btn btn-primary" onClick={SubmitHandlerUpdate}>
                                                                            Save
                                                                        </button>
                                                                    </div>


                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        


                                        {viewdataModal && (
                                            <div className="event-modal-view">
                                                <div className="modal-dialog modal-dialog-centered mw-650px">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h2 className="fw-bolder mb-0" data-kt-calendar="title ">View event</h2>
                                                            <div className="btn btn-icon btn-sm btn-active-icon-primary" onClick={hideViewModalCross}>
                                                                <span className="svg-icon svg-icon-1">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                                                        <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                                                    </svg>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="modal-body">
                                                            <div className="fv-row mb-9">
                                                                <label className="fs-6 fw-bold mb-2">Event Description</label>
                                                                <input type="text" className="form-control form-control-solid" placeholder="" value={event_descrption}
                                                                    onChange={(e) => {
                                                                        setevent_descrption(e.target.value);
                                                                        console.log("VALUE", event_descrption);
                                                                    }}
                                                                    readOnly
                                                                />
                                                            </div>

                                                            <div className="d-flex w-100">
                                                                <div className="pe-3 w-50">
                                                                    <label className="fs-6 fw-bold mb-2">Start Date</label>
                                                                    <input type="text" className="form-control form-control-solid" value={moment(start_date).format("DD/MM/yyyy h:mm aa")}
                                                                        readOnly

                                                                    />
                                                                </div>

                                                                <div className="ps-3 w-50">
                                                                    <label className="fs-6 fw-bold mb-2">End Date</label>
                                                                    <input type="text" className="form-control form-control-solid" value={moment(end_date).format("DD/MM/yyyy h:mm aa")}
                                                                        readOnly
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="modal-footer flex-center mt-10">
                                                                <button id="kt_modal_add_event_cancel" className="btn btn-outline-danger me-3" onClick={SubmitHandlerDelete}>Delete</button>
                                                                {permission_info?.edit_calendar !== false &&
                                                                <button className="btn btn-primary" onClick={hideViewModal}>
                                                                    Edit
                                                                </button>}
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        )}


                                        <Calendar
                                            selectable
                                            localizer={localizer}
                                            events={allEvents}
                                            startAccessor="start"
                                            endAccessor="end"
                                            onSelectEvent={ViewEventsHandler}
                                            onSelectSlot={handleSelectSlot}
                                            style={{ height: 500 }}
                                        />



                                    </div>

                                </div>

                            </div>


                        </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </div>



    )
}
function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}
const mapDispatchToProps = {

    onCreateEventCalendar: userActions.CreateEventCalendar,
    onUpdateEventCalendarDetails: userActions.UpdateEventCalendarDetails,
    onDeleteEventCalendarDetails: userActions.DeleteEventCalendarDetails,


};
export default connect(mapStateToProps, mapDispatchToProps)(CalenderWrap);
