import React from "react";
import { Modal } from "react-bootstrap";
import Validations from "../../Utility/Validations";
import TextareaAutosize from 'react-autosize-textarea';


class AddOperationalComment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            operational_comment_form: {

                operational_desc: {
                    type: "text",
                    label: "Add Comment",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },

                diary_comment_type: {
                    type: "text",
                    label: "diary_comment_type",
                    value: "operational",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },

            },

        };
    }


    inputChangeHandler(event, identifier) {
        const update_operational_comment_form = { ...this.state.operational_comment_form };
        const form_element = { ...update_operational_comment_form[identifier] };
        form_element.value = event.target.value;
        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_operational_comment_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_operational_comment_form) {
            is_form_valid = update_operational_comment_form[identifier].valid && is_form_valid;
        }
        this.setState({
            operational_comment_form: update_operational_comment_form,
            is_form_valid: is_form_valid,
        });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_operational_comment_form = this.state.operational_comment_form;
        console.log(update_operational_comment_form);


        for (let key in update_operational_comment_form) {
            let form_element = update_operational_comment_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_operational_comment_form[key] = form_element;
        }
        this.setState({ operational_comment_form: update_operational_comment_form });

        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.operational_comment_form) {
                form_data[key] = this.state.operational_comment_form[key].value;
            }
            this.props.CreateOperationalComment(form_data);
            console.log("Form_data", form_data);
        }

    };

    render() {

        const { operational_comment_form } = this.state;
        console.log(operational_comment_form);
        return (
            <React.Fragment>
                <Modal
                    backdrop={true}
                    size="lg"
                    keyboard={false}
                    className="add_operational_comment_modal confirm_modal mw-480px"
                    show={this.props.add_operational_comment_modal}
                    onHide={() => {
                        this.props.add_operational_comment_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Add Operational Comments</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="form w-100" onSubmit={this.SubmitHandler}>
                            <div className="row">
                                <div className="col-lg-12 fv-row ">
                                    <div className="row">
                                        <div className="col-lg-12 fv-row mb-4">
                                            <div>
                                                <label className="fw-bold mb-2">{operational_comment_form.operational_desc.label}</label>
                                                <TextareaAutosize
                                                    minRows={1}
                                                    maxRows={10}
                                                    placeholder={operational_comment_form.operational_desc.placeholder}
                                                    value={operational_comment_form.operational_desc.value ? operational_comment_form.operational_desc.value : ""}
                                                    onChange={(event) => this.inputChangeHandler(event, "operational_desc")}
                                                    className="form-control min-h-100px"
                                                />
                                                {!operational_comment_form.operational_desc.valid && operational_comment_form.operational_desc.onBlur_out ? <div className="error field-error">{operational_comment_form.operational_desc.error_msg}</div> : ""}
                                            </div>
                                        </div>
                                        <div className="col-sm-12 mt-4 d-flex justify-content-center">
                                            <button type="submit" className="btn btn-sm btn-primary px-10">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default AddOperationalComment;
