import React from "react";
import { useLocation } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConductRollcall from "../component/Snapshot/ConductRollCall/ConductRollcall";
import RollCallReport from "../component/Snapshot/RollCallReport/RollCallReport";

import SnapshotList from "../component/Snapshot/snapshot/snapshot";
const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" :user_info?.school_code;

const Snapshot = (props) => {
    const Renderview = () => {
        let location = useLocation();
        if (location.pathname === `/${school_code}/snapshot` || location.pathname === `/${school_code}/snapshot/`) {
            return <SnapshotList />;
        }
        if (location.pathname === `/${school_code}/conduct-rollcall` || location.pathname === `/${school_code}/conduct-rollcall/`) {
            return <ConductRollcall />;
        }
        if (location.pathname === `/${school_code}/rollcall-report` || location.pathname === `/${school_code}/rollcall-report/`) {
            return <RollCallReport /> ;
        } 
        
    };

    return (
        <React.Fragment>
            <ToastContainer />
            {Renderview()}
        </React.Fragment>
    );
};
export default Snapshot;