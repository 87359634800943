import axios from "axios";
// import Cdate from "../component/Constant/date";
import * as actionType from "../constants/actionTypes";
let qs = require("qs");

/*** GetAllManagersBySchoolId Action ***/

export const GetAllManagersBySchoolIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllManagersBySchoolId_START,
    };
};
export const GetAllManagersBySchoolIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllManagersBySchoolId_SUCCESS,
    };
};
export const GetAllManagersBySchoolIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllManagersBySchoolId_FAIL,
    };
};
export const GetAllManagersBySchoolId = (page, limit, sort, order, is_user_activate,search,school_detail_id ) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllManagersBySchoolIdStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
                school_detail_id: user_info.school_detail_id,
            },

            url: `${actionType.environment_url}/api/bss-admin/GetAllManagersBySchoolId`,
            params: {
                page: page,
                limit: limit,
                sort: sort,
                order: order,
                search: search,
                is_user_activate: is_user_activate,
                school_detail_id: user_info.school_detail_id,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllManagersBySchoolIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllManagersBySchoolIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** GetSchoolManagerById Action ***/
export const GetSchoolManagerByIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetSchoolManagerById_START,
    };
};
export const GetSchoolManagerByIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetSchoolManagerById_SUCCESS,
    };
};
export const GetSchoolManagerByIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetSchoolManagerById_FAIL,
    };
};
export const GetSchoolManagerById = (user_uuid) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

   
    return (dispatch) => {
        dispatch(GetSchoolManagerByIdStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetSchoolManagerById`,
            params: {
                user_uuid: user_uuid,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetSchoolManagerByIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetSchoolManagerByIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};




/*** UpdateSchooManager Action ***/

export const UpdateSchooManagerStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateSchooManager_START,
    };
};
export const UpdateSchooManagerSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateSchooManager_SUCCESS,
    };
};
export const UpdateSchooManagerFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateSchooManager_FAIL,
    };
};

export const UpdateSchooManager = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(UpdateSchooManagerStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/UpdateSchooManager`,
        })
            .then(function (response) {
                dispatch(UpdateSchooManagerSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateSchooManagerFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** CreateSchoolManager Action ***/
/*** CreateSchoolManager Action ***/
export const CreateSchoolManagerStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.CreateSchoolManager_START,
    };
};
export const CreateSchoolManagerSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.CreateSchoolManager_SUCCESS,
    };
};
export const CreateSchoolManagerFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.CreateSchoolManager_FAIL,
    };
};
export const CreateSchoolManager = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    // console.log("====",user_info);
    // console.log("====",user_info.school_code);
 
    return (dispatch) => {
        dispatch(CreateSchoolManagerStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
       
        return axios({
            method: "post",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
                school_detail_id: user_info.school_detail_id
            },
            // body: JSON.stringify({
            //     country_host: user_info.country_host ,
            //     school_code: user_info.school_code,
            //     school_detail_id: user_info.school_detail_id,
            //   }),
            url: `${actionType.environment_url}/api/bss-admin/CreateSchoolManager`,
        })
            .then(function (response) {
                dispatch(CreateSchoolManagerSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateSchoolManagerFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** DeactivateManager Action ***/

export const ArchiveOrUnarchiveManagerStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.ArchiveOrUnarchiveManager_START,
    };
};

export const ArchiveOrUnarchiveManagerSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.ArchiveOrUnarchiveManager_SUCCESS,
    };
};

export const ArchiveOrUnarchiveManagerFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.ArchiveOrUnarchiveManager_FAIL,
    };
};

export const ArchiveOrUnarchiveManager = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(ArchiveOrUnarchiveManagerStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/ArchiveOrUnarchiveManager`,
        })
            .then(function (response) {
                dispatch(ArchiveOrUnarchiveManagerSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(ArchiveOrUnarchiveManagerFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** ExportManagersCsv Action ***/
export const ExportManagersCsvStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.ExportManagersCsv_START,
    };
};


export const ExportManagersCsvSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.ExportManagersCsv_SUCCESS,
    };
};


export const ExportManagersCsvFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.ExportManagersCsv_FAIL,
    };
};

export const ExportManagersCsv = (page, limit, sort, order, is_user_activate,search) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(ExportManagersCsvStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
                school_detail_id: user_info.school_detail_id,
            },

            url: `${actionType.environment_url}/api/bss-admin/ExportManagersCsv`,
            params: {
                page: page,
                limit: limit,
                sort: sort,
                order: order,
                search: search,
                is_user_activate: is_user_activate,
                // school_detail_id: user_info.school_detail_id,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(ExportManagersCsvSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(ExportManagersCsvFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** ExportManagersPdf Action ***/
export const ExportManagersPdfStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.ExportManagersPdf_START,
    };
};


export const ExportManagersPdfSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.ExportManagersPdf_SUCCESS,
    };
};


export const ExportManagersPdfFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.ExportManagersPdf_FAIL,
    };
};

export const ExportManagersPdf = (page, limit, sort, order, is_user_activate,search) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(ExportManagersPdfStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
                school_detail_id: user_info.school_detail_id,
            },

            url: `${actionType.environment_url}/api/bss-admin/ExportManagersPdf`,
            params: {
                page: page,
                limit: limit,
                sort: sort,
                order: order,
                search: search,
                is_user_activate: is_user_activate,
                // school_detail_id: user_info.school_detail_id,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(ExportManagersPdfSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(ExportManagersPdfFail("Something went wrong, Please try again."));
                return error;
            });
    };
};




/*** GetAllManagersListBySchoolId Action ***/
export const GetAllManagersListBySchoolIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllManagersListBySchoolId_START,
    };
};
export const GetAllManagersListBySchoolIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllManagersListBySchoolId_SUCCESS,
    };
};
export const GetAllManagersListBySchoolIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllManagersListBySchoolId_FAIL,
    };
};
export const GetAllManagersListBySchoolId = (school_detail_id) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

   
    return (dispatch) => {
        dispatch(GetAllManagersListBySchoolIdStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllManagersListBySchoolId`,
            params: {
                school_detail_id: user_info.school_detail_id,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllManagersListBySchoolIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllManagersListBySchoolIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};





/*** UpdateManagerPassword ***/

export const UpdateManagerPasswordStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateManagerPassword_START,
    };
};

export const UpdateManagerPasswordSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateManagerPassword_SUCCESS,
    };
};

export const UpdateManagerPasswordFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateManagerPassword_FAIL,
    };
};

export const UpdateManagerPassword = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(UpdateManagerPasswordStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/UpdateManagerPassword`,
        })
            .then(function (response) {
                dispatch(UpdateManagerPasswordSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateManagerPasswordFail("Something went wrong, Please try again."));
                return error;
            });
    };
};





/*** UpdateUserProfile Action ***/

export const UpdateUserProfileStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateUserProfile_START,
    };
};
export const UpdateUserProfileSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateUserProfile_SUCCESS,
    };
};
export const UpdateUserProfileFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateUserProfile_FAIL,
    };
};

export const UpdateUserProfile = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(UpdateUserProfileStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/UpdateUserProfile`,
        })
            .then(function (response) {
                dispatch(UpdateUserProfileSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateUserProfileFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



