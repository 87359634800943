import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { connect } from "react-redux";
import Select from 'react-select';
import moment from "moment";
import { toast } from "react-toastify";
import Alert from 'react-bootstrap/Alert';
import SideBarLeave from "../../Constant/SideBarLeave";
import Footer from "../../Constant/Footer";
import TopBar from "../../Constant/TopBar";
import Validations from "../../Utility/Validations";
import * as userActions from '../../../actions/index';
import AddStudentHostModal from '../LeaveModals/AddStudentHostModal'
import ApplyBorderLeaveModal from '../LeaveModals/ApplyBorderLeaveModal';
import { Link } from "react-router-dom";
import * as actionType from "../../../constants/actionTypes";
import DatePicker from "react-datepicker";
import { Tooltip } from "@mui/material";
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import BoaderLeaveDepartureTimeHandler from '../UniversalComponent/BoaderLeaveDepartureTimeHandler';
import BoarderLeaveReturnTimeHandeler from '../UniversalComponent/BoarderLeaveReturnTimeHandeler';
import Cdate from '../../Constant/date';
import { TailSpin } from 'react-loader-spinner'


const customStyles = {
   option: (provided, state) => ({
      ...provided,
      "&:hover": {
         backgroundColor: state.isFocused ? "#e7f5fe" : "",
      },
      backgroundColor: state.isSelected ? "#4a8fb8" : "",
   }),
};
const getDaysOfWeekNumber = (day) => {
   var week = {
      'sunday': 1,
      'monday': 2,
      'tuesday': 3,
      'wednesday': 4,
      'thursday': 5,
      'friday': 6,
      'saturday': 7,
      'default': 'Default item'
   };
   return (week[day.toLowerCase()] || week['default']);
}
const getNextWeekDateFromDay = (week_number) => {
   let d = moment();
   d.day(((week_number + 8 - d.day()) % 7) || 7);
   return d.format('YYYY-MM-DD');
}

const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code ?? null;
const ApplyBorderLeave = (props) => {
   const navigate = useNavigate();
   const textareaRefHost = useRef(null);
   const textareaRefParent = useRef(null);
   const textareaRefBoarding = useRef(null);
   let { weekend_day_from, weekend_time_from, weekend_time_to } = user_info.user_school
   const week_number = getDaysOfWeekNumber(weekend_day_from);
   weekend_day_from = getNextWeekDateFromDay(week_number);
   const weekend_day_to = moment(weekend_day_from).add(1, 'days').format('YYYY-MM-DD')

   console.log("Dsfdghfjhg", user_info.user_school);

   // states for design
   const [isAlertVisible, setIsAlertVisible] = useState(false);
   const [showModal, setShowModal] = useState(false);

   // states for functionality
   const [showLoader, setShowLoader] = useState(false)
   const [departure_mode_id, setDepartureModeId] = useState([])
   const [return_mode_id, setReturnModeId] = useState([])
   const [host_id, setHostId] = useState([])
   const [stuHost_id, setStuHost_id] = useState([])
   const [parent_id, setParentId] = useState([])
   const [leave_applied_ontime, setLeaveAppliedOnTime] = useState(true)


   const [showApplyLeaveModal, setShowApplyLeaveModal] = useState(false)
   const [showAddHostModal, setShowAddHostModal] = useState(false)
   const [leaveData, setLeaveData] = useState({})
   const [travelModeList, setTravelModeList] = useState([])
   const [hostList, setHostList] = useState([])

   const [hostData, setHostData] = useState() 

   const [applyBorderLeaveForm, setApplyBorderLeaveForm] = useState({
      student_avatar: {
         label: "File",
         type: "file",
         value: '',
         valid: true,
         error_msg: "",
         validations: { required: false },
         thumbnail_image: "/assets/media/school-logo.png",
      },
      student_id: {
         type: "hidden",
         label: "Student Id",
         value: "",
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "",
         validations: { required: false },

      },
      student_name: {
         type: "hidden",
         label: "Student Id",
         value: "",
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "",
         validations: { required: false },
      },
      preferred_name: {
         type: "hidden",
         label: "Preferred Name",
         value: "",
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "",
         validations: { required: false },
      },
      gender: {
         type: "hidden",
         label: "Student Id",
         value: "",
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "",
         validations: { required: false },
      },
      class_id: {
         type: "hidden",
         label: "Class Id",
         value: "",
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "",
         validations: { required: false },
      },
      class_name: {
         type: "hidden",
         label: "Class Id",
         value: "",
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "",
         validations: { required: false },
      },
      dormitory_id: {
         type: "hidden",
         label: "Dormitory Id",
         value: "",
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "",
         validations: { required: false },
      },
      dormitory_name: {
         type: "hidden",
         label: "Dormitory Id",
         value: "",
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "",
         validations: { required: false },
      },
      leave_applied_ontime: {
         type: "text",
         label: "Leave Applied For",
         value: "",
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "",
         validations: { required: false },
      },
      host_id: {
         type: "hidden",
         label: "Host Id",
         value: "",
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "",
         validations: { required: true },
         options: []
      },
      host_name: {
         type: "hidden",
         label: "Host Name",
         value: "",
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "",
         validations: { required: false },
      },
      host_email: {
         type: "hidden",
         label: "Host Name",
         value: "",
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "",
         validations: { required: false },
      },
      host_contact: {
         type: "hidden",
         label: "Host Contact",
         value: "",
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "",
         validations: { required: false },
      },
      host_address: {
         type: "hidden",
         label: "Host Contact",
         value: "",
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "",
         validations: { required: false },
      },
      host_relation: {
         type: "hidden",
         label: "Host Contact",
         value: "",
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "",
         validations: { required: false },
      },
      parent_id: {
         type: "hidden",
         label: "Parent Id",
         value: "",
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "",
         validations: { required: true },
         options: []
      },
      parent_type: {
         type: "hidden",
         label: "Parent Type",
         value: '',
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "",
         validations: { required: false },
      },
      remarks_by_host: {
         type: "text",
         label: "Remarks by Host",
         value: "",
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "Leave a comment here",
         validations: { required: false },
      },
      departure_date: {
         type: "date",
         label: "Departure Date",
         value: "",
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "Select Departure Date",
         validations: { required: true },
      },
      departure_time: {
         type: "time",
         label: "Departure Time",
         value: '',
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "Select Departure Time",
         validations: { required: true },
      },
      departure_mode_id: {
         type: "hidden",
         label: "Modes Of Travel For Departure",
         value: "",
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "",
         validations: { required: true },
         options: []
      },
      departure_mode_name: {
         type: "hidden",
         label: "Modes Of Travel For Departure",
         value: "",
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "",
         validations: { required: true },
         options: []
      },
      return_date: {
         type: "date",
         label: "Return Date",
         value: "",
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "Select Return Date",
         validations: { required: true },
      },
      return_time: {
         type: "time",
         label: "Return Time",
         value: '',
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "Select Return Time",
         validations: { required: true },
      },
      return_mode_id: {
         type: "hidden",
         label: "Modes Of Travel For Return ",
         value: "",
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "",
         validations: { required: true },
         options: []
      },
      return_mode_name: {
         type: "hidden",
         label: "Modes Of Travel For Return ",
         value: "",
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "",
         validations: { required: true },
         options: []
      },
      remarks_by_parent: {
         type: "text",
         label: "Remarks By Parent",
         value: '',
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "",
         validations: { required: false },
      },
      remarks_by_boarding: {
         type: "text",
         label: "Remarks By Boarding",
         value: '',
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "",
         validations: { required: false , min_length :10 },
      },
      parent_data: {
         first_point_email: "",
         father_name: "",
         mother_name: "",
         father_email: "",
         mother_email: ""
      }
   });


   useEffect(() => {
      GetAllTravelModes();
      GetStudentAndParentById()
   }, []);

   const GetStudentAndParentById = () => {
      props.onGetStudentAndParentById(props.user_uuid).then((response) => {
         if (response.success === true) {
            const studentHostIds = response?.data?.parent_data?.parents_as_host?.filter((item) => item.student_host_id).map((item) => item.student_host_id);
            setStuHost_id(studentHostIds);
            let student_data = response.data;
            const update_apply_border_leave_form = { ...applyBorderLeaveForm };
            update_apply_border_leave_form['student_name'].value = student_data.student_first_name + " " + student_data.student_last_name;
            update_apply_border_leave_form['student_id'].value = student_data.student_id;
            update_apply_border_leave_form['class_name'].value = student_data.class_name;
            update_apply_border_leave_form['class_id'].value = student_data.class_id;
            update_apply_border_leave_form['dormitory_name'].value = student_data.dormitory_data.dormitory_name;
            update_apply_border_leave_form['dormitory_id'].value = student_data.dormitory_id;
            update_apply_border_leave_form['student_avatar'].thumbnail_image = actionType.environment_url + '/' + student_data.student_avatar;
            update_apply_border_leave_form['student_avatar'].value = student_data.student_avatar;
            update_apply_border_leave_form['gender'].value = student_data.gender;
            update_apply_border_leave_form['preferred_name'].value = student_data.preferred_name;

            console.log('student_data=======================', student_data);

            update_apply_border_leave_form['parent_data'].first_point_email = student_data.first_point_email;
            update_apply_border_leave_form['parent_data'].father_email = student_data.parent_data.father_email;
            update_apply_border_leave_form['parent_data'].mother_email = student_data.parent_data.mother_email;
            update_apply_border_leave_form['parent_data'].father_name = student_data.parent_data.father_name;
            update_apply_border_leave_form['parent_data'].mother_name = student_data.parent_data.mother_name;
            setApplyBorderLeaveForm(update_apply_border_leave_form)
            GetApprovedStudentHost(student_data.student_id)
         } else {
         }
      });
   }

   const GetApprovedStudentHost = async (student_id) => {
      props.onGetApprovedStudentHost(student_id).then((response) => {
         if (response.success === true) {
            const host_data = response.data;
            const parentOptions = host_data.parent_data.parents_as_host.map(stu_host_data => {
               let parentType = stu_host_data.parent_type;
               let student_host_id = stu_host_data.student_host_id;
               let parentName = parentType === 'father' ? host_data.parent_data.father_name : host_data.parent_data.mother_name;
               let type = parentType === 'father' ? 'father' : 'mother';
               return {
                  label: parentName,
                  value: stu_host_data.parent_id,
                  type: type,
                  student_host_id: student_host_id
               };
            });
            console.log("host_data===============", host_data.stu_host_data);
            const hostOptions = host_data.stu_host_data.filter(stu_host_data => stu_host_data.stu_host.is_host_approved === true).map(stu_host_data => ({
               label: stu_host_data.host_name,
               value: stu_host_data.host_id,
               host_address: stu_host_data.host_address,
               host_contact: stu_host_data.host_contact,
               host_email: stu_host_data.host_email,
               host_relation: stu_host_data.stu_host.host_relation,
               type: 'host'
            }));
            setParentId(parentOptions);
            setHostId(hostOptions);
            console.log("Sdasdasd::", parentOptions)
            setHostList([
               { label: "Select  Host", value: "", type: 'none' },
               ...parentOptions,
               ...hostOptions
            ]);

         }
      });
   };

   const GetAllTravelModes = async () => {
      props.onGetAllTravelModes().then((response) => {
         let country_level = [];
         country_level.push({
            label: "Select Travel Modes",
            value: ""
         })
         if (response.success === true) {
            const arrayData = response.data.rows;
            for (let key in arrayData) {
               country_level.push({
                  label: arrayData[key].travel_mode_name,
                  value: arrayData[key].travel_mode_id,
               });
            }
         }
         setTravelModeList(country_level)
      });
   };

   function getCurrentDate() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
   }

   const checkAppliedLeaveTiming = (departure_date) => {
      const update_apply_border_leave_form = { ...applyBorderLeaveForm };
      const { cut_off_day, cut_off_time } = user_info.user_school;
      let hours = parseInt(cut_off_time.split(':')[0], 10)
      let minute = parseInt(cut_off_time.split(':')[1], 10)
      let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      var currentDate = new Date();
      var daysUntilCutOffDay = ((days.indexOf(cut_off_day)) - currentDate.getDay() + 7) % 7;
      var cut_off_date = new Date(currentDate);
      cut_off_date.setDate(currentDate.getDate() + daysUntilCutOffDay);
      cut_off_date.setHours(hours);
      cut_off_date.setMinutes(minute);
      const depart_date = new Date(departure_date)
      var timeDifference = cut_off_date - depart_date;
      var hoursDifference = timeDifference / (1000 * 60 * 60);
      if (Math.floor(hoursDifference) > 48 || Math.floor(hoursDifference) < 0) {
         update_apply_border_leave_form['leave_applied_ontime'].value = true;
      } else {
         update_apply_border_leave_form['leave_applied_ontime'].value = false;
      }
   }


   const dateAndTimeHandler = (event, identifier) => {
      const update_apply_border_leave_form = { ...applyBorderLeaveForm };
      if (identifier === 'departure_date') {
         checkAppliedLeaveTiming(event.target.value);
         const form_element = { ...update_apply_border_leave_form['departure_date'] };
         form_element.value = event.target.value;
         let validate_element = Validations(form_element);
         form_element.valid = validate_element.valid;
         form_element.error_msg = validate_element.error_msg;
         update_apply_border_leave_form[identifier] = form_element;
         if (update_apply_border_leave_form['return_time'].value !== "") {
            update_apply_border_leave_form['return_time'].value = ""
         }
      } else if (identifier === 'departure_time') {
         console.log("event._d", event);
         const form_element = { ...update_apply_border_leave_form[identifier] };
         form_element.value = moment(event, 'hh:mm A').format('HH:mm');
         let validate_element = Validations(form_element);
         form_element.valid = validate_element.valid;
         form_element.error_msg = validate_element.error_msg;
         update_apply_border_leave_form[identifier] = form_element;
         if (update_apply_border_leave_form['return_time'].value !== "") {
            update_apply_border_leave_form['return_time'].value = ""
         }
      } else if (identifier === 'return_time') {
         const form_element = { ...update_apply_border_leave_form[identifier] };
         form_element.value = moment(event, 'hh:mm A').format('HH:mm');
         let validate_element = Validations(form_element);
         form_element.valid = validate_element.valid;
         form_element.error_msg = validate_element.error_msg;
         update_apply_border_leave_form[identifier] = form_element;
      } else if (identifier === 'return_date') {
         const form_element = { ...update_apply_border_leave_form[identifier] };
         form_element.value = event.target.value
         let validate_element = Validations(form_element);
         form_element.valid = validate_element.valid;
         form_element.error_msg = validate_element.error_msg;
         update_apply_border_leave_form[identifier] = form_element;
         if (update_apply_border_leave_form['return_time'].value !== "") {
            update_apply_border_leave_form['return_time'].value = ""
         }
      } else {
         const form_element = { ...update_apply_border_leave_form[identifier] };
         form_element.value = event.target.value
         let validate_element = Validations(form_element);
         form_element.valid = validate_element.valid;
         form_element.error_msg = validate_element.error_msg;
         update_apply_border_leave_form[identifier] = form_element;

      }
      setApplyBorderLeaveForm(update_apply_border_leave_form)
   }

   const inputChangeHandler = (event, identifier) => {
      const update_apply_border_leave_form = { ...applyBorderLeaveForm };
      const form_element = { ...update_apply_border_leave_form[identifier] };
      let value = event.target.value.substring(0,130)
      form_element.value = value;
      // Check Validation
      let validate_element = Validations(form_element);
      form_element.valid = validate_element.valid;
      form_element.error_msg = validate_element.error_msg;

      update_apply_border_leave_form[identifier] = form_element;
      setApplyBorderLeaveForm(update_apply_border_leave_form)
   }

   const handleSelectChange = (data, identifier, type) => {
      const update_apply_border_leave_form = { ...applyBorderLeaveForm };
      if (identifier === 'host_id') {
         if (data.type == 'father' || data.type == 'mother') {
            update_apply_border_leave_form['parent_id'].value = data.value;
            update_apply_border_leave_form['host_id'].value = '';
            update_apply_border_leave_form['host_id'].validations = { required: false }
            update_apply_border_leave_form['parent_type'].value = data.type == 'father' ? 'father' : 'mother';
         } else if (data.type == 'host') {
            update_apply_border_leave_form['host_address'].value = data.host_address;
            update_apply_border_leave_form['host_name'].value = data.label;
            update_apply_border_leave_form['host_contact'].value = data.host_contact;
            update_apply_border_leave_form['host_relation'].value = data.host_relation;
            update_apply_border_leave_form['host_email'].value = data.host_email;
            update_apply_border_leave_form['host_id'].value = data.value;

            update_apply_border_leave_form['parent_id'].value = '';
            update_apply_border_leave_form['parent_id'].validations = { required: false }
         }
      } else {
         if (identifier === "return_mode_id") {
            update_apply_border_leave_form["return_mode_id"].value = data.value;
            update_apply_border_leave_form['return_mode_name'].value = data.label;
         }
         if (identifier === "departure_mode_id") {
            update_apply_border_leave_form['departure_mode_id'].value = data.value;
            update_apply_border_leave_form['departure_mode_name'].value = data.label;
         }
      };

      const form_element = { ...update_apply_border_leave_form[identifier] };
      // Check Validation
      let validate_element = Validations(form_element);
      form_element.valid = validate_element.valid;
      form_element.error_msg = validate_element.error_msg;

      update_apply_border_leave_form[identifier] = form_element;
      setApplyBorderLeaveForm(update_apply_border_leave_form)
   }

   const submitBoarderLeave = async (event) => {
      let is_form_valid = true;
      const update_apply_border_leave_form = { ...applyBorderLeaveForm };
      for (let key in update_apply_border_leave_form) {
         if (key === "parent_data") {
            update_apply_border_leave_form[key] = update_apply_border_leave_form[key];
         } else {
            let form_element = update_apply_border_leave_form[key];
            let validate_element = Validations(form_element);
            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;
            update_apply_border_leave_form[key] = form_element;
         }
      }
      console.log("update_apply_border_leave_form", update_apply_border_leave_form);
      setApplyBorderLeaveForm(update_apply_border_leave_form);
      if (is_form_valid) {
         const form_data = {};
         for (let key in applyBorderLeaveForm) {
            form_data[key] = applyBorderLeaveForm[key].value;
            if (['student_id'].includes(key)) {
               const parse_data = parseInt(applyBorderLeaveForm[key].value);
               form_data[key] = parse_data
            }
            else if (key === "parent_data") {
               form_data[key] = JSON.stringify(applyBorderLeaveForm[key]);

            } else {
               form_data[key] = applyBorderLeaveForm[key].value;
            }
         }
         setShowApplyLeaveModal(true)
         setLeaveData(form_data)
      }
   }

   const ApplyBorderLeave = async (leave_data) => {
      props.onApplyBorderLeave(leave_data).then((response) => {
         if (response.success === true) {
            toast.success(response.message, {
               position: toast.POSITION.TOP_CENTER,
            });
            navigate(`/${school_code}/leave/boarders-leave-details`)
         }
         if (response.success === false) {
            setShowApplyLeaveModal(false)
            toast.error(response.error, {
               position: toast.POSITION.TOP_CENTER,
            });
         }
      }).catch((e) => {
         // setShowApplyLeaveModal(false)
         // toast.error(response.error, {
         //    position: toast.POSITION.TOP_CENTER,
         // });
      })
   }

   const hideLeaveModal = (value) => {
      setShowApplyLeaveModal(value)
   }

   const AddOrUpdateStudentHost = async (hostData, student_id) => {
      setShowLoader(true)
      props.onAddOrUpdateStudentHost(hostData).then((response) => {
         if (response.success === true) {
            toast.success(response.message, {
               position: toast.POSITION.TOP_CENTER,
            });
            setShowAddHostModal(false)
            setShowLoader(false)
            GetApprovedStudentHost(student_id)
         } else {
            toast.error(response.message, {
               position: toast.POSITION.TOP_CENTER,
            });
         }
      }).catch((e) => {
      })
   }
   const hideAddHostModal = (value) => {
      setShowAddHostModal(value)
   }

   const getScheduleTime = (Scheduletime) => {

      console.log("Scheduletime", Scheduletime);

   }

   const GetStudentHostByHostEmail = async (host_email, index) => {
      props.onGetStudentHostByHostEmail(host_email?.target?.value).then((response) => {
         //  const update_edit_student_page = this.state.edit_student_page;
         //  const update_host_data = update_edit_student_page.host_data
         //  let setHostdetail = update_host_data[index];
          if (response.success === true) {
              if (response.data === null) {
              } else {
               setHostData(response.data)
                  // let host_data = response.data;
                  // setHostdetail['host_name'].value = host_data.host_name;
                  // setHostdetail['host_email'].value = host_data.host_email;
                  // setHostdetail['host_relation'].value = host_data.stu_host.host_relation;
                  // setHostdetail['host_contact'].value = host_data.host_contact;
                  // setHostdetail['host_address'].value = host_data.host_address;
                  // setHostdetail['remark_boarding'].value = host_data.remark_boarding;
                  // setHostdetail['remark_host'].value = host_data.remark_host;
                  // setHostdetail['remark_parents'].value = host_data.remark_parents;
              }
          } else {
          }
         //  update_host_data[index] = setHostdetail;
         //  update_edit_student_page.host_data = update_host_data
         //  this.setState({ edit_student_page: update_edit_student_page })
      })
  }
  console.log(hostData,"::GetStudentHostByHostEmail")

   return (
      <>
         {showLoader &&
            <div style={{ backdropFilter: "blur(10px)", display: "flex", justifyContent: "center", alignItems: "center", zIndex: "99999999", position: "fixed", top: 0, left: 0, right: 0, bottom: 0, background: "#b8b8b82b" }}>
               <div >
                  <TailSpin
                     visible={showLoader}
                     height="100"
                     width="100"
                     color="#3693d0"
                     ariaLabel="tail-spin-loading"
                     radius="1"
                     wrapperStyle={{}}
                     wrapperClass=""
                  />
               </div>

            </div>
         }
         {showApplyLeaveModal &&
            <ApplyBorderLeaveModal
               showApplyLeaveModal={showApplyLeaveModal}
               hideLeaveModal={(value) => hideLeaveModal(value)}
               leaveData={leaveData}
               ApplyBorderLeave={(leave) => ApplyBorderLeave(leave)}
            />
         }
         {showAddHostModal &&
            <AddStudentHostModal
               showAddHostModal={showAddHostModal}
               applyBorderLeaveForm={applyBorderLeaveForm}
               AddOrUpdateStudentHost={(data, student_id) => AddOrUpdateStudentHost(data, student_id)}
               hideAddHostModal={(host) => hideAddHostModal(host)}
               GetStudentHostByHostEmail={(host_email) => GetStudentHostByHostEmail(host_email)}
               hostData={hostData}
               setHostData={setHostData}
            />
         }

         <div
            id="kt_body"
            className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed"
         >
            <div className="d-flex flex-column flex-root">
               <div className="page d-flex flex-row flex-column-fluid">
                  <SideBarLeave />
                  <div
                     className="wrapper d-flex flex-column flex-row-fluid "
                     id="kt_wrapper"
                  >
                     <TopBar />
                     <div
                        className="content d-flex flex-column flex-column-fluid"
                        id="kt_content"
                     >
                        <div className="post d-flex flex-column-fluid" id="kt_post">
                           {/* <!--begin::Container--> */}
                           <div id="kt_content_container" className="container-fluid">
                              <div className="row justify-content-center">
                                 <div className="col-md-12">
                                    <div className="card card-xl-stretch">
                                       <div className="card-header align-items-center">
                                          <h2 className="card-title">
                                             Apply for Boarders Leave
                                          </h2>
                                          <Link to={`/${school_code}/leave/present-student-list`} className='d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M18 17L13 12L18 7M11 17L6 12L11 7" stroke="#4a8fb8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg> Back</Link>
                                       </div>

                                       <form>
                                          <div className="card-body">
                                             {applyBorderLeaveForm.leave_applied_ontime.value === false ?
                                                <div>
                                                   <Alert variant="danger" className="alert-02">
                                                      <strong>It appears you may be requesting Leave, a little late, your application might not be approved.</strong>
                                                      <img src="https://bss.dikonia.in/application/admin/bssleave/images/smile-icon.png" alt="" />
                                                   </Alert>
                                                </div>
                                                : ""
                                             }

                                             <div className='boarder_leave_timer text-right'>
                                                <div className='font-medium text-center justify-content-center   d-inline-flex align-items-center border border-dashed border-gray-300 rounded  px-5 py-3 mb-4 gap-5 '>
                                                   <Cdate></Cdate>
                                                </div>
                                             </div>
                                             <div className="custom-thumbnail" >
                                                <img
                                                   alt="profile"
                                                   className="img-fluid"
                                                   src={applyBorderLeaveForm?.student_avatar?.value === null || applyBorderLeaveForm?.student_avatar?.value === "" ? (applyBorderLeaveForm.gender.value === "male" ? "/media/avatars/boy.jpg" : "/media/avatars/girl.jpg") : applyBorderLeaveForm.student_avatar.thumbnail_image}
                                                />
                                             </div>
                                             <div className=" row mt-3" >
                                                <div className='col-md-2'>
                                                   <label className="mb-2 fw-bold">Welcome </label>
                                                </div>
                                                <div className='col-md-5'>{applyBorderLeaveForm.student_name.value}</div>
                                             </div>
                                             <div className=" row mt-3" >
                                                <div className='col-md-2'>
                                                   <label className="mb-2 fw-bold"> Year </label>
                                                </div>
                                                <div className='col-md-5'>{applyBorderLeaveForm.class_name.value}</div>
                                             </div>
                                             <div className='row mt-3'>
                                                <div className='col-md-2'>
                                                   <label className="mb-2 fw-bold">  Dormitory Name  </label>
                                                </div>
                                                <div className='col-md-5'>
                                                   {applyBorderLeaveForm.dormitory_name.value}
                                                </div>
                                             </div>
                                             <div className="row mt-3">
                                                <div className="col-md-2"><label className="mb-2 fw-bold">Leave Applied For</label></div>
                                                <div className="col-md-10">
                                                   {applyBorderLeaveForm.leave_applied_ontime.value === "" ?
                                                      <span className='badge badge-light-warning'>
                                                         Select Departure Date
                                                      </span>
                                                      :
                                                      <span className={`badge ${applyBorderLeaveForm.leave_applied_ontime.value === true ? 'text-bg-success' : 'text-bg-danger'} py-2`}>
                                                         {applyBorderLeaveForm.leave_applied_ontime.value === true ? 'On Time' : 'Late'}
                                                      </span>
                                                   }
                                                </div>
                                             </div>

                                             <div className="row mt-5">
                                                <div className='col-md-6 col-lg-3'>
                                                   <div className="col-md-12" ><label className="mb-2 fw-bold">Host Name</label></div>
                                                   <div className="col-md-12">
                                                      <Select
                                                         styles={customStyles}
                                                         className="react-bootstrap-typeahead tag-outer"
                                                         options={hostList}
                                                         // value={hostList.filter(function (option) { 
                                                         //    return applyBorderLeaveForm.host_id.value !== '' ? option.value === applyBorderLeaveForm.host_id.value : stuHost_id.includes(option.student_host_id);
                                                         //    // return applyBorderLeaveForm.host_id.value !== '' ? option.value === applyBorderLeaveForm.host_id.value : option.student_host_id  === applyBorderLeaveForm.parent_id.value;
                                                         // })}
                                                         onChange={(newValue) => handleSelectChange(newValue, "host_id", "weekend_day from value")}
                                                         autoComplete="off"
                                                      />
                                                      {!applyBorderLeaveForm.host_id.valid && applyBorderLeaveForm.host_id.onBlur_out ? <div className="error field-error">{applyBorderLeaveForm.host_id.error_msg}</div> : ""}
                                                   </div>
                                                   <p className='mt-2'>(Only Approved Hosts Will Display)</p>
                                                </div>

                                                <div className="col-md-6 col-lg-9">
                                                   <div className="col-md-12">
                                                      <label className="mb-2 fw-bold">{applyBorderLeaveForm.remarks_by_host.label}</label>
                                                   </div>
                                                   <div className='row'>
                                                      <div className="col-md-8">
                                                         <textarea
                                                            type={applyBorderLeaveForm.remarks_by_host.type}
                                                            value={applyBorderLeaveForm.remarks_by_host.value ? applyBorderLeaveForm.remarks_by_host.value : ""}
                                                            className="form-control text-area-height-wrap mb-2"
                                                            placeholder={applyBorderLeaveForm.remarks_by_host.placeholder}
                                                            onChange={(event) => inputChangeHandler(event, "remarks_by_host")}
                                                            autoComplete="on"
                                                            ref={textareaRefHost}
                                                         />
                                                         <span className="countLength">Max 130 Characters: {applyBorderLeaveForm?.remarks_by_host?.value?.length}</span>
                                                         {!applyBorderLeaveForm.remarks_by_host.valid && applyBorderLeaveForm.remarks_by_host.onBlur_out ? <div className="error field-error">{applyBorderLeaveForm.remarks_by_host.error_msg}</div> : ""}
                                                      </div>
                                                      <div className="col-md-4">
                                                         <Tooltip title="Adding an Express Host, will approve the Host automatically & assign the Host to this student" placement="top">
                                                            <i className="fas fa-question-circle me-2"></i>
                                                         </Tooltip>
                                                         <button type="button" onClick={() => setShowAddHostModal(true)} className="btn btn-primary py-2">Add Host Express</button>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                             <div className="row mt-5  gap-y-5">
                                                <div className="col-md-4 col-lg-4 col-xxl-4">
                                                   <label className="mb-2 fw-bold">{applyBorderLeaveForm.departure_date.label}</label>
                                                   <div className='position-relative'>
                                                      <span className='position-absolute apply_adhoc_leave_date_and_time' onClick={() => document.getElementById('session-date').click()}>
                                                    
                                                      </span>
                                                      <input
                                                         id="session-date"
                                                         type="date"
                                                         className="form-control bg-transparent"
                                                         placeholder={applyBorderLeaveForm.departure_date.placeholder}
                                                         value={applyBorderLeaveForm.departure_date.value}
                                                         onChange={(event) => dateAndTimeHandler(event, "departure_date")}
                                                         autoComplete="off"
                                                         required
                                                         min={getCurrentDate()}
                                                      />
                                                   </div>
                                                   {!applyBorderLeaveForm.departure_date.valid && applyBorderLeaveForm.departure_date.onBlur_out ? <div className="error field-error">{applyBorderLeaveForm.departure_date.error_msg}</div> : ""}
                                                </div>

                                                <div className="col-md-4 col-lg-4 col-xxl-4">
                                                   <label className="mb-2 fw-bold">{applyBorderLeaveForm.departure_time.label}</label>
                                                   <BoaderLeaveDepartureTimeHandler
                                                      selectDate={applyBorderLeaveForm.departure_date.value}
                                                      placeholder={applyBorderLeaveForm.departure_time.placeholder}
                                                      onChange={(event) => dateAndTimeHandler(event, "departure_time")}
                                                   />
                                                   {!applyBorderLeaveForm.departure_time.valid && applyBorderLeaveForm.departure_time.onBlur_out ? <div className="error field-error">{applyBorderLeaveForm.departure_time.error_msg}</div> : ""}
                                                </div>

                                                <div className="col-md-4 col-lg-4 col-xxl-4">
                                                   <label className="mb-2 fw-bold">{applyBorderLeaveForm.departure_mode_id.label}</label>
                                                   <Select
                                                      styles={customStyles}
                                                      className="react-bootstrap-typeahead tag-outer"
                                                      options={travelModeList}
                                                      value={travelModeList.filter(function (option) {
                                                         return option.value === applyBorderLeaveForm.departure_mode_id.value;
                                                      })}
                                                      onChange={(newValue) => handleSelectChange(newValue, "departure_mode_id", "weekend_day from value")}
                                                      autoComplete="off"
                                                   />
                                                   {!applyBorderLeaveForm.departure_mode_id.valid && applyBorderLeaveForm.departure_mode_id.onBlur_out ? <div className="error field-error">{applyBorderLeaveForm.departure_mode_id.error_msg}</div> : ""}
                                                </div>




                                                <div className="col-md-4 col-lg-4 col-xxl-4">
                                                   <label className="mb-2 fw-bold">{applyBorderLeaveForm.return_date.label}</label>
                                                   <div className='position-relative'>
                                                      <span className='position-absolute apply_adhoc_leave_date_and_time' onClick={() => document.getElementById('session-date').click()}>
                                             
                                                      </span>
                                                      <input
                                                         id="session-date"
                                                         type={applyBorderLeaveForm.return_date.type}
                                                         className="form-control bg-transparent"
                                                         placeholder={applyBorderLeaveForm.return_date.placeholder}
                                                         value={applyBorderLeaveForm.return_date.value}
                                                         onChange={(event) => dateAndTimeHandler(event, "return_date")}
                                                         autoComplete="off"
                                                         required
                                                         min={applyBorderLeaveForm.departure_date.value}
                                                      />
                                                   </div>
                                                   {!applyBorderLeaveForm.return_date.valid && applyBorderLeaveForm.return_date.onBlur_out ? <div className="error field-error">{applyBorderLeaveForm.return_date.error_msg}</div> : ""}
                                                </div>
                                                <div className="col-md-4 col-lg-4 col-xxl-4">
                                                   <label className="mb-2 fw-bold">{applyBorderLeaveForm.return_time.label}</label>
                                                   <BoarderLeaveReturnTimeHandeler
                                                      selectDepartureDate={applyBorderLeaveForm.departure_date.value}
                                                      selectReturnDate={applyBorderLeaveForm.return_date.value}
                                                      selectDepartureTime={applyBorderLeaveForm.departure_time.value}
                                                      placeholder={applyBorderLeaveForm.return_time.placeholder}
                                                      returnTime={applyBorderLeaveForm.return_time.value}
                                                      onChange={(event) => dateAndTimeHandler(event, "return_time")}
                                                   />
                                                   {!applyBorderLeaveForm.return_time.valid && applyBorderLeaveForm.return_time.onBlur_out ? <div className="error field-error">{applyBorderLeaveForm.return_time.error_msg}</div> : ""}
                                                </div>
                                                <div className="col-md-4 col-lg-4 col-xxl-4">
                                                   <label className="mb-2 fw-bold">{applyBorderLeaveForm.return_mode_id.label}</label>
                                                   <Select
                                                      styles={customStyles}
                                                      className="react-bootstrap-typeahead tag-outer"
                                                      options={travelModeList}
                                                      value={travelModeList.filter(function (option) {
                                                         return option.value === applyBorderLeaveForm.return_mode_id.value;
                                                      })}
                                                      onChange={(newValue) => handleSelectChange(newValue, "return_mode_id", "weekend_day from value")}
                                                      autoComplete="off"
                                                   />
                                                   {!applyBorderLeaveForm.return_mode_id.valid && applyBorderLeaveForm.return_mode_id.onBlur_out ? <div className="error field-error">{applyBorderLeaveForm.return_mode_id.error_msg}</div> : ""}
                                                </div>
                                             </div>

                                             <div className='row'>
                                                <div className='col-md-6'>
                                                   <div className="mt-5">
                                                      <label className="mb-2 fw-bold">{applyBorderLeaveForm.remarks_by_parent.label}</label>
                                                      <textarea
                                                         value={applyBorderLeaveForm?.remarks_by_parent?.value ? applyBorderLeaveForm?.remarks_by_parent?.value : ""}
                                                         className="form-control h-150 mb-2"
                                                         placeholder={applyBorderLeaveForm.remarks_by_parent.placeholder}
                                                         id="floatingTextarea2"
                                                         onChange={(event) => inputChangeHandler(event, "remarks_by_parent")}
                                                         ref={textareaRefParent}
                                                      ></textarea>
                                                      <span className="countLength">Max 130 Characters: {applyBorderLeaveForm?.remarks_by_parent?.value?.length}</span>
                                                   </div>
                                                </div>
                                                <div className='col-md-6'>
                                                   <div className="mt-5">
                                                      <label className="mb-2 fw-bold">
                                                         {applyBorderLeaveForm.remarks_by_boarding.label}
                                                         {/* {applyBorderLeaveForm.remarks_by_boarding.value === "" ? `( Insert at least 10 characters )` :
                                                            applyBorderLeaveForm.remarks_by_boarding.value.length < 10 && applyBorderLeaveForm.remarks_by_boarding.value !== "" ? `( Add at least ${10 - applyBorderLeaveForm.remarks_by_boarding.value.length} more characters )` :
                                                               ""
                                                         } */}
                                                      </label>
                                                      <textarea
                                                         className="form-control h-300 mb-2"
                                                         placeholder={applyBorderLeaveForm.remarks_by_boarding.placeholder}
                                                         value={applyBorderLeaveForm?.remarks_by_boarding?.value ? applyBorderLeaveForm?.remarks_by_boarding?.value : ""}
                                                         id="floatingTextarea2" 
                                                         onChange={(event) => inputChangeHandler(event, "remarks_by_boarding")}
                                                         ref={textareaRefBoarding}
                                                      ></textarea>
                                                      <span className="countLength">Max 130 Characters: {applyBorderLeaveForm?.remarks_by_boarding?.value?.length}</span>
                                                      {!applyBorderLeaveForm.remarks_by_boarding.valid && applyBorderLeaveForm.remarks_by_boarding.onBlur_out ? <div className="error field-error">{applyBorderLeaveForm.remarks_by_boarding.error_msg}</div> : ""}

                                                   </div>
                                                </div>
                                             </div>

                                             <div className="mt-5 text-right">
                                                <button type="button" className="btn btn-primary py-2" onClick={() => submitBoarderLeave()}>Apply</button>
                                             </div>
                                          </div>
                                       </form>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <Footer />
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}

function mapStateToProps(state) {
   return {

   };
}

const mapDispatchToProps = {
   onAddOrUpdateStudentHost: userActions.AddOrUpdateStudentHost,
   onGetApprovedStudentHost: userActions.GetApprovedStudentHost,
   onGetStudentAndParentById: userActions.GetStudentAndParentById,
   onApplyBorderLeave: userActions.ApplyBorderLeave,
   onGetAllTravelModes: userActions.GetAllTravelModes,
   onGetStudentHostByHostEmail: userActions.GetStudentHostByHostEmail,
};
export default connect(mapStateToProps, mapDispatchToProps)(ApplyBorderLeave);