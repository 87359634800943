import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import "react-datepicker/dist/react-datepicker.css";
import * as actionType from "../../../../constants/actionTypes";
import moment from "moment"; 
// import Moment from "moment";


const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

const IDCardItem = (props) => {
    const [perpagedata, setperpagedata] = useState(10);
    const expire_date = moment(new Date()).add(1,"months").format("DD/MM/yyyy");
    const issue_date = moment(new Date()).format("DD/MM/yyyy");
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    

    const rowClasses = (row, rowIndex) => {
        return 'custom-row-class';
    };


    const [columns] = useState([
        {
            text: "",
            dataField: "Actions",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <div key={"repeat_" + row.country_id} className="print_half_class">

                        <div style={{ width: "385px", margin: "0  auto 15px", border: "1px solid #f2f2f2", borderRadius: "25px" }} className="inner-div">
                            <table style={{ width: "385px", margin: "0  auto" }}>
                                <tr>
                                    <td style={{ background: "#4a8fb8", color: "#fff", textAlign: "center", borderTopRightRadius: "25px", borderTopLeftRadius: "25px", fontWeight: "bold", padding: "25px 30px 90px " }} className="card_heading">TEMPORARY STUDENT ID CARD</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "center", padding: "0 30px 0" }}>
                                        <img className="card-profile" src={row.student_avatar ? actionType.environment_url + '/' + row.student_avatar : (row.gender === "male" ? "/media/avatars/boy.jpg" : "/media/avatars/girl.jpg")} style={{ width: "120px", height: "120px", borderRadius: "50%" }} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="bg-card" style={{ padding: "20px 25px" }}>
                                        <table >
                                            <tr>
                                                <td style={{ fontSize:"13px", paddingRight: "25px", fontWeight: "400", paddingTop: "3px", paddingBottom: "3px", textTransform: "uppercase" }}>School Name</td>
                                                <td style={{ fontSize:"13px", paddingRight: "25px", fontWeight: "400", paddingTop: "3px", paddingBottom: "3px" }}>{row.school_name}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontSize:"13px", paddingRight: "25px", fontWeight: "400", textTransform: "uppercase", paddingTop: "3px", paddingBottom: "3px" }}>ID</td>
                                                <td style={{ fontSize:"13px", paddingRight: "25px", fontWeight: "400", textTransform: "uppercase", paddingTop: "3px", paddingBottom: "3px" }}>{row.id_card_code}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontSize:"13px", paddingRight: "25px", fontWeight: "400", paddingTop: "3px", paddingBottom: "3px" }}>Name</td>
                                                <td style={{ fontSize:"13px", paddingRight: "25px", fontWeight: "400", paddingTop: "3px", paddingBottom: "3px" }}>{row.student_first_name + " " + row.student_last_name}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontSize:"13px", paddingRight: "25px", fontWeight: "400", paddingTop: "3px", paddingBottom: "3px" }}>DOB</td>
                                                <td style={{ fontSize:"13px", paddingRight: "25px", fontWeight: "400", paddingTop: "3px", paddingBottom: "3px" }}>{row.date_of_birth}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontSize:"13px", paddingRight: "25px", fontWeight: "400", paddingTop: "3px", paddingBottom: "3px" }}>Class</td>
                                                <td style={{ fontSize:"13px", paddingRight: "25px", fontWeight: "400", paddingTop: "3px", paddingBottom: "3px" }}>{row.class_name}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontSize:"13px", paddingRight: "25px", fontWeight: "400", paddingTop: "3px", paddingBottom: "3px" }}>Dormitory</td>
                                                <td style={{ fontSize:"13px", paddingRight: "25px", fontWeight: "400", paddingTop: "3px", paddingBottom: "3px" }}>{row.dormitory_name}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontSize:"13px", paddingRight: "12px", fontWeight: "400", paddingTop: "3px", paddingBottom: "3px" }}>Dormitory Contact</td>
                                                <td style={{ fontSize:"13px", paddingRight: "25px", fontWeight: "400", paddingTop: "3px", paddingBottom: "3px" }}>{row.dormitiory_contact}</td>
                                            </tr>
                                           
                                            {/* <tr>
                                                <td style={{ paddingRight: "25px", fontWeight: "400" }}>Valid to</td>
                                                <td style={{ paddingRight: "25px", fontWeight: "400" }}>{str}</td>
                                            </tr> */}
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ background: "#4a8fb8", padding: "17px 25px", borderBottomRightRadius: "25px", borderBottomLeftRadius: "25px", }} className="print_footer">
                                        <table style={{ width: "100%" }}>
                                            <tr>
                                                <td style={{width:"40%"}}>
                                                    <img src={user_info.user_school.school_logo ? actionType.environment_url + '/' + user_info.user_school.school_logo : "/assets/media/school-logo.png"} alt="" style={{ width: "63px", }} className="rounded-circle" /></td>
                                                <td style={{ paddingRight: "0", fontWeight: "400", color: "white", textAlign: "right" }}>
                                                    <p className="m-0 fs-8">Date Printed : {issue_date}</p>
                                                    <p className="m-0 fs-8">Expiry Date  : {expire_date}</p>
                                                    <p className="m-0 fs-8">Manager Name  : {row.manager_name === undefined ? user_info.first_name + " " + user_info.last_name : row.manager_name}</p>
                                                    <p className="m-0 fs-8">Manager Phone : {row.manager_contact === undefined ? user_info.contact_one : row.manager_contact}</p>
                                                    {console.log("manager_name",user_info)}
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </div>

                    </div>
                );
            },
        },
    ]);
    const handleTableChange = (type, { sortField, sortOrder, page }) => {
        if (type === "sort") {
            props.propsfiltersort(sortField);
            props.propsfilterorder(sortOrder);
        }

        if (type === "pagination") {
            props.propsfilterpage(page);
        }
    };
    const RemotePagination = ({ columns, dataField, order, data, page, sizePerPage, onTableChange, totalSize }) => (
        <PaginationProvider
            pagination={paginationFactory({
                custom: true,
                page,
                sizePerPage,
                totalSize,
            })}
        >
            {({ paginationProps, paginationTableProps }) => (
                <>
                    <div className="common-table superadmin_table">
                        <BootstrapTable
                            remote
                            bootstrap4
                            data={data}
                            keyField="student_uuid"
                            sort={{ dataField: dataField, order: order }}
                            columns={columns}
                            {...paginationTableProps}
                            onTableChange={onTableChange}
                            sizePerPageList={sizePerPage}
                            headerClasses="fw-bolder text-white bg-dark-row"
                            classes="table-bordered align-middle custom-table-style table-fields"
                            id="printDiv"
                            rowClasses={rowClasses}
                            noDataIndication={() => <span className="text-center text-gray-400">No records found</span>}
                        />
                    </div>
                    <div className="d-flex flex-stack justify-content-between flex-wrap  pagination-main">
                        {totalSize > 0 && (
                            <div className="pagination_limt">
                                <select id='select' name='group' className='form-control input-sm form-select form-select-sm form-select-solid' size='1' value={perpagedata}
                                    onChange={(event) => {
                                        props.propsfilterpage(1);
                                        setperpagedata(event.target.value);
                                        props.propsfilterlimit(parseInt(event.target.value));
                                    }}
                                >
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={250}>250</option>
                                    <option value={400}>400</option>
                                </select>
                                {(parseInt(sizePerPage) * parseInt(page)) > totalSize ? (
                                    <div className="count_number">Displaying {(parseInt(sizePerPage) * parseInt(page) + parseInt(1)) - (parseInt(perpagedata))}-{totalSize} of {totalSize} records</div>
                                ) : (
                                    <div className="count_number">Displaying {(parseInt(sizePerPage) * parseInt(page) + parseInt(1)) - (parseInt(perpagedata))}-{parseInt(sizePerPage) * parseInt(page)} of {totalSize} records</div>
                                )}
                            </div>
                        )}
                        <div className="pagination_page">
                            <PaginationListStandalone {...paginationProps} />
                        </div>
                    </div>
                </>
            )}
        </PaginationProvider>
    );
    return (
        <React.Fragment>
            <div className="row gy-5 g-xl-8 temp_id_padding_and_margin_0">
                <div className="col-xl-12 temp_id_padding_and_margin_0">
                    <div className="card temp_id_padding_and_margin_0">
                        <div className="card-body temp_id_padding_and_margin_0">
                            <div id="kt_profile_overview_table" className="dataTables_wrapper dt-bootstrap4 no-footer team-list-table">
                                <div className="card-body p-0 bg-white radious-style radious-users align-table-body">
                                    {/* <Spin spinning={props.loading}> */}
                                    <RemotePagination
                                        data={props.data}
                                        columns={columns}
                                        page={props.filterpage}
                                        totalSize={props.IDCardcount}
                                        sizePerPage={props.filterlimit}
                                        order={props.filterorder}
                                        onTableChange={handleTableChange}
                                    />
                                    {/* </Spin> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default IDCardItem;