import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import Footer from "../../Constant/Footer";
import SideBarLeave from "../../Constant/SideBarLeave";
import TopBar from "../../Constant/TopBar";
import { Link } from "react-router-dom";
import LeaveRejectionReasonItem from "./LeaveRejectionReasonItem";
import * as userActions from "../../../actions/index";
import { toast } from "react-toastify";
import DeleteConfirmRemove from "../../Utility/DeleteConfirmRemove";


const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info.school_code;
const permission_info = JSON.parse(localStorage.getItem("permissions"));
const ManageLeaveRejection = (props) => {
    const dispatch = useDispatch();

    const [filterlimit, setfilterlimit] = useState(10);
    const [filterpage, setfilterpage] = useState(1);
    const [filterorder] = useState("asc");
    const [filtersort] = useState("reject_reason");


    const [LeaveRejectionList, setLeaveRejectionList] = useState([]);
    const [LeaveRejectionListcount, setLeaveRejectionListcount] = useState(0);
    const [editconfirmremovedata, seteditconfirmremovedata] = useState([]);
    const [confirm_modal, setconfirm_modal] = useState(false);

    const [filtersearch, setfiltersearch] = useState(null);


    const GetAllLeaveRejectReasons = useCallback(async () => {
        dispatch(userActions.GetAllLeaveRejectReasons(filterpage, filterlimit, filtersort, filterorder, filtersearch)).then((response) => {
            if (response.success === true) {
                const arrayData = response.data.rows;

                //    console.log("=======",response.data.rows[0].leave_reject_reason_uuid);
                let MemberId_level = [];
                const sumvalue = 1;
                for (let key in arrayData) {
                    const perpage = ((parseInt(filterpage) - parseInt(sumvalue)) * parseInt(filterlimit) + parseInt(key) + parseInt(sumvalue));

                    MemberId_level.push({
                        perpage: perpage,
                        reject_reason: arrayData[key].reject_reason,
                        is_reject_reason_activate: arrayData[key].is_reject_reason_activate,
                        leave_reject_reason_uuid: arrayData[key].leave_reject_reason_uuid,
                    });
                }
                setLeaveRejectionList(MemberId_level);
                setLeaveRejectionListcount(response.data.count);
            } else {
            }
        });
    }, [dispatch, filterpage, filterlimit, filtersort, filterorder, filtersearch]);

    useEffect(() => {
        GetAllLeaveRejectReasons();
    }, [GetAllLeaveRejectReasons, filterpage, filterlimit, filtersort, filterorder, filtersearch]);

    const showAddModalHandle = (event) => {
        setconfirm_modal(false);
    };

    const RemoveCourseModule = (event, course_data) => {
        seteditconfirmremovedata(course_data);
        setconfirm_modal(true);
    };

    const ConfirmRemove = (form_data) => {
        console.log('form_data===', form_data)
        setconfirm_modal(false);
        props.onDeleteLeaveRejectReason(form_data).then((response) => {
            if (response.success === true) {
                GetAllLeaveRejectReasons();
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                // setadd_course_modal(false);
                // setedit_course_modal(false);
                setconfirm_modal(false);
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    };


    const handleChange = async (event, leave_reject_reason_uuid, value) => {
        console.log("hello")
        const Payload = {
            leave_reject_reason_uuid: leave_reject_reason_uuid,
            is_reject_reason_activate: value,
        };
        console.log('Payload===', Payload)


        props.onUpdateLeaveRejectStatus(Payload).then((response) => {
            if (response.success === true) {
                GetAllLeaveRejectReasons();
                setfilterpage(1);
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });

    }
    return (
        <>
            {confirm_modal && (
                <DeleteConfirmRemove
                    title={`Delete Reason?`}
                    bodytitle="Are you sure you want to delete this Leave Rejection Reason?"
                    editconfirmremovedata={editconfirmremovedata}
                    confirm_modal={confirm_modal}
                    confirmButtonText="Delete"
                    ConfirmRemove={(event) => ConfirmRemove(event)}
                    delete_confirm_modal_action={(modal_action) => showAddModalHandle(modal_action)}
                />
            )}
            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed" >
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <SideBarLeave />
                        <div className="wrapper d-flex flex-column flex-row-fluid " id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">

                                <div className="post d-flex flex-column-fluid" id="kt_post">

                                    <div id="kt_content_container" className="container-fluid">
                                        <div className="col-lg-12">

                                            <div className="card">
                                                <div className="card-header border-bottom align-items-center">
                                                    <h3 className="card-title align-items-start flex-column">
                                                        Leave Rejection Reason Details
                                                    </h3>
                                                    {
                                                        permission_info?.add_rejection_reason !== false &&
                                                        <Link to={`/${school_code}/leave/manage-leave-rejection-reason/add-manage-leave-rejection-reason`} className="btn btn-sm btn-primary" >Add Rejection Reasons</Link>
                                                    }
                                                </div>

                                                <div className="card-body p-6 pb-0 d-flex align-items-center justify-content-end">

                                                    <div className="d-flex align-items-center position-relative">
                                                        <span className="svg-icon svg-icon-1 position-absolute ms-4">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect><path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path></svg></span><input type="text" className="form-control form-control-sm w-350px ps-12 search-out fs-6" placeholder="Search " style={{ background: "transparent" }}


                                                                onChange={(event) => {
                                                                    setfiltersearch(event.target.value);
                                                                    setfilterpage(1);
                                                                }}
                                                        /></div>
                                                </div>
                                                {<LeaveRejectionReasonItem
                                                    LeaveRejectionListcount={LeaveRejectionListcount}
                                                    LeaveRejectionList={LeaveRejectionList}
                                                    data={LeaveRejectionList}
                                                    propsfilterpage={(event) => setfilterpage(event)}
                                                    RemoveCourseModule={(event, data) => RemoveCourseModule(event, data)}
                                                    handleChange={(event, leave_reject_reason_uuid, value) => handleChange(event, leave_reject_reason_uuid, value)}
                                                    filterlimit={filterlimit}
                                                    filterpage={filterpage}
                                                    propsfilterlimit={(filterlimit) => {
                                                        setfilterlimit(filterlimit);
                                                    }}
                                                />}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <Footer />

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
    onUpdateLeaveRejectStatus: userActions.UpdateLeaveRejectStatus,
    onDeleteLeaveRejectReason: userActions.DeleteLeaveRejectReason,
};
export default connect(mapStateToProps, mapDispatchToProps)(ManageLeaveRejection);