import React from "react";
import { Modal } from "react-bootstrap";
import Validations from "../../Utility/Validations";
import Select from "react-select";
import { connect } from "react-redux";
import * as userActions from "../../../actions/index";
import TextareaAutosize from 'react-autosize-textarea';
import IncludeEmailModal from "../../Utility/IncludeEmailModal";


const customStyles = {
    control: (base, state) => ({
        ...base,
        borderRadius: 0,

    }),
    menuList: base => ({
        ...base,
        padding: 0
    }),
    indicatorSeparator: base => ({
        ...base,
        display: 'none'
    }),
};

class AddMedicalComment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show_include_modal: false,
            diary_comment_form: {
                student_names: {
                    type: "select",
                    label: "Student Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Student Name",
                    validations: { required: true },
                    options: [],
                },
                student_ids: {
                    type: "select",
                    label: "Student Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Student Name",
                    validations: { required: true },
                    options: [],
                },
                diary_comment_desc: {
                    type: "text",
                    label: "Add Comment",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                is_med_issued: {
                    type: "checkbox",
                    label: "To indicate that medication has been issued to students, please check this box.",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                include_mail: {
                    type: "checkbox",
                    label: "Include primary email contact",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },


                diary_comment_type: {
                    type: "text",
                    label: "diary_comment_type",
                    value: "medical",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },


            },

        };
    }

    componentDidMount() {
        this.GetAllStudentsGeneric();
    }


    GetAllStudentsGeneric = async (form_data) => {
        this.props.onGetAllStudentsGeneric().then((response) => {
            let country_level = [];
            const update_diary_comment_form = { ...this.state.diary_comment_form };
            if (response.success === true) {
                const arrayData = response.data.rows;
                console.log(arrayData);
                for (let key in arrayData) {
                    country_level.push({
                        label: arrayData[key].student_first_name + " " + arrayData[key].student_last_name,
                        value: arrayData[key].student_id,
                        student_id: JSON.stringify([arrayData[key].student_id]),
                    });
                }
            }
            update_diary_comment_form['student_ids'].options = country_level;
            this.setState({ diary_comment_form: update_diary_comment_form });
        });
    }



    handleChange(data, identifier) {
        const update_diary_comment_form = { ...this.state.diary_comment_form };
        let medicalCommentArray = [];
        let student_ids = [];
        data.forEach((item) => {
            medicalCommentArray.push(item.label)
            student_ids.push(item.value)
        })
        update_diary_comment_form[identifier].value = student_ids;
        update_diary_comment_form['student_names'].value = JSON.stringify(medicalCommentArray);
        this.setState({ diary_comment_form: update_diary_comment_form });
    }

    inputChangeHandler(event, identifier) {
        const update_diary_comment_form = { ...this.state.diary_comment_form };
        update_diary_comment_form[identifier].value = event.target.value;
        this.setState({ diary_comment_form: update_diary_comment_form });
    }

    inputChangeHandlerCheckbox(event, identifier) {
        const update_diary_comment_form = { ...this.state.diary_comment_form };
        const form_element = { ...update_diary_comment_form[identifier] };
        form_element.value = event.target.checked;
        update_diary_comment_form[identifier] = form_element;
        if (identifier === "include_mail") {
            this.setState({ show_include_modal: event.target.checked })
        }
        this.setState({ diary_comment_form: update_diary_comment_form });
    }

    SubmitHandler = (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_diary_comment_form = this.state.diary_comment_form;
        for (let key in update_diary_comment_form) {
            let form_element = update_diary_comment_form[key];
            let validate_element = Validations(form_element);
            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;
            update_diary_comment_form[key] = form_element;
        }
        this.setState({ diary_comment_form: update_diary_comment_form });
        console.log("include==============", this.state.diary_comment_form);
        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.diary_comment_form) {
                if (key === "student_ids") {
                    form_data[key] = JSON.stringify(this.state.diary_comment_form[key].value);
                } else {
                    form_data[key] = this.state.diary_comment_form[key].value;
                }
            }
            this.props.CreateDiaryMedicalComment(form_data);
        }
    };


    setIncludeModalVlaue = (val) => {
        const update_diary_comment_form = { ...this.state.diary_comment_form };
        update_diary_comment_form["include_mail"].value = val;
        this.setState({ show_include_modal: false })
        this.setState({ diary_comment_form: update_diary_comment_form });
    }

    render() {
        const { diary_comment_form } = this.state;
        const { show_include_modal } = this.state;
        return (
            <React.Fragment>
                {show_include_modal &&
                    <IncludeEmailModal
                        includes={show_include_modal}
                        setIncludeModalVlaue={(val) => this.setIncludeModalVlaue(val)}
                    />
                }
                <Modal
                    size="lg"
                    backdrop={true}
                    keyboard={false}
                    className="add_medical_comment_modal confirm_modal"
                    show={this.props.add_medical_comment_modal}
                    onHide={() => {
                        this.props.add_medical_comment_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Add Medical Comments</Modal.Title>
                    </Modal.Header>
                    <form className="form w-100" onSubmit={this.SubmitHandler}>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-12">

                                    <div className="rect-select">
                                        <label className="fw-bold mb-2">{diary_comment_form.student_ids.label}</label>
                                        <Select
                                            styles={customStyles}
                                            className="react-bootstrap-typeahead tag-outer"
                                            options={diary_comment_form.student_ids.options}
                                            value={diary_comment_form.student_ids.options.filter(function (option) {
                                                return diary_comment_form.student_ids.value.includes(option.value);
                                            })}
                                            onChange={(newValue) => this.handleChange(newValue, "student_ids")}
                                            isMulti
                                        />
                                        {!diary_comment_form.student_ids.valid && diary_comment_form.student_ids.onBlur_out ? (
                                            <div className="error field-error">{diary_comment_form.student_ids.error_msg}</div>
                                        ) : (
                                            ""
                                        )}
                                    </div>

                                    <div className="my-5">
                                        <label className="fw-bold mb-2">{diary_comment_form.diary_comment_desc.label}</label>
                                        <TextareaAutosize
                                            minRows={1}
                                            maxRows={10}
                                            placeholder={diary_comment_form.diary_comment_desc.placeholder}
                                            value={diary_comment_form.diary_comment_desc.value ? diary_comment_form.diary_comment_desc.value : ""}
                                            onChange={(event) => this.inputChangeHandler(event, "diary_comment_desc")}
                                            className="form-control min-h-100px"
                                       />
                                        {!diary_comment_form.diary_comment_desc.valid && diary_comment_form.diary_comment_desc.onBlur_out ? <div className="error field-error">{diary_comment_form.diary_comment_desc.error_msg}</div> : ""}
                                    </div>

                                    <div className="mb-5">
                                        <label className="fw-bold d-flex align-items-center gap-2" style={{textTransform:"none"}}>
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                value={diary_comment_form.is_med_issued.value}
                                                checked={diary_comment_form.is_med_issued.value}
                                                onChange={(event) => this.inputChangeHandlerCheckbox(event, "is_med_issued")}
                                            />
                                             {diary_comment_form.is_med_issued.label}
                                        </label>
                                    </div>

                                    <div>
                                        <label className="fw-bold d-flex align-items-center gap-2" style={{textTransform:"none"}}>
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                value={diary_comment_form.include_mail.value}
                                                checked={diary_comment_form.include_mail.value}
                                                onChange={(event) => this.inputChangeHandlerCheckbox(event, "include_mail")}
                                            />
                                             {diary_comment_form.include_mail.label}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="row">
                                <div className="col-12 d-flex justify-content-center">
                                    <button type="submit" className="btn btn-sm btn-primary">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </Modal.Footer>
                    </form>
                </Modal>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onGetAllStudentsGeneric: userActions.GetAllStudentsGeneric,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddMedicalComment);

