import React, { useCallback, useEffect, useState } from "react";
import Sidebar from "../../../Constant/sidebar";
import Footer from "../../../Constant/Footer";
import TopBar from "../../../Constant/TopBar";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import * as userActions from "../../../../actions/index";
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import moment from "moment";
import Tooltip from 'react-bootstrap/Tooltip';

const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info?.school_code;
const LeaveHistory = (props) => {
    const [leaveList, setLeaveList] = useState([])
    const [rowVisibility, setRowVisibility] = useState({});
    useEffect(() => {
        HistoryOfLeaves();
    }, [])
    const HistoryOfLeaves = useCallback(async () => {
        props.onHistoryOfLeaves(props.user_uuid).then((response) => {
            if (response.success === true) {
                let leaveListArray = response.data.rows.map((item) => {
                    return { ...item, show: false }
                })
                setLeaveList(leaveListArray)
            } else {
            }
        })
    }, [])

    const handleToggleRowVisibility = (index) => {
        setRowVisibility((prevState) => ({
            ...prevState,
            [index]: !prevState[index], // Toggle visibility for the specified index
        }));
    };
    return (
        <>
            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <Sidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <TopBar/>
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-fluid">
                                        <ul className="nav nav-tabs home-tabs" id="myTab" role="tablist"  style={{display:"flex", justifyContent:"space-between", alignItems:"flex-end"}}>
                                            <div style={{display:"flex"}}>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/student-profile/${props.user_uuid}`} className="nav-link" id="StudentProfile-tab" type="button" aria-controls="home" aria-selected="true">
                                                        Student Profile
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/approved-host/${props.user_uuid}`} className="nav-link" id="ApprovedHosts-tab" type="button" aria-controls="process" aria-selected="true">
                                                        Approved Hosts
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/leave-history/${props.user_uuid}`} className="nav-link active" id="LeaveHistory-tab" type="button" aria-controls="process" aria-selected="false">
                                                        Leave History
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/attendance-history/${props.user_uuid}`} className="nav-link" id="AttendanceHistory-tab" type="button" aria-controls="process" aria-selected="false">
                                                        Attendance History
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/pastoral-notes/${props.user_uuid}`} className="nav-link" id="PastoralNotes-tab" type="button" aria-controls="process" aria-selected="false">
                                                        Pastoral Notes
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/medical-notes/${props.user_uuid}`} className="nav-link" id="MedicalNotes-tab" type="button" aria-controls="process" aria-selected="false">
                                                        Medical Notes
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/allergy-reports/${props.user_uuid}`} className="nav-link " id="AllergyReport-tab" type="button" aria-controls="process" aria-selected="false">
                                                        Allergy Reports
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/adhoc-leave/${props.user_uuid}`} className="nav-link" id="AdhocLeave-tab" type="button" aria-controls="process" aria-selected="false">
                                                        AdhocLeave
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/adhoc-actual/${props.user_uuid}`} className="nav-link" id="AdhocLeave-tab" type="button" aria-controls="process" aria-selected="false">
                                                        Adhoc Actual
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/file-storage/${props.user_uuid}`} className="nav-link" id="AdhocLeave-tab" type="button" aria-controls="process" aria-selected="false">
                                                        File Storage
                                                    </Link>
                                                </li>
                                            </div>
                                            <div className="d-flex align-items-center gap-2">
                                                <b> {props?.studentData?.student_first_name ? props?.studentData?.student_first_name + " " + props?.studentData?.student_last_name : "BSS"}</b>
                                                <div id="topaccountmenudata" className="symbol symbol-30px symbol-md-40px">
                                                    <img alt="Logo" style={{marginBottom:"4px"}} 
                                                        src={
                                                            props?.studentData?.student_avatar !== null && props?.studentData?.student_avatar !== "" 
                                                            ? "https://bssnew.dikonia.in/" + props?.studentData?.student_avatar
                                                            : (props?.studentData?.gender === "male" 
                                                                ? "https://bssnew.dikonia.in/media/avatars/boy.jpg" 
                                                                : "https://bssnew.dikonia.in/media/avatars/girl.jpg")

                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </ul>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="card mb-5 tabs-outer">
                                                    <div className="card-header position-relative py-0 details-bg d-flex align-items-center">
                                                        <h2 className="card-title  m-0">Leave History</h2>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className='table-responsive'>
                                                            {
                                                                leaveList.map((item, index) => {
                                                                    return (
                                                                        <div className={`${index + 1 < leaveList.length ? "border-bottom" : ""}`}>
                                                                            <MDBTable>
                                                                                <MDBTableHead>
                                                                                    <tr >
                                                                                        <th className="w-40px">
                                                                                            <button
                                                                                                className={`btn ${rowVisibility[index] ? 'btn-light-secondary' : 'btn-light-secondary'} btn-sm w-32px`}
                                                                                                type="button"
                                                                                                onClick={() => handleToggleRowVisibility(index)}
                                                                                            >
                                                                                                <i
                                                                                                    className={`bi ${rowVisibility[index]
                                                                                                        ? 'bi bi-chevron-down'
                                                                                                        : 'bi bi-chevron-up'
                                                                                                        } p-0`}
                                                                                                ></i>
                                                                                            </button>
                                                                                        </th>
                                                                                        <th>Departure Date</th>
                                                                                        <th>Departure Time</th>
                                                                                        <th>
                                                                                            Mode Of Travel On Departure
                                                                                        </th>
                                                                                        <th>Return Date</th>
                                                                                        <th>Return Time</th>
                                                                                        <th>
                                                                                            Mode Of Travel On Return
                                                                                        </th>
                                                                                        <th>Parent Approval</th>
                                                                                        <th>Status - Manager</th>
                                                                                    </tr>
                                                                                </MDBTableHead>
                                                                                <MDBTableBody>
                                                                                    <tr>
                                                                                        <td></td>
                                                                                        <td>{item.departure_date}</td>
                                                                                        <td>{item.departure_time}</td>
                                                                                        <td>{item.departure_mode_data.travel_mode_name}</td>
                                                                                        <td>{item.return_date}</td>
                                                                                        <td>{item.return_time}</td>
                                                                                        <td>{item.return_mode_data.travel_mode_name}</td>
                                                                                        <td>
                                                                                            <span className={`badge badge-light-${item.is_parent_approval === 'approved' ? 'success' : item.is_parent_approval === 'rejected' ? 'danger' : 'warning'}`}>
                                                                                                {item.is_parent_approval}
                                                                                            </span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span className={`badge badge-light-${item.is_user_approval === 'approved' ? 'success' : item.is_user_approval === 'rejected' ? 'danger' : 'warning'}`}>
                                                                                                {item.is_user_approval}
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </MDBTableBody>
                                                                            </MDBTable>

                                                                            {console.log("items==================", item)}
                                                                            {rowVisibility[index] &&
                                                                                (
                                                                                    <div>
                                                                                        <MDBTable className="mb-0 text-bg-light">
                                                                                            <MDBTableHead>
                                                                                                <tr >
                                                                                                    <th className="w-40px"></th>
                                                                                                    <th className="col-md-3">Last Parent Approval Date</th>
                                                                                                    <th className="col-md-3">Last User Approval Date</th>
                                                                                                    <th className="col-md-3">Leave Applied Ontime</th>
                                                                                                    <th>Is Leaved Archived </th>
                                                                                                </tr>
                                                                                            </MDBTableHead>
                                                                                            <MDBTableBody>
                                                                                                <tr>
                                                                                                    <td></td>
                                                                                                    <td>{moment(item.last_parent_approval_date).format('YYYY-MM-DD')}</td>
                                                                                                    <td>{moment(item.last_user_approval_date).format('YYYY-MM-DD')}</td>
                                                                                                    <td>
                                                                                                        <span className={`badge badge-light-${item.leave_applied_ontime ? 'success' : 'danger'}`}>
                                                                                                            {`${item.leave_applied_ontime ? 'On time' : 'Delay'}`}
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <span className={`badge badge-light-${item.is_leave_archived ? 'success' : 'danger'}`}>
                                                                                                            {`${item.is_leave_archived ? 'Unarchive' : 'Archive'}`}
                                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </MDBTableBody>
                                                                                        </MDBTable>
                                                                                        <MDBTable className="mb-0 text-bg-light">
                                                                                            <MDBTableHead>
                                                                                                <tr >
                                                                                                    <th className="w-40px"></th>
                                                                                                    <th className="col-md-6">Parent Approved Comments</th>
                                                                                                    <th >User Approved Comments</th>
                                                                                                </tr>
                                                                                            </MDBTableHead>
                                                                                            <MDBTableBody>
                                                                                                <tr>
                                                                                                    <td></td>
                                                                                                    <td>{item.parent_approved_comments}</td>
                                                                                                    <td>{item.user_approved_comments}</td>
                                                                                                </tr>
                                                                                            </MDBTableBody>
                                                                                        </MDBTable>
                                                                                    </div>

                                                                                )
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        </>
    );
}


const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
    onHistoryOfLeaves: userActions.HistoryOfLeaves,
};
export default connect(mapStateToProps, mapDispatchToProps)(LeaveHistory);

