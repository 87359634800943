import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import * as userActions from "../../../actions/index";
import { connect } from "react-redux";
import Validations from "../../Utility/Validations";
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: state.isFocused ? "#e7f5fe" : "",
    },
    backgroundColor: state.isSelected ? "#4a8fb8" : "",
  }),
};
const BorderLeaveRejectModal = (props) => {
  const [showModal, setShowModal] = useState(false)
  const [reject_form, setRejectForm] = useState({
    modal_mode: {
      type: "text",
      label: "Remarks By Host",
      value: '',
      valid: true,
      error_msg: "",
      onBlur_out: false,
      placeholder: "",
      validations: { required: false },
    },
    is_parent_approval: {
      type: "text",
      label: "Remarks By Host",
      value: '',
      valid: true,
      error_msg: "",
      onBlur_out: false,
      placeholder: "",
      validations: { required: false },
    },
    is_user_approval: {
      type: "text",
      label: "Remarks By Host",
      value: '',
      valid: true,
      error_msg: "",
      onBlur_out: false,
      placeholder: "",
      validations: { required: false },
    },
    leave_reject_reason_id: {
      type: "hidden",
      label: "Reject Reason Id",
      value: "",
      valid: true,
      error_msg: "",
      onBlur_out: false,
      placeholder: "",
      validations: { required: true },
      options: [],
    },
    parent_rejected_reason_id: {
      type: "hidden",
      label: "Reject Reason Id",
      value: "",
      valid: true,
      error_msg: "",
      onBlur_out: false,
      placeholder: "",
      validations: { required: false },
      options: [],
    },

    user_rejected_reason_id: {
      type: "hidden",
      label: "Reject Reason Id",
      value: "",
      valid: true,
      error_msg: "",
      onBlur_out: false,
      placeholder: "",
      validations: { required: false },
      options: [],
    },
    border_leave_id: {
      type: "hidden",
      label: "Border Id",
      value: "",
      valid: true,
      error_msg: "",
      onBlur_out: false,
      placeholder: "",
      validations: { required: false },
    },
  });


  const handleSelectChange = (data, identifier, type) => {
    const update_reject_form = { ...reject_form };
    if (props.approvedBy === 'parent') {
      update_reject_form['leave_reject_reason_id'].value = data.value;
      update_reject_form['parent_rejected_reason_id'].value = data.value;
      update_reject_form['is_parent_approval'].value = 'rejected';
      update_reject_form['modal_mode'].value = 'parent';
    }
    else if (props.approvedBy === 'user') {
      update_reject_form['leave_reject_reason_id'].value = data.value;
      update_reject_form['user_rejected_reason_id'].value = data.value;
      update_reject_form['is_user_approval'].value = 'rejected';
      update_reject_form['modal_mode'].value = 'user';
    }
    setRejectForm(update_reject_form)
  }
  const closeModal = () => {
    props.hideRejectLeaveModal(false)
  }

  const SubmitHandler = async (event) => {
    // event.preventDefault();
    let is_form_valid = true;
    const addedForm = { ...reject_form };
    for (let key in addedForm) {
      let addedElement = addedForm[key];
      let validate_element = Validations(addedElement);
      is_form_valid = validate_element.valid === false ? false : is_form_valid;
      addedElement.onBlur_out = true;
      addedElement.valid = validate_element.valid;
      addedElement.error_msg = validate_element.error_msg;
      addedForm[key] = addedElement;
    }
    setRejectForm(addedForm);
    if (is_form_valid) {
      const form_data = {};
      for (let key in reject_form) {
        form_data[key] = reject_form[key].value;
      }
      props.BorderLeaveApproval(form_data);
    }
  }

  const GetAllLeaveRejectReasons = async () => {
    const update_reject_form = { ...reject_form }
    props.onGetAllLeaveRejectReasons().then((response) => {
      if (response.success === true) {
        const rejectionList = response.data.rows;
        const state_data = [];
        state_data.push({
          label: "Select Reason",
          value: "",
        })
        rejectionList.forEach((element) => {
          state_data.push({
            label: element.reject_reason,
            value: element.leave_reject_reason_id,
          })
        })
        update_reject_form['leave_reject_reason_id'].options = state_data
        setRejectForm(update_reject_form)
      }
    })
  };

  useEffect(() => {
    GetAllLeaveRejectReasons()
  }, []);

  useEffect(() => {
    setShowModal(props.showBoarderLeaveRejectModal)
    const update_reject_form = { ...reject_form }
    update_reject_form['border_leave_id'].value = props.boarderLeaveData.border_leave_id;
    setRejectForm(update_reject_form)
  }, [props]);

  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Leave Reject Reason</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="form w-100" onSubmit={SubmitHandler}>
          <div className="row">
            <div className="col-lg-12 fv-row mb-4">
              <div className="row">
                <div className="col-sm-12">
                  <label className="fw-bold mb-2 me-4">Note</label>
                  <Select
                    styles={customStyles}
                    className="react-bootstrap-typeahead tag-outer"
                    options={reject_form.leave_reject_reason_id.options}
                    onChange={(newValue) => handleSelectChange(newValue, "leave_reject_reason_id")}
                    autoComplete="off"
                  />
                  {!reject_form.leave_reject_reason_id.valid && reject_form.leave_reject_reason_id.onBlur_out ? <div className="error field-error">{reject_form.leave_reject_reason_id.error_msg}</div> : ""}
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-secondary btn-sm" onClick={closeModal}>Close</Button>
        <Button type="submit" className="btn btn-sm btn-primary px-10 btn-sm" onClick={() => SubmitHandler()}>Submit</Button>
      </Modal.Footer>
    </Modal>
  );
};


const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  onGetAllLeaveRejectReasons: userActions.GetAllLeaveRejectReasons,
};
export default connect(mapStateToProps, mapDispatchToProps)(BorderLeaveRejectModal);

