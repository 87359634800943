import React from "react";
import Validations from "../Utility/Validations";
import Sidebar from "../Constant/sidebar";
import Footer from "../Constant/Footer";
import TopBar from "../Constant/TopBar";
import Select from "react-select";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as userActions from "../../actions/index";
import { connect } from "react-redux";
import { Tooltip } from "@mui/material";

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#e7f5fe" : "",
        },
        backgroundColor: state.isSelected ? "#4a8fb8" : "",
    }),
};




const permission_info = JSON.parse(localStorage.getItem("permissions"));
const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info.school_code;

class ClassAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            class_form: {
                class_name: {
                    type: "select",
                    label: "Year Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "City",
                    validations: { required: true },
                    options: [],
                },



                class_code: {
                    type: "text",
                    label: "Year Code *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true , max_length: 3}
                },

                class_desc: {
                    type: "text",
                    label: "Year Description *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true , max_length: 8}
                },


            },
        };
    }
    componentDidMount() {
        this.GetLoginUserPermissions();
        this.GetAllManagersListBySchoolId();

    }

    GetAllManagersListBySchoolId = async (form_data) => {
        const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
        const maxclass = user_info.user_school.highest_class;
        console.log("SDadfsfdsg", user_info);
        let state_level = [{ label: "Select Year", value: "" }];
        let first_level = [];
        let second_level = [];
        let third_level = [];
        let fourth_level = [];
        // first_level.push({
        //     label: "Select Class",
        //     value: "",
        // });
        const update_class_form = { ...this.state.class_form };

        for (var i = 1; i <= maxclass; i++) {
            state_level.push({
                label: `Year ${i}`,
                value: `Year ${i}`
            })
        }


        // if (1 <= maxclass) {
        //     for (var i = 1; i <= 1; i++) {
        //         first_level.push({

        //             label: `${i}st Class`,
        //             value: `${i}st Class`,

        //         });
        //     }
        // }

        // if (2 <= maxclass) {
        //     for (i = 2; i <= 2; i++) {
        //         second_level.push({

        //             label: `${i}nd Class`,
        //             value: `${i}nd Class`,

        //         });
        //     }
        // }

        // if (3 <= maxclass) {
        //     for (i = 3; i <= 3; i++) {
        //         third_level.push({

        //             label: `${i}rd Class`,
        //             value: `${i}rd Class`,

        //         });
        //     }
        // }

        // if (4 <= maxclass) {
        //     for (i = 4; i <= maxclass; i++) {
        //         fourth_level.push({

        //             label: `${i}th Class`,
        //             value: `${i}th Class`,

        //         });
        //     }
        // }

        // const children = state_level.concat(first_level, second_level, third_level, fourth_level);
        const children = state_level;
        console.log("Classes", children);
        update_class_form['class_name'].options = children;
        this.setState({ class_form: update_class_form });

    }

    GetLoginUserPermissions() {
        this.props.onGetLoginUserPermissions().then((response) => {
            if (response.success === true) {
            } else {
            }
        });
    }

    inputChangeHandler(event, identifier) {
        const update_class_form = { ...this.state.class_form };
        const form_element = { ...update_class_form[identifier] };
        form_element.value = event.target.value;
        // Check Validation
        let validate_element = Validations(form_element);
        console.log("dddddddddddddddd",validate_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_class_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_class_form) {
            is_form_valid = update_class_form[identifier].valid && is_form_valid;
        }
        this.setState({
            class_form: update_class_form,
            is_form_valid: is_form_valid,
        });
    }

    handleChange(data, identifier, type) {
        const update_class_form = { ...this.state.class_form };
        update_class_form[identifier].value = data.value;
        this.setState({ class_form: update_class_form });
    }

    SubmitHandler = async (event) => {

        event.preventDefault();
        let is_form_valid = true;
        const update_class_form = this.state.class_form;
        for (let key in update_class_form) {
            let form_element = update_class_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_class_form[key] = form_element;
        }
        this.setState({ class_form: update_class_form });
        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.class_form) {
                form_data[key] = this.state.class_form[key].value;
            }

            this.props.onCreateClass(form_data).then((response) => {
                if (response.success === true) {
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: `/${school_code}/class-list`,
                        });
                    }, 1000);
                } else {

                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }

            });
        }
    };
    render() {
        const { class_form } = this.state;

        console.log("class_form===", class_form);

        return (
            <React.Fragment>
                {this.state.isAuthenticated ? <Navigate to={this.state.redirect_url} /> : null}

                <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed demo h-100">
                    <div className="d-flex flex-column flex-root h-100">
                        <div className="page d-flex flex-row flex-column-fluid">

                            <Sidebar></Sidebar>

                            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">

                                <TopBar></TopBar>

                                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                    <div className="post d-flex flex-column-fluid" id="kt_post">
                                        <div id="kt_content_container" className="container-fluid">
                                            <div className="school-edit-form">
                                                <div className="" id="Snapshot">
                                                    <form onSubmit={this.SubmitHandler}>
                                                        <div className="card mb-5 tabs-outer">
                                                            <div className="card-header position-relative py-0 details-bg">
                                                                <h5 className="card-title text-gray-800 fw-bolder m-0">Add Year</h5>
                                                            </div>

                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-lg-6 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{class_form.class_name.label}</label>
                                                                        <Tooltip title="This is to accommodate your school systems name conventions:- 
So here you merely count how many years this cohort has been attending school.  Also you ONLY add a “Year Code” and “Year Description *” for the applicable Year Levels you have in Boarding" placement="top">
                                                                            <i className="fas fa-question-circle ms-2"></i>
                                                                        </Tooltip>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={class_form.class_name.options}
                                                                            value={class_form.class_name.options.filter(function (option) {
                                                                                return option.value === class_form.class_name.value;
                                                                            })}
                                                                            onChange={(newValue) => this.handleChange(newValue, "class_name")}
                                                                        />

                                                                        {!class_form.class_name.valid && class_form.class_name.onBlur_out ? <div className="error field-error">{class_form.class_name.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-6 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{class_form.class_code.label} </label>
                                                                        <Tooltip title="This is just an abbreviation of “Year Description *” which may be used in interfaces which do not have sufficient space.
You might choose for “Year 10” to read “Y10” or “Form 6” to read “F6”" placement="top">
                                                                            <i className="fas fa-question-circle ms-2"></i>
                                                                        </Tooltip>
                                                                        <input
                                                                            type={class_form.class_code.type}
                                                                            value={class_form.class_code.value ? class_form.class_code.value : ""}
                                                                            className="form-control"
                                                                            placeholder={class_form.class_code.placeholder}
                                                                            onChange={(event) => this.inputChangeHandler(event, "class_code")}

                                                                        />

                                                                        {!class_form.class_code.valid && class_form.class_code.onBlur_out ? <div className="error field-error">{class_form.class_code.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-12 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{class_form.class_desc.label}</label>
                                                                        <Tooltip title="This is the typical or normal name for the cohort that your school uses on a daily basis. ie “Form 6” or “Std 10” or “Year 12”" placement="top">
                                                                            <i className="fas fa-question-circle ms-2"></i>
                                                                        </Tooltip>
                                                                        <textarea
                                                                            type={class_form.class_desc.type}
                                                                            value={class_form.class_desc.value ? class_form.class_desc.value : ""}
                                                                            className="form-control autoresizing"
                                                                            placeholder={class_form.class_desc.placeholder}
                                                                            onChange={(event) => this.inputChangeHandler(event, "class_desc")}
                                                                        />

                                                                        {!class_form.class_desc.valid && class_form.class_desc.onBlur_out ? <div className="error field-error">{class_form.class_desc.error_msg}</div> : ""}
                                                                    </div>


                                                                    {/* <div className="col-lg-6 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{class_form.class_name.label}</label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={class_form.class_name.options}
                                                                            value={class_form.class_number.options.filter(function (option) {
                                                                                return option.value === class_form.class_number.value;
                                                                            })}
                                                                            onChange={(newValue) => this.handleChange(newValue, "class_number")}
                                                                            
                                                                        />

                                                                        {!class_form.class_name.valid && class_form.class_name.onBlur_out ? <div className="error field-error">{class_form.class_name.error_msg}</div> : ""}
                                                                    </div> */}







                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="col-sm-12 d-flex justify-content-end">
                                                            <div className="d-flex align-items-end">
                                                                <button type="submit" className="btn btn-sm btn-primary apply-btn min-150px addschool-button">
                                                                    Add Year
                                                                </button>
                                                            </div>
                                                        </div>
                                                    
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Footer />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}
const mapDispatchToProps = {

    onCreateClass: userActions.CreateClass,
    onGetLoginUserPermissions: userActions.GetLoginUserPermissions,
};
export default connect(mapStateToProps, mapDispatchToProps)(ClassAdd);


