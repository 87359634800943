import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";

const EditBorderLeaveModal = (props) => {
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    setShowModal(props.showEditLeaveModal)
  }, [])

  const closeModal = () => {
    props.hideLeaveModal(false)
  };

  const updateChildState = () => {
    props.EditBoardersLeave(props.leaveData)
  };

  return (
    <div>
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Submission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you wish to change students Depart Date ?</p>
        </Modal.Body>
        <Modal.Footer>
          <div className="w-100 text-center">
            <Button type="button" onClick={closeModal} className="btn btn-secondary btn-sm me-2">Back</Button>
            <Button type='submit' className=" btn-sm" onClick={updateChildState}>Yes</Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditBorderLeaveModal;
