import axios from "axios";
import * as actionType from "../constants/actionTypes";
let qs = require("qs");


/*** CreateOrUpdateRollCall Action ***/
export const CreateOrUpdateRollCallStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.CreateOrUpdateRollCall_START,
    };
};
export const CreateOrUpdateRollCallSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.CreateOrUpdateRollCall_SUCCESS,
    };
};
export const CreateOrUpdateRollCallFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.CreateOrUpdateRollCall_FAIL,
    };
};
export const CreateOrUpdateRollCall = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    
    return (dispatch) => {
        dispatch(CreateOrUpdateRollCallStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/CreateOrUpdateRollCall`,
        })
            .then(function (response) {
                dispatch(CreateOrUpdateRollCallSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateOrUpdateRollCallFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetRollCallDetails Action ***/
export const GetRollCallDetailsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetRollCallDetails_START,
    };
};
export const GetRollCallDetailsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetRollCallDetails_SUCCESS,
    };
};
export const GetRollCallDetailsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetRollCallDetails_FAIL,
    };
};
export const GetRollCallDetails = (master_setting_uuid) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

   
    return (dispatch) => {
        dispatch(GetRollCallDetailsStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetRollCallDetails`,
            params: {
                master_setting_uuid: master_setting_uuid,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetRollCallDetailsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetRollCallDetailsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};