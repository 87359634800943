import React from "react";
import Rollcall from "./rollcall";
import Piechart, { Piechart2, Piechart3 } from "./piechart";
import Rightsidebar from "./rightsidebar";
import MedicalIssue from "./medicalissue";
import Adhocreport from "./adhocreport";
import DiscardedSession from "./DiscardedSession";
import { useNavigate } from "react-router-dom";
import Calendar from "./calendarmain";



const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info.school_code;
export default function DashboardContent() {
  const navigate = useNavigate();
  const navigateToSnapshot = () => {
    navigate(`/${school_code}/snapshot`, { replace: true });
  }
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-lg-8 col-xl-10">
              
              <Calendar /></div>
              <div className="col-lg-4 col-xl-2"><Rightsidebar navigateToSnapshot={navigateToSnapshot}></Rightsidebar></div>


            <div className="col-lg-12"><Adhocreport></Adhocreport></div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-4">
              <div className="mb-5"><Piechart></Piechart></div></div>
              <div className="col-lg-4">
              <div className="mb-5"><Piechart2></Piechart2></div></div>
              <div className="col-lg-4">
              <div className="mb-0"><Piechart3></Piechart3></div>
            </div>
          </div>
          <MedicalIssue></MedicalIssue>
          <Rollcall></Rollcall>
          <DiscardedSession></DiscardedSession>
        </div>

      </div>
      {/* <div className"row g-0 text-center">
      <div className"row gx-2">
        <div className"col">
         <div className" col-sm-6 col-md-3">
          <Piechart></Piechart> <br />
          <Piechart2></Piechart2> <br />
          <Piechart3></Piechart3></div>
        </div>
        <div className"col">
          <div className" col-sm-6 col-md-6"> <Rollcall></Rollcall></div>
        </div>
        <div className"col">
          <div className" col-sm-6 col-md-3"><Rightsidebar></Rightsidebar></div>
        </div>
      </div>
    </div> */}

      {/* <div className"container px-4 text-center">
      <div className"row gx-2">
        <div className"col">
         <div className"p-3 border bg-light"><Piechart></Piechart></div>
        </div>
        <div className"col">
        <div className"p-3 border bg-light"><Piechart2></Piechart2></div>
       </div>
        <div className"col">
          <div className"p-3 border bg-light"><Piechart3></Piechart3></div>
        </div>
      </div>
    </div> */}

      {/* <div className"container px-4 text-center">
      <div className"row gx-2">
        <div className"col">
         <div className"p-3 border bg-light"><Calendar></Calendar></div>
        </div>
        <div className"col">
        <div className"p-3 border bg-light"><Rollcall></Rollcall></div>
       </div>
        
      </div>
    </div> */}
    </>
  );
}
