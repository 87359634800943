import React from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReasonList from "../component/Reason/ReasonList";
import ReasonAdd from "../component/Reason/ReasonAdd";
import ReasonEdit from "../component/Reason/ReasonEdit";


const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" :user_info?.school_code;
const Reason = (props) => {
    const Renderview = () => {
        let location = useLocation();

        if (location.pathname === `/${school_code}/reason-list` || location.pathname === `/${school_code}/reason-list/`) {
            return <ReasonList />;
        }
        if (location.pathname === `/${school_code}/reason-list/add-reason` || location.pathname === `/${school_code}/reason-list/add-reason/`) {
            return <ReasonAdd />;
        }
        if (location.pathname === `/${school_code}/reason-list/edit-reason` || location.pathname === `/${school_code}/reason-list/edit-reason/`) {
            return <ReasonEdit /> ;
        }
    };

    return (
        <React.Fragment>
            <ToastContainer />
            {Renderview()}
        </React.Fragment>
    );
};
export default Reason;