import React from "react";

import Validations from "../../Utility/Validations";
import Sidebar from "../../Constant/sidebar";
import Footer from "../../Constant/Footer";
import TopBar from "../../Constant/TopBar";

import { toast } from "react-toastify";
import * as userActions from "../../../actions/index";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import TextareaAutosize from "react-autosize-textarea";


const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" :user_info.school_code;

class MessageAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message_add_form: {
                user_message_title: {
                    type: "text",
                    label: "Title *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Title",
                    validations: { required: true },
                },

                user_message_desc: {
                    type: "text",
                    label: "Message Description *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Description",
                    validations: { required: true },
                },
            },
        };
    }

    inputChangeHandler(event, identifier) {
        const update_message_add_form = { ...this.state.message_add_form };
        const form_element = { ...update_message_add_form[identifier] };
        form_element.value = event.target.value;
        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_message_add_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_message_add_form) {
            is_form_valid = update_message_add_form[identifier].valid && is_form_valid;
        }
        this.setState({
            message_add_form: update_message_add_form,
            is_form_valid: is_form_valid,
        });
    }
    handleChange(data, identifier, type) {
        const update_message_add_form = { ...this.state.message_add_form };
        update_message_add_form[identifier].value = data.value;
        this.setState({ message_add_form: update_message_add_form });
    }
    SubmitHandler = async (event) => {
        console.log("gfdgjdfkj");
        event.preventDefault();
        let is_form_valid = true;
        const update_message_add_form = this.state.message_add_form;
        for (let key in update_message_add_form) {
            let form_element = update_message_add_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_message_add_form[key] = form_element;
        }
        this.setState({ message_add_form: update_message_add_form });
        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.message_add_form) {
                form_data[key] = this.state.message_add_form[key].value;
            }
            this.props.onCreateUserMessage(form_data).then((response) => {
                if (response.success === true) {
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: `/${school_code}/leave/host-responsibility`,
                        });
                    }, 1000);
                } else {
                    console.log("error");
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
    
            });

            console.log("form_data===", form_data);
        }
    };
    render() {
        const { message_add_form } = this.state;

        console.log("message_add_form===", message_add_form);

        return (
            <React.Fragment>

                 {this.state.isAuthenticated ? <Navigate to={this.state.redirect_url} /> : null}

                <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed demo h-100">
                    <div className="d-flex flex-column flex-root h-100">
                        <div className="page d-flex flex-row flex-column-fluid">
                            <Sidebar></Sidebar>

                            {/* <LeftSideBar></LeftSideBar> */}

                            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                                {/* <Topbar></Topbar> */}

                                <TopBar></TopBar>
                                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                    <div className="post d-flex flex-column-fluid" id="kt_post">
                                        <div id="kt_content_container" className="container-fluid">
                                            <div className="school-edit-form ">
                                                <div className="" id="Snapshot">
                                                    <form onSubmit={this.SubmitHandler}>
                                                        <div className="card mb-5 tabs-outer">
                                                            <div className="card-header position-relative py-0 details-bg">
                                                                <h2 className="card-title  m-0">Add Message</h2>
                                                            </div>

                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-lg-4 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{message_add_form.user_message_title.label}</label>
                                                                        <input
                                                                            style={{height:"42.2222px"}}
                                                                            type={message_add_form.user_message_title.type}
                                                                            value={message_add_form.user_message_title.value ? message_add_form.user_message_title.value : ""}
                                                                            className="form-control"
                                                                            placeholder={message_add_form.user_message_title.placeholder}
                                                                            onChange={(event) => this.inputChangeHandler(event, "user_message_title")}
                                                                        />

                                                                        {!message_add_form.user_message_title.valid && message_add_form.user_message_title.onBlur_out ? <div className="error field-error">{message_add_form.user_message_title.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-8 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{message_add_form.user_message_desc.label}</label>
                                                                            <TextareaAutosize
                                                                                maxLength={400}
                                                                                style={{height:"30px"}}
                                                                                type={message_add_form.user_message_desc.type}
                                                                                value={message_add_form.user_message_desc.value ? message_add_form.user_message_desc.value : ""}
                                                                                className="form-control"
                                                                                placeholder={message_add_form.user_message_desc.placeholder}
                                                                                onChange={(event) => this.inputChangeHandler(event, "user_message_desc")}
                                                                            />

                                                                        {!message_add_form.user_message_desc.valid && message_add_form.user_message_desc.onBlur_out ? (
                                                                            <div className="error field-error">{message_add_form.user_message_desc.error_msg}</div>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-12 d-flex justify-content-end">
                                                            <div className="d-flex align-items-end">
                                                                <button type="submit" className="btn btn-sm btn-primary apply-btn min-150px addschool-button">
                                                                    Add Message
                                                                </button>
                                                            </div>
                                                        </div>
                                                            </div>
                                                        </div>

                                                       
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Footer />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}
const mapDispatchToProps = {
   
    onCreateUserMessage: userActions.CreateUserMessage,



};
export default connect(mapStateToProps, mapDispatchToProps)(MessageAdd);

