import React, { useState, useEffect, useCallback } from "react";
import Chart from "react-google-charts";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as userActions from "../../actions/index";
import Select from "react-select";

import DatePicker from "react-datepicker";


const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#e7f5fe" : "",
        },
        backgroundColor: state.isSelected ? "#4a8fb8" : "",

    }),
};
const Piechart = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [StudentCount, setStudentCount] = useState([]);
    const [ClassName, setClassName] = useState([]);
    const [Data, setData] = useState();
    const [ChartData, setChartData] = useState([]);

    const GetAllClassStudentsCountForChart = useCallback(async () => {
        dispatch(userActions.GetAllClassStudentsCountForChart()).then((response) => {
            console.log("AARRAY==========", response);
            if (response.success === true) {
                const arrayData = response.data;
                let Demo = [];
                let Final = [];
                let ClassName = [];
                let StudentCount = [];
                let result = [];

                for (let i of arrayData) {
                    ClassName.push(i.class_name)
                    StudentCount.push(JSON.parse(i.student_count));
                }
                setClassName(ClassName);
                setStudentCount(StudentCount);
                console.log("StudentCount", StudentCount);
                result = StudentCount.map((v, index) => [ClassName[index], v]);
                Final.push(result);
                Demo.push(["className", "Student"])

                const children = Demo.concat(Final[0]);
                console.log("ChartData", children)
                setChartData(children);
                console.log("FINAL", ChartData);

            }

            else if (response.status === 401) {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                setTimeout(() => {
                    navigate('/logout')

                }, 5000);
            }
            else {
            }
        });
        // eslint-disable-next-line
    }, [dispatch]);



    useEffect(() => {
        GetAllClassStudentsCountForChart();
    }, [GetAllClassStudentsCountForChart]);



    return (
        <>
            <div className="card  h-100">
                <div className="card-header">
                    <h2 className="card-title">
                        Classes
                    </h2>
                    {/* <button className="btn btn-primary" style={{ marginLeft: "190px", marginTop: "-37px", marginBottom: "20px" }}>
                        Excel
                    </button> */}
                </div>
                {console.log("chart_data=====", ChartData)}
                <Chart
                    width={"100%"}
                    height={"260px"}
                    chartType="PieChart"
                    loader={<div>Loading Pie Chart</div>}
                    data={ChartData}
                    options={{
                        title: "Class",
                        pieHole: 0.4,
                        is3D: false,
                      
                        colors: ['#61cdbb', '#f57560', '#e8a838'],
              

                    }}
                />
            </div>
        </>
    );
}

export function Piechart2() {

    const dispatch = useDispatch();
    const [present, setPresent] = useState("")
    const [absent, setAbsent] = useState("")


    const GetWeeklyAttendanceCountForChart = useCallback(async () => {
        dispatch(userActions.GetWeeklyAttendanceCountForChart()).then((response) => {
            if (response.success === true) {
                const arrayData = response.data
                console.log("arrayData=======", arrayData);
                let absent;
                let present;
                if (arrayData.length > 0) {
                    if (arrayData[0].is_attendance == false) {
                        // console.log("Response in present first")
                        absent = JSON.parse(response?.data[0]?.atten_count ? response?.data[0]?.atten_count : 0);
                        present = JSON.parse(response?.data[1]?.atten_count ? response?.data[1]?.atten_count : 0);
                        setPresent(present);
                        setAbsent(absent);

                    } else if (arrayData[0]?.is_attendance == true) {
                        present = JSON.parse(response?.data[0]?.atten_count ? response?.data[0]?.atten_count : 0);
                        absent = JSON.parse(response?.data[1]?.atten_count ? response?.data[1]?.atten_count : 0);
                        setPresent(present);
                        setAbsent(absent);

                    } else {
                        present = 1;
                        absent = 1;
                        setPresent(present);
                        setAbsent(absent);
                    }
                }
            } else { }
        });
        // eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        GetWeeklyAttendanceCountForChart()

    }, [])

    useEffect(() => {
        GetWeeklyAttendanceCountForChart();
    }, [GetWeeklyAttendanceCountForChart]);
    return (
        <>
            <div className="card h-100">
                <div className="card-header">
                    <h2 className="card-title">
                        Weekly Attendance
                    </h2>
                    {/* <button className="btn btn-primary" style={{ marginLeft: "190px", marginTop: "-37px", marginBottom: "20px" }}>
                        Excel
                    </button> */}
                </div>
                <Chart
                    width={"100%"}
                    height={"260px"}
                    chartType="PieChart"
                    loader={<div>Loading Pie Chart</div>}
                    data={present >= 1 || absent>= 1 ? [
                        ["class", "Student"],
                        [`Present  ${present}`, present],
                        [`Absent ${absent}`, absent],
                    ] : [["className", "Student"],
                    ["No Data of Present and  Abesent Student", 1]]}
                    options={{
                        title: "Attendance",
                        pieHole: 0.4,
                        is3D: false,
                        colors: ['#f57560', '#61cdbb'],
                    }}
                />
            </div>
        </>
    );
}

export function Piechart3() {

    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(new Date());
    const [present, setPresent] = useState("")
    const [absent, setAbsent] = useState("")
    const [allWeeks, setAllWeeks] = useState([])



    const [year, setYear] = useState()
    const [month, setMonth] = useState()
    const [start_date, setStart_Date] = useState('')
    const [end_date, setEnd_Date] = useState('')


    const [SL_Count, setSL_Count] = useState(0)
    const [KL_Count, setKL_Count] = useState(0)
    const [MIA_Count, setMIA_Count] = useState(0)
    const [OC_Count, setOC_Count] = useState(0)
    const [L_Count, setL_Count] = useState(0)


    const [noData, setNoData] = useState(true)

    const WeekWiseAttendanceReasonsChart = () => {
        dispatch(userActions.WeekWiseAttendanceReasonsChart(start_date, end_date)).then((response) => {
            if (response.success === true) {
                let arrayData = response.countAttennd
                const arr = [
                    { reason_name: "SL", count: 0 },
                    { reason_name: "KL", count: 0 },
                    { reason_name: "MIA", count: 0 },
                    { reason_name: "On Campus", count: 0 },
                    { reason_name: "L", count: 0 }
                ]
                arr.forEach((items) => {
                    arrayData.forEach((item) => {
                        if (items?.reason_name === item?.atten_reason_data?.reason_name) {
                            items.count = JSON.parse(item.reason_count);
                        }
                    })
                })
                setSL_Count(arr[0].count);
                setKL_Count(arr[1].count);
                setMIA_Count(arr[2].count);
                setOC_Count(arr[3].count);
                setL_Count(arr[4].count);
            } else { }
        });
    };

    const ResetAllReasonsdata = () => {
        dispatch(userActions.ResetAllReasonsdata()).then((response) => {
            if (response.success === true) {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });

            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });

            }
        });
    };





    const setDefaultValue = () => {
        const fullYear = new Date().getUTCFullYear()
        const fullMonth = new Date().getUTCMonth()
        setYear(fullYear)
        setMonth(fullMonth + 1)
        const day_array = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
        const month_length = new Date(fullYear, fullMonth + 1, 0).getDate();
        const get_day = day_array[new Date(fullYear, fullMonth).getDay()];
        const weeks_data = []
        let week_array;
        const month_dates = []
        for (let i = 1; i <= month_length; i++) {
            month_dates.push(i)
        }
        if (get_day === 'Sun') {
            const all_weeks = Math.floor(month_length / 7);
            const remain_last_day = month_length % 7;
            const all_weeks_slice = month_dates.slice(0, all_weeks * 7)
            const remaining_days = month_dates.slice((all_weeks * 7), month_dates.length)
            weeks_data.push(remaining_days)
            for (let i = 1; i <= all_weeks; i++) {
                const thirdPart = all_weeks_slice.splice(-7);
                weeks_data.push(thirdPart)
            }
            week_array = weeks_data.map((item, index) => {
                return {
                    label: `${item[0]} - ${item[item.length - 1]}`,
                    value: `${item[0] <= 9 ? "0" + item[0] : item[0]} - ${item[item.length - 1] <= 9 ? "0" + item[item.length - 1] : item[item.length - 1]}`
                }
            })
        } else {
            const pre_week_days = day_array.length - day_array.indexOf(get_day)
            const remain_last_day = (month_length - pre_week_days) % 7;
            const all_weeks = Math.floor((month_length - pre_week_days) / 7);
            const pre_days_slice = month_dates.slice(0, pre_week_days)
            const all_weeks_slice = month_dates.slice(pre_week_days, (all_weeks * 7) + pre_week_days)
            const remaining_days = month_dates.slice((all_weeks * 7) + pre_week_days, month_dates.length)
            if (remaining_days.length > 0) {
                weeks_data.push(remaining_days);
            }
            for (let i = 1; i <= all_weeks; i++) {
                const thirdPart = all_weeks_slice.splice(-7);
                weeks_data.push(thirdPart)
            }
            weeks_data.push(pre_days_slice)
            week_array = weeks_data.map((item, index) => {
                return {
                    label: `${item[0]} - ${item[item.length - 1]}`,
                    value: `${item[0] <= 9 ? "0" + item[0] : item[0]} - ${item[item.length - 1] <= 9 ? "0" + item[item.length - 1] : item[item.length - 1]}`
                }
            })
        }
        setAllWeeks(week_array.reverse())


        let curDate = new Date().getUTCDate()
        let date_week;
        week_array.forEach((item) => {
            if (curDate >= parseInt(item.value.slice(0, 2)) && curDate <= parseInt(item.value.slice(-2))) {
                date_week = item.value;
            }
        })
        let date_month = fullMonth + 1 <= 9 ? `0${fullMonth + 1}` : `${fullMonth + 1}`;
        let start_dates = `${fullYear}-${date_month}-${date_week.slice(0, 2)}`;
        let end_dates = `${fullYear}-${date_month}-${date_week.slice(-2)}`;
        setStart_Date(start_dates)
        setEnd_Date(end_dates)
    }

    useEffect(() => {
        setDefaultValue()
    }, [])

    useEffect(() => {
        WeekWiseAttendanceReasonsChart()
    }, [startDate, end_date])

    const handleChange = (date) => {
        setStartDate(date)
        const fullYear = new Date(date).getUTCFullYear()
        const fullMonth = new Date(date).getUTCMonth()
        setYear(fullYear)
        setMonth(fullMonth + 1)
        const day_array = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
        const month_length = new Date(fullYear, fullMonth + 1, 0).getDate();
        const get_day = day_array[new Date(fullYear, fullMonth).getDay()];
        const weeks_data = []
        let week_array;
        const month_dates = []
        for (let i = 1; i <= month_length; i++) {
            month_dates.push(i)
        }
        if (get_day === 'Sun') {
            const all_weeks = Math.floor(month_length / 7);
            const remain_last_day = month_length % 7;
            const all_weeks_slice = month_dates.slice(0, all_weeks * 7)
            const remaining_days = month_dates.slice((all_weeks * 7), month_dates.length)
            weeks_data.push(remaining_days)
            for (let i = 1; i <= all_weeks; i++) {
                const thirdPart = all_weeks_slice.splice(-7);
                weeks_data.push(thirdPart)
            }
            week_array = weeks_data.map((item, index) => {
                return {
                    label: `${item[0]} - ${item[item.length - 1]}`,
                    value: `${item[0] <= 9 ? "0" + item[0] : item[0]} - ${item[item.length - 1] <= 9 ? "0" + item[item.length - 1] : item[item.length - 1]}`
                }
            })
        } else {
            const pre_week_days = day_array.length - day_array.indexOf(get_day)
            const remain_last_day = (month_length - pre_week_days) % 7;
            const all_weeks = Math.floor((month_length - pre_week_days) / 7);
            const pre_days_slice = month_dates.slice(0, pre_week_days)
            const all_weeks_slice = month_dates.slice(pre_week_days, (all_weeks * 7) + pre_week_days)
            const remaining_days = month_dates.slice((all_weeks * 7) + pre_week_days, month_dates.length)
            if (remaining_days.length > 0) {
                weeks_data.push(remaining_days);
            }
            for (let i = 1; i <= all_weeks; i++) {
                const thirdPart = all_weeks_slice.splice(-7);
                weeks_data.push(thirdPart)
            }
            weeks_data.push(pre_days_slice)
            week_array = weeks_data.map((item, index) => {
                return {
                    label: `${item[0]} - ${item[item.length - 1]}`,
                    value: `${item[0] <= 9 ? "0" + item[0] : item[0]} - ${item[item.length - 1] <= 9 ? "0" + item[item.length - 1] : item[item.length - 1]}`
                }
            })
        }
        setAllWeeks(week_array.reverse())
    }


    const setWeekforPiechart = (event, data) => {
        let date_month = month <= 9 ? `0${month}` : `${month}`;
        const date_week = data.value;
        let start_dates = `${year}-${date_month}-${date_week.slice(0, 2)}`;
        let end_dates = `${year}-${date_month}-${date_week.slice(-2)}`;
        setStart_Date(start_dates)
        setEnd_Date(end_dates)
    }



    const ExportWeeklyAttendanceReasonsPdf = (event) => {
        dispatch(userActions.ExportWeeklyAttendanceReasonsPdf(start_date, end_date)).then((response) => {
            if (response.success === true) {
                const csv_url = response.file;
                DownloadPdf(csv_url);
                // GetAllCourses();
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
            else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    };
    const DownloadPdf = (csv_url) => {
        const link = document.createElement("a");
        link.href = csv_url;
        link.target = "_blank"
        // link.setAttribute("download");
        document.body.appendChild(link);
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }

    return (
        <>
            <div className="card">
                <div className="card-header ">
                    <div className="d-flex align-items-center">
                        <h2 className="card-title">
                            Weekly Absentee
                        </h2>
                    </div>

                </div>
                <div className="d-flex align-items-center justify-content-end weekly-wrap">
                    <button onClick={(event) => ExportWeeklyAttendanceReasonsPdf(event)} className="btn  btn-primary btn-block pull-right btn-sm">PDF</button>


                    <button onClick={(event) => ResetAllReasonsdata(event)} className=" btn btn-secondary  btn-block pull-right btn-sm ">Reset</button>


                    <DatePicker
                        selected={startDate}
                        showMonthYearPicker
                        dateFormat="MM-yyyy"
                        className="form-control"
                        onChange={(event) => handleChange(event)}
                    />

                </div>

                <div className="piechart">
                    {console.log("arrayData=======", KL_Count, SL_Count, MIA_Count, OC_Count, L_Count)}
                    <Chart
                        width={"100%"}
                        height={"200px"}
                        chartType="PieChart"
                        loader={<div>Loading Pie Chart</div>}
                        data={KL_Count > 0 || SL_Count > 0 || MIA_Count > 0 || OC_Count > 0 ? [
                            ["className", "Student"],
                            [`KL ${KL_Count}`, KL_Count],
                            [`SL ${SL_Count}`, SL_Count],
                            [`MIA ${MIA_Count}`, MIA_Count],
                            [`OC ${OC_Count}`, OC_Count],
                        ] : [
                            ["className", "Student"],
                            ["No Data of this week", 1],
                        ]
                        }
                        options={{
                            title: "Absent Reason",
                            pieHole: 0.4,
                            is3D: false,
                            colors: ['#f57560', '#61cdbb'],
                        }}
                    />
                    <div className="d-flex justify-content-around mb-4">
                        {
                            allWeeks.map((item, index) => {
                                return (
                                    <span onClick={(event) => setWeekforPiechart(event, item)} className="cursor-pointer fw-bold hover-zoom link-secondary" style={{ fontSize: "12px" }}>Week {index + 1}</span>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Piechart;