import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import "react-datepicker/dist/react-datepicker.css";
import * as actionType from "../../../constants/actionTypes";
import Dropdown, { DropdownSubmenu } from 'react-bootstrap/Dropdown';
import { Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import BlinkingDot from "./BlinkingDot";
import moment from "moment";


// For The ADHOC Status Expire (Red Blink Dot) 
const CustomAdhocCell = ({ row, currentTime, currentDate }) => {
    const [studentAdhocEndTime, setStudentAdhocEndTime] = useState(null);
    const [studentAdhocStatus, setStudentAdhocStatus] = useState(null);
    const [studentAdhocEndDate, setStudentAdhocEndDate] = useState(null);
    const filterData = row?.stu_adhoc_data.filter((data) => data?.check_in_out === "out");

    useEffect(() => {
        if (row?.stu_adhoc_data.length > 0) {
            const lastAdhocData = filterData[0];
            setStudentAdhocEndTime(moment(lastAdhocData?.end_time, 'HH:mm:ss').format('HH:mm'));
            setStudentAdhocStatus(lastAdhocData?.check_in_out);
            setStudentAdhocEndDate(moment(lastAdhocData?.arrival_date).format('MMMM Do YYYY'));
        }
    }, [filterData]);

    const currentMoment = moment(`${currentDate} ${currentTime}`, 'MMMM Do YYYY HH:mm');
    const studentAdhocEndMoment = moment(`${studentAdhocEndDate} ${studentAdhocEndTime}`, 'MMMM Do YYYY HH:mm');

    const isBlinkDot = currentMoment.isAfter(studentAdhocEndMoment) && studentAdhocStatus === "out" && row?.stu_adhoc_data.length > 0;

    return (
        <div className="h-100 adhoc-hover">
            {console.log(currentMoment.format(), studentAdhocEndMoment.format(), isBlinkDot, row?.stu_adhoc_data.length > 0, "::SDFFF")}
            <span className="sub-heading text-center text-white" style={{ opacity: row?.stu_adhoc_data.length ? "1" : "0.6" }}>
                ADHOC <span className={isBlinkDot ? "blinkDot" : ""}></span>
            </span>
            {row?.stu_adhoc_data.length > 0 &&
                <div className="hover-adhoc text-white">
                    {row?.stu_adhoc_data.map((item, index) => {
                        return (
                            <div key={index}>
                                <p className="p-0 m-0">
                                    {(item?.bss_adhoc_leave_detail?.manager_inst.length > 0 || item?.bss_adhoc_leave_detail?.parents_inst.length > 0) && <span className="text-danger fw-bolder fs-4 me-1">!</span>}
                                    <span>{item?.bss_adhoc_leave_detail?.adhoc_activity_data?.activity_name}</span> :
                                    <span>{moment(item.start_time, 'HH:mm:ss').format('hh:mm A')}</span>-
                                    <span>{moment(item?.end_time, 'HH:mm:ss').format('hh:mm A')}</span>
                                </p>
                            </div>
                        );
                    })}
                </div>
            }
        </div>
    );
};

//For The Leave Status Expire (Red Blink Dot)
const CustomLeaveCell = ({ row, currentTime, currentDate }) => {
    const [studentLeaveEndTime, setStudentLeaveEndTime] = useState(null);
    const [studentLeaveStatus, setStudentLeaveStatus] = useState(null);
    const [studentLeaveEndDate, setStudentLeaveEndDate] = useState(null);
    useEffect(() => {
        if (row?.stu_leave_data.length > 0) { 
            const lastAdhocData = row.stu_leave_data[0];
            setStudentLeaveEndTime(moment(lastAdhocData?.return_students_data?.planned_return_time, 'HH:mm:ss').format('HH:mm'));
            setStudentLeaveStatus(lastAdhocData?.check_in_out);
            setStudentLeaveEndDate(moment(lastAdhocData?.return_students_data?.planned_return_date).format('MMMM Do YYYY'));
        }
    }, [row?.stu_leave_data]);

    const currentMoment = moment(`${currentDate} ${currentTime}`, 'MMMM Do YYYY HH:mm');
    const studentLeaveEndMoment = moment(`${studentLeaveEndDate} ${studentLeaveEndTime}`, 'MMMM Do YYYY HH:mm');

    const isBlinkDot = currentMoment.isAfter(studentLeaveEndMoment) && studentLeaveStatus === "out" &&  row?.stu_leave_data.length > 0;
    
    {console.log(currentMoment.format(), studentLeaveEndMoment.format(),studentLeaveEndDate, studentLeaveEndTime, isBlinkDot, row?.stu_leave_data.length > 0, "::SDxFFF")}

    return (
        <div className=" yellow-light-bg h-100 leave-hover" style={row?.stu_leave_data.length > 0 ? null : {backgroundColor:"rgb(253, 101, 0, 0.5)"}}>
            <span className="sub-heading text-center text-white" style={{opacity:row?.stu_leave_data.length ? "1" : "0.6"}}>
                Leave <span className={isBlinkDot ? "blinkDot" : ""}></span>
            </span> 
            {row?.stu_leave_data.length > 0 &&
                <div className="hover-leave text-white">
                    {row?.stu_leave_data.map((leave, index) => {
                        return (
                            <div style={{color: "white", marginTop: index == 0?"0px" :"10px"}}>
                                {leave?.stu_host_data === null ?
                                    (leave.parent_type === "mother" ?
                                        <span className="d-block">Host: Mother:{" "}{leave.parent_data.mother_name.charAt(0).toUpperCase() +  leave.parent_data.mother_name.slice(1)}</span> :
                                        <span className="d-block">Host: Father:{" "}{leave.parent_data.father_name.charAt(0).toUpperCase() +  leave.parent_data.father_name.slice(1)}</span>) :
                                    <span className="d-block">Host: Name:{" "+(leave?.stu_host_data?.host_name)}</span>

                                }
                                <span className="d-block">Depart:{" "+leave?.departure_date}<span style={{marginLeft:"5px"}}>{moment(leave?.departure_time,"hh:mm:ss A").format("hh:mm") == "Invalid date"? "--":moment(leave?.departure_time,"hh:mm:ss A").format("hh:mm A")}</span></span>
                                <span className="d-block">Return:{" "+leave?.return_date}<span style={{marginLeft:"5px"}}>{moment(leave?.return_time,"hh:mm:ss A").format("hh:mm") == "Invalid date"? "--":moment(leave?.return_time,"hh:mm:ss A").format("hh:mm A")}</span></span>
                                
                            </div>
                        )
                    })}
                </div>
            }
        </div>

    );
};

const SnapshotItem = (props) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    console.log("sssssssssssssssssssss", props)
    const school_code = user_info.school_code;
    const permission_info = JSON.parse(localStorage.getItem("permissions"));
    const comparision = JSON.parse(localStorage.getItem("Comparision"));
    const [perpagedata, setperpagedata] = useState(props.StudentListcount);
    const role_type = user_info?.role_type
    var on_campus = props.on_campus_location;
    var reasArr1 = props.ReasonData
    var reasArr2 = reasArr1.slice(1, 4)
    var reasArr3 = reasArr2.filter((item) => { if (item.value !== 2) { return item } })
    var reasons = reasArr3
    const [currentTime, setCurrentTime] = useState();
    const [currentDate, setCurrentDate] = useState();

    const [studentAdhocEndTime, setStudentAdhocEndTime] = useState();
    const [studentAdhocStatus, setStudentAdhocStatus] = useState();
    
    // To get the current time and date only base on school timezone
    const timezone = user_info?.user_school?.timezone;
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(moment().tz(timezone).format('HH:mm'));
            setCurrentDate(moment().tz(timezone).format('MMMM Do YYYY'))
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);

    const setPerpagePagination = (event) => {
        console.log("sssssssssssssssssssss==============", event.target.value)
        props.propsfilterpage(1);
        setperpagedata(event.target.value);
        props.propsfilterlimit(parseInt(event.target.value));
    }
    useEffect(() => {
        if (props.filteredData === "") {
            setperpagedata(props.StudentListcount);
        }
    }, [props.filteredData])
    const columns = [{
        text: "",
        dataField: "Actions",
        formatter: (cellContent, row, index, extraData) => {
            return (
                <div className="row">
                    <div className="col-sm-12 d-flex">
                        <div className="box-one">
                        <div className="d-flex justify-content-end position-relative" 
                            style={
                                row?.current_loc_data === null 
                                    ? { backgroundColor: "transparent" } 
                                    : row?.current_loc_data?.reason_id === 4 
                                        ? { backgroundColor: "transparent" } 
                                        : row?.current_loc_data?.reason_id !== null 
                                            ? row?.current_loc_data?.current_loc_reason?.reason_name === "SL" 
                                                ? { backgroundColor: "rgba(255, 101, 0, 0.5)" } 
                                                :row?.current_loc_data?.current_loc_reason?.reason_name==="MIA"  ? {backgroundColor: "rgba(203, 0, 50, 0.5)"} :row?.current_loc_data?.current_loc_reason?.reason_name=== 'ad-hoc'? {backgroundColor: "rgba(253, 225, 1, 0.5)"} :{backgroundColor: "transparent"}
                                            : row?.current_loc_data?.current_present_status === true 
                                                ? { backgroundColor: "transparent" } 
                                                : row?.current_loc_data?.current_loc_oncampus !== null 
                                                    ? { backgroundColor: "transparent" } 
                                                    : { backgroundColor: "transparent" }
                            }
                        >
                        
                                <img alt="Logo" className="w-100 alt-img-wrap img-wrap-style"
                                    style={
                                        row?.current_loc_data === null 
                                            ? { opacity: "1" } 
                                            : row?.current_loc_data?.reason_id === 4 
                                                ? { opacity: "1" } 
                                                : row?.current_loc_data?.reason_id !== null 
                                                    ? row?.current_loc_data?.current_loc_reason?.reason_name === "SL" 
                                                        ? { opacity: "0.5" } 
                                                        :row?.current_loc_data?.current_loc_reason?.reason_name==="MIA"  ? {opacity: "0.5"} :row?.current_loc_data?.current_loc_reason?.reason_name=== 'ad-hoc'? {opacity: "0.5"} :{opacity: "1"}
                                                    : row?.current_loc_data?.current_present_status === true 
                                                        ? { opacity: "1" } 
                                                        : row?.current_loc_data?.current_loc_oncampus !== null 
                                                            ? { opacity: "1" } 
                                                            : { opacity: "1" }
                                    }
                                    src={row?.student_avatar === null || row?.student_avatar === '' || row?.display_image_required === false ? row?.gender === 'female' ?
                                        "/media/avatars/girl.jpg" :
                                        "/media/avatars/boy.jpg" : actionType.environment_url + '/' + row?.student_avatar}
                                // src={permission_info?.dis_stu_avatar_onsnapshot !== false ? (row?.student_avatar === null || row?.student_avatar === '' ? row?.gender === 'female' ?
                                //     "/media/avatars/girl.jpg" :
                                //     "/media/avatars/boy.jpg" : actionType.environment_url + '/' + row?.student_avatar) : "/media/avatars/boy.jpg"}
                                />
                                <div className="justify-content-between top-wrap-block">
                                    <div className="row">
                                        <div className="col-6 ps-0">
                                            <ul className="list-icons-wrap">
                                                <li>
                                                    {
                                                        role_type === 1 || role_type === 2 || role_type === 3 ?
                                                            (
                                                                // rollcall_compare_status -- check from backend, is_loc_changed--- check from compare rollcall, comparision--- from rollcall to compare or not compare students location
                                                                (
                                                                    row.rollcall_compare_status === true &&
                                                                        row.current_loc_data?.is_loc_changed === true && comparision == 'true' || comparision == true ?
                                                                        // (row.current_loc_data.current_present_status === true && row.current_loc_data.is_loc_changed === true ?
                                                                        (row?.campus_or_reasons_data[0]?.reason_id !== row?.campus_or_reasons_data[1]?.reason_id ||
                                                                            row?.campus_or_reasons_data[0]?.sign_in_out_status !== row?.campus_or_reasons_data[1]?.sign_in_out_status ||
                                                                            row?.campus_or_reasons_data[0]?.on_campus_location_id !== row?.campus_or_reasons_data[1]?.on_campus_location_id
                                                                            ?
                                                                            <div>
                                                                                <div className="ocm-hover h-100 w-10">
                                                                                    <span id={row?.student_id}><BlinkingDot id={row?.student_id} navigateToSnapshot={props.navigateToSnapshot} /></span>
                                                                                    <div className="hover-oc text-white blink-block">
                                                                                        {
                                                                                            // row.current_loc_data.is_loc_changed === true 
                                                                                            row.current_loc_data?.current_present_status === true && row.current_loc_data?.is_loc_changed === false ? "P" :
                                                                                                row.current_loc_data?.current_present_status === true && row?.campus_or_reasons_data[0]?.sign_in_out_status === 2 ?
                                                                                                    (row?.campus_or_reasons_data[0]?.on_campus_location_id === null && row?.campus_or_reasons_data[0]?.reason_id === 4 ? "OC" :
                                                                                                        row?.campus_or_reasons_data[0]?.on_campus_location_id === null && row?.campus_or_reasons_data[0]?.reason_id === 3 ? "KL" :
                                                                                                            row?.campus_or_reasons_data[0]?.on_campus_location_id === null && row?.campus_or_reasons_data[0]?.reason_id === 2 ? "SL" :
                                                                                                                row?.campus_or_reasons_data[0]?.on_campus_location_id === null && row?.campus_or_reasons_data[0]?.reason_id === 1 ? "MIA" :
                                                                                                                    row?.campus_or_reasons_data[0]?.reason_id === null && row?.campus_or_reasons_data[0]?.on_campus_location_id !== null ? "OC" : "OC")
                                                                                                    :
                                                                                                    row.current_loc_data?.current_present_status === false && row?.campus_or_reasons_data[0]?.sign_in_out_status === 1 ?

                                                                                                        (
                                                                                                            row?.campus_or_reasons_data[1]?.sign_in_out_status === 2 ? "P" :
                                                                                                                row?.campus_or_reasons_data[1]?.on_campus_location_id === null && row?.campus_or_reasons_data[1]?.reason_id === 4 ? "OC" :
                                                                                                                    row?.campus_or_reasons_data[1]?.on_campus_location_id === null && row?.campus_or_reasons_data[1]?.reason_id === 3 ? "KL" :
                                                                                                                        row?.campus_or_reasons_data[1]?.on_campus_location_id === null && row?.campus_or_reasons_data[1]?.reason_id === 2 ? "SL" :
                                                                                                                            row?.campus_or_reasons_data[1]?.on_campus_location_id === null && row?.campus_or_reasons_data[1]?.reason_id === 1 ? "MIA" :
                                                                                                                                row?.campus_or_reasons_data[1]?.reason_id === null && row?.campus_or_reasons_data[1]?.on_campus_location_id !== null ? "OC"
                                                                                                                                    : "OC")
                                                                                                        : "OC"
                                                                                        }

                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                            : null)
                                                                        : null)
                                                                // : null)
                                                            )

                                                            : null
                                                    }
                                                </li>
                                                {/* Generic Data */}
                                                <li style={{width:"20px"}}>
                                                    <span>
                                                        {row.generic_data.length > 0 ?
                                                            <img style={{ background: "#A64D79" }} src="/media/writing.png" alt="" />

                                                            : <img src="/media/writing.png" alt="" style={{ background: "#b1b1b1" }} />
                                                        }
                                                    </span>
                                                    <div className={row?.generic_data == '' ? "" : "hover-block"}>{row?.generic_data ? row?.generic_data[0]?.generic_desc : ""}</div>
                                                </li>
                                                {/* Allergy Details */}
                                                <li style={{width:"20px"}}>
                                                    <span>
                                                        {row.allergy_details.length > 0 ?
                                                            <img style={{ background: "#49BBFB" }} src="/media/letter-a.png" alt="" />
                                                            :
                                                            <img src="/media/letter-a.png" alt="" style={{ background: "#b1b1b1" }} />
                                                        }
                                                    </span>
                                                    <div className={row?.allergy_details == '' ? "" : "hover-block"}>{Array.isArray(row?.allergy_details) ?
                                                        row?.allergy_details.map(item => { return <li>{item.allergy_name}</li> })
                                                        : ""}
                                                    </div>
                                                </li>
                                                {/* Grounded Data */}
                                                <li style={{width:"20px"}}>
                                                    <span>
                                                        {row.grounded_data.length > 0 ?
                                                            <img style={{ background: "#7239EA" }} src="/media/anchor-new.png" alt="" />
                                                            :
                                                            <img src="/media/anchor-new.png" alt="" style={{ background: "#b1b1b1" }} />
                                                        }
                                                    </span>
                                                    <div className={row?.grounded_data == '' ? "" : "hover-block"}>
                                                        <div>
                                                            {row?.grounded_data ? row?.grounded_data[0]?.snapshot_desc : ""}
                                                        </div>
                                                        <div>
                                                            {row?.grounded_data.length > 0 ? `${row?.grounded_data[0]?.grounded_start_date} to ${row?.grounded_data[0]?.grounded_end_date}` : ""}

                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-6 mb-3 p-0">
                                            <div class="row me-1">
                                                <div className="text-end p-0">
                                                    {
                                                        (row?.current_loc_data?.current_loc_reason?.reason_name != "SL" && row?.current_loc_data?.current_loc_reason?.reason_name != "ad-hoc") && 
                                                            <input
                                                                className="form-check-input m-0 "
                                                                type="checkbox"
                                                                stu_id={row.student_id}
                                                                value={row.checked}
                                                                checked={row.checked}
                                                                onClick={(event) => {
                                                                    props.toggleChecked(event);
                                                                }}
                                                            >
                                                            </input>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row btn-menu me-0">
                                                <div className="text-end p-0">
                                                    {row.checked && (row?.current_loc_data?.current_loc_reason?.reason_name != "SL" && row?.current_loc_data?.current_loc_reason?.reason_name != "ad-hoc")  ?
                                                        <span className="text-white d-inline mx-1" style={{ backgroundColor: '#009A66', borderRadius: '8px', }}>
                                                            {row.current_loc_data ?
                                                                (props.reason_Name &&
                                                                    row.current_loc_data.current_loc_oncampus ?
                                                                    (row.current_loc_data.current_loc_oncampus.location_name != props.reason_Name ?
                                                                        props.reason_Name : props.reason_Name) :
                                                                    row.current_loc_data.current_loc_reason ?
                                                                        (row.current_loc_data.current_loc_reason.reason_name !== props.reason_Name ?
                                                                            props.reason_Name : props.reason_Name) :
                                                                        row.current_loc_data.current_present_status === true ?
                                                                            (props.reason_Name !== 'present' ? props.reason_Name : props.reason_Name)
                                                                            : props.reason_Name
                                                                ) : props.reason_Name}
                                                        </span>
                                                        :
                                                        <Dropdown>
                                                            <Dropdown.Toggle id="dropdown-basic">
                                                                <img src="/media/menu.png" alt="" />
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>

                                                                {/* <ul className="list">   */}
                                                                {/* {console.log("data",row?.current_loc_data?.current_loc_oncampus === null && row?.current_loc_data?.current_loc_reason === null)} */}

                                                                {/* Condition to show change status when status is present */}
                                                                {/* {row?.current_loc_data?.current_loc_oncampus === null && row?.current_loc_data?.current_loc_reason === null ? */}
                                                                {row?.current_loc_data?.current_loc_reason?.reason_name === "SL" ?
                                                                    <Dropdown.Item>
                                                                        <button className="btn-menu" onClick={() => props.showReturnApprovalModal(true, row)}>
                                                                            Return Student
                                                                        </button>
                                                                    </Dropdown.Item> :
                                                                    <div>
                                                                        {/* <li className="subdropblock"> */}
                                                                        {/* <Dropdown.Toggle > */}
                                                                        <div >
                                                                            {/* <Dropdown>
                                                                            <Dropdown.Toggle id="dropdown-basic">
                                                                                On Campus
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu>
                                                                                {on_campus?.map(item => {
                                                                                    return <Dropdown.Item
                                                                                        style={row?.current_loc_data?.on_campus_location_id === item?.on_campus_location_id ? { opacity: "0.6" } : { opacity: "1" }}
                                                                                        disabled={row?.current_loc_data?.on_campus_location_id === item?.on_campus_location_id ? true : false}
                                                                                    >
                                                                                        <button
                                                                                            style={{ color: "white", fontsize: "10px" }}
                                                                                            id={row?.student_id}
                                                                                            campus={item?.on_campus_location_id}
                                                                                            onClick={(e) => {
                                                                                                props.ChangeStatus(e)
                                                                                            }
                                                                                            }
                                                                                        >
                                                                                            {item?.location_name}
                                                                                        </button>
                                                                                    </Dropdown.Item>
                                                                                })}
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>*/}
                                                                        </div>
                                                                        <div >
                                                                            <Dropdown>
                                                                                <Dropdown.Toggle id="dropdown-basic">
                                                                                    Change Status
                                                                                </Dropdown.Toggle>

                                                                                <Dropdown.Menu>
                                                                                    {reasons?.map(item => {
                                                                                        return < Dropdown.Item
                                                                                            style={row?.current_loc_data?.reason_id === item?.reason_Id ? { opacity: "0.6" } : { opacity: "1" }}
                                                                                            disabled={row?.current_loc_data?.reason_id === item?.reason_Id ? true : false}
                                                                                        >
                                                                                            <button className="btn-menu"
                                                                                                style={{ color: "white", fontsize: "10px", }}
                                                                                                id={row?.student_id}
                                                                                                reason={item?.reason_Id}
                                                                                                // onClick={(event) => {
                                                                                                //    
                                                                                                //     props.ChangeStatus(event);   
                                                                                                // }}
                                                                                                onClick={(e) => {
                                                                                                    console.log("Checking OnClick in dropdown", row.student_id)
                                                                                                    props?.ChangeStatus(e);
                                                                                                }}
                                                                                            >
                                                                                                {item?.label}
                                                                                            </button>
                                                                                        </Dropdown.Item>
                                                                                    })}



                                                                                    <Dropdown>
                                                                                        <Dropdown.Toggle id="dropdown-basic">
                                                                                            On Campus
                                                                                        </Dropdown.Toggle>
                                                                                        <Dropdown.Menu>
                                                                                            {on_campus?.map(item => {
                                                                                                return <Dropdown.Item
                                                                                                    style={row?.current_loc_data?.on_campus_location_id === item?.on_campus_location_id ? { opacity: "0.6" } : { opacity: "1" }}
                                                                                                    disabled={row?.current_loc_data?.on_campus_location_id === item?.on_campus_location_id ? true : false}
                                                                                                >
                                                                                                    <button
                                                                                                        style={{ color: "white", fontsize: "10px" }}
                                                                                                        id={row?.student_id}
                                                                                                        campus={item?.on_campus_location_id}
                                                                                                        onClick={(e) => {
                                                                                                            props.ChangeStatus(e)
                                                                                                        }
                                                                                                        }
                                                                                                    >
                                                                                                        {item?.location_name}
                                                                                                    </button>
                                                                                                </Dropdown.Item>
                                                                                            })}
                                                                                        </Dropdown.Menu>
                                                                                    </Dropdown>



                                                                                    <Dropdown.Item id={row.student_id}
                                                                                        style={row?.current_loc_data?.current_present_status === true ? { opacity: "0.6" } : { opacity: "1" }}
                                                                                        disabled={row?.current_loc_data?.current_present_status === true ? true : false}
                                                                                        stu_reason_oncampus_uuid={row.campus_or_reasons_data.length === 0 ?
                                                                                            "" :
                                                                                            row.campus_or_reasons_data[0].stu_reason_oncampus_uuid}
                                                                                        onClick={(event) => {
                                                                                            props.ChangeStatusPresent(event);
                                                                                        }} >
                                                                                        Present
                                                                                    </Dropdown.Item>
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </div>
                                                                    </div>
                                                                }

                                                                {/* <li><Dropdown.Item id={row.student_id}
                                                                    style={row?.current_loc_data?.current_present_status === true ? { opacity: "0.6" } : { opacity: "1" }}
                                                                    disabled={row?.current_loc_data?.current_present_status === true ? true : false}
                                                                    stu_reason_oncampus_uuid={row.campus_or_reasons_data.length === 0 ?
                                                                        "" :
                                                                        row.campus_or_reasons_data[0].stu_reason_oncampus_uuid}
                                                                    onClick={(event) => {
                                                                        props.ChangeStatusPresent(event);
                                                                    }} >
                                                                    Present</Dropdown.Item>
                                                                </li> */}


                                                                {/* <li><Link class="dropdown-item" to={`/${school_code}/student/student-list/leave-history/${row.student_uuid}`}>Leave</Link></li> */}
                                                                {/* <li><Link class="dropdown-item" to={`/${school_code}/student/student-list/adhoc-leave/${row.student_uuid}`}>AdHoc</Link></li> */}
                                                                <li><Link class="dropdown-item" to={`/${school_code}/student/student-list/pastoral-notes/${row.student_uuid}`}>Pastoral Notes</Link></li>
                                                                {/* <li><Link class="dropdown-item" to={`/${school_code}/student/student-list/student-profile/${row.student_uuid}`}>Student File</Link></li> */}

                                                                <div >
                                                                    <Dropdown>
                                                                        <Dropdown.Toggle id="dropdown-basic">
                                                                         Rollcall History
                                                                        </Dropdown.Toggle>

                                                                        <Dropdown.Menu>
                                                                            <Dropdown.Item>
                                                                                {/* <li> */}
                                                                                <button className="btn-menu"
                                                                                    style={{ color: "white", fontsize: "10px", }}
                                                                                    student_uuid={row.student_uuid}
                                                                                    dormitory_name={row.dormitory_data.dormitory_name}
                                                                                    search_by_days="7"
                                                                                    onClick={(event) => {
                                                                                        props.GetEventHandler(event);
                                                                                    }}>7 Days</button>
                                                                                {/* </li> */}

                                                                            </Dropdown.Item>

                                                                            <Dropdown.Item>
                                                                                <button
                                                                                    style={{ color: "white", fontsize: "10px", }}
                                                                                    student_uuid={row.student_uuid}
                                                                                    dormitory_name={row.dormitory_data.dormitory_name}
                                                                                    search_by_days="14"
                                                                                    onClick={(event) => {
                                                                                        props.GetEventHandler(event);
                                                                                    }}>14 Days
                                                                                </button>
                                                                            </Dropdown.Item>

                                                                            <Dropdown.Item>

                                                                                <button
                                                                                    style={{ color: "white", fontsize: "10px", }}
                                                                                    student_uuid={row.student_uuid}
                                                                                    dormitory_name={row.dormitory_data.dormitory_name}
                                                                                    search_by_days="21"
                                                                                    onClick={(event) => {
                                                                                        props.GetEventHandler(event);
                                                                                    }}>21 Days</button>
                                                                            </Dropdown.Item>

                                                                            <Dropdown.Item>
                                                                                <button
                                                                                    style={{ color: "white", fontsize: "10px", }}
                                                                                    student_uuid={row.student_uuid}
                                                                                    dormitory_name={row.dormitory_data.dormitory_name}
                                                                                    search_by_days="28"
                                                                                    onClick={(event) => {
                                                                                        props.GetEventHandler(event);
                                                                                    }}>28 Days</button>

                                                                            </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div>
                                                                {/* </ul> */}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>


                                <div
                                    className="info-wrap "
                                    style={{
                                        backgroundColor: row?.current_loc_data?.current_loc_reason?.reason_name === "MIA" ? 'rgb(215, 24, 24,0.7)' :
                                            row?.current_loc_data?.current_loc_reason?.reason_name === "ad-hoc" ? "#ffc700a6" :
                                                row?.current_loc_data?.current_loc_reason?.reason_name === "SL" ? "#ff650099" :
                                                    row?.current_loc_data?.current_loc_oncampus !== null ? 'rgb(28, 26, 26,0.7)'
                                                        : 'rgb(28, 26, 26,0.7)'
                                    }}
                                >
                                    <h4>Rollcall, Adhoc & Leave status</h4>
                                    <div className="d-flex mb-1 justify-content-between snapshot-items">
                                        <div className=" d-flex  w-100" style={{gap:"5px"}}>
                                            <div className="black-light-bg ocm-hover h-100">
                                                <span className="single-word-wrap"
                                                    style={
                                                        row?.current_loc_data === null ? {color:"#009A66"} :
                                                        row?.current_loc_data?.reason_id === 4 ? {color:"#3566CD"} :
                                                        row?.current_loc_data?.reason_id !== null &&
                                                        row?.current_loc_data?.current_loc_reason?.reason_name === "SL" ? {color:"#FF6500"} :
                                                        row?.current_loc_data?.current_loc_reason?.reason_name === "KL" ? {color:"#0DC0FF"} :
                                                        row?.current_loc_data?.current_loc_reason?.reason_name === "ad-hoc" ? {color:"#FDE101"} :
                                                        row?.current_loc_data?.current_loc_reason?.reason_name === "MIA" ? {color:"#CB0032"} :
                                                        row?.current_loc_data?.current_present_status === true ? {color:"#009A66"} :
                                                        row?.current_loc_data?.current_loc_oncampus !== null ? {color:"#3566CD"} : {color:"#009A66"}
                                                    }
                                                >
                                                    {row?.current_loc_data === null ? "In Dorm" :
                                                        row?.current_loc_data?.reason_id === 4 ? "OC" :
                                                            row?.current_loc_data?.reason_id !== null &&
                                                                row?.current_loc_data?.current_loc_reason?.reason_name === "SL" ? "Leave" :
                                                                    row?.current_loc_data?.current_loc_reason?.reason_name === "ad-hoc" ? "ADHOC" : 
                                                                    row?.current_loc_data?.current_loc_reason?.reason_name === "KL" ? "KL" : 
                                                                    row?.current_loc_data?.current_loc_reason?.reason_name === "MIA" ? "MIA":
                                                                row?.current_loc_data?.current_present_status === true ? "P" :
                                                                    row?.current_loc_data?.current_loc_oncampus !== null ? "OC" : "I"
                                                    }
                                                </span>

                                                {row.stu_atten_details.length === 0 ? null :
                                                    <div className="hover-oc">{console.log(row?.stu_atten_details[0]?.attendance_reason[0]?.reason_name,"::datdat")}

                                                        {row.stu_atten_details[0] ?
                                                            <span
                                                                // className={row?.stu_atten_details[0] ? row?.stu_atten_details[0]?.is_attendance === false ? row?.stu_atten_details[0]?.attendance_reason[0]?.reason_name === "MIA" ? "text-danger" : "" : "" : ""}
                                                                style={{
                                                                  ...(row?.stu_atten_details[0] && row?.stu_atten_details[0]?.is_attendance === true ? {color:"#009A66"} :
                                                                    row?.stu_atten_details[0]?.attendance_reason[0]?.reason_name === "On Campus" ? {color:"#3566CD"} :
                                                                    row?.stu_atten_details[0]?.attendance_reason[0]?.reason_name === "SL" ? {color:"#FF6500"} :
                                                                    row?.stu_atten_details[0]?.attendance_reason[0]?.reason_name === "KL" ? {color:"#0DC0FF"} :
                                                                    row?.stu_atten_details[0]?.attendance_reason[0]?.reason_name === "ad-hoc" ? {color:"#FDE101"} :
                                                                    row?.stu_atten_details[0]?.attendance_reason[0]?.reason_name === "MIA" ? {color:"#CB0032"}: {color:"#009A66"}),
                                                                    marginRight: "13px"
                                                                }}
                                                            >
                                                                {row?.stu_atten_details[0] ? row?.stu_atten_details[0]?.is_attendance === true ? "P" :
                                                                    row?.stu_atten_details[0]?.attendance_reason[0]?.reason_name === "On Campus" ? "OC" :
                                                                        row?.stu_atten_details[0]?.attendance_reason[0]?.reason_name === "SL" ? "L" :
                                                                            row?.stu_atten_details[0]?.attendance_reason[0]?.reason_name : ""
                                                                }
                                                            </span>
                                                            : ""
                                                        }

                                                        {row.stu_atten_details[1] ?
                                                            <span
                                                                // className={row?.stu_atten_details[1] ? row?.stu_atten_details[1]?.is_attendance === false ? row?.stu_atten_details[1]?.attendance_reason[0]?.reason_name === "MIA" ? "text-danger" : "" : "" : ""}
                                                                style={row?.stu_atten_details[1] && row?.stu_atten_details[1]?.is_attendance === true ? {color:"#009A66"} :
                                                                    row?.stu_atten_details[1]?.attendance_reason[0]?.reason_name === "On Campus" ? {color:"#3566CD"} :
                                                                    row?.stu_atten_details[1]?.attendance_reason[0]?.reason_name === "SL" ? {color:"#FF6500"} :
                                                                    row?.stu_atten_details[1]?.attendance_reason[0]?.reason_name === "KL" ? {color:"#0DC0FF"} :
                                                                    row?.stu_atten_details[1]?.attendance_reason[0]?.reason_name === "ad-hoc" ? {color:"#FDE101"} :
                                                                    row?.stu_atten_details[1]?.attendance_reason[0]?.reason_name === "MIA" ? {color:"#CB0032"}: {color:"#009A66"}
                                                                }
                                                            >
                                                                {row?.stu_atten_details[1] ? row?.stu_atten_details[1]?.is_attendance === true ? "P" :
                                                                    row?.stu_atten_details[1]?.attendance_reason[0]?.reason_name === "On Campus" ? "OC" :
                                                                        row?.stu_atten_details[1]?.attendance_reason[0]?.reason_name === "SL" ? "L" :
                                                                            row?.stu_atten_details[1]?.attendance_reason[0]?.reason_name : ""
                                                                }
                                                            </span>
                                                            : ""
                                                        }

                                                        {row?.stu_atten_details[2] ?
                                                            <span
                                                                // className={row?.stu_atten_details[2] ? row?.stu_atten_details[2]?.is_attendance === false ? row?.stu_atten_details[2]?.attendance_reason[0]?.reason_name === "MIA" ? "text-danger" : "" : "" : ""}
                                                                style={row?.stu_atten_details[2] && row?.stu_atten_details[2]?.is_attendance === true ? {color:"#009A66"} :
                                                                    row?.stu_atten_details[2]?.attendance_reason[0]?.reason_name === "On Campus" ? {color:"#3566CD"} :
                                                                    row?.stu_atten_details[2]?.attendance_reason[0]?.reason_name === "SL" ? {color:"#FF6500"} :
                                                                    row?.stu_atten_details[2]?.attendance_reason[0]?.reason_name === "KL" ? {color:"#0DC0FF"} :
                                                                    row?.stu_atten_details[2]?.attendance_reason[0]?.reason_name === "ad-hoc" ? {color:"#FDE101"} :
                                                                    row?.stu_atten_details[2]?.attendance_reason[0]?.reason_name === "MIA" ? {color:"#CB0032"}: {color:"#009A66"}
                                                                }
                                                            >
                                                                {row?.stu_atten_details[2] ? row?.stu_atten_details[2]?.is_attendance === true ? "P" :
                                                                    row?.stu_atten_details[2]?.attendance_reason[0]?.reason_name === "On Campus" ? "OC" :
                                                                        row?.stu_atten_details[2]?.attendance_reason[0]?.reason_name === "SL" ? "L" :
                                                                            row?.stu_atten_details[2]?.attendance_reason[0]?.reason_name : ""
                                                                }
                                                            </span>
                                                            : ""
                                                        }

                                                        {row?.stu_atten_details[3] ?
                                                            <span
                                                                // className={row?.stu_atten_details[3] ? row?.stu_atten_details[3]?.is_attendance === false ? row?.stu_atten_details[3]?.attendance_reason[0]?.reason_name === "MIA" ? "text-danger" : "" : "" : ""}
                                                                style={row?.stu_atten_details[3] && row?.stu_atten_details[3]?.is_attendance === true ? {color:"#009A66"} :
                                                                    row?.stu_atten_details[3]?.attendance_reason[0]?.reason_name === "On Campus" ? {color:"#3566CD"} :
                                                                    row?.stu_atten_details[3]?.attendance_reason[0]?.reason_name === "SL" ? {color:"#FF6500"} :
                                                                    row?.stu_atten_details[3]?.attendance_reason[0]?.reason_name === "KL" ? {color:"#0DC0FF"} :
                                                                    row?.stu_atten_details[3]?.attendance_reason[0]?.reason_name === "ad-hoc" ? {color:"#FDE101"} :
                                                                    row?.stu_atten_details[3]?.attendance_reason[0]?.reason_name === "MIA" ? {color:"#CB0032"}: {color:"#009A66"}
                                                                }
                                                            >
                                                                {row?.stu_atten_details[3] ? row?.stu_atten_details[3]?.is_attendance === true ? "P" :
                                                                    row?.stu_atten_details[3]?.attendance_reason[0]?.reason_name === "On Campus" ? "OC" :
                                                                        row?.stu_atten_details[3]?.attendance_reason[0]?.reason_name === "SL" ? "L" :
                                                                            row?.stu_atten_details[3]?.attendance_reason[0]?.reason_name : ""
                                                                }
                                                            </span>
                                                            : ""
                                                        }

                                                        {row?.stu_atten_details[4] ?
                                                            <span
                                                                // className={row?.stu_atten_details[4] ? row?.stu_atten_details[4]?.is_attendance === false ? row?.stu_atten_details[4]?.attendance_reason[0]?.reason_name === "MIA" ? "text-danger" : "" : "" : ""}
                                                                style={row?.stu_atten_details[4] && row?.stu_atten_details[4]?.is_attendance === true ? {color:"#009A66"} :
                                                                    row?.stu_atten_details[4]?.attendance_reason[0]?.reason_name === "On Campus" ? {color:"#3566CD"} :
                                                                    row?.stu_atten_details[4]?.attendance_reason[0]?.reason_name === "SL" ? {color:"#FF6500"} :
                                                                    row?.stu_atten_details[4]?.attendance_reason[0]?.reason_name === "KL" ? {color:"#0DC0FF"} :
                                                                    row?.stu_atten_details[4]?.attendance_reason[0]?.reason_name === "ad-hoc" ? {color:"#FDE101"} :
                                                                    row?.stu_atten_details[4]?.attendance_reason[0]?.reason_name === "MIA" ? {color:"#CB0032"}: {color:"#009A66"}
                                                                }
                                                            >
                                                                {row?.stu_atten_details[4] ? row?.stu_atten_details[4]?.is_attendance === true ? "P" :
                                                                    row?.stu_atten_details[4]?.attendance_reason[0]?.reason_name === "On Campus" ? "OC" :
                                                                        row?.stu_atten_details[4]?.attendance_reason[0]?.reason_name === "SL" ? "L" :
                                                                            row?.stu_atten_details[4]?.attendance_reason[0]?.reason_name : ""
                                                                }
                                                            </span>
                                                            : ""
                                                        }

                                                    </div>
                                                }
                                            </div>
                                            <CustomAdhocCell row={row} currentTime={currentTime} currentDate={currentDate}/>
                                            <CustomLeaveCell row={row} currentTime={currentTime} currentDate={currentDate}/>
                                        </div>
                                    </div>

                                    {/* <p className="text-white m-0">Location  {row?.current_loc_data?.current_loc_oncampus === null ? "" : ": " + `${row?.current_loc_data?.current_loc_oncampus?.location_name === undefined ? "Dormitory" : row?.current_loc_data?.current_loc_oncampus?.location_name}`}</p> */}
                                    <p className="text-white m-0">
                                        Location:
                                        {row?.current_loc_data?.current_present_status === true ? " Dormitory" :
                                            row?.current_loc_data?.current_loc_reason !== null ? (row?.current_loc_data?.current_loc_reason?.reason_name  === "ad-hoc" && row?.stu_adhoc_data[0]?.check_in_out === "out") ? " "+row?.stu_adhoc_data[0]?.bss_adhoc_leave_detail?.venue : row?.current_loc_data?.current_loc_reason?.reason_name ==="SL" ? "": " "+row?.current_loc_data?.current_loc_reason?.reason_name :
                                            " "+row?.current_loc_data?.current_loc_oncampus?.location_name
                                        }
                                    </p>
                                </div>
                            </div>
                            <div className="box-txt">
                                <div className="row">
                                    <div className="col-8">
                                        <Link to={`/${school_code}/student/student-list/student-profile/${row.student_uuid}`}>
                                            <h6 className="text-black fw-bolder">{row.student_first_name + " " + row.student_last_name}</h6>
                                        </Link>
                                    </div>
                                    <div className="col-4">
                                        <p className="mb-0 text-black">{row.class_name.charAt(0).toUpperCase() + row.class_name.slice(1)}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-8 pe-0">
                                        <p className="mb-0 text-black">{row.dormitory_data.dormitory_name.charAt(0).toUpperCase() + row.dormitory_data.dormitory_name.slice(1)}{ }</p>
                                        <p className="mb-0 text-black">{row.sporting_house != "" ? row.sporting_house : "Sporting House"}</p>

                                    </div>

                                    <div className="col-md-4">

                                        <span className="text-black d-block">{row.unique_pin.charAt(0).toUpperCase() + row.unique_pin.slice(1)}</span>
                                        <p className="mb-0 text-black">{row.gender.charAt(0).toUpperCase() + row.gender.slice(1)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            );
        },
    }]
    const handleTableChange = (type, { sortField, sortOrder, page }) => {
        if (type === "sort") {
            props.propsfiltersort(sortField);
            props.propsfilterorder(sortOrder);
        }

        if (type === "pagination") {
            props.propsfilterpage(page);
        }

    };
    const RemotePagination = ({ columns, dataField, order, data, page, sizePerPage, onTableChange, totalSize }) => (
        <PaginationProvider
            pagination={paginationFactory({
                custom: true,
                page,
                sizePerPage,
                totalSize,
            })}
        >
            {({ paginationProps, paginationTableProps }) => (
                <>
                    <div className="common-table superadmin_table">
                        {console.log("total_size=================", paginationProps)}
                        <BootstrapTable
                            remote
                            bootstrap4
                            data={data}
                            keyField="student_uuid"
                            sort={{ dataField: dataField, order: order }}
                            columns={columns}
                            {...paginationTableProps}
                            onTableChange={onTableChange}
                            sizePerPageList={sizePerPage}
                            headerClasses="fw-bolder text-white bg-dark-row"
                            classes="table-bordered align-middle custom-table-style table-fields"
                            noDataIndication={() => <span className="text-center text-gray-400">No records found</span>}
                        />
                    </div>

                    <div className="d-flex flex-stack justify-content-between flex-wrap pt-3 pagination-main">
                        {console.log("sizePerPagepage", sizePerPage * page)}
                        {/* {console.log("pagination==================", sizePerPage, page, perpagedata, totalSize, (sizePerPage * page))} */}
                        {/* {console.log("pagination==========", (parseInt(sizePerPage) * parseInt(page) + parseInt(1)) - (parseInt(perpagedata == totalSize ? 1 : perpagedata)))} */}
                        {page === undefined || totalSize > 0 &&
                            (
                                <div className="pagination_limt">
                                    <select
                                        id='select'
                                        name='group'
                                        className='form-control input-sm form-select form-select-sm form-select-solid'
                                        size='1'
                                        value={perpagedata}
                                        onChange={(event) => setPerpagePagination(event)}
                                    >
                                        <option value={totalSize}>All</option>
                                        <option value={12}>12</option>
                                        <option value={25}>25</option>
                                        <option value={50}>50</option>
                                    </select>
                                    {console.log("xasdcsfsdvdfvd", sizePerPage * page > totalSize, sizePerPage, page, totalSize)}
                                    {props.filterlimit === "" ? <div className="count_number">Displaying {1 - (parseInt(perpagedata))}-{totalSize} of {totalSize} records</div> :
                                        sizePerPage * page > totalSize ? (
                                            <div className="count_number">Displaying {(parseInt(sizePerPage) * parseInt(page) + parseInt(1)) - (parseInt(perpagedata))}-{totalSize} of {totalSize} records</div>
                                        ) : (
                                            <div className="count_number">Displaying {(parseInt(sizePerPage) * parseInt(page) + parseInt(1)) - (parseInt(perpagedata))}-{parseInt(sizePerPage) * parseInt(page)} of {totalSize} records</div>
                                        )}
                                </div>
                            )
                        }
                        {totalSize < props.statusDefaultCount &&
                            <div className="count_number">Displaying {totalSize} of {props.statusDefaultCount} records</div>
                        }
                        {page !== undefined &&
                            <div className="pagination_page">
                                <PaginationListStandalone {...paginationProps} />
                            </div>
                        }
                    </div>
                </>
            )}
        </PaginationProvider>
    );
    return (
        <React.Fragment>
            <div className="row">
                <div className="col-xl-12 ">
                    <div id="std-details-wraps" className="dataTables_wrapper dt-bootstrap4 no-footer team-list-table">
                        <div className="card-body p-0 bg-white radious-style radious-users">
                            {/* <Spin spinning={props.loading}> */}
                            <RemotePagination
                                data={props.data}
                                columns={columns}
                                totalSize={props.StudentListcount}
                                page={props.filterpage}
                                sizePerPage={props.filterlimit === "" ? props.StudentListcount : props.filterlimit}
                                order={props.filterorder}
                                dataField={props.filtersort}
                                onTableChange={handleTableChange}
                            // const logic = {props.ChangeStatus}  

                            />
                            {/* </Spin> */}
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
};
export default SnapshotItem;
