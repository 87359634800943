import SideBarLeave from "../../Constant/SideBarLeave";
import Footer from "../../Constant/Footer";
import TopBar from "../../Constant/TopBar";
import { useState, useEffect } from "react";
import * as userActions from "../../../actions/index";
import IconButton from '@mui/material/IconButton';
import { connect } from "react-redux";
import { Tooltip } from "@mui/material";
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';

import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { margin } from "@mui/system";
import Pagination from '@mui/material/Pagination';

import Stack from '@mui/material/Stack';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
const StudentConnectToHostReport = (props) => {
    const [bssStudentHostReport, setBssStudentHostReport] = useState([])
    const [hostsApprovedBy, setHostApprovedBy] = useState("")
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState()
    const [is_host_approved, setIs_host_approved] = useState("")
    const [pdf_url, setPdf_url] = useState("")
    const [is_pdf, setIS_pdf] = useState(false)
    const [isPagination, setIsPagination] = useState(true)

    const row_limit = [
        { value: 5, label: 5 },
        { value: 10, label: 10 },
        { value: 15, label: 15 },
        { value: 20, label: 20 },
        { value: 25, label: 25 },
    ]


    useEffect(() => {
        BssStudentHostReport()
    }, [page, limit, is_host_approved, is_pdf, isPagination])

    const BssStudentHostReport = () => {
        props.onBssStudentHostReport(page, limit, is_host_approved, is_pdf, isPagination).then((response) => {
            if (response.success === true) {
                setBssStudentHostReport(response.data)
                setCount(response.totalCount)
                setPdf_url(response.file)
                if (is_pdf) {
                    DownloadPdf(response.file)
                }
            } else {
            }
        });

    }


    const handleChange = (event, value) => {
        setPage(value);
    };

    const changeLimit = (e) => {
        setLimit(parseInt(e.value));
    };

    const DownloadPdf = () => {
        const link = document.createElement("a");
        link.href = pdf_url;
        link.target = "_blank"
        // link.setAttribute("download");
        document.body.appendChild(link);
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
        setIS_pdf(false);
        setIsPagination(true);
    }

    return (
        <>
            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed" >
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <SideBarLeave />
                        <div className="wrapper d-flex flex-column flex-row-fluid " id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    {/* <!--begin::Container--> */}
                                    <div id="kt_content_container" className="container-fluid">
                                        <div className="tab-content" id="myTabContent">
                                            <div className="tab-pane fade show active" id="Rollcall" role="tabpanel" aria-labelledby="Rollcall-tab">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="card  tabs-outer">
                                                            <div className="card-header">
                                                                <h2 className="card-title">Hosts of a Student</h2>
                                                                <div className="d-flex align-items-center">
                                                                    <p className="m-0 me-3">Download PDF</p>
                                                                    <IconButton onClick={() => {setIS_pdf(true); setIsPagination(false) }}>
                                                                        <i class="fas fa-file-pdf"></i>
                                                                    </IconButton>
                                                                </div>
                                                            </div>

                                                            <div className="card-body">
                                                                <div className="card-toolbar">
                                                                    {bssStudentHostReport.map((student) => {
                                                                        return (
                                                                            <div>
                                                                                <div className="card-1  mb-4 rounded bg-light-info">
                                                                                    <div className="row">
                                                                                        <div className="col-12">
                                                                                            <Accordion>
                                                                                                <AccordionSummary
                                                                                                    className="w-100"
                                                                                                    expandIcon={<ExpandMoreIcon />}
                                                                                                >
                                                                                                    <Typography style={{ width: "100%" }}>
                                                                                                        <div className="d-flex justify-content-between w-100">
                                                                                                            <h4 className=" fs-6 d-flex font-medium">
                                                                                                                Student Name
                                                                                                                <span className="d-none d-lg-block bullet h-20px w-1px bg-secondary mx-4"></span>
                                                                                                                <span className=" fs-6 text-muted">
                                                                                                                    {student.student_first_name}  {student.student_last_name}
                                                                                                                </span>
                                                                                                                <span className="d-none d-lg-block bullet h-20px w-1px mx-4"></span>
                                                                                                                Year
                                                                                                                <span className="d-none d-lg-block bullet h-20px w-1px bg-secondary mx-4"></span>
                                                                                                                <span className="fs-6 text-muted">
                                                                                                                    {student.class_name}
                                                                                                                </span>
                                                                                                                <span className="d-none d-lg-block bullet h-20px w-1px mx-4"></span>
                                                                                                                Dormitory
                                                                                                                <span className="d-none d-lg-block bullet h-20px w-1px bg-secondary mx-4"></span>
                                                                                                                <span className="fs-6 text-muted">
                                                                                                                    {student.dormitory_data.dormitory_name}
                                                                                                                </span>
                                                                                                            </h4>
                                                                                                        </div>
                                                                                                    </Typography>
                                                                                                </AccordionSummary>
                                                                                                <AccordionDetails style={{ padding: "0px" }}>

                                                                                                    <div className="table-responsive w-100">
                                                                                                        <table className="table align-middle border rounded table-row-solid fs-6 g-5 dataTable no-footer table-bordered-set mb-0">
                                                                                                            <tr>
                                                                                                                <th>S.No.</th>
                                                                                                                <th>Host Name</th>
                                                                                                                <th>Host Relation</th>
                                                                                                                <th>Host Contact</th>
                                                                                                            </tr>
                                                                                                            <tbody>
                                                                                                                {student.parent_data.parents_as_host.map((parent, index) => {
                                                                                                                    return (
                                                                                                                        <tr>
                                                                                                                            <td>{index + 1}</td>
                                                                                                                            <td>{parent.parent_type}</td>
                                                                                                                            <td>{parent.host_relation}</td>
                                                                                                                            <td>{parent.parent_type === "mother" ? student.parent_data.mother_phone : student.parent_data.father_phone}</td>
                                                                                                                        </tr>
                                                                                                                    )
                                                                                                                })}
                                                                                                                {student.stu_host_data.map((host, index) => {
                                                                                                                    return (
                                                                                                                        <tr>
                                                                                                                            <td>{student.parent_data.parents_as_host.length + 1}</td>
                                                                                                                            <td>{host.host_name}</td>
                                                                                                                            <td>{host.stu_host.host_relation}</td>
                                                                                                                            <td>{host.host_contact}</td>
                                                                                                                        </tr>
                                                                                                                    )
                                                                                                                })}

                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </div>

                                                                                                </AccordionDetails>
                                                                                            </Accordion>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>

                                                                <div className="d-flex justify-content-between">
                                                                    <Select
                                                                        menuPlacement="top"
                                                                        className="basic-single"
                                                                        classNamePrefix="select"
                                                                        defaultValue={row_limit[1]}
                                                                        name="color"
                                                                        options={row_limit}
                                                                        onChange={changeLimit}
                                                                    />
                                                                    <Stack spacing={1} >
                                                                        <Pagination
                                                                            count={Math.ceil(count / limit)}
                                                                            page={page}
                                                                            onChange={handleChange}
                                                                            color="primary"
                                                                        />
                                                                    </Stack>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}

const mapDispatchToProps = {
    onGetAllDormitoryStudent: userActions.GetAllDormitoryStudent,
    onGetAllClassesList: userActions.GetAllClassesList,
    onBssStudentHostReport: userActions.BssStudentHostReport,
};
export default connect(mapStateToProps, mapDispatchToProps)(StudentConnectToHostReport);