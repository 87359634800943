import React, { useState, useEffect, useCallback } from "react";
import Sidebar from "../../Constant/sidebar";
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";
import DeleteConfirmRemove from "../../Utility/DeleteConfirmRemove";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as userActions from "../../../actions/index";
import DuplicateStudentItem from "./DuplicateStudentItem";
import { CustomDeleteModal1, CustomDeleteModal2 } from "../../Utility/DeleteStudentModal";
import IgnoreStudentModal from "../../Utility/IgnoreStudentModal";
import { TailSpin } from 'react-loader-spinner'

const DuplicateStudentList = (props) => {
    const dispatch = useDispatch();
    const [showLoader, setShowLoader] = useState(false)
    const [student_uuid, setstudent_uuid] = useState("");
    const [filterlimit, setfilterlimit] = useState(10);
    const [filterpage, setfilterpage] = useState(1);
    const [filterorder, setfilterorder] = useState("asc");
    const [filtersort, setfiltersort] = useState("student_first_name");
    const [filtersearch, setfiltersearch] = useState(null);
    const [DuplicateStudentList, setDuplicateStudentList] = useState([]);
    const [DuplicateStudentListcount, setDuplicateStudentListcount] = useState(0);
    const [is_student_activate, setis_student_activate] = useState('');
    const [uuid, setuuid] = useState([]);
    const navigate = useNavigate();
    const [groupBy, setGroupBy] = useState("preferred_name")
    const [showModal, setShowModal] = useState(false);
    const [showSecondModal, setShowSecondModal] = useState(false);
    const [confirmRemoveData, setConfirmRemoveData] = useState([]);
    const [ignoreModal, setIgnoreModal] = useState(false);
    const [duplicateStudentData, setDuplicateStudentData] = useState([])

    const handleClick = (event, data) => {
        var value = event.currentTarget.id;
        if (event.target.checked === true) {
            let duplicate_data = []
            uuid.push(value);
            const filterArrayData = DuplicateStudentList.map((item) => {
                let item_array = [];
                item.forEach(element => {
                    if (uuid.includes(element.student_uuid)) {
                        item_array.push({ ...element, checked: true })
                        duplicate_data.push(element)
                    } else {
                        item_array.push({ ...element, checked: false })
                    }
                });
                return item_array
            })
            setDuplicateStudentData(duplicate_data)
            setuuid(uuid);
            setDuplicateStudentList(filterArrayData);
        } else if (event.target.checked === false) {
            for (var i = 0; i < uuid.length; i++) {
                if (event.currentTarget.id == uuid[i]) {
                    for (var j = i; j < uuid.length - 1; j++) {
                        uuid[j] = uuid[j + 1];

                    }
                    uuid.pop(value);
                    break;
                }
            }
            const filterArrayData = DuplicateStudentList.map((item) => {
                let item_array = [];
                item.forEach(element => {
                    if (uuid.includes(element.student_uuid)) {
                        item_array.push({ ...element, checked: true })
                    } else {
                        item_array.push({ ...element, checked: false })
                    }
                });
                return item_array
            })
            let duplicate_data = duplicateStudentData.filter((item) => {
                return data.student_id !== item.student_id
            })

            setDuplicateStudentData(duplicate_data)
            setuuid(uuid);
            setDuplicateStudentList(filterArrayData);
        }
    };


    const GetAllDuplicateStudents = useCallback(async () => {
        dispatch(userActions.GetAllDuplicateStudents(filtersort, filterorder, is_student_activate, filtersearch, groupBy)).then((response) => {
            if (response.success === true) {
                const arrayData = response.data;

                let array = [];
                if (groupBy == "preferred_name") {
                    const groupedByPreferredName = arrayData.reduce((acc, student) => {
                        const { preferred_name } = student;
                        if (!acc[preferred_name]) {
                            acc[preferred_name] = [];
                        }
                        acc[preferred_name].push({ ...student, checked: false });
                        return acc;
                    }, {});
                    const arrayOfStudentsPreferred = Object.values(groupedByPreferredName)
                    array = arrayOfStudentsPreferred
                }

                if (groupBy == "student_phone") {
                    const groupedByPreferredName = arrayData.reduce((acc, student) => {
                        const { student_phone } = student;
                        if (!acc[student_phone]) {
                            acc[student_phone] = [];
                        }
                        acc[student_phone].push({ ...student, checked: false });
                        return acc;
                    }, {});
                    const arrayOfStudentsPreferred = Object.values(groupedByPreferredName)
                    array = arrayOfStudentsPreferred
                }
                console.log("adfajdasfasfasf", array);
                setDuplicateStudentList(array);
            } else if (response.status === 401) {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                setTimeout(() => {
                    navigate('/logout')
                }, 5000);
            } else {
            }
        });
    }, [dispatch, filtersort, filterorder, is_student_activate, filtersearch, groupBy]);


    useEffect(() => {
        GetAllDuplicateStudents();
    }, [GetAllDuplicateStudents, filtersort, filterorder, is_student_activate, filtersearch, groupBy]);


    const handleChange = async (event, student_uuid, value) => {
        const Payload = {
            student_uuid: student_uuid,
            is_student_activate: value,
        };
        props.onUpdateStudentStatus(Payload).then((response) => {
            if (response.success === true) {
                GetAllDuplicateStudents();
                setfilterpage(1);
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });

    }

    const handleChangeNonDuplicate = async (event, student_uuid, value) => {
        setDuplicateStudentData("")
        const Payload = {
            is_duplicate: false,
            duplicate_data: JSON.stringify(uuid),
        };
        setIgnoreModal(false)
        if (uuid.length === 0) {
            toast.error("Select Student", {
                position: toast.POSITION.TOP_CENTER,
            });
        } else {
            props.onUpdateStudentDuplicateStatus(Payload).then((response) => {
                if (response.success === true) {
                    GetAllDuplicateStudents();
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            });
        }

    }

    const SelectAllStudent = (event) => {

    }

    const toggleModalOne = (event, course_data) => {
        if (duplicateStudentData === "") {
            toast.error("Select Student", {
                position: toast.POSITION.TOP_CENTER,
            });
        } else {
            setShowModal(!showModal)
            setConfirmRemoveData(duplicateStudentData)
        }
    };

    const toggleModalTwo = (e) => {
        setShowSecondModal(!showSecondModal)
    };

    const openTwoAndCloseOne = (e) => {
        setShowSecondModal(!showSecondModal)
        if (showModal) { setShowModal(false) };
    };

    const DeleteStudent = async () => {
        setShowLoader(true)
        const payload = duplicateStudentData.map((element) => {
            return ({
                student_id: element.student_id,
                parent_id: element.parent_id === null || element.parent_id === undefined ? "" : element.parent_id,
                student_name: element.student_first_name + " " + element.student_last_name
            })
        })
        const form_data = { duplicate_student_data: JSON.stringify(payload) }
        props.onDeleteStudent(form_data).then((response) => {
            if (response.success === true) {
                setShowModal(false)
                setShowSecondModal(false)
                setfilterpage(1);
                GetAllDuplicateStudents();
                setShowLoader(false)
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });

            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }

        });
    }

    const closeIgnoreModal = () => {
        setIgnoreModal(false)
    }



    return (
        <>
            {showLoader &&
                <div style={{ backdropFilter: "blur(10px)", display: "flex", justifyContent: "center", alignItems: "center", zIndex: "99999999", position: "fixed", top: 0, left: 0, right: 0, bottom: 0, background: "#b8b8b82b" }}>
                    <div >
                        <TailSpin
                            visible={showLoader}
                            height="100"
                            width="100"
                            color="#3693d0"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>

                </div>
            }
            <IgnoreStudentModal
                modalState={ignoreModal}
                closeIgnoreModal={closeIgnoreModal}
                handleChangeNonDuplicate={handleChangeNonDuplicate}
            />
            <CustomDeleteModal1
                modalState={showModal}
                toggleModalOne={toggleModalOne}
                confirmRemoveData={confirmRemoveData}
                openTwoAndCloseOne={openTwoAndCloseOne}
            />
            <CustomDeleteModal2
                modalState={showSecondModal}
                toggleModalTwo={toggleModalTwo}
                confirmRemoveData={confirmRemoveData}
                DeleteStudent={DeleteStudent}
            />


            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <Sidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-fluid">
                                        <div className="school-edit-form">
                                            <div className="" id="Snapshot">
                                                <div className="card-body p-0">
                                                    <div className="row">

                                                        <div className="col-sm-8">
                                                            <div className="card tabs-outer">
                                                                <div className="card-header position-relative py-0 details-bg">
                                                                    <h2 className="card-title  m-0">Duplicate Students</h2>
                                                                    {/* <div className="card-toolbar">
                                                                        <span className="btn btn-sm btn-primary apply-btn add-btns me-2"
                                                                          onChange={(event) => {
                                                                            SelectAllStudent(event)
                                                                        }}
                                                                        >Select All</span>
                                                                    </div> */}
                                                                </div>

                                                                <div className="card-body">
                                                                    <div className="card-toolbar mb-6">
                                                                        <div className="d-flex align-items-center justify-content-between export-out">
                                                                            {/* <!-- <h4 className="card-title  m-0">Schools</h4> --> */}
                                                                            <div className="d-flex align-items-center position-relative">
                                                                                <span className="svg-icon svg-icon-1 position-absolute ms-4">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                                        <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                                                                        <path
                                                                                            d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                                                                            fill="currentColor"
                                                                                        ></path>
                                                                                    </svg>
                                                                                </span>

                                                                                <input type="text" className="form-control form-control-sm w-350px ps-12 search-out fs-6" placeholder="Search " style={{ background: "transparent" }}
                                                                                    onChange={(event) => {
                                                                                        setfiltersearch(event.target.value);
                                                                                        setfilterpage(1);
                                                                                    }}

                                                                                />
                                                                            </div>
                                                                            <div className="d-flex">
                                                                                <div className="card-toolbar d-flex duplicate-student">
                                                                                    <div className="card-toolbar">
                                                                                        <span
                                                                                            className="btn btn-sm btn-primary  add-btns me-2"
                                                                                            onClick={(event) => {
                                                                                                // handleChangeNonDuplicate(event, student_uuid, false);
                                                                                                setIgnoreModal(true)
                                                                                            }}
                                                                                        >Ignore</span>


                                                                                        <span
                                                                                            className="btn btn-sm btn-danger"
                                                                                            onClick={(event) => {
                                                                                                toggleModalOne(event)
                                                                                            }}
                                                                                        >Delete</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-sm-12">
                                                                            <DuplicateStudentItem
                                                                                data={DuplicateStudentList}
                                                                                filterlimit={filterlimit}
                                                                                filterpage={filterpage}
                                                                                propsfilterpage={(event) => setfilterpage(event)}
                                                                                handleChange={(event, student_uuid, value) => handleChange(event, student_uuid, value)}
                                                                                handleClick={(event, data) => handleClick(event, data)}
                                                                                handleChangeNonDuplicate={(event, student_uuid, value) => handleChangeNonDuplicate(event, student_uuid, value)}
                                                                                propsfilterlimit={(filterlimit) => {
                                                                                    setfilterlimit(filterlimit);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-4">

                                                            <div className="card">
                                                                <div className="card-header border-bottom border-bottom-1">
                                                                    <h3 className="card-title align-items-start flex-column">
                                                                        <span className="card-label fw-bold text-dark">Filters</span>
                                                                    </h3>
                                                                </div>

                                                                <div className="card-body p-6">
                                                                    <div className="d-flex flex-column">
                                                                        <h5>Sort By</h5>
                                                                        <div className="d-flex">
                                                                            <button className=

                                                                                {"filter-badge me-2 filter-button " +
                                                                                    (filtersort === "student_first_name" ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                                }
                                                                                onClick={(event) => {
                                                                                    if (filterorder === 'desc') {
                                                                                        setfilterorder('asc');
                                                                                    }
                                                                                    else {
                                                                                        setfilterorder('desc');
                                                                                    }
                                                                                    setfiltersort('student_first_name');
                                                                                    setfilterpage(1);

                                                                                }}

                                                                            >Name</button>

                                                                            <button className=

                                                                                {"filter-badge me-2 filter-button " +
                                                                                    (filtersort === "class_id" ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                                }
                                                                                onClick={(event) => {
                                                                                    if (filterorder === 'desc') {
                                                                                        setfilterorder('asc');
                                                                                    }
                                                                                    else {
                                                                                        setfilterorder('desc');
                                                                                    }
                                                                                    setfiltersort('class_id');
                                                                                    setfilterpage(1);

                                                                                }}

                                                                            >Class</button>

                                                                            <button className=

                                                                                {"filter-badge me-2 filter-button " +
                                                                                    (filtersort === "student_id" ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                                }
                                                                                onClick={(event) => {
                                                                                    if (filterorder === 'desc') {
                                                                                        setfilterorder('asc');
                                                                                    }
                                                                                    else {
                                                                                        setfilterorder('desc');
                                                                                    }
                                                                                    setfiltersort('student_id');
                                                                                    setfilterpage(1);

                                                                                }}

                                                                            >Student Id</button>

                                                                        </div>
                                                                    </div>

                                                                    <div className="d-flex flex-column mt-6">
                                                                        <h5>Show</h5>
                                                                        <div className="d-flex align-items-center">
                                                                            <span className=
                                                                                {"filter-badge me-2 filter-button " +
                                                                                    (is_student_activate === "" ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                                }
                                                                                onClick={(event) => {
                                                                                    setis_student_activate('');
                                                                                    setfilterpage(1);

                                                                                }}

                                                                            >Active</span>
                                                                            <span className=
                                                                                {"filter-badge me-2 filter-button " +
                                                                                    (is_student_activate === false ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                                }
                                                                                onClick={(event) => {
                                                                                    setis_student_activate(false);
                                                                                    setfilterpage(1);

                                                                                }}

                                                                            >Inactive</span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="d-flex flex-column mt-6">
                                                                        <div className="d-flex align-items-center">
                                                                            <button className=
                                                                                {"filter-badge me-2 filter-button " +
                                                                                    (groupBy === "preferred_name" ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                                }
                                                                                onClick={(event) => { setGroupBy('preferred_name') }}
                                                                            >Preferred Name </button>

                                                                            <button className=
                                                                                {"filter-badge me-2 filter-button " +
                                                                                    (groupBy === "student_phone" ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                                }
                                                                                onClick={(event) => { setGroupBy('student_phone') }}
                                                                            >Phone No.</button>

                                                                            {/* <button className=
                                                                                {"filter-badge me-2 filter-button " +
                                                                                    (groupBy === "all" ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                                }
                                                                                onClick={(event) => { setGroupBy("") }}
                                                                            >All</button> */}

                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>

                {/* <div className="modal fade delete-modal" tabindex="-1" id="delete-modal">
    <div className="modal-dialog">
        <div className="modal-content">
            <div className="modal-header">
                <h4 className="modal-title">Are you sure you want to delete this Dormitory?</h4>
    
            </div>

            <div className="modal-body">
                <p className="mb-0">This will be permanently delete.</p>
            </div>

            <div className="modal-footer">
                <button type="button" className="btn btn-light btn-sm min-150px" data-bs-dismiss="modal">Cancel</button>
                <button type="button" className="btn btn-primary btn-sm min-150px">Delete</button>
            </div>
        </div>
    </div>
</div>		 */}
            </div>
        </>
    );
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {


    onUpdateStudentStatus: userActions.UpdateStudentStatus,
    onUpdateStudentDuplicateStatus: userActions.UpdateStudentDuplicateStatus,
    onDeleteStudent: userActions.DeleteStudent,

};
export default connect(mapStateToProps, mapDispatchToProps)(DuplicateStudentList);