import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

const DeleteModal1 = ({
  modalState,
  toggleModalOne,
  confirmRemoveData,
  openTwoAndCloseOne,
  handleChange
}) => {
  const permission_info = JSON.parse(localStorage.getItem("permissions"));
  console.log("confirmRemoveData", toggleModalOne);
  const [modalOpen, setModalOpen] = useState(modalState);
  const [studentName, setStudentName] = useState("")
  const [row,setRow] = useState()

  useEffect(() => {
    setModalOpen(modalState);
    setStudentName(confirmRemoveData.student_first_name + " " + confirmRemoveData.student_last_name);
    setRow( confirmRemoveData)
  }, [modalState]);
  return (
    <Modal
      backdrop="static"
      keyboard={false}
      className="confirm_delete_modal confirm_modal"
      show={modalOpen}
      aria-labelledby="md-modal"
    >
      <Modal.Header>
        <Modal.Title>Confirm Deletion of {studentName}</Modal.Title>
        <span className="pointer" onClick={(event) => toggleModalOne(event, confirmRemoveData)}>
          <i className="fas fa-times me-2 fs-6" ></i>
        </span>
      </Modal.Header>
      <Modal.Body >
        
          <p className="text-center">Are you sure you want to delete this student. It would be result in a loss of important infomation that cannot be recovered. You can also archive this student.</p>
    

        <div className="col-sm-12 mt-5 d-flex justify-content-center">


          {/* {row && row.is_student_activate === true ? (
            (permission_info?.activate_deactivate_class !== false) ?
              (<div className="d-flex align-items-center me-5"
                onClick={(event) => {
                  handleChange(event, row.student_uuid, false);
                }}
              >
                <span className="deactivate-button1" >
                  <i className="bi bi-bell-slash me-2 fs-6"></i>Archive
                </span>
              </div>) : null
          ) : (
            (permission_info?.activate_deactivate_class !== false) ?
              (<div className="d-flex align-items-center me-5"
                onClick={(event) => {
                  handleChange(event, row.student_uuid, true);
                }}
              >
                <span className="deactivate-button1">
                  <i className="bi bi-bell me-2 fs-6"></i>Activate Student
                </span>
              </div>) : null
          )} */}
          <button type="submit"
            className="btn btn-sm btn-primary  me-3"
            onClick={openTwoAndCloseOne}>
            Confirm
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};


const customStyles = {
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: state.isFocused ? "#e7f5fe" : "",
    },
    backgroundColor: state.isSelected ? "#4a8fb8" : "",
  }),
};


const DeleteModal2 = ({
  modalState,
  toggleModalTwo,
  confirmRemoveData,
  DeleteStudent }) => {


    const [modalOpen, setModalOpen] = useState(modalState);
    const [typeDelete, setTypeDelete] = useState('');
    const [studentName, setStudentName] = useState("")
    const [showAlert,setShowAlert] = useState(!modalState)
    console.log("modal_data",  showAlert);

 
  useEffect(() => {
    setModalOpen(modalState);
    setStudentName(confirmRemoveData.student_first_name + " " + confirmRemoveData.student_last_name)
    if(modalState === true){
      setShowAlert(false)
    }
  }, [modalState]);

  const inputChangeHandler = (event, identifier) => {
    if(typeDelete.length>1){
      setShowAlert(false)
    }
    setTypeDelete(event.target.value)
  }

  const SubmitHandler = (event) => {
    event.preventDefault()
    if (typeDelete === 'DELETE') {
      DeleteStudent()
      toggleModalTwo()
      setTypeDelete("")
    }else{
      setShowAlert(true)
    }
  }

  return (

    <Modal
      backdrop="static"
      keyboard={false}
      className="confirm_delete_modal confirm_modal"
      show={modalOpen}

    >
      <Modal.Header >
        <Modal.Title>Confirm Deletion of {studentName}</Modal.Title>
        <span className="pointer" onClick={(event) => toggleModalTwo(event, confirmRemoveData)}>
          <i className="fas fa-times me-2 fs-6" ></i>
        </span>
      </Modal.Header>
      <Modal.Body >
        <form className="form w-100"
          onSubmit={SubmitHandler}
        >
          <div className="row">
            <div className="col-lg-12 fv-row mb-4">
              <div className="row">
                <h5 className="py-3" style={{ backgroundColor: "#ebbebe59", color: "#c11616", fontSize: "normal" }}>All data associated with this account will be permanently deleted and cannot be recovered.</h5>
                <div className="col-lg-12 my-4">
                  <label className="fw-bold mb-2 me-4">Please enter 'DELETE' Inside The Box To Confirm their account deletion:</label>
                  <input
                    styles={customStyles}
                    type='text'
                    name='DELETE'
                    className="form-control input_field"
                    onChange={(event) => inputChangeHandler(event, "DELETE")}
                    placeholder="Type 'DELETE' in the box"
                  />
                  {showAlert?<p className="text-danger">Please Enter 'DELETE' Inside The Box</p>:""}
                </div>
  
                <div className="col-sm-12 mt-4 d-flex justify-content-end">

                  <button type="submit" className="btn btn-sm btn-primary">
                    Delete Student
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};


export { DeleteModal1 as CustomDeleteModal1, DeleteModal2 as CustomDeleteModal2 };