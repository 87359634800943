import React from "react";
import { Navigate } from "react-router-dom";
import Validations from "../Utility/Validations";
import Sidebar from "../Constant/sidebar";
import Footer from "../Constant/Footer";
import TopBar from "../Constant/TopBar";
import * as userActions from "../../actions/index";
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import moment from "moment";
import Select from "react-select";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";

import * as actionType from "../../constants/actionTypes";
import { connect } from "react-redux";

import { toast } from "react-toastify";

const permission_info = JSON.parse(localStorage.getItem("permissions"));
const user_id = JSON.parse(localStorage.getItem("user_id"));
const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info.school_code;

console.log("permission_info", permission_info);
class ManagerProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user_id: '',
            manager_edit_form: {
                avatar: {
                    label: "File",
                    type: "file",
                    value: '',
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                    thumbnail_image: "/assets/media/school-logo.png",
                },



                first_name: {
                    type: "text",
                    label: "First Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "First Name",
                    validations: { required: true },
                },
                last_name: {
                    type: "text",
                    label: "Last Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Last Name",
                    validations: { required: false },
                },
                email: {
                    type: "email",
                    label: "Email",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Email",
                    validations: { required: false },
                },
                // password: {
                //     type: "password",
                //     label: "Password",
                //     value: "",
                //     valid: true,
                //     error_msg: "",
                //     onBlur_out: false,
                //     placeholder: "Abc@123",
                //     validations: { required: false },
                // },
                contact_one: {
                    type: "tel",
                    label: "Contact Number",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Contact Number",
                    validations: { required: true, min_length_phone: 7 },
                },
                designation: {
                    type: "text",
                    label: "Designation",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Designation",
                    validations: { required: true },
                },
                comment: {
                    type: "text",
                    label: "Comment",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "comment",
                    validations: { required: false },
                },
                user_uuid: {
                    type: "text",
                    label: "user_uuid",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },
                is_user_activate: {
                    type: "text",
                    label: "user_uuid",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },
                last_archived_date: {
                    type: "text",
                    label: "Date of Relieving",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },
                archived_note: {
                    type: "text",
                    label: "Relieving Note",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },
                created_date: {
                    type: "text",
                    label: " Date Of Joining",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },


                role_id: {
                    type: "text",
                    label: "Role ID  *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: true },
                },
                // permission_data: {
                //     type: "hidden",
                //     label: "Permission Data  *",
                //     value: "",
                //     valid: true,
                //     error_msg: "",
                //     onBlur_out: false,
                //     validations: { required: true },
                // }

                snapshot_gender_both: {
                    type: "radio",
                    label: "Gender",
                    value: false,
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: false },
                },
                snapshot_gender_female: {
                    type: "radio",
                    label: "Gender",
                    value: false,
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: false },
                },
                snapshot_gender_male: {
                    type: "radio",
                    label: "Gender",
                    value: false,
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: false },
                },
                dorm_data: {
                    type: "select",
                    label: "Select Dormitory *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                    options: [],
                },
                class_data: {
                    type: "select",
                    label: "Select Class *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                    options: [],
                },

            },
        }
    };



    componentDidMount() {
        this.GetSchoolManagerById();
        this.GetRoleAndPermissionList();
        this.GetManagerPermissonDetailsById()
    }


    GetSchoolManagerById() {
        this.props.onGetSchoolManagerById(this.props.user_uuid).then((response) => {
            if (response.success === true) {
                let manager_data = response.data;
                console.log("DSfsdgdfgdf",manager_data);
                const update_manager_edit_form = { ...this.state.manager_edit_form };
                let userID = manager_data.user_id;
                let userUUID = manager_data.user_uuid;
                this.setState({ user_id: userID })
                localStorage.setItem('user_id', JSON.stringify(userID));
                localStorage.setItem('user_uuid', JSON.stringify(userUUID));
                for (let key in update_manager_edit_form) {
                    let form_element = update_manager_edit_form[key];
                    if (manager_data[key]) {
                        if (manager_data.avatar !== null && manager_data.avatar !== '') {
                            update_manager_edit_form['avatar'].thumbnail_image = actionType.environment_url + '/' + manager_data.avatar;
                            update_manager_edit_form['avatar'].value = manager_data.avatar;
                        }
                        update_manager_edit_form['first_name'].value = manager_data.first_name;
                        update_manager_edit_form['last_name'].value = manager_data.last_name;
                        update_manager_edit_form['email'].value = manager_data.email;
                        update_manager_edit_form['contact_one'].value = manager_data.contact_one;
                        update_manager_edit_form['email'].value = manager_data.email;
                        update_manager_edit_form['comment'].value = manager_data.comment;
                        update_manager_edit_form['designation'].value = manager_data.designation;
                        update_manager_edit_form['user_uuid'].value = manager_data.user_uuid;
                        update_manager_edit_form['is_user_activate'].value = manager_data.is_user_activate;
                        update_manager_edit_form['last_archived_date'].value = moment(manager_data.last_archived_date).format("MMMM Do YYYY");;
                        update_manager_edit_form['archived_note'].value = manager_data.archived_note;
                        update_manager_edit_form['created_date'].value = moment(manager_data.created_date).format("MMMM Do YYYY");
                        update_manager_edit_form["role_id"].value = manager_data.role_id;
                        update_manager_edit_form[key] = form_element;

                    }
                }
                this.setState({ manager_edit_form: update_manager_edit_form });
                const selectedRoleID = manager_data.role_id;
                this.GetRoleAndPermissionList(selectedRoleID);
                this.GetManagerPermissonDetailsById(userID)
            } else {
            }
        });

    }

    GetRoleAndPermissionList = async (selectedRoleID) => {
        this.props.onGetRoleAndPermissionList().then((response) => {
            if (response.success === true) {
                const arrayData = response.data;
                const update_manager_edit_form = { ...this.state.manager_edit_form }
                let country_level = [];
                for (let key in arrayData) {
                    country_level.push({
                        label: arrayData[key]?.role_name,
                        value: arrayData[key]?.role_id,
                    })
                }
                update_manager_edit_form.role_id.value = country_level.filter(item => item.value === selectedRoleID)
                this.setState({ manager_edit_form: update_manager_edit_form })
            }else {
            }
        });
    }

    GetManagerPermissonDetailsById(userID) {
        this.props.onGetManagerPermissonDetailsById(userID).then((response) => {
            if (response.success === true) {
                let permission_res = response.data;
                const update_manager_edit_form = { ...this.state.manager_edit_form };
                const classes = [];
                const dormitoryes = []
                permission_res.perm_class_dorm.forEach(element => {
                    if (element.permi_dorm_data) {
                        dormitoryes.push({
                            value: element.permi_dorm_data.dormitory_id,
                            label: element.permi_dorm_data.dormitory_name
                        })
                    }
                    if (element.permi_class_data) {
                        classes.push({
                            value: element.permi_class_data.class_id,
                            label: element.permi_class_data.class_name
                        })
                    }

                });
                update_manager_edit_form["dorm_data"].value = dormitoryes;
                update_manager_edit_form["class_data"].value = classes;
                update_manager_edit_form["snapshot_gender_female"].value = permission_res?.snapshot_gender_female;
                update_manager_edit_form["snapshot_gender_male"].value = permission_res?.snapshot_gender_male;
                update_manager_edit_form["snapshot_gender_both"].value = permission_res?.snapshot_gender_both;
                this.setState({ manager_edit_form: update_manager_edit_form });
            } else {
            }
        });
    }




    render() {
        const { manager_edit_form } = this.state;
        return (
            <React.Fragment>
                {this.state.isAuthenticated ? <Navigate to={this.state.redirect_url} /> : null}

                <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed demo h-100">
                    <div className="d-flex flex-column flex-root h-100">
                        <div className="page d-flex flex-row flex-column-fluid">
                            <Sidebar></Sidebar>
                            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                                <TopBar></TopBar>
                                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                    <div className="post d-flex flex-column-fluid" id="kt_post">
                                        <div id="kt_content_container" className="container-fluid">

                                            <ul className="nav nav-tabs home-tabs" id="myTab" role="tablist">
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/manager-list/manager-profile/${this.props.user_uuid}`} className="nav-link active" id="ManagerProfile-tab" type="button" aria-controls="home" aria-selected="true">
                                                        Manager Profile
                                                    </Link>
                                                </li>

                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/manager-list/manager-history`} className="nav-link" id="PermissionHistory-tab" type="button" aria-controls="process" aria-selected="false">
                                                        Permissions History
                                                    </Link>
                                                </li>
                                            </ul>
                                            <div className="school-edit-form ">
                                                <div className="" id="Snapshot">
                                                    <form onSubmit={this.SubmitHandler}>
                                                        <div className="card mb-5 tabs-outer">


                                                            <div className="card-header position-relative py-0 details-bg align-items-center">
                                                                <h2 className="card-title  m-0">Manager Profile</h2>
                                                                {manager_edit_form.is_user_activate.value === true ? (
                                                                    <span className="badge badge-light-success px-4 py-2">Active</span>
                                                                ) : (
                                                                    <span className="badge badge-light-danger px-4 py-2">Inactive</span>
                                                                )}
                                                            </div>


                                                            <div className="col-lg-12 fv-row mb-5">
                                                                <div className="row">

                                                                </div>
                                                            </div>

                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-lg-4 col-xl-3">
                                                                        <div className="profile-image-out p-0">
                                                                            <div className="d-flex">
                                                                                <div className="col-sm-12">

                                                                                    <img alt="profile" src={manager_edit_form?.avatar?.thumbnail_image} className="img-fluid" />

                                                                                    <div className=" h-100 flex-column align-items-center">


                                                                                        <input
                                                                                            hidden
                                                                                            type="file"
                                                                                            accept=".png, .jpg, .jpeg"
                                                                                            // onChange={(event) => this.inputChangeHandlerimage(event, "student_avatar")}
                                                                                            onBlur={(event) => {
                                                                                                manager_edit_form.userName.onBlur_out = true;
                                                                                                this.setState({
                                                                                                    manager_edit_form: manager_edit_form,
                                                                                                });
                                                                                            }}
                                                                                            readOnly
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-8 col-xl-9">

                                                                        <div className="row">
                                                                            {manager_edit_form.is_user_activate.value === false ?
                                                                                <div className="col-md-12 col-lg-4 col-xl-4 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{manager_edit_form.first_name.label} </label>
                                                                                    <input
                                                                                        type={manager_edit_form.first_name.type}
                                                                                        value={manager_edit_form.first_name.value ? manager_edit_form.first_name.value : ""}
                                                                                        className="form-control"
                                                                                        placeholder={manager_edit_form.first_name.placeholder}
                                                                                        onChange={(event) => this.inputChangeHandler(event, "first_name")}
                                                                                        readOnly
                                                                                    />
                                                                                    {!manager_edit_form.first_name.valid && manager_edit_form.first_name.onBlur_out ? (
                                                                                        <div className="error field-error">{manager_edit_form.first_name.error_msg}</div>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </div> :

                                                                                <div className="col-md-12 col-lg-4 col-xl-4 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{manager_edit_form.first_name.label} </label>

                                                                                    <input
                                                                                        type={manager_edit_form.first_name.type}
                                                                                        value={manager_edit_form.first_name.value ? manager_edit_form.first_name.value : ""}
                                                                                        className="form-control"
                                                                                        placeholder={manager_edit_form.first_name.placeholder}
                                                                                        onChange={(event) => this.inputChangeHandler(event, "first_name")}
                                                                                        readOnly
                                                                                    />

                                                                                    {!manager_edit_form.first_name.valid && manager_edit_form.first_name.onBlur_out ? (
                                                                                        <div className="error field-error">{manager_edit_form.first_name.error_msg}</div>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </div>
                                                                            }


                                                                            {manager_edit_form.is_user_activate.value === false ?
                                                                                <div className="col-md-12 col-lg-4 col-xl-4 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{manager_edit_form.last_name.label} </label>
                                                                                    <input
                                                                                        type={manager_edit_form.last_name.type}
                                                                                        value={manager_edit_form.last_name.value ? manager_edit_form.last_name.value : ""}
                                                                                        className="form-control"
                                                                                        placeholder={manager_edit_form.last_name.placeholder}
                                                                                        onChange={(event) => this.inputChangeHandler(event, "last_name")}
                                                                                        readOnly
                                                                                    />

                                                                                    {!manager_edit_form.last_name.valid && manager_edit_form.last_name.onBlur_out ? <div className="error field-error">{manager_edit_form.last_name.error_msg}</div> : ""}
                                                                                </div> :

                                                                                <div className="col-md-12 col-lg-4 col-xl-4 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{manager_edit_form.last_name.label} </label>
                                                                                    <input
                                                                                        type={manager_edit_form.last_name.type}
                                                                                        value={manager_edit_form.last_name.value ? manager_edit_form.last_name.value : ""}
                                                                                        className="form-control"
                                                                                        placeholder={manager_edit_form.last_name.placeholder}
                                                                                        onChange={(event) => this.inputChangeHandler(event, "last_name")}
                                                                                        readOnly
                                                                                    />

                                                                                    {!manager_edit_form.last_name.valid && manager_edit_form.last_name.onBlur_out ? <div className="error field-error">{manager_edit_form.last_name.error_msg}</div> : ""}
                                                                                </div>
                                                                            }



                                                                            {manager_edit_form.is_user_activate.value === false ?

                                                                                <div className="col-md-12 col-lg-4 col-xl-4 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{manager_edit_form.email.label}</label>
                                                                                    <input
                                                                                        type={manager_edit_form.email.type}
                                                                                        value={manager_edit_form.email.value ? manager_edit_form.email.value : ""}
                                                                                        className="form-control"
                                                                                        placeholder={manager_edit_form.email.placeholder}
                                                                                        onChange={(event) => this.inputChangeHandler(event, "email")}
                                                                                        readOnly
                                                                                    />

                                                                                    {!manager_edit_form.email.valid && manager_edit_form.email.onBlur_out ? <div className="error field-error">{manager_edit_form.email.error_msg}</div> : ""}
                                                                                </div> :

                                                                                <div className="col-md-12 col-lg-4 col-xl-4 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{manager_edit_form.email.label}</label>
                                                                                    <input
                                                                                        type={manager_edit_form.email.type}
                                                                                        value={manager_edit_form.email.value ? manager_edit_form.email.value : ""}
                                                                                        className="form-control"
                                                                                        placeholder={manager_edit_form.email.placeholder}
                                                                                        onChange={(event) => this.inputChangeHandler(event, "email")}
                                                                                        readOnly
                                                                                    />

                                                                                    {!manager_edit_form.email.valid && manager_edit_form.email.onBlur_out ? <div className="error field-error">{manager_edit_form.email.error_msg}</div> : ""}
                                                                                </div>
                                                                            }

                                                                            {manager_edit_form.is_user_activate.value === false ?

                                                                                <div className="col-md-12 col-lg-4 col-xl-4 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{manager_edit_form.contact_one.label}</label>

                                                                                    <ReactPhoneInput
                                                                                        disabled
                                                                                        country={'in'}
                                                                                        value={manager_edit_form.contact_one.value ? manager_edit_form.contact_one.value : ""}
                                                                                        onChange={(event) => this.inputChangeHandler(event, "contact_one")}

                                                                                    />

                                                                                    {!manager_edit_form.contact_one.valid && manager_edit_form.contact_one.onBlur_out ? (
                                                                                        <div className="error field-error">{manager_edit_form.contact_one.error_msg}</div>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </div> :

                                                                                <div className="col-md-12 col-lg-4 col-xl-4 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{manager_edit_form.contact_one.label}</label>

                                                                                    <ReactPhoneInput
                                                                                        disabled
                                                                                        country={'in'}
                                                                                        value={manager_edit_form.contact_one.value ? manager_edit_form.contact_one.value : ""}
                                                                                        onChange={(event) => this.inputChangeHandler(event, "contact_one")}

                                                                                    />

                                                                                    {!manager_edit_form.contact_one.valid && manager_edit_form.contact_one.onBlur_out ? (
                                                                                        <div className="error field-error">{manager_edit_form.contact_one.error_msg}</div>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </div>
                                                                            }


                                                                            <div className="col-md-12 col-lg-4 col-xl-4 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">
                                                                                    {manager_edit_form.designation.label}
                                                                                    <Tooltip title="This is the Boarding staff members actual designation (Position Title) within your school as their employed title reads.  In some cases it will (or can) be the same as “Role.  Role is only unique to this system - the Admin links Role to Permissions. (Permissions can be changed by Admin, at any time.)" placement="top">
                                                                                        <i className="fas fa-question-circle ms-2"></i>
                                                                                    </Tooltip>
                                                                                </label>
                                                                                <input
                                                                                    type={manager_edit_form.designation.type}
                                                                                    className="form-control"
                                                                                    placeholder={manager_edit_form.designation.placeholder}
                                                                                    onChange={(event) => this.inputChangeHandler(event, "designation")}
                                                                                    value={manager_edit_form.designation.value ? manager_edit_form.designation.value : ""}
                                                                                    readOnly
                                                                                />

                                                                            </div>

                                                                            <div className="col-md-12 col-lg-4 col-xl-4 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{manager_edit_form.created_date.label}</label>
                                                                                <input
                                                                                    type={manager_edit_form.created_date.type}
                                                                                    className="form-control"
                                                                                    placeholder={manager_edit_form.created_date.placeholder}
                                                                                    onChange={(event) => this.inputChangeHandler(event, "created_date")}
                                                                                    value={manager_edit_form.created_date.value ? manager_edit_form.created_date.value : ""}
                                                                                    readOnly
                                                                                />

                                                                            </div>




                                                                            {manager_edit_form.is_user_activate.value === false ?
                                                                                <div className="col-md-12 col-lg-4 col-xl-4 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{manager_edit_form.last_archived_date.label}</label>
                                                                                    <input
                                                                                        type={manager_edit_form.last_archived_date.type}
                                                                                        className="form-control"
                                                                                        placeholder={manager_edit_form.last_archived_date.placeholder}
                                                                                        onChange={(event) => this.inputChangeHandler(event, "last_archived_date")}
                                                                                        value={manager_edit_form.last_archived_date.value ? manager_edit_form.last_archived_date.value : ""}
                                                                                        readOnly
                                                                                    />

                                                                                </div> : null
                                                                            }

                                                                            <div className="col-md-12 col-lg-4 col-xl-4 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">
                                                                                    Select Role
                                                                                    <Tooltip title="Role” refers to the predefined Roles that Admin has already assigned in this system to match the permissions and access to dormitory information." placement="top">
                                                                                        <i className="fas fa-question-circle ms-2"></i>
                                                                                    </Tooltip>
                                                                                </label>
                                                                                <div className="mt-0">
                                                                                    <div className="checkbox cursor-pointer ">
                                                                                        <Select
                                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                                            value={manager_edit_form?.role_id.value}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        
                                                                            {manager_edit_form.is_user_activate.value === false ?
                                                                                <div className="col-md-12 col-lg-4 col-xl-4 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{manager_edit_form.archived_note.label}</label>
                                                                                    <textarea
                                                                                        type={manager_edit_form.archived_note.type}
                                                                                        className="form-control autoresizing1"
                                                                                        placeholder={manager_edit_form.archived_note.placeholder}
                                                                                        onChange={(event) => this.inputChangeHandler(event, "archived_note")}
                                                                                        value={manager_edit_form.archived_note.value ? manager_edit_form.archived_note.value : ""}
                                                                                        readOnly
                                                                                    />

                                                                                </div> : null
                                                                            }

                                                                            {manager_edit_form.is_user_activate.value === false ?

                                                                                <div className="col-md-12 col-lg-8 col-xl-8 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{manager_edit_form.comment.label}</label>
                                                                                    <textarea
                                                                                        type={manager_edit_form.comment.type}
                                                                                        className="form-control autoresizing1"
                                                                                        placeholder={manager_edit_form.comment.placeholder}
                                                                                        onChange={(event) => this.inputChangeHandler(event, "comment")}
                                                                                        value={manager_edit_form.comment.value ? manager_edit_form.comment.value : ""}
                                                                                        readOnly
                                                                                    />

                                                                                    {!manager_edit_form.comment.valid && manager_edit_form.comment.onBlur_out ? <div className="error field-error">{manager_edit_form.comment.error_msg}</div> : ""}
                                                                                </div> :

                                                                                <div className="col-md-12 col-lg-8 col-xl-8 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{manager_edit_form.comment.label}</label>
                                                                                    <textarea
                                                                                        type={manager_edit_form.comment.type}
                                                                                        className="form-control autoresizing1"
                                                                                        placeholder={manager_edit_form.comment.placeholder}
                                                                                        onChange={(event) => this.inputChangeHandler(event, "comment")}
                                                                                        value={manager_edit_form.comment.value ? manager_edit_form.comment.value : ""}
                                                                                        readOnly
                                                                                    />

                                                                                    {!manager_edit_form.comment.valid && manager_edit_form.comment.onBlur_out ? <div className="error field-error">{manager_edit_form.comment.error_msg}</div> : ""}
                                                                                </div>
                                                                            }


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        <div className="card">
                                                            <div className="card-header position-relative py-0 details-bg">
                                                                <h5 className="card-title  m-0">Snapshot Permissions</h5>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="row">
                                                                            <div className="col-md-2">
                                                                                <label class="fw-bold mb-0">Manage Genders</label>
                                                                            </div>
                                                                            <div className="col-md-10">
                                                                                <div className="radio-btn d-flex ">
                                                                                   
                                                                                        <label className="cursor-pointer d-flex align-items-center">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id="gender1"
                                                                                                name="gen"
                                                                                                className="form-check-input me-2 cursor-pointer"
                                                                                                checked={manager_edit_form.snapshot_gender_male.value === true ? manager_edit_form.snapshot_gender_male.value === true : null}
                                                                                                disabled={!manager_edit_form.snapshot_gender_male.value}
                                                                                            />
                                                                                            Male
                                                                                        </label>
                                                                      
                                                                                        <label className="cursor-pointer d-flex align-items-center">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id="gender2"
                                                                                                name="gen"
                                                                                                className="form-check-input me-2 cursor-pointer"
                                                                                                checked={manager_edit_form.snapshot_gender_female.value === true ? manager_edit_form.snapshot_gender_female.value === true : null}
                                                                                                disabled={!manager_edit_form.snapshot_gender_female.value}
                                                                                            />
                                                                                            Female
                                                                                        </label>
                                                                                 
                                                                                  
                                                                                        <label className="cursor-pointer d-flex align-items-center">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id="genderall"
                                                                                                name="gen"
                                                                                                className="form-check-input me-2 cursor-pointer"
                                                                                                checked={manager_edit_form.snapshot_gender_both.value === true ? manager_edit_form.snapshot_gender_both.value === true : null}
                                                                                                disabled={!manager_edit_form.snapshot_gender_both.value}
                                                                                            />
                                                                                            Both
                                                                                        </label>
                                                                                   
                                                                                </div>
                                                                            </div>
                                                                        </div>





                                                                        <div className="row mt-5">
                                                                            <div className="col-6">
                                                                                <div className="checkbox-inline">
                                                                                    <label className="mb-0 fw-bold">Select Class</label>
                                                                                    <div className="checkbox cursor-pointer w-100 mt-4">
                                                                                        <Select
                                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                                            value={manager_edit_form?.class_data.value}
                                                                                            isMulti
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <div className="checkbox-inline">
                                                                                    <label className="mb-0 fw-bold">Select Dormitory</label>
                                                                                    <Tooltip title="Whilst engaging with the initial Role setup - ‘Dormitory’ cannot be selected as they have not yet been created. (if you are following the steps correctly) You will return to these two Role Permissions in a few steps time." placement="top">
                                                                                        <i className="fas fa-question-circle ms-2"></i>
                                                                                    </Tooltip>
                                                                                    <div className="checkbox cursor-pointer w-100 mt-4">
                                                                                        <Select
                                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                                            value={manager_edit_form?.dorm_data.value}
                                                                                            isMulti
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Footer />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}

const mapDispatchToProps = {
    onUpdateSchooManager: userActions.UpdateSchooManager,
    onGetSchoolManagerById: userActions.GetSchoolManagerById,
    onGetLoginUserPermissions: userActions.GetLoginUserPermissions,
    onGetRoleAndPermissionList: userActions.GetRoleAndPermissionList,
    onGetManagerPermissonDetailsById: userActions.GetManagerPermissonDetailsById,
    onGetAllDormitoryStudent: userActions.GetAllDormitoryStudent,
    onGetAllClassesList: userActions.GetAllClassesList,
};
export default connect(mapStateToProps, mapDispatchToProps)(ManagerProfile);

