import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ManagerList from "../component/Manager/ManagerList";
import ManagerAdd from "../component/Manager/ManagerAdd";
import ManagerEdit from "../component/Manager/ManagerEdit";
import ManagerProfile from "../component/Manager/ManagerProfile";
import ManagerPermission from "../component/Manager/ManagerPermission";
import ManagerPermHistory from "../component/Manager/ManagerPermHistory";

const permission_info = JSON.parse(localStorage.getItem("permissions"));
const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" :user_info?.school_code;

const Manager = (props) => {
    const Renderview = () => {
        let location = useLocation();
        let userId = useParams();

        if ((location.pathname === `/${school_code}/manager-list` || location.pathname === `/${school_code}/manager-list/`) && permission_info?.view_user !== false ) {
            return <ManagerList />;
        }

        if ((location.pathname === `/${school_code}/manager-list/add-manager` || location.pathname === `/${school_code}/manager-list/add-manager/`) && permission_info?.add_user !== false ) {
            return <ManagerAdd />;
        }
        
        if ((location.pathname === `/${school_code}/manager-list/edit-manager/${userId.id}` || location.pathname === `/${school_code}/manager-list/edit-manager/${userId.id}/`) && permission_info?.edit_user !== false) {
            return <ManagerEdit user_uuid={userId.id}  /> ;
        }
        if ((location.pathname === `/${school_code}/manager-list/manager-profile/${userId.id}` || location.pathname === `${school_code}/manager-list/manager-profile/${userId.id}/`) && permission_info?.view_user !== false) {
            return <ManagerProfile user_uuid={userId.id}  /> ;
        }
         if ((location.pathname === `/${school_code}/manager-list/edit-manager-permission` || location.pathname === `/${school_code}/manager-list/edit-manager-permission/`) && permission_info?.edit_user !== false) {
            return <ManagerPermission /> ;
        }
        if ((location.pathname === `/${school_code}/manager-list/manager-history` || location.pathname === `/${school_code}/manager-list/manager-history/`) && permission_info?.view_user !== false) {
            return <ManagerPermHistory /> ;
        }
    };

    return (
        <React.Fragment>
            <ToastContainer />
            {Renderview()}
        </React.Fragment>
    );
};
export default Manager;