import axios from "axios";
import * as actionType from "../../constants/actionTypes";
let qs = require("qs");

/*** GetAllStudentsHost ***/

export const GetAllStudentsHostStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllStudentsHost_START,
    };
};
export const GetAllStudentsHostSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllStudentsHost_SUCCESS,
    };
};
export const GetAllStudentsHostFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllStudentsHost_FAIL,
    };
};
export const GetAllStudentsHost = (page, limit, sort, order,search_host,search_student,class_id,dormitory_id ) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllStudentsHostStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllStudentsHost`,
            params: {
                page: page,
                limit: limit,
                sort: sort,
                order: order,
                search_host:search_host,
                search_student:search_student,
                class_id:class_id,
                dormitory_id:dormitory_id
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllStudentsHostSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllStudentsHostFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** ApproveHost ***/

export const ApproveHostStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.ApproveHost_START,
    };
};
export const ApproveHostSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.ApproveHost_SUCCESS,
    };
};
export const ApproveHostFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.ApproveHost_FAIL,
    };
};

export const ApproveHost = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(ApproveHostStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/ApproveHost`,
        })
            .then(function (response) {
                dispatch(ApproveHostSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(ApproveHostFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** Edit host data Action ***/

export const UpdateStudentHostStatusStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateStudentHostStatus_START,
    };
};
export const UpdateStudentHostStatusSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateStudentHostStatus_SUCCESS,
    };
};
export const UpdateStudentHostStatusFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateStudentHostStatus_FAIL,
    };
};

export const UpdateStudentHostStatus = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    // console.log("form_data============",form_data);
    return (dispatch) => {
        dispatch(UpdateStudentHostStatusStart());
        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url:`${actionType.environment_url}/api/bss-admin/UpdateStudentHostStatus`,
   
        })
        .then(function (response) {
            dispatch(UpdateStudentHostStatusSuccess(response.data));
            return response.data;
        })
        .catch(function (error) {
            dispatch(UpdateStudentHostStatusFail("Something went wrong, Please try again."));
            return error;
        });
    };
};

