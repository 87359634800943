import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";

import Footer from "../../../Constant/Footer";
import TopBar from "../../../Constant/TopBar";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import * as userActions from "../../../../actions/index";
import IconButton from '@mui/material/IconButton';
import Select from 'react-select';
// import ClassItem from "./ClassItem";
import Sidebar from "../../../Constant/sidebar";
import IDCardItem from "./IDCardItem";


const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#e7f5fe" : "",
        },
        backgroundColor: state.isSelected ? "#4a8fb8" : "",
    }),
};

const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info?.school_code;
const IDCard = (props) => {
    const dispatch = useDispatch();
    const [filterpage, setfilterpage] = useState(1);
    const [filterlimit, setfilterlimit] = useState(12);
    const [filterorder] = useState("asc");
    const [filtersort] = useState("student_first_name");

    const [class_id, setclass_id] = useState("");
    const [class_ids, setclass_ids] = useState([])
    const [dormitory_id, setdormitory_id] = useState("");
    const [dormitory_ids, setdormitory_ids] = useState([])
    const [student_age, setStudent_age] = useState("");
    const [student_ages, setStudent_ages] = useState([])
    const [search, setSearch] = useState("")
    const [gender, setGender] = useState("");
    const [managerList,setManagerList] = useState([])
    const [manager,setManager] = useState("")
   

    const [IDCard, setIDCard] = useState([]);
    const [IDCardcount, setIDCardcount] = useState(0);
    const [is_student_activate] = useState('');
    const navigate = useNavigate();

    const GetAllStudentsForCard = useCallback(async () => {
        dispatch(userActions.GetAllStudentsForCard(filterpage, filterlimit, filtersort, filterorder, is_student_activate, dormitory_id, class_id, student_age, search, gender,)).then((response) => {

            if (response.success === true) {
                const arrayData = response.data.rows;
                let MemberId_level = [];
                const sumvalue = 1;
                for (let key in arrayData) {
                    const perpage = ((parseInt(filterpage) - parseInt(sumvalue)) * parseInt(filterlimit) + parseInt(key) + parseInt(sumvalue));

                    MemberId_level.push({
                        perpage: perpage,
                        country_id: arrayData[key].country_id,
                        name: arrayData[key].name,


                    });
                }

                setIDCard(arrayData);
                setIDCardcount(response.data.count);
            }

            else if (response.status === 401) {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                setTimeout(() => {
                    navigate(`/${school_code}/dashboard`)
                }, 5000);
            }

            else {
            }
        });
        // eslint-disable-next-line
    }, [dispatch, filterpage, filterlimit, filtersort, filterorder, is_student_activate, dormitory_id, class_id, student_age, search, gender,]);

    useEffect(() => {
        GetAllStudentsForCard();
    }, [GetAllStudentsForCard, filterpage, filterlimit, filtersort, filterorder, is_student_activate, dormitory_id, class_id, student_age, search, gender,]);

    


    const backbutton = {
        display: "inline-block",
        outline: 0,
        border: 0,
        cursor: "pointer",
        fontWeight: 600,
        color: "rgb(72, 76, 122)",
        fontSize: "14px",
        height: "38px",
        padding: "8px 24px",
        bordeRadius: "50px",
        backgroundImage: "linear-gradient(180deg,#fff,#f5f5fa)",
        boxShadow: "0 4px 11px 0 rgb(37 44 97 / 15%), 0 1px 3px 0 rgb(93 100 148 / 20%)",
        transition: "all .1s ease-in-out",
    }




    const GetAllDormitoryStudent = async () => {
        props.onGetAllDormitoryStudent().then((response) => {
            let country_level = [];
            country_level.push({
                label: "Select Dormitory",
                value: ""
            })
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    country_level.push({
                        label: arrayData[key].dormitory_name,
                        value: arrayData[key].dormitory_id,
                    });
                }
            }
            setdormitory_ids(country_level)

        });
    }
    const GetAllClassesList = async () => {
        props.onGetAllClassesList().then((response) => {
            let country_level = [];
            country_level.push({
                label: "Select Year",
                value: ""
            })
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    country_level.push({
                        label: arrayData[key].class_name,
                        value: arrayData[key].class_id,
                    });
                }
            }
            setclass_ids(country_level)

        });
    }

    

    const GetAllManagersBySchoolId = async () => {
        const bss_authuser = JSON.parse(localStorage.getItem("bss_authuser"))
        props.onGetAllManagersBySchoolId().then((response) => {
            let country_level = [];
            country_level.push({
                label: bss_authuser.first_name + " " + bss_authuser.last_name,
                value: "",
                contect:bss_authuser.contact_one
            })
            if (response.success === true) {
                const arrayData = response.data.rows;
                for(let key in arrayData){
                    country_level.push({
                        label: arrayData[key].first_name + " " + arrayData[key].last_name,
                        value: arrayData[key].user_id,
                        contact:arrayData[key].contact_one
                    })
                }
                setManagerList(country_level)
            } else {
            }
        });
    };


    useEffect(() => {
        GetAllClassesList();
        GetAllDormitoryStudent();
        GetAllManagersBySchoolId()
    }, []);


    const printTempIdFunction = (e) => {
        document.getElementsByClassName('temp_id_filter_hide')[0].setAttribute('style', 'display:none !important');
        document.getElementsByClassName('header')[0].setAttribute('style', 'display:none !important');
        document.getElementsByClassName('pagination-main')[0].setAttribute('style', 'display:none !important');
        document.getElementsByClassName('footer')[0].setAttribute('style', 'display:none !important');
        document.getElementsByClassName('wrapper')[0].setAttribute('style', 'padding:0 !important');
        let all_margin_padding = document.querySelectorAll('#margin_padding_temp_id .temp_id_padding_and_margin_0')
        for(let all of all_margin_padding){
            all.classList.add('temp_id_padding_and_margin');
        }
        let Row = document.querySelectorAll("#printDiv .custom-row-class")
        for (let i of Row) {
            i.classList.add("pint_pdf")
        }
        window.print();
        for (let i of Row) {
            i.classList.remove('pint_pdf')
        }
        for(let all of all_margin_padding){
            all.classList.remove('temp_id_padding_and_margin');
        }
        document.getElementsByClassName('pagination-main')[0].setAttribute('style', 'display:flex !important');
        document.getElementsByClassName('footer')[0].setAttribute('style', 'display:flex !important');
        document.getElementsByClassName('temp_id_filter_hide')[0].setAttribute('style', 'display:block !important');
        document.getElementsByClassName('header')[0].setAttribute('style', 'display:flex !important');
        document.getElementsByClassName('wrapper')[0].setAttribute('style', 'padding:  !important');
    }

    const selectManagerHandler = (event) => {
        setManager(event.value)
        const filterIDCard = IDCard.map((id) => {
            return {...id,manager_name:event.label,manager_contact:event.contact}
        })
        setIDCard(filterIDCard)
    }
    return (
        <>
            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed" >
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid" id="margin_padding_temp_id">
                        <Sidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid " id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid temp_id_padding_and_margin_0" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-fluid temp_id_padding_and_margin_0">
                                        <div className='row temp_id_filter_hide'>
                                            <div className='col-md-12'>
                                                <div className='card'>
                                                    <div className='card-header align-items-center'>
                                                        <h3 className='m-0 fw-bolder'>Student Search</h3>
                                                        <div>
                                                            <button type="button" className="btn bg-white me-4" style={backbutton}>
                                                                <Link to={`/${school_code}/student/student-list`}>
                                                                    <i className="fa fa-angle-left me-3" aria-hidden="true"></i>Go Back
                                                                </Link>
                                                            </button>
                                                            <button type="button" className="btn bg-white text-primary" onClick={printTempIdFunction} style={backbutton}>
                                                                <i class="fa fa-print" aria-hidden="true"></i>
                                                                Print ID
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className='card-body'>
                                                        <div className="row filter-block-two">
                                                            <div className="col">
                                                                <label className='form-label'>Select Year</label>
                                                                <Select
                                                                    styles={customStyles}
                                                                    className="react-bootstrap-typeahead tag-outer select2-selection select2-selection--single "
                                                                    options={class_ids}
                                                                    value={class_ids.filter((option) => {
                                                                        return option.value === class_id
                                                                    })}
                                                                    onChange={(event) => {
                                                                        setclass_id(event.value);
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <label className='form-label'>Select Dormitory</label>
                                                                <Select
                                                                    styles={customStyles}
                                                                    className="react-bootstrap-typeahead tag-outer"
                                                                    options={dormitory_ids}
                                                                    value={dormitory_ids.filter((option) => {
                                                                        return option.value === dormitory_id
                                                                    })}
                                                                    onChange={(event) => {
                                                                        setdormitory_id(event.value);
                                                                    }}

                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <label className='form-label'>Select Manager</label>
                                                                <Select
                                                                    styles={customStyles}
                                                                    className="react-bootstrap-typeahead tag-outer"
                                                                    options={managerList}
                                                                    value={managerList.filter((option) => {
                                                                        return option.value === manager
                                                                    })}
                                                                    onChange={(event) => {selectManagerHandler(event)}}
                                                                    />
                                                            </div>

                                                            <div className='col'>
                                                                <div className='form-group'>
                                                                    <label className='form-label'>Student Name</label>
                                                                    <div className="d-flex align-items-center position-relative">
                                                                      
                                                                        <input
                                                                            type="search"
                                                                            className="form-control pe-10"
                                                                            value={search}
                                                                            onChange={(e) => setSearch(e.target.value)}
                                                                        />
                                                                          <span className="svg-icon svg-icon-1 position-absolute  right-2">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect><path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path></svg>
                                                                        </span>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col">
                                                                <label className="form-label">Gender</label>
                                                                <div className="d-flex radio-btn mt-3">
                                                                    <label className="cursor-pointer d-flex align-items-center">
                                                                        <input
                                                                            type="radio"
                                                                            id="gende"
                                                                            name="gen"
                                                                            className="form-check-input cursor-pointer me-2"
                                                                            value="male"
                                                                            onChange={(event) => { setGender(event.target.value); }}
                                                                            checked={gender === "male" ? true : false}
                                                                        />
                                                                        Male
                                                                    </label>

                                                                    <label className="ms-4 cursor-pointer d-flex align-items-center">
                                                                        <input
                                                                            type="radio"
                                                                            id="gender"
                                                                            name="gen"
                                                                            className="form-check-input cursor-pointer me-2"
                                                                            value="female"
                                                                            onChange={(event) => { setGender(event.target.value); }}
                                                                            checked={gender === "female" ? true : false}
                                                                        />
                                                                        Female
                                                                    </label>

                                                                    <label className="cursor-pointer d-flex align-items-center">
                                                                        <input
                                                                            type="radio"
                                                                            id="gender"
                                                                            name="gen"
                                                                            className="form-check-input me-2 cursor-pointer me-2"
                                                                            value=""
                                                                            onChange={(event) => { setGender(event.target.value); }}
                                                                            checked={gender === "" ? true : false}
                                                                        />
                                                                        Both
                                                                    </label>
                                                                </div>
                                                            </div>

                                                            <div className="col">
                                                                <div className="d-flex justify-content-end align-items-end mt-7">
                                                                    <button type="reset" className="ms-3  btn btn-secondary btn-sm btn-color-gray-700 btn-flex btn-active-primary  border-0 fw-bolder px-4 px-lg-6 me-2 me-lg-3 w-200 white-space"
                                                                    onClick={(event) => {
                                                                        setclass_id("");
                                                                        setdormitory_id("");
                                                                        setSearch("")
                                                                        setGender("")
                                                                        setStudent_age("")
                                                                     }} 
                                                                    >
                                                                        Clear Search
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-5 temp_id_padding_and_margin_0">
                                            <div className="col-sm-12 temp_id_padding_and_margin_0" >
                                                <IDCardItem
                                                    IDCardcount={IDCardcount}
                                                    data={IDCard}
                                                    filterlimit={filterlimit}
                                                    filterpage={filterpage}
                                                    propsfilterpage={(event) => setfilterpage(event)}
                                                    propsfilterlimit={(filterlimit) => {
                                                        setfilterlimit(filterlimit);
                                                    }}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer></Footer>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {


    onUpdateClassStatus: userActions.UpdateClassStatus,
    onDeleteClass: userActions.DeleteClass,
    onGetAllDormitoryStudent: userActions.GetAllDormitoryStudent,
    onGetAllClassesList: userActions.GetAllClassesList,
    onGetAllManagersBySchoolId: userActions.GetAllManagersBySchoolId,


};
export default connect(mapStateToProps, mapDispatchToProps)(IDCard);