import React from "react";
import { useLocation , useParams} from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SchoolList from "../component/School/school";
import SchoolEdit from "../component/School/SchoolEdit";

const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" :user_info?.school_code;

const School = (props) => {
    const Renderview = () => {
        let location = useLocation();
        let userId = useParams();

        if (location.pathname === `/${school_code}/school/${userId.id}` || location.pathname === `/${school_code}/school/${userId.id}/`) {
            return <SchoolList user_uuid={userId.id} />;
        }
        if (location.pathname === `/${school_code}/school/edit-school/${userId.id}` || location.pathname === `/${school_code}/school/edit-school/${userId.id}/`) {
            return <SchoolEdit user_uuid={userId.id} />;
        }
        
    };

    return (
        <React.Fragment>
            <ToastContainer />
            {Renderview()}
        </React.Fragment>
    );
};
export default School;