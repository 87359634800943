import React from "react";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Validations from "../../component/Utility/Validations";
import * as userActions from "../../actions/index";

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            forgot_password_form: {
                email: {
                    type: "email",
                    //label: "Your first_name address",
                    value: "",
                    valid: true,
                    error_msg: "",
                    placeholder: "Enter your first_name address",
                    validations: { required: true, first_name: true },
                },
               
            },
            is_form_valid: false,
            passwordshow: false,

            redirect_url: "",
            isAuthenticated: false,
        };
    }
    async componentDidMount() {
    }
    inputChangeHandler(event, identifier) {
        const update_forgot_password_form = { ...this.state.forgot_password_form };
        const form_element = { ...update_forgot_password_form[identifier] };
            form_element.value = event.target.value;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_forgot_password_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_forgot_password_form) {
            is_form_valid = update_forgot_password_form[identifier].valid && is_form_valid;
        }

        this.setState({
            forgot_password_form: update_forgot_password_form,
            is_form_valid: is_form_valid,
        });
    }

    loginSubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_forgot_password_form = this.state.forgot_password_form;
        update_forgot_password_form.email.value = update_forgot_password_form.email.value.toLowerCase();
        for (let key in update_forgot_password_form) {
            let form_element = update_forgot_password_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            // form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_forgot_password_form[key] = form_element;
        }
        this.setState({ forgot_password_form: update_forgot_password_form });

        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.forgot_password_form) {
                form_data[key] = this.state.forgot_password_form[key].value;
            }
            this.props.onForgotPassword(form_data).then((response) => {
                if (response.success === true) {
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                       
                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            });
        }
    };

    render() {
        const { forgot_password_form } = this.state;
        return (
            <React.Fragment>
                <ToastContainer />
                {this.state.isAuthenticated ? <Navigate to={this.state.redirect_url} /> : null}
                <div className="d-flex flex-column flex-root h-100">
                    <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
                        <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
                            <Link to="/" className="mb-8">
                                {/* <img alt="Logo" src="/assets/media/logos/logo.png" className="h-70px logo" /> */}
                                <img alt="Logo" src="/assets/media/logos/TRIANGLE PNG (WOP) (2).png" style={{height: '130px'}} />
                            </Link>
                            <div className="w-lg-500px bg-body rounded shadow-sm p-8 p-lg-10 mx-auto">
                                <form className="form w-100" onSubmit={this.loginSubmitHandler}>
                                    <div className="text-center mb-10">
                                        <h1 className="text-dark mb-3">Forgot Password?</h1>
                                    </div>
                                    <div className="row">
                                        <div className="fv-row mb-5 col-sm-12">
                                            <label className="form-label fw-bolder text-dark">Enter your email</label>

                                            <input
                                                type="text"
                                            className="form-control"                                            
                                            value={forgot_password_form.email.value ? forgot_password_form.email.value : ""}
                                            onChange={(event) => this.inputChangeHandler(event, "email")}
                                            />
                                            {!forgot_password_form.email.valid ? <div className="error field-error">{forgot_password_form.email.error_msg}</div> : ""} 
                                        </div>

                                    </div>
                                    <div className="text-center">
                                        <button type="submit" className="btn btn-lg btn-primary w-100">
                                            <span className="indicator-label">Reset Password</span>
                                            <span className="indicator-progress">
                                                Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                        </button>
                                    </div>
                                    <div className="d-flex justify-content-center mt-2">
                                        <Link to="/"  className="">
                                            Back to Login
                                        </Link>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        loader: state.Auth.loader,
        error: state.Auth.error,
    };
}
const mapDispatchToProps = {
    onForgotPassword: userActions.ForgotPassword,
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);