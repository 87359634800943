import React from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";

import Validations from "../Utility/Validations";
import Sidebar from "../Constant/sidebar";
import Footer from "../Constant/Footer";
import TopBar from "../Constant/TopBar";
import { Link } from "react-router-dom";
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import * as actionType from "../../constants/actionTypes";

import * as userActions from "../../actions/index";

const permission_info = JSON.parse(localStorage.getItem("permissions"));

const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info.school_code;

class SchoolList extends React.Component {
    constructor(props) {
        super(props);
        window.addEventListener('keydown', this.handleKeyPress);
        this.state = {
            school_form: {
                school_name: {
                    type: "text",
                    label: "School Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "School Name",
                    validations: { required: false },
                },

                school_detail_uuid: {
                    type: "text",
                    label: "school_detail_uuid",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },

                user_uuid: {
                    type: "text",
                    label: "school_detail_uuid",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },

                actual_school_code: {
                    type: "text",
                    label: "School Code *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "School Code",
                    validations: { required: false },
                },

                school_code: {
                    type: "text",
                    label: "School Code *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "School Code",
                    validations: { required: false },
                },

                weekend_day_from: {
                    type: "select",
                    label: "Weekend Start Day *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Select weekend day",
                    validations: { required: false },
                    options: [
                        { label: "From Day", value: "" },
                        { label: "Sunday", value: "0" },
                        { label: "Monday", value: "1" },
                        { label: "Tuesday", value: "2" },
                        { label: "Wednesday", value: "3" },
                        { label: "Thursday", value: "4" },
                        { label: "Friday", value: "5" },
                        { label: "Saturday", value: "6" },
                    ],
                },

                weekend_day_to: {
                    type: "select",
                    label: "Weekend Finish Day *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Select weekend day",
                    validations: { required: false },
                    options: [
                        { label: "To Day", value: "" },
                        { label: "Sunday", value: "0" },
                        { label: "Monday", value: "1" },
                        { label: "Tuesday", value: "2" },
                        { label: "Wednesday", value: "3" },
                        { label: "Thursday", value: "4" },
                        { label: "Friday", value: "5" },
                        { label: "Saturday", value: "6" },
                    ],
                },

                cut_off_day: {
                    type: "select",
                    label: "Cut off Day *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Select Cut off Day",
                    validations: { required: false },
                    options: [
                        { label: "Cut off Day", value: "" },
                        { label: "Sunday", value: "0" },
                        { label: "Monday", value: "1" },
                        { label: "Tuesday", value: "2" },
                        { label: "Wednesday", value: "3" },
                        { label: "Thursday", value: "4" },
                        { label: "Friday", value: "5" },
                        { label: "Saturday", value: "6" },
                    ],
                },

                email: {
                    type: "email",
                    label: "Primary E-mail",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "dikonia@gmail.com",
                    validations: { required: false, email: true },
                },

                // password: {
                //     type: "password",
                //     label: "Password",
                //     value: "",
                //     valid: true,
                //     error_msg: "",
                //     onBlur_out: false,
                //     placeholder: "Abc@123",
                //     validations: { required: false },
                // },

                contact_one: {
                    type: "number",
                    label: "Primary Contact Number *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "9876543210",
                    validations: { required: false, number: true },
                },

                contact_two: {
                    type: "number",
                    label: "Alternate Contact Number",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "9876543210",
                    validations: { required: false, number: true },
                },

                country: {
                    type: "text",
                    label: "",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "9876543210",
                    validations: { required: false },
                    disabled: true
                },

                country_id: {
                    type: "select",
                    label: "Country / Region *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Select weekend day",
                    validations: { required: false },
                    options: [],
                    disabled: true
                },

                state_id: {
                    type: "select",
                    label: "State *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "State",
                    validations: { required: false },
                    options: [{ label: "State *", value: "" },],
                },

                state: {
                    type: "text",
                    label: "",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "9876543210",
                    validations: { required: false },
                },

                city_id: {
                    type: "select",
                    label: "City *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "City",
                    validations: { required: false },
                    options: [
                        { label: "City *", value: "" },
                    ],
                },

                city: {
                    type: "text",
                    label: "",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "9876543210",
                    validations: { required: false },
                },

                school_address: {
                    type: "text",
                    label: "Physical Address *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "",
                    validations: { required: false },
                },

                zipcode: {
                    type: "text",
                    label: "Physical Address *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "1234",
                    validations: { required: false },
                },

                country_host: {
                    type: "text",
                    label: "country_host",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "dikonia@gmail.com",
                    validations: { required: false },
                },

                highest_class: {
                    type: "select",
                    label: "Highest Year *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "City",
                    validations: { required: false },
                    options: [
                        { label: "Highest Year", value: "" },
                        { label: "1st", value: 1 },
                        { label: "2nd", value: 2 },
                        { label: "3rd", value: 3 },
                        { label: "4th", value: 4 },
                        { label: "5th", value: 5 },
                        { label: "6th", value: 6 },
                        { label: "7th", value: 7 },
                        { label: "8th", value: 8 },
                        { label: "9th", value: 9 },
                        { label: "10th", value: 10 },
                        { label: "11th", value: 11 },
                        { label: "12th", value: 12 },
                    ],
                },
                domain_name: {
                    type: "text",
                    label: "School Email Domain *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "dikonia@gmail.com",
                    validations: { required: true },
                },

                first_name: {
                    type: "text",
                    label: "First Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "First Name *",
                    validations: { required: false },
                },

                last_name: {
                    type: "text",
                    label: "Last Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Last Name *",
                    validations: { required: false },
                },

                weekend_time_from: {
                    type: "time",
                    label: "Select weekend time from",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "08:08",
                    validations: { required: false },



                },

                weekend_time_to: {
                    type: "time",
                    label: "Select weekend time to",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "08:08",
                    validations: { required: false },
                },

                cut_off_time: {
                    type: "time",
                    label: "Select cutoff",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "08:08",
                    validations: { required: false },
                },

                session_start_month: {
                    type: "select",
                    label: "Session Start Month *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "City",
                    validations: { required: false },
                    options: [
                        { label: "Select Session Start Month", value: "" },
                        { label: "January", value: "1" },
                        { label: "February", value: "2" },
                        { label: "March", value: "3" },
                        { label: "April", value: "4" },
                        { label: "May", value: "5" },
                        { label: "June", value: "6" },
                        { label: "July", value: "7" },
                        { label: "August", value: "8" },
                        { label: "September", value: "9" },
                        { label: "October", value: "10" },
                        { label: "November", value: "11" },
                        { label: "December", value: "12" },
                    ],
                },

                session_end_month: {
                    type: "select",
                    label: "Session End Month *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "City",
                    validations: { required: false },
                    options: [
                        { label: "Select Session End Month", value: "" },
                        { label: "January", value: "1" },
                        { label: "February", value: "2" },
                        { label: "March", value: "3" },
                        { label: "April", value: "4" },
                        { label: "May", value: "5" },
                        { label: "June", value: "6" },
                        { label: "July", value: "7" },
                        { label: "August", value: "8" },
                        { label: "September", value: "9" },
                        { label: "October", value: "10" },
                        { label: "November", value: "11" },
                        { label: "December", value: "12" },
                    ],
                },

                session_start_year: {
                    type: "select",
                    label: "Session Start Year *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "City",
                    validations: { required: false },
                    options: [],
                },

                session_end_year: {
                    type: "select",
                    label: "Session End Year *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "City",
                    validations: { required: false },
                    options: [],
                },

                timezone: {
                    type: "select",
                    label: "Session End Year *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Time Zone",
                    validations: { required: false },
                    options: [{ label: "Time Zone *", value: "" },],
                },

                school_logo: {
                    label: "File",
                    type: "file",
                    value: '',
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                    thumbnail_image: "/assets/media/school-logo-2.png",
                },

                country_code: {
                    type: "hidden",
                    label: "country_host",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "dikonia@gmail.com",
                    validations: { required: false },
                },

                other_contact_1_name: {
                    type: "text",
                    label: "Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Last Name *",
                    validations: { required: false },
                },
                other_contact_1_position: {
                    type: "text",
                    label: "Position",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Last Name *",
                    validations: { required: false },
                },
                other_contact_1_email: {
                    type: "email",
                    label: "Email",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Last Name *",
                    validations: { required: false },
                },
                other_contact_1_contact: {
                    type: "tel",
                    label: "Contact",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Last Name *",
                    validations: { required: false },
                },
                other_contact_2_name: {
                    type: "text",
                    label: "Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Last Name *",
                    validations: { required: false },
                },
                other_contact_2_position: {
                    type: "text",
                    label: "Position",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Last Name *",
                    validations: { required: false },
                },
                other_contact_2_email: {
                    type: "email",
                    label: "Email",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Last Name *",
                    validations: { required: false },
                },
                other_contact_2_contact: {
                    type: "tel",
                    label: "Contact",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Last Name *",
                    validations: { required: false },
                },

                avatar: {
                    label: "File",
                    type: "file",
                    value: '',
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                    thumbnail_image: "/assets/media/school-logo.png",
                },
            },
        };
    }

    componentDidMount() {
        this.GetAllSelectedCountries();
        this.GetSchoolById();
        let maxOffset = 10;
        let thisYear = (new Date()).getFullYear();
        let allYears = [];
        for (let x = 0; x < maxOffset; x++) {
            allYears.push({
                label: thisYear + x,
                value: thisYear + x,
            })
        }
        const update_school_form = { ...this.state.school_form };
        update_school_form['session_start_year'].options = allYears;
        update_school_form['session_end_year'].options = allYears;


        const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

        update_school_form['country_code'].value = user_info.user_school.country_code;
        this.GetLoginUserPermissions();
        this.setState({ school_form: update_school_form });
    }

    GetLoginUserPermissions() {
        this.props.onGetLoginUserPermissions().then((response) => {
            if (response.success === true) {
            } else {
            }
        });
    }

    GetSchoolById() {
        this.props.onGetSchoolById(this.props.user_uuid).then((response) => {
            console.log("Dsfsdgsdgdfgdf", response);
            if (response.success === true) {
                let school_data = response.data;
                const update_school_form = { ...this.state.school_form };
                for (let key in update_school_form) {
                    let form_element = update_school_form[key];
                    if (school_data[key]) {
                        if (key === 'country_id') {
                            this.GetAllStatesByCountryId(school_data[key])
                            this.GetAllTimezonesByCountryCode(school_data[key]);
                        }
                        if (key === 'state_id') {
                            this.GetAllCitiesByStateId(school_data[key]);
                        }
                        if (key === 'school_logo' && school_data[key] !== null && school_data[key] !== '') {
                            form_element.thumbnail_image = actionType.environment_url + '/' + school_data[key];
                        }

                        if (key === 'session_start_year') {
                            form_element.value = parseInt(school_data[key]);
                        }

                        else if (key === 'session_end_year') {
                            form_element.value = parseInt(school_data[key]);
                        }

                        else {
                            form_element.value = school_data[key];
                        }

                        update_school_form[key] = form_element;
                    }
                }
                if (school_data.admin_user[0]) {
                    if (school_data.admin_user[0].avatar !== null && school_data.admin_user[0].avatar !== '') {
                        update_school_form['avatar'].thumbnail_image = actionType.environment_url + '/' + school_data.admin_user[0].avatar;
                        update_school_form['avatar'].value = school_data.admin_user[0].avatar;
                    }
                    update_school_form['first_name'].value = school_data.admin_user[0].first_name;
                    update_school_form['last_name'].value = school_data.admin_user[0].last_name;
                    update_school_form['contact_one'].value = school_data.admin_user[0].contact_one;
                    update_school_form['contact_two'].value = school_data.admin_user[0].contact_two;
                    update_school_form['email'].value = school_data.admin_user[0].email;
                    update_school_form['user_uuid'].value = school_data.admin_user[0].user_uuid;

                }
                this.setState({ school_form: update_school_form });
            } else {
            }
        });

    }

    inputChangeHandlerimage(event, identifier) {
        // Assuming only image
        console.log("event", event.target.files[0]);
        var file = event.target.files[0];
        var url = URL.createObjectURL(file);
        const update_school_form = { ...this.state.school_form };
        const form_element = { ...update_school_form[identifier] };
        form_element.thumbnail_image = url;
        form_element.value = file;
        update_school_form[identifier] = form_element;
        this.setState({ school_form: update_school_form });
    }

    inputChangeHandler(event, identifier) {
        const update_school_form = { ...this.state.school_form };
        const form_element = { ...update_school_form[identifier] };
        // form_element.value = event.target.value;


        if (identifier === 'contact_one') {

            form_element.value = event;
        }
        else if (identifier === 'contact_two') {

            form_element.value = event;
        }
        else {
            form_element.value = event.target.value;
        }

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_school_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_school_form) {
            is_form_valid = update_school_form[identifier].valid && is_form_valid;
        }
        this.setState({
            school_form: update_school_form,
            is_form_valid: is_form_valid,
        });
    }

    handleChange(data, identifier, type) {
        const update_school_form = { ...this.state.school_form };
        if (identifier === 'country_id') {
            this.GetAllStatesByCountryId(data.value);
            update_school_form['country'].value = data.label;
            update_school_form['country_host'].value = data.country_host;
            this.GetAllTimezonesByCountryCode(data.country_code);

        }
        else if (identifier === 'state_id') {
            update_school_form['state'].value = data.label;
            this.GetAllCitiesByStateId(data.value);

        }
        else if (identifier === 'city_id') {
            update_school_form['city'].value = data.label;

        }
        update_school_form[identifier].value = data.value;
        this.setState({ school_form: update_school_form });
    }
    GetAllSelectedCountries = async (form_data) => {
        this.props.onGetAllSelectedCountries().then((response) => {
            let country_level = [];
            country_level.push({
                label: "Country",
                value: "",
            });
            const update_school_form = { ...this.state.school_form };
            if (response.success === true) {

                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    country_level.push({
                        label: arrayData[key].name,
                        value: arrayData[key].country_id,
                        country_code: arrayData[key].country_code,
                        country_host: arrayData[key].country_host,
                    });
                }
            }
            update_school_form['country_id'].options = country_level;
            this.setState({ school_form: update_school_form });
        });
    }
    GetAllStatesByCountryId = async (CountryID) => {
        this.props.onGetAllStatesByCountryId(CountryID).then((response) => {
            let state_level = [];
            state_level.push({
                label: "State",
                value: "",
            });
            const update_school_form = { ...this.state.school_form };
            if (response.success === true) {

                const arrayData = response.data.rows;

                for (let key in arrayData) {
                    if (arrayData[key].is_activate === true) {
                        state_level.push({
                            label: arrayData[key].state_name,
                            value: arrayData[key].state_id,
                        });
                    }
                }
            }
            update_school_form['state_id'].options = state_level;
            this.setState({ school_form: update_school_form });
        });
    }
    GetAllCitiesByStateId = async (state_id) => {
        this.props.onGetAllCitiesByStateId(state_id).then((response) => {
            let City_level = [];
            City_level.push({
                label: "City",
                value: "",
            });
            const update_school_form = { ...this.state.school_form };
            if (response.success === true) {

                const arrayData = response.data.rows;

                for (let key in arrayData) {
                    if (arrayData[key].is_activate === true) {
                        City_level.push({
                            label: arrayData[key].city_name,
                            value: arrayData[key].city_id,
                        });
                    }
                }
            }
            update_school_form['city_id'].options = City_level;
            this.setState({ school_form: update_school_form });
        });
    }
    GetAllTimezonesByCountryCode = async (country_code) => {
        userActions.GetAllTimezonesByCountryCode(country_code).then((response) => {
            let state_level = [];
            state_level.push({
                label: "Time Zone",
                value: "",
            });
            const update_school_form = { ...this.state.school_form };
            if (response.success === true) {
                const arrayData = response.data;
                for (let key in arrayData) {
                    state_level.push({
                        label: arrayData[key].timezone,
                        value: arrayData[key].timezone,
                    });
                }
            }
            update_school_form['timezone'].options = state_level;
            this.setState({ school_form: update_school_form });
        });
    }


    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyPress);
    }

    handleKeyPress = (event) => {
        if (event.keyCode === 27) {
            this.setState({ OpenImageModal: false })
        }
    };


    render() {
        const { school_form } = this.state;
        const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
        console.log("school_form===", school_form);

        return (
            <React.Fragment>
                {this.state.isAuthenticated ? <Navigate to={`${this.state.redirect_url}`} /> : null}


                <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
                    <div className="d-flex flex-column flex-root">
                        <div className="page d-flex flex-row flex-column-fluid">
                            <Sidebar></Sidebar>
                            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                                <TopBar></TopBar>
                                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                    <div className="post d-flex flex-column-fluid" id="kt_post">
                                        {/* <!--begin::Container--> */}
                                        <div id="kt_content_container" className="container-fluid">
                                            <ul className="nav nav-tabs home-tabs fontweight" id="myTab" role="tablist">
                                                <li className="nav-item me-6" role="presentation">
                                                    <button className="nav-link active" id="StudentProfile-tab" data-bs-toggle="tab" data-bs-target="#StudentProfile" type="button" role="tab" aria-controls="home" aria-selected="true">
                                                        School Profile
                                                    </button>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <button className="nav-link" id="BoardingDetail-tab" data-bs-toggle="tab" data-bs-target="#BoardingDetail" type="button" role="tab" aria-controls="process" aria-selected="false">
                                                        School Admin Details
                                                    </button>
                                                </li>
                                            </ul>

                                            <div className="tab-content" id="myTabContent">
                                                <div className="tab-pane fade show active" id="StudentProfile" role="tabpanel" aria-labelledby="StudentProfile-tab">
                                                    <form onSubmit={this.SubmitHandler}>
                                                        <div className="card mb-5 tabs-outer">
                                                            <div className="card-header position-relative py-0 ">
                                                                <h3 className="card-title m-0">School Profile</h3>
                                                                {permission_info?.edit_school_profile !== false &&
                                                                    (<Link to={`/${school_code}/school/edit-school/${user_info.user_school.school_detail_uuid}`} className="btn btn-primary btn-sm align-self-center edit-btn addschool-button">
                                                                        Edit Profile
                                                                    </Link>)
                                                                }
                                                            </div>

                                                            <div className="card-body">
                                                                <div className="row mb-8">
                                                                    <div className="col-md-12 col-lg-4 col-xl-4 fv-row mb-5">
                                                                        <div className="row">
                                                                            <div className="col-sm-12">
                                                                                <label className="fw-bold mb-2 ">School Logo</label>
                                                                                {/* <label className="fw-bold mb-2">Upload file</label> */}
                                                                                <div className="profile-image-out p-0">
                                                                                    <img alt="profile" src={school_form.school_logo.thumbnail_image} className="img-fluid" />
                                                                                    <div className="d-flex justify-content-center h-100 flex-column align-items-center">
                                                                                        <br />
                                                                                        {/* <p className="text-center">Please attach jpg or png file</p> */}

                                                                                        <input
                                                                                            hidden
                                                                                            type="file"
                                                                                            accept=".png, .jpg, .jpeg"
                                                                                            onChange={(event) => this.inputChangeHandlerimage(event, "school_logo")}
                                                                                            onBlur={(event) => {
                                                                                                school_form.school_logo.onBlur_out = true;
                                                                                                this.setState({
                                                                                                    school_form: school_form,
                                                                                                });
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-12 col-lg-8 col-xl-8">
                                                                        <div className="row">
                                                                            <div className="col-lg-4 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{school_form.school_name.label} </label>
                                                                                <input
                                                                                    type={school_form.school_name.type}
                                                                                    value={school_form.school_name.value ? school_form.school_name.value : ""}
                                                                                    className="form-control"
                                                                                    placeholder={school_form.school_name.placeholder}
                                                                                    readOnly
                                                                                />
                                                                            </div>

                                                                            <div className="col-lg-4 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{school_form.actual_school_code.label}</label>
                                                                                <input
                                                                                    type={school_form.actual_school_code.type}
                                                                                    className="form-control"
                                                                                    value={school_form.actual_school_code.value ? school_form.actual_school_code.value : ""}
                                                                                    placeholder={school_form.actual_school_code.placeholder}
                                                                                    readOnly
                                                                                />
                                                                            </div>

                                                                            <div className="col-lg-4 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">Zip Code *</label>
                                                                                <input
                                                                                    type={school_form.zipcode.type}
                                                                                    value={school_form.zipcode.value ? school_form.zipcode.value : ""}
                                                                                    className="form-control"
                                                                                    placeholder={school_form.zipcode.placeholder}
                                                                                    onChange={(event) => this.inputChangeHandler(event, "zipcode")}
                                                                                    readOnly
                                                                                />
                                                                                {!school_form.zipcode.valid && school_form.zipcode.onBlur_out ? <div className="error field-error">{school_form.zipcode.error_msg}</div> : ""}
                                                                            </div>

                                                                            <div className="col-lg-4 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{school_form.country_id.label}</label>
                                                                                <input
                                                                                    type="text"
                                                                                    name="fname"
                                                                                    className="form-control"
                                                                                    value={school_form.country.value ? school_form.country.value : ""}
                                                                                    readOnly
                                                                                />
                                                                            </div>

                                                                            <div className="col-lg-4  fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{school_form.state_id.label}</label>
                                                                                <input
                                                                                    type="text"
                                                                                    name="fname"
                                                                                    className="form-control"
                                                                                    value={school_form.state.value ? school_form.state.value : ""}
                                                                                    readOnly
                                                                                />
                                                                                {!school_form.state_id.valid && school_form.state_id.onBlur_out ? <div className="error field-error">{school_form.state.error_msg}</div> : ""}
                                                                            </div>

                                                                            <div className="col-lg-4  fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{school_form.city_id.label}</label>
                                                                                <input
                                                                                    type="text"
                                                                                    name="fname"
                                                                                    className="form-control"
                                                                                    value={school_form.city.value ? school_form.city.value : ""}
                                                                                    readOnly
                                                                                />
                                                                            </div>

                                                                            <div className="col-lg-4 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">Time Zone *
                                                                                </label>
                                                                                <input
                                                                                    type="text"
                                                                                    name="fname"
                                                                                    className="form-control"
                                                                                    value={school_form.timezone.value ? school_form.timezone.value : ""}
                                                                                    readOnly
                                                                                />
                                                                            </div>

                                                                            <div className="col-lg-4 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{school_form.highest_class.label}</label>
                                                                                <input
                                                                                    type="text"
                                                                                    name="fname"
                                                                                    className="form-control"
                                                                                    value={school_form.highest_class.value ? school_form.highest_class.value : ""}
                                                                                    readOnly
                                                                                />
                                                                            </div>

                                                                            <div className="col-lg-4 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{school_form.domain_name.label}</label>
                                                                                <input
                                                                                    type="text"
                                                                                    value={school_form.domain_name.value ? school_form.domain_name.value : ""}
                                                                                    className="form-control"
                                                                                />
                                                                            </div>

                                                                            <div className="col-lg-12 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">Physical Address *</label>
                                                                                <input
                                                                                    type={school_form.school_address.type}
                                                                                    value={school_form.school_address.value ? school_form.school_address.value : ""}
                                                                                    className="form-control"
                                                                                    placeholder={school_form.school_address.placeholder}
                                                                                    readOnly
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div className="row">
                                                                    <h6 className="mb-5">Other School Contact 1
                                                                        <hr />
                                                                    </h6>

                                                                    <div className="col-lg-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{school_form.other_contact_1_name.label}</label>
                                                                        <input
                                                                            type={school_form.other_contact_1_name.type}
                                                                            className="form-control ps-7"
                                                                            value={school_form.other_contact_1_name.value ? school_form.other_contact_1_name.value : ""}
                                                                            readOnly
                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{school_form.other_contact_1_position.label}</label>
                                                                        <input
                                                                            type={school_form.other_contact_1_position.type}
                                                                            className="form-control ps-7"
                                                                            value={school_form.other_contact_1_position.value ? school_form.other_contact_1_position.value : ""}
                                                                            readOnly
                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{school_form.other_contact_1_contact.label}</label>
                                                                        <ReactPhoneInput
                                                                            countryCodeEditable={false}
                                                                            country={school_form.country_code.value ? school_form.country_code.value : ""}
                                                                            value={school_form.other_contact_1_contact.value ? school_form.other_contact_1_contact.value : ""}
                                                                            disabled={true}
                                                                            disableDropdown={true}
                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{school_form.other_contact_1_email.label}</label>
                                                                        <input
                                                                            type={school_form.other_contact_1_email.type}
                                                                            className="form-control ps-7"
                                                                            value={school_form.other_contact_1_email.value ? school_form.other_contact_1_email.value : ""}
                                                                            readOnly
                                                                        />
                                                                    </div>
                                                                </div>



                                                                <div className="row">
                                                                    <h6 className="mb-5 mt-5">Other School Contact 2
                                                                        <hr />
                                                                    </h6>
                                                                    <div className="col-lg-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{school_form.other_contact_2_name.label}</label>
                                                                        <input
                                                                            type={school_form.other_contact_2_name.type}
                                                                            className="form-control ps-7"
                                                                            value={school_form.other_contact_2_name.value ? school_form.other_contact_2_name.value : ""}
                                                                            readOnly
                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{school_form.other_contact_2_position.label}</label>
                                                                        <input
                                                                            type={school_form.other_contact_2_position.type}
                                                                            className="form-control ps-7"
                                                                            value={school_form.other_contact_2_position.value ? school_form.other_contact_2_position.value : ""}
                                                                            readOnly
                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{school_form.other_contact_2_contact.label}</label>
                                                                        <ReactPhoneInput
                                                                            countryCodeEditable={false}
                                                                            country={school_form.country_code.value ? school_form.country_code.value : ""}
                                                                            value={school_form.other_contact_2_contact.value ? school_form.other_contact_2_contact.value : ""}
                                                                            disabled={true}
                                                                            disableDropdown={true}
                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{school_form.other_contact_2_email.label}</label>
                                                                        <input
                                                                            type={school_form.other_contact_2_email.type}
                                                                            className="form-control ps-7"
                                                                            value={school_form.other_contact_2_email.value ? school_form.other_contact_2_email.value : ""}
                                                                            readOnly
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="card tabs-outer">
                                                            <div className="card-header position-relative py-0">
                                                                <h3 className="card-title m-0">Weekend Details</h3>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2 d-flex">{school_form.weekend_day_from.label}

                                                                        </label>
                                                                        <input type="text" name="fname" className="form-control" readOnly
                                                                            value={school_form.weekend_day_from.value ? school_form.weekend_day_from.value : ""}

                                                                        />

                                                                    </div>
                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">Weekend Start Time *</label>
                                                                        <input type="time" name="fname" className="form-control time-input"
                                                                            placeholder="13:30"
                                                                            value={school_form.weekend_time_from.value ? school_form.weekend_time_from.value : ""}
                                                                            readOnly
                                                                        />
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{school_form.weekend_day_to.label}</label>
                                                                        <input type="text" name="fname" className="form-control" readOnly
                                                                            value={school_form.weekend_day_to.value ? school_form.weekend_day_to.value : ""}

                                                                        />

                                                                    </div>
                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">Weekend Finish Time  *</label>
                                                                        <input type="time" name="fname" className="form-control time-input"
                                                                            value={school_form.weekend_time_to.value ? school_form.weekend_time_to.value : ""}

                                                                            readOnly />
                                                                    </div>
                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2 d-flex">{school_form.cut_off_day.label}

                                                                        </label>
                                                                        <input type="text" name="fname" className="form-control" readOnly
                                                                            value={school_form.cut_off_day.value ? school_form.cut_off_day.value : ""}

                                                                        />

                                                                    </div>
                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2 d-flex">Cut off time *

                                                                        </label>

                                                                        <input type="time" name="fname" className="form-control time-input"
                                                                            value={school_form.cut_off_time.value ? school_form.cut_off_time.value : ""}
                                                                            placeholder="13:30"
                                                                            readOnly />
                                                                    </div>
                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{school_form.session_start_month.label}</label>
                                                                        <input type="text" name="fname" className="form-control" readOnly
                                                                            value={school_form.session_start_month.value ? school_form.session_start_month.value : ""}

                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{school_form.session_start_year.label}</label>
                                                                        <input type="text" name="fname" className="form-control" readOnly
                                                                            value={school_form.session_start_year.value ? school_form.session_start_year.value : ""}

                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5 mb-md-0">
                                                                        <label className="fw-bold mb-2">{school_form.session_end_month.label}</label>
                                                                        <input type="text" name="fname" className="form-control" readOnly
                                                                            value={school_form.session_end_month.value ? school_form.session_end_month.value : ""}

                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5 mb-md-0">
                                                                        <label className="fw-bold mb-2">{school_form.session_end_year.label}</label>
                                                                        <input type="text" name="fname" className="form-control" readOnly
                                                                            value={school_form.session_end_year.value ? school_form.session_end_year.value : ""}

                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>




                                                <div className="tab-pane fade" id="BoardingDetail" role="tabpanel" aria-labelledby="BoardingDetail-tab">
                                                    <form onSubmit={this.SubmitHandlerBoarding}>
                                                        <div className="card mb-5 tabs-outer">
                                                            <div className="card-header position-relative py-0  d-flex align-items-center">
                                                                <h3 className="card-title m-0">School Admin Details</h3>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="row align-items-center">
                                                                    <div className="col-lg-4 col-xl-4 fv-row">
                                                                        <label className="fw-bold mb-2">School Admin Image</label>
                                                                        <div className="row">
                                                                            <div className="col-sm-12 mb-5 mb-sm-0">
                                                                                <div className="profile-image-out p-0">
                                                                                    <div className="row">
                                                                                        <div className="col-sm-12">
                                                                                            <img alt="profile" src={school_form.avatar.thumbnail_image} className="img-fluid" />
                                                                                            <div className="justify-content-center h-100 flex-column align-items-center">
                                                                                                <br />
                                                                                                {/* <p className="text-center">Please attach jpg or png file</p> */}
                                                                                                <input
                                                                                                    hidden
                                                                                                    type="file"
                                                                                                    accept=".png, .jpg, .jpeg"
                                                                                                    onChange={(event) => this.inputChangeHandlerimage(event, "avatar")}
                                                                                                    onBlur={(event) => {
                                                                                                        school_form.avatar.onBlur_out = true;
                                                                                                        this.setState({
                                                                                                            school_form: school_form,
                                                                                                        });
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-8 col-xl-8">
                                                                        <div className="row">
                                                                            <div className="col-lg-6  col-xl-4 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{school_form.first_name.label} </label>
                                                                                <input
                                                                                    type={school_form.first_name.type}
                                                                                    value={school_form.first_name.value ? school_form.first_name.value : ""}
                                                                                    className="form-control"
                                                                                    placeholder={school_form.first_name.placeholder}
                                                                                    readOnly
                                                                                />

                                                                            </div>
                                                                            <div className="col-lg-6  col-xl-4 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{school_form.last_name.label} </label>
                                                                                <input
                                                                                    type={school_form.last_name.type}
                                                                                    value={school_form.last_name.value ? school_form.last_name.value : ""}
                                                                                    className="form-control"
                                                                                    placeholder={school_form.last_name.placeholder}
                                                                                    readOnly
                                                                                />
                                                                            </div>

                                                                            <div className="col-lg-6  col-xl-4 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{school_form.contact_one.label}</label>
                                                                                <ReactPhoneInput
                                                                                    countryCodeEditable={false}
                                                                                    country={school_form.country_code.value ? school_form.country_code.value : ""}
                                                                                    value={school_form.contact_one.value ? school_form.contact_one.value : ""}
                                                                                    onChange={(event) => this.inputChangeHandler(event, "contact_one")}
                                                                                    enableLongNumbers={true}
                                                                                    disabled
                                                                                //  onChange={this.handleOnChange}
                                                                                />

                                                                            </div>

                                                                            <div className="col-lg-6  col-xl-4 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{school_form.contact_two.label}</label>
                                                                                {console.log("school_form.contact_two.value================ ", school_form.contact_two.value)}
                                                                                <ReactPhoneInput
                                                                                    countryCodeEditable={false}
                                                                                    country={school_form.country_code.value ? school_form.country_code.value : ""}
                                                                                    value={school_form.contact_two.value ? school_form.contact_two.value : ""}
                                                                                    onChange={(event) => this.inputChangeHandler(event, "contact_two")}
                                                                                    enableLongNumbers={true}
                                                                                    disabled
                                                                                //  onChange={this.handleOnChange}
                                                                                />
                                                                            </div>
                                                                            <div className=" col-xl-8 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">Primary Email * (Will be used for Admin Login)</label>
                                                                                <input
                                                                                    type={school_form.email.type}
                                                                                    value={school_form.email.value ? school_form.email.value : ""}
                                                                                    className="form-control"
                                                                                    placeholder={school_form.email.placeholder}
                                                                                    readOnly
                                                                                />
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!--end::Container--> */}
                                    </div>
                                </div>
                                <Footer />
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onGetAllSelectedCountries: userActions.GetAllSelectedCountries,
    onGetAllStatesByCountryId: userActions.GetAllStatesByCountryId,
    onGetAllCitiesByStateId: userActions.GetAllCitiesByStateId,
    onUpdateSchool: userActions.UpdateSchool,
    onUpdateSchoolUser: userActions.UpdateSchoolUser,
    onGetSchoolById: userActions.GetSchoolById,
    onGetLoginUserPermissions: userActions.GetLoginUserPermissions,
};
export default connect(mapStateToProps, mapDispatchToProps)(SchoolList);