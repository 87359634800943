import React from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReportList from '../component/Settings/Reports/ReportList';

const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" :user_info?.school_code;

const Report = (props) => {
    const Renderview = () => {
        let location = useLocation();

        if (location.pathname === `/${school_code}/settings/reports` || location.pathname === `/${school_code}/settings/reports/`) {
            return <ReportList />;
        }
        
    };

    return (
        <React.Fragment>
            <ToastContainer />
            {Renderview()}
        </React.Fragment>
    );
};
export default Report;