import React from "react";
import { Navigate } from "react-router-dom";
import * as actions from "./actions";

const SuperAdminRouter = ({ children }) => {
    if (actions.isAuthorize()) {
        const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
        if (user_info && user_info?.role_type === 1) {
            return children
        } else {
            return <Navigate to="/dashboard" />;
        }
        ;
    } else {
        return <Navigate to="/" />;
    }
};
export default SuperAdminRouter;