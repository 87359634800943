import React from "react";
import { Modal } from "react-bootstrap";

const DeleteActivityModal = (props) => {
    const deleteHandler = (data) => {
        const payload = {activity_uuid : data.activity_uuid}
        props.ConfirmRemove(payload)
    }
    return (
        <React.Fragment>
            <Modal
                backdrop="static"
                keyboard={false}
                className="confirm_delete_modal confirm_modal"
                show={props.confirm_modal}
                onHide={() => {
                    props.delete_confirm_modal_action(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete Activity</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <h6>Are You Sure You Want To Delete The Activity?</h6>
                    </div>
                    <div className="col-sm-12 mt-4 d-flex justify-content-end">
                        <button type="submit" className="btn btn-sm btn-primary" onClick={() => {
                            deleteHandler(props.editActivitydata);
                        }}>
                            Delete
                        </button>
                        <button type="submit" className="btn btn-sm btn-secondary ms-4" onClick={() => {
                            props.delete_confirm_modal_action(false);
                        }}>
                            Cancel
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );

}
export default DeleteActivityModal;
