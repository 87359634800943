import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import { toast } from "react-toastify";

import Sidebar from "../../Constant/sidebar";
import Cdate from "../../Constant/date";
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";
import Menu from "../Menus";
import * as userActions from "../../../actions/index";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import RollCallReportItem from "./RollCallReportItem";
import moment from "moment";
import { DateRangePicker } from "mui-daterange-picker";
import Form from 'react-bootstrap/Form';
import parse from 'html-react-parser';
import { Link, useLocation } from "react-router-dom";

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#e7f5fe" : "",
        },
        backgroundColor: state.isSelected ? "#4a8fb8" : "",
    }),
};

const current = new Date();

// const date = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`;
const permission_info = JSON.parse(localStorage.getItem("permissions"));
console.log("permission_info=============", permission_info);
const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info.school_code;

const filters = JSON.parse(localStorage.getItem("Rollcall_filters"));
// const school_detail_id = user_info.school_detail_id ? user_info.school_detail_id : null;
var student_ids = '';

const RollCallReport = (props) => {
    const dispatch = useDispatch();

    const [RollcallList, setRollcallList] = useState([]);

    const [dormitory_id, setdormitory_id] = useState([]);
    const [dormitory_ids, setdormitory_ids] = useState("");
    const [dormitory_name, setdormitory_name] = useState("");
    const [class_id, setclass_id] = useState([]);
    const [class_ids, setclass_ids] = useState("");
    const [class_name, setclass_name] = useState("");

    const [class_value, setclass_value] = useState([]);
    const [class_values, setclass_values] = useState([]);

    const [gender, setgender] = useState('');
    const [attendance_date, setattendance_date] = useState(moment(current).format("yyyy-MM-DD"));
    const [user_id, setuser_id] = useState([]);
    const [user_ids, setuser_ids] = useState("");

    const [both_per, setBoth_per] = useState(true)
    const [male_per, setMale_per] = useState(true)
    const [female_per, setFemale_per] = useState(true)
    const [multiAPI, setMultiAPI] = useState(false)

    const [start_date, setStart_date] = useState(moment(current).subtract(3, 'days').format("yyyy-MM-DD"))
    const [end_date, setEnd_date] = useState(moment(current).format("yyyy-MM-DD"))

    const [open, setOpen] = useState(false)

    const navigate = useNavigate();
    useState(() => {
        let permissions;
        console.log("permissions=============", JSON.parse(localStorage.getItem("permissions")));
        if (JSON.parse(localStorage.getItem("permissions")) === null) {
        } else {
            permissions = JSON.parse(localStorage.getItem("permissions") ? localStorage.getItem("permissions") : "")
            if (permissions.snapshot_gender_both) {
                setMale_per(true)
                setFemale_per(true)
                setBoth_per(true)
            } else {
                setMale_per(permissions.snapshot_gender_male)
                setFemale_per(permissions.snapshot_gender_female)
                setBoth_per(permissions.snapshot_gender_both)
            }


            if (permissions.snapshot_gender_both) {
                setgender("")
            } else if (permissions.snapshot_gender_female) {
                setgender("female")
            } else {
                setgender("male")
            }
        }
    }, [])


    const ExportStudentsAttendancePdf = (event) => {
        props.onExportStudentsAttendancePdf(JSON.parse(class_ids), start_date, end_date, JSON.parse(dormitory_ids), user_ids, gender).then((response) => {
            if (response.success === true) {
                const csv_url = response.file;
                DownloadPdf(csv_url);
                // GetAllCourses();
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
            else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    };
    const DownloadPdf = (csv_url) => {
        const link = document.createElement("a");
        link.href = csv_url;
        link.target = "_blank"
        // link.setAttribute("download");
        document.body.appendChild(link);
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }


    const GetStudentsRollCallReport = useCallback(async () => {
        dispatch(userActions.GetStudentsRollCallReport(class_ids, start_date, end_date, dormitory_ids, user_ids, gender)).then((response) => {
            if (response.success === true) {
                const arrayData = response.data;
                setRollcallList(arrayData);
                console.log("arraydata", arrayData);
            }

            else if (response.status === 401) {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                setTimeout(() => {
                    navigate('/logout')

                }, 5000);
            }
            else {
            }
        });
        // eslint-disable-next-line
    }, [dispatch, class_ids, start_date, end_date, dormitory_ids, user_ids, gender]);


    const GetAllClassesDetailsStudent = useCallback(() => {
        dispatch(userActions.GetAllClassesDetailsStudent()).then((response) => {
            if (response.success === true) {
                let state_data = [];
                state_data.push({
                    label: "All",
                    value: "",
                });
                const arrayData = response.data.rows;
                if (permission_info) {
                    const permClassDormArray = permission_info?.perm_class_dorm;
                    const filteredPermClass = permClassDormArray.filter(item => item?.class_id !== null)
                    const allowedClassIds = filteredPermClass.map(item => item?.class_id);

                    const filteredData = arrayData.filter(item => allowedClassIds.includes(item.class_id));

                    for (let key in filteredData) {
                        state_data.push({
                            label: filteredData[key].class_name,
                            value: filteredData[key].class_id,
                            // class_id: arrayData[key].class_id,
                        });
                    }

                    let defaultYear = filteredData.map((item) => {
                        return item.class_id
                    })
                    setclass_ids(JSON.stringify(defaultYear))

                } else {
                    for (let key in arrayData) {
                        state_data.push({
                            label: arrayData[key].class_name,
                            value: arrayData[key].class_id,
                            // class_id: arrayData[key].class_id,
                        });
                    }

                    let defaultYear = arrayData.map((item) => {
                        return item.class_id
                    })
                    setclass_ids(JSON.stringify(defaultYear))
                }
                setclass_id(state_data);
            } else {

            }

            for (let index in class_id) {
                if (class_id[index].value === class_value) {
                    setclass_values(class_id[index])
                }
            }
        });
    }, [dispatch]);

    const GetAllDormitoryStudent = useCallback(() => {
        dispatch(userActions.GetAllDormitoryStudent()).then((response) => {
            if (response.success === true) {
                let state_data = [];
                state_data.push({
                    label: "All",
                    value: "",
                });
                const arrayData = response.data.rows;
                if (permission_info) {
                    const permClassDormArray = permission_info?.perm_class_dorm;
                    const filteredPermDorm = permClassDormArray.filter(item => item?.dormitory_id !== null)
                    const allowedDormIds = filteredPermDorm.map(item => item?.dormitory_id);
                    const filteredData = arrayData.filter(item => allowedDormIds.includes(item.dormitory_id));
                    for (let key in filteredData) {
                        state_data.push({
                            label: filteredData[key].dormitory_name,
                            value: filteredData[key].dormitory_id,
                            dormitory_id: filteredData[key].dormitory_id,
                        });
                    }
                    let defaultdormitory = filteredData.map((item) => {
                        return item.dormitory_id
                    })
                    setdormitory_ids(JSON.stringify(defaultdormitory))
                } else {
                    for (let key in arrayData) {
                        state_data.push({
                            label: arrayData[key].dormitory_name,
                            value: arrayData[key].dormitory_id,
                            dormitory_id: arrayData[key].dormitory_id,
                        });
                    }

                    let defaultdormitory = arrayData.map((item) => {
                        return item.dormitory_id
                    })
                    setdormitory_ids(JSON.stringify(defaultdormitory))
                }
                setdormitory_id(state_data);
            } else {
                //notify.show(response.message, "error");
            }
        });
    }, [dispatch]);


    const GetAllManagersListBySchoolId = useCallback(() => {
        dispatch(userActions.GetAllManagersListBySchoolId()).then((response) => {
            if (response.success === true) {
                let state_data = [];
                state_data.push({
                    label: "Select Manager",
                    value: "",
                });
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    state_data.push({
                        label: arrayData[key].first_name + " " + arrayData[key].last_name,
                        value: arrayData[key].user_id,

                    });
                }
                setuser_id(state_data);
            } else {
                //notify.show(response.message, "error");
            }
        });
    }, [dispatch]);

    useEffect(() => {
        GetAllClassesDetailsStudent();
        GetAllDormitoryStudent();
        setMultiAPI(true)
    }, []);


    useEffect(() => {
        if (multiAPI && dormitory_id.length > 0 && class_id.length > 0) {
            GetStudentsRollCallReport();
            GetAllManagersListBySchoolId();
        }
        // eslint-disable-next-line
    }, [class_ids, start_date, end_date, dormitory_ids, user_ids, gender]);


    const GetLoginUserPermissions = useCallback(async () => {
        dispatch(userActions.GetLoginUserPermissions()).then((response) => {
            if (response.success === true) {
            }

            else if (response.status === 401) {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }

            else {
            }
        });
        // eslint-disable-next-line
    }, [dispatch]);


    useEffect(() => {
        GetLoginUserPermissions();
    }, []);

    // const FilterSave = async (event) => {

    //     const Payload = {
    //         dormitory_id: dormitory_ids,
    //         class_id: class_ids,
    //         gender: gender,
    //     };
    //     console.log('Payload===', Payload)

    //     // localStorage.setItem('Rollcall_filters', JSON.stringify(Payload));

    // }

    const Dormitory_and_class = (event, identifire) => {
        if (identifire === "year") {
            if (event.value === "") {
                let setYear = [];
                class_id.forEach((item) => {
                    if (item.value !== "") {
                        setYear.push(item.value)
                    }
                })
                setclass_name(event.value)
                setclass_ids(JSON.stringify(setYear))

            } else {
                setclass_name(event.value)
                setclass_ids(JSON.stringify([event.value]))
            }
        }
        if (identifire === "dormitory") {
            if (event.value === "") {
                let setDorm = [];
                dormitory_id.forEach((item) => {
                    if (item.value !== "") {
                        setDorm.push(item.value)
                    }
                })
                setdormitory_name(event.value)
                setdormitory_ids(JSON.stringify(setDorm))
            } else {
                setdormitory_name(event.value)
                setdormitory_ids(JSON.stringify([event.value]));
            }
        }
    }


    const showDatePicker = () => setOpen(!open)
    const toggle = () => setOpen(!open)
    const setDateRange = (event) => {
        setStart_date(moment(event.startDate).format("yyyy-MM-DD"))
        setEnd_date(moment(event.endDate).format("yyyy-MM-DD"))
        toggle()
    }





    return (
        <>
            <div className="d-flex flex-column flex-root header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed h-100">
                <div className="page d-flex flex-row flex-column-fluid">
                    <Sidebar />
                    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                        <TopBar />
                        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                            <div className="post d-flex flex-column-fluid" id="kt_post">
                                <div id="kt_content_container" className="container-fluid">

                                    <div className="mt-0">
                                        <ul className="nav nav-tabs home-tabs">
                                            <li className="nav-item me-6" >
                                                <Link to={`/${school_code}/snapshot`} className="nav-link" type="button" aria-controls="process" aria-selected="false">
                                                    Snapshot
                                                </Link>
                                            </li>
                                            <li className="nav-item me-6" >
                                                <Link to={`/${school_code}/rollcall-report`} className="nav-link  active" type="button" aria-controls="process" aria-selected="true"    >
                                                    Roll  Call Reports
                                                </Link>
                                            </li>
                                            <li className="nav-item me-6" >
                                                <Link to={`/${school_code}/conduct-rollcall`} className="nav-link" type="button" aria-controls="process" aria-selected="false">
                                                    Conduct Roll Call
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="tab-content" id="myTabContent">



                                        <div className="card">
                                            <div className="card-header position-relative py-0 details-bg">
                                                <h2 className="card-title text-gray-800  m-0" >
                                                    Roll Call Report <span className="d-none d-lg-block bullet h-20px w-1px bg-black mx-4"></span>
                                                    <span className="date-block">{" "}

                                                        {" "}
                                                        <Cdate />{" "}
                                                    </span>
                                                </h2>

                                                {/* <span className="btn btn-link btn-color-info btn-active-color-primary me-5 mb-2 d-flex align-items-center" onClick={(event) => ExportStudentsAttendancePdf(event)}>
                                                    <span className="svg-icon svg-icon-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <rect opacity="0.3" x="12.75" y="4.25" width="12" height="2" rx="1" transform="rotate(90 12.75 4.25)" fill="currentColor"></rect>
                                                            <path d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z" fill="currentColor"></path>
                                                            <path d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z" fill="#C4C4C4"></path>
                                                        </svg>
                                                    </span>
                                                    Export as PDF
                                                </span> */}
                                            </div>



                                            <form >
                                                <div className="card-body p-6">
                                                    <div className="row">

                                                        <div className="col">
                                                            <label className="fw-bold mb-2 ">Select Date</label>
                                                            <div className='position-relative w-230px'  onClick={() => showDatePicker()}>
                                                                <span className='position-absolute apply_adhoc_leave_date_and_time'>
                                                                    <i class="far fa-calendar-alt mt-2px"></i>
                                                                </span>
                                                                <input
                                                                    type="text"
                                                                    placeholder="Large text"
                                                                    value={start_date + "   to   " + end_date}
                                                                    readOnly
                                                                    className="form-control bg-transparent"
                                                                    
                                                                />

                                                            </div>

                                                            <div className="date-picker position-absolute" style={{ minWidth: "700px" }}>
                                                                <DateRangePicker
                                                                    open={open}
                                                                    toggle={toggle}
                                                                    onChange={(range) => setDateRange(range)}
                                                                    closeOnClickOutside={true}

                                                                />
                                                            </div>
                                                        </div>

                                                                <div className="col">
                                                                    <label className="fw-bold mb-2 ">Select Year</label>
                                                                    <Select
                                                                        styles={customStyles}
                                                                        className="react-bootstrap-typeahead tag-outer "
                                                                        options={class_id}
                                                                        value={class_id.filter(function (option) {
                                                                            return option.value === class_name;
                                                                        })}
                                                                        onChange={(event) => { Dormitory_and_class(event, "year") }}
                                                                    />
                                                                </div>

                                                                <div className="col">
                                                                    <label className="fw-bold mb-2 ">Select Dormitory</label>
                                                                    <Select
                                                                        styles={customStyles}
                                                                        className="react-bootstrap-typeahead tag-outer "
                                                                        options={dormitory_id}
                                                                        value={dormitory_id.filter(function (option) {
                                                                            return option.value === dormitory_name;
                                                                        })}
                                                                        onChange={(event) => { Dormitory_and_class(event, "dormitory") }}
                                                                    />
                                                                </div>

                                                                <div className="col">
                                                                    <label className="fw-bold mb-2 ">Manager Name</label>
                                                                    <Select
                                                                        styles={customStyles}
                                                                        className="react-bootstrap-typeahead tag-outer "
                                                                        options={user_id}
                                                                        value={user_id.filter(function (option) {
                                                                            return option.value === user_ids;

                                                                        })}
                                                                        onChange={(event) => {
                                                                            setuser_ids(event.value);
                                                                        }}
                                                                    />
                                                                </div>

                                                                <div className="col">
                                                                    <label className="fw-bold mb-5">Gender</label>
                                                                    <div className="d-flex radio-btn">
                                                                        <label className="cursor-pointer d-flex align-items-center">
                                                                            <input
                                                                                type="radio"
                                                                                id="gender"
                                                                                name="gen"
                                                                                className="form-check-input me-2 cursor-pointer"
                                                                                value="male"
                                                                                onChange={(event) => { setgender(event.target.value); }}
                                                                                defaultChecked={female_per === false && male_per === true && both_per === false ? true : false}
                                                                                disabled={!male_per}
                                                                            />
                                                                            Male
                                                                        </label>

                                                                        <label className="cursor-pointer d-flex align-items-center">
                                                                            <input
                                                                                type="radio"
                                                                                id="gender1"
                                                                                name="gen"
                                                                                className="form-check-input me-2 cursor-pointer"
                                                                                value="female"
                                                                                onChange={(event) => { setgender(event.target.value); }}
                                                                                defaultChecked={female_per === true && male_per === false && both_per === false ? true : false}
                                                                                disabled={!female_per}
                                                                            />
                                                                            Female
                                                                        </label>

                                                                        <label className="cursor-pointer d-flex align-items-center">
                                                                            <input
                                                                                type="radio"
                                                                                id="gender2"
                                                                                name="gen"
                                                                                className="form-check-input me-2 cursor-pointer"
                                                                                value=""
                                                                                onChange={(event) => { setgender(event.target.value); }}
                                                                                defaultChecked={male_per === true && female_per === true && both_per === true ? true : false}
                                                                                disabled={!both_per}
                                                                            />
                                                                            Both
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="card mt-5">
                                            <div className="card-header">
                                                <h2 className="card-title m-0">Recent Roll Call Reports</h2>
                                                <span className="btn btn-link btn-color-info btn-active-color-primary me-5 mb-2 d-flex align-items-center" onClick={(event) => ExportStudentsAttendancePdf(event)}>
                                                    <span className="svg-icon svg-icon-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <rect opacity="0.3" x="12.75" y="4.25" width="12" height="2" rx="1" transform="rotate(90 12.75 4.25)" fill="currentColor"></rect>
                                                            <path d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z" fill="currentColor"></path>
                                                            <path d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z" fill="#C4C4C4"></path>
                                                        </svg>
                                                    </span>
                                                    Export as PDF
                                                </span>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <RollCallReportItem
                                                            data={RollcallList}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!--end::Container--> */}
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {

    onExportStudentsAttendancePdf: userActions.ExportStudentsAttendancePdf,
};
export default connect(mapStateToProps, mapDispatchToProps)(RollCallReport);
