import React from "react";
import { Link } from "react-router-dom";



const Footer = () => {
  
    return (
        <div>
            <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
                <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between ">
                    <div className="text-dark order-2 order-md-1">




                        <span className="text-muted fw-bold me-1">{new Date().getFullYear()}©</span>
                        <Link to="#" target="_blank" className="text-gray-800 text-hover-primary">
                            BSS
                        </Link>
                    </div>
                    <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1"></ul>
                </div>
            </div>
        </div>
    );
};

export default Footer;
