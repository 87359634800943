import React from "react";
import SideBarLeave from "../../Constant/SideBarLeave";
import Footer from "../../Constant/Footer";
import TopBar from "../../Constant/TopBar";
import * as userActions from "../../../actions/index";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { ButtonGroupButtonContext, Tooltip } from "@mui/material";
import Pagination from '@mui/material/Pagination';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import Stack from '@mui/material/Stack';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import IconButton from '@mui/material/IconButton';
import moment from "moment";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Button from '@mui/material/Button';
import ReplyIcon from '@mui/icons-material/Reply';
import * as actionType from "../../../constants/actionTypes";


const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#e7f5fe" : "",
        },
        backgroundColor: state.isSelected ? "#4a8fb8" : "",
    }),
};

const CateringReport = (props) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    const timezone = user_info?.user_school?.timezone;
    const current = new Date();

    const [date, setDate] = useState(moment(current))
    const [dormitory_ids, setdormitory_ids] = useState([]);
    const [dormitory_id, setdormitory_id] = useState([]);
    const [dormitory_name, setdormitory_name] = useState([]);
    const [class_ids, setclass_ids] = useState([]);
    const [class_id, setclass_id] = useState([]);


    const [breakfast, setBreakfast] = useState({})
    const [lunch, setLunch] = useState({})
    const [dinner, setDinner] = useState({})

    const [mealType, setMealType] = useState("")
    const [studentCountForMeal, setStudentCountForMeal] = useState()
    const [showMealData, setShowMealData] = useState(true)

    const [is_pdf, setIS_pdf] = useState(false)
    const [is_pdf_report, setIS_pdf_report] = useState(false)
    const [pdfReportFile, setPdfReportFile] = useState("")

    const [cateringReportDetailsByMealType, setCateringReportDetailsByMealType] = useState([])

    useEffect(() => {
        GetAllClassesList();
        GetAllDormitoryStudent();
    }, []);

    const GetAllClassesList = async () => {
        props.onGetAllClassesList().then((response) => {
            if (response.success === true) {
                const arrayData = response.data.rows;
                let state_data = [];
                state_data.push({
                    label: "Select Year",
                    value: "",
                })
                for (let key in arrayData) {
                    state_data.push({
                        label: arrayData[key].class_name,
                        value: arrayData[key].class_id,
                    })
                }
                setclass_ids(state_data);
            }
        });
    };

    const GetAllDormitoryStudent = async () => {
        props.onGetAllDormitoryStudent().then((response) => {
            if (response.success === true) {
                let state_data = [];
                // state_data.push({
                //     label: "All Residences",
                //     value: "",
                // });
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    state_data.push({
                        label: arrayData[key].dormitory_name,
                        value: JSON.parse(arrayData[key].dormitory_id),
                        dormitory_id: JSON.parse(arrayData[key].dormitory_id),
                    });
                }
                let defaultYear = arrayData.map((item) => {
                    return item.dormitory_id
                })

                setdormitory_ids(state_data);
            } else {
                //notify.show(response.message, "error");
            }
        });
    };

    useEffect(() => {
        GetCateringReport()
    }, [dormitory_id, date, is_pdf])

    const GetCateringReport = () => {
        console.log(dormitory_id,"::sdfsdf")
        props.onGetCateringReport(dormitory_id, dormitory_name, date.format("YYYY-MM-DD"), is_pdf).then((response) => {
            console.log("Dasfsdgdfh", response);
            if (response.status === 200) {
                setBreakfast(response.data[0].breakfast)
                setDinner(response.data[0].dinner)
                setLunch(response.data[0].lunch)
                if (is_pdf) {
                    DownloadPdf(response.file)
                }
            } else {
                //notify.show(response.message, "error");
            }
        })
    }

    const DownloadPdf = (pdf_url) => {
        console.log("111111111111111>>>>>>>>>>>>>")
        const link = document.createElement("a");
        link.href = pdf_url;
        link.target = "_blank"
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setIS_pdf(false)
    }

    const DownloadPdfReport = (pdf_url) => {
        console.log("2222222222222222222>>>>>>>>>>>>>")
        const link = document.createElement("a");
        link.href = pdf_url;
        link.target = "_blank"
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setIS_pdf_report(false)
    }


    const getCateringReportDetailsByMealType = (meal_type, meal_time, date, count) => {
        setShowMealData(false)
        setMealType(meal_type.charAt(0).toUpperCase() + meal_type.slice(1))
        setStudentCountForMeal(count)
        console.log(is_pdf_report,"::is_pdf_report")
        props.onGetCateringReportDetailsByMealType(meal_type, meal_time, dormitory_id, dormitory_name, date.format("YYYY-MM-DD"), showMealData).then((response) => {
            if (response.success === true) {
                if (showMealData) {
                    setPdfReportFile(response.file)
                }
                setCateringReportDetailsByMealType(response.data)
            } else {
                //notify.show(response.message, "error");
            }
        })
    }


    const handleDormitoryChange = (selectedOptions) => {
        setShowMealData(true);

        // Check if "All Residences" is selected
        // const isAllResidencesSelected = selectedOptions.some(option => option.label === "All Residences");

        // if (isAllResidencesSelected) {
        //     // Include all dormitory IDs and labels if "All Residences" is selected
        //     const allDormitoryIds = dormitory_ids
        //         .filter(option => option.dormitory_id !== undefined)
        //         .map(option => Number(option.dormitory_id));

        //     const allDormitoryNames = dormitory_ids
        //         .filter(option => option.label !== "All Residences")
        //         .map(option => option.label);

        //     setdormitory_id(allDormitoryIds);
        //     setdormitory_name(allDormitoryNames);
        // } else {
            // Otherwise, include only the selected options' dormitory IDs and labels
            const dormitoryIds = selectedOptions
                .map(option => Number(option.dormitory_id))
                .filter(id => id !== undefined);

            const dormitoryNames = selectedOptions
                .map(option => option.label)
                .filter(label => label !== undefined);

            setdormitory_id(dormitoryIds);
            setdormitory_name(dormitoryNames);
        // }
    };

console.log(showMealData ? "1" : "2",showMealData,"<<<<<<<::SDfsdf")
    return (
        <>
            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed" >
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <SideBarLeave />
                        <div className="wrapper d-flex flex-column flex-row-fluid " id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    {/* <!--begin::Container--> */}
                                    <div id="kt_content_container" className="container-fluid">
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='card'>
                                                    <div className='card-header align-items-center'>
                                                        <h3 className='card-title'>Catering Report</h3>
                                                        <Tooltip title="Download .pdf" placement="top">
                                                            <IconButton onClick={() => {showMealData ? setIS_pdf(true) : DownloadPdfReport(pdfReportFile)}}>
                                                                <i class="fas fa-file-pdf"></i>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                    <div className='card-body'>
                                                        <div className='row'>
                                                            <div className="col-lg-4">
                                                                <label className="mb-3">Select Residence</label>
                                                                <Select
                                                                    className="react-bootstrap-typeahead tag-outer text-secondary"
                                                                    options={dormitory_ids}
                                                                    // value={dormitory_ids.filter((dormitory) => dormitory.value === dormitory_id)}
                                                                    // value={default_for_class()}
                                                                    // onChange={(e) => { setShowMealData(true); setdormitory_id(e.value) ;setdormitory_name(e.label) }}
                                                                    onChange={handleDormitoryChange}
                                                                    isMulti
                                                                />

                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className="col-lg-12">
                                                                <div className="d-flex align-items-center justify-content-center border border-dashed border-gray-300 rounded px-5 py-0 mt-6">
                                                                    <span className="me-5">
                                                                        <IconButton color="primary" aria-label="add to shopping cart" onClick={() => { setShowMealData(true); setDate(moment(date)?.subtract(1, 'days')) }}>
                                                                            <ArrowBackIosIcon fontSize="small" />
                                                                        </IconButton>
                                                                    </span>
                                                                    <span className="text-primary text-center" style={{ width: "200px" }}>
                                                                        {date?.tz(timezone)?.format('dddd, MMMM Do YYYY')}
                                                                    </span>
                                                                    <span className="ms-5">
                                                                        <IconButton color="primary" aria-label="add to shopping cart" onClick={() => { setShowMealData(true); setDate(moment(date)?.add(1, 'days')) }}>
                                                                            <ArrowForwardIosIcon fontSize="small" />
                                                                        </IconButton>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='row mt-5'>
                                                            {showMealData ?
                                                                <div className="col-lg-12">
                                                                    <MDBTable>
                                                                        <MDBTableHead className="border-secondary border-bottom">
                                                                            <tr>
                                                                                <th colSpan={1}></th>
                                                                                <th className="text-center">Early</th>
                                                                                <th className="text-center">On Time</th>
                                                                                <th className="text-center">Late</th>
                                                                                <th className="text-center">Total</th>
                                                                            </tr>
                                                                        </MDBTableHead>
                                                                        <MDBTableBody>
                                                                            <tr className="border-bottom">
                                                                                <th>Breakfast</th>
                                                                                <td className="text-center">
                                                                                    <Button onClick={() => getCateringReportDetailsByMealType("breakfast", "early", date, breakfast.early)}>
                                                                                        <span className="h6">{breakfast.early}</span>
                                                                                    </Button>
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <Button onClick={() => getCateringReportDetailsByMealType("breakfast", "normal", date, breakfast.onTime)}>
                                                                                        <span className="h6">{breakfast.onTime}</span>
                                                                                    </Button>
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <Button onClick={() => getCateringReportDetailsByMealType("breakfast", "late", date, breakfast.late)}>
                                                                                        <span className="h6">{breakfast.late}</span>
                                                                                    </Button>
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <span className="h6">{breakfast.total}</span>
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="border-bottom">
                                                                                <th>Lunch</th>
                                                                                <td className="text-center">
                                                                                    <Button onClick={() => getCateringReportDetailsByMealType("lunch", "early", date, lunch.early)}>
                                                                                        <span className="h6">{lunch.early}</span>
                                                                                    </Button>
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <Button onClick={() => getCateringReportDetailsByMealType("lunch", "normal", date, lunch.onTime)}>
                                                                                        <span className="h6">{lunch.onTime}</span>
                                                                                    </Button>
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <Button onClick={() => getCateringReportDetailsByMealType("lunch", "late", date, lunch.late)}>
                                                                                        <span className="h6">{lunch.late}</span>
                                                                                    </Button>
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <span className="h6">{lunch.total}</span>
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="border-bottom">
                                                                                <th>Dinner</th>
                                                                                <td className="text-center">
                                                                                    <Button className="" onClick={() => getCateringReportDetailsByMealType("dinner", "early", date, dinner.early)}>
                                                                                        <span className="h6">{dinner.early}</span>
                                                                                    </Button>
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <Button onClick={() => getCateringReportDetailsByMealType("dinner", "normal", date, dinner.onTime)}>
                                                                                        <span className="h6 text-center">{dinner.onTime}</span>
                                                                                    </Button>
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <Button onClick={() => getCateringReportDetailsByMealType("dinner", "late", date, dinner.late)}>
                                                                                        <span className="h6">{dinner.late}</span>
                                                                                    </Button>
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <span className="h6">{dinner.total}</span>
                                                                                </td>
                                                                            </tr>
                                                                        </MDBTableBody>
                                                                    </MDBTable>
                                                                </div>

                                                                :
                                                                <div className="col-12">
                                                                    <MDBTable>
                                                                        <MDBTableHead className="border-secondary border-bottom">
                                                                            <tr>
                                                                                <th>{mealType}{"  "}{"  "}({studentCountForMeal})</th>
                                                                                <th className="text-right">
                                                                                    <Button onClick={() => { setCateringReportDetailsByMealType([]); setShowMealData(true) }} startIcon={<ReplyIcon />}>
                                                                                        Back to All Meal
                                                                                    </Button>
                                                                                </th>
                                                                            </tr>
                                                                        </MDBTableHead>
                                                                        <MDBTableBody>
                                                                            {cateringReportDetailsByMealType.map((report) => {
                                                                                return (
                                                                                    <tr className="border-bottom">
                                                                                        {console.log("report============", report.StudentDetails.student_avatar)}
                                                                                        <td>
                                                                                            <span className="me-3">
                                                                                                <img
                                                                                                    alt="/assets/media/school-logo.png"
                                                                                                    src={report.StudentDetails.student_avatar === "" || report.StudentDetails.student_avatar === null ? report.StudentDetails.gender === "male" ? `/media/avatars/boy.jpg` : `/media/avatars/girl.jpg` : `${actionType.environment_url}/${report.StudentDetails.student_avatar}`}
                                                                                                    className="rounded-circle" style={{ width: "35px", height: "35px" }}
                                                                                                />
                                                                                            </span>
                                                                                            {report.StudentDetails.student_first_name}{report.StudentDetails.student_last_name}
                                                                                        </td>
                                                                                        <td>
                                                                                            {report.StudentDetails.allergy_details.map((allergy) => {
                                                                                                return (
                                                                                                    <span>
                                                                                                        {allergy.allergy_name}
                                                                                                    </span>
                                                                                                )
                                                                                            })}
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })}
                                                                        </MDBTableBody>
                                                                    </MDBTable>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}

const mapDispatchToProps = {
    onGetAllDormitoryStudent: userActions.GetAllDormitoryStudent,
    onGetAllClassesList: userActions.GetAllClassesList,
    onStudentDepartureReport: userActions.StudentDepartureReport,
    onGetCateringReport: userActions.GetCateringReport,
    onGetCateringReportDetailsByMealType: userActions.GetCateringReportDetailsByMealType
};
export default connect(mapStateToProps, mapDispatchToProps)(CateringReport);