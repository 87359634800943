import React from "react";
import Validations from "../../Utility/Validations";
import Sidebar from "../../Constant/sidebar";
import Footer from "../../Constant/Footer";
import TopBar from "../../Constant/TopBar";
import { connect } from "react-redux";
import * as userActions from "../../../actions/index";
import { toast } from "react-toastify";
import { Navigate } from "react-router-dom";
import { DeleteAllergyModal } from "../../Utility/DeleteAllergyModal";

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#e7f5fe" : "",
        },
        backgroundColor: state.isSelected ? "#4a8fb8" : "",
    }),
};
const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" :user_info?.school_code;
const permission_info = JSON.parse(localStorage.getItem("permissions"));
class EditAllergicStudent extends React.Component {
    constructor(props) {
        console.log("safsdgdg", props);
        super(props);
        this.state = {
            showModal:false,
            confirmRemoveData:{},
            edit_allergic_student_form: {
                student_name: {
                    type: "select",
                    label: "Student Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                    options: [],
                },
                student_id: {
                    type: "text",
                    label: "student id",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },
                update_allergy_data: [],
                allergy_data: []
            },
        };
    }

    componentDidMount() {
        this.GetAllergicStudentDetailById(this.props.user_uuid);
        this.GetLoginUserPermissions();
    }

    GetLoginUserPermissions() {
        this.props.onGetLoginUserPermissions().then((response) => {
            if (response.success === true) {
            } else {
            }
        });
    }

    GetAllergicStudentDetailById = async (student_uuid) => {
        const update_edit_allergic_student_form = { ...this.state.edit_allergic_student_form };
        this.props.onGetAllergicStudentDetailById(student_uuid).then((response) => {
            let student_data = response.data;
            if (response.success === true) {
                const updateed_allergy_data = student_data.allergy_details;
                update_edit_allergic_student_form['update_allergy_data'] = updateed_allergy_data.map((item) => {
                    return ({
                        allergy_name: {
                            type: "text",
                            label: "Allergy Name",
                            value: item.allergy_name,
                            valid: true,
                            error_msg: "",
                            onBlur_out: false,
                            placeholder: "Allergy Name",
                            validations: { required: true },
                        },
                        allergy_info: {
                            type: "text",
                            label: "Allergy Info",
                            value: item.allergy_info,
                            valid: true,
                            error_msg: "",
                            onBlur_out: false,
                            placeholder: "Treatment",
                            validations: { required: true },
                        },
                        allergy_note: {
                            type: "text",
                            label: "Allergy Note",
                            value: item.allergy_note,
                            valid: true,
                            error_msg: "",
                            onBlur_out: false,
                            placeholder: "Allergy Note",
                            validations: { required: false },
                        },
                        student_allergy_uuid: {
                            type: "text",
                            label: "student_allergy_uuid",
                            value: item.student_allergy_uuid,
                            valid: true,
                            error_msg: "",
                            onBlur_out: false,
                            placeholder: "student_allergy_uuid",
                            validations: { required: false },
                        },
                        student_allergy_id: {
                            type: "text",
                            label: " student_allergy_id",
                            value: item.student_allergy_id,
                            valid: true,
                            error_msg: "",
                            onBlur_out: false,
                            placeholder: " student_allergy_id",
                            validations: { required: false },
                        },
                        student_id: {
                            type: "text",
                            label: "student_id",
                            value: item.student_id,
                            valid: true,
                            error_msg: "",
                            onBlur_out: false,
                            placeholder: "student_id",
                            validations: { required: false },
                        }
                    })

                })

                update_edit_allergic_student_form['student_name'].value = student_data.student_first_name + " " + student_data.student_last_name;
                update_edit_allergic_student_form['student_id'].value = student_data.student_id;
                this.setState({ edit_allergic_student_form: update_edit_allergic_student_form });
            }
        })
    }


    clickAddAllergy() {
        const update_edit_allergic_student_form = { ...this.state.edit_allergic_student_form };
        const update_row = update_edit_allergic_student_form.allergy_data;
        update_row.push(
            {
                allergy_name: {
                    type: "text",
                    label: "Allergy Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Allergy Name",
                    validations: { required: true },
                },
                allergy_info: {
                    type: "text",
                    label: "Allergy Info",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Treatment",
                    validations: { required: true },
                },
                allergy_note: {
                    type: "text",
                    label: "Allergy Note",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Allergy Note",
                    validations: { required: false },
                },
            }
        )
        update_edit_allergic_student_form.allergy_data = update_row;
        this.setState({ edit_allergic_student_form: update_edit_allergic_student_form })
    }

    clickDeleteAllergyrow = (identifier, index, e) => {
        const update_edit_allergic_student_form = { ...this.state.edit_allergic_student_form };      
        if (identifier === "allergy_data") {
            const deleteAllergyData = update_edit_allergic_student_form.allergy_data;
            let edit_row = deleteAllergyData.filter((i) => i !== index);
            update_edit_allergic_student_form['allergy_data'] = edit_row;

            this.setState({ edit_allergic_student_form: update_edit_allergic_student_form });

        } else {
            const deleteAllergyData = update_edit_allergic_student_form['update_allergy_data'];
            const deleteAllergy = deleteAllergyData.find((value, i) => {
                return value.student_allergy_uuid.value === index.student_allergy_uuid.value;
            })

            const delete_allergy_form_data = {}
            for (let key in deleteAllergy) {
                delete_allergy_form_data[key] = deleteAllergy[key].value;
            }
            this.props.onDeleteStudentAllergyById(delete_allergy_form_data).then((response) => {
                if (response.success === true) {
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            });
            let edit_row = deleteAllergyData.filter((i) => i !== index);
            update_edit_allergic_student_form['update_allergy_data'] = edit_row;
            this.setState({ edit_allergic_student_form: update_edit_allergic_student_form });
        }

      
    };








    inputChangeAllergyName(identifier, event, i,) {
        if(event.target.value.length <= 35){
            const update_edit_allergic_student_form = { ...this.state.edit_allergic_student_form }
            if (identifier === 'update_allergy_data') {
                let setName = update_edit_allergic_student_form.update_allergy_data[i];
                setName["allergy_name"].value = event.target.value
            } else {
                let setName = update_edit_allergic_student_form.allergy_data[i];
                setName["allergy_name"].value = event.target.value
            }
            this.setState({ edit_allergic_student_form: update_edit_allergic_student_form })
        }
    }

    inputChangeAllergyInfo(identifier, event, i) {
        if(event.target.value.length <= 70){
            const update_edit_allergic_student_form = { ...this.state.edit_allergic_student_form }
            if (identifier === 'update_allergy_data') {
                let setName = this.state.edit_allergic_student_form.update_allergy_data[i];
                setName["allergy_info"].value = event.target.value
            } else {
                let setName = update_edit_allergic_student_form.allergy_data[i];
                setName["allergy_info"].value = event.target.value
            }
            this.setState({ edit_allergic_student_form: update_edit_allergic_student_form })
        }
    }

    inputChangeAdditional_note(identifier, event, i) {
        if(event.target.value.length <= 500){
            const update_edit_allergic_student_form = { ...this.state.edit_allergic_student_form }
            if (identifier === 'update_allergy_data') {
                let setName = update_edit_allergic_student_form.update_allergy_data[i];
                setName["allergy_note"].value = event.target.value
            } else {
                let setName = update_edit_allergic_student_form.allergy_data[i];
                setName["allergy_note"].value = event.target.value
            }
            this.setState({ edit_allergic_student_form: update_edit_allergic_student_form })
        }
    }




    SubmitHandler = async (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_edit_allergic_student_form = this.state.edit_allergic_student_form;
        for (let key in update_edit_allergic_student_form) {
            if (Array.isArray(update_edit_allergic_student_form[key])) {
                const allergy_array = update_edit_allergic_student_form[key];
                allergy_array.forEach((element) => {
                    for (let key in element) {
                        let form_element = element[key];
                        let validate_element = Validations(form_element);
                        is_form_valid = validate_element.valid === false ? false : is_form_valid;
                        form_element.onBlur_out = true;
                        form_element.valid = validate_element.valid;
                        form_element.error_msg = validate_element.error_msg;
                        update_edit_allergic_student_form[key] = form_element;
                    }
                })
            }
        }
        this.setState({ edit_allergic_student_form: update_edit_allergic_student_form });
        if (is_form_valid) {
            const form_data = {};
            form_data['student_name'] = update_edit_allergic_student_form.student_name.value;
            const update_oldArray = update_edit_allergic_student_form['update_allergy_data'];
            let updated_allergy_data = update_oldArray.map((item, index) => {
                return ({
                    allergy_name: item.allergy_name.value,
                    allergy_info: item.allergy_info.value,
                    allergy_note: item.allergy_note.value,
                    student_allergy_uuid: item.student_allergy_uuid.value,
                    student_id: item.student_id.value,
                    student_name:update_edit_allergic_student_form.student_name.value
                })
            })
            form_data['update_allergy_data'] = JSON.stringify(updated_allergy_data)
            let oldArray = update_edit_allergic_student_form['allergy_data'];
            let update_allergy_data = oldArray.map((item, index) => {
                return ({
                    allergy_name: item.allergy_name.value,
                    allergy_info: item.allergy_info.value,
                    allergy_note: item.allergy_note.value,
                    student_id: update_edit_allergic_student_form.student_id.value,
                    student_name:update_edit_allergic_student_form.student_name.value
                })
            }
            )

            form_data['allergy_data'] = JSON.stringify(update_allergy_data);
            console.log("update_edit_allergic_student_form",update_edit_allergic_student_form.allergy_data);
            if(updated_allergy_data.length===0 && update_allergy_data.length===0){
                this.setState({showModal:!this.state.showModal})
            }else{
                this.props.onAddOrUpdateStudentAllergyDetails(form_data).then((response) => {
                    if (response.success === true) {
                        setTimeout(() => {
                            toast.success(response.message, {
                                position: toast.POSITION.TOP_CENTER,
                            });
                            this.setState({
                                isAuthenticated: true,
                                redirect_url: `/${school_code}/student/allergic-student`,
                            });
                        },500);
                    } else {
                        toast.error(response.message, {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                });
            }
        }
    };
    toggleModal = (event, row) => {
        this.setState({showModal:!this.state.showModal})
        // setConfirmRemoveData(row)
    };

    DeleteAllergy = () => {
        const update_edit_allergic_student_form = this.state.edit_allergic_student_form;
        const payload = {
            student_id: update_edit_allergic_student_form.student_id.value,
        }
        this.props.onRemoveAllAllergyOfStudent(payload).then((response) => {
            if (response.success === true) {
                this.setState({showModal:!this.state.showModal})
                setTimeout(() => {
                    this.setState({
                        isAuthenticated: true,
                        redirect_url: `/${school_code}/student/allergic-student`,
                    });
                }, 500);
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });

            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }

        });
    }

    render() {
        const { edit_allergic_student_form } = this.state;
        return (

            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
                <DeleteAllergyModal
                modalState={this.state.showModal}
                toggleModal={this.toggleModal}
                confirmRemoveData={this.state.confirmRemoveData}
                DeleteAllergy={this.DeleteAllergy}
            />
                {this.state.isAuthenticated ? <Navigate to={this.state.redirect_url} /> : null}
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <Sidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">

                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-fluid">
                                    <h4 className="card-title text-gray-800 m-0 mb-5">dsfsf</h4>
                                        <div className="school-edit-form">
                                            <div className="" id="Snapshot">
                                                <div className="card-body p-0">
                                            
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <form onSubmit={this.SubmitHandler}>
                                                                <div className="card mb-5 tabs-outer">
                                                                    <div className="card-header position-relative py-0 details-bg">
                                                                        <h2 className="card-title  m-0">Student Details</h2>
                                                                    </div>

                                                                    <div className="card-body">
                                                                        <div className="row">
                                                                            <div className="col-lg-5 fv-row ">
                                                                                <label className="fw-bold mb-2">{edit_allergic_student_form.student_name.label}</label>
                                                                                {console.log("sdfregtrhtrht", this.state.edit_allergic_student_form.student_name)}
                                                                                <input
                                                                                    name="fname"
                                                                                    className="form-control"
                                                                                    placeholder="Student Name"
                                                                                    value={edit_allergic_student_form.student_name.value ? edit_allergic_student_form.student_name.value : ""}
                                                                                />

                                                                                {!edit_allergic_student_form.student_name.valid && edit_allergic_student_form.student_name.onBlur_out ? (
                                                                                    <div className="error field-error">{edit_allergic_student_form.student_name.error_msg}</div>
                                                                                ) : (
                                                                                    ""
                                                                                )}
                                                                            </div>

                                                                            <div className="col-lg-12 fv-row mt-5">
                                                                             
                                                                                {console.log("dsgydtwfwf", edit_allergic_student_form.update_allergy_data)}
                                                                                {edit_allergic_student_form.update_allergy_data.map((item, i) => {
                                                                                    return (
                                                                                        <div className="row" key={i}>

                                                                                            <div className="col-12">
                                                                                                <div className="row">
                                                                                                    <div className="col-sm-5">
                                                                                                 
                                                                                                        <input
                                                                                                            type={item.allergy_name.type}
                                                                                                            className="form-control mb-2"
                                                                                                            placeholder={item.allergy_name.placeholder}
                                                                                                            value={item.allergy_name.value}
                                                                                                            onChange={(event) => this.inputChangeAllergyName("update_allergy_data", event, i)}
                                                                                                        />
                                                                                                        <span className="countLength">Max 35 Characters: {item.allergy_name.value?.length}</span>
                                                                                                        {!item.valid && item.allergy_name.onBlur_out ? (
                                                                                                            <div className="error field-error">{item.allergy_name.error_msg}</div>
                                                                                                        ) : (
                                                                                                            ""
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <div className="col-sm-5">
                                                                                                        <input
                                                                                                            type={item.allergy_info.type}
                                                                                                            className="form-control mb-2"
                                                                                                            placeholder={item.allergy_info.placeholder}
                                                                                                            value={item.allergy_info.value}
                                                                                                            onChange={(event) => this.inputChangeAllergyInfo("update_allergy_data", event, i)}
                                                                                                        />
                                                                                                        <span className="countLength">Max 70 Characters: {item.allergy_info.value?.length}</span>
                                                                                                        {!item.allergy_info.valid && item.allergy_info.onBlur_out ? (
                                                                                                            <div className="error field-error">{item.allergy_info.error_msg}</div>
                                                                                                        ) : (
                                                                                                            ""
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <div className="col-sm-2">
                                                                                                        <div className="d-flex flex-shrink-0 ">
                                                                                                            {edit_allergic_student_form.allergy_data.length === 0 && edit_allergic_student_form.update_allergy_data.length === i + 1 ?
                                                                                                                <span
                                                                                                                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm pointer"
                                                                                                                    data-bs-toggle="tooltip"
                                                                                                                    title="Add"
                                                                                                                    data-bs-original-title="Add"
                                                                                                                    aria-describedby="tooltip230873"
                                                                                                                    onClick={(i) => this.clickAddAllergy()}
                                                                                                                >
                                                                                                                    <span className="svg-icon svg-icon-3 pointer">
                                                                                                                        <i className="fas fa-plus"></i>
                                                                                                                    </span>
                                                                                                                </span> : null
                                                                                                            }

                                                                                                            <span className="ms-2 btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                                                                                                                data-bs-toggle="tooltip" title="Delete" data-bs-original-title="Delete"
                                                                                                                onClick={(e) => this.clickDeleteAllergyrow("update_allergy_data", item, e)}
                                                                                                            >
                                                                                                                <span className="svg-icon svg-icon-3">
                                                                                                                    <i className="far fa-trash-alt"></i>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="row mt-5">

                                                                                                <div className="col-lg-12 fv-row">
                                                                                                    <label className="fw-bold mb-2">Additional Notes</label>
                                                                                                    <textarea
                                                                                                        rows="2"
                                                                                                        className="form-control notes-main"
                                                                                                        type={item.allergy_note.value}
                                                                                                        placeholder={item.allergy_note.placeholder}
                                                                                                        value={item.allergy_note.value}
                                                                                                        onChange={(event) => this.inputChangeAdditional_note("update_allergy_data", event, i)}
                                                                                                    />
                                                                                                    {!item.allergy_note.valid && item.allergy_note.onBlur_out ? (
                                                                                                        <div className="error field-error">{item.allergy_note.error_msg}</div>
                                                                                                    ) : (
                                                                                                        ""
                                                                                                    )}
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                })

                                                                                }

                                                                                {edit_allergic_student_form.update_allergy_data.length === 0 && edit_allergic_student_form.allergy_data.length === 0 ?
                                                                                    <div className="row ">
                                                                                        <div className="col-12 text-end">
                                                                                            <span
                                                                                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm pointer"
                                                                                                data-bs-toggle="tooltip"
                                                                                                title="Add"
                                                                                                data-bs-original-title="Add"
                                                                                                aria-describedby="tooltip230873"
                                                                                                onClick={(i) => this.clickAddAllergy()}
                                                                                            >
                                                                                                <span className="svg-icon svg-icon-3 pointer">
                                                                                                    <i className="fas fa-plus"></i>
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div> :
                                                                                    edit_allergic_student_form.allergy_data.map((index, i) => {
                                                                                        return (
                                                                                            <div className="row mt-5" key={i}>
                                                                                                <div className="col-sm-5">
                                                                                                    <div className="row align-items-end">
                                                                                                        <div className="col-sm-12 mb-5">
                                                                                                        <label className="fw-bold mb-2">Allergy *</label>
                                                                                                            <input
                                                                                                                className="form-control mb-2"
                                                                                                                type={index.allergy_name.type}
                                                                                                                placeholder={index.allergy_name.placeholder}
                                                                                                                value={index.allergy_name.value}
                                                                                                                onChange={(event) => this.inputChangeAllergyName("allergy_data", event, i)}
                                                                                                            />
                                                                                                            <span className="countLength">Max 35 Characters: {index.allergy_name.value?.length}</span>
                                                                                                            {!index.valid && index.allergy_name.onBlur_out ? (
                                                                                                                <div className="error field-error">{index.allergy_name.error_msg}</div>
                                                                                                            ) : (
                                                                                                                ""
                                                                                                            )}
                                                                                                        </div>
                                                                                                        <div className="col-sm-12 mb-5">
                                                                                                            <input
                                                                                                                className="form-control mb-2"
                                                                                                                type={index.allergy_name.type}
                                                                                                                placeholder={index.allergy_info.placeholder}
                                                                                                                value={index.allergy_info.value}
                                                                                                                onChange={(event) => this.inputChangeAllergyInfo("allergy_data", event, i)}
                                                                                                            />
                                                                                                            <span className="countLength">Max 70 Characters: {index.allergy_info.value?.length}</span>
                                                                                                            {!index.allergy_info.valid && index.allergy_info.onBlur_out ? (
                                                                                                                <div className="error field-error">{index.allergy_info.error_msg}</div>
                                                                                                            ) : (
                                                                                                                ""
                                                                                                            )}
                                                                                                        </div>
                                                                                                        
                                                                                                    </div>
                                                                                                  
                                                                                                </div>
                                                                                                <div className="col-lg-5 fv-row">
                                                                                                        <label className="fw-bold mb-2">Additional Notes</label>
                                                                                                        <textarea
                                                                                                            rows="2"
                                                                                                            className="form-control notes-main"
                                                                                                            type={index.allergy_name.type}
                                                                                                            placeholder={index.allergy_note.placeholder}
                                                                                                            value={index.allergy_note.value}
                                                                                                            onChange={(event) => this.inputChangeAdditional_note("allergy_data", event, i)}
                                                                                                        />
                                                                                                        {!index.allergy_note.valid && index.allergy_note.onBlur_out ? (
                                                                                                            <div className="error field-error">{index.allergy_note.error_msg}</div>
                                                                                                        ) : (
                                                                                                            ""
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <div className="col-md-2">
                                                                                                            <div className="d-flex flex-shrink-0 mt-8">
                                                                                                                {edit_allergic_student_form.allergy_data.length === i + 1 ?

                                                                                                                    <span
                                                                                                                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm pointer"
                                                                                                                        data-bs-toggle="tooltip"
                                                                                                                        title="Add"
                                                                                                                        data-bs-original-title="Add"
                                                                                                                        aria-describedby="tooltip230873"
                                                                                                                        onClick={(i) => this.clickAddAllergy(index)}
                                                                                                                    >
                                                                                                                        <span className="svg-icon svg-icon-3 pointer">
                                                                                                                            <i className="fas fa-plus"></i>
                                                                                                                        </span>
                                                                                                                    </span> : null
                                                                                                                }
                                                                                                                <span className="ms-2 btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                                                                                                                    data-bs-toggle="tooltip" title="Delete" data-bs-original-title="Delete"
                                                                                                                    onClick={(e) => this.clickDeleteAllergyrow("allergy_data", index, e)}
                                                                                                                >
                                                                                                                    <span className="svg-icon svg-icon-3">
                                                                                                                        <i className="far fa-trash-alt"></i>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                            </div>
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-12 d-flex justify-content-end">
                                                                    <div className="d-flex align-items-end">
                                                                        <button type="submit" className="btn btn-sm btn-primary apply-btn min-150px">
                                                                            Save
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onAddOrUpdateStudentAllergyDetails: userActions.AddOrUpdateStudentAllergyDetails,
    onAddStudentNewAllergyDetails: userActions.AddStudentNewAllergyDetails,
    onGetAllStudentsAllergy: userActions.GetAllStudentsAllergy,
    onGetAllDormitoryStudent: userActions.GetAllDormitoryStudent,
    onGetAllClassesDetailsStudent: userActions.GetAllClassesDetailsStudent,
    onGetAllergicStudentDetailById: userActions.GetAllergicStudentDetailById,
    onDeleteStudentAllergyById: userActions.DeleteStudentAllergyById,
    onGetLoginUserPermissions: userActions.GetLoginUserPermissions,
    onRemoveAllAllergyOfStudent: userActions.RemoveAllAllergyOfStudent,

};
export default connect(mapStateToProps, mapDispatchToProps)(EditAllergicStudent);
