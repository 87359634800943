import React, { useState, useEffect, useCallback, useRef } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Tooltip } from "@mui/material";
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";
import SideBarLeave from "../../Constant/SideBarLeave";
import { toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import * as userActions from "../../../actions/index";
// 
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';

const customStyles = {
   option: (provided, state) => ({
      ...provided,
      "&:hover": {
         backgroundColor: state.isFocused ? "#e7f5fe" : "",
      },
      backgroundColor: state.isSelected ? "#4a8fb8" : "",
   }),
};

const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code ?? null;
const PresentStuListForAdhoc = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();

   const [page, setPage] = useState(1);
   const [limit, setLimit] = useState(10);
   const [count, setCount] = useState();
   const [sort, setSort] = useState();
   const [order, setOrder] = useState();
   const [search, setSearch] = useState("");
   const [dormitory_ids, setdormitory_ids] = useState([]);
   const [dormitory_id, setdormitory_id] = useState("");
   const [class_ids, setclass_ids] = useState([]);
   const [class_id, setclass_id] = useState("");
   const [gender, setGender] = useState("");
   const [StudentList, setStudentList] = useState([]);
   const [filterStudentList, setFilterStudentList] = useState([])
   const row_limit = [
      { value: 5, label: 5 },
      { value: 10, label: 10 },
      { value: 15, label: 15 },
      { value: 20, label: 20 },
      { value: 25, label: 25 }
   ]


   const GetAllStudentsForAdhocLeave = async () => {
      dispatch(userActions.GetAllStudentsForAdhocLeave(page, limit, sort, order, search, gender, dormitory_id, class_id)).then((response) => {
         if (response.success === true) {
            const arrayData = response.data.rows;
            arrayData.forEach((element , index) => {
               element.serialNo =  0 + limit * (page - 1) + (index+1);
               element.checked = false;
            });
            setCount(response.data.count)
            setStudentList(arrayData);
         }
         else if (response.status === 401) {
            toast.error(response.message, {
               position: toast.POSITION.TOP_CENTER,
            });
            setTimeout(() => {
               navigate('/logout')

            }, 5000);
         }
         else {
         }
      });
   };


   const GetAllClassesDetailsStudent = useCallback(() => {
      dispatch(userActions.GetAllClassesDetailsStudent()).then((response) => {
         if (response.success === true) {
            let state_data = [];
            state_data.push({
               label: "Select Year",
               value: "",
            });
            const arrayData = response.data.rows;
            for (let key in arrayData) {
               state_data.push({
                  label: arrayData[key].class_name,
                  value: arrayData[key].class_id,
                  class_id: arrayData[key].class_id,
               });
            }
            setclass_ids(state_data);
         } else {

         }
      });
   }, [dispatch]);

   const GetAllDormitoryStudent = useCallback(() => {
      dispatch(userActions.GetAllDormitoryStudent()).then((response) => {
         if (response.success === true) {
            let state_data = [];
            state_data.push({
               label: "Select Dormitory",
               value: "",
            });
            const arrayData = response.data.rows;
            for (let key in arrayData) {
               state_data.push({
                  label: arrayData[key].dormitory_name,
                  value: arrayData[key].dormitory_id,
                  dormitory_id: arrayData[key].dormitory_id,
               });
            }
            setdormitory_ids(state_data);
         } else {
         }
      });
   }, [dispatch]);

   useEffect(() => {
      GetAllClassesDetailsStudent();
      GetAllDormitoryStudent();
   }, []);
   useEffect(() => {
      GetAllStudentsForAdhocLeave();
   }, [page, limit, sort, order, search, gender, dormitory_id, class_id])

   const onChangeHandlar = (e, item) => {
      const resetStudentList = StudentList.map((student) => {
         if (item.student_id === student.student_id) {
            return { ...student, checked: e.target.checked }
         } else {
            return student
         }
      })
      const filtStudentList = resetStudentList.filter((item) => {
         if (item.checked === true) {
            return item
         }
      })
      setFilterStudentList(filtStudentList)
      setStudentList(resetStudentList)
   }


   const applyAdhocLeaveHandler = () => {
      navigate(`/${school_code}/leave/apply-adhoc-leave`, { state: { data: filterStudentList } })
   }

   return (
      <>
         <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed" >
            <div className="d-flex flex-column flex-root">
               <div className="page d-flex flex-row flex-column-fluid">
                  <SideBarLeave />
                  <div className="wrapper d-flex flex-column flex-row-fluid " id="kt_wrapper">
                     <TopBar />
                     <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="post d-flex flex-column-fluid" id="kt_post">
                           <div id="kt_content_container" className="container-fluid">
                              <div className='row'>
                                 <div className='col-md-12'>
                                    <div className='card'>
                                       <div className='card-header align-items-center'>
                                          <h3 className='m-0 fw-bolder'>Apply for Adhoc Leave ( on behalf of students )</h3>
                                       </div>
                                       <div className='card-body'>
                                          <div className='row'>
                                             <div className='col-10'>
                                                <div className='row'>
                                                   <div className='col-md-3'>
                                                      <label className='fw-bold mb-3'>Select Year</label>
                                                      <Select
                                                         styles={customStyles}
                                                         className="react-bootstrap-typeahead tag-outer" options={class_ids}
                                                         value={class_ids.filter((option) => {
                                                            return option.value === class_id;
                                                         })}
                                                         onChange={(event) => {
                                                            setclass_id(event.value);
                                                         }}
                                                      />
                                                   </div>
                                                   <div className="col-md-3">
                                                      <label className='fw-bold mb-3'>Select Dormitory</label>
                                                      <Select
                                                         styles={customStyles}
                                                         className="react-bootstrap-typeahead tag-outer"
                                                         options={dormitory_ids}
                                                         value={dormitory_ids.filter((option) => {
                                                            return option.value === dormitory_id;

                                                         })}
                                                         onChange={(event) => {
                                                            setdormitory_id(event.value);
                                                         }}
                                                      />
                                                   </div>
                                                   <div className='col-md-3'>
                                                      <div className='form-group'>
                                                         <label className='fw-bold mb-3'>Student Name</label>
                                                         <div className="position-relative">
                                                            <span className="search-icon-block">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none">
                                                            <path d="M19 19L13.0001 13M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z" stroke="#999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                            </svg>
                                                            </span>
                                                            <input 
                                                               type="search" 
                                                               placeholder="Search..." 
                                                               className="form-control pe-10" 
                                                               value={search} 
                                                               onChange={(e) => setSearch(e.target.value)} 
                                                            />
                                                         </div>
                                                      </div>
                                                   </div>


                                                   <div className="col-md-3">
                                                      <label className="fw-bold mb-3">Gender</label>
                                                      <div className="d-flex gap-2 mt-2">
                                                         <label className="cursor-pointer d-flex align-items-center">
                                                            <input
                                                               type="radio"
                                                               id="gende"
                                                               name="gen"
                                                               className="form-check-input me-2 cursor-pointer"
                                                               value="male"
                                                               onChange={(event) => { setGender(event.target.value); }}
                                                               checked={gender === "male" ? true : false}
                                                            />
                                                            Male
                                                         </label>

                                                         <label className="ms-4 cursor-pointer d-flex align-items-center">
                                                            <input
                                                               type="radio"
                                                               id="gender"
                                                               name="gen"
                                                               className="form-check-input me-2 cursor-pointer"
                                                               value="female"
                                                               onChange={(event) => { setGender(event.target.value); }}
                                                               checked={gender === "female" ? true : false}
                                                            />
                                                            Female
                                                         </label>

                                                         <label className="ms-4 cursor-pointer d-flex align-items-center">
                                                            <input
                                                               type="radio"
                                                               id="gender"
                                                               name="gen"
                                                               className="form-check-input me-2 cursor-pointer"
                                                               value=""
                                                               onChange={(event) => { setGender(event.target.value); }}
                                                               checked={gender === "" ? true : false}
                                                            />
                                                            Both
                                                         </label>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                             <div className='col-2'>
                                                <button type="reset" className="btn btn-secondary btn-sm float-end mt-7"
                                                   onClick={(event) => {
                                                      setclass_id("");
                                                      setdormitory_id("");
                                                      setSearch("")
                                                      setGender("")
                                                   }} >Clear Search
                                                </button>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className='row'>
                                 <div className="col-lg-12 mt-5">
                                    <div className='card'>
                                       <div className="card-header align-items-center">
                                          <h2 className="m-0 card-title">Select Student or Students from the list below</h2>
                                          <button
                                             className="btn btn-primary btn-flex btn-sm  border-0 fw-bolder px-4 px-lg-6"
                                             onClick={() => applyAdhocLeaveHandler()}
                                             disabled={filterStudentList.length > 0 ? false : true}
                                          >
                                             Apply Adhoc Leave
                                          </button>
                                       </div>
                                       <div className='card-body'>
                                          <div className='table-responsive'>
                                             <MDBTable className='table table-row-bordered'>
                                                <MDBTableHead>
                                                   <tr>
                                                      <th>Sr.No</th>
                                                      <th>Student Name</th>
                                                      <th>Year</th>
                                                      <th>Dormitory</th>
                                                      <th>Gender</th>
                                                      <th>Father Name</th>
                                                      <th>Father Phone Number</th>
                                                      <th>Actions</th>
                                                   </tr>
                                                </MDBTableHead>
                                                <MDBTableBody>
                                                   {StudentList.map((item, index) => {
                                                      return (
                                                         <tr key={item.student_id}>
                                                            <td scope='row'>{item.serialNo}</td>
                                                            <td>{`${item.student_first_name} ${item.student_last_name}`}</td>
                                                            <td>{item.class_name}</td>
                                                            <td>{item.dormitory_name}</td>
                                                            <td>{item.gender}</td>
                                                            <td>{item?.parent_data?.father_name}</td>
                                                            <td>{item?.parent_data?.father_phone}</td>
                                                            <td>

                                                               {item.withdraw_leave ?
                                                                  <Tooltip title="Ad-hoc leave has been temporarily withdrawn for this student" placement="top">
                                                                     <i class="fas fa-eye ms-5 fs-6" aria-hidden="true"></i>
                                                                  </Tooltip> :
                                                                  <input
                                                                     type="checkbox"
                                                                     className="form-check-input ms-5"
                                                                     onClick={(e) => onChangeHandlar(e, item)}
                                                                  />
                                                               }
                                                            </td>
                                                         </tr>
                                                      )
                                                   })}
                                                </MDBTableBody>
                                             </MDBTable>
                                          </div>
                                          <div className="d-flex justify-content-between">
                                             <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                defaultValue={row_limit[1]}
                                                name="color"
                                                options={row_limit}
                                                onChange={(e) => setLimit(e.value)}
                                             />
                                             <Stack>
                                                <Pagination
                                                   count={Math.ceil(count / limit)}
                                                   page={page}
                                                   onChange={(e, value) => setPage(value)}
                                                   color="primary"
                                                />
                                             </Stack>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        </div>
                        <Footer />
                     
                  </div>

               </div>
            </div>
         </div>
      </>
   )
}

export default PresentStuListForAdhoc;