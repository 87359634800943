import React from "react";
import { Modal } from "react-bootstrap";
import Validations from "../../../Utility/Validations";


class AddOnCampusModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            oncampus_form: {
                location_name: {
                    type: "text",
                    label: "Add Location",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
            },
            
        };
    }


    inputChangeHandler(event, identifier) {
        const update_oncampus_form = { ...this.state.oncampus_form };
        const form_element = { ...update_oncampus_form[identifier] };
        form_element.value = event.target.value;
        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_oncampus_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_oncampus_form) {
            is_form_valid = update_oncampus_form[identifier].valid && is_form_valid;
        }
        this.setState({
            oncampus_form: update_oncampus_form,
            is_form_valid: is_form_valid,
        });
    }
    



    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_oncampus_form = this.state.oncampus_form;
        console.log(update_oncampus_form);


        for (let key in update_oncampus_form) {
            let form_element = update_oncampus_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_oncampus_form[key] = form_element;
        }
        this.setState({ oncampus_form: update_oncampus_form });

        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.oncampus_form) {
                form_data[key] = this.state.oncampus_form[key].value;
            }
            this.props.CreateOnCampusLocation(form_data);
        }
    };

    render() {

        const { oncampus_form } = this.state;
        console.log(oncampus_form);
        return (
            <React.Fragment>
                <Modal
                    backdrop="static"
                    keyboard={false}
                    className="add_oncampus_modal confirm_modal"
                    show={this.props.add_oncampus_modal}
                    onHide={() => {
                        this.props.add_oncampus_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Add On Campus Location</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="form w-100" onSubmit={this.SubmitHandler}>
                           
                             


                                        
                                            <label className="fw-bold mb-2">{oncampus_form.location_name.label}</label>
                                           
                                            <input
                                                type={oncampus_form.location_name.type}
                                                value={oncampus_form.location_name.value ? oncampus_form.location_name.value : ""}
                                                className="form-control"
                                                placeholder={oncampus_form.location_name.placeholder}
                                                onChange={(event) => this.inputChangeHandler(event, "location_name")}
                                            />

                                            {!oncampus_form.location_name.valid && oncampus_form.location_name.onBlur_out ? <div className="error field-error">{oncampus_form.location_name.error_msg}</div> : ""}
                                    



                                        <div className="mt-4 d-flex justify-content-center">
                                            <button type="submit" className="btn btn-sm btn-primary">
                                                Save
                                            </button>
                                
                                    </div>
                                
                        </form>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default AddOnCampusModal;
