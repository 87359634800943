import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import DuplicateRoleModal from './DuplicateRoleModal';
import DeleteRoleModal from './DeleteRoleModal';
import DeactivateRoleModal from './DeactivateRoleModal';
import { toast } from "react-toastify";
import * as userActions from "../../actions/index";
import { connect, useDispatch } from "react-redux";
import { Tooltip } from "@mui/material";




const permission_infos = JSON.parse(localStorage.getItem("permissions"));
const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info.school_code;


const ReadOnlyRow = ({
    item,
    index,
    handleEditClick,
    permission_info,
    handleEdit,
    handleChange,
    CreateDuplicateRoleAndPerm,
    all_role_name,
    DeleteRole
}) => {
    console.log("permission_info", permission_infos);

    return (
        <tr>
            <td>{index + 1}</td>

            <td className="">
                <div className='d-flex align-items-center'>
                    <span>{item.role_name}</span>
                    <span className='ms-1'>
                        {permission_infos?.edit_role !== false &&
                            <Tooltip title="Edit Role Name" placement="top">
                                <IconButton onClick={(event) => handleEditClick(event, item, index)}>
                                    <i className="fa fa-pencil-alt font-16px"></i>
                                </IconButton>
                            </Tooltip>
                        }
                    </span>
                </div>
            </td>

            <td className="text-justify">
                <div >
                    {item.is_role_activate === true ? (
                        permission_infos?.deactivate_role !== false &&
                        <div className="d-flex align-items-center">
                            <DeactivateRoleModal
                                role_id={item.role_id}
                                role_uuid={item.role_uuid}
                                role_name={item.role_name}
                                handleChange={handleChange}
                            />
                            <span>Deactivate Role</span>
                        </div>
                    ) : (
                        <div className="d-flex align-items-center"
                            onClick={(event) => { handleChange(event, item.role_uuid, true, item.role_name); }}
                        >
                            <span className="deactivate-button1">
                                <i className="bi bi-bell me-2 fs-6"></i>Activate Role
                            </span>
                        </div>
                    )}
                </div>
            </td>

            <td className="text-justify">
                <div className="d-flex justify-content">
                    {item.is_role_activate === true ? (
                        <span className="badge badge-light-success px-4 py-2">Active</span>
                    ) : (
                        <span className="badge badge-light-danger px-4 py-2">Deactivated</span>
                    )}
                </div>
            </td>

            <td className="text-end">
                <div className="d-flex justify-content-end flex-shrink-0">
                    {permission_infos?.delete_role !== false &&
                        <DeleteRoleModal
                            role_id={item.role_id}
                            role_uuid={item.role_uuid}
                            DeleteRole={DeleteRole}
                            className="btn-cts"
                        />
                    }
                    {permission_infos?.add_duplicate_role !== false &&
                        <DuplicateRoleModal
                            role_uuid={item.role_uuid}
                            createI_role_name={CreateDuplicateRoleAndPerm}
                            all_role_name={all_role_name}
                            className="btn-cts"
                        />
                    }
                    {permission_infos?.edit_permission !== false &&
                        (<Link
                            to={`/${school_code}/permissions-list/edit-permission/${item.role_uuid}`}
                            data-bs-toggle="tooltip"
                            title=""
                            className='btn-cts'
                            data-bs-original-title="Edit"
                            onClick={() => handleEdit(item.role_id)}
                        >
                            <Tooltip title="Edit Role Permissions" placement="top">

                            <i className="fas fa-edit font-16px"></i>

                            </Tooltip>
                        </Link>)
                    }
                </div>
            </td>
        </tr>
    );
};

export default ReadOnlyRow;
