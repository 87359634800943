import React from "react";
import { Modal } from "react-bootstrap";

const ApplyAdhocLeaveModal = (props) => {
  return (
    <React.Fragment>
      <Modal
        backdrop="static"
        keyboard={false}
        className="confirm_delete_modal confirm_modal"
        show={props.confirm_modal}
        onHide={() => { props.modalHide() }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Adhoc leave submission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to submit this ad-hoc leave request? This action cannot be modified again.</p>
        </Modal.Body>
        <Modal.Footer>
          <div className="w-100 text-center">
            <button type="submit" className="btn btn-sm btn-primary" onClick={() => { props.SubmitHandler() }}>
              Submit
            </button>
            <button type="submit" className="btn btn-sm btn-secondary ms-4" onClick={() => { props.modalHide() }}>
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );

}
export default ApplyAdhocLeaveModal;

