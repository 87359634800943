import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";


const BorderLeaveApprovalModal = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [boarderLeaveData, setBoarderLeaveData] = useState({})

  useEffect(() => {
    setShowModal(props.showModal)
    setBoarderLeaveData(props.boarderLeaveData)
  }, [props])

  const closeModal = () => { props.hideApprovelLeaveModal(false) };

  const openRejectModal = () => {
    props.approveAndRejectModal({ value: false, mode: "reject" });
  };

  const openApproveModal = () => {
    props.approveAndRejectModal({ value: false, mode: "approve" });
  };


  return (
    <div>
      <Modal
        show={showModal}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Take a decision ?</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div className="row">
            <div className="col-5 text-start">
              <label className="form-label">Student Name</label>
            </div>
            <div className="col-7">
              <span>{boarderLeaveData?.leave_stu_data?.student_name}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-5 text-start">
              <label className="form-label">Leave Dates</label>
            </div>
            <div className="col-7">
              <span>{boarderLeaveData?.departure_date + " to  " + boarderLeaveData.return_date}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-5 text-start">
              <label className="form-label">Remarks by Host</label>
            </div>
            <div className="col-7">
              <span>{boarderLeaveData?.remarks_by_host === "" ? "N/A" : boarderLeaveData?.remarks_by_host}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-5 text-start ">
              <label className="form-label">Remarks by Parent</label>
            </div>
            <div className="col-7">
              <span>{boarderLeaveData?.remarks_by_parent === "" ? "N/A" : boarderLeaveData?.remarks_by_parent}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-5 text-start">
              <label className="form-label">Remarks by Boarding</label>
            </div>
            <div className="col-7">
              <span>{boarderLeaveData?.remarks_by_boarding === "" ? "N/A" : boarderLeaveData?.remarks_by_boarding}</span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button className="btn-sm px-7  me-2" onClick={openApproveModal}>Approve</Button>
          <Button className="btn-sm px-7 btn-danger" onClick={openRejectModal}>Reject</Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default BorderLeaveApprovalModal;

