import axios from "axios";
import * as actionType from "../constants/actionTypes";
let qs = require("qs");


/*** GetManagerPermissonDetailsById Action ***/
export const GetManagerPermissonDetailsByIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetManagerPermissonDetailsById_START,
    };
};
export const GetManagerPermissonDetailsByIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetManagerPermissonDetailsById_SUCCESS,
    };
};
export const GetManagerPermissonDetailsByIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetManagerPermissonDetailsById_FAIL,
    };
};
export const GetManagerPermissonDetailsById = (user_id) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetManagerPermissonDetailsByIdStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info?.country_host ,
                school_code: user_info?.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetManagerPermissonDetailsById`,
            params: {
                user_id:user_id,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetManagerPermissonDetailsByIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetManagerPermissonDetailsByIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** UpdateManagerPermissions Action ***/

export const UpdateManagerPermissionsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateManagerPermissions_START,
    };
};

export const UpdateManagerPermissionsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateManagerPermissions_SUCCESS,
    };
};

export const UpdateManagerPermissionsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateManagerPermissions_FAIL,
    };
};

export const UpdateManagerPermissions = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(UpdateManagerPermissionsStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        // formdata = form_data

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info?.country_host ,
                school_code: user_info?.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/UpdateManagerPermissions`,
        })
            .then(function (response) {
                dispatch(UpdateManagerPermissionsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateManagerPermissionsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** GetLoginUserPermissions Action ***/
export const GetLoginUserPermissionsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetLoginUserPermissions_START,
    };
};
export const GetLoginUserPermissionsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetLoginUserPermissions_SUCCESS,
    };
};
export const GetLoginUserPermissionsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetLoginUserPermissions_FAIL,
    };
};
export const GetLoginUserPermissions = () => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetLoginUserPermissionsStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info?.country_host ,
                school_code: user_info?.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetLoginUserPermissions`,
            // params: {
            //     // dormitory_status:dormitory_status,
            // },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                if (response.data.success === true) {
                    localStorage.setItem("permissions", JSON.stringify(response.data.data));
                }
                dispatch(GetLoginUserPermissionsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetLoginUserPermissionsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** GetManagerPermissonDetailsById Action ***/
export const GetManagerPermissonHistoryByIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetManagerPermissonHistoryById_START,
    };
};
export const GetManagerPermissonHistoryByIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetManagerPermissonHistoryById_SUCCESS,
    };
};
export const GetManagerPermissonHistoryByIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetManagerPermissonHistoryById_FAIL,
    };
};
export const GetManagerPermissonHistoryById = (user_id) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetManagerPermissonHistoryByIdStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info?.country_host ,
                school_code: user_info?.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetManagerPermissonHistoryById`,
            params: {
                user_id:user_id,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetManagerPermissonHistoryByIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetManagerPermissonHistoryByIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** GetAllDormitoryListForSnapshot Action ***/
export const GetAllDormitoryListForSnapshotStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllDormitoryListForSnapshot_START,
    };
};
export const GetAllDormitoryListForSnapshotSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllDormitoryListForSnapshot_SUCCESS,
    };
};
export const GetAllDormitoryListForSnapshotFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllDormitoryListForSnapshot_FAIL,
    };
};
export const GetAllDormitoryListForSnapshot = () => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllDormitoryListForSnapshotStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info?.country_host ,
                school_code: user_info?.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllDormitoryListForSnapshot`,
            params: {
                // dormitory_status:dormitory_status,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllDormitoryListForSnapshotSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllDormitoryListForSnapshotFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** CreateRole Action ***/
export const CreateRoleStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.CreateRole_START,
    };
};
export const CreateRoleSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.CreateRole_SUCCESS,
    };
};
export const CreateRoleFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.CreateRole_FAIL,
    };
};
export const CreateRole = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    // console.log("formDATA", form_data)
    return (dispatch) => {
        dispatch(CreateRoleStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info?.country_host ,
                school_code: user_info?.school_code
            },
          
            url: `${actionType.environment_url}/api/bss-admin/CreateRole`,
        })
            .then(function (response) {
                dispatch(CreateRoleSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateRoleFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** GetAllRoleList Action ***/
export const GetAllRoleListStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllRoleList_START,
    };
};
export const GetAllRoleListSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllRoleList_SUCCESS,
    };
};
export const GetAllRoleListFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllRoleList_FAIL,
    };
};
export const GetAllRoleList = () => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllRoleListStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info?.country_host ,
                school_code: user_info?.school_code,
              
            },
            
            url: `${actionType.environment_url}/api/bss-admin/GetAllRoleList`,
            params: {
                // user_id:user_id,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllRoleListSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllRoleListFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetAllRoleDetails Action ***/
export const GetAllRoleDetailsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllRoleDetails_START,
    };
};
export const GetAllRoleDetailsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllRoleDetails_SUCCESS,
    };
};
export const GetAllRoleDetailsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllRoleDetails_FAIL,
    };
};
export const GetAllRoleDetails = (page,limit,is_role_activate) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllRoleDetailsStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info?.country_host ,
                school_code: user_info?.school_code,
              
            },
            
            url: `${actionType.environment_url}/api/bss-admin/GetAllRoleDetails`,
            params: {
                page:page,
                limit:limit,
                // sort:sort,
                // order:order,
                is_role_activate:is_role_activate
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                // console.log("GetAllRoleList, action response", response)
                dispatch(GetAllRoleDetailsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                // console.log("GetAllRoleList, action error", error)
                dispatch(GetAllRoleDetailsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetPermissionsByRoleId Action ***/
export const GetPermissionsByRoleIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetPermissionsByRoleId_START,
    };
};
export const GetPermissionsByRoleIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetPermissionsByRoleId_SUCCESS,
    };
};
export const GetPermissionsByRoleIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetPermissionsByRoleId_FAIL,
    };
};
export const GetPermissionsByRoleId = (role_uuid) => {
    // console.log("role_uuid in action", role_uuid)
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetPermissionsByRoleIdStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info?.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetPermissionsByRoleId`,
            params: {
                role_uuid:role_uuid,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetPermissionsByRoleIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetPermissionsByRoleIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** UpdateRole Action ***/

export const UpdateRoleStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateRole_START,
    };
};

export const UpdateRoleSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateRole_SUCCESS,
    };
};

export const UpdateRoleFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateRole_FAIL,
    };
};

export const UpdateRole = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
// console.log("form_data in action" , form_data)
    return (dispatch) => {
        dispatch(UpdateRoleStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/UpdateRole`,
        })
            .then(function (response) {
                dispatch(UpdateRoleSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateRoleFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** CreateOrUpdateRolePermissions Action ***/

export const CreateOrUpdateRolePermissionsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.CreateOrUpdateRolePermissions_START,
    };
};

export const CreateOrUpdateRolePermissionsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.CreateOrUpdateRolePermissions_SUCCESS,
    };
};

export const CreateOrUpdateRolePermissionsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.CreateOrUpdateRolePermissions_FAIL,
    };
};

export const CreateOrUpdateRolePermissions = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(CreateOrUpdateRolePermissionsStart());

        let formdata = new FormData();
        // for (let key in form_data1) {
        //     formdata.append(key, form_data1[key]);
        // }
        formdata = form_data

        // console.log("FORMDATA 11 in action", formdata)

        return axios({
            method: "post",
            data: formdata,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/CreateOrUpdateRolePermissions`,
        })
            .then(function (response) {
                dispatch(CreateOrUpdateRolePermissionsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateOrUpdateRolePermissionsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** GetRoleAndPermissionList Action ***/
export const GetRoleAndPermissionListStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetRoleAndPermissionList_START,
    };
};
export const GetRoleAndPermissionListSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetRoleAndPermissionList_SUCCESS,
    };
};
export const GetRoleAndPermissionListFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetRoleAndPermissionList_FAIL,
    };
};
export const GetRoleAndPermissionList = () => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetRoleAndPermissionListStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info?.country_host ,
                school_code: user_info?.school_code,
              
            },
            url: `${actionType.environment_url}/api/bss-admin/GetRoleAndPermissionList`,
            params: {},
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetRoleAndPermissionListSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetRoleAndPermissionListFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** GetAllDormitory Action ***/
export const GetAllClassesListForSnapshotStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllClassesListForSnapshot_START,
    };
};
export const GetAllClassesListForSnapshotSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllClassesListForSnapshot_SUCCESS,
    };
};
export const GetAllClassesListForSnapshotFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllClassesListForSnapshot_FAIL,
    };
};
export const GetAllClassesListForSnapshot = () => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllClassesListForSnapshotStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info?.country_host ,
                school_code: user_info?.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllClassesListForSnapshot`,
            params: {},
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllClassesListForSnapshotSuccess(response.data));
                // console.log("GetAllClassesListForSnapshot", response)
                return response.data;
                
            })
            .catch(function (error) {
                dispatch(GetAllClassesListForSnapshotFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** UpdateRole Action ***/

export const UpdateRoleStatusStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateRoleStatus_START,
    };
};

export const UpdateRoleStatusSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateRoleStatus_SUCCESS,
    };
};

export const UpdateRoleStatusFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateRoleStatus_FAIL,
    };
};

export const UpdateRoleStatus = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
// console.log("form_data in action" , form_data)
    return (dispatch) => {
        dispatch(UpdateRoleStatusStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info?.country_host ,
                school_code: user_info?.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/UpdateRoleStatus`,
        })
            .then(function (response) {
                dispatch(UpdateRoleStatusSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateRoleStatusFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** CreateDuplicateRoleAndPerm ***/

export const CreateDuplicateRoleAndPermStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.CreateDuplicateRoleAndPerm_START,
    };
};

export const CreateDuplicateRoleAndPermSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.CreateDuplicateRoleAndPerm_SUCCESS,
    };
};

export const CreateDuplicateRoleAndPermFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.CreateDuplicateRoleAndPerm_FAIL,
    };
};

export const CreateDuplicateRoleAndPerm = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(CreateDuplicateRoleAndPermStart());
        let formdata = new FormData();
        formdata = form_data
        return axios({
            method: "post",
            data: formdata,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/CreateDuplicateRoleAndPerm`,
        })
            .then(function (response) {
                dispatch(CreateDuplicateRoleAndPermSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateDuplicateRoleAndPermFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** GetAllManagersByRoleId ***/

export const GetAllManagersByRoleIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllManagersByRoleId_START,
    };
};

export const GetAllManagersByRoleIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllManagersByRoleId_SUCCESS,
    };
};

export const GetAllManagersByRoleIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllManagersByRoleId_FAIL,
    };
};

export const GetAllManagersByRoleId = (role_id) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllManagersByRoleIdStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info?.country_host ,
                school_code: user_info?.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllManagersByRoleId`,
            params: {
                role_id:role_id
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllManagersByRoleIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllManagersByRoleIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** DeleteRole ***/



export const DeleteRoleStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.DeleteRole_START,
    };
};
export const DeleteRoleSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.DeleteRole_SUCCESS,
    };
};
export const DeleteRoleFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.DeleteRole_FAIL,
    };
};


export const DeleteRole = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(DeleteRoleStart());
        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        return axios({
            method: "delete",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url:`${actionType.environment_url}/api/bss-admin/DeleteRole`,
   
        })
        .then(function (response) {
            dispatch(DeleteRoleSuccess(response.data));
            return response.data;
        })
        .catch(function (error) {
            dispatch(DeleteRoleFail("Something went wrong, Please try again."));
            return error;
        });
    };
};



/*** /ReorderRoles ***/



export const ReorderRolesStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.ReorderRoles_START,
    };
};
export const ReorderRolesSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.ReorderRoles_SUCCESS,
    };
};
export const ReorderRolesFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.ReorderRoles_FAIL,
    };
};
export const ReorderRoles = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    // console.log("formDATA", form_data)
    return (dispatch) => {
        dispatch(ReorderRolesStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info?.country_host ,
                school_code: user_info?.school_code
            },
          
            url: `${actionType.environment_url}/api/bss-admin/ReorderRoles`,
        })
            .then(function (response) {
                dispatch(ReorderRolesSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(ReorderRolesFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

