import React from "react";
import { Modal } from "react-bootstrap";

class DeleteConfirmRemove extends React.Component {
    SubmitHandler = (event) => {
        event.preventDefault();
        console.log("editconfirmremovedata",this.props);
        const getstudent_name = this.props.editconfirmremovedata;
        let form_data;
        if(this.props.title === "Delete Student Reminder ?"){
            const student_name = getstudent_name.grounded_student_data.student_first_name + " " + getstudent_name.grounded_student_data.student_last_name;
            form_data = {...this.props.editconfirmremovedata,student_name}
        }else{
            // const student_name = getstudent_name.generic_student.student_first_name + " " + getstudent_name.generic_student.student_last_name;
            form_data = {...this.props.editconfirmremovedata}
        }
        this.props.ConfirmRemove(form_data);
    };

    render() {
        return (
            <React.Fragment>
                <Modal
                    backdrop="static"
                    keyboard={false}
                    className="confirm_delete_modal confirm_modal"
                    show={this.props.confirm_modal}
                    onHide={() => {
                        this.props.delete_confirm_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.title}</Modal.Title>
                    </Modal.Header>
                   <form className="form w-100" onSubmit={this.SubmitHandler}>
                   <Modal.Body>     
                            <div className="row">
                                <p className="mb-0 text-center">{this.props.bodytitle}</p>
                            </div>
                            
                            </Modal.Body>
                            <Modal.Footer className="justify-content-center border-0">
                                <button type="submit" className="btn btn-sm btn-primary">
                                    Delete
                                </button>
                            </Modal.Footer>
                        </form>
                    
                </Modal>
            </React.Fragment>
        );
    }
}
export default DeleteConfirmRemove;
