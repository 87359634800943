import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApproveLeaveReport from "../../component/Leave/Reports/ApproveLeaveReport";
import NonReturnReport from "../../component/Leave/Reports/NonReturnReport";
import DepartureReport from "../../component/Leave/Reports/DepartureReport";
import DueBackReport from "../../component/Leave/Reports/DueBackReport";
import CateringReport from "../../component/Leave/Reports/CateringReport";
import BoarderLeaveHistoryReport from "../../component/Leave/Reports/BoarderLeaveHistoryReport";
import AdhocLeaveHistoryReport from "../../component/Leave/Reports/AdhocLeaveHistoryReport";
import AdhocLeaveReport from "../../component/Leave/Reports/AdhocLeaveReport";
import AllergyReport from "../../component/Leave/Reports/AllergyReport";
import StudentConnectToHostReport from "../../component/Leave/Reports/StudentConnectToHostReport";
import HostConnectToStudentReport from "../../component/Leave/Reports/HostConnectToStudentReport";
import HostReport from "../../component/Leave/Reports/HostReport";




const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info?.school_code;



const Reports = (props) => {
    const Renderview = () => {
        let location = useLocation();
        let userId = useParams();

        if (location.pathname === `/${school_code}/leave/reports/approved-leave-report` || location.pathname === `/${school_code}/leave/reports/approved-leave-report/`) {
            return <ApproveLeaveReport />;
        }
        if (location.pathname === `/${school_code}/leave/reports/non-return-report` || location.pathname === `/${school_code}/leave/reports/non-return-report/`) {
            return <NonReturnReport />;
        }
        if (location.pathname === `/${school_code}/leave/reports/departure-report` || location.pathname === `/${school_code}/leave/reports/departure-report/`) {
            return <DepartureReport />;
        }
        if (location.pathname === `/${school_code}/leave/reports/due-back-report` || location.pathname === `/${school_code}/leave/reports/due-back-report/`) {
            return <DueBackReport />;
        }
        if (location.pathname === `/${school_code}/leave/reports/catering-report` || location.pathname === `/${school_code}/leave/reports/catering-report/`) {
            return <CateringReport />;
        }
        if (location.pathname === `/${school_code}/leave/reports/border-leave-history-report` || location.pathname === `/${school_code}/leave/reports/border-leave-history-report/`) {
            return <BoarderLeaveHistoryReport/>;
        }
        if (location.pathname === `/${school_code}/leave/reports/adhoc-leave-history-report` || location.pathname === `/${school_code}/leave/reports/adhoc-leave-history-report/`) {
            return <AdhocLeaveHistoryReport/>;
        }
        if (location.pathname === `/${school_code}/leave/reports/adhoc-report` || location.pathname === `/${school_code}/leave/reports/adhoc-report/`) {
            return <AdhocLeaveReport/>;
        }
        if (location.pathname === `/${school_code}/leave/reports/allergic-report` || location.pathname === `/${school_code}/leave/reports/allergic-report/`) {
            return <AllergyReport/>;
        }
        if (location.pathname === `/${school_code}/leave/reports/student-connect-to-host-report` || location.pathname === `/${school_code}/leave/reports/student-connect-to-host-report/`) {
            return <StudentConnectToHostReport/>;
        }
        if (location.pathname === `/${school_code}/leave/reports/host-connect-to-student-report` || location.pathname === `/${school_code}/leave/reports/host-connect-to-student-report/`) {
            return <HostConnectToStudentReport/>;
        }
        if (location.pathname === `/${school_code}/leave/reports/host-report` || location.pathname === `/${school_code}/leave/reports/host-report/`) {
            return <HostReport/>;
        }
    };

    return (
        <React.Fragment>
            <ToastContainer />
            {Renderview()}
        </React.Fragment>
    );
};
export default Reports;