import React from "react";
import { Navigate } from "react-router-dom";
import Validations from "../Utility/Validations";
import Sidebar from "../Constant/sidebar";
import Footer from "../Constant/Footer";
import TopBar from "../Constant/TopBar";
import * as userActions from "../../actions/index";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import moment from "moment";
import { Link } from "react-router-dom";
import Select from "react-select";
import AvatarImageCropper from 'react-avatar-image-cropper';
// import ManagerPermission from "./ManagerPermission";
import { Tooltip } from "@mui/material";


import * as actionType from "../../constants/actionTypes";
import { connect } from "react-redux";

import { toast } from "react-toastify";
import { FaceRetouchingOffSharp } from "@mui/icons-material";


const permission_info = JSON.parse(localStorage.getItem("permissions"));
const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info.school_code;

var user_id;
class ManagerEdit extends React.Component {
    constructor(props) {
        super(props);
        window.addEventListener('keydown', this.handleKeyPress);
        this.state = {

            current_password_hidden: true,
            new_password__hidden: true,
            confirm_new_password__hidden: true,
            OpenImageModal: false,
            manager_edit_form: {
                avatar: {
                    label: "File",
                    type: "file",
                    value: '',
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                    thumbnail_image: "/assets/media/school-logo.png",
                },

                user_id: {
                    type: "text",
                    label: "User ID",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "User ID",
                    validations: { required: true },
                },
                first_name: {
                    type: "text",
                    label: "First Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "First Name",
                    validations: { required: true },
                },
                last_name: {
                    type: "text",
                    label: "Last Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Last Name",
                    validations: { required: false },
                },
                email: {
                    type: "email",
                    label: "Email *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Email",
                    validations: { required: false },
                },
                contact_one: {
                    type: "tel",
                    label: "Contact Number *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Contact Number",
                    validations: { required: true, min_length_phone: 7 },
                },
                designation: {
                    type: "text",
                    label: "Designation *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Designation",
                    validations: { required: true },
                },
                comment: {
                    type: "text",
                    label: "Comment",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "comment",
                    validations: { required: false },
                },
                user_uuid: {
                    type: "text",
                    label: "user_uuid",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },
                is_user_activate: {
                    type: "text",
                    label: "user_uuid",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },
                last_archived_date: {
                    type: "text",
                    label: "Date of Relieving",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },
                last_archived_date1: {
                    type: "text",
                    label: "Date of Relieving",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },
                archived_note: {
                    type: "text",
                    label: "Relieving Note",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },
                created_date: {
                    type: "text",
                    label: " Date Of Joining",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },
                created_date1: {
                    type: "text",
                    label: " Date Of Joining",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },
                role_id: {
                    type: "Select",
                    label: "Role ID  *",
                    value: { label: "", value: "" },
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    options: [],
                    validations: { required: true },
                },
                permission_data: {
                    type: "hidden",
                    label: "Permission Data  *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // validations: { required: true },
                },


                snapshot_gender_both: {
                    type: "radio",
                    label: "Gender",
                    value: false,
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: false },
                },
                snapshot_gender_female: {
                    type: "radio",
                    label: "Gender",
                    value: false,
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: false },
                },
                snapshot_gender_male: {
                    type: "radio",
                    label: "Gender",
                    value: false,
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: false },
                },
                dorm_data: {
                    type: "select",
                    label: "Select Dormitory *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                    options: [],
                },
                class_data: {
                    type: "select",
                    label: "Select Year *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                    options: [],
                },
            },

            update_manager_password: {
                current_password: {
                    type: "text",
                    label: "Current Password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                new_password: {
                    type: "password",
                    label: "New Password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    // onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, min_length: 8, password: true },
                },

                confirm_new_password: {
                    type: "password",
                    label: "Confirm New Password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    // onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, min_length: 8, password: true },
                },
                user_uuid: {
                    type: "text",
                    label: "user_uuid",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },
            },
            role_id: "",
            default_permission_data: {},
            sanphot_male: " ",
            sanphot_female: " ",
            sanphot_both: " ",
            sanphot_male: " ",
            dor_data: "",
            cla_data: ""
        };
    }

    componentDidMount() {
        this.GetSchoolManagerById();
        this.GetAllDormitoryStudent()
        this.GetAllClassesList()
    }

    GetSchoolManagerById() {
        this.props.onGetSchoolManagerById(this.props.user_uuid).then((response) => {
            if (response.success === true) {
                let manager_data = response.data;
                console.log("edit_manager_permission", manager_data);
                const update_manager_edit_form = { ...this.state.manager_edit_form };
                const update_account_setting = { ...this.state.update_manager_password }
                let userID = manager_data.user_id;

                this.props.onGetManagerPermissonDetailsById(response.data.user_id).then((response) => {
                    if (response.success === true) {
                        let permission_res = response.data;
                        const update_manager_edit_form = { ...this.state.manager_edit_form };
                        let permission_data = {
                            // activate_deactivate_host: permission_res?.activate_deactivate_host,
                            // add_dorm_comment: arrayData[key]?.roll_perm.add_dorm_comment,
                            
                            //bss_role_perm_class_dorms:permission_res?.bss_role_perm_class_dorms
                            
                            //created_by:permission_res?.roll_perm.created_by
                            //created_date:permission_res?.roll_perm.created_date
                            
                            // delete_class: permission_res?.roll_perm.delete_class,
                            // delete_students: permission_res?.delete_students,
                            // deleted_by: permission_res?.deleted_by,
                            // deleted_date: permission_res?.deleted_date,
                            // dis_male_female_shadow_snapshot: permission_res?.dis_male_female_shadow_snapshot,
                            // dis_stu_avatar_onsnapshot:permission_res?.dis_stu_avatar_onsnapshot,
                            
                            
                            
                            // parents_approval_host: permission_res?.parents_approval_host,
                            
                            // require_stu_access_pin: permission_res?.require_stu_access_pin,
                            // role_id: permission_res?.role_id,
                            // role_permission_id: permission_res?.role_permission_id,
                            // role_permission_uuid: permission_res?.role_permission_uuid,
                            
                            // snapshot_gender_both: permission_res?.snapshot_gender_both,
                            // snapshot_gender_female: permission_res?.snapshot_gender_female,
                            // snapshot_gender_male: permission_res?.snapshot_gender_male,
                            // student_gender_all: permission_res?.student_gender_all,
                            // student_gender_female: permission_res?.student_gender_female,
                            // student_gender_male: permission_res?.student_gender_male,
                            // student_kiosk_mode: permission_res?.student_kiosk_mode,
                            
                            // updated_by: permission_res?.roll_perm.updated_by,
                            // updated_date: permission_res?.roll_perm.updated_date,
                            
                            
                            // view_dorm_comment: permission_res?.view_dorm_comment,

                            add_permission: permission_res?.add_permission,

                            view_weekend_leave: permission_res?.view_weekend_leave,
                            add_weekend_leave: permission_res?.add_weekend_leave,
                            edit_weekend_leave: permission_res?.edit_weekend_leave,

                            view_medical_comment: permission_res?.view_medical_comment,
                            add_medical_comment: permission_res?.add_medical_comment,
                            add_pastoral_comment: permission_res?.add_pastoral_comment,
                            view_pastoral_comment: permission_res?.view_pastoral_comment,
                            
                            view_host: permission_res?.view_host,
                            add_host: permission_res?.add_host,
                            edit_host: permission_res?.edit_host,

                            // Web Permission =================================
                            
                            add_class: permission_res?.add_class,
                            edit_class: permission_res?.edit_class,
                            view_class: permission_res?.view_class,
                            activate_deactivate_class: permission_res?.activate_deactivate_class,
                            
                            add_dormitory: permission_res?.add_dormitory,
                            edit_dormitory: permission_res?.edit_dormitory,
                            view_dormitory: permission_res?.view_dormitory,
                            activate_deactivate_dormitory: permission_res?.activate_deactivate_dormitory,
                            
                            view_school_profile: permission_res?.view_school_profile,
                            edit_school_profile: permission_res?.edit_school_profile,
                            
                            add_user: permission_res?.add_user,
                            edit_user: permission_res?.edit_user,
                            view_user: permission_res?.view_user,
                            activate_deactivate_user: permission_res?.activate_deactivate_user,
                            
                            edit_permission: permission_res?.edit_permission,
                            view_permission: permission_res?.view_permission,
                            add_role: permission_res?.add_role,
                            edit_role: permission_res?.edit_role,
                            add_duplicate_role: permission_res?.add_duplicate_role,
                            deactivate_role: permission_res?.deactivate_role,
                            delete_role: permission_res?.delete_role,
                            
                            view_snapshot: permission_res?.view_snapshot,
                            view_activities: permission_res?.view_activities,
                            
                            // Students Permissions =================================
                            
                            view_student: permission_res?.view_student,
                            activate_deactivate_student: permission_res?.activate_deactivate_student,
                            edit_student: permission_res?.edit_student,
                            create_tempid: permission_res?.create_tempid,
                            delete_student: permission_res?.delete_student,

                            add_student: permission_res?.add_student,

                            view_allergic_students: permission_res?.view_allergic_students,
                            add_allergic_students: permission_res?.add_allergic_students,
                            edit_allergic_students: permission_res?.edit_allergic_students,
                            delete_allergic_students: permission_res?.delete_allergic_students,

                            add_flag_students: permission_res?.add_flag_students,
                            view_flag_students: permission_res?.view_flag_students,
                            edit_flag_students: permission_res?.edit_flag_students,
                            add_grounded_students: permission_res?.add_grounded_students,
                            delete_flag_students: permission_res?.delete_flag_students,

                            deactivate_flag_students: permission_res?.deactivate_flag_students,
                            deactivate_grounded_students: permission_res?.deactivate_grounded_students,
                            delete_grounded_students: permission_res?.delete_grounded_students,
                            edit_grounded_students: permission_res?.edit_grounded_students,
                            view_grounded_students: permission_res?.view_grounded_students,

                            view_import_students: permission_res?.view_import_students,

                            view_duplicate_students: permission_res?.view_duplicate_students,

                            view_change_dormitory: permission_res?.view_change_dormitory,

                            // Setting Permissions =================================

                            view_attendance_report: permission_res?.view_attendance_report,

                            view_message: permission_res?.view_message,
                            edit_message: permission_res?.edit_message,

                            view_custom_links: permission_res?.view_custom_links,

                            view_rollcall_session_time: permission_res?.view_rollcall_session_time,

                            view_rollover_students: permission_res?.view_rollover_students,

                            view_oncampus_details: permission_res?.view_oncampus_details,

                            view_calendar: permission_res?.view_calendar,

                            // Leave Permissions =================================

                            view_boarder_leaves: permission_res?.view_boarder_leaves,
                            add_boarder_leaves: permission_res?.add_boarder_leaves,
                            edit_boarder_leaves: permission_res?.edit_boarder_leaves,

                            view_adhoc_leave: permission_res?.view_adhoc_leave,
                            add_adhoc_leave: permission_res?.add_adhoc_leave,
                            edit_adhoc_leave: permission_res?.edit_adhoc_leave,

                            view_reports: permission_res?.view_reports,

                            view_travel_mode: permission_res?.view_travel_mode,
                            add_travel_mode: permission_res?.add_travel_mode,
                            edit_travel_mode: permission_res?.edit_travel_mode,
                            delete_travel_mode: permission_res?.delete_travel_mode,
                            activate_deactivate_travel_mode: permission_res?.activate_deactivate_travel_mode,

                            view_rejection_reason: permission_res?.view_rejection_reason,
                            add_rejection_reason: permission_res?.add_rejection_reason,
                            edit_rejection_reason: permission_res?.edit_rejection_reason,
                            delete_rejection_reason: permission_res?.delete_rejection_reason,
                            activate_deactivate_rejection_reason: permission_res?.activate_deactivate_rejection_reason,

                            // dorm_data: permission_res?.bss_role_perm_class_dorms.filter(item => item.roll_perm_dorm)
                            //     .map(item => ({
                            //         dormitory_name: item.roll_perm_dorm.dormitory_name,
                            //         dormitory_id: item.roll_perm_dorm.dormitory_id
                            //     })
                            //     ),
                            // class_data: permission_res?.bss_role_perm_class_dorms.filter(item => item.roll_perm_class)
                            //     .map(item => ({
                            //         class_name: item.roll_perm_class.class_name,
                            //         class_id: item.roll_perm_class.class_id
                            //     })
                            //     )


                        }

                        const classes = [];
                        const dormitoryes = []
                        permission_res?.perm_class_dorm.forEach(element => {
                            if (element?.permi_dorm_data) {
                                dormitoryes.push({
                                    value: element.permi_dorm_data.dormitory_id,
                                    label: element.permi_dorm_data.dormitory_name
                                })
                            }
                            if (element?.permi_class_data) {
                                classes.push({
                                    value: element.permi_class_data.class_id,
                                    label: element.permi_class_data.class_name
                                })
                            }

                        });
                        update_manager_edit_form.dorm_data.value = dormitoryes;
                        update_manager_edit_form.class_data.value = classes;
                        update_manager_edit_form["snapshot_gender_female"].value = permission_res?.snapshot_gender_female;
                        update_manager_edit_form["snapshot_gender_male"].value = permission_res?.snapshot_gender_male;
                        update_manager_edit_form["snapshot_gender_both"].value = permission_res?.snapshot_gender_both;
                        this.setState({ manager_edit_form: update_manager_edit_form });
                        this.setState({ default_permission_data: permission_data })

                        this.setState({ sanphot_both: permission_res?.snapshot_gender_both })
                        this.setState({ sanphot_male: permission_res?.snapshot_gender_male })
                        this.setState({ sanphot_female: permission_res?.snapshot_gender_female })
                        this.setState({ dor_data: dormitoryes })
                        this.setState({ cla_data: classes })

                    } else {
                    }
                });

                let userUUID = manager_data.user_uuid;
                localStorage.setItem('user_uuid', JSON.stringify(userUUID));
                localStorage.setItem('user_id', JSON.stringify(userID));

                for (let key in update_manager_edit_form) {
                    let form_element = update_manager_edit_form[key];
                    if (manager_data[key]) {
                        if (manager_data.avatar !== null && manager_data.avatar !== '') {
                            update_manager_edit_form['avatar'].thumbnail_image = actionType.environment_url + '/' + manager_data.avatar;
                            update_manager_edit_form['avatar'].value = manager_data.avatar;
                        }
                        update_manager_edit_form["user_id"].value = manager_data.user_id;
                        update_manager_edit_form["first_name"].value = manager_data.first_name;
                        update_manager_edit_form["last_name"].value = manager_data.last_name;
                        update_manager_edit_form["email"].value = manager_data.email;
                        update_manager_edit_form["contact_one"].value = manager_data.contact_one;
                        update_manager_edit_form["email"].value = manager_data.email;
                        update_manager_edit_form["comment"].value = manager_data.comment;
                        update_manager_edit_form["designation"].value = manager_data.designation;
                        update_manager_edit_form["user_uuid"].value = manager_data.user_uuid;
                        update_manager_edit_form["is_user_activate"].value = manager_data.is_user_activate;
                        update_manager_edit_form["last_archived_date1"].value = moment(manager_data.last_archived_date).format("MMMM Do YYYY");
                        update_manager_edit_form["last_archived_date"].value = manager_data.last_archived_date;
                        update_manager_edit_form["archived_note"].value = manager_data.archived_note;
                        update_manager_edit_form["created_date1"].value = moment(manager_data.created_date).format("MMMM Do YYYY");
                        update_manager_edit_form["created_date"].value = manager_data.created_date;
                        // update_manager_edit_form["role_id"].value = {value : manager_data.role_id};
                        update_manager_edit_form[key] = form_element;
                    }
                }
                update_account_setting['user_uuid'].value = response.data.user_uuid;
                update_account_setting['current_password'].value = response.data.password;
                const selectedRoleID = manager_data.role_id;
                this.setState({ role_from_api: manager_data.role_id });

                this.setState({ update_manager_password: update_account_setting })
                this.setState({ manager_edit_form: update_manager_edit_form });

                this.GetRoleAndPermissionList(selectedRoleID);
            } else {
            }
        });
    }

    GetRoleAndPermissionList = async (selectedRoleID) => {
        this.props.onGetRoleAndPermissionList().then((response) => {
            if (response.success === true) {
                const arrayData = response.data;
                let country_level = [];
                const update_manager_edit_form = { ...this.state.manager_edit_form };
                for (let key in arrayData) {
                    country_level.push({
                        label: arrayData[key]?.role_name,
                        value: arrayData[key]?.role_id,
                        permission_data: {
                            // activate_deactivate_host: arrayData[key]?.roll_perm.activate_deactivate_host,
                            // add_dorm_comment: arrayData[key]?.roll_perm.add_dorm_comment,
                            //bss_role_perm_class_dorms:arrayData[key]?.roll_perm.bss_role_perm_class_dorms
                            
                            //created_by:arrayData[key]?.roll_perm.created_by
                            //created_date:arrayData[key]?.roll_perm.created_date
                            
                            // delete_class: arrayData[key]?.roll_perm.delete_class,
                            // delete_students: arrayData[key]?.roll_perm.delete_students,
                            // deleted_by: arrayData[key]?.roll_perm.deleted_by,
                            // deleted_date: arrayData[key]?.roll_perm.deleted_date,
                            // dis_male_female_shadow_snapshot: arrayData[key]?.roll_perm.dis_male_female_shadow_snapshot,
                            // dis_stu_avatar_onsnapshot: arrayData[key]?.roll_perm.dis_stu_avatar_onsnapshot,
                            
                            
                            // parents_approval_host: arrayData[key]?.roll_perm.parents_approval_host,
                            
                            // require_stu_access_pin: arrayData[key]?.roll_perm.require_stu_access_pin,
                            // role_id: arrayData[key]?.roll_perm.role_id,
                            // role_permission_id: arrayData[key]?.roll_perm.role_permission_id,
                            // role_permission_uuid: arrayData[key]?.roll_perm.role_permission_uuid,
                            
                            // snapshot_gender_both: arrayData[key]?.roll_perm.snapshot_gender_both,
                            // snapshot_gender_female: arrayData[key]?.roll_perm.snapshot_gender_female,
                            // snapshot_gender_male: arrayData[key]?.roll_perm.snapshot_gender_male,
                            // student_gender_all: arrayData[key]?.roll_perm.student_gender_all,
                            // student_gender_female: arrayData[key]?.roll_perm.student_gender_female,
                            // student_gender_male: arrayData[key]?.roll_perm.student_gender_male,
                            // student_kiosk_mode: arrayData[key]?.roll_perm.student_kiosk_mode,
                            
                            // updated_by: arrayData[key]?.roll_perm.updated_by,
                            // updated_date: arrayData[key]?.roll_perm.updated_date,
                            
                            // view_dorm_comment: arrayData[key]?.roll_perm.view_dorm_comment,
                            add_permission: arrayData[key]?.roll_perm.add_permission,

                            view_weekend_leave: arrayData[key]?.roll_perm.view_weekend_leave,
                            add_weekend_leave: arrayData[key]?.roll_perm.add_weekend_leave,
                            edit_weekend_leave: arrayData[key]?.roll_perm.edit_weekend_leave,
                            
                            view_medical_comment: arrayData[key]?.roll_perm.view_medical_comment,
                            add_medical_comment: arrayData[key]?.roll_perm.add_medical_comment,
                            view_pastoral_comment: arrayData[key]?.roll_perm.view_pastoral_comment,
                            add_pastoral_comment: arrayData[key]?.roll_perm.add_pastoral_comment,
                            
                            view_host: arrayData[key]?.roll_perm.view_host,
                            add_host: arrayData[key]?.roll_perm.add_host,
                            edit_host: arrayData[key]?.roll_perm.edit_host,
                            
                            // Web Permission =================================
                            
                            add_class: arrayData[key]?.roll_perm.add_class,
                            edit_class: arrayData[key]?.roll_perm.edit_class,
                            view_class: arrayData[key]?.roll_perm.view_class,
                            activate_deactivate_class: arrayData[key]?.roll_perm.activate_deactivate_class,
                            
                            add_dormitory: arrayData[key]?.roll_perm.add_dormitory,
                            edit_dormitory: arrayData[key]?.roll_perm.edit_dormitory,
                            view_dormitory: arrayData[key]?.roll_perm.view_dormitory,
                            activate_deactivate_dormitory: arrayData[key]?.roll_perm.activate_deactivate_dormitory,
                            
                            view_school_profile: arrayData[key]?.roll_perm.view_school_profile,
                            edit_school_profile: arrayData[key]?.roll_perm.edit_school_profile,

                            add_user: arrayData[key]?.roll_perm.add_user,
                            edit_user: arrayData[key]?.roll_perm.edit_user,
                            view_user: arrayData[key]?.roll_perm.view_user,
                            activate_deactivate_user: arrayData[key]?.roll_perm.activate_deactivate_user,

                            edit_permission: arrayData[key]?.roll_perm.edit_permission,
                            view_permission: arrayData[key]?.roll_perm.view_permission,
                            add_role: arrayData[key]?.roll_perm.add_role,
                            edit_role: arrayData[key]?.roll_perm.edit_role,
                            add_duplicate_role: arrayData[key]?.roll_perm.add_duplicate_role,
                            deactivate_role: arrayData[key].roll_perm.deactivate_role,
                            delete_role: arrayData[key].roll_perm.delete_role,
                            
                            view_snapshot: arrayData[key]?.roll_perm.view_snapshot,
                            
                            view_activities: arrayData[key].roll_perm.view_activities,
                            
                            // Students Permissions =================================
                            view_student: arrayData[key]?.roll_perm.view_student,
                            activate_deactivate_student: arrayData[key]?.roll_perm.activate_deactivate_student,
                            edit_student: arrayData[key]?.roll_perm.edit_student,
                            create_tempid: arrayData[key]?.roll_perm.create_tempid,
                            delete_student: arrayData[key]?.roll_perm.delete_student,
                            
                            add_student: arrayData[key]?.roll_perm.add_student,
                            
                            view_allergic_students: arrayData[key]?.roll_perm.view_allergic_students,
                            add_allergic_students: arrayData[key]?.roll_perm.add_allergic_students,
                            edit_allergic_students: arrayData[key]?.roll_perm.edit_allergic_students,
                            delete_allergic_students: arrayData[key]?.roll_perm.delete_allergic_students,
                            
                            add_flag_students: arrayData[key]?.roll_perm.add_flag_students,
                            view_flag_students: arrayData[key]?.roll_perm.view_flag_students,
                            edit_flag_students: arrayData[key]?.roll_perm.edit_flag_students,
                            delete_flag_students: arrayData[key]?.roll_perm.delete_flag_students,
                            // deactivate_flag_students: arrayData[key]?.roll_perm.deactivate_flag_students,

                            add_grounded_students: arrayData[key]?.roll_perm.add_grounded_students,
                            view_grounded_students: arrayData[key]?.roll_perm.view_grounded_students,
                            edit_grounded_students: arrayData[key]?.roll_perm.edit_grounded_students,
                            delete_grounded_students: arrayData[key]?.roll_perm.delete_grounded_students,
                            // deactivate_grounded_students: arrayData[key]?.roll_perm.deactivate_grounded_students,
                            
                            view_import_students: arrayData[key]?.roll_perm.view_import_students,
                            
                            view_duplicate_students: arrayData[key]?.roll_perm.view_duplicate_students,

                            view_change_dormitory: arrayData[key]?.roll_perm.view_change_dormitory,
                            
                            // Setting Permissions =================================

                            view_attendance_report: arrayData[key]?.roll_perm.view_attendance_report,

                            edit_message: arrayData[key]?.roll_perm.edit_message,
                            view_message: arrayData[key]?.roll_perm.view_message,

                            view_custom_links: arrayData[key]?.roll_perm.view_custom_links,

                            view_rollover_students: arrayData[key]?.roll_perm.view_rollover_students,

                            view_rollcall_session_time: arrayData[key]?.roll_perm.view_rollcall_session_time,

                            view_oncampus_details: arrayData[key]?.roll_perm.view_oncampus_details,

                            view_calendar: arrayData[key]?.roll_perm.view_calendar,
                            add_calendar: arrayData[key]?.roll_perm.add_calendar,
                            edit_calendar: arrayData[key]?.roll_perm.edit_calendar,

                            // Leave Permissions =================================

                            view_boarder_leaves: arrayData[key]?.roll_perm.view_boarder_leaves,
                            add_boarder_leaves: arrayData[key]?.roll_perm.add_boarder_leaves,
                            edit_boarder_leaves: arrayData[key]?.roll_perm.edit_boarder_leaves,

                            view_adhoc_leave: arrayData[key]?.roll_perm.view_adhoc_leave,
                            add_adhoc_leave: arrayData[key]?.roll_perm.add_adhoc_leave,
                            edit_adhoc_leave: arrayData[key]?.roll_perm.edit_adhoc_leave,

                            view_reports: arrayData[key]?.roll_perm.view_reports,

                            view_travel_mode: arrayData[key]?.roll_perm.view_travel_mode,
                            add_travel_mode: arrayData[key]?.roll_perm.add_travel_mode,
                            edit_travel_mode: arrayData[key]?.roll_perm.edit_travel_mode,
                            delete_travel_mode: arrayData[key]?.roll_perm.delete_travel_mode,
                            activate_deactivate_travel_mode: arrayData[key]?.roll_perm.activate_deactivate_travel_mode,

                            view_rejection_reason: arrayData[key]?.roll_perm.view_rejection_reason,
                            add_rejection_reason: arrayData[key]?.roll_perm.add_rejection_reason,
                            edit_rejection_reason: arrayData[key]?.roll_perm.edit_rejection_reason,
                            delete_rejection_reason: arrayData[key]?.roll_perm.delete_rejection_reason,
                            activate_deactivate_rejection_reason: arrayData[key]?.roll_perm.activate_deactivate_rejection_reason,

                            // dorm_data: arrayData[key]?.roll_perm.bss_role_perm_class_dorms.filter(item => item.roll_perm_dorm)
                            //     .map(item => ({
                            //         dormitory_name: item.roll_perm_dorm.dormitory_name,
                            //         dormitory_id: item.roll_perm_dorm.dormitory_id
                            //     })
                            //     ),
                            // class_data: arrayData[key]?.roll_perm.bss_role_perm_class_dorms.filter(item => item.roll_perm_class)
                            //     .map(item => ({
                            //         class_name: item.roll_perm_class.class_name,
                            //         class_id: item.roll_perm_class.class_id
                            //     })
                            //     )
                        }
                    })
                }
                update_manager_edit_form.role_id.options = country_level;
                update_manager_edit_form["role_id"].value = country_level.filter(item => item.value === selectedRoleID)[0];
                this.setState({ manager_edit_form: update_manager_edit_form });
                this.setState({ role_id: selectedRoleID })
            }
            else {
            }
        });
    }



    GetAllDormitoryStudent = async (form_data) => {
        this.props.onGetAllDormitoryStudent().then((response) => {
            let country_level = [];
            country_level.push({
                label: "Select All",
                value: "all"
            })
            const update_dorm_data = this.state.manager_edit_form;
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    if (arrayData[key].dormitory_status === true) {
                        country_level.push({
                            label: arrayData[key].dormitory_name,
                            value: arrayData[key].dormitory_id,
                        });
                    }
                }
            }
            update_dorm_data["dorm_data"].options = country_level;
            this.setState({ manager_edit_form: update_dorm_data })
        });
    }
    GetAllClassesList = async (form_data) => {
        this.props.onGetAllClassesList().then((response) => {
            let country_level = [];
            country_level.push({
                label: "Select All",
                value: "all"
            })
            const update_class_data = this.state.manager_edit_form;
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    if (arrayData[key].is_class_activate === true) {
                        country_level.push({
                            label: arrayData[key].class_name,
                            value: arrayData[key].class_id,
                        });
                    }
                }
            }
            update_class_data["class_data"].options = country_level;
            this.setState({ manager_edit_form: update_class_data })
        });
    }

    inputChangeHandlerimage = blob => {
        const src = window.URL.createObjectURL(blob);
        let url = URL.createObjectURL(blob);
        let file = new File([blob], "image.png", { lastModified: new Date().getTime(), type: blob.type })
        const update_manager_edit_form = { ...this.state.manager_edit_form };
        const form_element = { ...update_manager_edit_form["avatar"] };
        form_element.thumbnail_image = url;
        form_element.value = file;
        update_manager_edit_form["avatar"] = form_element;
        this.setState({ manager_edit_form: update_manager_edit_form });
        this.setState({ OpenImageModal: !this.state.OpenImageModal })
    };


    handleChangeRole(data, identifier) {
        const update_manager_edit_form = { ...this.state.manager_edit_form };
        update_manager_edit_form['role_id'].value = data;
        this.setState({ manager_edit_form: update_manager_edit_form })
    }

    inputChangeManager(event, identifier) {
        const update_manager_edit_form = { ...this.state.manager_edit_form };
        const form_element = { ...update_manager_edit_form[identifier] };
        if (identifier === "contact_one") {
            form_element.value = event;
        } else {
            form_element.value = event.target.value;
        }
        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;
        update_manager_edit_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_manager_edit_form) {
            is_form_valid =
                update_manager_edit_form[identifier].valid && is_form_valid;
        }
        this.setState({
            manager_edit_form: update_manager_edit_form,
            is_form_valid: is_form_valid,
        });
    }

    handleChange(data, identifier, type) {
        const update_manager_edit_form = { ...this.state.manager_edit_form };
        update_manager_edit_form[identifier].value = data.value;
        this.setState({ manager_edit_form: update_manager_edit_form });
    }

    inputChangeHandler(event, identifier) {
        const update_permission_add_form = { ...this.state.permission_edit_form };
        const form_element = { ...update_permission_add_form[identifier] };
        if (form_element.type === "checkbox") {
            form_element.value = event.target.checked;
        } else {
            form_element.value = event.target.value;
        }
        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;
        update_permission_add_form[identifier] = form_element;
        this.setState({ permission_edit_form: update_permission_add_form });
    }

    inputChangeHandlerSnapshot(event, identifier) {
        const update_manager_edit_form = { ...this.state.manager_edit_form };
        if (identifier === "snapshot_gender_female") {
            update_manager_edit_form['snapshot_gender_both'].value = false;
            update_manager_edit_form['snapshot_gender_male'].value = false;
            update_manager_edit_form['snapshot_gender_female'].value = true;
        } else if (identifier === "snapshot_gender_male") {
            update_manager_edit_form['snapshot_gender_both'].value = false;
            update_manager_edit_form['snapshot_gender_male'].value = true;
            update_manager_edit_form['snapshot_gender_female'].value = false;
        } if (identifier === "snapshot_gender_both") {
            update_manager_edit_form['snapshot_gender_both'].value = true;
            update_manager_edit_form['snapshot_gender_female'].value = false;
            update_manager_edit_form['snapshot_gender_male'].value = false;
        }
        this.setState({ manager_edit_form: update_manager_edit_form });
    }

    handleChangeClass(data, identifier) {
        const update_manager_edit_form = { ...this.state.manager_edit_form }
        if (data.length === 0 || data[data.length - 1].value === "all") {
            update_manager_edit_form[identifier].value = update_manager_edit_form.class_data.options.filter(val => val.value !== "all")
        } else {
            update_manager_edit_form[identifier].value = data;
        }
        this.setState({ manager_edit_form: update_manager_edit_form })
    }

    handleChangeDorm(data, identifier) {
        const update_manager_edit_form = { ...this.state.manager_edit_form }
        if (data.length === 0 || data[data.length - 1].value === "all") {
            update_manager_edit_form[identifier].value = update_manager_edit_form.dorm_data.options.filter(val => val.value !== "all")
        } else {
            update_manager_edit_form[identifier].value = data;
        }
        this.setState({ manager_edit_form: update_manager_edit_form })
    }





    SubmitHandler = async (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_manager_edit_form = this.state.manager_edit_form;
        for (let key in update_manager_edit_form) {
            if (key === 'permission_data') {
                update_manager_edit_form[key].value = this.state.default_permission_data;
            } else {
                let form_element = update_manager_edit_form[key];
                let validate_element = Validations(form_element);
                is_form_valid = validate_element.valid === false ? false : is_form_valid;
                form_element.onBlur_out = true;
                form_element.valid = validate_element.valid;
                form_element.error_msg = validate_element.error_msg;
                update_manager_edit_form[key] = form_element;
            }
        }
        this.setState({ manager_edit_form: update_manager_edit_form });

        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.manager_edit_form) {
                if (key === 'permission_data') {
                    const snapshot_value = this.state.sanphot_both === this.state.manager_edit_form.snapshot_gender_both.value && this.state.sanphot_female === this.state.manager_edit_form.snapshot_gender_female.value && this.state.sanphot_male === this.state.manager_edit_form.snapshot_gender_male.value ? true : false;
                    const role_value = this.state.role_id === this.state.manager_edit_form['role_id'].value.value ? true : false;
                    const dormitory_data = this.state.manager_edit_form.dorm_data.value;
                    const class_data = this.state.manager_edit_form.class_data.value;
                    const dorm_value = JSON.stringify(dormitory_data) === JSON.stringify(this.state.dor_data);
                    const class_value = JSON.stringify(class_data) === JSON.stringify(this.state.cla_data);

                    if (snapshot_value === true && role_value === true && dorm_value === true && class_value === true) {
                        form_data['permission_data'] = "";
                    } else {
                        let permissions_data = this.state.manager_edit_form['permission_data'].value;
                        let classes = this.state.manager_edit_form.class_data.value;
                        let update_class_data = classes.map((item) => {
                            return ({
                                class_id: item.value,
                                class_name: item.label
                            })
                        })
                        let dorms = this.state.manager_edit_form.dorm_data.value;
                        let update_dormitory_data = dorms.map((item) => {
                            return ({
                                dormitory_id: item.value,
                                dormitory_name: item.label,
                            })
                        })
                        permissions_data.dorm_data = update_dormitory_data;
                        permissions_data.class_data = update_class_data;
                        permissions_data.snapshot_gender_both = this.state.manager_edit_form.snapshot_gender_both.value;
                        permissions_data.snapshot_gender_female = this.state.manager_edit_form.snapshot_gender_female.value;
                        permissions_data.snapshot_gender_male = this.state.manager_edit_form.snapshot_gender_male.value;
                        form_data['permission_data'] = JSON.stringify(permissions_data);
                    }
                } else if (key === 'role_id') {
                    let role_data = this.state.manager_edit_form['role_id'].value
                    form_data['role_id'] = role_data.value;
                } else {
                    form_data[key] = this.state.manager_edit_form[key].value;
                }
            }
            this.props.onUpdateSchooManager(form_data).then((response) => {
                if (response.success === true) {
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: `/${school_code}/manager-list`,
                        });
                    }, 500);
                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            });
        }
    };


    toggleShow(identifier) {
        if (identifier === "current_password") { this.setState({ current_password_hidden: !this.state.current_password_hidden }) };
        if (identifier === "new_password") { this.setState({ new_password__hidden: !this.state.new_password__hidden }) };
        if (identifier === "confirm_new_password") { this.setState({ confirm_new_password__hidden: !this.state.confirm_new_password__hidden }) };
    }

    passwordChangeHandler(event, identifier) {
        const update_account_setting = { ...this.state.update_manager_password }
        const form_element = { ...update_account_setting[identifier] };
        form_element.value = event.target.value;
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;
        update_account_setting[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_account_setting) {
            is_form_valid = update_account_setting[identifier].valid && is_form_valid;
        }
        this.setState({
            update_manager_password: update_account_setting,
        })
    }

    SubmitManagerPassword = async (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_account_setting = { ...this.state.update_manager_password }
        for (let key in update_account_setting) {
            let form_element = update_account_setting[key];
            let validate_element = Validations(form_element);
            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;
            update_account_setting[key] = form_element;
        }
        this.setState({ update_account_setting: update_account_setting });
        if (is_form_valid) {
            const update_account_setting = { ...this.state.update_manager_password }
            const form_data = {};
            form_data['password'] = update_account_setting['new_password'].value
            form_data['confirm_password'] = update_account_setting['confirm_new_password'].value
            form_data['user_uuid'] = update_account_setting['user_uuid'].value
            this.props.onUpdateManagerPassword(form_data).then((response) => {
                if (response.success === true) {
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: `/${school_code}/manager-list`,
                        });
                    }, 500);
                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            });

        }
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyPress);
    }

    handleKeyPress = (event) => {
        if (event.keyCode === 27) {
            this.setState({ OpenImageModal: false })
        }
    };


    render() {
        const { manager_edit_form } = this.state;
        console.log("manager_edit_form", manager_edit_form);
        const { update_manager_password } = this.state;

        return (
            <React.Fragment>

                {this.state.OpenImageModal &&
                    <div className="upload-image-outer-div" >
                        <span style={{ float: "right" }} className="pointer m-5" onClick={() => this.setState({ OpenImageModal: !this.state.OpenImageModal })}>
                            <i class="fas fa-times fa-2x" style={{ color: "#ffffff", }}></i>
                        </span>
                    
                        <div className="upload-image-inner-div">
                            <AvatarImageCropper
                                rootStyle={{ borderRadius: "50%" }}
                                cancelBtnStyle={{ display: "none" }}
                                apply={this.inputChangeHandlerimage}
                                className={"image_crop_style"}
                                sliderConStyle={{ margin: "20px", backgroundColor: "none" }}
                                applyBtnStyle={{}}
                                isBack={true}
                            />
                        </div>
                        <div className="text-center mt-10">
                            <span className="text-white font-weight-bold">The image maximum size is 2MB. Please upload png/jpg/jpeg image</span>
                        </div>
                    </div>
                }

                {this.state.isAuthenticated ? (
                    <Navigate to={this.state.redirect_url} />
                ) : null}

                <div
                    id="kt_body"
                    className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed demo h-100"
                >
                    <div className="d-flex flex-column flex-root h-100">
                        <div className="page d-flex flex-row flex-column-fluid">
                            <Sidebar></Sidebar>

                            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                                <TopBar></TopBar>
                                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                    <div className="post d-flex flex-column-fluid" id="kt_post">
                                        <div id="kt_content_container" className="container-fluid">
                                            <ul className="nav nav-tabs home-tabs" id="myTab" role="tablist">
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/manager-list/edit-manager/${this.props.user_uuid}`} className="nav-link active" id="StudentProfile-tab" type="button" aria-controls="home" aria-selected="true">
                                                        Edit Manager
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/manager-list/edit-manager-permission`} className="nav-link" id="LeaveHistory-tab" type="button" aria-controls="process" aria-selected="false">

                                                        Edit Permissions
                                                    </Link>
                                                </li>
                                            </ul>



                                            <div className="school-edit-form ">
                                                <div className="" id="Snapshot">
                                                    <form onSubmit={this.SubmitHandler}>
                                                        <div className="card mb-5 tabs-outer">
                                                            <div className="card-header position-relative py-0 details-bg align-items-center">
                                                                <h3 className="card-title  m-0">
                                                                    Manager Profile
                                                                </h3>
                                                                {manager_edit_form.is_user_activate.value === true ? (
                                                                    <span className="badge badge-light-success px-4 py-2">
                                                                        Active
                                                                    </span>
                                                                ) : (
                                                                    <span className="badge badge-light-danger px-4 py-2">
                                                                        Inactive
                                                                    </span>
                                                                )}
                                                            </div>

                                                            <div className="card-body">
                                                                <div className="row align-items-center">
                                                                    <div className="col-lg-4 ">
                                                                        <div className="row">
                                                                            <div className="col-lg-12">
                                                                                <div className="profile-image-out p-0">
                                                                                    <div className="d-flex flex-column align-items-center">
                                                                                        <img alt="profile" src={manager_edit_form?.avatar?.thumbnail_image} className="img-fluid" />
                                                                                        <div className="flex-column align-items-center">
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-sm btn-outline-primary px-10 rounded-50 mt-2"
                                                                                                onClick={() => this.setState({ OpenImageModal: !this.state.OpenImageModal })}
                                                                                            >
                                                                                                Upload Image
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-lg-8">
                                                                        <div className="row">
                                                                            {manager_edit_form.is_user_activate.value === false ?
                                                                                (
                                                                                    <div className=" col-lg-6 col-xxl-4 fv-row  mb-5">
                                                                                        <label className="fw-bold mb-2">{manager_edit_form.first_name.label}</label>
                                                                                        <input
                                                                                            type={manager_edit_form.first_name.type}
                                                                                            value={manager_edit_form.first_name.value ? manager_edit_form.first_name.value : ""}
                                                                                            className="form-control"
                                                                                            placeholder={manager_edit_form.first_name.placeholder}
                                                                                            onChange={(event) => this.inputChangeManager(event, "first_name")}
                                                                                        />
                                                                                        {!manager_edit_form.first_name.valid && manager_edit_form.first_name.onBlur_out ? (
                                                                                            <div className="error field-error"> {manager_edit_form.first_name.error_msg} </div>
                                                                                        ) : ("")}
                                                                                    </div>
                                                                                ) : (
                                                                                    <div className="col-lg-6 col-xxl-4 fv-row  mb-5">
                                                                                        <label className="fw-bold mb-2">{manager_edit_form.first_name.label}</label>
                                                                                        <input
                                                                                            type={manager_edit_form.first_name.type}
                                                                                            value={manager_edit_form.first_name.value ? manager_edit_form.first_name.value : ""}
                                                                                            className="form-control"
                                                                                            placeholder={manager_edit_form.first_name.placeholder}
                                                                                            onChange={(event) => this.inputChangeManager(event, "first_name")}
                                                                                        />

                                                                                        {!manager_edit_form.first_name.valid && manager_edit_form.first_name.onBlur_out ? (
                                                                                            <div className="error field-error">
                                                                                                {manager_edit_form.first_name.error_msg}
                                                                                            </div>
                                                                                        ) : ("")}
                                                                                    </div>
                                                                                )
                                                                            }

                                                                            {manager_edit_form.is_user_activate.value === false ?
                                                                                (
                                                                                    <div className="col-lg-6 col-xxl-4 fv-row  mb-5">
                                                                                        <label className="fw-bold mb-2"> {manager_edit_form.last_name.label}</label>
                                                                                        <input
                                                                                            type={manager_edit_form.last_name.type}
                                                                                            value={manager_edit_form.last_name.value ? manager_edit_form.last_name.value : ""}
                                                                                            className="form-control"
                                                                                            placeholder={manager_edit_form.last_name.placeholder}
                                                                                            onChange={(event) => this.inputChangeManager(event, "last_name")}
                                                                                        />
                                                                                        {!manager_edit_form.last_name.valid && manager_edit_form.last_name.onBlur_out ? (
                                                                                            <div className="error field-error">
                                                                                                {manager_edit_form.last_name.error_msg}
                                                                                            </div>
                                                                                        ) : ("")}
                                                                                    </div>
                                                                                ) : (
                                                                                    <div className="col-lg-6 col-xxl-4 fv-row  mb-5">
                                                                                        <label className="fw-bold mb-2">{manager_edit_form.last_name.label}</label>
                                                                                        <input
                                                                                            type={manager_edit_form.last_name.type}
                                                                                            value={manager_edit_form.last_name.value ? manager_edit_form.last_name.value : ""}
                                                                                            className="form-control"
                                                                                            placeholder={manager_edit_form.last_name.placeholder}
                                                                                            onChange={(event) => this.inputChangeManager(event, "last_name")}
                                                                                        />
                                                                                        {!manager_edit_form.last_name.valid && manager_edit_form.last_name.onBlur_out ? (
                                                                                            <div className="error field-error">
                                                                                                {manager_edit_form.last_name.error_msg}</div>
                                                                                        ) : ("")}
                                                                                    </div>
                                                                                )
                                                                            }

                                                                            {manager_edit_form.is_user_activate.value === false ?
                                                                                (
                                                                                    <div className="col-lg-6 col-xxl-4 fv-row  mb-5">
                                                                                        <label className="fw-bold mb-2">{manager_edit_form.email.label}</label>
                                                                                        <input
                                                                                            type={manager_edit_form.email.type}
                                                                                            value={manager_edit_form.email.value ? manager_edit_form.email.value : ""}
                                                                                            className="form-control"
                                                                                            placeholder={manager_edit_form.email.placeholder}
                                                                                            onChange={(event) => this.inputChangeManager(event, "email")}
                                                                                            readOnly
                                                                                        />
                                                                                        {!manager_edit_form.email.valid && manager_edit_form.email.onBlur_out ? (
                                                                                            <div className="error field-error">
                                                                                                {manager_edit_form.email.error_msg}
                                                                                            </div>
                                                                                        ) : ("")}
                                                                                    </div>
                                                                                ) : (
                                                                                    <div className="col-lg-6 col-xxl-4 fv-row  mb-5">
                                                                                        <label className="fw-bold mb-2">{manager_edit_form.email.label}</label>
                                                                                        <input
                                                                                            type={manager_edit_form.email.type}
                                                                                            value={manager_edit_form.email.value ? manager_edit_form.email.value : ""}
                                                                                            className="form-control"
                                                                                            placeholder={manager_edit_form.email.placeholder}
                                                                                            onChange={(event) => this.inputChangeManager(event, "email")}
                                                                                            readOnly
                                                                                        />

                                                                                        {!manager_edit_form.email.valid && manager_edit_form.email.onBlur_out ? (
                                                                                            <div className="error field-error">
                                                                                                {manager_edit_form.email.error_msg}
                                                                                            </div>
                                                                                        ) : ("")}
                                                                                    </div>
                                                                                )
                                                                            }

                                                                            {manager_edit_form.is_user_activate.value === false ? (
                                                                                <div className="col-lg-6 col-xxl-4 fv-row  mb-5">
                                                                                    <label className="fw-bold mb-2">{manager_edit_form.contact_one.label}</label>
                                                                                    <ReactPhoneInput
                                                                                        country={"in"}
                                                                                        value={manager_edit_form.contact_one.value ? manager_edit_form.contact_one.value : ""}
                                                                                        onChange={(event) => this.inputChangeManager(event, "contact_one")}
                                                                                        enableLongNumbers={true}
                                                                                    />
                                                                                    {!manager_edit_form.contact_one.valid && manager_edit_form.contact_one.onBlur_out ? (
                                                                                        <div className="error field-error">
                                                                                            {manager_edit_form.contact_one.error_msg}
                                                                                        </div>
                                                                                    ) : ("")}
                                                                                </div>
                                                                            ) : (
                                                                                <div className="col-lg-6 col-xxl-4 fv-row  mb-5">
                                                                                    <label className="fw-bold mb-2">{manager_edit_form.contact_one.label}</label>
                                                                                    <ReactPhoneInput
                                                                                        country={"in"}
                                                                                        value={manager_edit_form.contact_one.value ? manager_edit_form.contact_one.value : ""}
                                                                                        onChange={(event) => this.inputChangeManager(event, "contact_one")}
                                                                                        enableLongNumbers={true}
                                                                                    />
                                                                                    {!manager_edit_form.contact_one.valid && manager_edit_form.contact_one.onBlur_out ? (
                                                                                        <div className="error field-error">
                                                                                            {manager_edit_form.contact_one.error_msg}
                                                                                        </div>
                                                                                    ) : ("")}
                                                                                </div>
                                                                            )}

                                                                            <div className="col-lg-6 col-xxl-4 fv-row  mb-5">
                                                                                <label className="fw-bold mb-2">
                                                                                    {manager_edit_form.designation.label}
                                                                                    <Tooltip title="This is the Boarding staff members actual designation (Position Title) within your school as their employed title reads.  In some cases it will (or can) be the same as “Role.  Role is only unique to this system - the Admin links Role to Permissions. (Permissions can be changed by Admin, at any time.)" placement="top">
                                                                                        <i className="fas fa-question-circle ms-2"></i>
                                                                                    </Tooltip>
                                                                                </label>
                                                                                <input
                                                                                    type={manager_edit_form.designation.type}
                                                                                    className="form-control"
                                                                                    placeholder={manager_edit_form.designation.placeholder}
                                                                                    onChange={(event) => this.inputChangeManager(event, "designation")}
                                                                                    value={manager_edit_form.designation.value ? manager_edit_form.designation.value : ""}
                                                                                />
                                                                            </div>

                                                                            <div className="col-lg-6 col-xxl-4 fv-row  mb-5">
                                                                                <label className="fw-bold mb-2">{manager_edit_form.created_date1.label}</label>
                                                                                <input
                                                                                    type={manager_edit_form.created_date1.type}
                                                                                    className="form-control"
                                                                                    placeholder={manager_edit_form.created_date1.placeholder}
                                                                                    onChange={(event) => this.inputChangeManager(event, "created_date1")}
                                                                                    value={manager_edit_form.created_date1.value ? manager_edit_form.created_date1.value : ""}
                                                                                    readOnly
                                                                                />
                                                                            </div>

                                                                            <div className="col-lg-6 col-xxl-4 fv-row  mb-5">
                                                                                <label className="fw-bold mb-2">
                                                                                    Select Role *
                                                                                    <Tooltip title="If you have created the Roles and they still do not display here, please check if you have added Permissions to those Roles." placement="top">
                                                                                        <i className="fas fa-question-circle ms-2"></i>
                                                                                    </Tooltip>
                                                                                    <Tooltip title="Role” refers to the predefined Roles that Admin has already assigned in this system to match the permissions and access to dormitory information." placement="top">
                                                                                        <i className="fas fa-question-circle ms-2"></i>
                                                                                    </Tooltip>
                                                                                </label>
                                                                                <div className="mt-0">
                                                                                    <div className="checkbox cursor-pointer ">
                                                                                        <Select
                                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                                            options={manager_edit_form.role_id.options}
                                                                                            value={manager_edit_form.role_id.options.filter(item => item.value === manager_edit_form?.role_id?.value?.value)}
                                                                                            onChange={(newValue) => this.handleChangeRole(newValue, "role_id")}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                            {manager_edit_form.is_user_activate.value === false ?
                                                                                (
                                                                                    <div className="col-lg-12 col-xxl-8 fv-row  mb-5">
                                                                                        <label className="fw-bold mb-2">{manager_edit_form.comment.label}</label>
                                                                                        <textarea
                                                                                            type={manager_edit_form.comment.type}
                                                                                            className="form-control autoresizing1"
                                                                                            placeholder={manager_edit_form.comment.placeholder}
                                                                                            onChange={(event) => this.inputChangeManager(event, "comment")}
                                                                                            value={manager_edit_form.comment.value ? manager_edit_form.comment.value : ""}
                                                                                        />
                                                                                        {!manager_edit_form.comment.valid && manager_edit_form.comment.onBlur_out ? (
                                                                                            <div className="error field-error">
                                                                                                {manager_edit_form.comment.error_msg}
                                                                                            </div>
                                                                                        ) : ("")}
                                                                                    </div>
                                                                                ) : (
                                                                                    <div className="col-lg-12 col-xxl-8 fv-row  mb-5">
                                                                                        <label className="fw-bold mb-2">{manager_edit_form.comment.label}</label>
                                                                                        <textarea
                                                                                            type={manager_edit_form.comment.type}
                                                                                            className="form-control autoresizing1"
                                                                                            placeholder={manager_edit_form.comment.placeholder}
                                                                                            onChange={(event) => this.inputChangeManager(event, "comment")}
                                                                                            value={manager_edit_form.comment.value ? manager_edit_form.comment.value : ""}
                                                                                        />
                                                                                        {!manager_edit_form.comment.valid && manager_edit_form.comment.onBlur_out ? (
                                                                                            <div className="error field-error">
                                                                                                {manager_edit_form.comment.error_msg}
                                                                                            </div>
                                                                                        ) : ("")}
                                                                                    </div>
                                                                                )
                                                                            }

                                                                            <input
                                                                                type="hidden"
                                                                                className="form-control"
                                                                                placeholder={manager_edit_form.created_date.placeholder}
                                                                                onChange={(event) => this.inputChangeManager(event, "created_date")}
                                                                                value={manager_edit_form.created_date.value ? manager_edit_form.created_date.value : ""}
                                                                                readOnly
                                                                            />

                                                                            {manager_edit_form.is_user_activate.value === false ?
                                                                                (<input
                                                                                    type="hidden"
                                                                                    className="form-control"
                                                                                    placeholder={manager_edit_form.last_archived_date.placeholder}
                                                                                    onChange={(event) => this.inputChangeManager(event, "last_archived_date")}
                                                                                    value={manager_edit_form.last_archived_date.value ? manager_edit_form.last_archived_date.value : ""}
                                                                                    readOnly
                                                                                />
                                                                                ) : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="card">
                                                            <div className="card-header position-relative py-0 details-bg">
                                                                <h5 className="card-title  m-0">Edit Snapshot Permissions</h5>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="sec-row row">
                                                                            <div className="col-md-2">
                                                                                <label className="fw-bold mb-2">Manage Genders *</label>
                                                                            </div>
                                                                            <div className="col-md-10 d-flex gap-10 align-items-center">


                                                                                <label className="cursor-pointer d-flex align-items-center">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id="gender1"
                                                                                        name="gen"
                                                                                        className="form-check-input me-2 cursor-pointer"
                                                                                        value={manager_edit_form.snapshot_gender_male.value}
                                                                                        checked={manager_edit_form.snapshot_gender_male.value === true ? manager_edit_form.snapshot_gender_male.value === true : null}
                                                                                        onChange={(event) => this.inputChangeHandlerSnapshot(event, "snapshot_gender_male")}
                                                                                    />
                                                                                    Male
                                                                                </label>


                                                                                <label className="cursor-pointer align-items-center">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id="gender2"
                                                                                        name="gen"
                                                                                        className="form-check-input me-2 cursor-pointer"
                                                                                        value={manager_edit_form.snapshot_gender_female.value}
                                                                                        checked={manager_edit_form.snapshot_gender_female.value === true ? manager_edit_form.snapshot_gender_female.value === true : null}
                                                                                        onChange={(event) => this.inputChangeHandlerSnapshot(event, "snapshot_gender_female")}
                                                                                    />
                                                                                    Female
                                                                                </label>


                                                                                <label className="cursor-pointer align-items-center">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id="genderall"
                                                                                        name="gen"
                                                                                        className="form-check-input me-2 cursor-pointer"
                                                                                        value={manager_edit_form.snapshot_gender_both.value}
                                                                                        checked={manager_edit_form.snapshot_gender_both.value === true ? manager_edit_form.snapshot_gender_both.value === true : null}
                                                                                        onChange={(event) => this.inputChangeHandlerSnapshot(event, "snapshot_gender_both")}
                                                                                    />
                                                                                    Both
                                                                                </label>


                                                                            </div>
                                                                        </div>

                                                                        <div className="row mt-5">
                                                                            <div className="col-6">
                                                                                <div className="checkbox-inline">
                                                                                    <label className="fw-bold mb-2">Select Class *</label>
                                                                                    <div className="checkbox cursor-pointer w-100">
                                                                                        <Select
                                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                                            options={manager_edit_form.class_data.options}
                                                                                            value={manager_edit_form.class_data.value}
                                                                                            onChange={(newValue) => this.handleChangeClass(newValue, "class_data")}
                                                                                            isMulti
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <div className="checkbox-inline">
                                                                                    <label className="fw-bold mb-2">Select Dormitory *</label>
                                                                                    <Tooltip title="Whilst engaging with the initial Role setup - ‘Dormitory’  cannot be selected as they have not yet been created. (if you are following the steps correctly) You will return to these two Role Permissions in a few steps time." placement="top">
                                                                                        <i className="fas fa-question-circle ms-2"></i>
                                                                                    </Tooltip>
                                                                                    <div className="checkbox cursor-pointer w-100">
                                                                                        <Select
                                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                                            options={manager_edit_form.dorm_data.options}
                                                                                            value={manager_edit_form.dorm_data.value}
                                                                                            onChange={(newValue) => this.handleChangeDorm(newValue, "dorm_data")}
                                                                                            isMulti
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex justify-content-end mt-5">
                                                                    <div className="d-flex align-items-end">
                                                                        <button type="submit" className="btn btn-sm btn-primary apply-btn min-150px addschool-button">
                                                                            Update Manager
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>


                                                <div className="card mt-5">
                                                    <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_signin_method">
                                                        <h2 className="card-title m-0">Change Password</h2>
                                                    </div>

                                                    <form onSubmit={this.SubmitManagerPassword}>
                                                        <div className="card-body">
                                                            <div className="row mb-1">
                                                                <div className="col-lg-4">
                                                                    <label className="fw-bold mb-2">{update_manager_password.current_password.label} </label>
                                                                    <div className="position-relative">
                                                                        <input
                                                                            type={this.state.current_password_hidden ? 'password' : 'text'}
                                                                            value={update_manager_password.current_password.value ? update_manager_password.current_password.value : ""}
                                                                            className="form-control"
                                                                        />
                                                                        <span onClick={() => this.toggleShow("current_password")} className="btn btn-icon position-absolute translate-middle top-50 end-0" data-kt-password-meter-control="visibility">
                                                                            {this.state.current_password_hidden ? <i className="bi bi-eye-slash fs-2"></i> : <i className="bi bi-eye fs-2"></i>}
                                                                        </span>
                                                                    </div>
                                                                    {!update_manager_password.current_password.valid && update_manager_password.current_password.onBlur_out ? (
                                                                        <div className="error field-error">{update_manager_password.current_password.error_msg}</div>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </div>

                                                                <div className="col-lg-4">
                                                                    <label className="fw-bold mb-2">{update_manager_password.new_password.label} </label>
                                                                    <div className="position-relative">
                                                                        <input
                                                                            type={this.state.new_password__hidden ? 'password' : 'text'}
                                                                            value={update_manager_password.new_password.value ? update_manager_password.new_password.value : ""}
                                                                            className="form-control"
                                                                            placeholder={update_manager_password.new_password.placeholder}
                                                                            onChange={(event) => this.passwordChangeHandler(event, "new_password")}
                                                                        />
                                                                        <span onClick={() => this.toggleShow("new_password")} className="btn btn-icon position-absolute translate-middle top-50 end-0" data-kt-password-meter-control="visibility">
                                                                            {this.state.new_password__hidden ? <i className="bi bi-eye-slash fs-2"></i> : <i className="bi bi-eye fs-2"></i>}
                                                                        </span>
                                                                    </div>
                                                                    {!update_manager_password.new_password.valid ?
                                                                        (<div className="error field-error">{update_manager_password.new_password.error_msg}</div>)
                                                                        : ("")
                                                                    }
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <label className="fw-bold mb-2">{update_manager_password.confirm_new_password.label} </label>
                                                                    <div className="position-relative">
                                                                        <input
                                                                            type={this.state.confirm_new_password__hidden ? 'password' : 'text'}
                                                                            value={update_manager_password.confirm_new_password.value ? update_manager_password.confirm_new_password.value : ""}
                                                                            className="form-control"
                                                                            placeholder={update_manager_password.confirm_new_password.placeholder}
                                                                            onChange={(event) => this.passwordChangeHandler(event, "confirm_new_password")}
                                                                        />
                                                                        <span onClick={() => this.toggleShow("confirm_new_password")} className="btn btn-icon position-absolute translate-middle top-50 end-0" data-kt-password-meter-control="visibility">
                                                                            {this.state.confirm_new_password__hidden ? <i className="bi bi-eye-slash fs-2"></i> : <i className="bi bi-eye fs-2"></i>}
                                                                        </span>
                                                                    </div>
                                                                    {!update_manager_password.confirm_new_password.valid ?
                                                                        (<div className="error field-error">{update_manager_password.confirm_new_password.error_msg}</div>)
                                                                        : ("")
                                                                    }
                                                                </div>
                                                            </div>
                                                            <br></br>
                                                            <div className="d-flex justify-content-end">
                                                                <button id="kt_password_submit" type="submit" className="btn btn-primary  px-6 btn-sm" >Update Password</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <Footer />
                    </div>
                </div>
                {/* </div> */}
            </React.Fragment >
        );
    }
}
function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}

const mapDispatchToProps = {
    onGetManagerPermissonDetailsById: userActions.GetManagerPermissonDetailsById,
    onUpdateManagerPermissions: userActions.UpdateManagerPermissions,
    onUpdateSchooManager: userActions.UpdateSchooManager,
    onGetSchoolManagerById: userActions.GetSchoolManagerById,
    onGetRoleAndPermissionList: userActions.GetRoleAndPermissionList,
    onUpdateManagerPassword: userActions.UpdateManagerPassword,
    onGetAllDormitoryStudent: userActions.GetAllDormitoryStudent,
    onGetAllClassesList: userActions.GetAllClassesList,
    // onGetLoginUserPermissions: userActions.GetLoginUserPermissions
};
export default connect(mapStateToProps, mapDispatchToProps)(ManagerEdit);
