import axios from "axios";
import * as actionType from "../constants/actionTypes";
let qs = require("qs");


/*** CreateDormitories Action ***/
export const CreateDormitoriesStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.CreateDormitories_START,
    };
};
export const CreateDormitoriesSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.CreateDormitories_SUCCESS,
    };
};
export const CreateDormitoriesFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.CreateDormitories_FAIL,
    };
};
export const CreateDormitories = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    console.log("====",user_info);
    console.log("====",user_info.school_code);
 
    return (dispatch) => {
        dispatch(CreateDormitoriesStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/CreateDormitories`,
        })
            .then(function (response) {
                dispatch(CreateDormitoriesSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateDormitoriesFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** GetAllDormitory Action ***/
export const GetAllDormitoryStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllDormitory_START,
    };
};
export const GetAllDormitorySuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllDormitory_SUCCESS,
    };
};
export const GetAllDormitoryFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllDormitory_FAIL,
    };
};
export const GetAllDormitory = (page, limit, sort, order, dormitory_status,search ) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllDormitoryStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllDormitory`,
            params: {
                page: page,
                limit: limit,
                sort: sort,
                order: order,
                search: search,
                dormitory_status:dormitory_status,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllDormitorySuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllDormitoryFail("Something went wrong, Please try again."));
                return error;
            });
    };
};




/*** UpdateDormitory Action ***/

export const UpdateDormitoryStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateDormitory_START,
    };
};
export const UpdateDormitorySuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateDormitory_SUCCESS,
    };
};
export const UpdateDormitoryFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateDormitory_FAIL,
    };
};

export const UpdateDormitory = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(UpdateDormitoryStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/UpdateDormitory`,
        })
            .then(function (response) {
                dispatch(UpdateDormitorySuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateDormitoryFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** GetDormitoryDetailsById Action ***/
export const GetDormitoryDetailsByIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetDormitoryDetailsById_START,
    };
};
export const GetDormitoryDetailsByIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetDormitoryDetailsById_SUCCESS,
    };
};
export const GetDormitoryDetailsByIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetDormitoryDetailsById_FAIL,
    };
};
export const GetDormitoryDetailsById = (dormitory_uuid) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

   
    return (dispatch) => {
        dispatch(GetDormitoryDetailsByIdStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetDormitoryDetailsById`,
            params: {
                dormitory_uuid: dormitory_uuid,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetDormitoryDetailsByIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetDormitoryDetailsByIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};




/*** DeactivateDormitory Action ***/

export const UpdateDormitoryStatusStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateDormitoryStatus_START,
    };
};

export const UpdateDormitoryStatusSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateDormitoryStatus_SUCCESS,
    };
};

export const UpdateDormitoryStatusFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateDormitoryStatus_FAIL,
    };
};

export const UpdateDormitoryStatus = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(UpdateDormitoryStatusStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/UpdateDormitoryStatus`,
        })
            .then(function (response) {
                dispatch(UpdateDormitoryStatusSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateDormitoryStatusFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



 /*** DeleteDormitory Action ***/

 export const DeleteDormitoryStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.DeleteDormitory_START,
    };
};
export const DeleteDormitorySuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.DeleteDormitory_SUCCESS,
    };
};
export const DeleteDormitoryFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.DeleteDormitory_FAIL,
    };
};
export const DeleteDormitory = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(DeleteDormitoryStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "delete",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
                
            },
            url: `${actionType.environment_url}/api/bss-admin/DeleteDormitory`,
        })
            .then(function (response) {
                console.log("resposne", response.data);
                dispatch(DeleteDormitorySuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                console.log("resposne fail", error);
                dispatch(DeleteDormitoryFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetAllDormitory Action ***/
export const GetAllDormitoryListForSnapshotStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllDormitoryListForSnapshot_START,
    };
};
export const GetAllDormitoryListForSnapshotSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllDormitoryListForSnapshot_SUCCESS,
    };
};
export const GetAllDormitoryListForSnapshotFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllDormitoryListForSnapshot_FAIL,
    };
};
export const GetAllDormitoryListForSnapshot = () => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllDormitoryListForSnapshotStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllDormitoryListForSnapshot`,
            params: {
                // page: page,
                // limit: limit,
                // sort: sort,
                // order: order,
                // search: search,
                // dormitory_status:dormitory_status,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllDormitoryListForSnapshotSuccess(response.data));
                console.log("GetAllDormitoryListForSnapshot", response)
                return response.data;
                
            })
            .catch(function (error) {
                dispatch(GetAllDormitoryListForSnapshotFail("Something went wrong, Please try again."));
                return error;
            });
    };
};
