import React, { useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../Constant/sidebar";
import Footer from "../Constant/Footer";
import TopBar from "../Constant/TopBar";
import DeleteConfirmRemove from "../Utility/DeleteConfirmRemove";



const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" :user_info.school_code;

function ReasonList() {
    const [data, setData] = useState(<span className="badge badge-success px-4 py-2 ms-2">Active</span>);
    const [data2, setData2] = useState(<span className="badge badge-success px-4 py-2 ms-2">Active</span>);
    const [data3, setData3] = useState(<span className="badge badge-success px-4 py-2 ms-2">Active</span>);

    const [confirm_modal, setconfirm_modal] = useState(false);
    const [editconfirmremovedata, seteditconfirmremovedata] = useState([]);

    const showAddModalHandle = (event) => {
        setconfirm_modal(false);
    };
    const RemoveCourseModule = (event, course_data) => {
        seteditconfirmremovedata(course_data);
        setconfirm_modal(true);
    };

    const ConfirmRemove = (form_data) => {
        console.log("form_data===", form_data);
        setconfirm_modal(false);
        // props.onDeleteCourse(form_data).then((response) => {
        //     if (response.success === true) {
        //         GetAllCourses();
        //         toast.success(response.message, {
        //             position: toast.POSITION.TOP_CENTER,
        //         });
        //         setadd_course_modal(false);
        //         setedit_course_modal(false);
        //         setconfirm_modal(false);
        //     } else {
        //         toast.error(response.message, {
        //             position: toast.POSITION.TOP_CENTER,
        //         });
        //     }
        // });
    };
    return (
        <React.Fragment>
            {confirm_modal && (
                <DeleteConfirmRemove
                    title={`Delete Reason?`}
                    bodytitle="Are you sure you want to delete this Reason?"
                    editconfirmremovedata={editconfirmremovedata}
                    confirm_modal={confirm_modal}
                    confirmButtonText="Delete"
                    ConfirmRemove={(event) => ConfirmRemove(event)}
                    delete_confirm_modal_action={(modal_action) => showAddModalHandle(modal_action)}
                />
            )}
            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <Sidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-fluid">
                                        <div className="school-edit-form">
                                            <div className="" id="Snapshot">
                                                <div className="card-body ">
                                                    <div className="row">
                                                        <div className="col-sm-8">
                                                            <div className="card mb-5 tabs-outer">
                                                                <div className="card-header position-relative py-0 details-bg">
                                                                    <h5 className="card-title text-gray-800 fw-bolder m-0">Reason Details</h5>
                                                                    <div className="card-toolbar">
                                                                        <Link to={`/${school_code}/reason-list/add-reason`} className="btn btn-sm btn-primary apply-btn add-btns addschool-button">
                                                                            Add Reason
                                                                        </Link>
                                                                    </div>
                                                                </div>

                                                                <div className="card-body">
                                                                    <div className="card-toolbar border-bottom border-bottom-1 pb-4 mb-6">
                                                                        <div className="d-flex align-items-center justify-content-between export-out">
                                                                            {/* <!-- <h4 className="card-title text-gray-800 fw-bolder m-0">Schools</h4> --> */}
                                                                            <div className="d-flex align-items-center position-relative">
                                                                                <span className="svg-icon svg-icon-1 position-absolute ms-4">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                                        <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                                                                        <path
                                                                                            d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                                                                            fill="currentColor"
                                                                                        ></path>
                                                                                    </svg>
                                                                                </span>

                                                                                <input type="text" className="form-control form-control-sm w-350px ps-12 search-out fs-6" placeholder="Search " style={{ background: "transparent" }} />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-sm-12">
                                                                            <div className="card-1 p-6 mb-4 heading-border">
                                                                                <div className="d-flex flex-stack mb-5">
                                                                                    <h3 className="mb-0 text-primary superadmin-headings">SL</h3>
                                                                                    <div className="d-flex align-items-center">{data}</div>
                                                                                </div>

                                                                                <div className="d-flex text-secondary align-items-center mb-3">
                                                                                    <div className="d-flex me-4 align-items-center">
                                                                                        {/* <!--  <i className="bi bi-card-image me-2 fs-6"></i> --> */}
                                                                                        <img alt="profile" src="/assets/media/s-image.jpg" className="img-fluid h-80px"></img>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="d-flex text-secondary align-items-center mb-3">
                                                                                    <div className="d-flex text-secondary align-items-center mb-3">
                                                                                        <div className="d-flex align-items-center me-4">
                                                                                            <Link to={`/${school_code}/reason-list/edit-reason`} className="heding-element ">
                                                                                                <i className="far fa-edit me-2 fs-6"></i>Edit
                                                                                            </Link>
                                                                                        </div>
                                                                                        <div className="d-flex align-items-center me-4 pointer">
                                                                                            <span onClick={(event) => RemoveCourseModule(event, "123133")}>
                                                                                                <i className="far fa-trash-alt me-2 fs-6"></i>
                                                                                                Delete
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="d-flex text-secondary align-items-center mb-3">
                                                                                        <div className="d-flex align-items-center">
                                                                                            <span className="deactivate-button1" onClick={() => setData(<span className="badge badge-danger px-4 py-2 ms-2 active-button">Inactive</span>)}>
                                                                                                <i className="bi bi-bell-slash me-2 fs-6"></i>Deactivate Manager
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="card-1 p-6 mb-4 heading-border">
                                                                                <div className="d-flex flex-stack mb-5">
                                                                                    <h3 className="mb-0 text-primary superadmin-headings">SL</h3>
                                                                                    <div className="d-flex align-items-center">{data2}</div>
                                                                                </div>

                                                                                <div className="d-flex text-secondary align-items-center mb-3">
                                                                                    <div className="d-flex me-4 align-items-center">
                                                                                        {/* <!--  <i className="bi bi-card-image me-2 fs-6"></i> --> */}
                                                                                        <img alt="profile" src="/assets/media/s-image.jpg" className="img-fluid h-80px"></img>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="d-flex text-secondary align-items-center mb-3">
                                                                                    <div className="d-flex text-secondary align-items-center mb-3">
                                                                                        <div className="d-flex align-items-center me-4">
                                                                                            <Link to={`/${school_code}/reason-list/edit-reason`} className="heding-element ">
                                                                                                <i className="far fa-edit me-2 fs-6"></i>Edit
                                                                                            </Link>
                                                                                        </div>
                                                                                        <div className="d-flex align-items-center me-4 pointer">
                                                                                            <span onClick={(event) => RemoveCourseModule(event, "123133")}>
                                                                                                <i className="far fa-trash-alt me-2 fs-6"></i>
                                                                                                Delete
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="d-flex text-secondary align-items-center mb-3">
                                                                                        <div className="d-flex align-items-center">
                                                                                            <span className="deactivate-button1" onClick={() => setData2(<span className="badge badge-danger px-4 py-2 ms-2 active-button">Inactive</span>)}>
                                                                                                <i className="bi bi-bell-slash me-2 fs-6"></i>Deactivate Manager
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="card-1 p-6 mb-4 heading-border">
                                                                                <div className="d-flex flex-stack mb-5">
                                                                                    <h3 className="mb-0 text-primary superadmin-headings">SL</h3>
                                                                                    <div className="d-flex align-items-center">{data3}</div>
                                                                                </div>

                                                                                <div className="d-flex text-secondary align-items-center mb-3">
                                                                                    <div className="d-flex me-4 align-items-center">
                                                                                        {/* <!--  <i className="bi bi-card-image me-2 fs-6"></i> --> */}
                                                                                        <img alt="profile" src="/assets/media/s-image.jpg" className="img-fluid h-80px"></img>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="d-flex text-secondary align-items-center mb-3">
                                                                                    <div className="d-flex text-secondary align-items-center mb-3">
                                                                                        <div className="d-flex align-items-center me-4">
                                                                                            <Link to={`/${school_code}/reason-list/edit-reason`} className="heding-element ">
                                                                                                <i className="far fa-edit me-2 fs-6"></i>Edit
                                                                                            </Link>
                                                                                        </div>
                                                                                        <div className="d-flex align-items-center me-4 pointer">
                                                                                            <span onClick={(event) => RemoveCourseModule(event, "123133")}>
                                                                                                <i className="far fa-trash-alt me-2 fs-6"></i>
                                                                                                Delete
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="d-flex text-secondary align-items-center mb-3">
                                                                                        <div className="d-flex align-items-center">
                                                                                            <span className="deactivate-button1" onClick={() => setData3(<span className="badge badge-danger px-4 py-2 ms-2 active-button">Inactive</span>)}>
                                                                                                <i className="bi bi-bell-slash me-2 fs-6"></i>Deactivate Manager
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            {/* <div className="d-flex flex-stack justify-content-between flex-wrap pt-3 pagination-main">
                                                                    <div className="pagination_limt">
                                                                        <select size="1" id="select" name="group" className="form-control input-sm form-select form-select-sm form-select-solid">
                                                                            <option value="10">10</option>
                                                                            <option value="25">25</option>
                                                                            <option value="50">50</option>
                                                                            <option value="100">100</option>
                                                                            <option value="250">250</option>
                                                                            <option value="500">500</option>
                                                                        </select>
                                                                        <div className="count_number ms-2">Displaying 1-10 of 100 records</div>
                                                                    </div>
                                                                    <div className="pagination_page">
                                                                        <ul className="pagination react-bootstrap-table-page-btns-ul">
                                                                            <li className="page-item" title="first page">
                                                                                <span  className="page-link"><i className="fas fa-angle-double-left"></i></span>
                                                                            </li>
                                                                            <li className="page-item" title="previous page">
                                                                                <span  className="page-link"><i className="fas fa-angle-left"></i></span>
                                                                            </li>
                                                                            <li className="active page-item" title="1"><span  className="page-link">1</span></li>
                                                                            <li className="page-item" title="2"><span  className="page-link">2</span></li>
                                                                            <li className="page-item" title="3"><span  className="page-link">3</span></li>
                                                                            <li className="page-item" title="4"><span  className="page-link">4</span></li>
                                                                            <li className="page-item" title="5"><span  className="page-link">5</span></li>
                                                                            <li className="page-item" title="next page">
                                                                                <span  className="page-link"><i className="fas fa-angle-right"></i></span>
                                                                            </li>
                                                                            <li className="page-item" title="last page">
                                                                                <span  className="page-link"><i className="fas fa-angle-double-right"></i></span>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-4">
                                                            <div className="card ">
                                                                {/* <!--begin::Header--> */}
                                                                <div className="card-header border-bottom border-bottom-1">
                                                                    <h3 className="card-title align-items-start flex-column">
                                                                        <span className="card-label fw-bold text-dark">Filters</span>
                                                                    </h3>
                                                                </div>

                                                                <div className="card-body p-6">
                                                                    <div className="d-flex flex-column">
                                                                        <h5>Sort By</h5>
                                                                        <div className="d-flex">
                                                                            <span className="btn btn-primary active-filter filter-badge me-2 filter-button ">Name</span>
                                                                            {/* <span className="btn btn-dark filter-badge me-2 filter-button " >Location</span> */}
                                                                        </div>
                                                                    </div>

                                                                    <div className="d-flex flex-column mt-6">
                                                                        <h5>Show</h5>
                                                                        <div className="d-flex align-items-center">
                                                                            <span className="btn btn-primary active-filter filter-badge me-2 filter-button ">All</span>
                                                                            <span className="btn btn-dark filter-badge me-2 filter-button ">Active</span>
                                                                            <span className="btn btn-dark filter-badge me-2 filter-button ">Inactive</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
export default ReasonList;
