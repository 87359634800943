import React, { createRef } from "react";
import Sidebar from "../Constant/sidebar";
import Footer from "../Constant/Footer";
import TopBar from "../Constant/TopBar";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import Select from "react-select";

import Validations from "../Utility/Validations";
import { toast } from "react-toastify";
import * as userActions from "../../actions/index";

import * as actionType from "../../constants/actionTypes";
import AvatarImageCropper from 'react-avatar-image-cropper';

import BreakfastTimeHandler from "../Leave/UniversalComponent/BreakfastTimeHandler";
import LunchTimeHandler from "../Leave/UniversalComponent/LunchTimeHandler";
import DinnerTimeHandler from "../Leave/UniversalComponent/DinnerTimeHandler";


import moment from "moment";
import { Cropper } from 'react-advanced-cropper';
import "react-advanced-cropper/dist/style.css";
import "./style.css";

var manager_id = [];
var getmanager_id = [];
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#e7f5fe" : "",
        },
        backgroundColor: state.isSelected ? "#4a8fb8" : "",
    }),
};
const permission_info = JSON.parse(localStorage.getItem("permissions"));

const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info.school_code;

class DormitoriesEdit extends React.Component {
    inputRef = createRef();
    cropperRef = createRef();
    constructor(props) {
        super(props);
        window.addEventListener('keydown', this.handleKeyPress);
        this.state = {
            OpenImageModal: false,
            image: "",
            errorMessage: "",
            dormitory_edit_form: {
                dormitory_name: {
                    type: "text",
                    label: "Dormitory Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Class Code",
                    validations: { required: true },
                },
                bio_note: {
                    type: "text",
                    label: "Bio Note *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Big Note",
                    validations: { required: true },
                },
                dormitory_type: {
                    type: "radio",
                    label: "Dormitory Type *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: true },
                },
                dormitory_image: {
                    label: "File",
                    type: "file",
                    value: '',
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                    thumbnail_image: "/assets/media/school-logo.png",
                },
                user_ids: {
                    type: "select",
                    label: "Select Dormitory Head *",
                    value: [],
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                    options: [],
                },
                dormitory_uuid: {
                    type: "text",
                    label: "dormitory_uuid",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },
                dormitory_id: {
                    type: "select",
                    label: "Select Dormitory Head *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                    options: [],
                },
                dormitory_contact: {
                    type: "number",
                    label: "Dormitory Contact Number *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Dormitory Contect Number",
                    validations: { required: true },
                },


                breakfast_time: {
                    type: "select",
                    label: "Breakfast Time *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Breakfast Time",
                    validations: { required: true },
                    options: [],
                },
                lunch_time: {
                    type: "select",
                    label: "Lunch Time *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Lunch Time",
                    validations: { required: true },
                    options: [],
                },
                dinner_time: {
                    type: "select",
                    label: "Dinner Time *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Dinner Time",
                    validations: { required: true },
                    options: [],
                },
            },
        };
    }

    componentDidMount() {
        this.GetDormitoryDetailsById();
        this.GetAllManagersListBySchoolId();
        this.GetLoginUserPermissions();
    }

    GetLoginUserPermissions() {
        this.props.onGetLoginUserPermissions().then((response) => {
            if (response.success === true) {
            } else {
            }
        });
    }

    GetAllManagersListBySchoolId = async (form_data) => {
        this.props.onGetAllManagersListBySchoolId().then((response) => {
            let state_level = [];
            const update_dormitory_edit_form = { ...this.state.dormitory_edit_form };
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {

                    state_level.push({
                        label: arrayData[key].first_name + " " + arrayData[key].last_name,
                        value: arrayData[key].user_id,

                    });
                }
            }
            update_dormitory_edit_form['user_ids'].options = state_level;
            this.setState({ dormitory_edit_form: update_dormitory_edit_form });
        });
    }

    handleChangeMulti = (event) => {
        const update_dormitory_edit_form = { ...this.state.dormitory_edit_form };
        // manager_id = []
        // for (let key in event) {
        //     manager_id.push(JSON.parse(event[key].value))
        // }
        // update_dormitory_edit_form["user_ids"].value = JSON.stringify(manager_id);
        update_dormitory_edit_form["user_ids"].value = event;
        this.setState({ dormitory_edit_form: update_dormitory_edit_form });
    };


    GetDormitoryDetailsById() {
        this.props.onGetDormitoryDetailsById(this.props.dormitory_uuid).then((response) => {
            if (response.success === true) {
                let dormitory_data = response.data;
                let manager_data = response.data.dormitory_users;
                const update_dormitory_edit_form = { ...this.state.dormitory_edit_form };

                for (let key in update_dormitory_edit_form) {
                    let form_element = update_dormitory_edit_form[key];

                    if (dormitory_data[key]) {
                        if (dormitory_data.dormitory_image !== null && dormitory_data.dormitory_image !== '') {
                            update_dormitory_edit_form['dormitory_image'].thumbnail_image = actionType.environment_url + '/' + dormitory_data.dormitory_image;
                            update_dormitory_edit_form['dormitory_image'].value = dormitory_data.dormitory_image;
                        }

                        update_dormitory_edit_form['dormitory_id'].value = dormitory_data.dormitory_id;
                        update_dormitory_edit_form['dormitory_type'].value = dormitory_data.dormitory_type;
                        update_dormitory_edit_form['dormitory_name'].value = dormitory_data.dormitory_name;
                        update_dormitory_edit_form['bio_note'].value = dormitory_data.bio_note;

                        update_dormitory_edit_form['dormitory_uuid'].value = dormitory_data.dormitory_uuid;
                        update_dormitory_edit_form['dormitory_contact'].value = dormitory_data.dormitory_contact;

                        update_dormitory_edit_form['breakfast_time'].value = dormitory_data.breakfast_time;
                        update_dormitory_edit_form['lunch_time'].value = dormitory_data.lunch_time
                        update_dormitory_edit_form['dinner_time'].value = dormitory_data.dinner_time
                        this.setState({ dormitory_edit_form: update_dormitory_edit_form });
                        update_dormitory_edit_form[key] = form_element;
                    }
                    update_dormitory_edit_form[key] = form_element;
                }
                getmanager_id = []
                for (let key in manager_data) {

                    getmanager_id.push({
                        label: manager_data[key].first_name + " " + manager_data[key].last_name,
                        value: manager_data[key].user_id,

                    });
                }
                // for (let key in manager_data) {
                //     getmanager_id.push(JSON.parse(manager_data[key].user_id))
                // }
                // update_dormitory_edit_form["user_ids"].value = JSON.stringify(getmanager_id);
                update_dormitory_edit_form["user_ids"].value = getmanager_id;
                this.setState({ dormitory_edit_form: update_dormitory_edit_form });
            } else {
            }
        });
    }

    // inputChangeHandlerimage = blob => {
    //     const src = window.URL.createObjectURL(blob);
    //     let url = URL.createObjectURL(blob);
    //     let file = new File([blob], "image.png", { lastModified: new Date().getTime(), type: blob.type })
    //     const update_dormitory_edit_form = { ...this.state.dormitory_edit_form };
    //     const form_element = { ...update_dormitory_edit_form["dormitory_image"] };
    //     form_element.thumbnail_image = url;

    //     form_element.value = file;
    //     update_dormitory_edit_form["dormitory_image"] = form_element;
    //     this.setState({ dormitory_edit_form: update_dormitory_edit_form });
    //     this.setState({ OpenImageModal: !this.state.OpenImageModal })
    // };


    inputChangeHandler(event, identifier) {
        const update_dormitory_edit_form = { ...this.state.dormitory_edit_form };
        const form_element = { ...update_dormitory_edit_form[identifier] };
        form_element.value = event.target.value;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_dormitory_edit_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_dormitory_edit_form) {
            is_form_valid = update_dormitory_edit_form[identifier].valid && is_form_valid;
        }
        this.setState({
            dormitory_edit_form: update_dormitory_edit_form,
            is_form_valid: is_form_valid,
        });
    }

    handleChange(data, identifier, type) {
        const update_dormitory_edit_form = { ...this.state.dormitory_edit_form };
        update_dormitory_edit_form[identifier].value = data.value;
        this.setState({ dormitory_edit_form: update_dormitory_edit_form });
    }

    SubmitHandler = async (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_dormitory_edit_form = this.state.dormitory_edit_form;
        manager_id = []
        for (let key in update_dormitory_edit_form.user_ids.value) {
            manager_id.push(JSON.parse(update_dormitory_edit_form.user_ids.value[key].value))
        }
        update_dormitory_edit_form["user_ids"].value = JSON.stringify(manager_id);
        for (let key in update_dormitory_edit_form) {
            let form_element = update_dormitory_edit_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_dormitory_edit_form[key] = form_element;
        }
        this.setState({ dormitory_edit_form: update_dormitory_edit_form });
        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.dormitory_edit_form) {
                form_data[key] = this.state.dormitory_edit_form[key].value;
            }
            console.log("form_data===", form_data)
            this.props.onUpdateDormitory(form_data).then((response) => {
                console.log("dsffsdvfd", response);
                if (response.success === true) {
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: `/${school_code}/dormitory-list`,
                        });
                    }, 1000);
                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            });
        }

    };

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyPress);
    }

    handleKeyPress = (event) => {
        if (event.keyCode === 27) {
            this.setState({ OpenImageModal: false })
        }
    };



    mealTimeHandler = (e, identifier) => {
        const update_dormitory_form = { ...this.state.dormitory_edit_form };
        const form_element = { ...update_dormitory_form[identifier] };
        form_element.value = moment(e, 'hh:mm A').format('HH:mm');
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;
        update_dormitory_form[identifier] = form_element;
        this.setState({ dormitory_edit_form: update_dormitory_form });
    }
    // react-advanced-cropper 

    onUpload = () => {
        if (this.inputRef.current) {
            this.inputRef.current.click();
        }
    };

    onCrop = () => {
        const cropper = this.cropperRef.current;
        if (cropper) {
            const canvas = cropper.getCanvas();
            if (canvas) {
                canvas.toBlob((blob) => {
                    if (blob) {
                        this.inputChangeHandlerimage(blob);
                    }
                });
            }
        }
    };

    inputChangeHandlerimage = (blob) => {
        const src = window.URL.createObjectURL(blob);
        const file = new File([blob], "image.png", { lastModified: new Date().getTime(), type: blob.type });
        const update_dormitory_edit_form = { ...this.state.dormitory_edit_form };
        const form_element = { ...update_dormitory_edit_form["dormitory_image"] };
        form_element.thumbnail_image = src;
        form_element.value = file;
        update_dormitory_edit_form["dormitory_image"] = form_element;
        this.setState({
            dormitory_edit_form: update_dormitory_edit_form,
            OpenImageModal: !this.state.OpenImageModal,
        });
    };


    // inputChangeHandlerimage = blob => {
    //     const src = window.URL.createObjectURL(blob);
    //     let url = URL.createObjectURL(blob);
    //     let file = new File([blob], "image.png", { lastModified: new Date().getTime(), type: blob.type })
    //     const update_dormitory_edit_form = { ...this.state.dormitory_edit_form };
    //     const form_element = { ...update_dormitory_edit_form["dormitory_image"] };
    //     form_element.thumbnail_image = url;

    //     form_element.value = file;
    //     update_dormitory_edit_form["dormitory_image"] = form_element;
    //     this.setState({ dormitory_edit_form: update_dormitory_edit_form });
    //     this.setState({ OpenImageModal: !this.state.OpenImageModal })
    // };

    onLoadImage = (event) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            if (file.size > 2 * 1024 * 1024) {
                this.setState({
                    errorMessage: "The size of image is too large.",
                    image: null, 
                });
            } else {
                this.setState({
                    image: URL.createObjectURL(file),
                    errorMessage: "", 
                });
            }
        }
        event.target.value = ""; 
    };

    componentWillUnmount() {
        const { image } = this.state;
        if (image) {
            URL.revokeObjectURL(image);
        }
    }


    render() {
        const { dormitory_edit_form } = this.state;
        const { image, OpenImageModal, errorMessage } = this.state;
        return (
            <React.Fragment>
                {this.state.OpenImageModal &&
                    <div className="upload-image-outer-div"  >
                        <span className="pointer img-crop-close-btn" onClick={() => this.setState({ OpenImageModal: !this.state.OpenImageModal })}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                            </svg>
                        </span>
                        {
                            image ?
                                <div className="upload-image-dormitory">
                                    <div className="example">
                                        <div className="example__cropper-wrapper">
                                            <Cropper
                                                ref={this.cropperRef}
                                                className="example__cropper"
                                                backgroundClassName="example__cropper-background"
                                                src={image}
                                            />
                                        </div>
                                        <div className="example__buttons-wrapper">
                                            <button className="example__button" onClick={this.onUpload}>
                                                <input
                                                    ref={this.inputRef}
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={this.onLoadImage}
                                                />
                                                Upload image
                                            </button>
                                            {image && (
                                                <button className="example__button" onClick={this.onCrop}>
                                                    Submit
                                                </button>
                                            )}
                                        </div>
                                        {OpenImageModal && <div className="modal">Image has been updated!</div>}
                                    </div>
                                </div>
                                : <div className="upload-image-inner-div-dormitory">
                                    <div className="upload-input-container">
                                        <div
                                            className="upload-input"
                                            onClick={this.onUpload}
                                        >
                                            <div>
                                                <svg viewBox="0 0 24 24" style={{ display: "inline-block", color: "rgb(255, 255, 255)", fill: "currentcolor", height: "32px", width: "32px", userSelect: "none" }}>
                                                    <circle cx="12" cy="12" r="3.2"></circle>
                                                    <path d="M9 2L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z"></path>
                                                </svg>
                                            </div>

                                            <input
                                                ref={this.inputRef}
                                                type="file"
                                                accept="image/*"
                                                onChange={this.onLoadImage}
                                            />
                                            Upload image
                                            {errorMessage && <span className="text-danger font-weight-bold">{errorMessage}</span>}
                                        </div>
                                    </div>
                                </div>
                        }

                        {/* <div className="upload-image-inner-div-dormitory">
                            <AvatarImageCropper
                                rootStyle={{ borderRadius: "50%" }}
                                cancelBtnStyle={{ display: "none" }}
                                apply={this.inputChangeHandlerimage}
                                className={"image_crop_style"}
                                sliderConStyle={{ margin: "20px", backgroundColor: "none" }}
                                applyBtnStyle={{}}
                                isBack={true}
                            />
                        </div> */}
                        <div className="text-center mt-10 d-flex flex-column align-items-center">
                            <span className="text-white font-weight-bold rounded ">The image maximum size is 2MB. Please upload png/jpg/jpeg image</span>
                        </div>
                    </div>
                }
                {this.state.isAuthenticated ? <Navigate to={this.state.redirect_url} /> : null}
                <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed demo h-100">
                    <div className="d-flex flex-column flex-root h-100">
                        <div className="page d-flex flex-row flex-column-fluid">
                            <Sidebar></Sidebar>
                            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                                <TopBar></TopBar>
                                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                    <div className="post d-flex flex-column-fluid" id="kt_post">
                                        <div id="kt_content_container" className="container-fluid">
                                            <div className="school-edit-form ">
                                                <div className="" id="Snapshot">
                                                    <form onSubmit={this.SubmitHandler}>
                                                        <div className="card  tabs-outer mb-5">
                                                            <div className="card-header position-relative py-0 details-bg">
                                                                <h2 className="card-title m-0">Edit Dormitory</h2>
                                                            </div>

                                                            <div className="card-body">
                                                                <div className="row align-items-center">
                                                                    <div className="col-lg-4">
                                                                        <div className="row">
                                                                            <div className="col-sm-12">
                                                                                <label className="fw-bold mb-2 ">Dormitory Image</label>
                                                                                <div className="profile-image-out p-0">
                                                                                    <img alt="profile" src={dormitory_edit_form.dormitory_image.thumbnail_image} className="img-fluid" style={{ objectFit: "contain" }} />
                                                                                    <div className="d-flex justify-content-center h-100 flex-column align-items-center">

                                                                                        {/* <p className="text-center">Please attach jpg or png file</p> */}
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-sm btn-outline-primary px-10 rounded-50 "
                                                                                            onClick={() => this.setState({ OpenImageModal: !this.state.OpenImageModal })}
                                                                                        >
                                                                                            Upload Image
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-lg-8">
                                                                        <div className="row">
                                                                            <div className="col-lg-12">
                                                                                <label className="fw-bold mb-2">{dormitory_edit_form.dormitory_type.label}</label>
                                                                                <div className="d-flex">
                                                                                    <label className="cursor-pointer">
                                                                                        <input
                                                                                            type="radio"
                                                                                            id="gender"
                                                                                            name="gen"
                                                                                            className="form-check-input me-2 cursor-pointer"
                                                                                            value="boy"
                                                                                            checked={dormitory_edit_form.dormitory_type.value === "boy" ? true : false}
                                                                                            onChange={(event) => this.inputChangeHandler(event, "dormitory_type")}
                                                                                        />
                                                                                        Male
                                                                                    </label>

                                                                                    <label className="ms-8 cursor-pointer">
                                                                                        <input
                                                                                            type="radio"
                                                                                            id="gender1"
                                                                                            name="gen"
                                                                                            className="form-check-input me-2 cursor-pointer"
                                                                                            value="girl"
                                                                                            checked={dormitory_edit_form.dormitory_type.value === "girl" ? true : false}
                                                                                            onChange={(event) => this.inputChangeHandler(event, "dormitory_type")}
                                                                                        />
                                                                                        Female
                                                                                    </label>

                                                                                    <label className="ms-8 cursor-pointer">
                                                                                        <input
                                                                                            type="radio"
                                                                                            id="gender2"
                                                                                            name="gen"
                                                                                            className="form-check-input me-2 cursor-pointer"
                                                                                            value="both"
                                                                                            checked={dormitory_edit_form.dormitory_type.value === "both" ? true : false}
                                                                                            onChange={(event) => this.inputChangeHandler(event, "dormitory_type")}
                                                                                        />
                                                                                        Both
                                                                                    </label>
                                                                                </div>
                                                                                {!dormitory_edit_form.dormitory_type.valid && dormitory_edit_form.dormitory_type.onBlur_out ? (
                                                                                    <div className="error field-error">{dormitory_edit_form.dormitory_type.error_msg}</div>
                                                                                ) : (
                                                                                    ""
                                                                                )}
                                                                            </div>
                                                                        </div>

                                                                        <div className="row my-5">
                                                                            <div className="col-lg-6">
                                                                                <label className="fw-bold mb-2">{dormitory_edit_form.dormitory_name.label} </label>
                                                                                <input
                                                                                    type={dormitory_edit_form.dormitory_name.type}
                                                                                    value={dormitory_edit_form.dormitory_name.value ? dormitory_edit_form.dormitory_name.value : ""}
                                                                                    className="form-control"
                                                                                    placeholder={dormitory_edit_form.dormitory_name.placeholder}
                                                                                    onChange={(event) => this.inputChangeHandler(event, "dormitory_name")}
                                                                                />
                                                                                {!dormitory_edit_form.dormitory_name.valid && dormitory_edit_form.dormitory_name.onBlur_out ? (
                                                                                    <div className="error field-error">{dormitory_edit_form.dormitory_name.error_msg}</div>
                                                                                ) : (
                                                                                    ""
                                                                                )}
                                                                            </div>

                                                                            <div className="col-lg-6">
                                                                                <label className="fw-bold mb-2">{dormitory_edit_form.user_ids.label}</label>
                                                                                <Select
                                                                                    isMulti
                                                                                    value={dormitory_edit_form.user_ids.value}
                                                                                    options={dormitory_edit_form.user_ids.options}
                                                                                    styles={customStyles}
                                                                                    className="react-bootstrap-typeahead tag-outer"
                                                                                    onChange={this.handleChangeMulti}
                                                                                />
                                                                                {!dormitory_edit_form.user_ids.valid && dormitory_edit_form.user_ids.onBlur_out ? <div className="error field-error">{dormitory_edit_form.user_ids.error_msg}</div> : ""}
                                                                            </div>
                                                                        </div>

                                                                        <div className="row">
                                                                            <div className="col-lg-6">
                                                                                <label className="fw-bold mb-2">{dormitory_edit_form.bio_note.label}</label>
                                                                                <textarea
                                                                                    type={dormitory_edit_form.bio_note.type}
                                                                                    value={dormitory_edit_form.bio_note.value ? dormitory_edit_form.bio_note.value : ""}
                                                                                    className="form-control autoresizing"
                                                                                    placeholder={dormitory_edit_form.bio_note.placeholder}
                                                                                    onChange={(event) => this.inputChangeHandler(event, "bio_note")}
                                                                                />
                                                                                {!dormitory_edit_form.bio_note.valid && dormitory_edit_form.bio_note.onBlur_out ? (
                                                                                    <div className="error field-error">{dormitory_edit_form.bio_note.error_msg}</div>
                                                                                ) : (
                                                                                    ""
                                                                                )}
                                                                            </div>

                                                                            <div className="col-lg-6">
                                                                                <label className="fw-bold mb-2">{dormitory_edit_form.dormitory_contact.label}</label>
                                                                                <input
                                                                                    type={dormitory_edit_form.dormitory_contact.type}
                                                                                    value={dormitory_edit_form.dormitory_contact.value ? dormitory_edit_form.dormitory_contact.value : ""}
                                                                                    className="form-control"
                                                                                    placeholder={dormitory_edit_form.dormitory_contact.placeholder}
                                                                                    onChange={(event) => this.inputChangeHandler(event, "dormitory_contact")}
                                                                                />
                                                                                {!dormitory_edit_form.dormitory_contact.valid && dormitory_edit_form.dormitory_contact.onBlur_out ? (
                                                                                    <div className="error field-error">{dormitory_edit_form.dormitory_contact.error_msg}</div>
                                                                                ) : (
                                                                                    ""
                                                                                )}
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div className="card mb-5 tabs-outer">
                                                            <div className="card-header position-relative details-bg">
                                                                <h5 className="card-title  m-0">Dormitory Meal Timings </h5>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="row mt-5">
                                                                    <div className="col-4">
                                                                        <label className="fw-bold mb-2">{dormitory_edit_form.breakfast_time.label}</label>
                                                                        <BreakfastTimeHandler
                                                                            placeholder={dormitory_edit_form.breakfast_time.placeholder}
                                                                            value={dormitory_edit_form.breakfast_time.value}
                                                                            onChange={(event) => this.mealTimeHandler(event, "breakfast_time")}
                                                                        />
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <label className="fw-bold mb-2">{dormitory_edit_form.lunch_time.label}</label>
                                                                        <LunchTimeHandler
                                                                            placeholder={dormitory_edit_form.lunch_time.placeholder}
                                                                            value={dormitory_edit_form.lunch_time.value}
                                                                            onChange={(event) => this.mealTimeHandler(event, "lunch_time")}
                                                                        />
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <label className="fw-bold mb-2">{dormitory_edit_form.dinner_time.label}</label>
                                                                        <DinnerTimeHandler
                                                                            placeholder={dormitory_edit_form.dinner_time.placeholder}
                                                                            value={dormitory_edit_form.dinner_time.value}
                                                                            onChange={(event) => this.mealTimeHandler(event, "dinner_time")}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-12 d-flex justify-content-end">
                                                            <div className="d-flex align-items-end">
                                                                <button type="submit" className="btn btn-sm btn-primary apply-btn min-150px addschool-button">
                                                                    Update Dormitory
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Footer />
                            </div>
                        </div>
                    </div>
                </div >
            </React.Fragment >
        );
    }
}
function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}

const mapDispatchToProps = {
    onUpdateDormitory: userActions.UpdateDormitory,
    onGetDormitoryDetailsById: userActions.GetDormitoryDetailsById,
    onGetAllManagersListBySchoolId: userActions.GetAllManagersListBySchoolId,
    onGetLoginUserPermissions: userActions.GetLoginUserPermissions,
};
export default connect(mapStateToProps, mapDispatchToProps)(DormitoriesEdit);
