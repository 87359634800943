import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import "react-datepicker/dist/react-datepicker.css";
import { Tooltip } from "@mui/material";
// import { Link } from "react-router-dom";
// import Moment from "moment";
const DuplicateStudentItem = (props) => {
    console.log("props=========", props);
    const [perpagedata, setperpagedata] = useState(10);
    const [data, setData] = useState([])

    useEffect(() => {
        setData(props.data)
    }, [props.data])
    return (
        <>
            <div>
                <div>
                    {data.map((item_1) => {
                        return (
                            <div className={item_1.length > 1 ? "my-5 px-3 border border-danger rounded shadow-sm" : "my-3 px-3 "}  >
                                {
                                    item_1.length > 1 ? item_1.map((item_2) => {
                                        return (
                                            <div className="card-1 p-6 my-3 rounded bg-light-info w-auto" >
                                                <div className="d-flex flex-stack mb-3">
                                                    {console.log("row============",item_1)}
                                                    <h3 className="mb-0 text-primary superadmin-headings">
                                                        <input
                                                            type="checkbox"
                                                            checked={item_2.checked}
                                                            className="me-4 big-checkbox"
                                                            id={item_2.student_uuid}
                                                            onClick={(event) => {
                                                                props.handleClick(event, item_2);
                                                            }}

                                                        />
                                                        {item_2.student_first_name + " " + item_2.student_last_name}
                                                    </h3>
                                                    <div className="d-flex align-items-center">
                                                        {item_2.is_student_activate === true ? (
                                                            <span className="badge badge-light-success px-4 py-2 active-button">Active</span>
                                                        ) : (
                                                            <span className="badge badge-light-danger px-4 py-2">Inactive</span>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="d-flex text-secondary align-items-center">
                                                    <div className="d-flex align-items-center">
                                                        <Tooltip title="Student unique pin" placement="top">
                                                            <i className="fas fa-list-ol me-2 fs-6"></i>
                                                         </Tooltip>
                                                        <p className="mb-0 heding-element me-4 ">{item_2.unique_pin} </p>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <Tooltip title="Student year" placement="top">
                                                             <i className="fas fa-chalkboard-teacher me-2 fs-6"></i>
                                                         </Tooltip>
                                                        <p className="mb-0 heding-element me-4">{item_2.class_name} </p>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                    <Tooltip title="Dormitory name" placement="top">
                                                    <i className="fas fa-building me-2 fs-6"></i>
                                                         </Tooltip>
                                                     
                                                        <p className="mb-0 heding-element me-4">{item_2.dormitory_data.dormitory_name} </p>
                                                    </div>
                                                </div>

                                                <div className="d-flex text-secondary align-items-center my-3">
                                                    {item_2 && item_2.is_student_activate === true ? (
                                                        <div className="d-flex align-items-center"
                                                            onClick={(event) => {
                                                                props.handleChange(event, item_2.student_uuid, false);
                                                            }}
                                                        >
                                                            <span className="deactivate-button1" >
                                                                <i className="bi bi-bell-slash me-2 fs-6"></i>Deactivate Student
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <div className="d-flex align-items-center"
                                                            onClick={(event) => {
                                                                props.handleChange(event, item_2.student_uuid, true);
                                                            }}
                                                        >
                                                            <span className="deactivate-button1">
                                                                <i className="bi bi-bell me-2 fs-6"></i>Activate Student
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="d-flex text-secondary align-items-center my-3">
                                                    <div className="d-flex align-items-center me-3">
                                                        <span className="deactivate-button1" >
                                                       
                                                        <Tooltip title="Preferred Name" placement="top">
                                                          <i class="fas fa-file-signature me-2 fs-6"></i>   {item_2.preferred_name}
                                                         </Tooltip>
                 
                                                        </span>
                                                    </div>

                                                    <div className="d-flex align-items-center">
                                                        <span className="deactivate-button1">
                                     
                                                        <Tooltip title="Student mobile number" placement="top">
                                                        <i class="fas fa-mobile-alt me-2 fs-6"></i>{item_2.student_phone}
                                                         </Tooltip>
                                                        </span>
                                                    </div>

                                                </div>
                                            </div>
                                        )
                                    }) : ""
                                }

                            </div>
                        )
                    })
                    }
                </div>
            </div>
        </>

    );
};
export default DuplicateStudentItem;