import React from "react";
// import React, { useState, useEffect, useCallback } from "react";
import Validations from "../Utility/Validations";
import { connect } from "react-redux";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import * as userActions from "../../actions/index";

const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" :user_info.school_code;

class AddRollModall extends React.Component {
    constructor(props) {
        console.log("props_role",props);
        super(props);
        this.state = {
            show: false,
            all_role_name:[],
            roles_permission: {
                role_name: {
                    type: "text",
                    label: "Role Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
            }
        }
    }

    inputChangeHandler(event, role_name, value) {

        const update_role = { ...this.state.roles_permission };
        const form_element = { ...update_role[role_name] };
        console.log("form_element", form_element)
        console.log("event.target.value", event.target.value)
        form_element.value = event.target.value;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_role["role_name"] = form_element;

        let is_form_valid = true;
        for (let role_name in update_role) {
            is_form_valid = update_role[role_name].valid && is_form_valid;
        }
        console.log("update_role", update_role)
        this.setState({
            roles_permission: update_role,
            // is_form_valid: is_form_valid,
        });
    }

    handleClose = () => {
        this.setState({ show: false })
    };

    handleShow = () => {
        this.setState({ show: true })
    };

    GetAllRoleDetails =async () => {
        this.props.onGetAllRoleDetails().then((response) => {
            if (response.success === true) {
                const arrayData = response.data.rows;
               
                let MemberId_level = [];
                for (let key in arrayData) {
                    MemberId_level.push(arrayData[key].role_name);
                }
                this.setState({all_role_name:MemberId_level})
            }else if (response.status === 401) {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }else {
            }
        });
    };

    componentDidMount(){
        this.GetAllRoleDetails()
    }



    SubmitHandler = async (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_role = this.state.roles_permission;
        const all_role = this.state.all_role_name

        for (let key in update_role) {
            let form_element = update_role[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_role[key] = form_element;
        }
        this.setState({ roles_permission: update_role });
        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.roles_permission) {
                form_data[key] = this.state.roles_permission[key].value;
            }
            if(all_role.includes(form_data.role_name.trim())){
                toast.error("This role name is already exist", {
                    position: toast.POSITION.TOP_CENTER,
                });
            }else{
               
                this.props.onCreateRole(form_data).then((response) => {
                    if (response.success === true) {
                       this.props.GetAllRoleDetails()
                        toast.success(response.message, {
                            position: toast.POSITION.TOP_CENTER,
                        });
                        setTimeout(() => {
                            this.setState({
                                show: false,
                                isAuthenticated: true,
                                redirect_url:`/${school_code}/edit-permission`,
                            });
                        }, 500);

                        update_role.role_name.value=""
                        this.setState({roles_permission:update_role})
                    } else {
                        toast.error(response.message, {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
    
                });
            }
            

        }



    };

    render() {
        const { roles_permission } = this.state;
        // console.log("roles_permission", roles_permission.role_name)
        return (
            <>
                {/* {this.state.isAuthenticated ? <Navigate to={this.state.redirect_url} /> : null} */}
                <Button className="btn btn-sm btn-primary apply-btn add-btns addschool-button" onClick={this.handleShow}>
                    Add Role
                </Button>

                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Role</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={this.SubmitHandler}>
                            <div className="row">
                                <div className="col-lg-12 fv-row">
                                    <div className="row">

                                        <div className="col-lg-12 fv-row mb-4">
                                            <label className="fw-bold mb-2">{roles_permission.role_name?.label}</label>

                                            <input
                                                type={roles_permission.role_name?.type}
                                                value={roles_permission.role_name?.value ? roles_permission.role_name?.value : ""}
                                                className="form-control"
                                                placeholder={roles_permission.role_name?.placeholder}
                                                onChange={(event) => this.inputChangeHandler(event, "role_name")}
                                            />

                                            {/* {!oncampus_form.location_name.valid && oncampus_form.location_name.onBlur_out ? <div className="error field-error">{oncampus_form.location_name.error_msg}</div> : ""} */}
                                        </div>
                                        <div className="col-lg-12 my-4">
                                            <p className=""><strong>Note</strong> : Please be sure to add <em>Permissions</em> to the <em>Roles</em>  you are creating here, then the <em>Roles</em> will be available, when adding your Managers/Staff</p>
                                        </div>

                                        <div className="col-sm-12 mt-4 d-flex justify-content-center">
                                            <button type="submit" className="btn btn-sm btn-primary apply-btn min-150px addschool-button">
                                                Add Role
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>

                </Modal>
            </>
        );
    }
}

// function AddRollModall(props) {
//     const [show, setShow] = useState(false);
//     const [roleName, setRollName] = useState('');
//     const handleClose = () => setShow(false);
//     const handleShow = () => setShow(true);


//     const SubmitHandler = (event) => {
//         event.preventDefault();

//         // props.onCreateRole(Payload).then((response) => {
//         //     if (response.success === true) {

//         //         toast.success(response.message, {
//         //             position: toast.POSITION.TOP_CENTER,
//         //         });
//         //     } else {
//         //         toast.error(response.message, {
//         //             position: toast.POSITION.TOP_CENTER,
//         //         });
//         //     }
//         // });
//     }
//     return (
//         <>
//             <Button className="btn btn-sm btn-primary apply-btn add-btns addschool-button" onClick={handleShow}>
//                 Add Role
//             </Button>

//             <Modal show={show} onHide={handleClose}>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Add Role</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <form className="form w-100" onSubmit={() => SubmitHandler}>
//                         <div className="row">
//                             <div className="col-lg-12 fv-row mb-4">
//                                 <div className="row">


//                                     <div className="col-lg-12 fv-row mb-4">
//                                         <label className="fw-bold mb-2"></label>

//                                         <input
//                                             // type={oncampus_form.location_name.type}
//                                             // value={oncampus_form.location_name.value ? oncampus_form.location_name.value : ""}
//                                             className="form-control"
//                                         // placeholder={oncampus_form.location_name.placeholder}
//                                         // onChange={(event) => this.inputChangeHandler(event, "location_name")}
//                                         />

//                                         {/* {!oncampus_form.location_name.valid && oncampus_form.location_name.onBlur_out ? <div className="error field-error">{oncampus_form.location_name.error_msg}</div> : ""} */}
//                                     </div>



//                                     <div className="col-sm-12 mt-4 d-flex justify-content-center">
//                                         <button type="submit" className="btn btn-sm btn-primary">
//                                             Save
//                                         </button>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </form>
//                 </Modal.Body>

//             </Modal>
//         </>
//     );

// }
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
    onCreateRole: userActions.CreateRole,
    onGetAllRoleDetails:userActions.GetAllRoleDetails

    // onGetManagerPermissonDetailsById: userActions.GetManagerPermissonDetailsById,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddRollModall);
