import React, { useEffect, useState } from 'react';
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";
import SideBarLeave from "../../Constant/SideBarLeave";
import TextareaAutosize from 'react-autosize-textarea';
import * as userActions from '../../../actions/index';
import { toast } from "react-toastify";
import { connect } from "react-redux";
import MessagesList from '../../Settings/Messages/MessagesList';
const HostResponsibility = (props) => {
    const [rulesResponsibilities, setRulesResponsibilities] = useState("")
    useEffect(()=>{
        GetHostResposibilties()
    },[props])

    const GetHostResposibilties = () =>{
        props.onGetHostResposibilties().then((response)=>{
            if (response.success === true) {
                setRulesResponsibilities(response.data.host_responsibilities)
            }
        })
    }
    const inputChangeHandler = (e, identifire) => {
        setRulesResponsibilities(e.target.value)
    }
    const submitRulesResponsibilities = () => {
        const form_data = {
            host_responsibilities:rulesResponsibilities
        }
        props.onCreateHostResposibilties(form_data).then((response) => {
            if (response.success === true) {
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        })
    }
    return (
        <>
            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed" >
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <SideBarLeave />
                        <div className="wrapper d-flex flex-column flex-row-fluid " id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-fluid">
                                        <div className='card'>
                                            <div className='card-header align-items-center'>
                                                <h3 className="card-title">Responsibilities of Host</h3>
                                            </div>
                                            <div className='card-body'>
                                                <TextareaAutosize
                                                    minRows={1}
                                                    maxRows={10}
                                                    placeholder="Add Responsibilities of Host"
                                                    value={rulesResponsibilities}
                                                    onChange={(event) => inputChangeHandler(event, "rules_responsibilities")}
                                                    className="form-control min-h-100px"
                                                />
                                                <div className="d-flex align-items-end mt-8">
                                                    <button type="button" onClick={() => submitRulesResponsibilities()} className="btn btn-sm btn-primary apply-btn min-150px ms-auto">
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    <MessagesList/>
                                    </div>
                                </div>
                            </div>
                            {/* <Footer /> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onCreateHostResposibilties: userActions.CreateHostResposibilties,
    onGetHostResposibilties:userActions.GetHostResposibilties,
};
export default connect(mapStateToProps, mapDispatchToProps)(HostResponsibility);