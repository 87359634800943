import * as actionType from "../constants/actionTypes";

const initial_state = {
    error: null,
    token: null,
    loader: false,
};

const reducer = (state = initial_state, action) => {
    switch (action.type) {
        case actionType.SignIn_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
            };
        case actionType.SignIn_SUCCESS:
            return {
                ...state,
                error: null,
                loader: false,
                token: action.token,
            };
        case actionType.SignIn_FAIL:
            return {
                ...state,
                loader: action.loader,
                error: action.error,
            };

        

        default:
            return state;
    }
};

export default reducer;