import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import GetPastrolCommentItem from "./GetDiaryCommentItem/GetPastrolCommentItem"
import GetOperationalCommentItem from "./GetDiaryCommentItem/GetOperationalCommentItem"
import GetMedicalCommentItem from "./GetDiaryCommentItem/GetMedicalCommentItem"
import * as userActions from "../../../actions/index";
import MedicalComment from "./GetDiaryCommentItem/GetMedicalCommentItem";

import Form from 'react-bootstrap/Form';
import { DateRangePicker } from "mui-daterange-picker";
import Tooltip from '@mui/material/Tooltip';
import moment from "moment";



const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" :user_info.school_code;
const DiaryComment = (props) => {

    const dispatch = useDispatch();

    const [filterlimit] = useState();
    const [filterpage, setfilterpage] = useState(1);

    const [filtersearch] = useState(null);

    const [DiaryCommentOperational, setDiaryCommentOperational] = useState([]);
    const [DiaryCommentOperationalcount, setDiaryCommentOperationalcount] = useState(0);

    const [DiaryCommentPatsrol, setDiaryCommentPatsrol] = useState([]);
    const [DiaryCommentPatsrolcount, setDiaryCommentPatsrolcount] = useState(0);

    const [DiaryComment, setDiaryComment] = useState([]);
    const [DiaryCommentcount, setDiaryCommentcount] = useState(0);

    const [searchComment, setsearchComment] = useState([])
    const [pastoral_Array, setpastoral_Array] = useState([])
    const [medicalComment, setMedicalComment] = useState([])

    const [open, setOpen] = useState(false)
    const [DateRange, setDateRange] = useState("")
    const [StartDate, setStartDate] = useState("")
    const [EndDate, setEndDate] = useState("")

    const [CaseSensitive, setCaseSensitive] = useState(false)

    const navigate = useNavigate();

    const GetAllOperationalComments = useCallback(async () => {
        dispatch(userActions.GetAllOperationalComments(filterpage, filterlimit,)).then((response) => {
           
            if (response.success === true) {
                const arrayData = response.data.rows;
                let MemberId_level = [];
                const sumvalue = 1;
                for (let key in arrayData) {
                    const perpage = ((parseInt(filterpage) - parseInt(sumvalue)) * parseInt(filterlimit) + parseInt(key) + parseInt(sumvalue));
                    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
                    const timezone = user_info?.user_school?.timezone;
                    const date = moment(arrayData[key].created_date)?.tz(timezone)?.format('MMMM Do YYYY, h:mm a');

                    MemberId_level.push({
                        perpage: perpage,
                        operational_desc: arrayData[key].operational_desc,
                        created_date: date,
                        created_by: arrayData[key].operational_comment_userdata.first_name + " " + arrayData[key].operational_comment_userdata.last_name
                    });
                }
                setDiaryCommentOperational(MemberId_level);
                setDiaryCommentOperationalcount(response.data.count);
            } else {
            }
        });
    }, [dispatch, filterpage, filterlimit]);

    const GetAllDiaryPastoralComments = useCallback(async () => {
        dispatch(userActions.GetAllDiaryPastoralComments(filterpage, filterlimit,)).then((response) => {
            if (response.success === true) {
                const arrayData = response.data.rows;
                let MemberId_level = [];
                const sumvalue = 1;
                for (let key in arrayData) {
                    const perpage = ((parseInt(filterpage) - parseInt(sumvalue)) * parseInt(filterlimit) + parseInt(key) + parseInt(sumvalue));
                    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
                    const timezone = user_info?.user_school?.timezone;
                    const date = moment(arrayData[key].created_date)?.tz(timezone)?.format('MMMM Do YYYY, h:mm a');
                    MemberId_level.push({
                        perpage: perpage,
                        diary_comment_desc: arrayData[key].diary_comment_desc,
                        class_name: arrayData[key].comment_student_data.class_name,
                        student_name: arrayData[key].comment_student_data.student_first_name + " " + arrayData[key].comment_student_data.student_last_name,
                        created_date:date,
                        created_by: arrayData[key].diary_comment_userdata.first_name + " " + arrayData[key].diary_comment_userdata.last_name
                    });
                }
                setDiaryCommentPatsrol(MemberId_level);
                setpastoral_Array(MemberId_level)
                setDiaryCommentPatsrolcount(response.data.count);
            } else {
            }
        });
    }, [dispatch, filterpage, filterlimit]);

    const GetAllDiaryMedicalComments = useCallback(async (e) => {
        dispatch(userActions.GetAllDiaryMedicalComments(filterpage, filterlimit,)).then((response) => {
            console.log("medical_comment=====",response);
            if (response.success === true) {
                const arrayData = response.data.rows;
                let MemberId_level = [];
                const sumvalue = 1;
                for (let key in arrayData) {
                    const perpage = ((parseInt(filterpage) - parseInt(sumvalue)) * parseInt(filterlimit) + parseInt(key) + parseInt(sumvalue));
                    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
                    const timezone = user_info?.user_school?.timezone;
                    const date = moment(arrayData[key].created_date)?.tz(timezone)?.format('MMMM Do YYYY, h:mm a');
                    MemberId_level.push({
                        perpage: perpage,
                        diary_comment_desc: arrayData[key].diary_comment_desc,
                        class_name: arrayData[key].comment_student_data.class_name,
                        student_name: arrayData[key].comment_student_data.student_first_name + " " + arrayData[key].comment_student_data.student_last_name,
                        created_date: date,
                        is_med_issued: arrayData[key].is_med_issued,
                        diary_comment_id: arrayData[key].diary_comment_id,
                        created_by: arrayData[key].diary_comment_userdata.first_name + " " + arrayData[key].diary_comment_userdata.last_name,
                        serial_no: parseInt(key) + 1
                    });
                }
                setDiaryComment(MemberId_level);
                setDiaryCommentcount(response.data.count);
                setMedicalComment(MemberId_level)
            } else {
            }
        });
    }, [dispatch, filterpage, filterlimit]);

    useEffect(() => {
        GetAllOperationalComments();
        GetAllDiaryPastoralComments();
        GetAllDiaryMedicalComments();
    }, []);

    const searchHandler = (e) => {
        setsearchComment(e.target.value)
        if (CaseSensitive) {
            const modifyArray = DiaryCommentPatsrol.filter((item) => {
                return item.diary_comment_desc.includes(e.target.value)
            })
            setpastoral_Array(modifyArray)
            const modifyMedicslArray = DiaryComment.filter((item) => {
                return item.diary_comment_desc.includes(e.target.value)
            })
            setMedicalComment(modifyMedicslArray)
        } else {
            const modifyArray = DiaryCommentPatsrol.filter((item) => {
                return item.diary_comment_desc.toLowerCase().includes(e.target.value.toLowerCase())
            })
            setpastoral_Array(modifyArray)
            const modifyMedicslArray = DiaryComment.filter((item) => {
                return item.diary_comment_desc.toLowerCase().includes(e.target.value.toLowerCase())
            })
            setMedicalComment(modifyMedicslArray)
        }
    }

    const showDatePicker = () => setOpen(!open);
    const toggle = () => setOpen(!open);
    const DateRangePickerHandler = (range) => {
        const StartDate = new Date(range.startDate).toLocaleDateString();
        const EndDate = new Date(range.endDate).toLocaleDateString();

        const modifyPastoralArray = DiaryCommentPatsrol.filter((item) => {
            const commentDate = new Date(item.created_date).toLocaleDateString();
            if (commentDate >= StartDate && commentDate <= EndDate) {
                return true
            }
        })
        setpastoral_Array(modifyPastoralArray)

        const modifyMedicslArray = DiaryComment.filter((item) => {
            const commentDate = new Date(item.created_date).toLocaleDateString();
            if (commentDate >= StartDate && commentDate <= EndDate) {
                return true
            }
        })
        setMedicalComment(modifyMedicslArray)
        setStartDate(StartDate);
        setEndDate(EndDate)
    }

    const UpdateDiaryMedicalComment = async (form_data) => {
        props.onUpdateDiaryMedicalComments(form_data).then((response) => {
            if (response.success === true) {
                props.navigateToSnapshot();
                setTimeout(() => {
                    navigate(`/${school_code}/dashboard`, { replace: true });
                    props.get_diary_comment_modal_action(false)
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }, 50);
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    }

    return (
        <>

            <Modal
                backdrop={true}
                keyboard={false}
                className="get_diary_comment_modal confirm_modal "
                show={props.get_diary_comment_modal}
                onHide={() => {
                    props.get_diary_comment_modal_action(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Diary Comments</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div id="kt_content_container" className="container-xxl">
                        <div class="row justify-content-end">
                        <div className="col-3">
                                <Form.Control
                                    type="text"
                                    placeholder="Large text"
                                    value={StartDate + (StartDate === "" ? " Select Date Range " : "  to  ") + EndDate}
                                    readOnly
                                    onClick={() => showDatePicker()}
                                />
                                <div className="date-picker position-absolute" style={{ minWidth: "700px", zIndex: "9" }}>
                                    <DateRangePicker
                                        open={open}
                                        toggle={toggle}
                                        onChange={(range) => DateRangePickerHandler(range)}
                                    />
                                </div>
                            </div>

                            <div className="col-3">
                                <div className="d-flex align-items-center position-relative">
                                    <span className="svg-icon svg-icon-1 position-absolute ms-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                            <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                            <path
                                                d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                                fill="currentColor"
                                            ></path>
                                        </svg>
                                    </span>
                                    <input type="text" placeholder="Search Comments" onChange={(e) => searchHandler(e)} value={searchComment} className="form-control form-control-sm w-100 ps-12 pe-13 search-out fs-6" style={{ background: "transparent" }} />
                                    <span
                                        className="svg-icon svg-icon-1 position-absolute end-0 me-3 border-start ps-3 py-2 d-flex align-items-center"
                                        data-bs-toggle="tooltip" data-bs-placement="top" title="sensitive searches"
                                    >
                                        <input type="checkbox"  class="form-check-input" onChange={() => setCaseSensitive(!CaseSensitive)} />
                                        <Tooltip title="Click for CSS" placement="top">
                                            <i className="fas fa-question-circle ms-2"></i>
                                        </Tooltip>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <ul className="nav nav-tabs home-tabs fontweight mt-5" id="myTab" role="tablist">

                                    <li className="nav-item me-6" role="presentation">
                                        <button className="nav-link active" id="OperationalComment-tab" data-bs-toggle="tab" data-bs-target="#OperationalComment" type="button" role="tab" aria-controls="home" aria-selected="true">
                                            Operational Comments
                                        </button>
                                    </li>

                                    <li className="nav-item me-6" role="presentation">
                                        <button className="nav-link" id="PastrolComment-tab" data-bs-toggle="tab" data-bs-target="#PastrolComment" type="button" role="tab" aria-controls="process" aria-selected="false">
                                            Pastoral Comments
                                        </button>
                                    </li>

                                    <li className="nav-item me-6" role="presentation">
                                        <button className="nav-link" id="MedicalComment-tab" data-bs-toggle="tab" data-bs-target="#MedicalComment" type="button" role="tab" aria-controls="process" aria-selected="false">
                                            Medical Comments
                                        </button>
                                    </li>
                                </ul>
                            </div>

                           
                        </div>

                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="OperationalComment" role="tabpanel" aria-labelledby="OperationalComment-tab">
                                <div className="row">
                                    <div className="col-sm-12">
                                        {<GetOperationalCommentItem
                                            DiaryCommentOperationalcount={DiaryCommentOperationalcount}
                                            DiaryCommentOperational={DiaryCommentOperational}
                                            data={DiaryCommentOperational}
                                            propsfilterpage={(event) => setfilterpage(event)}
                                            filterlimit={filterlimit}
                                            filterpage={filterpage}
                                            searchComment={searchComment}
                                            caseSensitive={CaseSensitive}
                                        />}
                                    </div>
                                </div>
                            </div>


                            <div className="tab-pane fade" id="PastrolComment" role="tabpanel" aria-labelledby="PastrolComment-tab">
                                <div className="row">
                                    <div className="col-sm-12">
                                        {<GetPastrolCommentItem
                                            DiaryCommentPatsrolcount={DiaryCommentPatsrolcount}
                                            DiaryCommentPatsrol={DiaryCommentPatsrol}
                                            data={pastoral_Array}
                                            propsfilterpage={(event) => setfilterpage(event)}
                                            filterlimit={filterlimit}
                                            filterpage={filterpage}
                                            searchComment={searchComment}
                                            caseSensitive={CaseSensitive}
                                        />}
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="MedicalComment" role="tabpanel" aria-labelledby="MedicalComment-tab">
                                <div className="row">
                                    <div className="col-sm-12">
                                        {<GetMedicalCommentItem
                                            DiaryCommentcount={DiaryCommentcount}
                                            DiaryComment={medicalComment}
                                            data={medicalComment}
                                            propsfilterpage={(event) => setfilterpage(event)}
                                            filterlimit={filterlimit}
                                            filterpage={filterpage}
                                            searchComment={searchComment}
                                            caseSensitive={CaseSensitive}
                                            UpdateDiaryMedicalComment={(form_data) => UpdateDiaryMedicalComment(form_data)}
                                        />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
    onUpdateClassStatus: userActions.UpdateClassStatus,
    onDeleteClass: userActions.DeleteClass,
    onUpdateDiaryMedicalComments: userActions.UpdateDiaryMedicalComments,
    onGetDashboardStudentsMedicalIssued: userActions.GetDashboardStudentsMedicalIssued,
};
export default connect(mapStateToProps, mapDispatchToProps)(DiaryComment);