import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "moment-timezone";


import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { margin } from "@mui/system";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));


const useStyless = makeStyles(() => ({
    expanded: {
        marginBottom: 0,
    },
}));

const RollCallReportItem = (props) => {
    const classes = useStyles();
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    const timezone = user_info?.user_school.timezone;
    const [columns] = useState([
        {
            text: "",
            dataField: "Actions",
            formatter: (cellContent, row, index, extraData) => {
                let stdList = row.atten_data;
                return (
                    <div>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <Typography style={{ width: "100%" }}>
                                    <div className="d-flex justify-content-between w-100">
                                        <h4 className=" fs-6 d-flex font-medium">
                                            Roll Call Conducted By
                                            <span className="d-none d-lg-block bullet h-20px w-1px bg-secondary mx-4"></span>
                                            <span className=" fs-6 text-muted">
                                                {row.attend_user.first_name + " " + row.attend_user.last_name}
                                            </span>
                                            <span className="d-none d-lg-block bullet h-20px w-1px mx-4"></span>
                                            Session name
                                            <span className="d-none d-lg-block bullet h-20px w-1px bg-secondary mx-4"></span>
                                            {console.log("attendance_title",row)}
                                            <span className="fs-6 text-muted">
                                                ({row.attendance_title})
                                            </span>
                                        </h4>
                                        <h4 className="fs-6 text-muted font-medium"> ({moment.utc(row.atten_date).format('MMMM Do YYYY, h:mm a')})</h4>
                                    </div>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ padding: "0px" }}>
                                <Typography >
                                    <div className="table-responsive">
                                        <table className="table align-middle border rounded table-row-solid fs-6 g-5 dataTable no-footer table-bordered-set mb-0">
                                            <tr>
                                                <th>Roll No.</th>
                                                <th>Name</th>
                                                <th>Dormitory</th>
                                                <th>Class</th>
                                                <th>Gender</th>
                                                <th>Attendance</th>
                                            </tr>
                                            <tbody>
                                                {stdList.map(row => {
                                                    return <tr>
                                                        <td>{row.atten_student.unique_pin}</td>
                                                        <td>{row.atten_student.student_first_name + " " + row.atten_student.student_last_name}</td>
                                                        <td>{row.atten_student.dormitory_data.dormitory_name}</td>
                                                        <td>{row.atten_student.class_name}</td>
                                                        <td>{row.atten_student.gender.charAt(0).toUpperCase() + row.atten_student.gender.slice(1)}</td>
                                                        <td>{row.is_attendance === true ? <span className="text-success">Present</span>
                                                            : <span className="text-danger">Absent{" "}(
                                                                {row.attendance_reason[0] ? row.attendance_reason[0].reason_name === "SL" ? "Leave" : row.attendance_reason[0].reason_name : ""}
                                                                )
                                                            </span>
                                                        }
                                                        </td>
                                                    </tr>
                                                })}

                                            </tbody>
                                        </table>
                                    </div>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                );
            },
        },
    ]);
    const handleTableChange = (type, { sortField, sortOrder, page }) => {
        if (type === "sort") {
            props.propsfiltersort(sortField);
            props.propsfilterorder(sortOrder);
        }
    };
    const RemotePagination = ({ columns, dataField, order, data, page, sizePerPage, onTableChange, totalSize }) => (
        <PaginationProvider
            pagination={paginationFactory({
                custom: true,
                page,
                sizePerPage,
                totalSize,
            })}
        >
            {({ paginationProps, paginationTableProps }) => (
                <>
                    <div className="table-responsive">
                        <BootstrapTable
                            remote
                            bootstrap4
                            data={data}
                            keyField="student_uuid"
                            sort={{ dataField: dataField, order: order }}
                            columns={columns}
                            {...paginationTableProps}
                            onTableChange={onTableChange}
                            sizePerPageList={sizePerPage}
                            headerClasses="fw-bolder text-white bg-dark-row"
                            classes="mb-0"
                            noDataIndication={() => <span className="text-center text-gray-400">No records found</span>}
                        />
                    </div>

                </>
            )}
        </PaginationProvider>
    );

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-xl-12">


                    <div className="dataTables_wrapper dt-bootstrap4 no-footer team-list-table">
                        <div className="table-responsive">
                            <div className="card-body p-0 bg-white radious-style radious-users">
                                {/* <Spin spinning={props.loading}> */}
                                <RemotePagination
                                    data={props.data}
                                    columns={columns}
                                    page={props.filterpage}
                                    totalSize={props.StudentListcount}
                                    sizePerPage={props.filterlimit}
                                    order={props.filterorder}
                                    dataField={props.filtersort}
                                    onTableChange={handleTableChange}
                                />
                                {/* </Spin> */}
                            </div>
                        </div>
                    </div>


                </div>
            </div>

        </React.Fragment>
    );
};
export default RollCallReportItem;