import axios from "axios";
import * as actionType from "../constants/actionTypes";
let qs = require("qs");


/*** CreateOnCampusLocation Action ***/
export const CreateOnCampusLocationStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.CreateOnCampusLocation_START,
    };
};
export const CreateOnCampusLocationSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.CreateOnCampusLocation_SUCCESS,
    };
};
export const CreateOnCampusLocationFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.CreateOnCampusLocation_FAIL,
    };
};
export const CreateOnCampusLocation = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    
    return (dispatch) => {
        dispatch(CreateOnCampusLocationStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/CreateOnCampusLocation`,
        })
            .then(function (response) {
                dispatch(CreateOnCampusLocationSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateOnCampusLocationFail("Something went wrong, Please try again."));
                return error;
            });
    };
};




/*** GetAllOnCampusLocation Action ***/
export const GetAllOnCampusLocationStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllOnCampusLocation_START,
    };
};
export const GetAllOnCampusLocationSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllOnCampusLocation_SUCCESS,
    };
};
export const GetAllOnCampusLocationFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllOnCampusLocation_FAIL,
    };
};
export const GetAllOnCampusLocation = (page, limit, sort, order ) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllOnCampusLocationStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllOnCampusLocation`,
            params: {
                page: page,
                limit: limit,
                sort: sort,
                order: order,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllOnCampusLocationSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllOnCampusLocationFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** UpdateOnCampusLocationStatus Action ***/

export const UpdateOnCampusLocationStatusStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateOnCampusLocationStatus_START,
    };
};

export const UpdateOnCampusLocationStatusSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateOnCampusLocationStatus_SUCCESS,
    };
};

export const UpdateOnCampusLocationStatusFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateOnCampusLocationStatus_FAIL,
    };
};

export const UpdateOnCampusLocationStatus = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(UpdateOnCampusLocationStatusStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/UpdateOnCampusLocationStatus`,
        })
            .then(function (response) {
                dispatch(UpdateOnCampusLocationStatusSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateOnCampusLocationStatusFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** DeleteOnCampusLocation Action ***/

export const DeleteOnCampusLocationStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.DeleteOnCampusLocation_START,
    };
};
export const DeleteOnCampusLocationSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.DeleteOnCampusLocation_SUCCESS,
    };
};
export const DeleteOnCampusLocationFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.DeleteOnCampusLocation_FAIL,
    };
};
export const DeleteOnCampusLocation = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(DeleteOnCampusLocationStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "delete",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
                
            },
            url: `${actionType.environment_url}/api/bss-admin/DeleteOnCampusLocation`,
        })
            .then(function (response) {
                console.log("resposne", response.data);
                dispatch(DeleteOnCampusLocationSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                console.log("resposne fail", error);
                dispatch(DeleteOnCampusLocationFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetOnCampusLocationById Action ***/
export const GetOnCampusLocationByIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetOnCampusLocationById_START,
    };
};
export const GetOnCampusLocationByIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetOnCampusLocationById_SUCCESS,
    };
};
export const GetOnCampusLocationByIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetOnCampusLocationById_FAIL,
    };
};
export const GetOnCampusLocationById = (on_campus_location_uuid) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

   
    return (dispatch) => {
        dispatch(GetOnCampusLocationByIdStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetOnCampusLocationById`,
            params: {
                on_campus_location_uuid: on_campus_location_uuid,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetOnCampusLocationByIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetOnCampusLocationByIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** UpdateOnCampusLocationDetails Action ***/

export const UpdateOnCampusLocationDetailsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateOnCampusLocationDetails_START,
    };
};
export const UpdateOnCampusLocationDetailsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateOnCampusLocationDetails_SUCCESS,
    };
};
export const UpdateOnCampusLocationDetailsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateOnCampusLocationDetails_FAIL,
    };
};

export const UpdateOnCampusLocationDetails = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(UpdateOnCampusLocationDetailsStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/UpdateOnCampusLocationDetails`,
        })
            .then(function (response) {
                dispatch(UpdateOnCampusLocationDetailsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateOnCampusLocationDetailsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};






