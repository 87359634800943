import axios from "axios";
import * as actionType from "../constants/actionTypes";
let qs = require("qs");



/*** GetAllDuplicateStudents Action ***/

export const GetAllDuplicateStudentsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllDuplicateStudents_START,
    };
};
export const GetAllDuplicateStudentsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllDuplicateStudents_SUCCESS,
    };
};
export const GetAllDuplicateStudentsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllDuplicateStudents_FAIL,
    };
};
export const GetAllDuplicateStudents = (sort, order, is_student_activate,search,groupBy) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllDuplicateStudentsStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllDuplicateStudents`,
            params: {
                // page: page,
                // limit: limit,
                sort: sort,
                order: order,
                search: search,
                is_student_activate:is_student_activate,
                groupBy:groupBy

            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllDuplicateStudentsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllDuplicateStudentsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** UpdateStudentDuplicateStatus Action ***/

export const UpdateStudentDuplicateStatusStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateStudentDuplicateStatus_START,
    };
};

export const UpdateStudentDuplicateStatusSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateStudentDuplicateStatus_SUCCESS,
    };
};

export const UpdateStudentDuplicateStatusFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateStudentDuplicateStatus_FAIL,
    };
};

export const UpdateStudentDuplicateStatus = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(UpdateStudentDuplicateStatusStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/UpdateStudentDuplicateStatus`,
        })
            .then(function (response) {
                dispatch(UpdateStudentDuplicateStatusSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateStudentDuplicateStatusFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

