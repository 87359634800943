import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";


const DelteAdhocLeaveModal = (props) => {
  const [showModal, setShowModal] = useState(false);
    useEffect(()=>{
        setShowModal(props.confirmDeleteModal)
    },[])
    const closeModal = () => {
        props.hideDeleteAdhostModal(false)
    }
    const deleteAdhocLeave = () => {
        props.deleteAdhocLeaveById(props.adhocDelete)
    }
  
  return (
    <div>
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Ad-Hoc leave confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
            <p>Are You sure , Do you want to delete Adhoc Leave</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-sm px-7  me-2" onClick={closeModal}>Cancel</Button>
          <Button className="btn-sm px-7 btn-danger" onClick={deleteAdhocLeave}>Delete</Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default DelteAdhocLeaveModal;
