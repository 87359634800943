import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import Sidebar from "../Constant/sidebar";
import Footer from "../Constant/Footer";
import TopBar from "../Constant/TopBar";
import { useNavigate } from "react-router-dom";



const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" :user_info?.school_code;

function NoPermissionPage() {
    const navigate = useNavigate();
    return (
        <>
            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <Sidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid heding-element" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-fluid">
                                        <div className="row p-5">
                                            <div className="col-12">
                                                <div className="d-flex flex-column align-items-center gap-5 py-5">
                                                    <img src="/media/no_permission.png" className="w-400px" />
                                                    <h3>opps! We can't let you in here.</h3>
                                                    <p>Please ask your account adminstrator for access.</p>
                                                    <div className="d-flex gap-3">
                                                        <button type="button" onClick={()=>navigate(`/${school_code}/dashboard`)} class="btn btn-primary btn-sm w-200px">Go To Dashboard</button>
                                                        <button type="button" onClick={()=>navigate(`/${school_code}/activities`)} class="btn btn-secondary btn-sm w-200px">Go Back</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(NoPermissionPage);
