import axios from "axios";
import * as actionType from "../../constants/actionTypes";
let qs = require("qs");

/*** CreateActivity Action ***/
export const CreateActivityStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.CreateActivity_START,
    };
};
export const CreateActivitySuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.CreateActivity_SUCCESS,
    };
};
export const CreateActivityFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.CreateActivity_FAIL,
    };
};
export const CreateActivity = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    
    return (dispatch) => {
        dispatch(CreateActivityStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/CreateActivity`,
        })
            .then(function (response) {
                dispatch(CreateActivitySuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateActivityFail("Something went wrong, Please try again."));
                return error;
            });
    };
};
/*** GetAllActivity Action ***/
export const GetAllActivityStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllActivity_START,
    };
};
export const GetAllActivitySuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllActivity_SUCCESS,
    };
};
export const GetAllActivityFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllActivity_FAIL,
    };
};
export const GetAllActivity = (page, limit, sort, order,search, getAllActivity) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllActivityStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url:getAllActivity ? `${actionType.environment_url}/api/bss-admin/GetAllActivity` :`${actionType.environment_url}/api/Bss-admin/GetAllActivityForAdhocLeave`,
            params: {
                page: page,
                limit: limit,
                sort: sort,
                order: order,
                search:search,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllActivitySuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllActivityFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** DeleteActivity ***/


export const DeleteActivityStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.DeleteActivity_START,
    };
};
export const DeleteActivitySuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.DeleteActivity_SUCCESS,
    };
};
export const DeleteActivityFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.DeleteActivity_FAIL,
    };
};


export const DeleteActivity = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(DeleteActivityStart());
        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        return axios({
            method: "delete",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url:`${actionType.environment_url}/api/bss-admin/DeleteActivity`,
   
        })
        .then(function (response) {
            dispatch(DeleteActivitySuccess(response.data));
            return response.data;
        })
        .catch(function (error) {
            dispatch(DeleteActivityFail("Something went wrong, Please try again."));
            return error;
        });
    };
};


/*** UpdateActivityStatus ***/

export const UpdateActivityStatusStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateActivityStatus_START,
    };
};

export const UpdateActivityStatusSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateActivityStatus_SUCCESS,
    };
};

export const UpdateActivityStatusFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateActivityStatus_FAIL,
    };
};

export const UpdateActivityStatus = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(UpdateActivityStatusStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info?.country_host ,
                school_code: user_info?.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/UpdateActivityStatus`,
        })
            .then(function (response) {
                dispatch(UpdateActivityStatusSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateActivityStatusFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** UpdateActivity ***/

export const UpdateActivityStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateActivity_START,
    };
};

export const UpdateActivitySuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateActivity_SUCCESS,
    };
};

export const UpdateActivityFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateActivity_FAIL,
    };
};

export const UpdateActivity = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(UpdateActivityStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info?.country_host ,
                school_code: user_info?.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/UpdateActivity`,
        })
            .then(function (response) {
                dispatch(UpdateActivitySuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateActivityFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

