import axios from "axios";
import * as actionType from "../constants/actionTypes";

export const GetState = () => {
    return new Promise((resolve) => {
        axios({
            method: "get",
            url: `${actionType.environment_url}/api/GetAllIndiaStates`,
        })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                resolve(error);
            });
    });
};

export const GetCity = (state_id) => {
    return new Promise((resolve) => {
        axios({
            method: "get",
            url: `${actionType.environment_url}/api/GetCitiesStateWise`,
            params: {
                state_id: state_id,
            },
        })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                resolve(error);
            });
    });
};

export const GetAllCountries = () => {
    return new Promise((resolve) => {
        axios({
            method: "get",
            url: `${actionType.environment_url}/api/GetAllCountries`,
        })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                resolve(error);
            });
    });
};


export const GetAllTimezonesByCountryCode = (country_code) => {
    return new Promise((resolve) => {
        axios({
            method: "get",
            url: `${actionType.environment_url}/api/GetAllTimezonesByCountryCode`,
            params: {
                country_code: country_code,
            },
        })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                resolve(error);
            });
    });
};

export const GetStatesCountryWise = (country_id) => {
    return new Promise((resolve) => {
        axios({
            method: "get",
            url: `${actionType.environment_url}/api/GetStatesCountryWise`,
            params: {
                country_id: country_id,
            },
        })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                resolve(error);
            });
    });
};

export const GetAllTemplesByCityId = (city_id) => {
    return new Promise((resolve) => {
        axios({
            method: "get",
            url: `${actionType.environment_url}/api/GetAllTemplesByCityId`,
            params: {
                city_id: city_id,
            },
        })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                resolve(error);
            });
    });
};


export const GetAllTemplesByStateId = (state_id) => {
    return new Promise((resolve) => {
        axios({
            method: "get",
            url: `${actionType.environment_url}/api/GetAllTemplesByStateId`,
            params: {
                state_id: state_id,
            },
        })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                resolve(error);
            });
    });
};

export const GetAllSchoolsByStateId = (state_id) => {
    return new Promise((resolve) => {
        axios({
            method: "get",
            url: `${actionType.environment_url}/api/GetAllSchoolsByStateId`,
            params: {
                state_id: state_id,
            },
        })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                resolve(error);
            });
    });
};



export const GetAllCountryRegions = () => {
    return new Promise((resolve) => {
        axios({
            method: "get",
            url: `${actionType.environment_url}/api/GetAllCountryRegions`,
        })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                resolve(error);
            });
    });
};
