import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

const IgnoreStudentmodal = ({ modalState, closeIgnoreModal, handleChangeNonDuplicate }) => {
  const [modalOpen, setModalOpen] = useState(modalState);
  useEffect(() => {
    setModalOpen(modalState);
  }, [modalState]);
  return (
    <Modal
      backdrop="static"
      keyboard={false}
      className="confirm_delete_modal confirm_modal"
      show={modalOpen}
      aria-labelledby="md-modal"
    >
      <Modal.Header>
        <Modal.Title>Ignore Student</Modal.Title>
        <span className="pointer" onClick={closeIgnoreModal}>
          <i className="fas fa-times me-2 fs-6" ></i>
        </span>
      </Modal.Header>
      <Modal.Body>
        <div className="row m-2">
          <p className="text-center">
            Do you want to proceed with ignoring this student? Ignoring this student will move them to the student list and they will no longer be shown on this page.            </p>
        </div>
        <div className="col-sm-12 mt-4 d-flex justify-content-end">
          <button type="button"
            className="btn btn-sm btn-secondary me-3"
            onClick={closeIgnoreModal}
          >
            Cancel
          </button>
          <button type="button"
            className="btn btn-sm btn-primary me-3"
            onClick={handleChangeNonDuplicate}
          >
            Ok
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default IgnoreStudentmodal;