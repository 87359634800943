import React, { useEffect } from "react";

const Picture = ({cam_not_available,take_photo_by_props}) => {
    console.log("props_data",cam_not_available);
    const constraints = { facingMode: 'environment', "video": { width: { exact: 400 } }, advanced: [{ focusMode: "continuous" }] };
    let para;
    var imageCapturer;

    const start = async (value) => {
        navigator.mediaDevices.getUserMedia({ video: constraints })
            .then(gotMedia)
            .catch(e => { 
                cam_not_available(null)
            });
    }

    useEffect(() => {
        start();
        para = document.createElement("video");
        para.setAttribute("id", "video-tag")
        document.getElementById("add_div").appendChild(para)
    }, [])


    const gotMedia = (MediaStream) => {
        para.srcObject = MediaStream;
        para.play()
        var videoTrack = MediaStream.getVideoTracks()[0];
        imageCapturer = new ImageCapture(videoTrack);
    }
    const takePhoto = (identifier) => {

        imageCapturer.takePhoto().then((blob) => {
                if (identifier === "take_photo") {
                    take_photo_by_props(blob, "student_avatar")
                } else {
                    take_photo_by_props(blob, "cancel")
                }
                let stream = para.srcObject;
                let tracks = stream.getTracks();
                tracks.forEach(track => track.stop());
                tracks[0].stop()
                para.srcObject = null;
            })
            .catch((err) => {

                console.error("takePhoto() failed: ", err);
            });



    }



    return (
        <>
            <div>
                <div style={{ float: "left" }} id="add_div">
                    {/* <video id="video-tag" style={{ width: "600px", height: "450px", border: "2px solid #000" }} autoplay={true} /> */}
                </div>
            </div>
            <div className="mt-5">
                <p>
                    <button
                        type="button"
                        className="btn btn-sm btn-secondary apply-btn min-150px m-5"
                        onClick={() => takePhoto("cancel")}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="btn btn-sm btn-primary apply-btn min-150px m-5"
                        onClick={() => takePhoto("take_photo")}
                    >
                        Take Photo
                    </button>
                </p>
            </div>
        </>
    )
}


export default Picture