import React, { useState, useEffect } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import Highlighter from "react-highlight-words";
import Pagination from '@mui/material/Pagination';

import Stack from '@mui/material/Stack';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';

import { useNavigate } from "react-router-dom";
import parse from 'html-react-parser';


const firstIndex = 0;
const MedicalComment = (props) => {

    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const [data, setData] = useState(props.data.slice(firstIndex, pageSize));

    const row_limit = [
        { value: 5, label: 5 },
        { value: 10, label: 10 },
        { value: 15, label: 15 },
        { value: 20, label: 20 },
        { value: 25, label: 25 }
    ]

    useEffect(() => {
        setData(props.data.slice(0, pageSize));
    }, [pageSize]);

    const handleChange = (event, value) => {
        setPage(value);
        setData(props.data.slice(firstIndex + pageSize * (value - 1), pageSize * value));
    };

    //Change width
    const changeWidth = (e, value) => {
        setPageSize(parseInt(e.value, 10));
    };

    let searchArray = []
    if (props?.searchComment?.length > 0) {
        searchArray.push(props?.searchComment)
    }

    const changeInputHandler = (event, comment_id, student_name) => {
        const form_data = {
            is_med_issued: event.target.checked,
            diary_comment_id: comment_id,
            student_name: student_name
        }
        props.UpdateDiaryMedicalComment(form_data)
    }

    return (
        <React.Fragment>
            <div className="row gy-5 g-xl-8">
                <div className="col-xl-12">
                  
                     
                                <div id="kt_profile_overview_table" className="dataTables_wrapper dt-bootstrap4 no-footer team-list-table">
                                    <div className="table-responsive">
                                        <div className="bg-white radious-style radious-users  mt-5">
                                            <MDBTable className="">
                                                <MDBTableHead>
                                                    <tr>
                                                        <th style={{ width: "4%" }} scope='col'>S.No.</th>
                                                        <th style={{ width: "12%" }} scope='col'>Student Name</th>
                                                        <th style={{ width: "6%" }} scope='col'>Class</th>
                                                        <th style={{ width: "auto" }} scope='col'>Medical Comments</th>
                                                        <th style={{ width: "15%" }} scope='col'>Is Medication Issued</th>
                                                    </tr>
                                                </MDBTableHead>
                                                <MDBTableBody>
                                                    {data.length === 0 ?
                                                        props.data.slice(0, 10).map((item, index) => {
                                                            console.log("all_data=============", item);
                                                            return (
                                                                <tr>
                                                                    <th scope='row'>{item.serial_no}</th>
                                                                    <td>{item.student_name}</td>
                                                                    <td>{item.class_name}</td>
                                                                    <td>
                                                                        <div>
                                                                            <span className="fw-bold">{item.created_date}</span>
                                                                            <span className="fw-bold ms-3">{item.created_by} :-</span>
                                                                        </div>
                                                                        <div>

                                                                            <Highlighter
                                                                                highlightStyle={{ backgroundColor: "#ffe00096", borderRadius: "25px" }}
                                                                                highlightClassName="YourDiaryHighlightClass"
                                                                                searchWords={[...searchArray]}
                                                                                autoEscape={true}
                                                                                textToHighlight={item.diary_comment_desc}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        {item.is_med_issued ?
                                                                            <input
                                                                                type="checkbox"
                                                                                readOnly
                                                                                checked={item.is_med_issued}
                                                                                class="form-check-input widget-9-check align-middle"
                                                                            /> :
                                                                            <input
                                                                                type="checkbox"
                                                                                onChange={(event) => changeInputHandler(event, item.diary_comment_id, item.student_name)}
                                                                                // checked={item.is_med_issued?true:""}
                                                                                class="form-check-input widget-9-check align-middle"
                                                                            />
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }) : data.map((item, index) => {
                                                            return (
                                                                <tr>
                                                                    <th scope='row'>{item.serial_no}</th>
                                                                    <td>{item.student_name}</td>
                                                                    <td>{item.class_name}</td>
                                                                    <td>
                                                                        <div>
                                                                            <span className="fw-bold">{item.created_date}</span>
                                                                            <span className="fw-bold ms-3">{item.created_by} :-</span>
                                                                        </div>
                                                                        <div>

                                                                            <Highlighter
                                                                                highlightStyle={{ backgroundColor: "#ffe00096", borderRadius: "25px" }}
                                                                                highlightClassName="YourDiaryHighlightClass"
                                                                                searchWords={[...searchArray]}
                                                                                autoEscape={true}
                                                                                textToHighlight={item.diary_comment_desc}
                                                                                caseSensitive={props.caseSensitive}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        {item.is_med_issued ?
                                                                            <input
                                                                                type="checkbox"
                                                                                readOnly
                                                                                checked={item.is_med_issued}
                                                                                class="form-check-input widget-9-check align-middle"
                                                                            /> :
                                                                            <input
                                                                                type="checkbox"
                                                                                onChange={(event) => changeInputHandler(event, item.diary_comment_id, item.student_name)}
                                                                                // checked={item.is_med_issued?true:""}
                                                                                class="form-check-input widget-9-check align-middle"
                                                                            />
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                </MDBTableBody>
                                            </MDBTable>
                                            <div className="d-flex justify-content-between">
                                                <Select
                                                    menuPlacement="top"
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    defaultValue={row_limit[1]}
                                                    name="color"
                                                    options={row_limit}
                                                    onChange={changeWidth}
                                                />
                                                <Stack spacing={1} >
                                                    <Pagination
                                                        count={Math.ceil(props.data.length / pageSize)}
                                                        page={page}
                                                        onChange={handleChange}
                                                        color="primary"
                                                    />
                                                </Stack>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    
                 
            </div>
        </React.Fragment>
    );
};
export default MedicalComment;