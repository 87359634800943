import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import Footer from "../../Constant/Footer";
import SideBarLeave from "../../Constant/SideBarLeave";
import TopBar from "../../Constant/TopBar";
import { Link } from "react-router-dom";

import AddHocActivityItems from "./AdHocActivityItems";
import * as userActions from "../../../actions/index";

// import DeleteConfirmRemove from "../../Utility/DeleteConfirmRemove";
import DeleteActivityModal from "../LeaveModals/DeleteActivityModal";

import { toast } from "react-toastify";

const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info.school_code;

const ManageAddActivity = (props) => {
    const dispatch = useDispatch();
    const [filterpage, setfilterpage] = useState(1);
    const [filtersearch, setfiltersearch] = useState(null);

    const [filterlimit, setfilterlimit] = useState(10);
    const [filterorder] = useState("asc");
    const [filtersort] = useState("activity_name");


    const [addActivityList, setActivityList] = useState([]);
    const [addActivityListcount, setActivityListcount] = useState(0);
    const [editActivitydata, seteditActivitydata] = useState([]);
    const [confirm_modal, setconfirm_modal] = useState(false);
    const [getAllActivity, setGetAllActivity] = useState(true); 


    const GetAllActivity = useCallback(async () => {
        dispatch(userActions.GetAllActivity(filterpage, filterlimit, filtersort, filterorder, filtersearch, getAllActivity)).then((response) => {
            if (response.success === true) {
                const arrayData = response.data.rows;
                let MemberId_level = [];
                const sumvalue = 1;
                for (let key in arrayData) {
                    const perpage = ((parseInt(filterpage) - parseInt(sumvalue)) * parseInt(filterlimit) + parseInt(key) + parseInt(sumvalue));

                    MemberId_level.push({
                        perpage: perpage,
                        activity_name: arrayData[key].activity_name,
                        is_activity_activate: arrayData[key].is_activity_activate,
                        activity_uuid: arrayData[key].activity_uuid,
                    });
                }
                setActivityList(MemberId_level);
                setActivityListcount(response.data.count);
            } else {
            }
        });
    }, [dispatch, filterpage, filterlimit, filtersort, filterorder, filtersearch]);

    useEffect(() => {
        GetAllActivity();
    }, [GetAllActivity, filterpage, filterlimit, filtersort, filterorder, filtersearch]);

    const showAddModalHandle = (event) => {
        setconfirm_modal(false);
    };

    const RemoveCourseModule = (event, course_data) => {
        seteditActivitydata(course_data);
        setconfirm_modal(true);
    };

    const ConfirmRemove = (form_data) => {
        setconfirm_modal(false);
        props.onDeleteActivity(form_data).then((response) => {
            if (response.success === true) {
                GetAllActivity();
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                setconfirm_modal(false);
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    };

    const handleChange = async (event,is_activity_activate,activity_uuid) => {
        const Payload = {
            is_activity_activate : !is_activity_activate,
            activity_uuid : activity_uuid,
        };
        props.onUpdateActivityStatus(Payload).then((response) => {
            if (response.success === true) {
                GetAllActivity();
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    }



    return (
        <>
            {confirm_modal && (
                <DeleteActivityModal
                    confirm_modal={confirm_modal}
                    editActivitydata={editActivitydata}
                    delete_confirm_modal_action={(modal_action) => showAddModalHandle(modal_action)}
                    ConfirmRemove={(event) => ConfirmRemove(event)}
                />
            )}
            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed" >
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <SideBarLeave />
                        <div className="wrapper d-flex flex-column flex-row-fluid " id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">

                                <div className="post d-flex flex-column-fluid" id="kt_post">

                                    <div id="kt_content_container" className="container-fluid d-flex justify-content-center">
                                     

                                            <div className="card">
                                                <div className="card-header border-bottom pt-5 pb-5">
                                                    <h3 className="card-title align-items-start flex-column">
                                                      Activity Details
                                                    </h3>
                                                    <Link to={`/${school_code}/leave/ad-hoc-activity-off-campus/add-ad-hoc-activity`} className="btn btn-sm btn-primary" >Add Activity</Link>
                                                </div>
                                                <div className="p-6 pb-0 d-flex align-items-center justify-content-end">
                                                    <div className="d-flex align-items-center position-relative">
                                                        <span className="svg-icon svg-icon-1 position-absolute ms-4">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><rect opacity="1" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="#999"></rect><path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path></svg></span><input type="text" className="form-control form-control-sm w-350px ps-12 search-out fs-6" placeholder="Search " style={{ background: "transparent" }}


                                                                onChange={(event) => {
                                                                    setfiltersearch(event.target.value);
                                                                    setfilterpage(1);
                                                                }}
                                                        /></div>
                                                </div>



                                                {<AddHocActivityItems
                                                    addActivityListcount={addActivityListcount}
                                                    addActivityList={addActivityList}
                                                    data={addActivityList}
                                                    propsfilterpage={(event) => setfilterpage(event)}
                                                    RemoveCourseModule={(event, data) => RemoveCourseModule(event, data)}
                                                    handleChange={(event,is_activity_activate, activity_uuid) => handleChange(event,is_activity_activate,activity_uuid)}
                                                    filterlimit={filterlimit}
                                                    filterpage={filterpage}
                                                    propsfilterlimit={(filterlimit) => {
                                                        setfilterlimit(filterlimit);
                                                    }}
                                                />}
                                            </div>
                                        

                                    </div>
                                </div>
                                </div>
                                <Footer />
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
    onUpdateActivityStatus: userActions.UpdateActivityStatus,
    onDeleteActivity: userActions.DeleteActivity,
};
export default connect(mapStateToProps, mapDispatchToProps)(ManageAddActivity);