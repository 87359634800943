import React from "react";
import { Modal } from "react-bootstrap";
// import Select from "react-select";
import Validations from "../../../Utility/Validations";
import { connect } from "react-redux";
import * as userActions from "../../../../actions/index";

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#e7f5fe" : "",
        },
        backgroundColor: state.isSelected ? "#4a8fb8" : "",
    }),
};

class AddArchiveModal extends React.Component {
    constructor(props) {
        super(props);
        console.log(props.handleChange,"::SDFdf")
        this.state = {
            archive_form: {
                student_name: {
                    type: "text",
                    label: "",
                    value:"",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                archived_note: {
                    type: "text",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: true },
                },
                student_uuid: {
                    type: "text",
                    label: "student_uuid",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },
                is_student_activate: {
                    type: "text",
                    label: "student_uuid",
                    value: false,
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },
            },

        };
    }

    componentDidMount() {
        console.log("props",this.props);
        const archive_data = localStorage.getItem('archive_data');
        const update_archive_form = { ...this.state.archive_form };
        update_archive_form['student_name'].value = this.props.userName;
        update_archive_form['student_uuid'].value = this.props.userUuid;
        update_archive_form['is_student_activate'].value = this.props.userActivate;
        this.setState({archive_form:update_archive_form})

    }

    inputChangeHandler(event, identifier) {
        const update_archive_form = { ...this.state.archive_form };
        const form_element = { ...update_archive_form[identifier] };
        form_element.value = event.target.value;
        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_archive_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_archive_form) {
            is_form_valid = update_archive_form[identifier].valid && is_form_valid;
        }
        this.setState({
            archive_form: update_archive_form,
            is_form_valid: is_form_valid,
        });
    }

    SubmitHandler = (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_archive_form = this.state.archive_form;
        console.log(update_archive_form);


        for (let key in update_archive_form) {
            let form_element = update_archive_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_archive_form[key] = form_element;
        }
        this.setState({ archive_form: update_archive_form });

        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.archive_form) {
                form_data[key] = this.state.archive_form[key].value;
            }
            this.props.ArchiveOrUnarchiveManager(form_data);
        }
    };

    render() {
        let curr = new Date();
        curr.setDate(curr.getDate());
        let date = curr.toISOString().substring(0, 10);

        const { archive_form } = this.state;
        console.log(archive_form);


        return (
            <React.Fragment>
                <Modal
                    backdrop="static"
                    keyboard={false}
                    className="add_archive_modal confirm_modal"
                    show={this.props.add_archive_modal}
                    onHide={() => {
                        this.props.add_archive_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Archive Student</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="form w-100" onSubmit={this.SubmitHandler}>
                            <div className="row">
                                <div className="col-lg-12 fv-row">
                                    <div className="row">

                                        {/* <div className="col-sm-12 mb-5">
                                            <label className="fw-bold mb-2">Release Date </label>
                                           
                                            <input
                                                styles={customStyles}
                                                type={archive_form.last_archived_date.type}
                                                name={"last_archived_date"}
                                                className="form-control input_field"
                                                onChange={(event) => this.inputChangeHandler(event, "last_archived_date")}
                                                max={date}
                                            />
                                            {!archive_form.last_archived_date.valid ? <div className="error field-error">{archive_form.last_archived_date.error_msg}</div> : ""}
                                        </div> */}
                                    

                                        <div className="col-sm-12">
                                            <label className="fw-bold mb-2">Note</label>
                                            <textarea
                                              
                                               
                                                type={archive_form.archived_note.type}
                                                className="form-control input_field autoresizing1"
                                                onChange={(event) => this.inputChangeHandler(event, "archived_note")}
                                            />
                                            {!archive_form.archived_note.valid ? <div className="error field-error">{archive_form.archived_note.error_msg}</div> : ""}
                                        </div>


                                        <input
                                            value={archive_form.student_uuid.value ? archive_form.student_uuid.value : ""}
                                            type="hidden"
                                            className="react-bootstrap-typeahead tag-outer"
                                            onChange={(event) => this.inputChangeHandler(event, "student_uuid")}
                                        />

                                        <input
                                            value={archive_form.is_student_activate.value ? archive_form.is_student_activate.value : ""}
                                            type="hidden"
                                            className="react-bootstrap-typeahead tag-outer"
                                            onChange={(event) => this.inputChangeHandler(event, "is_student_activate")}
                                        />



                                        <div className="col-sm-12 mt-4 d-flex justify-content-end">
                                            <button type="submit" className="btn btn-sm btn-primary">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}

const mapDispatchToProps = {

    onGetSchoolManagerById: userActions.GetSchoolManagerById,

};
export default connect(mapStateToProps, mapDispatchToProps)(AddArchiveModal);

