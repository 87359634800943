// import { toast } from "react-toastify";

const Validation = (form_element) => {    
    let value = form_element.value;
    let rule = form_element.validations;
    console.log("rule",rule);

    if (!rule.required) {
        return {
            valid: true,
            error_msg: "",
        };
    }

    value = typeof value === "object" ? JSON.stringify(value) : value;
    value = typeof value === "boolean" ? JSON.stringify(value) : value;

    if (rule.required) {
        if (value == null || (typeof value === "string" && value.trim() === "") || (typeof value === "number" && value === "")) {
            // toast.error("Please complete required fields", {
            //     position: toast.POSITION.TOP_CENTER,
            // });
            return {
                
                valid: false,
                error_msg: "This field is required!",
                    
            };
        }
    }

    // If filed is email
    if (rule.email) {
        const is_email = value.match(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (!is_email) {
            return {
                valid: false,
                error_msg: "Email format is incorrect.",
            };
        }
    }

    if (rule.password) {
        const isStrong =  value.match(
            "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})"
            );
            
            if (!isStrong) {
                return {
                    valid: false,
                    error_msg: ("Your password must be at least 8 characters long, contain at least one special character and have a mixture of uppercase, lowercase and numbers.")
                    
                };
            }
    }


    if (rule.schoolcode) {
        const isStrong =  value.match(
            "^(?=.*[!@#%&-])"
            );
            
            if (isStrong) {
                return {
                    valid: false,
                    error_msg: ("Please do not use Special Character.")
                    
                };
            }
    }


    // const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

    if (rule.number) {
        const is_number = /^[0-9]+$/.test(value)
        if (!is_number) {
            return {
                valid: false,
                error_msg: "Please enter numeric value.",
            };
        }
    }
    // Validate min length
    if (rule.min_length) {
        if (value.length < rule.min_length) {
            return {
                valid: false,
                error_msg: "Field length minimum " + rule.min_length + "!",
                // error_msg: "You may enter up to " + rule.min_length + " characters here",
            };
        }
    }

    if (rule.max_length) {
        if (value.length > rule.max_length) {
            return {
                valid: false,
                // error_msg: "Field length minimum " + rule.min_length + "!",
                error_msg: "You may enter up to " + rule.max_length + " characters here",
            };
        }
    }

    if (rule.min_length_phone) {
        if (value.length < rule.min_length_phone) {
            return {
                valid: false,
                error_msg: "Contact number length is too short !",
            };
        }
    }


    // Validate min length
    if (rule.min_length) {
        if (value.length < rule.min_length) {
            return {
                valid: false,
                error_msg: "Field length minimum " + rule.min_length + "!",
            };
        }
    }
    // Validate Phone Number length
    if (rule.phone_length) {
       
        if (value.length > rule.phone_length) {
            return {
                valid: false,
                error_msg: "Field length minimum " + rule.min_length + "!",
                //error_msg: "Invalid phone format. ex: " + form_element.placeholder,
            };
        }
    }

    if (rule.typeahead) {
        if (value === "[]") {
            return {
                valid: false,
                error_msg: "This field is required!",
            };
        }
    }

    return {
        valid: true,
        error_msg: "",
    };
};
export default Validation;