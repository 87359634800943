import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";

const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" :user_info?.school_code;
const StudentItem = (props) => {
    const permission_info = JSON.parse(localStorage.getItem("permissions"));
    console.log("props_data", permission_info);
    const [perpagedata, setperpagedata] = useState(50);
    const [columns] = useState([


        {
            text: "",
            dataField: "Actions",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <div key={"repeat_" + row.country_id}>
                        <div className="card-1 p-6 mb-4 rounded bg-light-info">
                            <div className="d-flex flex-stack mb-3">
                                <h3 className="mb-0 text-primary superadmin-headings link-color">
                                    {permission_info?.view_student !== false ?
                                        (<Link to={`/${school_code}/student/student-list/student-profile/${row.student_uuid}`}>
                                            {row.student_first_name + " " + row.student_last_name}
                                        </Link>)
                                        :
                                        (row.student_first_name + " " + row.student_last_name)
                                    }
                                </h3>
                                <div className="d-flex align-items-center">
                                    {row.is_student_activate === true ? (
                                        <span className="badge badge-light-success px-4 py-2">Active</span>
                                    ) : (
                                        <span className="badge badge-light-danger px-4 py-2">Inactive</span>
                                    )}

                                </div>
                            </div>




                            <div className="d-flex text-secondary align-items-center mb-3">
                                <div className="d-flex align-items-center">
                                    <Tooltip title="Student Unique Pin" placement="top">
                                        <i className="fas fa-list-ol me-2 fs-6"></i>
                                    </Tooltip>
                                    <p className="mb-0 heding-element me-4 ">{row.unique_pin} </p>
                                </div>
                                <br />

                                <div className="d-flex align-items-center">
                                    <Tooltip title="Student Year Description" placement="top">
                                        <i className="fas fa-chalkboard-teacher me-2 fs-6"></i>
                                    </Tooltip>
                                    <p className="mb-0 heding-element me-4">{row.class_name} </p>
                                </div>
                                <br />

                                <div className="d-flex align-items-center">
                                    <Tooltip title="Student Dormitory" placement="top">
                                        <i className="fas fa-building me-2 fs-6"></i>
                                    </Tooltip>
                                    <p className="mb-0 heding-element me-4">{row.dormitory_data.dormitory_name} </p>
                                </div>
                            </div>




                            <div className="d-flex text-secondary align-items-center">
                                <div className="d-flex align-items-center me-4">
                                    {permission_info?.edit_student !== false &&
                                        (<Link to={`/${school_code}/student/student-list/edit-student/${row.student_uuid}`}><i className="far fa-edit me-2 fs-6"></i>Edit Student
                                        </Link>)}
                                </div>


                                {/* <div className="d-flex align-items-center me-4 pointer">
                                    <span
                                        onClick={(event) => props.RemoveCourseModule(event, row)}
                                    ><i className="far fa-trash-alt me-2 fs-6"></i>
                                        Delete</span>
                                </div> */}
                                {
                                    permission_info?.delete_student !== false &&
                                    <div className="d-flex align-items-center me-4 pointer">
                                        <span
                                            onClick={(event) => props.handleDelete(event, row)}
                                        ><i className="far fa-trash-alt me-2 fs-6"></i>
                                            Delete Student</span>
                                    </div>
                                }

                                {row && row.is_student_activate === true ? (
                                    (permission_info?.activate_deactivate_student !== false) ?
                                        (<div className="d-flex align-items-center"
                                            onClick={(event) => {
                                                props.AddArchivepoup(event, row.student_uuid, false, row.student_first_name + " " + row.student_last_name);
                                            }}
                                        > 
                                            <span className="deactivate-button1" >
                                                <img src="/media/Archive_student.png" style={{ width: "16px", marginRight: "8px", filter: "opacity(0.3)" }} />
                                                {/* <i className="bi bi-bell-slash me-2 fs-6"></i> */}
                                                Archive Student
                                            </span>
                                        </div>) : null
                                ) : (
                                    (permission_info?.activate_deactivate_student !== false) ?
                                        (<div className="d-flex align-items-center"
                                            onClick={(event) => {
                                                props.AddUnarchivepoup(event, row.student_uuid, true, row.student_first_name + " " + row.student_last_name);
                                            }}
                                        >
                                            <span className="deactivate-button1">
                                                <img src="/media/Un_archive_student.png" style={{ width: "16px", marginRight: "8px", filter: "opacity(0.3)" }} />
                                                {/* <i className="bi bi-bell me-2 fs-6"></i> */}
                                                Activate Student
                                            </span>
                                        </div>) : null
                                )}



                                {/* <span className="deactivate-button1" onClick={() => setData(<span className="badge badge-danger px-4 py-2 ms-2 active-button">Inactive</span>)}><i className="bi bi-bell-slash me-2 fs-6"></i>Deactivate School</span> */}

                                {/* <div className="d-flex align-items-center me-4"
                                    onClick={() => props.handleDelete(row.student_id, row.parent_id)}
                                    > */}

                                {/* {permission_info?.edit_student !== false &&  ( */}

                                {/* <i className="far fa-edit me-2 fs-6"></i> 
                                    Delete  */}
                                {/* )} */}
                                {/* </div> */}


                            </div>

                        </div>
                    </div>
                );
            },
        },
    ]);
    const handleTableChange = (type, { sortField, sortOrder, page }) => {
        if (type === "sort") {
            props.propsfiltersort(sortField);
            props.propsfilterorder(sortOrder);
        }

        if (type === "pagination") {
            props.propsfilterpage(page);
        }
    };
    const RemotePagination = ({ columns, dataField, order, data, page, sizePerPage, onTableChange, totalSize }) => (
        <PaginationProvider
            pagination={paginationFactory({
                custom: true,
                page,
                sizePerPage,
                totalSize,
            })}
        >
            {({ paginationProps, paginationTableProps }) => (
                <>
                    <div className="table-responsive common-table superadmin_table">
                        <BootstrapTable
                            remote
                            bootstrap4
                            data={data}
                            keyField="student_uuid"
                            sort={{ dataField: dataField, order: order }}
                            columns={columns}
                            {...paginationTableProps}
                            onTableChange={onTableChange}
                            sizePerPageList={sizePerPage}
                            headerClasses="fw-bolder text-white bg-dark-row"
                            classes="table-bordered align-middle custom-table-style table-fields"
                            noDataIndication={() => <span className="text-center text-gray-400">No records found</span>}
                        />
                    </div>
                    <div className="d-flex flex-stack justify-content-between flex-wrap pt-3 pagination-main">

                        {totalSize > 0 && (
                            <div className="pagination_limt">
                                <select id='select' name='group' className='form-control input-sm form-select form-select-sm form-select-solid' size='1' value={perpagedata}
                                    onChange={(event) => {
                                        props.propsfilterpage(1);
                                        setperpagedata(event.target.value);
                                        props.propsfilterlimit(parseInt(event.target.value));
                                    }}
                                >
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={250}>250</option>
                                    <option value={500}>500</option>
                                </select>
                                {(parseInt(sizePerPage) * parseInt(page)) > totalSize ? (
                                    <div className="count_number">Displaying {(parseInt(sizePerPage) * parseInt(page) + parseInt(1)) - (parseInt(perpagedata))}-{totalSize} of {totalSize} records</div>
                                ) : (
                                    <div className="count_number">Displaying {(parseInt(sizePerPage) * parseInt(page) + parseInt(1)) - (parseInt(perpagedata))}-{parseInt(sizePerPage) * parseInt(page)} of {totalSize} records</div>
                                )}
                            </div>
                        )}

                        <div className="pagination_page">
                            <PaginationListStandalone {...paginationProps} />
                        </div>
                    </div>
                </>
            )}
        </PaginationProvider>
    );
    return (
        <React.Fragment>
            <div className="row gy-5 g-xl-8">
                <div className="col-xl-12">
                   
                            <div className="table-responsive">
                                <div id="kt_profile_overview_table" className="dataTables_wrapper dt-bootstrap4 no-footer team-list-table">
                                    <div className="table-responsive">
                                        <div className="card-body p-0 bg-white radious-style radious-users">
                                            {/* <Spin spinning={props.loading}> */}
                                            <RemotePagination
                                                data={props.data}
                                                columns={columns}
                                                page={props.filterpage}
                                                totalSize={props.StudentListcount}
                                                sizePerPage={props.filterlimit}
                                                order={props.filterorder}
                                                dataField={props.filtersort}
                                                onTableChange={handleTableChange}
                                            />
                                            {/* </Spin> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                    
                </div>
            </div>

        </React.Fragment>
    );
};
export default StudentItem;