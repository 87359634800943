import React, { useState, useEffect, useCallback, useRef } from "react";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap"
import { connect, useDispatch } from "react-redux";
import * as userActions from "../../../actions/index";

const Timer = (props) => {

  const dispatch = useDispatch();
  const [firstWarn, setFirstWarn] = useState();
  const [SecondWarn, setSecondWarn] = useState();
  const [timer, setTimer] = useState(
    JSON.parse(localStorage.getItem("RollCall_time")) === 0 ? JSON.parse(localStorage.getItem("RollCall_time")) : ''
  );
  const firstStart = useRef(true);
  const tick = useRef();
  const [modelState, setModalState] = useState("close")
console.log(modelState,firstWarn,SecondWarn,"::modelState")
  const [idleTimer, setIdleTimer] = useState(60);

  const [blinkTimer, setBlinkTimer] = useState(60 * 10)

  const GetRollCallDetails = useCallback(async () => {
    dispatch(userActions.GetRollCallDetails()).then((response) => {
      const listLocalStorage = JSON.parse(localStorage.getItem("Rollcal_List"));
      const timeLocalStorage = JSON.parse(localStorage.getItem("RollCall_time"));
      if (response.success === true) {
        const timerdata = response.data
        const arrayData = timerdata[(timerdata.length - 1)]
        const time_data = (parseInt(arrayData.meta_value_one) * 60)
        console.log("time_data",timerdata)
        setFirstWarn((parseInt(arrayData.meta_value_two)) * 60);
        setSecondWarn((parseInt(arrayData.meta_value_three) * 60));
        if (!listLocalStorage) {
          setTimer(time_data);
        }
      } else { }
    });
  }, [dispatch]);


  const dispSecondsAsMins = (seconds) => {
    if (timer === firstWarn) {
      const time = setTimeout(() => {
        handleShowModelOne();
      },);
      return () => clearTimeout(time);
    } else if (timer === SecondWarn) {
      const time = setTimeout(() => {
        handleShowModelTwo();
      },);
      return () => clearTimeout(time);
    } 
    // else if (timer === "") { 
    //   const time = setTimeout(() => {
    //     handleShowModelThree();
    //   },);
    //   return () => clearTimeout(time);
    // }
    const mins = Math.floor(seconds / 60);
    const seconds_ = seconds % 60;
    return `${mins <= 9 ? "0" + mins.toString() : mins.toString()}` + ":" + (seconds_ <= 9 ? "0" + seconds_.toString() : seconds_.toString());
  };

  useEffect(() => {
    GetRollCallDetails();
  }, []);

  useEffect(() => {
    if (firstStart.current) {
      firstStart.current = !firstStart.current;

      return;
    }
    if (props.start) {
      tick.current = setInterval(() => {

        setTimer((timer) => timer < 1 ? "" : timer - 1);
      }, 1000);
    }
    else {
      clearInterval(tick.current);
    }
    return () => clearInterval(tick.current);
  }, [props.start]);

  useEffect(() => {
    const timeLocalStorage = JSON.parse(localStorage.getItem("RollCall_time"));
    if (timeLocalStorage) {
      setTimer(timeLocalStorage)
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('RollCall_time', JSON.stringify(timer === 0 ? timer : timer));
  }, [timer, props.start])
  
  if (timer <= 0) {
    clearInterval(tick.current);
  }
  console.log('RollCall_time========================','RollCall_time');

  useEffect(() => {
    const timeLocalStorage = JSON.parse(localStorage.getItem("RollCall_time"));
    if (timer === "") {
      // setIdleTimer(60);
    } else if (timer === 0) {
      const time = setTimeout(() => {
        handleShowModelThree();
      },);
  //  return () => clearTimeout(time);
      // const idleInterval = setInterval(() => {
      //   if (idleTimer > 0) {
      //     setIdleTimer(idleTimer - 1);
      //   }
      // }, 1000);
      // const resetTimeout = () => {
      //   setIdleTimer(59);
      // };

      // const events = [
      //   "load",
      //   "mousemove",
      //   "mousedown",
      //   "click",
      //   "scroll",
      //   "keypress"
      // ];

      // for (let i in events) {
      //   window.addEventListener(events[i], resetTimeout);
      // }
      // return () => {
      //   clearInterval(idleInterval);
      //   for (let i in events) {
      //     window.removeEventListener(events[i], resetTimeout);
      //   }
      // };

    }
  }, [idleTimer, timer]);


console.log(timer,"::timertimer")


  if (idleTimer == 0) {
    props.discardedSessionHandler();
  }

  const handleShowModelOne = () => {
    setModalState("modal-One")
  }

  const handleShowModelTwo = () => {
    setModalState("modal-Two")
  }

  const handleShowModelThree = () => {
    setModalState("modal-three")
  }
  
  const handleShowModelClose = () => {
    if(modelState==="modal-three"){
      props.discardedSessionHandler();
    }
    setModalState("close")
  }
console.log(idleTimer,timer,"::idleTimer")


  return (
    <div className="pomView">
      <div className="d-flex align-items-center">
        <span class="mr-auto p-2">
          <h1 >
            <span className={`${idleTimer < 60 ? "conduct_rollcall_blink" : ""}`}>
              {idleTimer < 60 ? (idleTimer <= 9 ? "00:" + "0" + idleTimer : "00:" + idleTimer) : dispSecondsAsMins(timer)}
            </span>
          </h1>
        </span>
        <div className="startDiv">
          {!props.start ? "" : ""}
        </div>
      </div>




      <div>

        <Modal
          show={modelState === "modal-One"}
        >
          <Modal.Body className="">
            <h5 className="text-center mb-0">
              {props.Managername} you have <span className="text-danger">{dispSecondsAsMins(timer)}</span> minutes to complete this Rollcall Session or it will be lost.  You may begin another Rollcall Session any time.
            </h5>
          </Modal.Body>
          <Modal.Footer className="">
            <Button className="mx-auto btn-sm"
              onClick={handleShowModelClose}
            >Ok</Button>
          </Modal.Footer>
        </Modal>



        <Modal
          show={modelState === "modal-Two"}
        >
          <Modal.Body className="">
            <h5 className="text-center mb-0">
              {props.Managername} you have <span className="text-danger">{dispSecondsAsMins(timer)}</span>  minutes to complete this Rollcall Session or it will be lost.  You may begin another Rollcall Session any time.
            </h5>
          </Modal.Body>
          <Modal.Footer className="">
            <Button className="mx-auto btn-sm"
              onClick={handleShowModelClose}
            >Ok
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={modelState === "modal-three"}
        >
          <Modal.Body className="">
            <h5 className="text-center mb-0">
              Rollcall Countdown has terminated the session.  If you still wish to conduct a Rollcall - kindly commence a new Rollcall session.
            </h5>
          </Modal.Body>
          <Modal.Footer className="">
            <Button className="mx-auto btn-sm"
              onClick={handleShowModelClose}
            >Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {

  onGetRollCallDetails: userActions.GetRollCallDetails,

};

export default connect(mapStateToProps, mapDispatchToProps)(Timer);