import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ManageBoarderLeaveDashboard from "../../component/Leave/ManagerBorderLeave/ManageBoarderLeaveDashboard";
import AddModeOfTravel from "../../component/Leave/Managemodeoftravel/AddModeOfTravel";
import EditModeOfTravel from "../../component/Leave/Managemodeoftravel/EditModeOfTravel";
import AddLeaveRejectionReason from "../../component/Leave/ManageLeaveRejectionReason/AddLeaveRejectionReason";
import EditLeaveRejectionReason from "../../component/Leave/ManageLeaveRejectionReason/EditLeaveRejectionReason";
import ManageStudentReturn from "../../component/Leave/ManagerBorderLeave/ManageStudentReturn";
import AdHocActivityList from "../../component/Leave/AdHocActivityOffCampus/AdHocActivityList"
import EditAdHocActivity from "../../component/Leave/AdHocActivityOffCampus/EditAdHocActivity"
import BoardersLeaveDetails from "../../component/Leave/ManagerBorderLeave/BoardersLeaveDetails";
import EditBoardersLeave from "../../component/Leave/ManagerBorderLeave/EditBoardersLeave";
import PresentStudentList from "../../component/Leave/ManagerBorderLeave/PresentStudentList";
import ApplyBorderLeave from "../../component/Leave/ManagerBorderLeave/ApplyBorderLeave";
import ManageStudentDeparture from "../../component/Leave/ManagerBorderLeave/ManageStudentDeparture";
import PresentStuListForAdhoc from "../../component/Leave/ManageAdHocLeave/PresentStuListForAdhoc";
import EditAdhocLeave  from "../../component/Leave/ManageAdHocLeave/EditAdhocLeave";

import ArchiveLeaves from "../../component/Leave/ManagerBorderLeave/ArchiveLeaves";
import ManageHost from "../../component/Leave/ManageHost/ManageHost";
import ManageModeOfTravel from "../../component/Leave/Managemodeoftravel/ModeOfTravelList"
import ManageLeaveRejection from "../../component/Leave/ManageLeaveRejectionReason/LeaveRejectionReasonList"
import AdhocLeaveDetails from "../../component/Leave/ManageAdHocLeave/AdhocLeaveDetails";
import ApplyAdhocLeave  from "../../component/Leave/ManageAdHocLeave/ApplyAdhocLeave";
import SignAdhocLeaveOutIn from "../../component/Leave/ManageAdHocLeave/SignAdhocLeaveOutIn";
import AddHocActivity from "../../component/Leave/AdHocActivityOffCampus/AddHocActivity";
import HostResponsibility from "../../component/Leave/HostResponsibility/HostResponsibility";

const permission_info = JSON.parse(localStorage.getItem("permissions"));
const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" :user_info?.school_code;

const ManageLeave = (props) => {
    const Renderview = () => {
        let location = useLocation();
        let userId = useParams();
        
        console.log("userId===========",userId.id);

        let { border_leave_id , adhoc_leave_detail_uuid } = useParams();
        const queryParams = new URLSearchParams(location.search);
        const parameterNames = ["student_id", "class_id", "dormitory_id", "dormitory_name", "class_name", "student_name", "student_avatar" , "gender"];
        const student_data = {};
        parameterNames.forEach(name => {
            student_data[name] = queryParams.get(name);
        });

        if (location.pathname === `/${school_code}/leave/manage-boarders-leave` || location.pathname === `/${school_code}/leave/manage-boarders-leave/`) {
            return <ManageBoarderLeaveDashboard />;
        }
        if (location.pathname === `/${school_code}/leave/Adhoc-leave-details` || location.pathname === `/${school_code}/leave/Adhoc-leave-details/`) {
            return <AdhocLeaveDetails />;
        }
        if (location.pathname === `/${school_code}/leave/edit-adhoc-leave/${adhoc_leave_detail_uuid}` || location.pathname === `/${school_code}/leave/edit-adhoc-leave/${adhoc_leave_detail_uuid}/`) {
            return <EditAdhocLeave adhoc_leave_detail_uuid={adhoc_leave_detail_uuid} />;
        }
        if (location.pathname === `/${school_code}/leave/present-student-list-for-adhoc` || location.pathname === `/${school_code}/leave/present-student-list-for-adhoc/`) {
            return <PresentStuListForAdhoc />;
        }
        if (location.pathname === `/${school_code}/leave/apply-adhoc-leave` || location.pathname === `/${school_code}/leave/apply-adhoc-leave/`) {
            return <ApplyAdhocLeave student_data={student_data} />;
        }
        if (location.pathname === `/${school_code}/leave/manage-mode-of-travels` || location.pathname === `/${school_code}/leave/manage-mode-of-travels/`) {
            return <ManageModeOfTravel />;
        }
        if (location.pathname === `/${school_code}/leave/manage-mode-of-travels/add-manage-mode-of-travels` || location.pathname === `/${school_code}/leave/manage-mode-of-travels/add-manage-mode-of-travels/`) {
            return <AddModeOfTravel />;
        }
        if (location.pathname === `/${school_code}/leave/manage-mode-of-travels/edit-mode-of-travels/${userId.id}` || location.pathname === `/${school_code}/leave/manage-mode-of-travels/edit-mode-of-travels/${userId.id}/`) {
            return <EditModeOfTravel travel_mode_uuid={userId.id} />;
        }
        if (location.pathname === `/${school_code}/leave/manage-leave-rejection-reason` || location.pathname === `/${school_code}/leave/manage-leave-rejection-reason/`) {
            return <ManageLeaveRejection />;
        }
        if (location.pathname === `/${school_code}/leave/manage-leave-rejection-reason/add-manage-leave-rejection-reason` || location.pathname === `/${school_code}/leave/manage-leave-rejection-reason/add-manage-leave-rejection-reason/`) {
            return <AddLeaveRejectionReason />;
        }
        if (location.pathname === `/${school_code}/leave/manage-leave-rejection-reason/edit-manage-leave-rejection-reason/${userId.id}` || location.pathname === `/${school_code}/leave/manage-leave-rejection-reason/edit-manage-leave-rejection-reason/${userId.id}/`) {
            return <EditLeaveRejectionReason leave_reject_reason_uuid={userId.id} />;
        }
        if (location.pathname === `/${school_code}/leave/student-return` || location.pathname === `/${school_code}/leave/student-return/`) {
            return <ManageStudentReturn />;
        }
        if (location.pathname === `/${school_code}/leave/activity-list` || location.pathname === `/${school_code}/leave/activity-list/`) {
            return <AdHocActivityList />;
        }
        if (location.pathname === `/${school_code}/leave/ad-hoc-activity-off-campus/edit-ad-hoc-activity/${userId.id}` || location.pathname === `/${school_code}/leave/ad-hoc-activity-off-campus/edit-ad-hoc-activity/${userId.id}/`) {
            return <EditAdHocActivity activity_uuid={userId.id} />;
        }
        if (location.pathname === `/${school_code}/leave/ad-hoc-activity-off-campus/add-ad-hoc-activity` || location.pathname === `/${school_code}/leave/ad-hoc-activity-off-campus/add-ad-hoc-activity/`) {
            return <AddHocActivity />;
        }
        if (location.pathname === `/${school_code}/leave/boarders-leave-details` || location.pathname === `/${school_code}/leave/boarders-leave-details/`) {
            return <BoardersLeaveDetails />;
        }
        if (location.pathname === `/${school_code}/leave/present-student-list` || location.pathname === `/${school_code}/leave/present-student-list/`) {
            return <PresentStudentList />;
        }
        if (location.pathname === `/${school_code}/leave/edit-boarders-leave/${border_leave_id}` || location.pathname === `/${school_code}/leave/edit-boarders-leave/${border_leave_id}/`) {
            return <EditBoardersLeave border_leave_id={border_leave_id} />;
        }
        if (location.pathname === `/${school_code}/leave/sign-boarders-out-in` || location.pathname === `/${school_code}/leave/sign-boarders-out-in/`) {
            return <SignAdhocLeaveOutIn/>;
        }
        if (location.pathname === `/${school_code}/leave/apply-border-leave/${userId.id}` || location.pathname === `/${school_code}/leave/apply-border-leave/${userId.id}/`) {
            return <ApplyBorderLeave user_uuid={userId.id}/>;
        }
        if (location.pathname === `/${school_code}/leave/archive-leaves` || location.pathname === `/${school_code}/leave/archive-leaves/`) {
            return <ArchiveLeaves />;
        }
        if (location.pathname === `/${school_code}/leave/manage-student-departure` || location.pathname === `/${school_code}/leave/manage-student-departure/`) {
            return <ManageStudentDeparture />;
        }
        if (location.pathname === `/${school_code}/leave/manage-host` || location.pathname === `/${school_code}/leave/manage-host/`) {
            return <ManageHost />;
        }
        if (location.pathname === `/${school_code}/leave/host-responsibility` || location.pathname === `/${school_code}/leave/host-responsibility/`) {
            return <HostResponsibility />;
        }
    };

    return (
        <React.Fragment>
            <ToastContainer />
            {Renderview()}
        </React.Fragment>
    );
};



export default ManageLeave;