import React from "react";

import Validations from "../Utility/Validations";
import Select from "react-select";
import * as userActions from "../../actions/index";
import { connect } from "react-redux";

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#e7f5fe" : "",
        },
        backgroundColor: state.isSelected ? "#4a8fb8" : "",
    }),
};

class StudentSearch extends React.Component {
    constructor(props) {
        super(props);
        console.log("propsssss",props.searchData
        );
        this.state = {
            student_search_form: {
                class_id: {
                    type: "select",
                    label: "Highest class *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "City",
                    validations: { required: false },
                    options: [],
                },

                dormitory_id: {
                    type: "select",
                    label: "Select Dormitory *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select Dormitory *",
                    validations: { required: false },
                    options: [],
                },

                gender: {
                    type: "radio",
                    label: "Gender * ",
                    value: "all",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: false },
                },

                select_age: {
                    type: "select",
                    label: "Select Age *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                    options: [
                        { label: "Select Age", value: "" },
                        { label: "1", value: "1" },
                        { label: "2", value: "2" },
                        { label: "3", value: "3" },
                        { label: "4", value: "4" },
                        { label: "5", value: "5" },
                        { label: "6", value: "6" },
                        { label: "7", value: "7" },
                        { label: "8", value: "8" },
                        { label: "9", value: "9" },
                        { label: "10", value: "10" },
                        { label: "11", value: "11" },
                        { label: "12", value: "12" },
                        { label: "13", value: "13" },
                        { label: "14", value: "14" },
                        { label: "15", value: "15" },
                        { label: "16", value: "16" },
                        { label: "17", value: "17" },
                        { label: "18", value: "18" },
                        { label: "19", value: "19" },
                        { label: "20", value: "20" },
                        { label: "21", value: "21" },
                        { label: "22", value: "22" },
                        { label: "23", value: "23" },
                        { label: "24", value: "24" },
                        { label: "25", value: "25" },
                        { label: "26", value: "26" },
                        { label: "27", value: "27" },
                        { label: "28", value: "28" },
                        { label: "29", value: "29" },
                        { label: "30", value: "30" },
                    ]
                },
            },
        };
    }

    componentDidMount() {
        this.GetAllDormitoryStudent();
        this.GetAllClassesDetailsStudent();
    }

    GetAllDormitoryStudent = async (form_data) => {
        this.props.onGetAllDormitoryStudent().then((response) => {
            let country_level = [];
            country_level.push({
                label: "Select Dormitory",
                value: "",
            });
            const update_student_search_form = { ...this.state.student_search_form };
            if (response.success === true) {
                const arrayData = response.data.rows;
                // console.log(arrayData);
                for (let key in arrayData) {
                    country_level.push({
                        label: arrayData[key].dormitory_name,
                        value: arrayData[key].dormitory_id,
                        // dormitory_id_name: arrayData[key].dormitory_id_name,
                    });
                }
            }
            update_student_search_form['dormitory_id'].options = country_level;
            this.setState({ student_search_form: update_student_search_form });
        });
    }

    GetAllClassesDetailsStudent = async (form_data) => {
        this.props.onGetAllClassesDetailsStudent().then((response) => {
            let country_level = [];
            country_level.push({
                label: "Select Class",
                value: "",
            });
            const update_student_search_form = { ...this.state.student_search_form };
            if (response.success === true) {

                const arrayData = response.data.rows;
                // console.log(arrayData);
                for (let key in arrayData) {
                    country_level.push({
                        label: arrayData[key].class_name,
                        value: arrayData[key].class_id,
                        class_name: arrayData[key].class_name,

                    });
                }
            }
            update_student_search_form['class_id'].options = country_level;
            this.setState({ student_search_form: update_student_search_form });
        });
    }

    inputChangeHandlerSearch(event, identifier) {
        const update_student_search_form = { ...this.state.student_search_form };
        const form_element = { ...update_student_search_form[identifier] };
        form_element.value = event.target.value;
        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_student_search_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_student_search_form) {
            is_form_valid = update_student_search_form[identifier].valid && is_form_valid;
        }
        this.setState({
            student_search_form: update_student_search_form,
            is_form_valid: is_form_valid,
        });
    }

    handleChangeSearch(data, identifier, type) {
        const update_student_search_form = { ...this.state.student_search_form };
        update_student_search_form[identifier].value = data.value;
        this.setState({ student_search_form: update_student_search_form });
    }

    SubmitHandler = async (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_student_search_form = this.state.student_search_form;
        for (let key in update_student_search_form) {
            let form_element = update_student_search_form[key];
            let validate_element = Validations(form_element);
            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;
            update_student_search_form[key] = form_element;
        }
        this.setState({ student_search_form: update_student_search_form });
        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.student_search_form) {
                form_data[key] = this.state.student_search_form[key].value;
            }
            this.props.searchData(form_data)
        }
    };

    cancelCourse = () => {
        document.getElementById("search-form").reset();
    }

    render() {
        const { student_search_form } = this.state;
        return (
            <React.Fragment>
                <div className="card tabs-outer">
                    <form onSubmit={this.SubmitHandler} id="search-form" >
                        <div className="card-header border-bottom border-bottom-1 align-items-center">
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bold text-dark">Student Search</span>
                            </h3>
                            <button onClick={this.cancelCourse} type="reset" className="clear-btn fs-6 pointer btn btn-link btn-color-info btn-active-color-primary">Clear Search</button>
                        </div>
                        <div className="card-body add-new-schools p-6">
                            <div className="row">
                                <div className="col-lg-12 fv-row mb-5">
                                    <label className="fw-bold mb-2">{student_search_form.class_id.label}</label>
                                    <Select
                                        styles={customStyles}
                                        className="react-bootstrap-typeahead tag-outer"
                                        options={student_search_form.class_id.options}
                                        value={student_search_form.class_id.options.filter(function (option) {
                                            return option.value === student_search_form.class_id.value;
                                        })}
                                        onChange={(newValue) => this.handleChangeSearch(newValue, "class_id")}
                                    />
                                    {!student_search_form.class_id.valid && student_search_form.class_id.onBlur_out ? <div className="error field-error">{student_search_form.class_id.error_msg}</div> : ""}
                                </div>

                                <div className="col-lg-12 fv-row mb-5">
                                    <label className="fw-bold mb-2">{student_search_form.dormitory_id.label}</label>
                                    <Select
                                        styles={customStyles}
                                        className="react-bootstrap-typeahead tag-outer"
                                        options={student_search_form.dormitory_id.options}
                                        value={student_search_form.dormitory_id.options.filter(function (option) {
                                            return option.value === student_search_form.dormitory_id.value;
                                        })}
                                        onChange={(newValue) => this.handleChangeSearch(newValue, "dormitory_id")}
                                    />
                                    {!student_search_form.dormitory_id.valid && student_search_form.dormitory_id.onBlur_out ? <div className="error field-error">{student_search_form.dormitory_id.error_msg}</div> : ""}
                                </div>

                                <div className="col-lg-12 fv-row mb-5">
                                    <label className="fw-bold mb-2">Gender</label>
                                    <div className="d-flex">
                                        <label className="cursor-pointer">
                                            <input type="radio" id="gender" name="gen" className="form-check-input me-2 cursor-pointer" value="male" onChange={(event) => this.inputChangeHandlerSearch(event, "gender")} />
                                            Male
                                        </label>

                                        <label className="ms-8 cursor-pointer">
                                            <input type="radio" id="gender1" name="gen" className="form-check-input me-2 cursor-pointer" value="female" onChange={(event) => this.inputChangeHandlerSearch(event, "gender")} />
                                            Female
                                        </label>

                                        <label className="ms-8 cursor-pointer">
                                            <input type="radio" id="gender2" name="gen" className="form-check-input me-2 cursor-pointer" value="all" onChange={(event) => this.inputChangeHandlerSearch(event, "gender")} defaultChecked />
                                            All
                                        </label>
                                    </div>
                                </div>

                                <div className="col-lg-12 fv-row mb-5">
                                    <label className="fw-bold mb-2">{student_search_form.select_age.label}</label>
                                    <Select
                                        styles={customStyles}
                                        className="react-bootstrap-typeahead tag-outer"
                                        options={student_search_form.select_age.options}
                                        value={student_search_form.select_age.options.filter(function (option) {
                                            return option.value === student_search_form.select_age.value;
                                        })}
                                        onChange={(newValue) => this.handleChangeSearch(newValue, "select_age")}
                                    />

                                    {!student_search_form.select_age.valid && student_search_form.select_age.onBlur_out ? <div className="error field-error">{student_search_form.select_age.error_msg}</div> : ""}
                                </div>

                                <div className="col-sm-12">
                                    <div className="d-flex justify-content-end">
                                        <button type="submit" className="btn btn-sm btn-primary apply-btn min-150px student-search">
                                            Search
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onGetAllDormitoryStudent: userActions.GetAllDormitoryStudent,
    onGetAllClassesDetailsStudent: userActions.GetAllClassesDetailsStudent,
    onGetAllSchool: userActions.GetAllSchool,



};
export default connect(mapStateToProps, mapDispatchToProps)(StudentSearch);
