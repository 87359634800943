import axios from "axios";
import * as actionType from "../constants/actionTypes";
let qs = require("qs");

/*** GetAllDashboardSchools Action ***/
export const GetAllDashboardSchoolsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllDashboardSchools_START,
    };
};
export const GetAllDashboardSchoolsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllDashboardSchools_SUCCESS,
    };
};
export const GetAllDashboardSchoolsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllDashboardSchools_FAIL,
    };
};
export const GetAllDashboardSchools = (page, limit, sort, order , search ) => {
   
    return (dispatch) => {
        dispatch(GetAllDashboardSchoolsStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,    
            },
            url: `${actionType.environment_url}/api/superadmin/GetAllDashboardSchools`,
            params: {
                page: page,
                limit: limit,
                sort: sort,
                order: order,
                search: search,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllDashboardSchoolsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllDashboardSchoolsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

