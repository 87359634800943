import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";


const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info.school_code;

const CustomLinkItem = (props) => {
    const [perpagedata, setperpagedata] = useState(10);
    const [columns] = useState([
        {
            text: 'S.No.',
            dataField: 'perpage',
        },

        {
            text: "Title",
            dataField: "custom_link_title",
        },

        {
            text: "	Url",
            // dataField: "custom_link_url",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <div key={"repeat_" + row.country_id}>
                        <a href={row.custom_link_url} target="_blank">
                            {row.custom_link_url}
                        </a>
                    </div>
                );
            },
        },

        {
            text: "Action",
            dataField: "Actions",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <div key={"repeat_" + row.country_id} className="d-flex justify-content-end">
                        <span className="edit_icon me-2">
                            <Link
                                to={`/${school_code}/settings/custom-link/edit-custom-link/${row.custom_link_uuid}`}
                                className="mx-1 btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                            >
                                <span className="svg-icon svg-icon-3">
                                    <i className="fas fa-pen"></i>
                                </span>
                            </Link>
                        </span>
                        <span className="edit_icon me-2">
                            <span
                                className="mx-1 btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                                onClick={(event) => props.RemoveCourseModule(event, row)}
                            >
                                <span className="svg-icon svg-icon-3">
                                    <i className="fas fa-trash-alt"></i>
                                </span>
                            </span>
                        </span>
                    </div>
                );
            },
        },
    ]);


    const handleTableChange = (type, { sortField, sortOrder, page }) => {
        if (type === "sort") {
            props.propsfiltersort(sortField);
            props.propsfilterorder(sortOrder);
        }

        if (type === "pagination") {
            props.propsfilterpage(page);
        }
    };


    const RemotePagination = ({ columns, dataField, order, data, page, sizePerPage, onTableChange, totalSize }) => (
        <PaginationProvider
            pagination={paginationFactory({
                custom: true,
                page,
                sizePerPage,
                totalSize,
            })}
        >
            {({ paginationProps, paginationTableProps }) => (
                <>
                    <div className="table-responsive common-table custom-message-table">
                        <BootstrapTable
                            remote
                            bootstrap4
                            data={data}
                            keyField="student_uuid"
                            sort={{ dataField: dataField, order: order }}
                            columns={columns}
                            {...paginationTableProps}
                            onTableChange={onTableChange}
                            sizePerPageList={sizePerPage}
                            headerClasses="fw-bolder text-white bg-dark-row"
                            classes="table-row-bordered align-middle custom-table-style table-fields"
                            noDataIndication={() => <span className="text-center text-gray-400">No records found</span>}
                        />
                    </div>
                    <div className="d-flex flex-stack justify-content-between flex-wrap pt-3 pagination-main">

                        {totalSize > 0 && (
                            <div className="pagination_limt">
                                <select id='select' name='group' className='form-control input-sm form-select form-select-sm form-select-solid' size='1' value={perpagedata}
                                    onChange={(event) => {
                                        props.propsfilterpage(1);
                                        setperpagedata(event.target.value);
                                        props.propsfilterlimit(parseInt(event.target.value));
                                    }}
                                >
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={250}>250</option>
                                    <option value={500}>500</option>
                                </select>
                                {(parseInt(sizePerPage) * parseInt(page)) > totalSize ? (
                                    <div className="count_number">Displaying {(parseInt(sizePerPage) * parseInt(page) + parseInt(1)) - (parseInt(perpagedata))}-{totalSize} of {totalSize} records</div>
                                ) : (
                                    <div className="count_number">Displaying {(parseInt(sizePerPage) * parseInt(page) + parseInt(1)) - (parseInt(perpagedata))}-{parseInt(sizePerPage) * parseInt(page)} of {totalSize} records</div>
                                )}
                            </div>
                        )}

                        <div className="pagination_page">
                            <PaginationListStandalone {...paginationProps} />
                        </div>
                    </div>
                </>
            )}
        </PaginationProvider>
    );
    return (
        <React.Fragment>
            <div className="row gy-5 g-xl-8">
                <div className="col-xl-12">

                    <div className="table-responsive">
                        <div id="kt_profile_overview_table" className="dataTables_wrapper dt-bootstrap4 no-footer team-list-table">
                            <div className="table-responsive">
                                <div className="card-body  bg-white radious-style radious-users ">
                                    <RemotePagination
                                        data={props.data}
                                        columns={columns}
                                        page={props.filterpage}
                                        totalSize={props.CustomLinkcount}
                                        sizePerPage={props.filterlimit}
                                        order={props.filterorder}
                                        onTableChange={handleTableChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default CustomLinkItem;