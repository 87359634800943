import React, { useEffect, useState } from "react";
import Sidebar from "../../../Constant/sidebar";
import Footer from "../../../Constant/Footer";
import TopBar from "../../../Constant/TopBar";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as userActions from "../../../../actions/index";
import { connect, useDispatch } from "react-redux";
import AttendanceHistoryItem from "./AttendanceHistoryItem";
import { DateRangePicker } from "mui-daterange-picker";
import Form from 'react-bootstrap/Form';
import moment from "moment";
import { IconButton, Tooltip } from "@mui/material";


const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" :user_info?.school_code;
export default function AttendanceHistory(props) {
    const [start_date, setStart_Date] = useState("")
    const [end_date, setEnd_Date] = useState("")
    const [student_uuid, setStudent_uuid] = useState(props.user_uuid)
    const dispatch = useDispatch();
    const [attendance_date, setattendance_date] = useState([])
    const [count, setCount] = useState(0)
    const [open, setOpen] = useState(false)
    const [pdf_url, setPdf_url] = useState("")
    const GetStudentAttandenceById = (event) => {
        dispatch(userActions.GetStudentAttandenceById(start_date, end_date, student_uuid)).then((response) => {
            if (response.success === true) {
                setattendance_date(response.data.rows)
                setCount(response.data.count)
            }
            else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    };
    useEffect(() => {
        GetStudentAttandenceById();
        StudentAttandencePdfByStudentId();
    }, [start_date, end_date, student_uuid])

    const toggle = () => setOpen(!open)
    const setDateRange = (range) => {
        const { startDate, endDate } = range;
        setStart_Date(moment(startDate).format('YYYY-MM-DD'));
        setEnd_Date(moment(endDate).format('YYYY-MM-DD'))
    }

    const StudentAttandencePdfByStudentId = (event) => {
        dispatch(userActions.GetStudentAttandencePdfByStudentId(start_date, end_date, student_uuid)).then((response) => {
            if (response.success === true) {
                setPdf_url(response.file)
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    }
    const DownloadPdf = () => {
        console.log("pdf_url", pdf_url);
        const link = document.createElement("a");
        link.href = pdf_url;
        link.target = "_blank"
        // link.setAttribute("download");
        document.body.appendChild(link);
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }

    return (
        <>
            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <Sidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-fluid">
                                        <ul className="nav nav-tabs home-tabs" id="myTab" role="tablist" style={{display:"flex", justifyContent:"space-between", alignItems:"flex-end"}}>
                                            <div style={{display:"flex"}}>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/student-profile/${props.user_uuid}`} className="nav-link" id="StudentProfile-tab" type="button" aria-controls="home" aria-selected="true">
                                                        Student Profile
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/approved-host/${props.user_uuid}`} className="nav-link" id="ApprovedHosts-tab" type="button" aria-controls="process" aria-selected="true">
                                                        Approved Hosts
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/leave-history/${props.user_uuid}`} className="nav-link" id="LeaveHistory-tab" type="button" aria-controls="process" aria-selected="false">
                                                        Leave History
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/attendance-history/${props.user_uuid}`} className="nav-link active" id="AttendanceHistory-tab" type="button" aria-controls="process" aria-selected="false">
                                                        Attendance History
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/pastoral-notes/${props.user_uuid}`} className="nav-link" id="PastoralNotes-tab" type="button" aria-controls="process" aria-selected="false">
                                                        Pastoral Notes
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/medical-notes/${props.user_uuid}`} className="nav-link" id="MedicalNotes-tab" type="button" aria-controls="process" aria-selected="false">
                                                        Medical Notes
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/allergy-reports/${props.user_uuid}`} className="nav-link " id="AllergyReport-tab" type="button" aria-controls="process" aria-selected="false">
                                                        Allergy Reports
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/adhoc-leave/${props.user_uuid}`} className="nav-link" id="AdhocLeave-tab" type="button" aria-controls="process" aria-selected="false">
                                                       AdhocLeave
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/adhoc-actual/${props.user_uuid}`} className="nav-link" id="AdhocLeave-tab" type="button" aria-controls="process" aria-selected="false">
                                                        Adhoc Actual
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/file-storage/${props.user_uuid}`} className="nav-link" id="AdhocLeave-tab" type="button" aria-controls="process" aria-selected="false">
                                                        File Storage
                                                    </Link>
                                                </li>
                                            </div>
                                            <div className="d-flex align-items-center gap-2">
                                                <b> {props?.studentData?.student_first_name ? props?.studentData?.student_first_name + " " + props?.studentData?.student_last_name : "BSS"}</b>
                                                <div id="topaccountmenudata" className="symbol symbol-30px symbol-md-40px">
                                                    <img alt="Logo" style={{marginBottom:"4px"}} 
                                                        src={
                                                            props?.studentData?.student_avatar !== null && props?.studentData?.student_avatar !== "" 
                                                            ? "https://bssnew.dikonia.in/" + props?.studentData?.student_avatar
                                                            : (props?.studentData?.gender === "male" 
                                                                ? "https://bssnew.dikonia.in/media/avatars/boy.jpg" 
                                                                : "https://bssnew.dikonia.in/media/avatars/girl.jpg")

                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </ul>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="card mb-5 tabs-outer">
                                                    <div className="card-header position-relative py-0 details-bg d-flex align-items-center">
                                                        <h2 className="card-title  m-0">Attendance History</h2>
                                                        <div className="d-flex gap-2 date-picker position-relative" >
                                                        <Tooltip title="Download .pdf" placement="top">
                                                            <IconButton onClick={() => DownloadPdf()}>
                                                                <i class="fas fa-file-pdf"></i>
                                                            </IconButton>
                                                        </Tooltip>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Large text"
                                                                value={start_date + (start_date === "" ? "Select Date Range" : " to ") + end_date}
                                                                readOnly
                                                                onClick={() => setOpen(!open)}
                                                            />
                                                            <div className="position-absolute " style={{ right: "0px" }}>
                                                                <DateRangePicker
                                                                    open={open}
                                                                    toggle={toggle}
                                                                    calendars={3}
                                                                    onChange={(range) => setDateRange(range)}
                                                                    disablePast={false}

                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body p-0">
                                                        <AttendanceHistoryItem
                                                            data={attendance_date}
                                                            count={count}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
}
