import axios from "axios";
import * as actionType from "../constants/actionTypes";
let qs = require("qs");


/*** CreateClass Action ***/
export const CreateClassStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.CreateClass_START,
    };
};
export const CreateClassSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.CreateClass_SUCCESS,
    };
};
export const CreateClassFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.CreateClass_FAIL,
    };
};
export const CreateClass = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    
    return (dispatch) => {
        dispatch(CreateClassStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info?.country_host ,
                school_code: user_info?.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/CreateClass`,
        })
            .then(function (response) {
                dispatch(CreateClassSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateClassFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** GetAllClassesDetails Action ***/
export const GetAllClassesDetailsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllClassesDetails_START,
    };
};
export const GetAllClassesDetailsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllClassesDetails_SUCCESS,
    };
};
export const GetAllClassesDetailsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllClassesDetails_FAIL,
    };
};
export const GetAllClassesDetails = (page, limit, sort, order, is_class_activate ) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllClassesDetailsStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info?.country_host ,
                school_code: user_info?.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllClassesDetails`,
            params: {
                page: page,
                limit: limit,
                sort: sort,
                order: order,
                is_class_activate:is_class_activate,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllClassesDetailsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllClassesDetailsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** DeactivateClass Action ***/

export const UpdateClassStatusStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateClassStatus_START,
    };
};

export const UpdateClassStatusSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateClassStatus_SUCCESS,
    };
};

export const UpdateClassStatusFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateClassStatus_FAIL,
    };
};

export const UpdateClassStatus = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(UpdateClassStatusStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info?.country_host ,
                school_code: user_info?.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/UpdateClassStatus`,
        })
            .then(function (response) {
                dispatch(UpdateClassStatusSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateClassStatusFail("Something went wrong, Please try again."));
                return error;
            });
    };
};




/*** DeleteClass Action ***/

export const DeleteClassStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.DeleteClass_START,
    };
};
export const DeleteClassSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.DeleteClass_SUCCESS,
    };
};
export const DeleteClassFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.DeleteClass_FAIL,
    };
};
export const DeleteClass = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(DeleteClassStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "delete",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
                
            },
            url: `${actionType.environment_url}/api/bss-admin/DeleteClass`,
        })
            .then(function (response) {
                console.log("resposne", response.data);
                dispatch(DeleteClassSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                console.log("resposne fail", error);
                dispatch(DeleteClassFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** GetClassDetailsById Action ***/
export const GetClassDetailsByIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetClassDetailsById_START,
    };
};
export const GetClassDetailsByIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetClassDetailsById_SUCCESS,
    };
};
export const GetClassDetailsByIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetClassDetailsById_FAIL,
    };
};
export const GetClassDetailsById = (class_uuid) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

   
    return (dispatch) => {
        dispatch(GetClassDetailsByIdStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetClassDetailsById`,
            params: {
                class_uuid: class_uuid,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetClassDetailsByIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetClassDetailsByIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** UpdateClass Action ***/

export const UpdateClassStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateClass_START,
    };
};
export const UpdateClassSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateClass_SUCCESS,
    };
};
export const UpdateClassFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateClass_FAIL,
    };
};

export const UpdateClass = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(UpdateClassStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/UpdateClass`,
        })
            .then(function (response) {
                dispatch(UpdateClassSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateClassFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



// GetAllClassesList

export const GetAllClassesListStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllClassesList_START,
    };
};
export const GetAllClassesListSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllClassesList_SUCCESS,
    };
};
export const GetAllClassesListFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllClassesList_FAIL,
    };
};
export const GetAllClassesList = (page, limit, sort, order, is_class_activate ) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllClassesListStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllClassesList`,
            params: {
                page: page,
                limit: limit,
                sort: sort,
                order: order,
                is_class_activate:is_class_activate,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllClassesListSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllClassesListFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetAllClassStudentsCountForChart Action ***/

export const GetAllClassStudentsCountForChartStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllClassStudentsCountForChart_START,
    };
};
export const GetAllClassStudentsCountForChartSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllClassStudentsCountForChart_SUCCESS,
    };
};
export const GetAllClassStudentsCountForChartFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllClassStudentsCountForChart_FAIL,
    };
};
export const GetAllClassStudentsCountForChart = () => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllClassStudentsCountForChartStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllClassStudentsCountForChart`,
            params: {
            
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllClassStudentsCountForChartSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllClassStudentsCountForChartFail("Something went wrong, Please try again."));
                return error;
            });
    };
};