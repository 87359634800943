import React, { useState, useEffect, useCallback } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";
import SideBarLeave from "../../Constant/SideBarLeave";
import * as userActions from "../../../actions/index";
import UnArchiveBorderLeaveModal from '../LeaveModals/UnArchiveBorderLeaveModal';

const ArchiveLeaves = () => {
  const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
  const school_code = user_info?.school_code ?? null;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rowVisibility, setRowVisibility] = useState({}); // State for row visibility
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState();
  const [sort, setSort] = useState("border_leave_id");
  const [order, setOrder] = useState("desc");
  const [search, setSearch] = useState("");
  const [from_date, setFromDate] = useState("");
  const [to_date, setToDate] = useState("");
  const [dormitory_id, setdormitory_id] = useState([]);
  const [dormitory_ids, setdormitory_ids] = useState("");
  const [class_id, setclass_id] = useState([]);
  const [class_ids, setclass_ids] = useState("");
  const [BorderLeaveList, setBorderLeaveList] = useState([]);
  const [userApprovalCounts, setUserApprovalCounts] = useState('');
  const row_limit = [
    { value: 5, label: 5 },
    { value: 10, label: 10 },
    { value: 15, label: 15 },
    { value: 20, label: 20 },
    { value: 25, label: 25 }
  ]

  const tooltipContent = (status, approval_date) => {
    return (
      <Tooltip id="tooltip-disabled">
        ({status})
        {moment(approval_date).format('Do MMM YYYY')}
      </Tooltip>
    );
  };

  const renderBadge = (status, approved_comments, reject_reason, tooltip) => (
    <div>
      <OverlayTrigger overlay={tooltip}>
        <span className={`badge badge-light-${status === 'approved' ? 'success' : status === 'rejected' ? 'danger' : 'warning'}`}>
          {status}
        </span>
      </OverlayTrigger>
      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">
        {status == 'approved' ? approved_comments : reject_reason}
      </Tooltip>}>
        <i class="fa-solid fas fa-comment" style={{ color: "#511f35", }}></i>
      </OverlayTrigger>

    </div>
  );

  const renderStatusCell = (status, modal_mode, border_leave_id, approval_date, approved_comments, reject_reason) => {
    if (status == 'pending') {
      return <span className='badge badge-light-warning'>
        {status}
      </span>
    } else {
      return renderBadge(status, approved_comments, reject_reason, tooltipContent(status, approval_date));
    }
  };

  // pagination
  const handlePaginationChange = (event, value) => {
    setPage(value);
    GetArchiveStudentDetails(value)
  };
  // for limit 
  const handleLimitChange = (e, value) => {
    const limit = parseInt(e.value);
    setLimit(limit, () => {
      GetArchiveStudentDetails(limit);
    });
  };

  const handleToggleRowVisibility = (index) => {
    setRowVisibility((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Toggle visibility for the specified index
    }));
  };


  const handleErrorResponse = (response) => {
    try {
      if (response.status === 401) {
        toast.error(response.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setTimeout(() => {
          navigate('/logout');
        }, 5000);
      } else {
        //  error cases
      }
    }
    catch (e) {
    }
  };

  const GetArchiveStudentDetails = async () => {
    dispatch(userActions.GetArchiveStudentDetails(page, limit, sort, order, search, from_date, to_date, class_ids, dormitory_ids)).then((response) => {
      if (response.success === true) {
        const arrayData = response.data.rows;
        setBorderLeaveList(arrayData);
        setCount(response.data.count)
        setUserApprovalCounts(response.user_approval_counts[0])
      }
      else {
        handleErrorResponse(response);
      }
    });
    // eslint-disable-next-line
  };

  const GetAllClassesDetailsStudent = useCallback(() => {
    dispatch(userActions.GetAllClassesDetailsStudent()).then((response) => {
      if (response.success === true) {
        const arrayData = response.data.rows;
        const state_data = [
          {
            label: "Class",
            value: "",
          },
          ...arrayData.map((data) => ({
            label: data.class_name,
            value: data.class_id,
            class_id: data.class_id,
          })),
        ];
        setclass_id(state_data);
      }
    });
  }, [dispatch]);



  const ArchiveOrUnarchiveBorderLeave = async (border_leave_id, is_leave_archived) => {
    try {
      const { success, message } = await dispatch(userActions.ArchiveOrUnarchiveBorderLeave({ border_leave_id, is_leave_archived }));
      if (success) {
        toast.success(message, {
          position: toast.POSITION.TOP_CENTER,
        });
        navigate(`/${school_code}/leave/boarders-leave-details`)
        GetArchiveStudentDetails();
      } else {
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {

    }
    // eslint-disable-next-line
  };

  useEffect(() => {
    GetArchiveStudentDetails();
    GetAllClassesDetailsStudent();
  }, [page, limit]);

  return (
  
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <SideBarLeave />
            <div
              className="wrapper d-flex flex-column flex-row-fluid "
              id="kt_wrapper"
            >
              <TopBar />
              <div
                className="content d-flex flex-column flex-column-fluid" id="kt_content">
                <div className="post d-flex flex-column-fluid" id="kt_post">
                  <div id="kt_content_container" className="container-fluid">
                   
                      <div className="card">
                        <div className="card-header align-items-center">
                          <h3 className="card-title">
                            Boarders Archive Status Details
                          </h3>
                        </div>
                        <div className="card-body">
                          <div className="table-responsive">
                            <MDBTable className='table table-row-bordered'>
                              <MDBTableHead>
                                <tr>
                                  <th></th>
                                  <th>Unique Pin</th>
                                  <th>Student Name</th>
                                  <th>Year</th>
                                  <th>Dormitory</th>
                                  {/* <th>S.No</th> */}
                                  <th>Student Host	</th>
                                  <th className='min-w-150px'>Departure Date</th>
                                  <th className='min-w-100px'>Departure Time</th>
                                  <th>On time Leave</th>
                                  <th>Parent Approval</th>
                                  <th>Manager Approval</th>
                                  <th>Actions</th>
                                </tr>
                              </MDBTableHead>
                              <MDBTableBody>
                                {BorderLeaveList.length === 0 ?
                                  BorderLeaveList.slice(0, 10).map((item, index) => {
                                    return (
                                      <tr key={item.student_id}>
                                        <th scope='row'>{index + 1}</th>
                                        <td>{`${item.student_first_name} ${item.student_last_name}`}</td>
                                        <td>{item.class_name}</td>
                                        <td>{item.dormitory_data?.dormitory_name}</td>
                                        <td>{item.gender}</td>
                                        <td>{item.first_point_email
                                          == 'f' || item.first_point_email === null ? item.parent_data.father_name : item.parent_data.mother_name}</td>
                                        <td>{item.student_phone}</td>
                                        <td>
                                          <OverlayTrigger overlay={
                                            <Tooltip id="tooltip-disabled">Apply Now</Tooltip>
                                          }>
                                            <a href="#" className="btn btn-primary btn-flex btn-sm  border-0 fw-bolder px-4 px-lg-6" type="button">Apply Now</a>
                                          </OverlayTrigger>
                                        </td>
                                      </tr>
                                    )
                                  }) :
                                  BorderLeaveList.map((item, index) => {
                                    return (
                                      <React.Fragment key={item.border_leave_id}>
                                        <tr key={item.border_leave_id}>
                                          <td>
                                            <button
                                              className={`btn ${rowVisibility[index]
                                                ? 'btn-light-danger '
                                                : 'btn-light-success '
                                                } btn-sm  w-32px`}
                                              type="button"
                                              onClick={() => handleToggleRowVisibility(index)}
                                            >
                                              <i
                                                className={`bi ${rowVisibility[index]
                                                  ? 'bi-dash-circle-fill'
                                                  : 'bi-plus-circle-fill'
                                                  } p-0`}
                                              ></i>
                                            </button>
                                          </td>
                                          {/* <th scope='row'>{index + 1}</th> */}
                                          <td>{item.leave_stu_data?.unique_pin || 'N/A'}</td>
                                          <td>{item.leave_stu_data?.student_name || 'N/A'}</td>
                                          <td>{item.leave_stu_data?.stu_class_data?.class_name || 'N/A'}</td>
                                          <td>{item.leave_stu_data?.dormitory_data?.dormitory_name || 'N/A'}</td>
                                          <td>{item?.stu_host_data?.host_name || item?.parent_data?.father_name || 'N/A'}</td>
                                          <td>{moment(item.departure_date).format('Do MMM. YYYY')}</td>
                                          <td>{item.departure_time}</td>
                                          <td>
                                            {item.leave_applied_ontime === true ?
                                              (<OverlayTrigger overlay={
                                                <Tooltip id="tooltip-disabled">On Time</Tooltip>
                                              }>
                                                <span className="d-inline-block">
                                                  <i className="bi bi-hourglass-split text-success fs-2" disabled style={{ pointerEvents: 'none' }}></i>
                                                </span>
                                              </OverlayTrigger>)
                                              : (<OverlayTrigger overlay={
                                                <Tooltip id="tooltip-disabled">Late</Tooltip>
                                              }>
                                                <span className="d-inline-block">
                                                  <i className="bi bi-hourglass-bottom text-danger fs-2" disabled style={{ pointerEvents: 'none' }}></i>
                                                </span>
                                              </OverlayTrigger>)
                                            }
                                          </td>
                                          <td>{renderStatusCell(item.is_parent_approval, "parent", item.border_leave_id, item.last_parent_approval_date, item.parent_approved_comments, item.parent_rejected_reason_data?.reject_reason)}</td>
                                          <td>{renderStatusCell(item.is_user_approval, "user", item.border_leave_id, item.last_parent_approval_date, item.user_approved_comments, item.user_rejected_reason_data?.reject_reason)}</td>
                                          <td>
                                            <OverlayTrigger overlay={
                                              <Tooltip id="tooltip-disabled">View</Tooltip>
                                            }>
                                              <Link to={`/${school_code}/leave/edit-boarders-leave/${item.border_leave_id}`}><i className="bi bi-eye-fill text-primary fs-2"></i>
                                              </Link>
                                            </OverlayTrigger>
                                          </td>
                                        </tr>
                                        {rowVisibility[index] && (
                                          <>
                                            <tr className='text-bg-light'>
                                              <th></th>
                                              <th colSpan={2}>Return Date</th>
                                              <th colSpan={1}>Return Time</th>
                                              <th colSpan={2}>Mode Of Travel Departure</th>
                                              <th colSpan={2}>Mode Of Travel Return</th>
                                              <th colSpan={1}>Submit Date</th>
                                              <th colSpan={2}>Submit Time</th>
                                              <th>Action</th>
                                            </tr>
                                            <tr className='text-bg-light'>
                                              <td></td>
                                              <td colSpan={2}>{moment(item.return_date).format('Do MMM. YYYY')}</td>
                                              <td colSpan={1}>{item.return_time}</td>
                                              <td colSpan={2}>{item.departure_mode_data?.travel_mode_name}</td>
                                              <td colSpan={2}>{item.return_mode_data?.travel_mode_name}</td>
                                              <td colSpan={1}>{moment(item.created_date).format('Do MMM. YYYY')}</td>
                                              <td colSpan={2}>{moment(item.created_date).format('hh:mm a')}</td>
                                              <td>
                                                <UnArchiveBorderLeaveModal ArchiveOrUnarchiveBorderLeave={() => {
                                                  // Call your function to perform the archive action here
                                                  ArchiveOrUnarchiveBorderLeave(item.border_leave_id, item.is_leave_archived = false);
                                                }} />
                                              </td>
                                            </tr>
                                          </>
                                        )}
                                      </React.Fragment>
                                    )
                                  })}
                              </MDBTableBody>
                            </MDBTable>

                            <div className="d-flex justify-content-between">
                              <Select
                                className="basic-single"
                                classNamePrefix="select"
                                defaultValue={row_limit[1]}
                                name="color"
                                options={row_limit}
                                onChange={handleLimitChange}
                              />
                              <Stack>
                                <Pagination
                                  count={Math.ceil(count / limit)}
                                  page={page}
                                  onChange={handlePaginationChange}
                                  color="primary"
                                />
                              </Stack>
                            </div>
                          </div>
                        </div>
                      </div>
                   
                  </div>
                </div>
                </div>
                <Footer />
           
            </div>
          </div>
        </div>
      </div>

  );
}
export default ArchiveLeaves;