import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";

const EditAdhocleaveTime = (props) => {
  const [leaveData, setLeaveData] = useState({})
  const [start_time, setStart_time] = useState("")
  const [end_time , setEnd_time] = useState("")
  useEffect(() => {
    setLeaveData(props.leaveData)
    setStart_time(props.leaveData.start_time)
    setEnd_time(props.leaveData.end_time)
  }, [props])
  
  const setDataAndSubmit = () => {
    const payload = {}
    payload['adhoc_leave_id'] = leaveData.adhoc_leave_id;
    payload['start_time'] = start_time;
    payload['end_time'] = end_time;
    props.SubmitHandler(payload)
  }

  console.log("leave", leaveData);
  return (
    <React.Fragment>
      <Modal
        backdrop="static"
        keyboard={false}
        className="confirm_delete_modal confirm_modal"
        show={props.confirm_modal}
        onHide={() => { props.modalHide() }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Adhoc Leave Time</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mb-5">
            <div className='col-5'>
              <label className="fw-bold">Adhoc Leave Day</label>
            </div>
            <div className='col-7'>
              <p>
                {leaveData.adhoc_leave_day}
              </p>
            </div>
          </div>
          <div className="row mb-5">
            <div className='col-5'>
              <label className="fw-bold">Adhoc Leave Date</label>
            </div>
            <div className='col-7'>
              <p>
                {moment(leaveData.arrival_date).format('Do MMM. YYYY')}
              </p>
            </div>
          </div>

          <div className="row mb-5">
            <div className='col-5'>
              <label className="fw-bold">Start Time</label>
            </div>
            {console.log("start_time",start_time)}
            <div className='col-7'>
              <input
                type="time"
                className="form-control form-control-sm"
                value={start_time}
                onChange={(event) => setStart_time(event.target.value)}
              />
            </div>
          </div>

          <div className="row mb-5">
            <div className='col-5'>
              <label className="fw-bold">End Time</label>
            </div>
            <div className='col-7'>
              <input
                type="time"
                className="form-control form-control-sm"
                value={end_time}
                onChange={(event) => setEnd_time(event.target.value)}
              />
            </div>
          </div>

          <div className="col-sm-12 mt-4 d-flex justify-content-end">
            <button type="submit" className="btn btn-sm btn-primary" onClick={() =>setDataAndSubmit()}>
              Submit
            </button>
            <button type="submit" className="btn btn-sm btn-secondary ms-4" onClick={() => { props.modalHide() }}>
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );

}
export default EditAdhocleaveTime;