import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const GetBirthdayItem = (props) => {
    const day = new Date().getUTCDay();
    const daysArray = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const [birthdayData, setBirthdayData] = useState([])
    useEffect(() => {
        setBirthdayData(props.data)
    }, [props.data])

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-xl-12">
                    

                            <div className=" font-medium text-center justify-content-center  mt-4 d-flex align-items-center border border-dashed border-gray-300 rounded  px-7 py-3 mb-4 gap-5 ">
                                <i class="fas fa-calendar-alt"></i> {daysArray[day]} {props.currentDate}
                            </div>
             
                            <div className="table-responsive">
                            <table class="table table-row-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">Student</th>
                                        <th scope="col">Birth Date</th>
                                        <th scope="col">Class</th>
                                        <th scope="col">Dormitory</th>
                                        <th scope="col">Age</th>
                                    </tr>
                                    
                                </thead>
                                <tbody>
                                    {birthdayData.map((birthday) => {
                                        return (
                                            <tr>
                                                <td className="mb-0 heding-element me-4">{birthday.student_first_name + " " + birthday.student_last_name}</td>
                                                <td className="mb-0 heding-element me-4">{birthday.date_of_birth}</td>
                                                <td className="mb-0 heding-element me-4">{birthday.class_name} </td>
                                                <td className="mb-0 heding-element me-4">{birthday.dormitory_data.dormitory_name} </td>
                                                <td className="mb-0 heding-element me-4">{moment().diff(moment(birthday.date_of_birth, "YYYY-MM-DD"), 'years')} </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                       
                            </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default GetBirthdayItem;