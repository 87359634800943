import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import moment from "moment";
import "moment-timezone";
// import "react-datepicker/dist/react-datepicker.css";
// import * as actionType from "../../actions/index";


const DiscardedSessionItem = (props) => {
const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    const columns = ([
     {
       text: "",
       dataField: "Actions",
       formatter: (cellContent, row, index, extraData) => {
       // console.log("ROW", row);
       const current = new Date(row.sessionEndTime);
       const timezone = user_info.user_school.timezone;
       const date = moment(current).tz(timezone).format('MMMM Do YYYY, h:mm a');
        
       let countIndex = props.data.indexOf(row);
       let count = countIndex + 1
    //    let localDate = ;
       return (
               <div className="px-2">
                <div className="py-5 px-5 " >
                 
                 <div style={{liststyletype: "none"}}> 
                    {/* {console.log("checkoing console.log in Discarded session ITEM", props.data.indexOf(row))} */}
                    
                    <span className="mx-2 position-relative">
                        {`${count}.`}</span>
                        <strong>{row.customName} </strong>RollCall sessions is discarded by <strong>{row.manager} </strong> at  <strong>{date} ({row.alotedSessionTime}  minutes time slot) </strong>
                 </div>
                </div>
                </div>
                );
            },
        },
    ]);
    const handleTableChange = (type, { sortField, sortOrder, page }) => {
        if (type === "sort") {
            props.propsfiltersort(sortField);
            props.propsfilterorder(sortOrder);
        }
    };
    const RemotePagination = ({ columns, dataField, order, data, page, sizePerPage, onTableChange, totalSize }) => (
        <PaginationProvider
            pagination={paginationFactory({
                custom: true,
                page,
                sizePerPage,
                totalSize,
            })}
        >
            {({ paginationProps, paginationTableProps }) => (
                <>
                    <div className="table-responsive common-table superadmin_table">
                        <BootstrapTable
                            remote
                            bootstrap4
                            data={(data.length >= 1)? data : []}
                            keyField="student_uuid"
                            sort={{ dataField: dataField, order: order }}
                            columns={columns}
                            {...paginationTableProps}
                            onTableChange={onTableChange}
                            sizePerPageList={sizePerPage}
                            headerClasses="fw-bolder text-white bg-dark-row"
                            classes="table-bordered align-middle custom-table-style table-fields"
                            noDataIndication={() => <span className="text-center text-gray-400">No records found</span>}
                        // selectRow={selectRow}
                        />
                    </div>

                </>
            )}
        </PaginationProvider>
    );
    return (
        <React.Fragment>
             <div className="table-responsive">
                                <RemotePagination
                                    data={props.data}
                                    indexCount = {props.indexcount}
                                    columns={columns}
                                    page={props.filterpage}
                                    // totalSize={props.StudentListcount}
                                    sizePerPage={props.filterlimit}
                                    order={props.filterorder}
                                    dataField={props.filtersort}
                                    onTableChange={handleTableChange}
                                />  
                        </div>
        </React.Fragment>
    );
};
export default DiscardedSessionItem;