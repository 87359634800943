import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import DuplicateRoleModal from './DuplicateRoleModal';
import DeleteRoleModal from './DeleteRoleModal';
import DeactivateRoleModal from './DeactivateRoleModal';
import { toast } from "react-toastify";
import * as userActions from "../../actions/index";
import { connect, useDispatch } from "react-redux";


const permission_infos = JSON.parse(localStorage.getItem("permissions"));
const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info.school_code;

const EditableRow = ({
    index, item,
    editRoleRow,
    updateRoleName,
    handleCancelClick,
    editName,
    permission_info,
    handleEdit,
    onEnter_editName,
    CreateDuplicateRoleAndPerm,
    all_role_name,
    DeleteRole
}) => {

    return (
        <tr>
            <td>{index + 1}</td>
            <td style={{width:"61%"}}>
                <input
                    type="text"
                    placeholder="Roll Name"
                    value={item.role_name}
                    onChange={(event) => editName(event, item, index,)}
                    onKeyDown={(event) => onEnter_editName(event, item, index,)}
                />
                <div className="d-inline">

                    <button
                        className="btn-link" data-bs-toggle="tooltip"
                        onClick={() => updateRoleName(item.role_uuid, item.role_name)}
                    >
                        <i class="fas fa-check"></i>
                    </button>

                    <button
                        className="btn-link"
                        onClick={() => handleCancelClick()}
                    >
                        <i class="fas fa-times"></i>
                    </button>
                </div>
            </td>

            <td className="text-justify">
                <div >
                    {item.is_role_activate === true ? (
                        permission_infos?.deactivate_role !== false &&
                        <div className="d-flex align-items-center">
                            <DeactivateRoleModal
                                role_id={item.role_id}
                                role_uuid={item.role_uuid}
                            />
                            <span>
                                Deactivate Role
                            </span>
                        </div>
                    ) : (
                        <div className="d-flex align-items-center">
                            <span className="deactivate-button1">
                                <i className="bi bi-bell me-2 fs-6"></i>Activate Role
                            </span>
                        </div>
                    )}
                </div>
            </td>

            <td className="text-justify">
                <div className="d-flex justify-content">
                    {item.is_role_activate === true ? (
                        <span className="badge badge-light-success px-4 py-2">Active</span>
                    ) : (
                        <span className="badge badge-light-danger px-4 py-2">Inactive</span>
                    )}
                </div>
            </td>

            <td className="text-end">
                <div className="d-flex justify-content-end flex-shrink-0">
                    {
                        //(permission_infos === null || permission_infos?.delete_role === true )&&
                        permission_infos?.delete_role !== false &&
                        <DeleteRoleModal
                            role_id={item.role_id}
                            role_uuid={item.role_uuid}
                            DeleteRole={DeleteRole}
                        />
                    }
                    {
                        //(permission_infos === null || permission_infos?.add_duplicate_role === true) &&
                        permission_infos?.add_duplicate_role !== false &&
                        <DuplicateRoleModal
                            role_uuid={item.role_uuid}
                            createI_role_name={CreateDuplicateRoleAndPerm}
                            all_role_name={all_role_name}
                        />
                    }
                    {
                        // (permission_infos === null || permission_infos?.edit_permission === true) &&
                        permission_infos?.edit_permission !== false &&
                        (<Link
                            to={`${school_code}/permissions-list/edit-permission/${item.role_uuid}`}
                            data-bs-toggle="tooltip"
                            title=""
                            data-bs-original-title="Edit"
                            onClick={() => handleEdit(item.role_id)}
                        >
                            <IconButton>
                                <i className="far fa-edit me-2 fs-6"></i>
                            </IconButton>
                        </Link>)
                    }
                </div>
            </td>
        </tr>
    );
};

export default EditableRow;