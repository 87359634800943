import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HistoricalPermission from "../component/Permissions/HistoricalPermission";
import PermissionAdd from "../component/Permissions/PermissionAdd";
import PermissionEdit from "../component/Permissions/PermissionEdit";
import PermissionList from "../component/Permissions/PermissionList";
import AddRollModal from "../component/Permissions/AddRollModal";
import NoPermissionPage from "../component/Permissions/NoPermissionPage";



const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" :user_info?.school_code;

const Permission = (props) => {
    const Renderview = () => {
        let location = useLocation();
        let userId = useParams();
        console.log("userId=====2",userId);
        if (location.pathname === `/${school_code}/permissions-list` || location.pathname === `/${school_code}/permissions-list/`) {
            return <PermissionList />;
        }
        if (location.pathname === `/${school_code}/permissions-list/add-permission` || location.pathname === `/${school_code}/permissions-list/add-permission/`) {
            return <PermissionAdd /> ;
        }
        if (location.pathname === `/${school_code}/permissions-list/edit-permission/${userId.id}` || location.pathname === `/${school_code}/permissions-list/edit-permission/${userId.id}/`) {
            return <PermissionEdit role_uuid={userId.id}/> ;
        }
        if (location.pathname === `/${school_code}/permissions-list/hostorical-permission` || location.pathname === `/${school_code}/permissions-list/hostorical-permission/`) {
            return <HistoricalPermission /> ;
        }
        if (location.pathname === `/${school_code}/no-permission` || location.pathname === `/${school_code}/no-permission/`) {
            return <NoPermissionPage/> ;
        }
    };

    return (
        <React.Fragment>
            <ToastContainer />
            {Renderview()}
        </React.Fragment>
    );
};
export default Permission;