import React from "react";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import Validations from "../../Utility/Validations";
import Sidebar from "../../Constant/sidebar";
import Footer from "../../Constant/Footer";
import TopBar from "../../Constant/TopBar";
import { toast } from "react-toastify";
import * as userActions from "../../../actions/index";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Tooltip from '@mui/material/Tooltip';
import * as actionType from "../../../constants/actionTypes";
import AvatarImageCropper from 'react-avatar-image-cropper';
import Picture from "./Picture";
import IconButton from '@mui/material/IconButton';

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#e7f5fe" : "",
        },
        backgroundColor: state.isSelected ? "#4a8fb8" : "",
    }),
};

const permission_info = JSON.parse(localStorage.getItem("permissions"));
const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info?.school_code;

class EditStudent extends React.Component {
    constructor(props) {
        super(props);
        window.addEventListener('keydown', this.handleKeyPress);
        this.props = props;
        this.state = {
            initial_name:"",
            upDate_parent_id:"",
            selectFatherCountry: false,
            selectMotherCountry: false,
            selectFatherState: false,
            selectMotherState: false,
            OpenImageModal: false,
            cam_Start: null,
            selectedmotherCountry: "",
            selectedfatherCountry: "",
            hiddenpassword: true,
            isOpen: false,
            edit_student_page: {
                is_preffered_name_update:{
                    value:false
                },
                student_first_name: {
                    type: "text",
                    label: "First Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },

                student_last_name: {
                    type: "text",
                    label: "Last Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },

                preferred_name: {
                    type: "text",
                    label: "Preferred Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },

                student_email: {
                    type: "email",
                    label: "Student E-mail address *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, email: true },
                },

                class_id: {
                    type: "select",
                    label: "Select class *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                    options: [],
                },

                student_phone: {
                    type: "text",
                    label: "Student's Mobile Number *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },

                class_name: {
                    type: "text",
                    label: "Select class *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                    options: [],
                },

                campus_name: {
                    type: "text",
                    label: "Campus",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                dormitory_id_name: {
                    type: "text",
                    label: "",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "9876543210",
                    validations: { required: false },
                },

                dormitory_id: {
                    type: "select",
                    label: "Select Dormitory *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                    options: [],
                },


                sporting_house: {
                    type: "text",
                    label: "Sporting House",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },

                laundry_number: {
                    type: "text",
                    label: "Laundry Number",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },

                tutor_name: {
                    type: "text",
                    label: "PC or Tutor or Carer",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },

                show_parent_detail_app: {
                    type: "checkbox",
                    label: "Show Parent Details on Liberty App",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                tutor_email: {
                    type: "email",
                    label: "E-mail address(PC or Tutor or Carer)",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false, email: true },
                },

                date_of_birth: {
                    type: "date",
                    label: "Date Of Birth *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },

                student_age: {
                    type: "text",
                    label: "Age *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },

                kiosk_mode_pin: {
                    type: "number",
                    label: "Kiosk Mode PIN",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false, max_length: 6, number: true },
                },



                parent_address_data: [
                    {
                        parent_address_type: {
                            type: "text",
                            label: "",
                            value: "father",
                            valid: true,
                            error_msg: "",
                            onBlur_out: false,
                            placeholder: "",
                            validations: { required: true },
                        },
                        address_line1: {
                            type: "text",
                            label: "Address Line 1 *",
                            value: "",
                            valid: true,
                            error_msg: "",
                            onBlur_out: false,
                            placeholder: "",
                            validations: { required: true },
                        },
                        address_line2: {
                            type: "text",
                            label: "Address Line 2 *",
                            value: "",
                            valid: true,
                            error_msg: "",
                            onBlur_out: false,
                            placeholder: "",
                            validations: { required: true },
                        },
                        parent_state: {
                            type: "text",
                            label: "State",
                            value: "",
                            valid: true,
                            options: [],
                            error_msg: "",
                            onBlur_out: false,
                            placeholder: "",
                            validations: { required: false },
                        },
                        parent_city: {
                            type: "text",
                            label: "City",
                            value: "",
                            valid: true,
                            options: [],
                            error_msg: "",
                            onBlur_out: false,
                            placeholder: "",
                            validations: { required: false },
                        },
                        parent_postcode: {
                            type: "text",
                            label: "Post Code *",
                            value: "",
                            valid: true,
                            error_msg: "",
                            onBlur_out: false,
                            placeholder: "",
                            validations: { required: true },
                        },
                        parent_country: {
                            type: "text",
                            label: "Country *",
                            value: "",
                            valid: true,
                            options: [],
                            error_msg: "",
                            onBlur_out: false,
                            placeholder: "",
                            validations: { required: true },
                        },
                        parent_address_id: "",
                    },
                    {
                        address_line1: {
                            type: "text",
                            label: "Address Line 1 *",
                            value: "",
                            valid: true,
                            error_msg: "",
                            onBlur_out: false,
                            placeholder: "",
                            validations: { required: true },
                        },
                        address_line2: {
                            type: "text",
                            label: "Address Line 2 *",
                            value: "",
                            valid: true,
                            error_msg: "",
                            onBlur_out: false,
                            placeholder: "",
                            validations: { required: true },
                        },
                        parent_state: {
                            type: "text",
                            label: "State",
                            value: "",
                            valid: true,
                            options: [],
                            error_msg: "",
                            onBlur_out: false,
                            placeholder: "",
                            validations: { required: false },
                        },
                        parent_city: {
                            type: "text",
                            label: "City",
                            value: "",
                            valid: true,
                            options: [],
                            error_msg: "",
                            onBlur_out: false,
                            placeholder: "",
                            validations: { required: false },
                        },
                        parent_postcode: {
                            type: "text",
                            label: "Post Code *",
                            value: "",
                            valid: true,
                            error_msg: "",
                            onBlur_out: false,
                            placeholder: "",
                            validations: { required: true },
                        },
                        parent_country: {
                            type: "text",
                            label: "Country *",
                            value: "",
                            valid: true,
                            options: [],
                            error_msg: "",
                            onBlur_out: false,
                            placeholder: "",
                            validations: { required: true },
                        },
                        parent_address_type: {
                            type: "text",
                            label: "Country",
                            value: "mother",
                            valid: true,
                            error_msg: "",
                            onBlur_out: false,
                            placeholder: "",
                            validations: { required: true },
                        },
                        parent_address_id: "",
                    },
                ],

                father_name: {
                    type: "text",
                    label: "Father's Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },

                mother_name: {
                    type: "text",
                    label: "Mother's Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },

                father_phone: {
                    type: "text",
                    label: "Father's Mobile Number *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, min_length_phone: 7 },
                },

                mother_phone: {
                    type: "text",
                    label: "Mother's Mobile Number *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, min_length_phone: 7 },
                },

                father_home_phone: {
                    type: "text",
                    label: "Home phone(Father)",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },

                father_work_phone: {
                    type: "text",
                    label: "Work phone(Father)",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },

                mother_home_phone: {
                    type: "text",
                    label: "Home phone(Mother)",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },

                mother_work_phone: {
                    type: "text",
                    label: "Work phone(Mother)",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },

                salutation: {
                    type: "text",
                    label: "Salutation",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },

                father_email: {
                    type: "email",
                    label: "Father's Email *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, email: true },
                },

                mother_email: {
                    type: "email",
                    label: "Mother's Email *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, email: true },
                },

                first_point_contact: {
                    type: "select",
                    label: "First Point of Contact(Number) *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                    options: [
                        { label: "First Point of Contact(Number)", value: "" },
                        { label: "Father's Contact Number", value: "f" },
                        { label: "Mother's Contact Number", value: "m" },
                    ],
                },

                first_point_email: {
                    type: "select",
                    label: "First Point of Contact(Email) *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                    options: [
                        { label: "First Point of Contact(Email)", value: "" },
                        { label: "Father's Email Address", value: "f" },
                        { label: "Mother's Email Address", value: "m" },
                    ],
                },

                show_parent_detail_app: {
                    type: "checkbox",
                    label: "Show Parent Details on Liberty App",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                exclude_automatic_host: {
                    type: "checkbox",
                    label: "Exclude Parents As Automatic Host ",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                allergy_unauth_access: {
                    type: "checkbox",
                    label: "Allow direct unauthenticated access to Allergy & Emergency details from Student App Login Screen",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                withdraw_leave: {
                    type: "checkbox",
                    label: "Withdraw Ad-Hoc Leave",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                permitted_student_app_signout: {
                    type: "checkbox",
                    label: "Permitted to Sign Out using Student App",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                display_image_required: {
                    type: "radio",
                    label: "Want to display Image? * ",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: true },
                },

                student_allergy_status: {
                    type: "radio",
                    label: "Allergic",
                    value: "",
                    valid: true,
                    status: false,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: false },
                },

                gender: {
                    type: "radio",
                    label: "Gender * ",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: true },
                },

                student_avatar: {
                    label: "File",
                    type: "file",
                    value: '',
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                    // thumbnail_image: "/assets/media/school-logo.png",
                    thumbnail_image: "",
                },
                school_detail_id: {
                    type: "hidden",
                    label: "",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },

                student_uuid: {
                    type: "text",
                    label: "student uuid",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },
                student_id: {
                    type: "text",
                    label: "student id",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },

                parent_uuid: {
                    type: "text",
                    label: "student uuid",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },

                school_name: {
                    type: "hidden",
                    label: "",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",

                },

                country_code: {
                    type: "hidden",
                    label: "country_host",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "dikonia@gmail.com",
                    validations: { required: false },
                },
                allergy_data: [],
                host_data: [],
            },


            country_id: {
                type: "select",
                label: "Country *",
                value: "",
                valid: true,
                error_msg: "",
                onBlur_out: false,
                // placeholder: "Select weekend day",
                validations: { required: true },
                options: [],
            },
            student_age_show: {
                type: "text",
                label: "Age *",
                value: "",
                valid: true,
                error_msg: "",
                onBlur_out: false,
                placeholder: "",
                validations: { required: true },
            },
            address_same_as_father: {
                type: "checkbox",
                isChecked: '',
                label: "Show Parent Details on Liberty App",
                value: false,
                valid: true,
                error_msg: "",
                validations: { required: false },
            },
            father_details: false,
            mother_details: false,

            father_as_host: {
                type: "checkbox",
                isChecked: '',
                label: "Show Parent Details on Liberty App",
                value: false,
                valid: true,
                error_msg: "",
                validations: { required: false },
            },
            mother_as_host: {
                type: "checkbox",
                isChecked: '',
                label: "Show Parent Details on Liberty App",
                value: false,
                valid: true,
                error_msg: "",
                validations: { required: false },
            },
            motherAdd: "",
            fatherAdd: "",
        };
    }



    componentDidMount() {
        this.GetStudentAndParentById();
        this.GetAllSelectedCountries();
        this.GetAllDormitoryStudent();
        this.GetAllClassesList();
        const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
        const update_edit_student_page = { ...this.state.edit_student_page };
        update_edit_student_page['country_code'].value = user_info.user_school.country_code;
        this.setState({ edit_student_page: update_edit_student_page });
    }


    GetAllClassesList = async (form_data) => {
        this.props.onGetAllClassesList().then((response) => {
            let country_level = [];
            country_level.push({
                label: "Select Class",
                value: "",
            });
            const update_edit_student_page = { ...this.state.edit_student_page };
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    country_level.push({
                        label: arrayData[key].class_name,
                        value: arrayData[key].class_id,
                        class_name: arrayData[key].class_name,
                    });
                }
            }
            update_edit_student_page['class_id'].options = country_level;
            this.setState({ edit_student_page: update_edit_student_page });
        });
    }

    GetAllDormitoryStudent = async (form_data) => {
        this.props.onGetAllDormitoryStudent().then((response) => {
            let country_level = [];
            country_level.push({
                label: "Select Dormitory",
                value: "",
            });
            const update_edit_student_page = { ...this.state.edit_student_page };
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    country_level.push({
                        label: arrayData[key].dormitory_name,
                        value: arrayData[key].dormitory_id,
                    });
                }
            }
            update_edit_student_page['dormitory_id'].options = country_level;
            this.setState({ edit_student_page: update_edit_student_page });
        });
    }


    GetAllSelectedCountries = async (form_data) => {
        this.props.onGetAllCountriesForImportStudents().then((response) => {
            let country_level = [];
            country_level.push({
                label: "Country",
                value: "",
            });
            const update_country = { ...this.state.country_id };
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    const country_name = arrayData[key].name.toLowerCase()
                    country_level.push({
                        label: country_name,
                        value: arrayData[key].country_id,
                    });
                }
            }
            update_country.options = country_level;
            this.setState({ country_id: update_country });
        });
    }


    ////////////////////////////// Parent address API's ////////////////////////
    ///////////////////////////// for country ///////////////////////

    GetAllSelectedCountriesForFather = async () => {
        this.props.onGetAllSelectedCountries().then((response) => {
            let country_level = [];
            country_level.push({
                label: "Country",
                value: "",
            });
            const update_edit_student_page = this.state.edit_student_page
            const update_parent_address_data = update_edit_student_page.parent_address_data
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    country_level.push({
                        label: arrayData[key].name,
                        value: arrayData[key].country_id,
                        country_code: arrayData[key].country_code,
                    });
                }
            }
            if (this.state.selectFatherCountry === false) {
                const parent_country_value = country_level.find(country => country.label.toLowerCase() === update_parent_address_data[0].parent_country.value.toLowerCase())
                console.log("country_level", parent_country_value);
                this.GetAllStatesByCountryIdForFather(parent_country_value !== undefined ? parent_country_value.value : "")
            }
            update_parent_address_data[0].parent_country.options = country_level;
            this.setState({ parent_address_data: update_parent_address_data })
        });
    }
    GetAllSelectedCountriesForMother = async () => {
        this.props.onGetAllSelectedCountries().then((response) => {

            let country_level = [];
            country_level.push({
                label: "Country",
                value: "",
            });
            const update_edit_student_page = this.state.edit_student_page
            const update_parent_address_data = update_edit_student_page.parent_address_data
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    country_level.push({
                        label: arrayData[key].name,
                        value: arrayData[key].country_id,
                        country_code: arrayData[key].country_code,
                    });
                }
            }


            if (this.state.selectMotherCountry === false) {
                const parent_country_value = country_level.find(country => country.label.toLowerCase() === update_parent_address_data[1].parent_country.value.toLowerCase())
                this.GetAllStatesByCountryIdForMother(parent_country_value !== undefined ? parent_country_value.value : "")
            }
            update_parent_address_data[1].parent_country.options = country_level;
            this.setState({ parent_address_data: update_parent_address_data })
        });
    }
    ///////////////////////////// for state ///////////////////////

    GetAllStatesByCountryIdForFather = async (CountryID) => {
        this.props.onGetAllStatesByCountryId(CountryID).then((response) => {
            let state_level = [];
            state_level.push({
                label: "State",
                value: "",
            });
            const update_edit_student_page = this.state.edit_student_page
            const update_parent_address_data = update_edit_student_page.parent_address_data
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    if (arrayData[key].is_activate === true) {
                        state_level.push({
                            label: arrayData[key].state_name,
                            value: arrayData[key].state_id,
                        });
                    }
                }
                if (this.state.selectFatherState === false) {
                    console.log("update_parent_address_data", state_level,update_parent_address_data);
                    const parent_state_value = state_level.find(state => state.label.toLowerCase() === update_parent_address_data[0]?.parent_state?.value.toLowerCase())
                    this.GetAllCitiesByStateIdForFather(parent_state_value !== undefined ? parent_state_value.value : "")
                    this.setState({ selectFatherState: true })
                }
                update_parent_address_data[0].parent_state.options = state_level;
                this.setState({ parent_address_data: update_parent_address_data })
            }
        });
    }
    GetAllStatesByCountryIdForMother = async (CountryID) => {
        this.props.onGetAllStatesByCountryId(CountryID).then((response) => {
            console.log("response", response);
            let state_level = [];
            state_level.push({
                label: "State",
                value: "",
            });
            const update_edit_student_page = this.state.edit_student_page
            const update_parent_address_data = update_edit_student_page.parent_address_data
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    if (arrayData[key].is_activate === true) {
                        state_level.push({
                            label: arrayData[key].state_name,
                            value: arrayData[key].state_id,
                        });
                    }
                }
                if (this.state.selectMotherState === false) {
                    const parent_state_value = state_level.find(state => state.label.toLowerCase() === update_parent_address_data[1].parent_state.value.toLowerCase())
                    this.GetAllCitiesByStateIdForMother(parent_state_value !== undefined ? parent_state_value.value : "")
                    this.setState({ selectMotherState: true })
                }
                update_parent_address_data[1].parent_state.options = state_level;
                this.setState({ parent_address_data: update_parent_address_data })
            }
        });
    }

    ///////////////////////////// for city ///////////////////////

    GetAllCitiesByStateIdForFather = async (state_id) => {
        this.props.onGetAllCitiesByStateId(state_id).then((response) => {
            let City_level = [];
            City_level.push({
                label: "City",
                value: "",
            });
            const update_edit_student_page = this.state.edit_student_page
            const update_parent_address_data = update_edit_student_page.parent_address_data
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    if (arrayData[key].is_activate === true) {
                        City_level.push({
                            label: arrayData[key].city_name,
                            value: arrayData[key].city_id,
                        });
                    }
                }
            }
            update_parent_address_data[0].parent_city.options = City_level;
            this.setState({ parent_address_data: update_parent_address_data })
        });
    }

    GetAllCitiesByStateIdForMother = async (state_id) => {
        this.props.onGetAllCitiesByStateId(state_id).then((response) => {
            let City_level = [];
            City_level.push({
                label: "City",
                value: "",
            });
            const update_edit_student_page = this.state.edit_student_page
            const update_parent_address_data = update_edit_student_page.parent_address_data
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    if (arrayData[key].is_activate === true) {
                        City_level.push({
                            label: arrayData[key].city_name,
                            value: arrayData[key].city_id,
                        });
                    }
                }
            }
            update_parent_address_data[1].parent_city.options = City_level;
            this.setState({ parent_address_data: update_parent_address_data })
        });
    }


    GetStudentAndParentById() {
        this.props.onGetStudentAndParentById(this.props.user_uuid).then((response) => {
            if (response.success === true) {
                let student_data = response.data;
                this.setState({upDate_parent_id : student_data.parent_id })
                const update_edit_student_page = { ...this.state.edit_student_page };
                const update_father_as_host = this.state.father_as_host;
                const update_mother_as_host = this.state.mother_as_host;
                const update_address_same_as_father = this.state.address_same_as_father;
                const add1 = '';
                const add2 = '';
                for (let key in update_edit_student_page) {
                    let form_element = update_edit_student_page[key];
                    if (student_data[key]) {
                        if (student_data.student_avatar !== null && student_data.student_avatar !== '') {
                            update_edit_student_page['student_avatar'].thumbnail_image = actionType.environment_url + '/' + student_data.student_avatar;
                            update_edit_student_page['student_avatar'].value = student_data.student_avatar;
                        }
                        if (key === 'class_id') {
                            form_element.value = parseInt(student_data[key]);
                        } else {
                            form_element.value = student_data[key];
                        }


                        let allergy_array = []
                        update_edit_student_page['allergy_data'] = student_data.allergy_details.map((item, index) => {
                            return ({
                                allergy_name: {
                                    type: "text",
                                    label: "Allergy Name *",
                                    value: item.allergy_name,
                                    valid: true,
                                    error_msg: "",
                                    onBlur_out: false,
                                    placeholder: "",
                                    validations: { required: true },
                                },
                                allergy_info: {
                                    type: "text",
                                    label: "Allergy Info *",
                                    value: item.allergy_info,
                                    valid: true,
                                    error_msg: "",
                                    onBlur_out: false,
                                    placeholder: "",
                                    validations: { required: true },
                                },
                                student_allergy_id: {
                                    type: "text",
                                    label: "student_allergy_id",
                                    value: item.student_allergy_id,
                                    valid: true,
                                    error_msg: "",
                                    onBlur_out: false,
                                    placeholder: "",
                                    validations: { required: true },
                                },
                                student_allergy_uuid: {
                                    type: "text",
                                    label: "student_allergy_uuid",
                                    value: item.student_allergy_uuid,
                                    valid: true,
                                    error_msg: "",
                                    onBlur_out: false,
                                    placeholder: "",
                                    validations: { required: true },
                                }
                            })
                        });


                        update_edit_student_page['student_id'].value = student_data.student_id;
                        update_edit_student_page['student_first_name'].value = student_data.student_first_name;
                        update_edit_student_page['student_last_name'].value = student_data.student_last_name;
                        update_edit_student_page['preferred_name'].value = student_data.preferred_name;
                        update_edit_student_page['student_email'].value = student_data.student_email;
                        update_edit_student_page['campus_name'].value = student_data.campus_name;
                        update_edit_student_page['sporting_house'].value = student_data.sporting_house;
                        update_edit_student_page['laundry_number'].value = student_data.laundry_number;
                        update_edit_student_page['tutor_name'].value = student_data.tutor_name;
                        update_edit_student_page['tutor_email'].value = student_data.tutor_email;
                        update_edit_student_page['date_of_birth'].value = student_data.date_of_birth;
                        update_edit_student_page['student_age'].value = student_data.student_age;
                        // update_edit_student_page['salutation'].value = student_data.salutation;
                        update_edit_student_page['gender'].value = student_data.gender;
                        update_edit_student_page['display_image_required'].value = JSON.stringify(student_data.display_image_required);
                        update_edit_student_page['permitted_student_app_signout'].value = student_data.permitted_student_app_signout;
                        update_edit_student_page['permitted_student_app_signout'].isChecked = student_data.permitted_student_app_signout;
                        update_edit_student_page['withdraw_leave'].value = student_data.withdraw_leave;
                        update_edit_student_page['withdraw_leave'].isChecked = student_data.withdraw_leave;

                        update_edit_student_page['allergy_unauth_access'].value = student_data.allergy_unauth_access;
                        update_edit_student_page['allergy_unauth_access'].isChecked = student_data.allergy_unauth_access;

                        if (student_data.allergy_unauth_access === "true") {
                            update_edit_student_page['allergy_unauth_access'].value = true;
                            update_edit_student_page['allergy_unauth_access'].isChecked = true;
                        }
                        if (student_data.allergy_unauth_access === "false") {
                            update_edit_student_page['allergy_unauth_access'].value = false;
                            update_edit_student_page['allergy_unauth_access'].isChecked = false;
                        }
                        update_edit_student_page['exclude_automatic_host'].value = student_data.exclude_automatic_host;
                        update_edit_student_page['exclude_automatic_host'].isChecked = student_data.exclude_automatic_host;
                        update_edit_student_page['show_parent_detail_app'].value = student_data.show_parent_detail_app;
                        update_edit_student_page['show_parent_detail_app'].isChecked = student_data.show_parent_detail_app;
                        update_edit_student_page['student_uuid'].value = student_data.student_uuid;
                        this.setState({ edit_student_page: update_edit_student_page });
                        this.setState({initial_name:student_data.preferred_name})
                        update_edit_student_page[key] = form_element;
                    }
                }

                if (student_data.parent_data) {
                    update_edit_student_page['father_name'].value = student_data.parent_data.father_name;
                    update_edit_student_page['mother_name'].value = student_data.parent_data.mother_name;
                    update_edit_student_page['father_email'].value = student_data.parent_data.father_email;
                    update_edit_student_page['mother_email'].value = student_data.parent_data.mother_email;
                    update_edit_student_page['father_phone'].value = student_data.parent_data.father_phone;
                    update_edit_student_page['mother_phone'].value = student_data.parent_data.mother_phone;
                    update_edit_student_page['father_home_phone'].value = student_data.parent_data.father_home_phone;
                    update_edit_student_page['mother_home_phone'].value = student_data.parent_data.mother_home_phone;
                    update_edit_student_page['father_work_phone'].value = student_data.parent_data.father_work_phone;
                    update_edit_student_page['mother_work_phone'].value = student_data.parent_data.mother_work_phone;
                    update_edit_student_page['parent_uuid'].value = student_data.parent_data.parent_uuid;
                    update_edit_student_page['salutation'].value = student_data.parent_data.salutation;

                    if (student_data.parent_data.parent_address.length > 0) {
                        const fatherAddress = student_data.parent_data.parent_address.find(address => address.parent_address_type === 'father')
                        if (fatherAddress) {
                            update_edit_student_page['parent_address_data'][0].address_line1.value = fatherAddress.address_line1;
                            update_edit_student_page['parent_address_data'][0].address_line2.value = fatherAddress.address_line2;
                            update_edit_student_page['parent_address_data'][0].parent_state.value = fatherAddress.parent_state;
                            update_edit_student_page['parent_address_data'][0].parent_city.value = fatherAddress.parent_city;
                            update_edit_student_page['parent_address_data'][0].parent_country.value = fatherAddress.parent_country;
                            update_edit_student_page['parent_address_data'][0].parent_postcode.value = fatherAddress.parent_postcode;
                            update_edit_student_page['parent_address_data'][0].parent_address_id = fatherAddress.parent_address_id;
                            update_edit_student_page['parent_address_data'][0].parent_address_type.value = fatherAddress.parent_address_type;

                            this.setState({ selectedfatherCountry: fatherAddress.parent_country });
                        }
                        const motherAddress = student_data.parent_data.parent_address.find(address => address.parent_address_type === 'mother')
                        if (motherAddress) {
                            update_edit_student_page['parent_address_data'][1].address_line1.value = motherAddress.address_line1;
                            update_edit_student_page['parent_address_data'][1].address_line2.value = motherAddress.address_line2;
                            update_edit_student_page['parent_address_data'][1].parent_state.value = motherAddress.parent_state;
                            update_edit_student_page['parent_address_data'][1].parent_city.value = motherAddress.parent_city;
                            update_edit_student_page['parent_address_data'][1].parent_country.value = motherAddress.parent_country;
                            update_edit_student_page['parent_address_data'][1].parent_postcode.value = motherAddress.parent_postcode;
                            update_edit_student_page['parent_address_data'][1].parent_address_id = motherAddress.parent_address_id;
                            update_edit_student_page['parent_address_data'][1].parent_address_type.value = motherAddress.parent_address_type;

                            this.setState({ selectedmotherCountry: motherAddress.parent_country });
                        }


                        if (student_data.parent_data.mother_name && fatherAddress && !motherAddress) {
                            update_edit_student_page['parent_address_data'][1].address_line1.value = fatherAddress.address_line1;
                            update_edit_student_page['parent_address_data'][1].address_line2.value = fatherAddress.address_line2;
                            update_edit_student_page['parent_address_data'][1].parent_state.value = fatherAddress.parent_state;
                            update_edit_student_page['parent_address_data'][1].parent_city.value = fatherAddress.parent_city;
                            update_edit_student_page['parent_address_data'][1].parent_country.value = fatherAddress.parent_country;
                            update_edit_student_page['parent_address_data'][1].parent_postcode.value = fatherAddress.parent_postcode;
                            update_edit_student_page['parent_address_data'][1].parent_address_id = fatherAddress.parent_address_id;
                            update_edit_student_page['parent_address_data'][1].parent_address_type.value = fatherAddress.parent_address_type;
                            update_address_same_as_father.value = true;
                            update_address_same_as_father.isChecked = true;
                            this.setState({
                                selectedmotherCountry: fatherAddress.parent_country,
                                address_same_as_father: update_address_same_as_father
                            });

                        }
                    }

                    if (student_data.parent_data.parents_as_host) {
                        const father = student_data.parent_data.parents_as_host.find(host => host.parent_type === 'father')
                        if (father) {
                            update_father_as_host.value = true;
                            update_father_as_host.isChecked = true;
                        }else{
                            update_father_as_host.value = false;
                            update_father_as_host.isChecked = false;
                        }
                        const mother = student_data.parent_data.parents_as_host.find(host => host.parent_type === 'mother')
                        if (mother) {
                            update_mother_as_host.value = true;
                            update_mother_as_host.isChecked = true;
                        }else{
                            update_mother_as_host.value = false;
                            update_mother_as_host.isChecked = false;
                        }
                    }
                }


                if (student_data?.stu_host_data) {
                    student_data.stu_host_data.forEach((value) => {
                        update_edit_student_page["host_data"].push({
                            host_id: {
                                type: "number",
                                value: value.host_id,
                                valid: true,
                                error_msg: "",
                                onBlur_out: false,
                                placeholder: "",
                                validations: { required: false },
                            },
                            host_name: {
                                type: "text",
                                label: "Host Name *",
                                value: value?.host_name,
                                valid: true,
                                error_msg: "",
                                onBlur_out: false,
                                placeholder: "",
                                validations: { required: true },
                            },
                            host_relation: {
                                type: "text",
                                label: "Host Relation *",
                                value: value?.stu_host?.host_relation,
                                valid: true,
                                error_msg: "",
                                onBlur_out: false,
                                placeholder: "",
                                validations: { required: true },
                            },
                            host_email: {
                                type: "email",
                                label: "Host's Email *",
                                value: value?.host_email,
                                valid: true,
                                error_msg: "",
                                onBlur_out: false,
                                placeholder: "",
                                validations: { required: true, email: true },
                            },
                            host_contact: {
                                type: "text",
                                label: "Host's Mobile Number *",
                                value: value?.host_contact,
                                valid: true,
                                error_msg: "",
                                onBlur_out: false,
                                placeholder: "",
                                validations: { required: true },
                            },
                            host_address: {
                                type: "text",
                                label: "Host's Address *",
                                value: value?.host_address,
                                valid: true,
                                error_msg: "",
                                onBlur_out: false,
                                placeholder: "",
                                validations: { required: true },
                            },
                            remark_parents: {
                                type: "text",
                                label: "Parent's remark",
                                value: value?.remark_parents,
                                valid: true,
                                error_msg: "",
                                onBlur_out: false,
                                placeholder: "",
                                validations: { required: false },
                            },
                            remark_boarding: {
                                type: "text",
                                label: "Boarding's remark",
                                value: value?.remark_boarding,
                                valid: true,
                                error_msg: "",
                                onBlur_out: false,
                                placeholder: "",
                                validations: { required: false },
                            },
                            remark_host: {
                                type: "text",
                                label: "Host's remark",
                                value: value?.remark_host,
                                valid: true,
                                error_msg: "",
                                onBlur_out: false,
                                placeholder: "",
                                validations: { required: false },
                            },
                            student_host_comment: {
                                type: "text",
                                label: "student_host_comment",
                                value: value?.stu_host?.student_host_comment === null ? " " : value?.stu_host?.student_host_comment,
                                valid: true,
                                error_msg: "",
                                onBlur_out: false,
                                placeholder: "",
                                validations: { required: false },
                            },
                            is_host_approved: {
                                type: "boolean",
                                label: "is_host_approved",
                                value: value?.stu_host.is_host_approved,
                                valid: true,
                                error_msg: "",
                                onBlur_out: false,
                                placeholder: "",
                                validations: { required: false },
                            },
                            host_status: {
                                type: "boolean",
                                label: "host_status",
                                value: value?.stu_host?.host_status,
                                valid: true,
                                error_msg: "",
                                onBlur_out: false,
                                placeholder: "",
                                validations: { required: false },
                            },
                        })
                    })
                }


                if (student_data?.stu_host_data.length === 0) {
                    student_data?.parent_data?.parents_as_host?.forEach((host) => {
                        let parentType = host?.parent_type;
                        let hostName = '';
                        let hostEmail = '';
                        let hostContact = '';
                        let hostAddress = '';
                        let parent_id = student_data.parent_id;

                        if (parentType === "father") {
                            hostName = student_data?.parent_data?.father_name;
                            hostEmail = student_data?.parent_data?.father_email;
                            hostContact = student_data?.parent_data?.father_phone;
                            hostAddress = student_data?.parent_data?.parent_address[0]?.address_line1 === undefined ? " " : student_data?.parent_data?.parent_address[0]?.address_line1 + " "
                                + student_data?.parent_data?.parent_address[0]?.address_line2 === undefined ? " " : student_data?.parent_data?.parent_address[0]?.address_line2 + " "
                                    + student_data?.parent_data?.parent_address[0]?.parent_state === undefined ? " " : student_data?.parent_data?.parent_address[0]?.parent_state + " "
                                        + student_data?.parent_data?.parent_address[0]?.parent_city === undefined ? " " : student_data?.parent_data?.parent_address[0]?.parent_city + " "
                                            + student_data?.parent_data?.parent_address[0]?.parent_country === undefined ? " " : student_data?.parent_data?.parent_address[0]?.parent_country;
                        } else if (parentType === "mother") {
                            hostName = student_data?.parent_data?.mother_name;
                            hostEmail = student_data?.parent_data?.mother_email;
                            hostContact = student_data?.parent_data?.mother_phone;
                            hostAddress = student_data?.parent_data?.parent_address[1]?.address_line1 === undefined ? " " : student_data?.parent_data?.parent_address[1]?.address_line1 + " "
                                + student_data?.parent_data?.parent_address[1]?.address_line2 === undefined ? " " : student_data?.parent_data?.parent_address[1]?.address_line2 + " "
                                    + student_data?.parent_data?.parent_address[1]?.parent_state === undefined ? " " : student_data?.parent_data?.parent_address[1]?.parent_state + " "
                                        + student_data?.parent_data?.parent_address[1]?.parent_city === undefined ? " " : student_data?.parent_data?.parent_address[1]?.parent_city + " "
                                            + student_data?.parent_data?.parent_address[1]?.parent_country === undefined ? " " : student_data?.parent_data?.parent_address[1]?.parent_country;
                        }

                        update_edit_student_page["host_data"]?.push({
                            parent_id: {
                                type: "number",
                                value: parent_id,
                                valid: true,
                                error_msg: "",
                                onBlur_out: false,
                                placeholder: "",
                                validations: { required: false },
                            },
                            host_name: {
                                type: "text",
                                label: "Host Name *",
                                value: hostName,
                                valid: true,
                                error_msg: "",
                                onBlur_out: false,
                                placeholder: "",
                                validations: { required: true },
                            },
                            host_relation: {
                                type: "text",
                                label: "Host Relation *",
                                value: parentType,
                                valid: true,
                                error_msg: "",
                                onBlur_out: false,
                                placeholder: "",
                                validations: { required: true },
                            },
                            host_email: {
                                type: "email",
                                label: "Host's Email *",
                                value: hostEmail,
                                valid: true,
                                error_msg: "",
                                onBlur_out: false,
                                placeholder: "",
                                validations: { required: true, email: true },
                            },
                            host_contact: {
                                type: "text",
                                label: "Host's Mobile Number *",
                                value: hostContact,
                                valid: true,
                                error_msg: "",
                                onBlur_out: false,
                                placeholder: "",
                                validations: { required: true },
                            },
                            host_address: {
                                type: "text",
                                label: "Host's Address *",
                                value: hostAddress,
                                valid: true,
                                error_msg: "",
                                onBlur_out: false,
                                placeholder: "",
                                validations: { required: true },
                            },
                            remark_parents: {
                                type: "text",
                                label: "Parent's remark",
                                value: "",
                                valid: true,
                                error_msg: "",
                                onBlur_out: false,
                                placeholder: "",
                                validations: { required: false },
                            },
                            remark_boarding: {
                                type: "text",
                                label: "Boarding's remark",
                                value: "",
                                valid: true,
                                error_msg: "",
                                onBlur_out: false,
                                placeholder: "",
                                validations: { required: false },
                            },
                            remark_host: {
                                type: "text",
                                label: "Host's remark",
                                value: "",
                                valid: true,
                                error_msg: "",
                                onBlur_out: false,
                                placeholder: "",
                                validations: { required: false },
                            },
                            student_host_comment: {
                                type: "text",
                                label: "student_host_comment",
                                value: host.student_host_comment === null ? " " : host.student_host_comment,
                                valid: true,
                                error_msg: "",
                                onBlur_out: false,
                                placeholder: "",
                                validations: { required: false },
                            },
                            is_host_approved: {
                                type: "boolean",
                                label: "is_host_approved",
                                value: host.is_host_approved,
                                valid: true,
                                error_msg: "",
                                onBlur_out: false,
                                placeholder: "",
                                validations: { required: false },
                            },
                            host_status: {
                                type: "boolean",
                                label: "host_status",
                                value: true,
                                valid: true,
                                error_msg: "",
                                onBlur_out: false,
                                placeholder: "",
                                validations: { required: false },
                            },

                        });
                    })

                } else if (student_data?.stu_host_data.length > 0) {
                    let parent_id = student_data.parent_id;
                    let fatherHost = student_data.stu_host_data.find(host => host.stu_host.host_relation === "father")
                    const fatherAddress = student_data.parent_data.parent_address.find(address => address.parent_address_type === 'father')
                    if (!fatherHost) {
                        let father = student_data?.parent_data?.parents_as_host.find(item => item.parent_type === "father")
                        if (father) {
                            update_edit_student_page["host_data"]?.push({
                                parent_id: {
                                    type: "number",
                                    value: parent_id,
                                    valid: true,
                                    error_msg: "",
                                    onBlur_out: false,
                                    placeholder: "",
                                    validations: { required: false },
                                },
                                host_name: {
                                    type: "text",
                                    label: "Host Name *",
                                    value: student_data?.parent_data?.father_name,
                                    valid: true,
                                    error_msg: "",
                                    onBlur_out: false,
                                    placeholder: "",
                                    validations: { required: true },
                                },
                                host_relation: {
                                    type: "text",
                                    label: "Host Relation *",
                                    value: father.parent_type,
                                    valid: true,
                                    error_msg: "",
                                    onBlur_out: false,
                                    placeholder: "",
                                    validations: { required: true },
                                },
                                host_email: {
                                    type: "email",
                                    label: "Host's Email *",
                                    value: student_data?.parent_data?.father_email,
                                    valid: true,
                                    error_msg: "",
                                    onBlur_out: false,
                                    placeholder: "",
                                    validations: { required: true, email: true },
                                },
                                host_contact: {
                                    type: "text",
                                    label: "Host's Mobile Number *",
                                    value: student_data?.parent_data?.father_phone,
                                    valid: true,
                                    error_msg: "",
                                    onBlur_out: false,
                                    placeholder: "",
                                    validations: { required: true },
                                },
                                host_address: {
                                    type: "text",
                                    label: "Host's Address *",
                                    value: fatherAddress?.address_line1 + " "
                                        + fatherAddress?.address_line2 + " "
                                        + fatherAddress?.address_line3 + " "
                                        + fatherAddress?.address_line4 + " "
                                        + fatherAddress?.parent_country,
                                    valid: true,
                                    error_msg: "",
                                    onBlur_out: false,
                                    placeholder: "",
                                    validations: { required: true },
                                },
                                student_host_comment: {
                                    type: "text",
                                    label: "student_host_comment",
                                    value: father.student_host_comment === null ? " " : father.student_host_comment,
                                    valid: true,
                                    error_msg: "",
                                    onBlur_out: false,
                                    placeholder: "",
                                    validations: { required: false },
                                },
                                is_host_approved: {
                                    type: "boolean",
                                    label: "is_host_approved",
                                    value: father.is_host_approved,
                                    valid: true,
                                    error_msg: "",
                                    onBlur_out: false,
                                    placeholder: "",
                                    validations: { required: false },
                                },
                                remark_parents: {
                                    type: "text",
                                    label: "Parent's remark",
                                    value: "",
                                    valid: true,
                                    error_msg: "",
                                    onBlur_out: false,
                                    placeholder: "",
                                    validations: { required: false },
                                },
                                remark_boarding: {
                                    type: "text",
                                    label: "Boarding's remark",
                                    value: "",
                                    valid: true,
                                    error_msg: "",
                                    onBlur_out: false,
                                    placeholder: "",
                                    validations: { required: false },
                                },
                                remark_host: {
                                    type: "text",
                                    label: "Host's remark",
                                    value: "",
                                    valid: true,
                                    error_msg: "",
                                    onBlur_out: false,
                                    placeholder: "",
                                    validations: { required: false },
                                },
                                host_status: {
                                    type: "boolean",
                                    label: "host_status",
                                    value: true,
                                    valid: true,
                                    error_msg: "",
                                    onBlur_out: false,
                                    placeholder: "",
                                    validations: { required: false },
                                },


                            })
                        }
                    }

                    let motherHost = student_data.stu_host_data.find(host => host.stu_host.host_relation === "mother")

                    if (!motherHost) {
                        let mother = student_data?.parent_data?.parents_as_host.find(item => item.parent_type === "mother")
                        const motherAddress = student_data.parent_data.parent_address.find(address => address.parent_address_type === 'mother')
                        if (mother) {
                            if (motherAddress) {
                                update_edit_student_page["host_data"]?.push({
                                    parent_id: {
                                        type: "number",
                                        value: parent_id,
                                        valid: true,
                                        error_msg: "",
                                        onBlur_out: false,
                                        placeholder: "",
                                        validations: { required: false },
                                    },
                                    host_name: {
                                        type: "text",
                                        label: "Host Name *",
                                        value: student_data?.parent_data?.mother_name,
                                        valid: true,
                                        error_msg: "",
                                        onBlur_out: false,
                                        placeholder: "",
                                        validations: { required: true },
                                    },
                                    host_relation: {
                                        type: "text",
                                        label: "Host Relation *",
                                        value: mother.parent_type,
                                        valid: true,
                                        error_msg: "",
                                        onBlur_out: false,
                                        placeholder: "",
                                        validations: { required: true },
                                    },
                                    host_email: {
                                        type: "email",
                                        label: "Host's Email *",
                                        value: student_data?.parent_data?.mother_email,
                                        valid: true,
                                        error_msg: "",
                                        onBlur_out: false,
                                        placeholder: "",
                                        validations: { required: true, email: true },
                                    },
                                    host_contact: {
                                        type: "text",
                                        label: "Host's Mobile Number *",
                                        value: student_data?.parent_data?.mother_phone,
                                        valid: true,
                                        error_msg: "",
                                        onBlur_out: false,
                                        placeholder: "",
                                        validations: { required: true },
                                    },
                                    host_address: {
                                        type: "text",
                                        label: "Host's Address *",
                                        value: motherAddress?.address_line1 + " "
                                            + motherAddress?.address_line2 + " "
                                            + motherAddress?.address_line3 + " "
                                            + motherAddress?.address_line4 + " "
                                            + motherAddress?.parent_country,
                                        valid: true,
                                        error_msg: "",
                                        onBlur_out: false,
                                        placeholder: "",
                                        validations: { required: true },
                                    },
                                    student_host_comment: {
                                        type: "text",
                                        label: "student_host_comment",
                                        value: mother.student_host_comment === null ? " " : mother.student_host_comment,
                                        valid: true,
                                        error_msg: "",
                                        onBlur_out: false,
                                        placeholder: "",
                                        validations: { required: false },
                                    },
                                    is_host_approved: {
                                        type: "boolean",
                                        label: "is_host_approved",
                                        value: mother.is_host_approved,
                                        valid: true,
                                        error_msg: "",
                                        onBlur_out: false,
                                        placeholder: "",
                                        validations: { required: false },
                                    },
                                    remark_parents: {
                                        type: "text",
                                        label: "Parent's remark",
                                        value: "",
                                        valid: true,
                                        error_msg: "",
                                        onBlur_out: false,
                                        placeholder: "",
                                        validations: { required: false },
                                    },
                                    remark_boarding: {
                                        type: "text",
                                        label: "Boarding's remark",
                                        value: "",
                                        valid: true,
                                        error_msg: "",
                                        onBlur_out: false,
                                        placeholder: "",
                                        validations: { required: false },
                                    },
                                    remark_host: {
                                        type: "text",
                                        label: "Host's remark",
                                        value: "",
                                        valid: true,
                                        error_msg: "",
                                        onBlur_out: false,
                                        placeholder: "",
                                        validations: { required: false },
                                    },
                                    host_status: {
                                        type: "boolean",
                                        label: "host_status",
                                        value: true,
                                        valid: true,
                                        error_msg: "",
                                        onBlur_out: false,
                                        placeholder: "",
                                        validations: { required: false },
                                    },

                                })
                            }


                            if (!motherAddress) {
                                update_edit_student_page["host_data"]?.push({
                                    parent_id: {
                                        type: "number",
                                        value: parent_id,
                                        valid: true,
                                        error_msg: "",
                                        onBlur_out: false,
                                        placeholder: "",
                                        validations: { required: false },
                                    },
                                    host_name: {
                                        type: "text",
                                        label: "Host Name *",
                                        value: student_data?.parent_data?.mother_name,
                                        valid: true,
                                        error_msg: "",
                                        onBlur_out: false,
                                        placeholder: "",
                                        validations: { required: true },
                                    },
                                    host_relation: {
                                        type: "text",
                                        label: "Host Relation *",
                                        value: mother.parent_type,
                                        valid: true,
                                        error_msg: "",
                                        onBlur_out: false,
                                        placeholder: "",
                                        validations: { required: true },
                                    },
                                    host_email: {
                                        type: "email",
                                        label: "Host's Email *",
                                        value: student_data?.parent_data?.mother_email,
                                        valid: true,
                                        error_msg: "",
                                        onBlur_out: false,
                                        placeholder: "",
                                        validations: { required: true, email: true },
                                    },
                                    host_contact: {
                                        type: "text",
                                        label: "Host's Mobile Number *",
                                        value: student_data?.parent_data?.mother_phone,
                                        valid: true,
                                        error_msg: "",
                                        onBlur_out: false,
                                        placeholder: "",
                                        validations: { required: true },
                                    },
                                    host_address: {
                                        type: "text",
                                        label: "Host's Address *",
                                        value: fatherAddress?.address_line1 + " "
                                            + fatherAddress?.address_line2 + " "
                                            + fatherAddress?.address_line3 + " "
                                            + fatherAddress?.address_line4 + " "
                                            + fatherAddress?.parent_country,
                                        valid: true,
                                        error_msg: "",
                                        onBlur_out: false,
                                        placeholder: "",
                                        validations: { required: true },
                                    },
                                    student_host_comment: {
                                        type: "text",
                                        label: "student_host_comment",
                                        value: mother.student_host_comment === null ? " " : mother.student_host_comment,
                                        valid: true,
                                        error_msg: "",
                                        onBlur_out: false,
                                        placeholder: "",
                                        validations: { required: false },
                                    },
                                    is_host_approved: {
                                        type: "boolean",
                                        label: "is_host_approved",
                                        value: mother.is_host_approved,
                                        valid: true,
                                        error_msg: "",
                                        onBlur_out: false,
                                        placeholder: "",
                                        validations: { required: false },
                                    },
                                    remark_parents: {
                                        type: "text",
                                        label: "Parent's remark",
                                        value: "",
                                        valid: true,
                                        error_msg: "",
                                        onBlur_out: false,
                                        placeholder: "",
                                        validations: { required: false },
                                    },
                                    remark_boarding: {
                                        type: "text",
                                        label: "Boarding's remark",
                                        value: "",
                                        valid: true,
                                        error_msg: "",
                                        onBlur_out: false,
                                        placeholder: "",
                                        validations: { required: false },
                                    },
                                    remark_host: {
                                        type: "text",
                                        label: "Host's remark",
                                        value: "",
                                        valid: true,
                                        error_msg: "",
                                        onBlur_out: false,
                                        placeholder: "",
                                        validations: { required: false },
                                    },
                                    host_status: {
                                        type: "boolean",
                                        label: "host_status",
                                        value: true,
                                        valid: true,
                                        error_msg: "",
                                        onBlur_out: false,
                                        placeholder: "",
                                        validations: { required: false },
                                    },

                                })
                            }

                        }
                    }

                }
                this.setState({ edit_student_page: update_edit_student_page });
                this.setState({ father_as_host: update_father_as_host })
                this.setState({ mother_as_host: update_mother_as_host })
                this.GetAllSelectedCountriesForFather()
                this.GetAllSelectedCountriesForMother()
            } else {
            }
        });
    }


    handleChange(data, identifier, type) {
        const update_edit_student_page = { ...this.state.edit_student_page };
        if (identifier === 'dormitory_id') {
            update_edit_student_page['dormitory_id_name'].value = data.label;
        }
        if (identifier === 'class_id') {
            update_edit_student_page['class_name'].value = data.label;
        }
        update_edit_student_page[identifier].value = data.value;
        this.setState({ edit_student_page: update_edit_student_page });
    }



    inputChangeHandlerCheckbox(event, identifier) {
        const update_edit_student_page = { ...this.state.edit_student_page };
        const form_element = { ...update_edit_student_page[identifier] };
        if (form_element.type === "checkbox") {
            form_element.value = event.target.checked;
            form_element.isChecked = event.target.checked;
        }
        else {
            form_element.value = event.target.value;
        }

        if (identifier === 'show_parent_detail_app') {
            if (event.target.checked === true) {
                form_element.value = event.target.checked;
                form_element.isChecked = true;
            }
            else {
                form_element.value = event.target.checked;
                form_element.isChecked = '';
            }
        }
        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;
        update_edit_student_page[identifier] = form_element;
        this.setState({ edit_student_page: update_edit_student_page });
    }

    inputParentCheckbox(event, identifier) {
        const update_edit_student_page = this.state.edit_student_page
        const update_parent_address_data = update_edit_student_page.parent_address_data
        const update_same_as_father = { ...this.state.address_same_as_father };
        console.log("update_same_as_father", event.target,identifier,event.target.checked)
        const update_father_as_host = { ...this.state.father_as_host }
        const update_mother_as_host = { ...this.state.mother_as_host }

        if (identifier === "father_as_host") {
            update_father_as_host.value = !event.target.checked;
            update_father_as_host.isChecked = !event.target.checked;

            this.setState({ father_as_host: update_father_as_host });
        }
        if (identifier === "mother_as_host") {
            update_mother_as_host.value = !event.target.checked;
            update_mother_as_host.isChecked = !event.target.checked;

            this.setState({ mother_as_host: update_mother_as_host });
        }

        if (identifier === "address_same_as_father") {
            update_same_as_father.value = event.target.checked;
            update_same_as_father.isChecked = event.target.checked;
            this.setState({ address_same_as_father: update_same_as_father });
            const form_data = {
                parent_address_uuid: this.state.edit_student_page.parent_uuid.value,
                parent_address_type: "mother"
            }
            if (event.target.checked === true) {
                update_parent_address_data[1].address_line1.value = update_parent_address_data[0].address_line1.value;
                update_parent_address_data[1].address_line2.value = update_parent_address_data[0].address_line2.value;
                update_parent_address_data[1].parent_state.value = update_parent_address_data[0].parent_state.value;
                update_parent_address_data[1].parent_city.value = update_parent_address_data[0].parent_city.value;
                update_parent_address_data[1].parent_address_type.value = update_parent_address_data[0].parent_address_type.value;
                update_parent_address_data[1].parent_country.value = update_parent_address_data[0].parent_country.value;
                update_parent_address_data[1].parent_address_type.value = update_parent_address_data[0].parent_address_type.value;
                update_parent_address_data[1].parent_postcode.value = update_parent_address_data[0].parent_postcode.value;
                update_parent_address_data[1].parent_address_id = update_parent_address_data[0].parent_address_id;
                update_edit_student_page.parent_address_data = update_parent_address_data;
                this.setState({ edit_student_page: update_edit_student_page })
                this.setState({
                    selectedmotherCountry: update_parent_address_data[0].parent_country.value,
                });

            } else {


            }
            if (event.target.checked === true) {
                this.props.onRemoveParentAddress(form_data).then((response) => {
                    if (response.success === true) {

                        console.log("event.target.checked === true", response)
                        // toast.success(response.message, {
                        //     position: toast.POSITION.TOP_CENTER,
                        // });
                    } else {
                        toast.error(response.message, {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                })
            }
        }

        // update_checkbox.value = event.target.checked;
        // update_checkbox.isChecked = event.target.checked;

        // this.setState({ address_same_as_father: update_checkbox });
    }


    // fahterDetailsRequire(father_name){
    //     if(father_name !== '') {
    //         const update_edit_student_page = { ...this.state.edit_student_page };

    //         console.log("fahterDetailsRequire", [ ...this.state.parent_address_data])
    //         // const update_parent_address_data = [ ...this.state.parent_address_data];
    //         // console.log("fahterDetailsRequire", update_parent_address_data)

    //         update_edit_student_page.father_email.validations.required = true
    //         update_edit_student_page.father_phone.validations.required = true
    //         update_edit_student_page.father_name.validations.required = true

    //         update_edit_student_page.parent_address_data[0].address_line1.validations.required = true
    //         update_edit_student_page.parent_address_data[0].address_line2.validations.required = true
    //         update_edit_student_page.parent_address_data[0].parent_postcode.validations.required = true
    //         // update_parent_address_data[0].parent_country.validations.required = true

    //         this.setState({father_details: true})
    //         // this.setState({parent_address_data: update_parent_address_data})

    //         this.setState({add_student_form: update_edit_student_page})
    //     }
    // }
    // mohterDetailsRequire(mother_name){
    //     if(mother_name !== '') {
    //         const update_edit_student_page = { ...this.state.edit_student_page };
    //         console.log("mohterDetailsRequire", [ ...this.state.parent_address_data])
    //         // const update_parent_address_data = [ ...this.state.parent_address_data];


    //         update_edit_student_page.mother_email.validations.required = true
    //         update_edit_student_page.mother_phone.validations.required = true
    //         update_edit_student_page.mother_name.validations.required = true

    //         update_edit_student_page.parent_address_data[1].address_line1.validations.required = true
    //         update_edit_student_page.parent_address_data[1].address_line2.validations.required = true
    //         update_edit_student_page.parent_address_data[1].parent_postcode.validations.required = true
    //         // update_parent_address_data[1].parent_country.validations.required = true

    //         this.setState({mother_details: true})
    //         // this.setState({parent_address_data: update_parent_address_data})

    //         this.setState({add_student_form: update_edit_student_page})
    //     }
    // }

    ///////////////////////////////////////////// parent address select function ///////////////////////////

    handleChangeCountry(data, index, identifier) {
        console.log("identifier================", data, index, identifier);

        const update_edit_student_page = { ...this.state.edit_student_page };
        const update_parent_address_data = [...update_edit_student_page.parent_address_data];
        if (!update_parent_address_data[index]) {
            update_parent_address_data[index] = {};
        }
        const update_parents_data = { ...update_parent_address_data[index] }
        if (!update_parents_data[identifier]) {
            update_parents_data[identifier] = {}
        }
        if (identifier === "parent_country") {
            if (index === 0) {
                this.GetAllStatesByCountryIdForFather(data.value)
            } else {
                this.GetAllStatesByCountryIdForMother(data.value)
            }
            update_parents_data[identifier].value = data.label;
        } else if (identifier === "parent_state") {
            if (index === 0) {
                this.GetAllCitiesByStateIdForFather(data.value)
            } else {
                this.GetAllCitiesByStateIdForMother(data.value)
            }
            update_parents_data[identifier].value = data.label;
        } else {
            update_parents_data[identifier].value = data.label;
        }


        if (index === 0) {
            this.setState({ selectedfatherCountry: data.label });
        }
        if (index === 1) {
            this.setState({ selectedmotherCountry: data.label });
        }
        this.setState({ parent_address_data: update_parent_address_data });
    }


    ///////////////////////////////////////////// parent address input function ///////////////////////////


    inputChangeCountry(event, index, identifier) {
        const update_edit_student_page = { ...this.state.edit_student_page };
        const update_parent_address_data = [...update_edit_student_page.parent_address_data];
        if (!update_parent_address_data[index]) {
            update_parent_address_data[index] = {};
        }
        const update_parents_data = { ...update_parent_address_data[index] }
        if (!update_parents_data[identifier]) {
            update_parents_data[identifier] = {}
        }
        update_parents_data[identifier].value = event.target.value;
        update_parent_address_data[index] = update_parents_data
        this.setState({ parent_address_data: update_parent_address_data });
    }

    validatePinLength(event) {
        const { value, key } = event.target;
        if (!/^\d*$/.test(value) && key !== 'Backspace') {
            event.preventDefault();
            event.target.value = '';
        }
        if (value.length >= 6 && event.key !== 'Backspace') {
            event.preventDefault();
        }
    }

    cam_not_available = (value) => {
        this.setState({ cam_Start: value })
        toast.info("camera not found", {
            position: toast.POSITION.TOP_CENTER,
        });
    }

    take_photo_by_props = (blob, identifier) => {
        if (identifier === "student_avatar") {
            let url = URL.createObjectURL(blob);
            let file = new File([blob], "image.png", { lastModified: new Date().getTime(), type: blob.type })
            const update_edit_student_page = { ...this.state.edit_student_page };
            const form_element = { ...update_edit_student_page[identifier] };
            form_element.thumbnail_image = url;
            form_element.value = file;
            update_edit_student_page[identifier] = form_element;
            this.setState({ edit_student_page: update_edit_student_page });
            this.setState({ cam_Start: null })
        } else {
            this.setState({ cam_Start: null })
        }
    }

    inputChangeHandlerimage = blob => {
        const src = window.URL.createObjectURL(blob);
        let url = URL.createObjectURL(blob);
        let file = new File([blob], "image.png", { lastModified: new Date().getTime(), type: blob.type })
        const update_edit_student_page = { ...this.state.edit_student_page };
        const form_element = { ...update_edit_student_page["student_avatar"] };
        form_element.thumbnail_image = url;
        form_element.value = file;
        update_edit_student_page["student_avatar"] = form_element;
        this.setState({ edit_student_page: update_edit_student_page });
        this.setState({ OpenImageModal: !this.state.OpenImageModal })
    };

    inputChangeHandler(event, identifier) {
        const update_edit_student_page = { ...this.state.edit_student_page };
        const form_element = { ...update_edit_student_page[identifier] };
        if(identifier === 'preferred_name' && this.state.initial_name != event.target.value){
            this.state.edit_student_page.is_preffered_name_update.value = true;
        }else if(identifier === 'preferred_name' && this.state.initial_name === event.target.value){
            this.state.edit_student_page.is_preffered_name_update.value = false;
        }
        if (identifier === 'student_phone') {
            form_element.value = event;
        } else if (identifier === 'father_phone') {
            form_element.value = event;
        } else if (identifier === 'mother_phone') {
            form_element.value = event;
        } else if (identifier === 'father_home_phone') {
            form_element.value = event;
        } else if (identifier === 'father_work_phone') {
            form_element.value = event;
        } else if (identifier === 'mother_home_phone') {
            form_element.value = event;
        } else if (identifier === 'mother_work_phone') {
            form_element.value = event;
        } else {
            form_element.value = event.target.value;
        }
        const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
        update_edit_student_page['school_detail_id'].value = user_info.user_school.school_detail_id;
        update_edit_student_page['school_name'].value = user_info.user_school.school_name;

        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_edit_student_page[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_edit_student_page) {
            is_form_valid = update_edit_student_page[identifier].valid && is_form_valid;
        }
        this.setState({
            edit_student_page: update_edit_student_page,
            is_form_valid: is_form_valid,
        });
    }


    ////////////////////////////////////////// Allergy functionality ///////////////////////////

    inputChangeAllergyName(event, i,) {
        const update_edit_student_page = { ...this.state.edit_student_page }
        let allergy_array = update_edit_student_page.allergy_data;
        allergy_array[i]["allergy_name"].value = event.target.value
        update_edit_student_page.allergy_data = allergy_array;
        this.setState({ edit_student_page: update_edit_student_page })
    }

    inputChangeAllergyInfo(event, i) {
        const update_edit_student_page = { ...this.state.edit_student_page }
        let allergy_array = update_edit_student_page.allergy_data;
        allergy_array[i]["allergy_info"].value = event.target.value
        update_edit_student_page.allergy_data = allergy_array;
        this.setState({ edit_student_page: update_edit_student_page })
    }

    clickAddAllergyrow() {
        const update_row = this.state.edit_student_page.allergy_data;
        let counter = update_row.length
        update_row.push(
            {
                allergy_name: {
                    type: "text",
                    label: "Allergy Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                allergy_info: {
                    type: "text",
                    label: "Allergy Info *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                id: {
                    type: "text",
                    label: "id",
                    value: counter++,
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
            }
        )
        this.setState({ allergy_data: update_row })
    }


    clickDeleteAllergyrow = (index, e) => {
        const update_edit_student_page = { ...this.state.edit_student_page };
        const deleteAllergyData = update_edit_student_page.allergy_data;
        if (index['id'] !== undefined) {
            let edit_row = deleteAllergyData.filter((item, i) => i !== e);
            update_edit_student_page['allergy_data'] = edit_row;
            this.setState({ edit_student_page: update_edit_student_page });
        } else {
            const payload = { student_allergy_uuid: index.student_allergy_uuid.value }
            this.props.onDeleteStudentAllergyById(payload).then((response) => {
                if (response.success === true) {
                    this.GetStudentAndParentById();
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            });
            let edit_row = deleteAllergyData.filter((item, i) => i !== e);
            update_edit_student_page['allergy_data'] = edit_row;
            this.setState({ edit_student_page: update_edit_student_page });
        }
    };

    ///////////////////////////////////// host functionality /////////////////////////

    GetStudentHostByHostEmail = async (host_email, index) => {
        this.props.onGetStudentHostByHostEmail(host_email).then((response) => {
            const update_edit_student_page = this.state.edit_student_page;
            const update_host_data = update_edit_student_page.host_data
            let setHostdetail = update_host_data[index];
            if (response.success === true) {
                if (response.data === null) {
                } else {
                    let host_data = response.data;
                    setHostdetail['host_name'].value = host_data.host_name;
                    setHostdetail['host_email'].value = host_data.host_email;
                    setHostdetail['host_relation'].value = host_data.stu_host.host_relation;
                    setHostdetail['host_contact'].value = host_data.host_contact;
                    setHostdetail['host_address'].value = host_data.host_address;
                    setHostdetail['remark_boarding'].value = host_data.remark_boarding;
                    setHostdetail['remark_host'].value = host_data.remark_host;
                    setHostdetail['remark_parents'].value = host_data.remark_parents;
                }
            } else {
            }
            update_host_data[index] = setHostdetail;
            update_edit_student_page.host_data = update_host_data
            this.setState({ edit_student_page: update_edit_student_page })
        })
    }



    clickAnotherHost() {
        const update_row = this.state.edit_student_page.host_data;
        let counter = update_row.length
        update_row.push(
            {
                id: {
                    type: "number",
                    value: counter++,
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                host_id: {
                    type: "text",
                    value: "new_host",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                host_name: {
                    type: "text",
                    label: "Host Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Host Name",
                    validations: { required: true },
                },
                host_relation: {
                    type: "text",
                    label: "Host Relation *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Host Relation",
                    validations: { required: true },
                },
                host_email: {
                    type: "email",
                    label: "Host's Email *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Host's Email",
                    validations: { required: true, email: true },
                },
                host_contact: {
                    type: "text",
                    label: "Host's Mobile Number *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Host's Mobile Number",
                    validations: { required: true },
                },
                host_address: {
                    type: "text",
                    label: "Host's Address *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Host's Address",
                    validations: { required: true },
                },
                remark_parents: {
                    type: "text",
                    label: "Parent's remark",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Parent's remark",
                    validations: { required: false },
                },
                remark_boarding: {
                    type: "text",
                    label: "Boarding's remark",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Boarding's remark",
                    validations: { required: false },
                },
                remark_host: {
                    type: "text",
                    label: "Host's remark",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Host's remark",
                    validations: { required: false },
                },
                student_host_comment: {
                    type: "text",
                    label: "Student Host Comment",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Student Host Comment",
                    validations: { required: false },
                },
                is_host_approved: {
                    type: "text",
                    label: "is_host_approved",
                    value: true,
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                host_status: {
                    type: "text",
                    label: "host_status",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
            }
        )
        this.setState({ stu_host_data: update_row })
    }




    inputChangeHost(event, index, identifier) {
        const update_edit_student_page = this.state.edit_student_page;
        const update_host_data = update_edit_student_page.host_data
        let setHostdetail = update_host_data[index];
        if (identifier === "host_name") {
            setHostdetail[identifier].value = event.target.value;
            update_host_data[index] = setHostdetail;
            this.setState({ edit_student_page: update_edit_student_page })
        }
        else if (identifier === "host_relation") {
            setHostdetail[identifier].value = event.target.value;
            update_host_data[index] = setHostdetail;
            this.setState({ edit_student_page: update_edit_student_page })
        }
        else if (identifier === "host_email") {
            this.GetStudentHostByHostEmail(event.target.value, index);
            setHostdetail[identifier].value = event.target.value;
            update_host_data[index] = setHostdetail;
            this.setState({ edit_student_page: update_edit_student_page })
        }
        else if (identifier === "host_address") {
            setHostdetail[identifier].value = event.target.value
            update_host_data[index] = setHostdetail;
            this.setState({ edit_student_page: update_edit_student_page })
        }
        else if (identifier === "remark_parents") {
            setHostdetail[identifier].value = event.target.value
            update_host_data[index] = setHostdetail;
            this.setState({ edit_student_page: update_edit_student_page })
        }
        else if (identifier === "remark_boarding") {
            setHostdetail[identifier].value = event.target.value
            update_host_data[index] = setHostdetail;
            this.setState({ edit_student_page: update_edit_student_page })
        }
        else if (identifier === "remark_host") {
            setHostdetail[identifier].value = event.target.value
            update_host_data[index] = setHostdetail;
            this.setState({ edit_student_page: update_edit_student_page })

        } else if (identifier === "student_host_comment") {
            setHostdetail[identifier].value = event.target.value
            update_host_data[index] = setHostdetail;
            this.setState({ edit_student_page: update_edit_student_page })

        } else {
            update_host_data[index] = setHostdetail;
            this.setState({ edit_student_page: update_edit_student_page })
        }

    }

    inputChangehostCheckbox(event, index, identifier) {
        const update_edit_student_page = this.state.edit_student_page;
        const update_host_data = update_edit_student_page.host_data
        let setHostdetail = update_host_data[index];
        if (identifier === "checkbox") {
            if (event.target.checked === true) {
                setHostdetail.is_host_approved.value = event.target.checked;
                update_host_data[index] = setHostdetail;
                this.setState({ edit_student_page: update_edit_student_page })
            } else {
                setHostdetail.is_host_approved.value = event.target.checked;
                update_host_data[index] = setHostdetail;
                this.setState({ edit_student_page: update_edit_student_page })
            }
        }
    }

    inputChangeHostContect(event, index) {
        const update_edit_student_page = this.state.edit_student_page;
        const update_host_data = update_edit_student_page.host_data
        let setHostdetail = update_host_data[index];
        setHostdetail['host_contact'].value = event;
        update_host_data[index] = setHostdetail;
        this.setState({ edit_student_page: update_edit_student_page })
    }

    ///////////////////////////////////////////// student submit handler ////////////////////////////////////////

    SubmitHandler = async (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_edit_student_page = this.state.edit_student_page;
        const { student_first_name, preferred_name, student_email, class_name, dormitory_id, date_of_birth, student_age, student_phone, gender, class_id, first_point_contact, first_point_email, display_image_required } = update_edit_student_page;
        const { allergy_data } = update_edit_student_page;
        const err_mess_obj = { student_first_name, preferred_name, student_email, class_name, dormitory_id, date_of_birth, student_age, student_phone, gender, class_id, first_point_contact, first_point_email, display_image_required }
        for (let key in err_mess_obj) {
            let form_element = update_edit_student_page[key];
            let validate_element = Validations(form_element);
            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;
            update_edit_student_page[key] = form_element;
        }
        for (let ind in allergy_data) {
            for (let key in allergy_data[ind]) {
                let form_element = allergy_data[ind][key];
                let validate_element = Validations(form_element);
                is_form_valid = validate_element.valid === false ? false : is_form_valid;
                form_element.onBlur_out = true;
                form_element.valid = validate_element.valid;
                form_element.error_msg = validate_element.error_msg;
                allergy_data[ind][key] = form_element;
            }
        }
        this.setState({ edit_student_page: update_edit_student_page });
        if (is_form_valid) {
            const form_data = {};
            for (let key in update_edit_student_page) {
                form_data[key] = update_edit_student_page[key].value;
            }
            let oldArray = update_edit_student_page.allergy_data;
            let update_allergy_data = []
            oldArray.forEach((item, index) => {
                if (!item['id']) {
                    update_allergy_data.push({
                        allergy_name: item.allergy_name.value,
                        allergy_info: item.allergy_info.value,
                        student_allergy_id: !item['student_allergy_id'] ? "" : item.student_allergy_id.value,
                        student_allergy_uuid: !item['student_allergy_uuid'] ? "" : item.student_allergy_uuid.value
                    })
                } else {
                    update_allergy_data.push({
                        allergy_name: item.allergy_name.value,
                        allergy_info: item.allergy_info.value,
                    })
                }
            })
            form_data['allergy_data'] = JSON.stringify(update_allergy_data);

            form_data['parent_id'] = this.state.upDate_parent_id;
            if (this.state.father_as_host.value === true && this.state.mother_as_host.value === false) {
                let parent_as_host = [{
                    parent_id : this.state.upDate_parent_id,
                    student_id : this.state.edit_student_page.student_id.value,
                    parent_type: "father",
                    is_host_approved: true,
                    host_relation: "father as host"
                }]
                form_data['parent_as_host'] = JSON.stringify(parent_as_host);
            } else if (this.state.father_as_host.value === false && this.state.mother_as_host.value === true) {
                let parent_as_host = [{
                    parent_id : this.state.upDate_parent_id,
                    student_id : this.state.edit_student_page.student_id.value,
                    parent_type: "mother",
                    is_host_approved: true,
                    host_relation: "mother as host"
                }]
                form_data['parent_as_host'] = JSON.stringify(parent_as_host);
            } else if (this.state.father_as_host.value === true && this.state.mother_as_host.value === true) {
                let parent_as_host = [{
                    parent_id : this.state.upDate_parent_id,
                    student_id : this.state.edit_student_page.student_id.value,
                    parent_type: "mother",
                    is_host_approved: true,
                    host_relation: "mother as host"
                }, {
                    parent_id : this.state.upDate_parent_id,
                    student_id : this.state.edit_student_page.student_id.value,
                    parent_type: "father",
                    is_host_approved: true,
                    host_relation: "father as host"
                },]
                form_data['parent_as_host'] = JSON.stringify(parent_as_host);
            }else if (this.state.father_as_host.value === false && this.state.mother_as_host.value === false){
                let parent_as_host = []
                form_data['parent_as_host'] = parent_as_host;
            } 
            console.log("ffffffffffffffffffffffffffffffffffff",form_data);
            
            this.props.onUpdateStudentPersonal(form_data).then((response) => {
                if (response.success === true) {
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: `/${school_code}/student/student-list`,
                        });
                    }, 500);
                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            });
        }
    };

    ///////////////////////////////////////////////////// parent submit handler /////////////////////////////

    SubmitHandlerParent = async (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_edit_student_page = this.state.edit_student_page;
        const { father_name, mother_name, father_email, mother_email, father_phone, mother_phone, parent_address_data } = update_edit_student_page;
        console.log("parent_address_data",parent_address_data);
        const err_mess_obj = { father_name, mother_name, father_email, mother_email, father_phone, mother_phone }
        for (let key in err_mess_obj) {
            let form_element = update_edit_student_page[key];
            let validate_element = Validations(form_element);
            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;
            update_edit_student_page[key] = form_element;
        }
        for (let ind in parent_address_data) {
            for (let key in parent_address_data[ind]) {
                if (key === "parent_address_id") {
                } else {
                    let form_element = parent_address_data[ind][key];
                    let validate_element = Validations(form_element);
                    is_form_valid = validate_element.valid === false ? false : is_form_valid;
                    form_element.onBlur_out = true;
                    form_element.valid = validate_element.valid;
                    form_element.error_msg = validate_element.error_msg;
                    parent_address_data[ind][key] = form_element;
                }
            }
        }
        update_edit_student_page['parent_address_data'] = parent_address_data;
        this.setState({ edit_student_page: update_edit_student_page });
        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.edit_student_page) {
                form_data[key] = this.state.edit_student_page[key].value;
                if (key === "parent_address_data") {
                    if (this.state.address_same_as_father.value === false) {
                        let parent_address_data = [
                            {
                                "address_line1": this.state.edit_student_page.parent_address_data[0].address_line1.value,
                                "address_line2": this.state.edit_student_page.parent_address_data[0].address_line2.value,
                                "parent_state": this.state.edit_student_page.parent_address_data[0].parent_state.value,
                                "parent_city": this.state.edit_student_page.parent_address_data[0].parent_city.value,
                                "parent_postcode": this.state.edit_student_page.parent_address_data[0].parent_postcode.value,
                                "parent_country": this.state.edit_student_page.parent_address_data[0].parent_country.value,
                                "parent_address_type": this.state.edit_student_page.parent_address_data[0].parent_address_type.value,
                                "parent_address_id": this.state.edit_student_page.parent_address_data[0].parent_address_id,
                            },
                            {
                                "address_line1": this.state.edit_student_page.parent_address_data[1].address_line1.value,
                                "address_line2": this.state.edit_student_page.parent_address_data[1].address_line2.value,
                                "parent_state": this.state.edit_student_page.parent_address_data[1].parent_state.value,
                                "parent_city": this.state.edit_student_page.parent_address_data[1].parent_city.value,
                                "parent_postcode": this.state.edit_student_page.parent_address_data[1].parent_postcode.value,
                                "parent_country": this.state.edit_student_page.parent_address_data[1].parent_country.value,
                                "parent_address_type": this.state.edit_student_page.parent_address_data[1].parent_address_type.value,
                                "parent_address_id": this.state.edit_student_page.parent_address_data[1].parent_address_id,
                            }
                        ]
                        form_data['parent_address_data'] = JSON.stringify(parent_address_data);
                    }
                } else if (this.state.address_same_as_father.value === true) {
                    let parent_address_data = [
                        {
                            "address_line1": this.state.edit_student_page.parent_address_data[0].address_line1.value,
                            "address_line2": this.state.edit_student_page.parent_address_data[0].address_line2.value,
                            "parent_state": this.state.edit_student_page.parent_address_data[0].parent_state.value,
                            "parent_city": this.state.edit_student_page.parent_address_data[0].parent_city.value,
                            "parent_postcode": this.state.edit_student_page.parent_address_data[0].parent_postcode.value,
                            "parent_country": this.state.edit_student_page.parent_address_data[0].parent_country.value,
                            "parent_address_type": this.state.edit_student_page.parent_address_data[0].parent_address_type.value,
                            "parent_address_id": this.state.edit_student_page.parent_address_data[0].parent_address_id,
                        }
                    ]
                    form_data['parent_address_data'] = JSON.stringify(parent_address_data);
                }
            }


            form_data['parent_id'] = this.state.upDate_parent_id;
            if (this.state.father_as_host.value === true && this.state.mother_as_host.value === false) {
                let parent_as_host = [{
                    parent_id : this.state.upDate_parent_id,
                    student_id : this.state.edit_student_page.student_id.value,
                    parent_type: "father",
                    is_host_approved: true,
                    host_relation: "father as host"
                }]
                form_data['parent_as_host'] = JSON.stringify(parent_as_host);
            } else if (this.state.father_as_host.value === false && this.state.mother_as_host.value === true) {
                let parent_as_host = [{
                    parent_id : this.state.upDate_parent_id,
                    student_id : this.state.edit_student_page.student_id.value,
                    parent_type: "mother",
                    is_host_approved: true,
                    host_relation: "mother as host"
                }]
                form_data['parent_as_host'] = JSON.stringify(parent_as_host);
            } else if (this.state.father_as_host.value === true && this.state.mother_as_host.value === true) {
                let parent_as_host = [{
                    parent_id : this.state.upDate_parent_id,
                    student_id : this.state.edit_student_page.student_id.value,
                    parent_type: "mother",
                    is_host_approved: true,
                    host_relation: "mother as host"
                }, {
                    parent_id : this.state.upDate_parent_id,
                    student_id : this.state.edit_student_page.student_id.value,
                    parent_type: "father",
                    is_host_approved: true,
                    host_relation: "father as host"
                },]
                form_data['parent_as_host'] = JSON.stringify(parent_as_host);
            }else if (this.state.father_as_host.value === false && this.state.mother_as_host.value === false){
                let parent_as_host = []
                form_data['parent_as_host'] = parent_as_host;
            } 
            
            this.props.onUpdateParentPersonal(form_data).then((response) => {
                if (response.success === true) {
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: `/${school_code}/student/student-list`,
                        });
                    }, 500);
                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            });
        }
    };


    ///////////////////////////////////////////////// host submit handler ///////////////////////////////////


    SubmitHandlerHost = async (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_edit_student_page = this.state.edit_student_page;
        const update_host_data = update_edit_student_page.host_data;
        for (let ind in update_host_data) {
            for (let key in update_host_data[ind]) {
                let form_element = update_host_data[ind][key];
                let validate_element = Validations(form_element);
                is_form_valid = validate_element.valid === false ? false : is_form_valid;
                form_element.onBlur_out = true;
                form_element.valid = validate_element.valid;
                form_element.error_msg = validate_element.error_msg;
                update_host_data[ind][key] = form_element;
                update_edit_student_page.host_data = update_host_data;
            }
        }
        this.setState({ edit_student_page: update_edit_student_page });
        console.log("host_id==================", this.state.edit_student_page);
        if (is_form_valid) {
            const form_data = {}
            let old_host_data = update_host_data;
            let new_host_data = []
            old_host_data.forEach((item, index) => {
                if (item['host_id']) {
                    new_host_data.push({
                        "host_id": item.host_id.value === "new_host" ? "" : item.host_id.value,
                        "host_name": item.host_name.value,
                        "host_email": item.host_email.value,
                        "host_relation": item.host_relation.value,
                        "host_contact": item.host_contact.value,
                        "host_address": item.host_address.value,
                        "is_host_approved": item.is_host_approved.value,
                        "remark_boarding": item.remark_boarding.value,
                        "remark_host": item.remark_host.value,
                        "remark_parents": item.remark_parents.value,
                        "student_host_comment": item.student_host_comment.value
                    })
                }
            })
            form_data['student_id'] = this.state.edit_student_page.student_id.value;
            console.log("new_host_data", new_host_data);
            form_data['host_data'] = JSON.stringify(new_host_data);
            
            form_data['is_preffered_name_update'] = this.state.edit_student_page.is_preffered_name_update.value;

            this.props.onAddOrUpdateStudentHost(form_data).then((response) => {
                if (response.success === true) {
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: `/${school_code}/student/student-list`,
                        });
                    }, 500);
                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            });
        }
    };



    ////////////////////////////////// email resend function ////////////////////////////////

    ResendStudentAndParentsPassword = (evnet, identifire) => {
        const payload = {
            student_id: this.state.edit_student_page.student_id.value,
            toStudent: identifire === "toStudent" ? true : false,
            toFather: identifire === "toFather" ? true : false,
            toMother: identifire === "toMother" ? true : false
        }
        this.props.onResendStudentAndParentsPassword(payload).then((response) => {
            if (response.success === true) {
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyPress);
    }

    handleKeyPress = (event) => {
        if (event.keyCode === 27) {
            this.setState({ OpenImageModal: false })
        }
    };


    render() {
        const { edit_student_page } = this.state;


        const { student_age_show } = this.state
        const { parent_address_data } = this.state;
        const { country_id } = this.state
        const { host_data } = this.state.edit_student_page;
        console.log("fatherashost", this.state.father_as_host)
        console.log("motherashost", this.state.mother_as_host)

        console.log("edit_student_page", edit_student_page);

        console.log("addresssameasfather", edit_student_page?.date_of_birth?.value);
        const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
        let curr = new Date();
        curr.setDate(curr.getDate());
        let date = curr.toISOString().substring(0, 10);
        let setEnterUserAge = edit_student_page.date_of_birth.value;
        let setNew = new Date(setEnterUserAge)
        let d = new Date();
        const currentyear = d.getFullYear();
        const yearOnly = currentyear - setNew.getFullYear() ? currentyear - setNew.getFullYear() : ``;
        let age_now = d.getFullYear() - setNew.getFullYear();
        let monthsDiff = d.getMonth() - setNew.getMonth();
        let dayDiff = d.getDate() - setNew.getDate();
        if (dayDiff < 0) {
            let tempDate = new Date(d.getFullYear(), d.getMonth() - 1, 0);
            dayDiff += tempDate.getDate();
            monthsDiff--;
            // monthsDiff--;
            // dayDiff += new Date(d.getFullYear(), d.getMonth(), 0).getDate();
        }
        if (monthsDiff < 0 || (monthsDiff === 0 && dayDiff < 0)) {
            age_now--;
            monthsDiff += 12;
        }

        let years = age_now > 0 ? age_now + " year" + (age_now > 1 ? "s" : "") : "";
        let months = monthsDiff > 0 ? monthsDiff + " month" + (monthsDiff > 1 ? "s" : "") : "";
        let year = years && months ? years + " " + months : years + months;

        student_age_show.value = year;
        edit_student_page.student_age.value = yearOnly;

        console.log("dddddddddddddddddddddddd>>", edit_student_page)
        return (

            <React.Fragment>

                {this.state.OpenImageModal &&
                    <div className="upload-image-outer-div" >
                        <span style={{ float: "right" }} className="pointer m-5" onClick={() => this.setState({ OpenImageModal: !this.state.OpenImageModal })}>
                            <i class="fas fa-times fa-2x" sixe="xl" style={{ color: "#ffffff", }}></i>

                        </span>

                        <div className="upload-image-inner-div">
                            <AvatarImageCropper
                                rootStyle={{ borderRadius: "50%" }}
                                cancelBtnStyle={{ display: "none" }}
                                apply={this.inputChangeHandlerimage}
                                className={"image_crop_style"}
                                sliderConStyle={{ margin: "20px", backgroundColor: "none" }}
                                applyBtnStyle={{}}
                                isBack={true} />
                        </div>
                        <div className="text-center mt-10">
                            <span className="text-white font-weight-bold">The image maximum size is 2MB. Please upload png/jpg/jpeg image</span>
                        </div>
                    </div>
                }

                {this.state.isAuthenticated ? <Navigate to={this.state.redirect_url} /> : null}

                <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed demo h-100">
                    <div className="d-flex flex-column flex-root h-100">
                        <div className="page d-flex flex-row flex-column-fluid">
                            <Sidebar></Sidebar>

                            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                                <TopBar></TopBar>
                                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                    <div className="post d-flex flex-column-fluid" id="kt_post">
                                        <div id="kt_content_container" className="container-fluid">
                                            <ul className="nav nav-tabs home-tabs fontweight" id="myTab" role="tablist">

                                                <li className="nav-item me-6" role="presentation">
                                                    <button className="nav-link active" id="StudentProfile-tab" data-bs-toggle="tab" data-bs-target="#StudentProfile" type="button" role="tab" aria-controls="home" aria-selected="true">
                                                        Student Profile
                                                    </button>
                                                </li>

                                                <li className="nav-item me-6" role="presentation">
                                                    <button className="nav-link" id="BoardingDetail-tab" data-bs-toggle="tab" data-bs-target="#BoardingDetail" type="button" role="tab" aria-controls="process" aria-selected="false">
                                                        Parent Profile
                                                    </button>
                                                </li>

                                                <li className="nav-item me-6" role="presentation">
                                                    <button className="nav-link" id="HostDetail-tab" data-bs-toggle="tab" data-bs-target="#HostDetail" type="button" role="tab" aria-controls="process" aria-selected="false">
                                                        Host Profile
                                                    </button>
                                                </li>
                                            </ul>



                                            <div className="tab-content" id="myTabContent">


                                                <div className="tab-pane fade show active" id="StudentProfile" role="tabpanel" aria-labelledby="StudentProfile-tab">
                                                    <form onSubmit={this.SubmitHandler}>
                                                        <div className="card mb-5 tabs-outer">
                                                            <div className="card-header position-relative py-0 details-bg">
                                                                <h2 className="card-title  m-0">Student Profile</h2>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="row">

                                                                    <div className="col-lg-12 fv-row mb-5">
                                                                        <div className="row">
                                                                            <div className="col-sm-4 col-lg-3 mb-10">
                                                                                <div className="profile-image-out p-0">
                                                                                    <div className="row justify-content-center">
                                                                                        <div className="col-sm-12">
                                                                                            <img
                                                                                                alt="profile"
                                                                                                className="img-fluid"
                                                                                                src={
                                                                                                    edit_student_page?.student_avatar?.thumbnail_image !== "" ? edit_student_page?.student_avatar?.thumbnail_image
                                                                                                        :
                                                                                                        (edit_student_page.gender.value === "male" ? "/media/avatars/boy.jpg" : "/media/avatars/girl.jpg")

                                                                                                }
                                                                                            />
                                                                                            {this.state.cam_Start === null ? ""
                                                                                                :
                                                                                                <div className="pb-5 student-photo-by-camera">
                                                                                                    <Picture
                                                                                                        take_photo_by_props={(blob, identifier) => this.take_photo_by_props(blob, identifier)}
                                                                                                        cam_not_available={(value) => this.cam_not_available(value)}
                                                                                                    />
                                                                                                </div>
                                                                                            }
                                                                                            <div className="h-100  wrap-btn-col align-items-center upload-btn-block">

                                                                                                <input type="button" className="btn btn-sm btn-outline-primary px-4 rounded-50 " value="Upload Student Image" onClick={() => this.setState({ OpenImageModal: !this.state.OpenImageModal })} />

                                                                                                <label className="fw-bold px-7">Or</label>

                                                                                                <button type="button" className="btn btn-sm btn-primary apply-btn"
                                                                                                    onClick={() => this.setState({ cam_Start: "stream" })}
                                                                                                >
                                                                                                    Take Photo
                                                                                                </button>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-8 col-lg-9">
                                                                            <div>

<input
    type={edit_student_page.school_detail_id.type}
    value={user_info.user_school.school_detail_id ? user_info.user_school.school_detail_id : ""}
    className="form-control"
/>

</div>
                                                                                <div className="row">
                                                                                <div className="col-lg-4  col-xl-4 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.student_first_name.label}
                                                                            <Tooltip title="Note:- if you edit your first name, then your login username is also changed and please check your mail." placement="top">
                                                                                <i className="fas fa-question-circle ms-2"></i>
                                                                            </Tooltip>
                                                                        </label>
                                                                        <input
                                                                            type={edit_student_page.student_first_name.type}
                                                                            value={edit_student_page.student_first_name.value ? edit_student_page.student_first_name.value : ""}
                                                                            className="form-control"
                                                                            placeholder={edit_student_page.student_first_name.placeholder}
                                                                            onChange={(event) => this.inputChangeHandler(event, "student_first_name")}
                                                                        />
                                                                        {!edit_student_page.student_first_name.valid && edit_student_page.student_first_name.onBlur_out ? <div className="error field-error">{edit_student_page.student_first_name.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-4 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.student_last_name.label}</label>
                                                                        <input
                                                                            type={edit_student_page.student_last_name.type}
                                                                            className="form-control"
                                                                            placeholder={edit_student_page.student_last_name.placeholder}
                                                                            onChange={(event) => this.inputChangeHandler(event, "student_last_name")}
                                                                            value={edit_student_page.student_last_name.value ? edit_student_page.student_last_name.value : ""}
                                                                        />
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-4 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.preferred_name.label}</label>
                                                                        <input
                                                                            type={edit_student_page.preferred_name.type}
                                                                            className="form-control"
                                                                            placeholder={edit_student_page.preferred_name.placeholder}
                                                                            onChange={(event) => this.inputChangeHandler(event, "preferred_name")}
                                                                            value={edit_student_page.preferred_name.value ? edit_student_page.preferred_name.value : ""}
                                                                        />
                                                                        {!edit_student_page.preferred_name.valid && edit_student_page.preferred_name.onBlur_out ? <div className="error field-error">{edit_student_page.preferred_name.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-4 fv-row mb-5">
                                                                        <label className="fw-bold mb-2 d-flex justify-content-between">
                                                                            <div>
                                                                                {edit_student_page.student_email.label}
                                                                                <Tooltip title="Note:- If genuine address used, student will automatically receive Login instructions to  “VIEW only” Personal & Leave details." placement="top">
                                                                                    <i className="fas fa-question-circle ms-2"></i>
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div>
                                                                                <span class="btn-label text-secondary" onClick={(event) => this.ResendStudentAndParentsPassword(event, "toStudent")}>
                                                                                    <i class="fas fa-retweet me-1"></i> Resend
                                                                                </span>
                                                                            </div>
                                                                        </label>
                                                                        <input
                                                                            type={edit_student_page.student_email.type}
                                                                            className="form-control"
                                                                            placeholder={edit_student_page.student_email.placeholder}
                                                                            onChange={(event) => this.inputChangeHandler(event, "student_email")}
                                                                            value={edit_student_page.student_email.value ? edit_student_page.student_email.value : ""}
                                                                            readOnly
                                                                        />
                                                                        {!edit_student_page.student_email.valid && edit_student_page.student_email.onBlur_out ? <div className="error field-error">{edit_student_page.student_email.error_msg}</div> : ""}
                                                                    </div>
                                                                    <div className="col-lg-4  col-xl-4 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.student_phone.label}</label>
                                                                        <ReactPhoneInput
                                                                            countryCodeEditable={false}
                                                                            // country={edit_student_page.country_code.value ? edit_student_page.country_code.value : ""}
                                                                            value={edit_student_page.student_phone.value ? edit_student_page.student_phone.value : ""}
                                                                            onChange={(event) => this.inputChangeHandler(event, "student_phone")}
                                                                            // disabled={add_student_form.data_status.value === "null" ? false : true}
                                                                            enableLongNumbers={true}
                                                                            autoComplete="off"
                                                                        />
                                                                        {!edit_student_page.student_phone.valid && edit_student_page.student_phone.onBlur_out ? <div className="error field-error">{edit_student_page.student_phone.error_msg}</div> : ""}
                                                                    </div>
                                                                   
                                                                    <div className="col-lg-4  col-xl-4 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">Gender * </label>
                                                                        <div className="d-flex mt-3">
                                                                            <label className="cursor-pointer">
                                                                                <input
                                                                                    type="radio"
                                                                                    id="Gender"
                                                                                    name="Gender"
                                                                                    className="form-check-input me-2 cursor-pointer"
                                                                                    checked={edit_student_page.gender.value === "male" ? true : false}
                                                                                    value="male"
                                                                                    onChange={(event) => this.inputChangeHandler(event, "gender")}

                                                                                />
                                                                                Male
                                                                            </label>
                                                                            <label className="ms-8 cursor-pointer">
                                                                                <input
                                                                                    type="radio"
                                                                                    id="Gender1"
                                                                                    name="Gender"
                                                                                    className="form-check-input me-2 cursor-pointer"
                                                                                    value="female"
                                                                                    checked={edit_student_page.gender.value === "female" ? true : false}
                                                                                    onChange={(event) => this.inputChangeHandler(event, "gender")}
                                                                                />
                                                                                Female
                                                                            </label>
                                                                        </div>
                                                                        {!edit_student_page.gender.valid && edit_student_page.gender.onBlur_out ? <div className="error field-error">{edit_student_page.gender.error_msg}</div> : ""}
                                                                    </div>
                                                                    <div className="col-lg-4  col-xl-4 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.date_of_birth.label}</label>
                                                                        <input
                                                                            type={edit_student_page.date_of_birth.type}
                                                                            className="form-control"
                                                                            placeholder={edit_student_page.date_of_birth.placeholder}
                                                                            onChange={(event) => this.inputChangeHandler(event, "date_of_birth")}
                                                                            value={edit_student_page.date_of_birth.value ? edit_student_page.date_of_birth.value : ""}
                                                                            max={date}
                                                                        />
                                                                        {!edit_student_page.date_of_birth.valid && edit_student_page.date_of_birth.onBlur_out ? <div className="error field-error">{edit_student_page.date_of_birth.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-4 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.student_age.label}</label>
                                                                        <input
                                                                            style={{ backgroundColor: "#f5f5f5", pointerEvents: "none" }}
                                                                            type={edit_student_page.student_age.type}
                                                                            className="form-control"
                                                                            placeholder={edit_student_page.student_age.placeholder}
                                                                            // onChange={(event) => this.inputChangeHandler(event, "student_age")}
                                                                            value={student_age_show.value ? student_age_show.value : ""}
                                                                        />
                                                                        {!edit_student_page.student_age.valid && edit_student_page.student_age.onBlur_out ? <div className="error field-error">{edit_student_page.student_age.error_msg}</div> : ""}
                                                                    </div>

                                                                     <div className="col-lg-4  col-xl-4 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.class_id.label}</label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={edit_student_page.class_id.options}
                                                                            value={edit_student_page.class_id.options.filter(function (option) {
                                                                                return option.value === edit_student_page.class_id.value;
                                                                            })}
                                                                            onChange={(newValue) => this.handleChange(newValue, "class_id")}
                                                                        />
                                                                        {!edit_student_page.class_id.valid && edit_student_page.class_id.onBlur_out ? <div className="error field-error">{edit_student_page.class_id.error_msg}</div> : ""}
                                                                    </div>

                                                                    
                                                                    

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                 

                                                                   
                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.campus_name.label} </label>
                                                                        <input
                                                                            type={edit_student_page.campus_name.type}
                                                                            value={edit_student_page.campus_name.value ? edit_student_page.campus_name.value : ""}
                                                                            className="form-control"
                                                                            placeholder={edit_student_page.campus_name.placeholder}
                                                                            onChange={(event) => this.inputChangeHandler(event, "campus_name")}
                                                                        />
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.dormitory_id.label}</label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={edit_student_page.dormitory_id.options}
                                                                            value={edit_student_page.dormitory_id.options.filter(function (option) {
                                                                                return option.value === edit_student_page.dormitory_id.value;
                                                                            })}
                                                                            onChange={(newValue) => this.handleChange(newValue, "dormitory_id", "weekend_day from value")}
                                                                        />
                                                                        {!edit_student_page.dormitory_id.valid && edit_student_page.dormitory_id.onBlur_out ? <div className="error field-error">{edit_student_page.dormitory_id.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.sporting_house.label} </label>
                                                                        <input
                                                                            type={edit_student_page.sporting_house.type}
                                                                            value={edit_student_page.sporting_house.value ? edit_student_page.sporting_house.value : ""}
                                                                            className="form-control"
                                                                            placeholder={edit_student_page.sporting_house.placeholder}
                                                                            onChange={(event) => this.inputChangeHandler(event, "sporting_house")}
                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.laundry_number.label} </label>
                                                                        <input
                                                                            type={edit_student_page.laundry_number.type}
                                                                            value={edit_student_page.laundry_number.value ? edit_student_page.laundry_number.value : ""}
                                                                            className="form-control"
                                                                            placeholder={edit_student_page.laundry_number.placeholder}
                                                                            onChange={(event) => this.inputChangeHandler(event, "laundry_number")}
                                                                        />
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.tutor_name.label} </label>
                                                                        <input
                                                                            type={edit_student_page.tutor_name.type}
                                                                            value={edit_student_page.tutor_name.value ? edit_student_page.tutor_name.value : ""}
                                                                            className="form-control"
                                                                            placeholder={edit_student_page.tutor_name.placeholder}
                                                                            onChange={(event) => this.inputChangeHandler(event, "tutor_name")}
                                                                        />
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.tutor_email.label}</label>
                                                                        <input
                                                                            type={edit_student_page.tutor_email.type}
                                                                            className="form-control"
                                                                            placeholder={edit_student_page.tutor_email.placeholder}
                                                                            onChange={(event) => this.inputChangeHandler(event, "tutor_email")}
                                                                            value={edit_student_page.tutor_email.value ? edit_student_page.tutor_email.value : ""}

                                                                        />
                                                                    </div>

                                                                    

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.kiosk_mode_pin.label}</label>
                                                                        <div className="position-relative">
                                                                            <input
                                                                                type={edit_student_page.kiosk_mode_pin.type}
                                                                                className="form-control"
                                                                                maxLength={6}
                                                                                min={0}
                                                                                value={edit_student_page.kiosk_mode_pin.value ? edit_student_page.kiosk_mode_pin.value : ""}
                                                                                placeholder={edit_student_page.kiosk_mode_pin.placeholder}
                                                                                onChange={(event) => this.inputChangeHandler(event, "kiosk_mode_pin")}
                                                                                onKeyDown={(event) => this.validatePinLength(event)}
                                                                            />
                                                                        </div>
                                                                    </div>


                                                                   
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="card mb-5 tabs-outer">
                                                            <div className="card-header position-relative py-0 details-bg">
                                                                <h2 className="card-title  m-0">Permission’s</h2>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.first_point_contact.label}</label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={edit_student_page.first_point_contact.options}
                                                                            value={edit_student_page.first_point_contact.options.filter(function (option) {
                                                                                return option.value === edit_student_page.first_point_contact.value;
                                                                            })}
                                                                            onChange={(newValue) => this.handleChange(newValue, "first_point_contact", "weekend_day from value")}
                                                                        />
                                                                        {!edit_student_page.first_point_contact.valid && edit_student_page.first_point_contact.onBlur_out ? <div className="error field-error">{edit_student_page.first_point_contact.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.first_point_email.label}</label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={edit_student_page.first_point_email.options}
                                                                            value={edit_student_page.first_point_email.options.filter(function (option) {
                                                                                return option.value === edit_student_page.first_point_email.value;
                                                                            })}
                                                                            onChange={(newValue) => this.handleChange(newValue, "first_point_email", "weekend_day from value")}
                                                                        />
                                                                        {!edit_student_page.first_point_email.valid && edit_student_page.first_point_email.onBlur_out ? <div className="error field-error">{edit_student_page.first_point_email.error_msg}</div> : ""}
                                                                    </div>
                                                                    
                                                                    {/* <div className="col-lg-6 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">Allergic * </label>
                                                                        <div className="d-flex">
                                                                            <label className="cursor-pointer">
                                                                                <input
                                                                                    type="radio"
                                                                                    id="Allergy"
                                                                                    name="Allergic"
                                                                                    className="form-check-input me-2 cursor-pointer"
                                                                                    value="yes"
                                                                                    checked={edit_student_page.student_allergy_status.value === "yes" ? true : false}
                                                                                    onChange={(event) => this.inputChangeHandler(event, "student_allergy_status")}
                                                                                />
                                                                                Yes
                                                                            </label>
                                                                            <label className="ms-8 cursor-pointer">
                                                                                <input
                                                                                    type="radio"
                                                                                    id="Allergy1"
                                                                                    name="Allergic"
                                                                                    className="form-check-input me-2 cursor-pointer"
                                                                                    value="no"
                                                                                    checked={edit_student_page.student_allergy_status.value === "no" ? true : false}
                                                                                    onChange={(event) => this.inputChangeHandler(event, "student_allergy_status")}

                                                                                />
                                                                                No
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    {this?.state?.edit_student_page?.allergy_data?.map((index, i) => {
                                                                        return (edit_student_page?.student_allergy_status?.value === "yes" ?
                                                                            <div className="col-lg-12 fv-row mb-5">
                                                                                <div className="row">
                                                                                    <div className="col-sm-3">
                                                                                        <input
                                                                                            type={index.allergy_name.type}
                                                                                            name="fname"
                                                                                            placeholder={index.allergy_name.placeholder}
                                                                                            className="form-control"
                                                                                            value={index?.allergy_name?.value}
                                                                                            onChange={(event) => this.inputChangeAllergyName(event, i)}
                                                                                        />
                                                                                        {!index.allergy_name.valid && index.allergy_name.onBlur_out ? <div className="error field-error">{index.allergy_name.error_msg}</div> : ""}
                                                                                    </div>
                                                                                    <div className="col-sm-4">
                                                                                        <input
                                                                                            type={index.allergy_info.type}
                                                                                            name="fname"
                                                                                            placeholder={index.allergy_info.placeholder}
                                                                                            className="form-control"
                                                                                            value={index?.allergy_info?.value}
                                                                                            onChange={(event) => this.inputChangeAllergyInfo(event, i)}
                                                                                        />
                                                                                        {!index.allergy_info.valid && index.allergy_info.onBlur_out ? <div className="error field-error">{index.allergy_info.error_msg}</div> : ""}
                                                                                    </div>

                                                                                    <div className="col-sm-1">
                                                                                        <div className="d-flex justify-content-around flex-shrink-0">
                                                                                            <span
                                                                                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm pointer"
                                                                                                data-bs-toggle="tooltip"
                                                                                                title="Add"
                                                                                                data-bs-original-title="form_dataAdd"
                                                                                                aria-describedby="tooltip230873"
                                                                                                onClick={(i) => this.clickAddAllergyrow(index.id)}
                                                                                            >
                                                                                                <span className="svg-icon svg-icon-3 pointer d-block">
                                                                                                    <i className="fas fa-plus"></i>
                                                                                                </span>
                                                                                            </span>
                                                                                            <span
                                                                                                style={this.state.edit_student_page.allergy_data.length === 1 ? { display: "none" } : { display: "block" }}
                                                                                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm "
                                                                                                data-bs-toggle="tooltip"
                                                                                                title="Delete"
                                                                                                data-bs-original-title="Delete"
                                                                                                key={index.id}
                                                                                                onClick={(e) => this.clickDeleteAllergyrow(index, i,)}
                                                                                            >
                                                                                                <span className="svg-icon svg-icon-3 d-block pt-3">
                                                                                                    <i className="far fa-trash-alt"></i>
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null
                                                                        )
                                                                    })} */}

                                                                    {edit_student_page.student_allergy_status.value === "no" ?
                                                                        <>
                                                                            <Modal show={this.state.isOpen} onHide={this.closeModal}>
                                                                                <Modal.Header closeButton>
                                                                                    <Modal.Title>Modal heading</Modal.Title>
                                                                                </Modal.Header>
                                                                                <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
                                                                                <Modal.Footer>
                                                                                    <button variant="secondary" onClick={this.closeModal}>
                                                                                        Close
                                                                                    </button>
                                                                                </Modal.Footer><li className="nav-item me-6" role="presentation">
                                                                                    <button className="nav-link" id="BoardingDetail-tab" data-bs-toggle="tab" data-bs-target="#BoardingDetail" type="button" role="tab" aria-controls="process" aria-selected="false">
                                                                                        Parent Profile
                                                                                    </button>
                                                                                </li>
                                                                            </Modal>
                                                                        </>
                                                                        :
                                                                        null
                                                                    }




                                                                    <div className="col-lg-12 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">Want to display Image? * </label>
                                                                        <div className="d-flex">
                                                                            <label className="cursor-pointer">
                                                                                <input
                                                                                    type="radio"
                                                                                    id="per12"
                                                                                    name="permission"
                                                                                    className="form-check-input me-2 cursor-pointer"
                                                                                    value="true"
                                                                                    checked={edit_student_page.display_image_required.value === "true" ? true : false}
                                                                                    onChange={(event) => this.inputChangeHandler(event, "display_image_required")}
                                                                                />
                                                                                Yes
                                                                            </label>
                                                                            <label className="ms-8 cursor-pointer">
                                                                                <input
                                                                                    type="radio"
                                                                                    id="per112"
                                                                                    name="permission"
                                                                                    className="form-check-input me-2 cursor-pointer"
                                                                                    value="false"
                                                                                    checked={edit_student_page.display_image_required.value === "false" ? true : false}
                                                                                    onChange={(event) => this.inputChangeHandler(event, "display_image_required")}

                                                                                />
                                                                                No
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    {console.log("father_as_host=============",this.state.father_as_host,this.state.mother_as_host)}
                                                                    <div className="col-lg-4 fv-row mb-5 d-flex">
                                                                        <label className="fw-bold  d-flex me-4"> {edit_student_page.show_parent_detail_app.label} </label>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input me-1"
                                                                            value={edit_student_page.show_parent_detail_app.value}
                                                                            checked={edit_student_page.show_parent_detail_app.isChecked}
                                                                            onChange={(event) => this.inputChangeHandlerCheckbox(event, "show_parent_detail_app")}
                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-4 fv-row mb-5 d-flex">
                                                                        <label className="fw-bold d-flex me-4"> Exclude Father as Host </label>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input me-1"
                                                                            value={this.state.father_as_host.value}
                                                                            checked={this.state.father_as_host.isChecked ? false : true}
                                                                            onChange={(event) => this.inputParentCheckbox(event, "father_as_host")}
                                                                            autoComplete="off"
                                                                        />
                                                                    </div>

                                                                    <div className="col-lg-4 fv-row mb-5 d-flex">
                                                                        <label className="fw-bold d-flex me-4"> Exclude Mother as Host </label>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input me-1"
                                                                            value={this.state.mother_as_host.value}
                                                                            checked={this.state.mother_as_host.isChecked ? false : true}
                                                                            onChange={(event) => this.inputParentCheckbox(event, "mother_as_host")}
                                                                            autoComplete="off"
                                                                        />
                                                                    </div>

                                                                    <div className="col-lg-4 fv-row mb-5 d-flex">
                                                                        <label className="fw-bold d-flex me-4"> {edit_student_page.allergy_unauth_access.label} </label>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input me-1"
                                                                            value={edit_student_page.allergy_unauth_access.value}
                                                                            checked={edit_student_page.allergy_unauth_access.isChecked}
                                                                            onChange={(event) => this.inputChangeHandlerCheckbox(event, "allergy_unauth_access")}
                                                                        />

                                                                    </div>
                                                                    <div className="col-lg-4 fv-row mb-5 d-flex">
                                                                        <label className="fw-bold  d-flex me-4"> {edit_student_page.withdraw_leave.label} </label>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input me-1"
                                                                            value={edit_student_page.withdraw_leave.value}
                                                                            checked={edit_student_page.withdraw_leave.isChecked}
                                                                            onChange={(event) => this.inputChangeHandlerCheckbox(event, "withdraw_leave")}
                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-4 fv-row mb-5 d-flex">
                                                                        <label className="fw-bold d-flex me-4"> {edit_student_page.permitted_student_app_signout.label} </label>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input me-1"
                                                                            value={edit_student_page.permitted_student_app_signout.value}
                                                                            checked={edit_student_page.permitted_student_app_signout.isChecked}
                                                                            onChange={(event) => this.inputChangeHandlerCheckbox(event, "permitted_student_app_signout")}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="col-sm-12 d-flex justify-content-end">
                                                            <div className="d-flex align-items-end">
                                                                <button type="submit" className="btn btn-sm btn-primary apply-btn min-150px">
                                                                    Update Student
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>



















                                                <div className="tab-pane fade" id="BoardingDetail" role="tabpanel" aria-labelledby="BoardingDetail-tab">
                                                    <form onSubmit={this.SubmitHandlerParent}>
                                                        <div className="card mb-5 tabs-outer">
                                                            <div className="card-header position-relative py-0 details-bg d-flex align-items-center">
                                                                <h2 className="card-title  m-0">Parent Details</h2>
                                                            </div>

                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.father_name.label}</label>
                                                                        <input
                                                                            type={edit_student_page.father_name.type}
                                                                            className="form-control"
                                                                            placeholder={edit_student_page.father_name.placeholder}
                                                                            value={edit_student_page.father_name.value ? edit_student_page.father_name.value : ""}
                                                                            onChange={(event) => this.inputChangeHandler(event, "father_name")}
                                                                        />
                                                                        {!edit_student_page.father_name.valid && edit_student_page.father_name.onBlur_out ? (
                                                                            <div className="error field-error">{edit_student_page.father_name.error_msg}</div>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.mother_name.label}</label>
                                                                        <input
                                                                            type={edit_student_page.mother_name.type}
                                                                            className="form-control"
                                                                            placeholder={edit_student_page.mother_name.placeholder}
                                                                            value={edit_student_page.mother_name.value ? edit_student_page.mother_name.value : ""}
                                                                            onChange={(event) => this.inputChangeHandler(event, "mother_name")}
                                                                        />
                                                                        {!edit_student_page.mother_name.valid && edit_student_page.mother_name.onBlur_out ? (
                                                                            <div className="error field-error">{edit_student_page.mother_name.error_msg}</div>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2 d-flex justify-content-between">
                                                                            <div>
                                                                                {edit_student_page.father_email.label}
                                                                            </div>
                                                                            <div>
                                                                                <span class="btn-label text-secondary" onClick={(event) => this.ResendStudentAndParentsPassword(event, "toFather")}>
                                                                                    <i class="fas fa-retweet me-1"></i>
                                                                                    Resend
                                                                                </span>
                                                                            </div>
                                                                        </label>
                                                                        <input
                                                                            type={edit_student_page.father_email.type}
                                                                            className="form-control"
                                                                            placeholder={edit_student_page.father_email.placeholder}
                                                                            value={edit_student_page.father_email.value ? edit_student_page.father_email.value : ""}
                                                                            onChange={(event) => this.inputChangeHandler(event, "father_email")}
                                                                        />

                                                                        {!edit_student_page.father_email.valid && edit_student_page.father_email.onBlur_out ? (
                                                                            <div className="error field-error">{edit_student_page.father_email.error_msg}</div>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2 d-flex justify-content-between">
                                                                            <div>
                                                                                {edit_student_page.mother_email.label}
                                                                            </div>
                                                                            <div >
                                                                                <span class="btn-label text-secondary" onClick={(event) => this.ResendStudentAndParentsPassword(event, "toMother")}>
                                                                                    <i class="fas fa-retweet me-1"></i> Resend
                                                                                </span>
                                                                            </div>
                                                                        </label>
                                                                        <input
                                                                            type={edit_student_page.mother_email.type}
                                                                            className="form-control"
                                                                            placeholder={edit_student_page.mother_email.placeholder}
                                                                            value={edit_student_page.mother_email.value ? edit_student_page.mother_email.value : ""}
                                                                            onChange={(event) => this.inputChangeHandler(event, "mother_email")}
                                                                        />

                                                                        {!edit_student_page.mother_email.valid && edit_student_page.mother_email.onBlur_out ? (
                                                                            <div className="error field-error">{edit_student_page.mother_email.error_msg}</div>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>

                                                                    {/* <div className="col-lg-12">
                                                                        <div className="d-flex justify-content-between gap-5">
                                                                            <h6 className="mb-0"> Father Address </h6>
                                                                            <div className="d-flex align-items-center">
                                                                                <label className="fw-bold d-flex align-items-center"> <input
                                                                                    type="checkbox"
                                                                                    className="form-check-input me-4"
                                                                                    value={this.state.father_as_host.value}
                                                                                    checked={this.state.father_as_host.isChecked ? true : false}
                                                                                    onChange={(event) => this.inputParentCheckbox(event, "father_as_host")}
                                                                                    autoComplete="off"
                                                                                /> Automatically Add Father as Host
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                    </div> */}


                                                                    {/*============================== address_line 1 ============================= */}

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.parent_address_data[0].address_line1.label}</label>
                                                                        <input
                                                                            type={edit_student_page.parent_address_data[0].address_line1.type}
                                                                            className="form-control"
                                                                            placeholder={edit_student_page.parent_address_data[0].address_line1.placeholder}
                                                                            value={edit_student_page.parent_address_data[0].address_line1.value ? edit_student_page.parent_address_data[0].address_line1.value : ""}
                                                                            onChange={(event) => this.inputChangeCountry(event, 0, "address_line1")}
                                                                            autoComplete="off"
                                                                        />
                                                                        {!edit_student_page.parent_address_data[0].address_line1.valid && edit_student_page.parent_address_data[0].address_line1.onBlur_out ?
                                                                            <div className="error field-error">
                                                                                {edit_student_page.parent_address_data[0].address_line1.error_msg}
                                                                            </div> :
                                                                            ""
                                                                        }
                                                                    </div>


                                                                    {/*============================== address_line 2 ============================= */}

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.parent_address_data[0].address_line2.label}</label>
                                                                        <input
                                                                            type={edit_student_page.parent_address_data[0].address_line2.type}
                                                                            className="form-control"
                                                                            placeholder={edit_student_page.parent_address_data[0].address_line2.placeholder}
                                                                            value={edit_student_page.parent_address_data[0].address_line2.value ? edit_student_page.parent_address_data[0].address_line2.value : ""}
                                                                            onChange={(event) => this.inputChangeCountry(event, 0, "address_line2")}
                                                                            autoComplete="off"
                                                                        />
                                                                        {!edit_student_page.parent_address_data[0].address_line2.valid && edit_student_page.parent_address_data[0].address_line2.onBlur_out ?
                                                                            <div className="error field-error">
                                                                                {edit_student_page.parent_address_data[0].address_line2.error_msg}
                                                                            </div> :
                                                                            ""
                                                                        }
                                                                    </div>

                                                                    {/*============================== father country ============================= */}

                                                                    {console.log("ffffffffffffffffffff", country_id.options, edit_student_page.parent_address_data[0].parent_country.value, country_id.options.find(option => option.label.toLowerCase() == edit_student_page.parent_address_data[0].parent_country.value.toLowerCase()))}

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.parent_address_data[0].parent_country.label}</label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={edit_student_page.parent_address_data[0].parent_country.options}
                                                                            value={edit_student_page.parent_address_data[0].parent_country.options.find(option => option.label.toLowerCase() === edit_student_page.parent_address_data[0].parent_country.value.toLowerCase())}
                                                                            onChange={(event) => this.handleChangeCountry(event, 0, "parent_country")}
                                                                        />
                                                                        {!edit_student_page.parent_address_data[0].parent_country.valid && edit_student_page.parent_address_data[0].parent_country.onBlur_out ?
                                                                            <div className="error field-error">
                                                                                {edit_student_page.parent_address_data[0].parent_country.error_msg}
                                                                            </div> :
                                                                            ""
                                                                        }
                                                                    </div>

                                                                    {/*============================== father state ============================= */}

                                                                    {console.log("dddddddddddddddddddddddddd", edit_student_page.parent_address_data)}
                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.parent_address_data[0].parent_state.label}</label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={edit_student_page.parent_address_data[0].parent_state.options}
                                                                            value={edit_student_page.parent_address_data[0].parent_state.options.find(option => option.label.toLowerCase() === edit_student_page.parent_address_data[0].parent_state.value.toLowerCase())}
                                                                            onChange={(event) => this.handleChangeCountry(event, 0, "parent_state")}
                                                                        />
                                                                    </div>

                                                                    {/*============================== father city ============================= */}

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.parent_address_data[0].parent_city.label}</label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={edit_student_page.parent_address_data[0].parent_city.options}
                                                                            value={edit_student_page.parent_address_data[0].parent_city.options.find(option => option.label.toLowerCase() === edit_student_page.parent_address_data[0].parent_city.value.toLowerCase())}
                                                                            onChange={(event) => this.handleChangeCountry(event, 0, "parent_city")}
                                                                        />
                                                                    </div>

                                                                    {/*============================== father postcode ============================= */}


                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.parent_address_data[0].parent_postcode.label}</label>
                                                                        <input
                                                                            type={edit_student_page.parent_address_data[0].parent_postcode.type}
                                                                            className="form-control"
                                                                            placeholder={edit_student_page.parent_address_data[0].parent_postcode.placeholder}
                                                                            value={edit_student_page.parent_address_data[0].parent_postcode.value ? edit_student_page.parent_address_data[0].parent_postcode.value : ""}
                                                                            onChange={(event) => this.inputChangeCountry(event, 0, "parent_postcode")}
                                                                            autoComplete="off"
                                                                        />
                                                                        {!edit_student_page.parent_address_data[0].parent_postcode.valid && edit_student_page.parent_address_data[0].parent_postcode.onBlur_out ?
                                                                            <div className="error field-error">
                                                                                {edit_student_page.parent_address_data[0].parent_postcode.error_msg}
                                                                            </div> :
                                                                            ""
                                                                        }
                                                                    </div>



                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.father_phone.label}</label>
                                                                        <ReactPhoneInput
                                                                            // country={"ind"}
                                                                            value={edit_student_page.father_phone.value ? edit_student_page.father_phone.value : ""}
                                                                            onChange={(event) => this.inputChangeHandler(event, "father_phone")}
                                                                            enableLongNumbers={true}

                                                                        />
                                                                        {!edit_student_page.father_phone.valid && edit_student_page.father_phone.onBlur_out ? (
                                                                            <div className="error field-error">{edit_student_page.father_phone.error_msg}</div>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.father_home_phone.label}</label>
                                                                        <ReactPhoneInput
                                                                            // country={edit_student_page.country_code.value ? edit_student_page.country_code.value : ""}
                                                                            value={edit_student_page.father_home_phone.value ? edit_student_page.father_home_phone.value : ""}
                                                                            onChange={(event) => this.inputChangeHandler(event, "father_home_phone")}
                                                                            enableLongNumbers={true}
                                                                        />
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.father_work_phone.label}</label>
                                                                        <ReactPhoneInput
                                                                            // country={edit_student_page.country_code.value ? edit_student_page.country_code.value : ""}
                                                                            value={edit_student_page.father_work_phone.value ? edit_student_page.father_work_phone.value : ""}
                                                                            onChange={(event) => this.inputChangeHandler(event, "father_work_phone")}
                                                                            enableLongNumbers={true}

                                                                        />
                                                                    </div>



                                                                    <div className="col-lg-12">
                                                                        <div className="d-flex justify-content-between gap-5">
                                                                            <div className="d-flex align-items-center gap-10">
                                                                                <h6 className="mb-0">Mother Address  </h6>
                                                                                <label className="fw-bold d-flex align-items-center">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="form-check-input ms-4 me-2"
                                                                                        value={this.state.address_same_as_father.value}
                                                                                        checked={this.state.address_same_as_father.isChecked}
                                                                                        onChange={(event) => this.inputParentCheckbox(event, "address_same_as_father")}
                                                                                    />
                                                                                    If Mother Address are same as father
                                                                                </label>
                                                                            </div>

                                                                            {/* <div className="d-flex align-items-center">
                                                                                <label className="fw-bold d-flex align-items-center">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="form-check-input ms-4"
                                                                                        value={this.state.mother_as_host.value}
                                                                                        checked={this.state.mother_as_host.isChecked ? true : false}
                                                                                        onChange={(event) => this.inputParentCheckbox(event, "mother_as_host")}
                                                                                        autoComplete="off"
                                                                                    />
                                                                                    Automatically Add Mother as Host
                                                                                </label>
                                                                            </div> */}
                                                                        </div>
                                                                        <hr />
                                                                    </div>



                                                                    {this.state.address_same_as_father.value === false ?
                                                                        <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                            <label className="fw-bold mb-2">{edit_student_page.parent_address_data[1].address_line1.label}</label>
                                                                            <input
                                                                                type={edit_student_page.parent_address_data[1].address_line1.type}
                                                                                className="form-control"
                                                                                placeholder={edit_student_page.parent_address_data[1].address_line1.placeholder}
                                                                                value={edit_student_page.parent_address_data[1].address_line1.value ? edit_student_page.parent_address_data[1].address_line1.value : ""}
                                                                                onChange={(event) => this.inputChangeCountry(event, 1, "address_line1")}
                                                                                autoComplete="off"
                                                                            />
                                                                            {!edit_student_page.parent_address_data[1].address_line1.valid && edit_student_page.parent_address_data[1].address_line1.onBlur_out ?
                                                                                <div className="error field-error">
                                                                                    {edit_student_page.parent_address_data[1].address_line1.error_msg}
                                                                                </div> :
                                                                                ""
                                                                            }
                                                                        </div>
                                                                        :
                                                                        <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                            <label className="fw-bold mb-2">{edit_student_page.parent_address_data[0].address_line1.label}</label>
                                                                            <input
                                                                                type={edit_student_page.parent_address_data[1].address_line1.type}
                                                                                className="form-control"
                                                                                placeholder={edit_student_page.parent_address_data[1].address_line1.placeholder}
                                                                                value={edit_student_page.parent_address_data[1].address_line1.value ? edit_student_page.parent_address_data[1].address_line1.value : ""}
                                                                                autoComplete="off"
                                                                            />
                                                                            {!edit_student_page.parent_address_data[1].address_line1.valid && edit_student_page.parent_address_data[1].address_line1.onBlur_out ?
                                                                                <div className="error field-error">
                                                                                    {edit_student_page.parent_address_data[1].address_line1.error_msg}
                                                                                </div> :
                                                                                ""
                                                                            }
                                                                        </div>
                                                                    }

                                                                    {/*============================== mother address_line 2 ============================= */}


                                                                    {this.state.address_same_as_father.value === false ?
                                                                        <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                            <label className="fw-bold mb-2">{edit_student_page.parent_address_data[1].address_line2.label}</label>
                                                                            <input
                                                                                type={edit_student_page.parent_address_data[1].address_line2.type}
                                                                                className="form-control"
                                                                                placeholder={edit_student_page.parent_address_data[1].address_line2.placeholder}
                                                                                value={edit_student_page.parent_address_data[1].address_line2.value ? edit_student_page.parent_address_data[1].address_line2.value : ""}
                                                                                onChange={(event) => this.inputChangeCountry(event, 1, "address_line2")}
                                                                                autoComplete="off"
                                                                            />
                                                                            {!edit_student_page.parent_address_data[1].address_line2.valid && edit_student_page.parent_address_data[1].address_line2.onBlur_out ?
                                                                                <div className="error field-error">
                                                                                    {edit_student_page.parent_address_data[1].address_line2.error_msg}
                                                                                </div> :
                                                                                ""
                                                                            }
                                                                        </div>
                                                                        :
                                                                        <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                            <label className="fw-bold mb-2">{edit_student_page.parent_address_data[1].address_line2.label}</label>
                                                                            <input
                                                                                type={edit_student_page.parent_address_data[1].address_line2.type}
                                                                                className="form-control"
                                                                                placeholder={edit_student_page.parent_address_data[1].address_line2.placeholder}
                                                                                value={edit_student_page.parent_address_data[1].address_line2.value ? edit_student_page.parent_address_data[1].address_line2.value : ""}
                                                                                autoComplete="off"
                                                                            />
                                                                            {!edit_student_page.parent_address_data[1].address_line2.valid && edit_student_page.parent_address_data[1].address_line2.onBlur_out ?
                                                                                <div className="error field-error">
                                                                                    {edit_student_page.parent_address_data[1].address_line2.error_msg}
                                                                                </div> :
                                                                                ""
                                                                            }
                                                                        </div>
                                                                    }

                                                                    {/*============================== mother countary ============================= */}

                                                                    {this.state.address_same_as_father.value === false ?
                                                                        <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                            <label className="fw-bold mb-2">{edit_student_page.parent_address_data[1].parent_country.label}</label>
                                                                            <Select
                                                                                styles={customStyles}
                                                                                className="react-bootstrap-typeahead tag-outer"
                                                                                options={edit_student_page.parent_address_data[1].parent_country.options}
                                                                                value={edit_student_page.parent_address_data[1].parent_country.options.find(option => option.label.toLowerCase() === edit_student_page.parent_address_data[1].parent_country.value.toLowerCase())}
                                                                                onChange={(event) => this.handleChangeCountry(event, 1, "parent_country")}
                                                                            />
                                                                            {!edit_student_page.parent_address_data[1].parent_country.valid && edit_student_page.parent_address_data[1].parent_country.onBlur_out ?
                                                                                <div className="error field-error">
                                                                                    {edit_student_page.parent_address_data[1].parent_country.error_msg}
                                                                                </div> :
                                                                                ""
                                                                            }
                                                                        </div> :
                                                                        <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                            <label className="fw-bold mb-2">{edit_student_page.parent_address_data[1].parent_country.label}</label>
                                                                            <Select
                                                                                styles={customStyles}
                                                                                className="react-bootstrap-typeahead tag-outer"
                                                                                options={edit_student_page.parent_address_data[0].parent_country.options}
                                                                                value={edit_student_page.parent_address_data[0].parent_country.options.find(option => option.label.toLowerCase() === edit_student_page.parent_address_data[1].parent_country.value.toLowerCase())}

                                                                            />
                                                                            {!edit_student_page.parent_address_data[1].parent_country.valid && edit_student_page.parent_address_data[1].parent_country.onBlur_out ?
                                                                                <div className="error field-error">
                                                                                    {edit_student_page.parent_address_data[1].parent_country.error_msg}
                                                                                </div> :
                                                                                ""
                                                                            }
                                                                        </div>
                                                                    }


                                                                    {/*============================== mother state ============================= */}


                                                                    {this.state.address_same_as_father.value === false ?
                                                                        <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                            <label className="fw-bold mb-2">{edit_student_page.parent_address_data[1].parent_state.label}</label>
                                                                            <Select
                                                                                styles={customStyles}
                                                                                className="react-bootstrap-typeahead tag-outer"
                                                                                options={edit_student_page.parent_address_data[1].parent_state.options}
                                                                                value={edit_student_page.parent_address_data[1].parent_state.options.find(option => option.label.toLowerCase() === edit_student_page.parent_address_data[1].parent_state.value.toLowerCase())}
                                                                                onChange={(event) => this.handleChangeCountry(event, 1, "parent_state")}
                                                                                autoComplete="off"
                                                                            />
                                                                        </div> :
                                                                        <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                            <label className="fw-bold mb-2">{edit_student_page.parent_address_data[1].parent_state.label}</label>
                                                                            <Select
                                                                                styles={customStyles}
                                                                                className="react-bootstrap-typeahead tag-outer"
                                                                                options={edit_student_page.parent_address_data[1].parent_state.options}
                                                                                value={edit_student_page.parent_address_data[1].parent_state.options.find(option => option.label.toLowerCase() === edit_student_page.parent_address_data[1].parent_state.value.toLowerCase())}
                                                                                autoComplete="off"
                                                                            />
                                                                        </div>
                                                                    }

                                                                    {/*============================== mother city ============================= */}

                                                                    {console.log("dddddddddddddddddddddddddddd",edit_student_page.parent_address_data)}

                                                                    {this.state.address_same_as_father.value === false ?
                                                                        <div className="col-lg-2  col-xl fv-row mb-5">
                                                                            <label className="fw-bold mb-2">{edit_student_page.parent_address_data[1].parent_city.label}</label>
                                                                            <Select
                                                                                styles={customStyles}
                                                                                className="react-bootstrap-typeahead tag-outer"
                                                                                options={edit_student_page.parent_address_data[1].parent_city.options}
                                                                                value={edit_student_page.parent_address_data[1].parent_city.options.find(option => option.label.toLowerCase() === edit_student_page.parent_address_data[1].parent_city.value.toLowerCase())}
                                                                                onChange={(event) => this.handleChangeCountry(event, 1, "parent_city")}
                                                                                autoComplete="off"
                                                                            />
                                                                        </div> 
                                                                        :
                                                                        <div className="col-lg-2  col-xl fv-row mb-5">
                                                                            <label className="fw-bold mb-2">{edit_student_page.parent_address_data[1].parent_city.label}</label>
                                                                            <Select
                                                                                styles={customStyles}
                                                                                className="react-bootstrap-typeahead tag-outer"
                                                                                options={edit_student_page.parent_address_data[1].parent_city.options}
                                                                                value={edit_student_page.parent_address_data[1].parent_city.options.find(option => option.label.toLowerCase() === edit_student_page.parent_address_data[1].parent_city.value.toLowerCase())}
                                                                                autoComplete="off"
                                                                            />
                                                                        </div>
                                                                    }

                                                                    {/*============================== postcode ============================= */}



                                                                    {this.state.address_same_as_father.value === false ?
                                                                        <div className="col-lg-2  col-xl fv-row mb-5">
                                                                            <label className="fw-bold mb-2">{edit_student_page.parent_address_data[1].parent_postcode.label}</label>
                                                                            <input
                                                                                type={edit_student_page.parent_address_data[1].parent_postcode.type}
                                                                                className="form-control"
                                                                                placeholder={edit_student_page.parent_address_data[1].parent_postcode.placeholder}
                                                                                value={edit_student_page.parent_address_data[1].parent_postcode.value ? edit_student_page.parent_address_data[1].parent_postcode.value : ""}
                                                                                onChange={(event) => this.inputChangeCountry(event, 1, "parent_postcode")}
                                                                                autoComplete="off"
                                                                            />
                                                                            {!edit_student_page.parent_address_data[1].parent_postcode.valid && parent_address_data[1].parent_postcode.onBlur_out ?
                                                                                <div className="error field-error">
                                                                                    {parent_address_data[1].parent_postcode.error_msg}
                                                                                </div> :
                                                                                ""
                                                                            }
                                                                        </div> :
                                                                        <div className="col-lg-2  col-xl fv-row mb-5">
                                                                            <label className="fw-bold mb-2">{edit_student_page.parent_address_data[1].parent_postcode.label}</label>
                                                                            <input
                                                                                type={edit_student_page.parent_address_data[1].parent_postcode.type}
                                                                                className="form-control"
                                                                                placeholder={edit_student_page.parent_address_data[1].parent_postcode.placeholder}
                                                                                value={edit_student_page.parent_address_data[1].parent_postcode.value ? edit_student_page.parent_address_data[1].parent_postcode.value : ""}
                                                                                autoComplete="off"
                                                                            />
                                                                            {!edit_student_page.parent_address_data[1].parent_postcode.valid && parent_address_data[1].parent_postcode.onBlur_out ?
                                                                                <div className="error field-error">
                                                                                    {parent_address_data[1].parent_postcode.error_msg}
                                                                                </div> :
                                                                                ""
                                                                            }
                                                                        </div>
                                                                    }


                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.mother_phone.label}</label>
                                                                        <ReactPhoneInput
                                                                            // country={edit_student_page.country_code.value ? edit_student_page.country_code.value : ""}
                                                                            value={edit_student_page.mother_phone.value ? edit_student_page.mother_phone.value : ""}
                                                                            onChange={(event) => this.inputChangeHandler(event, "mother_phone")}
                                                                            enableLongNumbers={true}

                                                                        />
                                                                        {!edit_student_page.mother_phone.valid && edit_student_page.mother_phone.onBlur_out ? (
                                                                            <div className="error field-error">{edit_student_page.mother_phone.error_msg}</div>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>


                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.mother_home_phone.label}</label>
                                                                        <ReactPhoneInput
                                                                            // country={edit_student_page.country_code.value ? edit_student_page.country_code.value : ""}
                                                                            value={edit_student_page.mother_home_phone.value ? edit_student_page.mother_home_phone.value : ""}
                                                                            onChange={(event) => this.inputChangeHandler(event, "mother_home_phone")}
                                                                            enableLongNumbers={true}

                                                                        />
                                                                    </div>


                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.mother_work_phone.label}</label>
                                                                        <ReactPhoneInput
                                                                            // country={edit_student_page.country_code.value ? edit_student_page.country_code.value : ""}
                                                                            value={edit_student_page.mother_work_phone.value ? edit_student_page.mother_work_phone.value : ""}
                                                                            onChange={(event) => this.inputChangeHandler(event, "mother_work_phone")}
                                                                            enableLongNumbers={true}

                                                                        />
                                                                    </div>

                                                                    <div className="col-lg-12 fv-row mb-10">
                                                                        <label className="fw-bold mb-2">{edit_student_page.salutation.label}</label>
                                                                        <input
                                                                            type={edit_student_page.salutation.type}
                                                                            className="form-control"
                                                                            placeholder={edit_student_page.salutation.placeholder}
                                                                            onChange={(event) => this.inputChangeHandler(event, "salutation")}
                                                                            value={edit_student_page.salutation.value ? edit_student_page.salutation.value : ""}
                                                                        />

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="col-sm-12 d-flex justify-content-end">
                                                            <div className="d-flex align-items-end">
                                                                <button type="submit" className="btn btn-sm btn-primary apply-btn min-150px">
                                                                    Update Parent Detail
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </form>
                                                </div>

                                                <div className="tab-pane fade" id="HostDetail" role="tabpanel" aria-labelledby="HostDetail-tab">
                                                    <form onSubmit={this.SubmitHandlerHost}>
                                                        {
                                                            host_data?.map((item, index) => {
                                                                return (
                                                                    <div className="card mb-5 tabs-outer">
                                                                        <div className="card-header position-relative py-0 details-bg d-flex align-items-center">
                                                                            <h2 className="card-title  m-0">
                                                                                {this?.state.edit_student_page?.host_data?.length > 1 ? `Host Details ${index + 1}` : `Host Details`}
                                                                            </h2>
                                                                            <div className="d-flex align-items-center">
                                                                                {item?.host_status?.value === true ? (
                                                                                    <span className="badge badge-light-success px-4 py-2">Active</span>
                                                                                ) : (
                                                                                    <span className="badge badge-light-danger px-4 py-2">Inactive</span>
                                                                                )}

                                                                            </div>
                                                                        </div>



                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{item.host_name.label}</label>
                                                                                    <input
                                                                                        type={item.host_name.type}
                                                                                        className="form-control"
                                                                                        placeholder={item.host_name.placeholder}
                                                                                        value={item.host_name.value ? item.host_name.value : ""}
                                                                                        onChange={(event) => this.inputChangeHost(event, index, "host_name")}
                                                                                    />
                                                                                    {!item.host_name.valid && item.host_name.onBlur_out ? <div className="error field-error">{item.host_name.error_msg}</div> : ""}
                                                                                </div>

                                                                                <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{item.host_relation.label}</label>
                                                                                    <input
                                                                                        type={item.host_relation.type}
                                                                                        className="form-control"
                                                                                        placeholder={item.host_relation.placeholder}
                                                                                        value={item.host_relation.value ? item.host_relation.value : ""}
                                                                                        onChange={(event) => this.inputChangeHost(event, index, "host_relation")}
                                                                                    />
                                                                                    {!item.host_relation.valid && item.host_relation.onBlur_out ? <div className="error field-error">{item.host_relation.error_msg}</div> : ""}
                                                                                </div>

                                                                                <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{item.host_email.label}</label>
                                                                                    <input
                                                                                        type={item.host_email.type}
                                                                                        className="form-control"
                                                                                        placeholder={item.host_email.placeholder}
                                                                                        value={item.host_email.value ? item.host_email.value : ""}
                                                                                        onChange={(event) => this.inputChangeHost(event, index, "host_email")}
                                                                                    />
                                                                                    {!item.host_email.valid && item.host_email.onBlur_out ? <div className="error field-error">{item.host_email.error_msg}</div> : ""}
                                                                                </div>

                                                                                <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{item.host_contact.label}</label>
                                                                                    <ReactPhoneInput
                                                                                        style={{ padding: "0" }}
                                                                                        // country={edit_student_page.country_code.value ? edit_student_page.country_code.value : ""}
                                                                                        className="form-control"
                                                                                        placeholder={item.host_contact.placeholder}
                                                                                        value={item.host_contact.value ? item.host_contact.value : ""}
                                                                                        onChange={(event) => this.inputChangeHostContect(event, index, "host_contact")}
                                                                                        enableLongNumbers={true}
                                                                                    >
                                                                                    </ReactPhoneInput>
                                                                                    {!item.host_contact.valid && item.host_contact.onBlur_out ? <div className="error field-error">{item.host_contact.error_msg}</div> : ""}
                                                                                </div>

                                                                                <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{item.host_address.label}</label>
                                                                                    <input
                                                                                        type={item.host_address.type}
                                                                                        className="form-control"
                                                                                        placeholder={item.host_address.placeholder}
                                                                                        value={item.host_address.value ? item.host_address.value : ""}
                                                                                        onChange={(event) => this.inputChangeHost(event, index, "host_address")}
                                                                                    />
                                                                                    {!item.host_address.valid && item.host_address.onBlur_out ? <div className="error field-error">{item.host_address.error_msg}</div> : ""}
                                                                                </div>

                                                                                <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{item.remark_parents.label}</label>
                                                                                    <input
                                                                                        type={item.remark_parents.type}
                                                                                        className="form-control"
                                                                                        placeholder={item.remark_parents.placeholder}
                                                                                        value={item?.remark_parents.value ? item.remark_parents.value : ""}
                                                                                        onChange={(event) => this.inputChangeHost(event, index, "remark_parents")}
                                                                                    />
                                                                                    {!item.remark_parents.valid && item.remark_parents.onBlur_out ? <div className="error field-error">{item.remark_parents.error_msg}</div> : ""}
                                                                                </div>

                                                                                <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{item.remark_boarding.label}</label>
                                                                                    <input
                                                                                        type={item.remark_boarding.type}
                                                                                        className="form-control"
                                                                                        placeholder={item.remark_boarding.placeholder}
                                                                                        value={item?.remark_boarding.value ? item.remark_boarding.value : ""}
                                                                                        onChange={(event) => this.inputChangeHost(event, index, "remark_boarding")}
                                                                                    />
                                                                                    {!item.remark_boarding.valid && item.remark_boarding.onBlur_out ? <div className="error field-error">{item.remark_boarding.error_msg}</div> : ""}
                                                                                </div>

                                                                                <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{item.remark_host.label}</label>
                                                                                    <input
                                                                                        type={item.remark_host.type}
                                                                                        className="form-control"
                                                                                        placeholder={item.remark_host.placeholder}
                                                                                        value={item?.remark_host.value ? item.remark_host.value : ""}
                                                                                        onChange={(event) => this.inputChangeHost(event, index, "remark_host")}
                                                                                    />
                                                                                    {!item.remark_host.valid && item.remark_host.onBlur_out ? <div className="error field-error">{item.remark_host.error_msg}</div> : ""}
                                                                                </div>

                                                                                {item.student_host_comment === null ? "" :
                                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                                        <label className="fw-bold mb-2">{item.student_host_comment.label}</label>
                                                                                        <input
                                                                                            type={item.student_host_comment.type}
                                                                                            className="form-control"
                                                                                            placeholder={item.student_host_comment.placeholder}
                                                                                            value={item.student_host_comment.value ? item.student_host_comment.value : ""}
                                                                                            onChange={(event) => this.inputChangeHost(event, index, "student_host_comment")}
                                                                                        />
                                                                                        {!item.student_host_comment.valid && item.student_host_comment.onBlur_out ? <div className="error field-error">{item.student_host_comment.error_msg}</div> : ""}
                                                                                    </div>
                                                                                }
                                                                                {item.id ?
                                                                                    <div className="col-lg-6 fv-row d-flex align-items-center">
                                                                                        <label className="fw-bold">Automatically Approved As Host
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                className="form-check-input ms-4"
                                                                                                value={item?.is_host_approved.value}
                                                                                                defaultChecked={item?.is_host_approved?.value}
                                                                                                onChange={(event) => this.inputChangehostCheckbox(event, index, "checkbox")}
                                                                                            />
                                                                                        </label>
                                                                                        {!item.is_host_approved.valid && item.is_host_approvedt.onBlur_out ? <div className="error field-error">{item.is_host_approved.error_msg}</div> : ""}
                                                                                    </div> : ""
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }



                                                        <div className="col-sm-12 d-flex justify-content-end">
                                                            <div className="d-flex align-items-end">
                                                                <button className="btn btn-outline-dark btn-sm me-4"
                                                                    onClick={(i) => this.clickAnotherHost()}
                                                                >
                                                                    Add Another Host
                                                                </button>
                                                                <button type="submit" className="btn btn-sm btn-primary apply-btn min-150px">
                                                                    Update Host Details
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div >
                                </div >
                                <Footer />
                            </div >

                        </div >
                    </div >
                </div>

            </React.Fragment >
        );
    }
}


function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}
const mapDispatchToProps = {


    onGetAllDormitoryStudent: userActions.GetAllDormitoryStudent,
    onGetStudentAndParentById: userActions.GetStudentAndParentById,
    onUpdateStudentPersonal: userActions.UpdateStudentPersonal,
    onUpdateParentPersonal: userActions.UpdateParentPersonal,
    onGetAllClassesList: userActions.GetAllClassesList,
    onDeleteStudentAllergyById: userActions.DeleteStudentAllergyById,
    onAddOrUpdateStudentHost: userActions.AddOrUpdateStudentHost,
    onGetAllSelectedCountries: userActions.GetAllSelectedCountries,
    onRemoveParentAddress: userActions.RemoveParentAddress,
    onGetStudentHostByHostEmail: userActions.GetStudentHostByHostEmail,
    onGetStudentHostByHostContact: userActions.GetStudentHostByHostContact,
    onResendStudentAndParentsPassword: userActions.ResendStudentAndParentsPassword,
    onGetAllCountriesForImportStudents: userActions.GetAllCountriesForImportStudents,
    onGetAllStatesByCountryId: userActions.GetAllStatesByCountryId,
    onGetAllCitiesByStateId: userActions.GetAllCitiesByStateId,

};
export default connect(mapStateToProps, mapDispatchToProps)(EditStudent);
