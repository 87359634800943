import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import * as actionType from "../../constants/actionTypes";


const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info.school_code;

function withParams(Component) {
    return props => <Component {...props} location={useLocation()} />;
}

class Sidebar extends React.Component {
    render() {
        const { location } = this.props;
        const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
        const permission_info = JSON.parse(localStorage.getItem("permissions"));
        const user_rollcall = JSON.parse(localStorage.getItem("bss_rollcall")) ? JSON.parse(localStorage.getItem("bss_rollcall")) : "";
        const { school_code } = user_info
        const currentPath = location.pathname === `/${school_code}/rollcall-report` ? `/${school_code}/rollcall-report` : location.pathname === `/${school_code}/conduct-rollcall` ? `/${school_code}/conduct-rollcall` : `/${school_code}/snapshot`;
        return (
            <>
                <div
                    id="kt_aside"
                    className="aside aside-light aside-hoverable aside-minimize"
                    data-kt-drawer="true"
                    data-kt-drawer-name="aside"
                    data-kt-drawer-activate="{default: true, lg: false}"
                    data-kt-drawer-overlay="true"
                    data-kt-drawer-width="{default:'200px', '300px': '250px'}"
                    data-kt-drawer-direction="start"
                    data-kt-drawer-toggle="#kt_aside_mobile_toggle"
                    style={{ overflow: "scroll" }}
                >
                    <div className="aside-logo flex-column-auto" id="kt_aside_logo">
                        <Link to={`/${school_code}/dashboard`}>
                            {/* <img alt="Logo" src="/assets/media/logos/logo.png" className="h-50px logo" /> */}
                            <img alt="Logo" src="/assets/media/logos/TRIANGLE PNG (WOP) (2).png" className="h-50px logo" />
                        </Link>
                        <div className="cursor-pointer symbol symbol-30px symbol-md-40px" data-kt-menu-placement="bottom-end">
                            <img alt="Logo" src={user_info?.user_school?.school_logo ? actionType?.environment_url + '/' + user_info?.user_school?.school_logo : "/assets/media/school-logo-2.png"} />
                        </div>
                        <div className="menu-item menu-accordion">
                            <div className="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle" data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body" data-kt-toggle-name="aside-minimize">
                                <span className="svg-icon svg-icon-1 rotate-180">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path
                                            opacity="0.5"
                                            d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z"
                                            fill="black"
                                        />

                                        <path
                                            d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z"
                                            fill="black"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div id="sidebarscrole" className="aside-menu flex-column-fluid sidebarscrole">
                        <div
                            className="hover-scroll-overlay-y mb-5 mb-lg-5"
                            id="kt_aside_menu_wrapper"
                            data-kt-scroll="true"
                            data-kt-scroll-activate="{default: true, lg: false}"
                            data-kt-scroll-height="auto"
                            data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
                            data-kt-scroll-wrappers="#kt_aside_menu"
                            data-kt-scroll-offset="0"
                        >
                            <div
                                className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
                                id="#kt_aside_menu"
                                data-kt-menu="true"
                                data-kt-menu-expand="false"
                            >
                                <div className="menu-item">
                                    <NavLink activeclassname="menu-active" to={`/${school_code}/dashboard`} className="menu-link">
                                        <span className="menu-icon">
                                            <img src="/media/Dashboard.png" alt="" className="max-w-22"/>
                                            {/* <span className="svg-icon svg-icon-2">
                                                <i className="bi bi-columns-gap"></i>
                                            </span> */}
                                        </span>
                                        <span className="menu-title">Dashboard</span>
                                    </NavLink>
                                </div>

                                {permission_info?.view_snapshot !== false && <div className="menu-item">
                                    <NavLink activeclassname="menu-active" to={currentPath} className="menu-link">
                                        <span className="menu-icon">
                                            <img src="/media/snapshot.png" alt="" className="max-w-22"/>
                                            {/* <span className="svg-icon svg-icon-2"> */}
                                                {/* <i className="fas fa-images"></i> */}
                                            {/* </span> */}
                                        </span>
                                        <span className="menu-title">Snapshot</span>
                                    </NavLink>
                                </div>}

                                {permission_info?.view_school_profile !== false && <div className="menu-item">
                                    <NavLink activeclassname="menu-active" to={`/${school_code}/school/${user_info?.user_school?.school_detail_uuid}`} className="menu-link">
                                        <span className="menu-icon">
                                        <img src="/media/school.png" alt="" className="max-w-22"/>
                                            {/* <i className="fas fa-building"></i>  */}
                                        </span>
                                        <span className="menu-title">School</span>
                                    </NavLink>
                                </div>}

                                {permission_info?.view_user !== false && (<div className="menu-item">
                                    <NavLink activeclassname="menu-active" to={`/${school_code}/manager-list`} className="menu-link">
                                        <span className="menu-icon">
                                            <i className="fas fa-user-friends" style={{filter: "brightness(0.7)"}}></i>
                                        </span>
                                        <span className="menu-title">Managers</span>
                                    </NavLink>
                                </div>)}

                                {permission_info?.view_dormitory !== false && (<div className="menu-item">
                                    <NavLink activeclassname="menu-active" to={`/${school_code}/dormitory-list`} className="menu-link">
                                        <span className="menu-icon">
                                        <img src="/media/Dormitories.png" alt="" className="max-w-22"/>
                                            {/* <i className="fas fa-building"></i> */}
                                        </span>
                                        <span className="menu-title">Dormitories</span>
                                    </NavLink>
                                </div>)}

                                {permission_info?.view_class !== false && (<div className="menu-item">
                                    <NavLink activeclassname="menu-active" to={`/${school_code}/class-list`} className="menu-link">
                                        <span className="menu-icon">
                                            <img src="/media/Year.png" alt="" className="max-w-22"/>
                                            {/* <i className="bi bi-person-lines-fill"></i> */}
                                        </span>
                                        <span className="menu-title">Year</span>
                                    </NavLink>
                                </div>)}

                                {permission_info?.view_permission !== false && (<div className="menu-item">
                                    <NavLink activeclassname="menu-active" to={`/${school_code}/permissions-list`} className="menu-link">
                                        <span className="menu-icon">
                                            <img src="/media/Role-and-Permision.png" alt="" className="max-w-22"/>
                                            {/* <i className="fas fa-user-circle"></i> */}
                                        </span>
                                        <span className="menu-title">Roles & Permissions</span>
                                    </NavLink>
                                </div>)}

                                {console.log("permission============", permission_info)}


                                {permission_info?.view_activities !== false && (<div className="menu-item">
                                    <NavLink activeclassname="menu-active" to={`/${school_code}/activities`} className="menu-link">
                                        <span className="menu-icon">
                                            <img src="/media/activities.png" alt="" className="max-w-22"/>
                                            {/* <i class="fas fa-chart-line"></i> */}
                                            {/* <i class="fas fa-heartbeat"></i> */}
                                        </span>
                                        <span className="menu-title">Activities</span>
                                    </NavLink>
                                </div>)}




                                <div id="students" data-kt-menu-trigger="click"
                                    className={
                                        "menu-item menu-accordion pagenav" +
                                        (location && location.pathname && (location.pathname === `/${school_code}/student/student-list` || location.pathname === `/${school_code}/student/student-list/`
                                            || location.pathname === `/${school_code}/student/add-student` || location.pathname === `/${school_code}/student/add-student/1`
                                            || location.pathname === `/${school_code}/student/allergic-student` || location.pathname === `/${school_code}/student/allergic-student/`
                                            || location.pathname === `/${school_code}/student/flag-student` || location.pathname === `/${school_code}/student/flag-student/`
                                            || location.pathname === `/${school_code}/student/grounded-student` || location.pathname === `/${school_code}/student/grounded-student/`
                                            || location.pathname === `/${school_code}/student/import-student` || location.pathname === `/${school_code}/student/import-student/`
                                            || location.pathname === `/${school_code}/student/duplicate-student` || location.pathname === `/${school_code}/student/duplicate-student/`
                                            || location.pathname === `/${school_code}/student/change-dormitory` || location.pathname === `/${school_code}/student/change-dormitory/`
                                        ) ? " active-menu" : "")
                                    }
                                >
                                    <span className="menu-link py-3 main-menu">
                                        <span className="menu-icon">
                                        <img src="/media/Students.png" alt="" className="max-w-22" style={{filter: "brightness(0.5)"}}/>
                                            {/* <i className="far fa-user"></i> */}
                                        </span>
                                        <span className="menu-title">Students</span>
                                        <span className="menu-arrow"></span>
                                    </span>
                                    {console.log("permission_info=========================", permission_info)}
                                    <div className="menu-sub menu-sub-accordion menu-active-bg">
                                        <div className="menu-item menu-accordion">
                                            {permission_info?.view_student !== false &&
                                                (<NavLink activeclassname="menu-active" className="menu-link" to={`/${school_code}/student/student-list`} >
                                                    <span className="menu-bullet" style={{filter: "brightness(0.5)"}}>
                                                        <i className="fas fa-list"></i>
                                                    </span>
                                                    <span className="menu-title">Student List</span>
                                                </NavLink>)
                                            }

                                            {permission_info?.add_student !== false &&
                                                (<NavLink activeclassname="menu-active" className="menu-link" to={`/${school_code}/student/add-student`} >
                                                    <span className="menu-bullet" style={{filter: "brightness(0.5)"}}>
                                                        <i className="fas fa-plus"></i>
                                                    </span>
                                                    <span className="menu-title">Add Student</span>
                                                </NavLink>)
                                            }


                                            {permission_info?.view_grounded_students !== false &&
                                                (<NavLink activeclassname="menu-active" className="menu-link" to={`/${school_code}/student/grounded-student`} >
                                                    <span className="menu-bullet">
                                                        <img style={{ filter: "brightness(0.1)" }} src="/media/anchor.png" alt="" />
                                                        {/* <i className="fas fa-radiation"></i> */}
                                                    </span>
                                                    <span className="menu-title">Grounded Students</span>
                                                </NavLink>)
                                            }
                                            {permission_info?.view_allergic_students !== false &&
                                                (<NavLink activeclassname="menu-active" className="menu-link" to={`/${school_code}/student/allergic-student`}>
                                                    <span className="">
                                                        <img src="/media/Students_Allergic Students.png" alt="" className="max-w-22"/>
                                                        {/* <i className="fas fa-allergies"></i> */}
                                                    </span>
                                                    <span className="menu-title">Allergic Students</span>
                                                </NavLink>)
                                            }

                                            {permission_info?.view_flag_students !== false &&
                                                (<NavLink activeclassname="menu-active" className="menu-link" to={`/${school_code}/student/flag-student`} >
                                                    <span className="">
                                                        <img src="/media/Students Student-Reminder.png" style={{filter: "brightness(0.5)"}} alt="" className="max-w-22"/>
                                                        {/* <i className="fas fa-comment-dots"></i> */}
                                                    </span>
                                                    <span className="menu-title">Student Reminder</span>
                                                </NavLink>)
                                            }


                                            {permission_info?.view_import_students !== false &&
                                                (<NavLink activeclassname="menu-active" className="menu-link" to={`/${school_code}/student/import-student`} >
                                                    <span className="menu-bullet" style={{filter: "brightness(0.5)"}}>
                                                        <i className="fas fa-file-import"></i>
                                                    </span>
                                                    <span className="menu-title">Import Students</span>
                                                </NavLink>)
                                            }

                                            {permission_info?.view_duplicate_students !== false &&
                                                (<NavLink activeclassname="menu-active" className="menu-link" to={`/${school_code}/student/duplicate-student`} >
                                                    <span className="menu-bullet" style={{filter: "brightness(0.5)"}}>
                                                        <i class="far fa-copy"></i>
                                                    </span>
                                                    <span className="menu-title">Duplicate Students</span>
                                                </NavLink>)
                                            }

                                            {permission_info?.view_change_dormitory !== false &&
                                                (<NavLink activeclassname="menu-active" className="menu-link" to={`/${school_code}/student/change-dormitory`} >
                                                    <span className="">
                                                        <img src="/media/Students Change Dormitory.jpg" alt="" className="max-w-22"/>
                                                        {/* <i className="fas fa-person-booth"></i> */}
                                                    </span>
                                                    <span className="menu-title">Change Dormitory</span>
                                                </NavLink>)
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div id="settings" data-kt-menu-trigger="click"
                                    className={
                                        "menu-item menu-accordion pagenav" +
                                        (location && location.pathname && (location.pathname === `/${school_code}/settings/reports` || location.pathname === `/${school_code}/settings/reports/` 
                                            // || location.pathname === `${school_code}/settings/messages` || location.pathname === `${school_code}/settings/messages/`
                                            || location.pathname === `/${school_code}/settings/custom-link` || location.pathname === `/${school_code}/settings/custom-link/`
                                            || location.pathname === `/${school_code}/settings/rollover-student` || location.pathname === `/${school_code}/settings/rollover-student/`
                                            || location.pathname === `/${school_code}/settings/roll-call` || location.pathname === `/${school_code}/settings/roll-call/`
                                            || location.pathname === `/${school_code}/settings/on-campus-location` || location.pathname === `/${school_code}/settings/on-campus-location/`
                                            || location.pathname === `/${school_code}/settings/calendar-holidays` || location.pathname === `/${school_code}/settings/calendar-holidays/`

                                        ) ? " active-menu" : "")
                                    }
                                >
                                    <span className="menu-link py-3 main-menu">
                                        <span className="menu-icon">
                                            <i className="fas fa-cog" style={{filter: "brightness(0.6)"}}></i>
                                        </span>
                                        <span className="menu-title">Settings</span>
                                        <span className="menu-arrow"></span>
                                    </span>

                                    <div className="menu-sub menu-sub-accordion menu-active-bg">
                                        <div className="menu-item menu-accordion">
                                            {permission_info?.view_attendance_report !== false &&
                                                (<NavLink activeclassname="menu-active" to={`/${school_code}/settings/reports`} className="menu-link">
                                                    <span className="menu-icon">
                                                        <img src="/media/Reports-will-change-to-say-Charts1.png" style={{filter: "brightness(0.5)"}} alt="" className="max-w-22"/>
                                                        {/* <i className="fas fa-poll"></i> */}
                                                    </span>
                                                    <span className="menu-title">Charts</span>
                                                </NavLink>)}

                                            {/* {permission_info?.view_message !== false &&
                                                (<NavLink activeclassname="menu-active" to={`/${school_code}/settings/messages`} className="menu-link">
                                                    <span className="menu-icon">
                                                        <i className="fas fa-envelope"></i>
                                                    </span>
                                                    <span className="menu-title">Messages</span>
                                                </NavLink>)} */}

                                            {permission_info?.view_custom_links !== false &&
                                                (<NavLink activeclassname="menu-active" to={`/${school_code}/settings/custom-link`} className="menu-link">
                                                    <span className="menu-icon" style={{filter: "brightness(0.5)"}}>
                                                        <i className="fas fa-link"></i>
                                                    </span>
                                                    <span className="menu-title">Custom Links</span>
                                                </NavLink>)}

                                            {permission_info?.view_rollover_students !== false &&
                                                (<NavLink activeclassname="menu-active" className="menu-link" to={`/${school_code}/settings/rollover-student`} >
                                                    <span className="menu-bullet" style={{filter: "brightness(0.5)"}}>
                                                        <i className="fas fa-step-forward"></i>
                                                    </span>
                                                    <span className="menu-title">Rollover Students</span>
                                                </NavLink>)}

                                            {permission_info?.view_rollcall_session_time !== false &&
                                                (<NavLink activeclassname="menu-active" className="menu-link" to={`/${school_code}${user_rollcall.master_setting_uuid ? "/settings/roll-call/" + user_rollcall.master_setting_uuid : "/settings/roll-call"}`}>
                                                    <span className="menu-bullet" style={{filter: "brightness(0.5)"}}>
                                                        <i className="fas fa-clock"></i>
                                                    </span>
                                                    <span className="menu-title">Roll Call Session Duration
                                                    </span>
                                                </NavLink>)}

                                            {permission_info?.view_oncampus_details !== false &&
                                                (<NavLink activeclassname="menu-active" className="menu-link" to={`/${school_code}/settings/on-campus-location`}>
                                                    <span className="menu-bullet" style={{filter: "brightness(0.5)"}}>
                                                        <i className="fas fa-location-arrow"></i>
                                                    </span>
                                                    <span className="menu-title">
                                                        On Campus Locations</span>
                                                </NavLink>)}



                                            {permission_info?.view_calendar !== false &&
                                                (<NavLink activeclassname="menu-active" className="menu-link" to={`/${school_code}/settings/calendar-holidays`}>
                                                    <span className="menu-bullet" style={{filter: "brightness(0.5)"}}>
                                                        <i className="fas fa-calendar-alt"></i>
                                                    </span>
                                                    <span className="menu-title">Calendar Edit or Define Holidays</span>
                                                </NavLink>)}    



                                        </div>
                                    </div>
                                </div>



                                <div className="menu-item">
                                    <NavLink activeclassname="menu-active" to={`/${school_code}/leave/manage-boarders-leave`} className="menu-link">
                                        <span className="menu-icon me-2">
                                            <img style={{ width: "30px" }} className="" src="/media/leave_1.png" alt="" />
                                        </span>
                                        <span className="menu-title">Leave</span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default withParams(Sidebar);
