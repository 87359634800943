import axios from "axios";
import * as actionType from "../../constants/actionTypes";
let qs = require("qs");

/*** CreateTravelMode Action ***/
export const CreateTravelModeStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.CreateTravelMode_START,
    };
};
export const CreateTravelModeSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.CreateTravelMode_SUCCESS,
    };
};
export const CreateTravelModeFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.CreateTravelMode_FAIL,
    };
};
export const CreateTravelMode = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    
    return (dispatch) => {
        dispatch(CreateTravelModeStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/CreateTravelMode`,
        })
            .then(function (response) {
                dispatch(CreateTravelModeSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateTravelModeFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** GetAllTravelModes Action ***/
export const GetAllTravelModesStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllTravelModes_START,
    };
};
export const GetAllTravelModesSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllTravelModes_SUCCESS,
    };
};
export const GetAllTravelModesFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllTravelModes_FAIL,
    };
};
export const GetAllTravelModes = (page, limit, sort, order, search ) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllTravelModesStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllTravelModes`,
            params: {
                page: page,
                limit: limit,
                sort: sort,
                order: order,
                search:search,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllTravelModesSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllTravelModesFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** GetTravelModeById Action ***/
export const GetTravelModeByIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetTravelModeById_START,
    };
};
export const GetTravelModeByIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetTravelModeById_SUCCESS,
    };
};
export const GetTravelModeByIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetTravelModeById_FAIL,
    };
};
export const GetTravelModeById = (travel_mode_uuid) => {

    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

   
    return (dispatch) => {
        dispatch(GetTravelModeByIdStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetTravelModeById`,
            params: {
                travel_mode_uuid   : travel_mode_uuid,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetTravelModeByIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetTravelModeByIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** UpdateTravelMode Action ***/

export const UpdateTravelModeStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateTravelMode_START,
    };
};
export const UpdateTravelModeSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateTravelMode_SUCCESS,
    };
};
export const UpdateTravelModeFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateTravelMode_FAIL,
    };
};

export const UpdateTravelMode = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(UpdateTravelModeStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/UpdateTravelMode`,
        })
            .then(function (response) {
                dispatch(UpdateTravelModeSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateTravelModeFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** UpdateTravelModeStatus Action ***/

export const UpdateTravelModeStatusStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateTravelModeStatus_START,
    };
};

export const UpdateTravelModeStatusSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateTravelModeStatus_SUCCESS,
    };
};

export const UpdateTravelModeStatusFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateTravelModeStatus_FAIL,
    };
};

export const UpdateTravelModeStatus = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(UpdateTravelModeStatusStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/UpdateTravelModeStatus`,
        })
            .then(function (response) {
                dispatch(UpdateTravelModeStatusSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateTravelModeStatusFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** DeleteTravelMode Action ***/

export const DeleteTravelModeStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.DeleteTravelMode_START,
    };
};
export const DeleteTravelModeSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.DeleteTravelMode_SUCCESS,
    };
};
export const DeleteTravelModeFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.DeleteTravelMode_FAIL,
    };
};
export const DeleteTravelMode = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(DeleteTravelModeStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "delete",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
                
            },
            url: `${actionType.environment_url}/api/bss-admin/DeleteTravelMode`,
        })
            .then(function (response) {
                console.log("resposne", response.data);
                dispatch(DeleteTravelModeSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                console.log("resposne fail", error);
                dispatch(DeleteTravelModeFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


