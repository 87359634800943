import React from "react";
import Select from "react-select";
import Validations from "../Utility/Validations";
import * as userActions from "./../../actions/index";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { DateRangePicker } from "mui-daterange-picker";
import Form from 'react-bootstrap/Form';
import moment from "moment";

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#e7f5fe" : "",
        },
        backgroundColor: state.isSelected ? "#4a8fb8" : "",
    }),
};

class MedicalIssue extends React.Component {
    constructor(props) {
        console.log("props_data", props);
        super(props);
        this.state = {
            open: false,
            medical_data: [],
            medical_form: {
                start_date: {
                    type: "select",
                    label: "Start Date",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Start Date",
                    validations: { required: true },
                },
                end_date: {
                    type: "select",
                    label: "End Date",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "End Date",
                    validations: { required: true },
                },
                dormitory_id: {
                    type: "select",
                    label: "Select Dormitory",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select Dormitory",
                    validations: { required: true },
                    options: [],
                },
                class_id: {
                    type: "select",
                    label: "Select Year",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select Year",
                    validations: { required: true },
                    options: [],
                },
            },
        };
    }
    inputChangeHandler(event, identifier) {
        const update_rollcall_form = { ...this.state.medical_form };
        const form_element = { ...update_rollcall_form[identifier] };
        form_element.value = event.target.value;
        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_rollcall_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_rollcall_form) {
            is_form_valid = update_rollcall_form[identifier].valid && is_form_valid;
        }
        this.setState({
            medical_form: update_rollcall_form,
            is_form_valid: is_form_valid,
        });
    }


    componentDidMount() {
        this.GetDashboardStudentsMedicalIssued();
        this.GetAllDormitoryStudent();
        this.GetAllClassesList();
    }


    GetDashboardStudentsMedicalIssued = async () => {
        this.props.onGetDashboardStudentsMedicalIssued(this.state.medical_form.start_date.value, this.state.medical_form.end_date.value, this.state.medical_form.dormitory_id.value,this.state.medical_form.class_id.value).then((response) => {
            if (response.success) {
                const medical_response = response.data.rows;
                const filter_medical_array = medical_response.map((medical_object) => {
                    return {
                        comment_by_user: medical_object.comment_by_user.first_name + " " + medical_object.comment_by_user.last_name,
                        user_id: medical_object.comment_by_user.user_id,
                        student_name: medical_object.comment_student_data.student_first_name + " " + medical_object.comment_student_data.student_last_name,
                        student_id: medical_object.comment_student_data.student_id,
                        student_uuid: medical_object.comment_student_data.student_uuid,
                        unique_pin: medical_object.comment_student_data.unique_pin,
                        created_date: medical_object.comment_create_date,
                        diary_comment_desc: medical_object.diary_comment_desc,
                        diary_comment_type: medical_object.diary_comment_type,
                        diary_comment_uuid: medical_object.diary_comment_uuid
                    }
                })
                this.setState({ medical_data: filter_medical_array })
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    }
    toggle = () => this.setState({ open: !this.state.open })
    GetAllClassesList = async (form_data) => {
        this.props.onGetAllClassesList().then((response) => {
            let country_level = [];
            country_level.push({
                label: "Select Year",
                value: "",
            });
            const update_medical_form = { ...this.state.medical_form };
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    country_level.push({
                        label: arrayData[key].class_name,
                        value: arrayData[key].class_id,
                        class_name: arrayData[key].class_name,
                    });
                }
            }
            update_medical_form['class_id'].options = country_level;
            this.setState({ medical_form: update_medical_form });
        });
    }
    GetAllDormitoryStudent = async (form_data) => {
        this.props.onGetAllDormitoryStudent().then((response) => {
            let country_level = [];
            country_level.push({
                label: "Select Dormitory",
                value: "",
            });
            const update_medical_form = { ...this.state.medical_form };
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    country_level.push({
                        label: arrayData[key].dormitory_name,
                        value: arrayData[key].dormitory_id,
                    });
                }
            }
            update_medical_form['dormitory_id'].options = country_level;
            this.setState({ medical_form: update_medical_form });
        });
    }

    handleChange(data, identifier) {
        const update_medical_form = { ...this.state.medical_form };
        if (identifier === 'dormitory_id') {
            update_medical_form['dormitory_id'].value = data.value;
            this.GetDashboardStudentsMedicalIssued(data.value);
        }

        if (identifier === 'class_id') {
            update_medical_form['class_id'].value = data.value;
            this.GetDashboardStudentsMedicalIssued(data.value);
        }
        this.setState({ medical_form: update_medical_form });
    }

    ExportMedicalIssuedStudentsPdf(){
        this.props.onExportMedicalIssuedStudentsPdf().then((response) => {
            this.DownloadPdf(response.file)
        });
    }

    DownloadPdf(csv_url){
        const link = document.createElement("a");
        link.href = csv_url;
        link.target = "_blank"
        // link.setAttribute("download");
        document.body.appendChild(link);
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }
    SubmitHandler = async (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_rollcall_form = this.state.medical_form;
        for (let key in update_rollcall_form) {
            let form_element = update_rollcall_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_rollcall_form[key] = form_element;
        }
        this.setState({ medical_form: update_rollcall_form });
        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.medical_form) {
                form_data[key] = this.state.medical_form[key].value;
            }
        }
    };


    setDateRange = (range) => {
        const update_medical_form = this.state.medical_form;
        const StartDate = new Date(range.startDate);
        const EndDate = new Date(range.endDate);
        let start_month_in_number = StartDate.getMonth() + 1 <= 9 ? ("0" + (StartDate.getMonth() + 1)) : StartDate.getMonth() + 1;
        let start_date_in_number = StartDate.getDate() + 1 <= 9 ? ("0" + (StartDate.getDate())) : StartDate.getDate();
        let end_month_in_number = EndDate.getMonth() + 1 <= 9 ? ("0" + (EndDate.getMonth() + 1)) : EndDate.getMonth() + 1;
        let end_date_in_number = EndDate.getDate() + 1 <= 9 ? ("0" + (EndDate.getDate())) : EndDate.getDate();
        let RangeStartDate = `${StartDate.getFullYear()}-${start_month_in_number}-${start_date_in_number}`
        let RangeEndDate = `${EndDate.getFullYear()}-${end_month_in_number}-${end_date_in_number}`
        update_medical_form['end_date'].value = RangeEndDate;
        update_medical_form['start_date'].value = RangeStartDate;
        this.GetDashboardStudentsMedicalIssued(RangeStartDate, RangeEndDate);
        this.setState({ medical_form: update_medical_form })
        this.setState({ open: !this.state.open })

    }

    dateChangePicker = (e) => {
        console.log("xxxxxxxxxxxxxx", window);
    }
    showDatePicker = () => {
        this.setState({ open: !this.state.open })
    }

    render() {
        const { medical_form } = this.state;
        const { medical_data } = this.state;
        return (
            <>
                <div className="card mb-6 mt-6">
                    <div className="card-header">
                        <h2 className="card-title">
                            Medical Issues
                        </h2>
                        <button type="button" className="btn btn-primary btn-sm my-3 py-2 px-8" onClick={()=>this.ExportMedicalIssuedStudentsPdf()}>
                            PDF
                        </button>
                    </div>
                    <div className="row m-0 py-5 px-3">
                        <div className="col-lg-4">
                            <label className="fw-bold mb-2">Select Date Range</label>
                            <Form.Control
                                type="text"
                                placeholder="Large text"
                                value={medical_form.start_date.value + (medical_form.start_date.value === "" ? "Select Date Range" : " to ") + medical_form.end_date.value}
                                // onChange={(e) => this.dateChangePicker(e)}
                                readOnly
                                onClick={() => this.showDatePicker()}
                            />
                            <div className="date-picker position-absolute" style={{ minWidth: "700px" }}>
                                <DateRangePicker
                                    open={this.state.open}
                                    toggle={this.toggle}
                                    onChange={(range) => this.setDateRange(range)}

                                />
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <label className="fw-bold mb-2">Select Dormitory</label>
                            <Select
                                styles={customStyles}
                                className="react-bootstrap-typeahead tag-outer "
                                options={medical_form.dormitory_id.options}
                                value={medical_form.dormitory_id.options.filter(function (option) {
                                    return option.value === medical_form.dormitory_id.value;
                                })}
                                onChange={(newValue) => this.handleChange(newValue, 'dormitory_id')}
                            />
                        </div>

                        <div className="col-lg-4">
                            <label className="fw-bold mb-2">Select Year</label>
                            <Select
                                styles={customStyles}
                                className="react-bootstrap-typeahead tag-outer "
                                options={medical_form.class_id.options}
                                value={medical_form.class_id.options.filter(function (option) {
                                    return option.value === medical_form.class_id.value;
                                })}
                                onChange={(newValue) => this.handleChange(newValue, 'class_id')}
                            />
                        </div>
                    </div>
                    <div className="pt-5 px-6" style={{ height: "220px", overflowY: "scroll" }}>
                        <div className=""  >
                            <table class="table table-row-bordered std-table-wrap cst-table-overright">
                                <thead class="thead-dark">
                                    <tr>
                                        <th scope="col">S.No.</th>
                                        <th scope="col">Manager Name</th>
                                        <th scope="col">Student Name</th>
                                        <th scope="col">Comment Date</th>
                                        <th scope="col">Diary Comment</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {medical_data.map((item, index) => {
                                        return (
                                            <tr>
                                                <th scope="row">{index + 1}</th>
                                                <td>{item.comment_by_user.charAt(0).toUpperCase() +  item.comment_by_user.slice(1)}</td>
                                                <td>{item.student_name.charAt(0).toUpperCase() +  item.student_name.slice(1)}</td>
                                                <td>{moment.utc(item.created_date).format("YYYY-MM-DD")}</td>
                                                <td>{item.diary_comment_desc.charAt(0).toUpperCase() +  item.diary_comment_desc.slice(1)}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}


const mapDispatchToProps = {
    onGetDashboardStudentsMedicalIssued: userActions.GetDashboardStudentsMedicalIssued,
    onGetAllDormitoryStudent: userActions.GetAllDormitoryStudent,
    onGetAllClassesList: userActions.GetAllClassesList,
    onExportMedicalIssuedStudentsPdf : userActions.ExportMedicalIssuedStudentsPdf
};
export default connect(mapStateToProps, mapDispatchToProps)(MedicalIssue);
// export default MedicalIssue;
