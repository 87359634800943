import axios from "axios";
import * as actionType from "../constants/actionTypes";
let qs = require("qs");


/*** GetAllStudentsForCard Action ***/
export const GetAllStudentsForCardStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllStudentsForCard_START,
    };
};
export const GetAllStudentsForCardSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllStudentsForCard_SUCCESS,
    };
};
export const GetAllStudentsForCardFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllStudentsForCard_FAIL,
    };
};
export const GetAllStudentsForCard = (page , limit , sort, order, is_Student_activate, dormitory_id, class_id, student_age, search, gender, ) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllStudentsForCardStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllStudentsForCard`,
            params: {
                page:page,
                limit:limit,
                sort: sort,
                order: order,
                is_Student_activate:is_Student_activate,
                dormitory_id: dormitory_id,
                class_id: class_id,
                student_age: student_age,
                search: search,
                gender: gender,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllStudentsForCardSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllStudentsForCardFail("Something went wrong, Please try again."));
                return error;
            });
    };
};
