import React, { useEffect, useState, useRef } from "react";
import { useOnClickOutside } from 'usehooks-ts'
import moment from "moment";
import "moment-timezone";

const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const timezone = user_info?.user_school?.timezone;

let setTimer = ''

const BreakfastTimeHandler = (props) => {
    console.log("props===================",props);
    const ref = useRef(null)
    const [hours_drop, setHours_drop] = useState([])
    const [minutes_drop, setMinutes_drop] = useState([])
    const [am_pm, setAm_pm] = useState([])
    const [hours, setHours] = useState("")
    const [minutes, setMinutes] = useState("")
    const [Am_Pm, setAm_Pm] = useState("")
    const [show_time, setShowTime] = useState(false)
    const [time, setTime] = useState(props.placeholder)

    useEffect(()=>{
        setTime(props.value !== undefined ? moment(props.value, "HH:mm").format("hh:mm A") : props.placeholder)
    },[props.value])
    useEffect(() => {

        const getTodayDate = moment(new Date())?.tz(timezone)?.format('MMMM Do YYYY');
        const getAM_PM = moment(new Date())?.tz(timezone)?.format('A');
        const getTodayHour = moment(new Date())?.tz(timezone)?.hours()
        const getTodayMinu = moment(new Date())?.tz(timezone)?.minutes()
        const getComponentDate = moment(props.selectDate)?.tz(timezone)?.format('MMMM Do YYYY');
        let hours_array = []
        let minutess_array = []




        for (let i = 1; i <= 12; i++) {
            hours_array.push({
                disabled: false,
                value: i <= 9 ? `0${i}` : `${i}`,
                label: i <= 9 ? `0${i}` : `${i}`
            })
        }
        {
            let i = 0
            while (i < 60) {
                minutess_array.push({
                    disabled: false,
                    value: i <= 9 ? `0${i}` : `${i}`,
                    label: i <= 9 ? `0${i}` : `${i}`
                })
                i = i + 5
            }
        }
        setAm_pm([
            {
                disabled: false,
                value: "AM",
                label: "AM"
            },
            {
                disabled: false,
                value: "PM",
                label: "PM"
            }
        ])

        setHours_drop(hours_array)
        setMinutes_drop(minutess_array)
        const hour = new Date().getHours() > 12 ? new Date().getHours() - 12 : new Date().getHours();
        const current_hours = (Math.floor(new Date().getMinutes() / 5) * 5) + 5 > 55 ? hour + 1 : hour;
        const current_Minutes = (Math.floor(new Date().getMinutes() / 5) * 5) + 5 > 55 ? "00" : (Math.floor(new Date().getMinutes() / 5) * 5) + 5
        setAm_Pm(getAM_PM)
        setHours(current_hours < 9 ? `0${current_hours}` : current_hours)
        setMinutes(current_Minutes < 9 ? `0${current_Minutes}` : `${current_Minutes}`)
    }, [props.selectDate]);

    const setleavetime = (value, identifier) => {
        if ("hours" == identifier) {
            setHours(value)
            setTime(`${value}:${minutes} ${Am_Pm}`)
            setTimer = `${value}:${minutes} ${Am_Pm}`
            sendTimeForparent()
        } else if ("minutes" == identifier) {
            setMinutes(value)
            setTime(`${hours}:${value} ${Am_Pm}`)
            setTimer = `${hours}:${value} ${Am_Pm}`
            sendTimeForparent()
        } else {
            setAm_Pm(value)
            setTime(`${hours}:${minutes} ${value}`)
            setTimer = `${hours}:${minutes} ${value}`
            sendTimeForparent()
            setShowTime(false)
        }
    }
    const sendTimeForparent = () => {
        props.onChange(setTimer)
    }
    const handleClickOutside = () => {
        setShowTime(false)
    }
    useOnClickOutside(ref, handleClickOutside)
    const watchPosition = {
        position: "absolute",
        right: 0,
        margin: "10px"
    }


    return (
        <>
            <div ref={ref} className='position-relative'>
                <span style={watchPosition} onClick={() => setShowTime(!show_time)}><i class="far fa-clock"></i></span>
                <input

                    className="form-control"
                    value={time}
                    onClick={() => setShowTime(!show_time)}
                />
                {
                    show_time &&
                    <div className='position-absolute bg-white shadow' style={{ zIndex: 9 }}>
                        <div className='d-flex border p-5'>
                            <div className='hours_class'>
                                {hours_drop.map((hour) => {
                                    return (
                                        <div className='p-0 m-0'>
                                            <button type='button' disabled={hour.disabled} onClick={() => setleavetime(hour.value, "hours")} className='border-0 bg-white px-4 py-1 btn btn-light text-dark'>
                                                {hour.label}
                                            </button>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='minutes_class'>
                                {minutes_drop.map((minute) => {
                                    return (
                                        <div className='p-0 m-0'>
                                            <button type='button' onClick={() => setleavetime(minute.value, "minutes")} className='border-0 bg-white px-4 py-1 btn btn-light text-dark'>
                                                {minute.label}
                                            </button>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='am_pm_class'>
                                {am_pm.map((item) => {
                                    return (
                                        <div className='p-0 m-0'>
                                            <button type='button' disabled={item.disabled} onClick={() => setleavetime(item.value, "am/pm")} className='border-0 bg-white px-4 py-1 btn btn-light text-dark'>
                                                {item.label}
                                            </button>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>

    )
}

export default BreakfastTimeHandler;