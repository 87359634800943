import axios from "axios";
import * as actionType from "../constants/actionTypes";
let qs = require("qs");


/*** GetStuLocNotificationsbyUserId Action ***/


export const GetStuLocNotificationsbyUserIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetStuLocNotificationsbyUserId_START,
    };
};
export const GetStuLocNotificationsbyUserIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetStuLocNotificationsbyUserId_SUCCESS,
    };
};
export const GetStuLocNotificationsbyUserIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetStuLocNotificationsbyUserId_FAIL,
    };
};
export const GetStuLocNotificationsbyUserId = () => {
   
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetStuLocNotificationsbyUserIdStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info?.country_host ,
                school_code: user_info?.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetStuLocNotificationsbyUserId`,
            params: {

               

            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetStuLocNotificationsbyUserIdSuccess(response.data));
                // console.log("Response in action for notification to manager", response)
                // console.log("API RESPONSE action", response)
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetStuLocNotificationsbyUserIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** DeleteStuLocNotificationsbyUserId Action ***/
export const DeleteStuLocNotificationsbyUserIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.DeleteStuLocNotificationsbyUserId_START,
    };
};
export const DeleteStuLocNotificationsbyUserIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.DeleteStuLocNotificationsbyUserId_SUCCESS,
    };
};
export const DeleteStuLocNotificationsbyUserIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.DeleteStuLocNotificationsbyUserId_FAIL,
    };
};
export const DeleteStuLocNotificationsbyUserId = (form_data) => {
    // console.log("00-11 userid", form_data)
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    // console.log("00-22 userid", form_data)
    return (dispatch) => {
        dispatch(DeleteStuLocNotificationsbyUserIdStart());

        let formdata = new FormData();
       
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        // console.log("00-333--- userid", form_data)

        return axios({
            method: "delete",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info?.country_host ,
                school_code: user_info?.school_code,
                
            },
            url: `${actionType.environment_url}/api/bss-admin/DeleteStuLocNotificationsbyUserId`,
        })
            .then(function (response) {
                // console.log("resposne00--action--11", response);
                dispatch(DeleteStuLocNotificationsbyUserIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(DeleteStuLocNotificationsbyUserIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** UpdateStuLocNotificationStatus Action ***/
export const UpdateStuLocNotificationStatusStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateStuLocNotificationStatus_START,
    };
};
export const UpdateStuLocNotificationStatusSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateStuLocNotificationStatus_SUCCESS,
    };
};
export const UpdateStuLocNotificationStatusFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateStuLocNotificationStatus_FAIL,
    };
};

export const UpdateStuLocNotificationStatus = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(UpdateStuLocNotificationStatusStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info?.country_host ,
                school_code: user_info?.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/UpdateStuLocNotificationStatus`,
        })
            .then(function (response) {
                dispatch(UpdateStuLocNotificationStatusSuccess(response.data));
                // console.log("Update API response in Action", response)
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateStuLocNotificationStatusFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

