import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Sidebar from "../Constant/sidebar";
import Footer from "../Constant/Footer";
import TopBar from "../Constant/TopBar";
import { useNavigate } from "react-router-dom";
import * as userActions from "../../actions/index";

export default function HistoricalPermission() {

    const dispatch = useDispatch();
    const navigate = useNavigate();


    const GetManagerPermissonHistoryById = useCallback(async () => {
        dispatch(userActions.GetManagerPermissonHistoryById()).then((response) => {
            if (response.success === true) {
                console.log("RESPONSE", response)
                const arrayData = response.data.rows;
                let MemberId_level = [];
                // const sumvalue = 1;
                for (let key in arrayData) {
                    // const perpage = ((parseInt(filterpage) - parseInt(sumvalue)) * parseInt(filterlimit) + parseInt(key) + parseInt(sumvalue));

                    // MemberId_level.push({
                    //     // perpage: perpage,
                    //     first_name: arrayData[key].first_name,
                    //     last_name: arrayData[key].last_name,
                    //     email: arrayData[key].email,
                    //     contact_one: arrayData[key].contact_one,
                    //     designation: arrayData[key].designation,
                    //     user_uuid: arrayData[key].user_uuid,
                    //     is_user_activate: arrayData[key].is_user_activate,
                    //     user_id: arrayData[key].user_id,

                    // });
                }
                // setManagerList(MemberId_level);
                // setManagerListcount(response.data.count);
            
            }

            else if (response.status === 401) {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,   
                });  
                setTimeout(() => { 
                    navigate('/logout')
                 }, 5000);
                 }
            
            else {
            }
        });
        // eslint-disable-next-line
    }, [dispatch, ]);

    useEffect(() => {
        GetManagerPermissonHistoryById();            
    }, [])

    return (
        <>
            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <Sidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-fluid">
                                        <div className="row gx-5 gx-xl-10">
                                            <div className="col-xl-12">
                                                <h4 className="card-title text-gray-800 fw-bolder m-0">Historical Permissions</h4>
                                            </div>

                                            <div className="col-xl-12">
                                                <div className="card mb-5 tabs-outer">
                                                    <div className="card-header position-relative py-0 details-bg">
                                                        {/* <!--begin::Card title--> */}
                                                        <h5 className="card-title text-gray-800 fw-bolder m-0">Web Permission</h5>
                                                    </div>

                                                    <div className="card-body">
                                                        <div className="row">
                                                        

                                                            <div className="table-responsive common-table mt-4">
                                                                {/* <!--begin::Table--> */}
                                                                <table className="table table-row-bordered align-middle gs-0 gy-2 my-0 history-out">
                                                                    {/* <!--begin::Table head--> */}
                                                                    <thead>
                                                                        <tr className="fw-bolder">
                                                                            <th className="pb-3">Date</th>
                                                                            <th className="pb-3">Permission Name</th>
                                                                            <th className="pb-3 pe-12">Permissions</th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        <tr>
                                                                            <td>25-08-2022</td>
                                                                            <td> Manage Class</td>
                                                                            <td>
                                                                                <div className="d-flex">
                                                                                    <span className="badge badge-light me-1">Add Class</span>
                                                                                    <br />
                                                                                    <span className="badge badge-light me-1">Edit Class</span>
                                                                                    <br />
                                                                                    <span className="badge badge-light me-1">View Class</span>
                                                                                    <br />
                                                                                    <span className="badge badge-light me-1">Delete Class</span>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>30-08-2022</td>
                                                                            <td> Manage Students</td>
                                                                            <td>
                                                                                <div className="d-flex">
                                                                                    <span className="badge badge-light me-1">Add Student</span>
                                                                                    <span className="badge badge-light me-1">Edit Student</span>
                                                                                    <span className="badge badge-light me-1">View Student</span>
                                                                                    <span className="badge badge-light me-1">Delete Student</span>
                                                                                    <span className="badge badge-light me-1">Activate/Deactivate Students</span>
                                                                                </div>
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>25-08-2022</td>
                                                                            <td>Manage Reasons</td>
                                                                            <td>
                                                                                <div className="d-flex">
                                                                                    <span className="badge badge-light me-1">Add Reason</span>
                                                                                    <span className="badge badge-light me-1">Edit Reason</span>
                                                                                    <span className="badge badge-light me-1">View Reason</span>
                                                                                    <span className="badge badge-light me-1">Delete Reason</span>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>12-08-2022</td>
                                                                            <td>Manage Host</td>
                                                                            <td>
                                                                                <div className="d-flex">
                                                                                    <span className="badge badge-light me-1">Add Host</span>
                                                                                    <span className="badge badge-light me-1">Edit Host</span>
                                                                                    <span className="badge badge-light me-1">View Host</span>
                                                                                    <span className="badge badge-light me-1">Parents Approval</span>
                                                                                    <span className="badge badge-light me-1">Activate/Deactivate Host</span>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>14-06-2022</td>
                                                                            <td>Manage Travel Mode</td>
                                                                            <td>
                                                                                <div className="d-flex">
                                                                                    <span className="badge badge-light me-1">Add Travel Mode</span>
                                                                                    <span className="badge badge-light me-1">Edit Travel Mode</span>
                                                                                    <span className="badge badge-light me-1">View Travel Mode</span>
                                                                                    <span className="badge badge-light me-1">Delete Travel Mode</span>
                                                                                    <span className="badge badge-light me-1">Activate/Deactivate Travel Mode</span>
                                                                                </div>
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>14-06-2022</td>
                                                                            <td>Manage Leave Rejection Reason</td>
                                                                            <td>
                                                                                <div className="d-flex">
                                                                                    <span className="badge badge-light me-1">Add Reason</span>
                                                                                    <span className="badge badge-light me-1">Edit Reason</span>
                                                                                    <span className="badge badge-light me-1">View Reason</span>
                                                                                    <span className="badge badge-light me-1">Delete Reason</span>
                                                                                    <span className="badge badge-light me-1">Activate/Deactivate Reason</span>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>14-06-2022</td>
                                                                            <td>Manage Weekend Leave</td>
                                                                            <td>
                                                                                <div className="d-flex">
                                                                                    <span className="badge badge-light me-1">view weekend leave</span>
                                                                                    <span className="badge badge-light me-1">Edit weekend leave</span>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>14-06-2022</td>
                                                                            <td>Manage Adhoc Leave</td>
                                                                            <td>
                                                                                <div className="d-flex">
                                                                                    <span className="badge badge-light me-1">view adhoc leave</span>
                                                                                    <span className="badge badge-light me-1">Add adhoc leave</span>
                                                                                    <span className="badge badge-light me-1">Edit Adhoc Leave</span>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>14-06-2022</td>
                                                                            <td>Manage Time Slot</td>
                                                                            <td>
                                                                                <div className="d-flex">
                                                                                    <span className="badge badge-light me-1">View Time Slot</span>
                                                                                    <span className="badge badge-light me-1">Add Time Slot</span>
                                                                                    <span className="badge badge-light me-1">Edit Time Slot</span>
                                                                                    <span className="badge badge-light me-1">Delete Time Slot</span>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>14-06-2022</td>
                                                                            <td>Manage Managers</td>
                                                                            <td>
                                                                                <div className="d-flex">
                                                                                    <span className="badge badge-light me-1">View Managers</span>
                                                                                    <span className="badge badge-light me-1">Add Manager</span>
                                                                                    <span className="badge badge-light me-1">Edit Manager</span>
                                                                                </div>
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>14-06-2022</td>
                                                                            <td>Manage Dormitory</td>
                                                                            <td>
                                                                                <div className="d-flex">
                                                                                    <span className="badge badge-light me-1">View Dormitory</span>
                                                                                    <span className="badge badge-light me-1">Add Dormitory</span>
                                                                                    <span className="badge badge-light me-1">Edit Dormitory</span>
                                                                                    <span className="badge badge-light me-1">Delete Dormitory</span>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>14-06-2022</td>
                                                                            <td>Manage Reports</td>
                                                                            <td>
                                                                                <div className="d-flex">
                                                                                    <span className="badge badge-light me-1">View Reports</span>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>14-06-2022</td>
                                                                            <td>Manage Web Permissions</td>
                                                                            <td>
                                                                                <div className="d-flex">
                                                                                    <span className="badge badge-light me-1">View Permission</span>
                                                                                    <span className="badge badge-light me-1">Add Permission</span>
                                                                                    <span className="badge badge-light me-1">Edit Permission</span>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>14-06-2022</td>
                                                                            <td>Manage Misc Message</td>
                                                                            <td>
                                                                                <div className="d-flex">
                                                                                    <span className="badge badge-light me-1">View Message</span>
                                                                                    <span className="badge badge-light me-1">Edit Message</span>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>

                                                                <div className="d-flex flex-stack justify-content-end flex-wrap pt-3 pagination-main">
                                                                    <div className="pagination_limt">
                                                                        <select size="1" id="select" name="group" className="form-control input-sm form-select form-select-sm form-select-solid">
                                                                            <option value="10">10</option>
                                                                            <option value="25">25</option>
                                                                            <option value="50">50</option>
                                                                            <option value="100">100</option>
                                                                            <option value="250">250</option>
                                                                            <option value="500">500</option>
                                                                        </select>
                                                                        <div className="count_number ms-2">Displaying 1-10 of 100 records</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
