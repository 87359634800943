// actions.js
export const updateBooleanValue = (value) => ({
    type: 'UPDATE_BOOLEAN_VALUE',
    payload: value,
  });
  
  // reducer.js
  const initialState = {
    booleanValue: false,
  };
  
  const reducersnapshot = (state = initialState, action) => {
    switch (action.type) {
      case 'UPDATE_BOOLEAN_VALUE':
        return {
          ...state,
          booleanValue: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default reducersnapshot;
  