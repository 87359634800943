import React from "react";

import Validations from "../Utility/Validations";
import Sidebar from "../Constant/sidebar";
import Footer from "../Constant/Footer";
import TopBar from "../Constant/TopBar";

class ReasonAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reason_form: {
                reason_name: {
                    type: "text",
                    label: "Reason Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Reason_Name",
                    validations: { required: true },
                },
            },
        };
    }

    inputChangeHandler(event, identifier) {
        const update_manager_form = { ...this.state.reason_form };
        const form_element = { ...update_manager_form[identifier] };
        form_element.value = event.target.value;
        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_manager_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_manager_form) {
            is_form_valid = update_manager_form[identifier].valid && is_form_valid;
        }
        this.setState({
            reason_form: update_manager_form,
            is_form_valid: is_form_valid,
        });
    }
    handleChange(data, identifier, type) {
        const update_manager_form = { ...this.state.reason_form };
        update_manager_form[identifier].value = data.value;
        this.setState({ reason_form: update_manager_form });
    }
    SubmitHandler = async (event) => {
        console.log("gfdgjdfkj");
        event.preventDefault();
        let is_form_valid = true;
        const update_manager_form = this.state.reason_form;
        for (let key in update_manager_form) {
            let form_element = update_manager_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_manager_form[key] = form_element;
        }
        this.setState({ reason_form: update_manager_form });
        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.reason_form) {
                form_data[key] = this.state.reason_form[key].value;
            }
            console.log("form_data===", form_data);
        }
    };
    render() {
        const { reason_form } = this.state;

        console.log("reason_form===", reason_form);

        return (
            <React.Fragment>
                <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed demo h-100">
                    <div className="d-flex flex-column flex-root h-100">
                        <div className="page d-flex flex-row flex-column-fluid">
                            <Sidebar></Sidebar>

                            {/* <LeftSideBar></LeftSideBar> */}

                            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                                {/* <Topbar></Topbar> */}

                                <TopBar></TopBar>
                                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                    <div className="post d-flex flex-column-fluid" id="kt_post">
                                        <div id="kt_content_container" className="container-fluid">
                                            <div className="school-edit-form ">
                                                <div className="" id="Snapshot">
                                                    <form onSubmit={this.SubmitHandler}>
                                                        <div className="card mb-5 tabs-outer">
                                                            <div className="card-header position-relative py-0 details-bg">
                                                                <h5 className="card-title text-gray-800 fw-bolder m-0">Add Reason</h5>
                                                            </div>

                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-lg-6 fv-row mb-10">
                                                                        <label className="fw-bold mb-2">{reason_form.reason_name.label} </label>

                                                                        <input
                                                                            type={reason_form.reason_name.type}
                                                                            value={reason_form.reason_name.value ? reason_form.reason_name.value : ""}
                                                                            className="form-control"
                                                                            placeholder={reason_form.reason_name.placeholder}
                                                                            onChange={(event) => this.inputChangeHandler(event, "reason_name")}
                                                                        />

                                                                        {!reason_form.reason_name.valid && reason_form.reason_name.onBlur_out ? <div className="error field-error">{reason_form.reason_name.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-12 fv-row mb-5">
                                                                        <div className="row">
                                                                            <div className="col-sm-3">
                                                                                <h4 className="fw-bold mb-2 ">Reason Image</h4>
                                                                                <label className="fw-bold mb-2">Upload file</label>

                                                                                <div className="profile-image-out p-0">
                                                                                    <img alt="profile" src="/assets/media/school-logo.jpg" className="img-fluid" />
                                                                                    <div className="d-flex justify-content-center h-100 flex-column align-items-center">
                                                                                        <p className="text-center">Please attach jpg or png file</p>
                                                                                        <label className="btn btn-sm btn-outline-primary rounded-50 mb-4">
                                                                                            Attach Image
                                                                                            <input hidden="hidden" type="file" />
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-12 d-flex justify-content-end">
                                                            <div className="d-flex align-items-end">
                                                                <button type="submit" className="btn btn-sm btn-primary apply-btn min-150px addschool-button">
                                                                    Add Reason
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Footer />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default ReasonAdd;
