import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from "@mui/material";
import { toast } from "react-toastify";

function DuplicateRoleModal(props) {
    const [role_name, setRole_name] = useState("")
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const inputHandler = (e) => {
        setRole_name(e.target.value)
    }

    const submitData = () => {
        if (props.all_role_name.includes(role_name.trim())) {
            toast.error("This role name is already exist", {
                position: toast.POSITION.TOP_CENTER,
            });
        } else {
            props.createI_role_name(props.role_uuid, role_name)
            handleClose()
        }
    }

    return (
        <>
            <Tooltip title="Duplicate Role" placement="top">
                <IconButton variant="primary" onClick={handleShow}>
                    <i class="fas fa-copy fa-xs"></i>
                </IconButton>
            </Tooltip>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Duplicate Role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <label className="fw-bold mb-2 text-wrap">Add a New Role Name that will carry the same Permissions.
                            (These Permissions can always be edited later).</label>
                        <input
                            value={role_name}
                            className='form-control'
                            onChange={(e) => inputHandler(e)}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className=''>
                    <Button className='btn-sm' variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button className="btn-sm" variant="primary" onClick={submitData}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DuplicateRoleModal;